'use strict';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('userAgreement', () => ({
    restrict: 'E',
    scope: {
        user: '<',
        team: '<'
    },
    template,
    controller: Controller
}));
