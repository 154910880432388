'use strict';

import template from './index.html';
import controller from './controller';

angular.module('serviceApp').component('reactApp', {
    template,
    controller,
    bindings: {
        team: '<',
        user: '<'
    }
});
