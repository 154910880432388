'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('registerBoardSlice', {
    controller,
    template,
    bindings: {
        type: '<'
    }
});
