'use strict';
import template from './index.html';
import controller from './controller';

angular.module('serviceApp').directive('instantActionMenu', () => ({
    restrict: 'E',
    scope: {
        type: '=',
        user: '<',
        board: '=',
        content: '=',
        contextMenuClass: '<?'
    },
    template,
    controller
}));
