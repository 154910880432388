angular.module('serviceApp').factory('cardsUtilService', [
    '$log',
    '$resource',
    '$q',
    '$rootScope',
    'definitionService',
    '$httpParamSerializer',
    'appSettingsService',
    'boardSliceService',
    function(
        $log,
        $resource,
        $q,
        $rootScope,
        definitionService,
        $httpParamSerializer,
        appSettingsService,
        boardSliceService
    ) {
        const CARD_URL = '/api/card';
        const CardResource = $resource(
            '/api/card',
            {},
            {
                getCards: {
                    method: 'POST',
                    isArray: true,
                    cancellable: true,
                    url: CARD_URL
                },
                getCardsIgnoreLoadingBar: {
                    method: 'POST',
                    isArray: true,
                    cancellable: true,
                    url: CARD_URL,
                    ignoreLoadingBar: true
                },
                renderCard: {
                    method: 'POST',
                    cancellable: true,
                    url: `${CARD_URL}/single`
                }
            }
        );

        return {
            getCards: function({
                boardId,
                boardDefinitionName,
                panelName,
                panelGroup,
                cardName,
                query,
                facets,
                appId,
                noFilter,
                filter,
                cacheOnly,
                boardDefinition,
                interrupt,
                hierarchy = undefined,
                ignoreLoadingBar = false
            } = {}) {
                var deferred = $q.defer();

                if (!query) {
                    query = {};
                }

                if (_.isEmpty(cardName)) {
                    return $q.reject('cardName must be defined in getCards');
                }
                let _filter = getCurrentFilter();
                if (filter) {
                    _filter = filter;
                }

                // If there is a board definition, trim it down before sending it with the API.
                if (boardDefinition) {
                    boardDefinition = _.pick(boardDefinition, [
                        'id',
                        'name',
                        'displayName',
                        'globalId',
                        'definitionType',
                        'propertySchema',
                        'propertyValues',
                        'filter',
                        'board'
                    ]);
                    boardDefinition.propertySchema = _.pick(boardDefinition.propertySchema || {}, [
                        'layout',
                        'filter'
                    ]);
                    boardDefinition.propertyValues = _.pick(boardDefinition.propertyValues || {}, [
                        'layout',
                        'filter'
                    ]);
                    boardDefinition.board = {
                        boardType: _.get(boardDefinition, 'board.boardType'),
                        contentType: _.get(boardDefinition, 'board.contentType'),
                        cardConfig: cardName
                            ? _.filter(_.get(boardDefinition, 'board.cardConfig', []), cardConfigItem =>
                                  _.includes(_.castArray(cardName), cardConfigItem.cardName)
                              )
                            : _.get(boardDefinition, 'board.cardConfig', [])
                    };
                }

                let currentCall;
                const options = {
                    board: boardId,
                    definitionName: boardDefinitionName,
                    app: appId || '',
                    group: panelGroup || '',
                    panel: panelName,
                    card: cardName,
                    query: query.str || query.query || '',
                    destinationTime: query.destinationTime || '',
                    advancedQuery: !!_.get(query, 'advancedQuery', true),
                    facets: facets,
                    filter: noFilter ? '' : _filter,
                    cacheOnly: cacheOnly || false,
                    ignoreLazy: true,
                    boardDefinition: boardDefinition,
                    hierarchy
                };

                // Card row render shows progress in the loading skeleton
                if (ignoreLoadingBar) {
                    currentCall = CardResource.getCardsIgnoreLoadingBar(options);
                } else {
                    currentCall = CardResource.getCards(options);
                }

                currentCall.$promise
                    .then(
                        function(cards) {
                            cards = _.castArray(cards);

                            _.forEach(cards, function(card) {
                                //The kanban view uses a mapped set of cards that is used in the carousel view.
                                if (card.map) {
                                    cards = mapCards(cards);
                                }
                            });
                            deferred.resolve(cards);
                        },
                        function(errorMsg) {
                            //Only log errors on non-interrupt calls
                            if (!interrupt) {
                                $log.error('Could not get cards ', errorMsg);
                            }
                            deferred.reject(errorMsg);
                        }
                    )
                    .catch(error => {
                        //Only log errors on non-interrupt calls
                        if (!interrupt) {
                            $log.error('Could not get cards ', error.msg);
                        }
                        deferred.reject(error.msg);
                    });
                // If ability to interrupt is true, push this card request to the current card requests array inflight
                if (interrupt) {
                    $rootScope._currentCardRequest = $rootScope._currentCardRequest || [];
                    $rootScope._currentCardRequest.push(currentCall);
                }
                return deferred.promise;
            },
            // Exactly the same as getCards, expects a single card to be returned
            getCard: function(opts) {
                return this.getCards(opts).then(function(cards) {
                    if (_.isArray(cards)) {
                        cards = _.first(cards);
                    }

                    return cards;
                });
            },
            // Get every card for a panel.
            getPanel: function(
                boardId,
                boardDefinitionName,
                panelName,
                panelGroup,
                query,
                facets,
                appId,
                interrupt,
                fromUser,
                ignoreLazy = false,
                boardDefinition,
                hierarchy,
                noFilter
            ) {
                // Go through all the currently inflight card requests and cancel them
                if (interrupt && $rootScope._currentCardRequest) {
                    _.each($rootScope._currentCardRequest, request => {
                        if (!request.$resolved) {
                            request.$cancelRequest();
                        }
                    });
                    $rootScope._currentCardRequest = undefined;
                }

                var deferred = $q.defer();

                if (!query) {
                    query = {};
                }

                var currentCall = CardResource.getCards({
                    board: boardId,
                    definitionName: boardDefinitionName,
                    app: appId || '',
                    panel: panelName,
                    group: panelGroup || '',
                    query: query.str || query.query || '',
                    destinationTime: query.destinationTime || '',
                    advancedQuery: !!_.get(query, 'advancedQuery', true),
                    facets: facets,
                    filter: noFilter ? '' : getCurrentFilter(),
                    cacheOnly: true,
                    fromUser,
                    ignoreLazy,
                    boardDefinition: boardDefinition,
                    hierarchy
                });

                currentCall.$promise
                    .then(
                        function(cards) {
                            _.forEach(cards, function(card) {
                                //The kanban view uses a mapped set of cards that is used in the carousel view.
                                if (card.map) {
                                    cards = mapCards(cards);
                                }
                            });
                            deferred.resolve(cards);
                        },
                        function(errorMsg) {
                            deferred.reject(errorMsg);
                            $log.error('Could not get panel ', errorMsg);
                        }
                    )
                    .catch(error => {
                        deferred.reject(error.msg);
                        $log.error('Could not get panel ', error.msg);
                    });

                // If ability to interrupt is true, push this card request to the current card requests array inflight
                if (interrupt) {
                    $rootScope._currentCardRequest = $rootScope._currentCardRequest || [];
                    $rootScope._currentCardRequest.push(currentCall);
                }

                return deferred.promise;
            },
            // Resolve a card based on definition
            renderCard: function({
                boardDefinitionName,
                boardType,
                cardDefinitionId,
                query,
                facets,
                filter,
                cardDefinition,
                hierarchy
            }) {
                var deferred = $q.defer();

                if (!query) {
                    query = {};
                }

                var currentCall = CardResource.renderCard({
                    boardDefinitionName,
                    boardType,
                    cardDefinitionId,
                    query: query.str || query.query || '',
                    advancedQuery: !!_.get(query, 'advancedQuery', true),
                    facets,
                    filter,
                    cardDefinition,
                    hierarchy
                });

                currentCall.$promise
                    .then(card => {
                        deferred.resolve(card);
                    })
                    .catch(error => {
                        deferred.reject(error.msg ? error.msg : error);
                        $log.error('Could not resolve card ', error.msg ? error.msg : error);
                    });

                return deferred.promise;
            },
            /**
             * Given a board config with a resolved `definition`, return an array of lazy card configs
             * that can be used for loading / displaying those cards later.
             *
             * @param {Object} board - the board config that has a resolved `definition` property, being a board definition
             *                    with `propertyValues.layout` populated.
             * @param {String} [panelName]  - the name of the panel to load cards for.  If not provided, the first panel
             *                           in the board will be used.
             * @returns {Array} - an array of objects describing cards to load.
             */
            getLayoutCards: function(board, panelName) {
                const boardLayout = _.get(board, 'definition.propertyValues.layout');
                let lazyCards = [];
                // Find the specified panel by name, or else default to the first panel in the board.
                const panel =
                    _.find(boardLayout.panels, panel => panel.name === panelName) || boardLayout.panels[0];
                _.each(panel.rows, (row, rowIndex) => {
                    _.each(row.cards, (card, colIndex) => {
                        // Generate a list of positional layout cards that can be used by the
                        // UI to create a loading state that doesn't require a card API call
                        lazyCards.push({
                            lazy: true,
                            loading: true,
                            name: card.cardName,
                            position: colIndex,
                            row: _.toString(rowIndex),
                            size: {
                                x: card.width
                            },
                            type: 'card-loader'
                        });
                    });
                });

                return lazyCards;
            },
            /**
             * Return a data payload that can be used in a request to the CSV Export API.
             * For legacy reasons this is named `buildDownloadUrl` but it now returns
             * a POJO rather than a URL string.
             *
             * Typically not all of these args will be used.  The `boardDefinition` will be provided when
             * exporting data from a dashboard card inspection view, but not from report view, for example.
             *
             * @param {String} [args.boardId] - the ID of a board to use when generating data for export.
             * @param {Object} [args.boardDefinition] - a board definition object to use when generating data for export.
             * @param {String} [args.boardDefinitionName] - the name of a board to use when generating data for export.
             * @param {String} [args.boardType] - the type of board (e.g. pipeline, insights) we're generating data from.
             * @param {String} [args.cardName] - the name of a specific card on a board to generate data for.
             * @param {String} [args.cardDefinitionId] - the ID of a specific card on a board to generate data for.
             * @param {Object} [args.cardDefinition] - the card definition object to use when generating data.
             * @param {String} [args.panelName] - the name of the board panel we should use to generate data.
             * @param {Object} [args.query] - a set of key/value pairs to use as free-text query when generating data.
             * @param {Object} [args.facets] - a set of facet values to apply when generating data.
             * @param {Object} [args.filter] - a set of pliable filters to apply when generating data.
             * @param {Object} [args.hierarchy] - hierarchy info (definition ID, node ID, etc.) to apply when generating data.
             * @param {Array} [args.fields] - the fields to use as columns in the exported CSV.
             * @returns
             */
            buildDownloadUrl(args) {
                const {
                    boardId,
                    boardDefinition,
                    boardDefinitionName,
                    boardType,
                    cardName,
                    panelName,
                    cardDefinitionId,
                    query,
                    facets,
                    filter,
                    cardDefinition,
                    hierarchy,
                    fields // which fields to include in the CSV
                } = args;
                return {
                    board: boardId,
                    boardDefinition,
                    definitionName: boardDefinitionName,
                    cardDefinition,
                    cardDefinitionId,
                    boardType,
                    panel: panelName || 'main',
                    card: cardName,
                    facets,
                    query: _.get(query, 'str') || _.get(query, 'query') || '',
                    advancedQuery: !!_.get(query, 'advancedQuery', true),
                    filter: angular.toJson(filter || getCurrentFilter()),
                    hierarchy,
                    fields
                };
            }
        };

        // The only card that has map: 'true' is the Pipeline topContent card.
        function mapCards(cards) {
            var mappedCards = [];
            _.forEach(cards, function(card) {
                if (card.map) {
                    var contentKey = _.get(card, 'properties.contentKey');
                    if (_.isObject(contentKey)) {
                        card.properties.content = _.map(card.properties.content, function(contentItem) {
                            if (contentKey.type == 'regex') {
                                var regexp = new RegExp(contentKey.value);
                                var match = regexp.exec(_.get(contentItem, contentKey.field));
                                contentItem.identifier = match[contentKey.captureGroup];
                                contentItem.contentType = contentKey.contentType
                                    ? contentKey.contentType
                                    : contentItem.contentType;
                            }
                            return contentItem;
                        });
                        contentKey = 'identifier';
                    }
                    var columnKey = _.get(card, 'properties.columnKey');
                    if (_.isObject(columnKey)) {
                        card.properties.columns = _.map(card.properties.columns, function(columnItem) {
                            if (columnKey.type == 'transform') {
                                if (columnKey.value == 'kebabCase') {
                                    columnItem.identifier = _.kebabCase(_.get(columnItem, columnKey.field));
                                }
                            }
                            return columnItem;
                        });
                        columnKey = 'identifier';
                    }
                    card.properties.content = _.sortBy(card.properties.content, function(content, i) {
                        if (content.firstValue) {
                            return content.firstValue;
                        } else {
                            return i;
                        }
                    });

                    // If multiple columns have the same value of contentKey, de-duplicate them for display purposes
                    const uniqColumns = _.uniqBy(card.properties.columns, column => {
                        return _.get(column, columnKey);
                    });
                    _.forEach(uniqColumns, function(column) {
                        const columnValue = _.get(column, columnKey);
                        var mappedCard = {
                            title: columnValue,
                            type: card.type,
                            size: _.clone(card.size),
                            properties: {
                                content: _.filter(card.properties.content, function(content) {
                                    return _.get(content, contentKey) == columnValue;
                                })
                            }
                        };

                        if (_.get(card, 'properties.metrics')) {
                            if (_.get(card, 'properties.metrics.display')) {
                                var display = _.get(card, 'properties.metrics.display');
                                _.forEach(display.dataSets, function(dataSetDisplay) {
                                    var data = _.get(
                                        card,
                                        'properties.metrics.data[' + dataSetDisplay.name + ']'
                                    );
                                    let dataIndex;
                                    var metrics = _.find(data, function(item, index) {
                                        if (
                                            _.kebabCase(dataSetDisplay.mapKey + ' ' + mappedCard.title) ==
                                            _.get(item, dataSetDisplay.mapField)
                                        ) {
                                            dataIndex = index;
                                            return true;
                                        }
                                        return false;
                                    });

                                    mappedCard.metrics = _({})
                                        .set('data', {})
                                        .set('display', {})
                                        .value();
                                    mappedCard.metrics.data = _({})
                                        .set(dataSetDisplay.name, [metrics])
                                        .value();
                                    mappedCard.metrics.display = _({})
                                        .set('dataSets', [dataSetDisplay])
                                        .value();
                                    if (_.get(card, `properties.metrics.totalSize[${dataIndex}]`)) {
                                        mappedCard.metrics.totalSize = _.get(
                                            card,
                                            `properties.metrics.totalSize[${dataIndex}]`
                                        );
                                    }
                                });
                            } else {
                                mappedCard.metrics = card.properties.metrics;
                            }
                        }

                        if (card.subTitle) {
                            mappedCard.title =
                                mappedCard.title + ' (' + _.get(column, card.subTitle, '') + '%)';
                        }

                        mappedCards.push(mappedCard);
                    });
                } else {
                    mappedCards.push(card);
                }
            });
            return mappedCards;
        }

        /**
         * Get the filter to apply to all "get cards" API calls.
         * This is separate from any board-level filters, which are applied
         * using the `boardDefinition` API param.
         * @returns {Object} a filter definition
         */
        function getCurrentFilter() {
            const filter =
                // If the board slice is loaded and there's a definition in there, use it.
                boardSliceService.isLoaded() && boardSliceService.definition
                    ? boardSliceService.definition
                    : // Otherwise use the definition stored in the root scope, which will have come from
                      // local storage.
                      $rootScope.currentFilter;
            return definitionService.getFilter(filter);
        }
    }
]);
