import template from './index.html';
import controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('reportPills', () => ({
    restrict: 'E',
    scope: {
        board: '<?',
        hierarchy: '=?',
        card: '<?',
        goalCard: '<?',
        isInspectionMode: '<?'
    },
    template,
    controller
}));
