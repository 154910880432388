(function() {
    'use strict';

    angular.module('serviceApp').filter('mlReason', mlReason);

    mlReason.$inject = ['_', 'contentTypeService', '$filter'];
    function mlReason(_, contentTypeService, $filter) {
        /**
         * Utility function for getting a formatted property name and value.
         * @param {*} value - The value of the feature
         * @param {Object} displayOptions - An object containing content type display options
         * @param {String} contentType - The content type of the item we ran a prediction on to get the feature and value
         *                          This is used to detemine whether a property label should have a content type
         *                          label prepended, e.g. Account Name if the item is an opportunity.
         * @returns {Object} - An object with `propertyLabel` and (optionally) `valueString` keys.
         */
        function formatData(property, propertyContentType, value, displayOptions, contentType) {
            const contentTypeDisplayOptions = _.get(displayOptions, propertyContentType);
            // Get display options for the property itself.
            const propertyDisplayOptions = _.get(contentTypeDisplayOptions, [
                'propertyDisplayOptions',
                `${propertyContentType}.${property}`
            ]);
            // Default to start-cased property name, but use the label from display options if we have one.
            let propertyLabel = _.startCase(property);
            let valueString = value;
            if (propertyDisplayOptions) {
                propertyLabel = propertyDisplayOptions.label || propertyLabel;
                // Format the value using any display options we have.
                if (typeof value !== 'undefined' && propertyDisplayOptions.format) {
                    valueString = $filter(propertyDisplayOptions.format)(value, propertyDisplayOptions);
                }
            }
            // If the property is for a different content type than the item, prepend the label with
            // the content type name, using a custom label if we have one.
            if (propertyContentType !== contentType) {
                let contentTypeLabel = _.get(
                    contentTypeDisplayOptions,
                    'label',
                    _.startCase(propertyContentType)
                );
                propertyLabel = `${contentTypeLabel} ${propertyLabel}`;
            }

            return { propertyLabel, valueString };
        }

        let filter = function(reasonJSON, { activityDefinitionLabels = {}, contentType } = {}) {
            // Get the cached display options.  We should always have these loaded by the time
            // we're displaying ML reasons.
            const displayOptions = contentTypeService.getCachedContentDisplayOptions();

            // Parse the reason string into a POJO.
            const reason = (() => {
                try {
                    return JSON.parse(reasonJSON);
                } catch (e) {
                    console.error('Error parsing ml reason string', reasonJSON, e);
                }
            })();
            if (!reason) {
                return null;
            }
            const { type, value } = reason;

            // Format the reason according to the type of feature it is.
            if (type === 'activity') {
                let activityName = reason.activityDefinitionName;
                if (activityDefinitionLabels[activityName]) {
                    activityName = activityDefinitionLabels[activityName];
                } else {
                    activityName = _.startCase(activityName);
                }
                if (_.isNil(value)) {
                    return `No ${activityName} activity`;
                }
                return `Total ${activityName} activity count is ${value}`;
            } else if (type === 'daysInStage') {
                const stageName = reason.stageName;
                if (_.isNil(value)) {
                    return `Has not progressed to ${stageName} stage`;
                }
                if (value === 0) {
                    return `Spent less than a day in ${stageName} stage`;
                }
                return `Spent ${value} day${value === 1 ? '' : 's'} in ${stageName} stage`;
            } else if (type === 'categorical') {
                const { propertyLabel } = formatData(
                    reason.propertyName,
                    reason.contentType || contentType,
                    undefined,
                    displayOptions,
                    contentType
                );
                return `${propertyLabel} ${value == 1 ? 'is' : 'is not'} ${reason.propertyValue}`;
            } else {
                const { propertyLabel, valueString } = formatData(
                    reason.propertyName,
                    reason.contentType || contentType,
                    value,
                    displayOptions,
                    contentType
                );
                return `${propertyLabel} is ${_.isNil(value) ? 'missing' : valueString}`;
            }
        };

        return filter;
    }
})();
