(function() {
    'use strict';

    angular.module('authInterceptor', []).factory('authInterceptor', AuthInterceptor);

    AuthInterceptor.$inject = ['_', '$q', '$cookies', '$injector', '$rootScope', '$location'];

    function AuthInterceptor(_, $q, $cookies, $injector, $rootScope, $location) {
        var factory = {
            response: response,
            responseError: responseError
        };

        return factory;

        //////////////

        function response(response) {
            return response;
        }

        function responseError(response) {
            var stateService = $injector.get('$state');

            if (response.status == 401 && response.config.redirect !== false) {
                $cookies.remove('serviceDomain');

                if (
                    stateService.current.name !== 'core.resetpassword' &&
                    stateService.current.name !== 'core.login'
                ) {
                    stateService.go('core.login');
                    $rootScope.$emit('logout');
                }

                return $q.reject(response);
            }

            if (response.status == 502) {
                const msg = JSON.stringify(response.data);
                console.error('Error', msg);
                return $q.reject(response);
            }

            return $q.reject(response);
        }
    }
})();
