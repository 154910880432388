'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('contentTypeSelector', {
    controller,
    template,
    bindings: {
        board: '<',
        card: '<',
        facets: '<',
        applyFilter: '&'
    }
});
