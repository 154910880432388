'use strict';

import _ from 'lodash';
import stripHtml from 'string-strip-html';

export const removeNestedProperties = prop => {
    return !_.get(prop, 'nested') && prop.type !== 'nested';
};

export const isNumericalProperty = prop => {
    return prop.type === 'integer' || prop.type === 'long' || prop.type === 'double' || prop.type === 'float';
};

export const stripHtmlFromText = text => {
    if (text !== undefined) {
        return stripHtml(text).result;
    }
};

// If the theme ends in `-set`, strip that off
export const themeLabel = theme => {
    if (_.endsWith(theme, '-set')) {
        theme = theme.slice(0, -4);
    }
    return _.startCase(theme);
};
