'use strict';

/**
 * Angular wrapper for our <ContentGridHeaderExternalComponent /> react component.
 */
export default class ContentGridHeader {
    static get $inject() {
        return ['_', '$filter'];
    }

    constructor(_, $filter) {
        this._ = _;
        this.$filter = $filter;
    }

    $onInit() {
        // Props being passed to our react component
        this.hooks = {
            getContentTypeLabel: this.$filter('contentTypeLabel')
        };
        this.data = {
            options: this.options,
            metadata: this.metadata
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'options') && !changes.options.isFirstChange()) {
            this.options = changes.options.currentValue;
            this.data = { ...this.data, options: this.options };
        }

        if (this._.get(changes, 'metadata') && !changes.metadata.isFirstChange()) {
            this.metadata = changes.metadata.currentValue;
            this.data = { ...this.data, metadata: this.metadata };
        }
    }
}
