'use strict';

/**
 * Angular wrapper for our <InsightsError /> react component.
 */

export default class InsightsError {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.data = {
            errorType: this.errorType,
            title: this.title,
            body: this.body
        };
    }
}
