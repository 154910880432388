'use strict';

angular.module('serviceApp').controller('ExternalErrorController', [
    '$rootScope',
    '$scope',
    '$stateParams',
    function($rootScope, $scope, $stateParams) {
        $rootScope.useHeader = false;
        $scope.error_id = $stateParams.id;
        $scope.productName = $rootScope.productName;
    }
]);
