'use strict';

import './index.scss';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('tagItem', () => ({
    restrict: 'E',
    scope: {
        tag: '=',
        readonly: '<?',
        backgroundColor: '<?'
    },
    template,
    controller: Controller
}));
