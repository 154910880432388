(function() {
    'use strict';

    angular.module('serviceApp').directive('showMoreHandler', showMoreHandler);

    function showMoreHandler() {
        var directive = {
            restrict: 'AE',
            replace: true,
            scope: {
                input: '<',
                arrayLimit: '<',
                textLimit: '<'
            },
            template: require('views/tmpl/partials/showMoreHandler.html'),
            controller: ShowMoreHandlerController
        };

        return directive;
    }

    ShowMoreHandlerController.$inject = ['$scope'];

    function ShowMoreHandlerController($scope) {
        $scope.init = init;
        $scope.init();

        function init() {
            $scope.asArray = Array.isArray($scope.input);
        }

        $scope.$watchCollection('input', function() {
            $scope.init();
        });
    }
})();
