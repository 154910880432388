(function() {
    'use strict';

    angular
        .module('sfTypeAhead', ['schemaForm'])
        .config([
            '_',
            'schemaFormProvider',
            'schemaFormDecoratorsProvider',
            'sfBuilderProvider',
            'sfPathProvider',
            function(_, schemaFormProvider, schemaFormDecoratorsProvider, sfBuilderProvider, sfPathProvider) {
                var sfTypeAhead = function(name, schema, options) {
                    if (
                        schema.type === 'string' &&
                        _.isObject(schema.format) &&
                        schema.format.type == 'type-ahead'
                    ) {
                        var f = schemaFormProvider.stdFormObj(name, schema, options);
                        f.key = options.path;
                        f.type = 'type-ahead';
                        options.lookup[sfPathProvider.stringify(options.path)] = f;
                        return f;
                    }
                };

                schemaFormProvider.defaults.string.unshift(sfTypeAhead);

                schemaFormDecoratorsProvider.defineAddOn(
                    'bootstrapDecorator',
                    'type-ahead',
                    'views/tmpl/templates/sfTypeAhead.html',
                    sfBuilderProvider.stdBuilders
                );
            }
        ])
        .run([
            '$templateCache',
            function($templateCache) {
                $templateCache.put(
                    'views/tmpl/templates/sfTypeAhead.html',
                    require('views/tmpl/templates/sfTypeAhead.html')
                );
            }
        ]);
})();
