(function() {
    'use strict';

    angular.module('serviceApp').directive('globalModals', globalModals);

    function globalModals() {
        var directive = {
            restrict: 'E',
            scope: {},
            template: require('views/tmpl/partials/globalModals.html'),
            controller: GlobalModalsController
        };

        return directive;
    }

    GlobalModalsController.$inject = ['_', '$log', '$scope', '$rootScope', 'toastr', 'boardServiceNew'];

    function GlobalModalsController(_, $log, $scope, $rootScope, toastr, boardServiceNew) {
        $scope.modals = {
            createBoardModal: false
        };

        $scope.extras = {};
        $scope.form = {};

        $rootScope.$on('showModal', showModal);
        $rootScope.$on('hideAllModals', hideAllModals);
        $scope.hideModal = hideModal;
        $scope.hideAllModals = hideAllModals;

        $scope.createBoard = createBoard;

        function showModal(event, data) {
            $scope.extras = _.get(data, 'extras');

            if (_.isNil($scope.modals[data.modal])) {
                $log.error('Modal not found', data.modal);
                return;
            }

            $scope.modals[data.modal] = true;
        }

        function hideModal(modal) {
            if (_.isNil($scope.modals[modal])) {
                $log.error('Modal not found', modal);
                return;
            }
            $scope.modals[modal] = false;
        }

        function hideAllModals() {
            $scope.modals = _.forEach($scope.modals, function(modal) {
                modal = false;
                return modal;
            });
        }

        function createBoard() {
            var newBoard = {
                title: $scope.extras.title,
                query: $scope.extras.query
            };
            boardServiceNew.createBoard(newBoard).then(function() {
                toastr.success('Board Created!', '');
                $rootScope.$emit('boardEvent', {
                    message: 'newBoard'
                });
                $scope.modals.createBoardModal = false;
                $rootScope.$emit('hideAllModals');

                _.unset($scope.extras, 'query');
                _.unset($scope.extras, 'title');
            });
        }
    }
})();
