'use strict';
import _ from 'lodash';

/**
 * Is this item starred?
 *
 * @param {object} params
 * @param {object} params.user
 * @param {string} params.boardType
 * @param {string} params.defType
 * @param {string} params.cardDefinitionId
 * @param {string} params.boardDefinitionName
 * @returns {boolean} - Is the item starred?
 */
export const isStarred = ({ user, boardType, defType, cardDefinitionId, boardDefinitionName }) => {
    const starredData = _.get(user, 'state.starredDefinitions') || JSON.stringify({});
    let starredDefs = [];
    try {
        starredDefs = _.get(JSON.parse(starredData), boardType, []);
    } catch (err) {
        console.warn('An error occurred parsing starred items', err);
        return false;
    }

    return starredDefs.some(starredItem => {
        if (!starredItem) {
            return false;
        }
        if (defType === 'card') {
            return (
                starredItem.cardDefinitionId === cardDefinitionId &&
                starredItem.boardDefinitionName === boardDefinitionName
            );
        }
        return starredItem.boardDefinitionName === boardDefinitionName;
    });
};

/**
 * Set an items starred status
 *
 * @param {object} params
 * @param {string} params.boardType
 * @param {string} params.cardDefinitionId
 * @param {string} params.boardDefinitionName
 * @param {string} params.starred
 * @param {string} params.userServiceNew
 * @returns {Promise} - A promise providing the new starred state once the save is completed
 */
export const setStarred = ({ boardType, cardDefinitionId, boardDefinitionName, starred, userServiceNew }) => {
    // Get the latest version of the user to avoid cached data overwritting existing changes
    return userServiceNew.getUser().then(user => {
        let starredData = _.get(user, 'state.starredDefinitions') || JSON.stringify({});
        try {
            starredData = JSON.parse(starredData);
        } catch (err) {
            console.warn('An error occurred parsing starred items', err);
            return Promise.reject('An error occurred parsing starred items');
        }
        let starredDefs = _.get(starredData, boardType, []);

        if (starred) {
            starredDefs = [
                {
                    boardDefinitionName: boardDefinitionName,
                    cardDefinitionId: cardDefinitionId
                },
                ...starredDefs
            ];
        } else {
            starredDefs = starredDefs.filter(
                star =>
                    !(
                        star.cardDefinitionId === cardDefinitionId &&
                        star.boardDefinitionName === boardDefinitionName
                    )
            );
        }
        starredData[boardType] = starredDefs;

        return userServiceNew
            .updateUser({
                id: user.id,
                state: {
                    starredDefinitions: JSON.stringify(starredData)
                }
            })
            .then(
                () => {
                    console.log('Profile Updated!');
                    return starred;
                },
                error => {
                    console.error('An Error Occurred', error.msg);
                }
            );
    });
};
