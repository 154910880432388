'use strict';

/**
 * @ngdoc function
 * @name serviceApp.controller:Page500Ctrl
 * @description
 * # Page500Ctrl
 */
angular.module('serviceApp').controller('Page500Ctrl', [
    '$rootScope',
    function($rootScope) {
        $rootScope.useHeader = false;
    }
]);
