'use strict';

/**
 * Angular wrapper for our <TimeframeFilterTitleSelect /> react component.
 */

export default class TimeframeFilterTitleSelect {
    constructor() {}

    $onInit() {
        const selectedValueChanged = data => {
            this.selectedValue({ data });
        };
        // Props being passed to our react component
        this.data = {
            property: this.property,
            selectedValue: selectedValueChanged,
            card: this.card
        };
    }

    $onChanges() {
        const selectedValueChanged = data => {
            this.selectedValue({ data });
        };
        this.data = {
            property: this.property,
            selectedValue: selectedValueChanged,
            card: this.card
        };
    }
}
