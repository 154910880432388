'use strict';
import _ from 'lodash';
import moment from 'moment';

/**
 * @typedef suppliedScopeProperties
 * @property {Object} action - action item in list
 * @property {Object} [definition] - definition of the action
 * @property {function} toggleContext - function to call when an action item is selected
 * @typedef {ng.IScope & suppliedScopeProperties} $scope - input scope param
 *
 * @typedef _actionItemBaseScope - since we are adding properties to $scope we use a separate type def for the property
 * @property {string} prompt - cleaned version of action prompt
 * @property {string} boardId - board id retrieved from scope.action.content.boardId
 * @property {string} iconUrl - URL at which to fetch the icon for the action item
 * @property {string} defaultIcon - fallback image URL
 * @property {boolean} showContentIcon - true if the content image should be displayed.  Only false for team content
 * @property {function} toggle - fires an event to open the drawer (defaults to left drawer for now)
 * @typedef {$scope & _actionItemBaseScope} actionItemBaseScope
 */

export default class ActionItemBase {
    static get $inject() {
        return ['$scope', '$rootScope', '$timeout', 'userServiceNew', 'actionService'];
    }

    /**
     * @param {$scope} scope
     * @param {$rootScope} rootScope
     * @property {Object} action - the action
     * @property {actionItemBaseScope} scope
     */
    constructor(scope, $rootScope, $timeout, userService, actionService) {
        /** @type {actionItemBaseScope} */
        // @ts-ignore ignoring so type cast doesn't yell about missing props
        this.scope = scope;
        this.userService = userService;
        this.actionService = actionService;
        this.$timeout = $timeout;

        this.scope.payload = {};

        this.scope.isExternal = $rootScope.isExternal;

        this.action = this.scope.action;
        this.scope.boardId = _.get(this.action, 'content.boardId');
        this.scope.isRepair = _.get(this.action, 'name') === 'fix-user-integration';

        this.scope.showActionName = _.get(this.scope, 'options.showActionName', false);
        this.scope.actionName = _.startCase(this.action.displayName || this.action.name);

        this.scope.showStateIndicator = _.get(this.scope, 'options.showStateIndicator', true);
        this.scope.showAssigneeStateInfo = _.get(this.scope, 'options.showAssigneeStateInfo', true);

        this.scope.toggle = this.toggle.bind(this);
        this.scope.openMenu = this.openMenu.bind(this);
        this.scope.handleEventTrigger = this.handleEventTrigger.bind(this);
        this.scope.actionPoller = this.actionPoller.bind(this);

        const snoozeWatcher = this.scope.$watch('payload.snoozeUntil', (newVal, oldVal) => {
            if (oldVal !== newVal && newVal) {
                this.scope.handleEventTrigger('snooze');
            }
        });

        const stateWatcher = this.scope.$watch('action.state', (newVal, oldVal) => {
            if (newVal === oldVal) {
                return;
            }

            if (newVal === 'pending') {
                return this.scope.actionPoller();
            }
        });

        this.scope.$on('$destroy', () => {
            snoozeWatcher();
            stateWatcher();
        });

        this.scope.displayError = runError => {
            const errorMsg = _.head(_.castArray(runError));
            return !!errorMsg && !_.includes(errorMsg, 'No auto-run eventTrigger found');
        };

        if (!_.get(this.scope, 'user')) {
            this.userService.getCachedOrFetch().then(user => {
                this.scope.user = user;
                this.init();
            });
        } else {
            this.init();
        }
    }

    init() {
        this.scope.userIsAdmin = this.isUserAdmin();
        this.scope.userIsAssignee = this.isUserAssignee();
        this.scope.userSnoozedDate = this.getUserSnoozedDate();
        this.scope.allowSnooze = this.shouldAllowSnooze();
        this.scope.allowDismiss = this.shouldAllowDismiss();
        this.scope.allowDismissAll = this.shouldAllowDismissAll();

        // if this is the first item in the list on init
        // open the action details
        if (this.scope.first) {
            this.scope.toggle();
        }
    }

    /**
     * handleEventTrigger - dispatch the event trigger execution
     * @param {string} eventTriggerType - snooze, run, dismiss, etc
     */
    handleEventTrigger(eventTriggerType) {
        const data = {
            data: {
                eventTrigger: eventTriggerType,
                action: this.action,
                snoozeUntil: _.get(this.scope, 'payload.snoozeUntil')
            }
        };

        // currently an optional inclusion so prevent errors
        if (_.isFunction(this.scope.executeEventTrigger)) {
            this.scope.executeEventTrigger(data);
        }
    }

    /**
     * toggle - fire the supplied scope.toggleContext function with the params:
     * - {object} action
     * - {string} [actionId]
     * - {object} [definition]
     */
    toggle() {
        this.scope.toggleContext({
            data: {
                action: this.scope.action,
                actionId: this.scope.action.id,
                definition: this.scope.definition
            }
        });
    }

    /**
     * actionPoller - poll the get action API if the action state is pending
     */
    actionPoller() {
        this.actionService.getAction(this.scope.action.id).then(action => {
            this.scope.action = action;

            if (_.get(this.scope.action, 'state') === 'pending') {
                return this.$timeout(this.scope.actionPoller, 1000);
            } else if (_.get(this.scope.action, 'lastRunError')) {
                return;
            } else {
                this.scope.removeCompletedAction({ data: { actionId: this.scope.action.id } });
                return;
            }
        });
    }

    /**
     * openMenu
     * @return {Array} array of drop down menu items
     */
    openMenu() {
        return [
            this.scope.allowDismiss
                ? {
                      text: 'Dismiss',
                      click: () => {
                          this.scope.handleEventTrigger('dismiss');
                      }
                  }
                : undefined,
            this.scope.allowDismissAll
                ? {
                      text: 'Dismiss for all',
                      click: () => {
                          this.scope.handleEventTrigger('dismiss-all');
                      }
                  }
                : undefined
        ].filter(Boolean);
    }

    /**
     * shouldAllowSnooze - determine if snooze option should be shown
     * @returns {boolean} - true if should allow a user to snooze an action
     */
    shouldAllowSnooze() {
        return this.isUserAssignee();
    }

    /**
     * shouldAllowDismiss - determine if dismiss option should be shown
     * @returns {boolean} - true if should allow a user to dismiss an action
     */
    shouldAllowDismiss() {
        return this.isUserAssignee() && _.get(this.action, 'state') === 'active';
    }

    /**
     * shouldAllowDismissAll - determine if dismiss all option should be shown
     * @returns {boolean} - true if should allow a user to dismiss all an action
     */
    shouldAllowDismissAll() {
        return (this.isUserAssignee() || this.isUserAdmin()) && _.get(this.action, 'state') === 'active';
    }

    /**
     * isUserAdmin - determine if current user is an admin
     * @returns {boolean} - true if current user is an admin
     */
    isUserAdmin() {
        return !!_.get(this.scope, 'user.isOwner');
    }

    /**
     * isUserAssignee - determine if the current user is an assignee
     * @returns {boolean} - true if the current user is an assignee
     */
    isUserAssignee() {
        const lowerUserEmail = _.toLower(_.get(this.scope, 'user.email'));
        const assignees = _.cloneDeep(_.get(this.scope, 'action.assignees', []));
        return !!_.find(assignees, { email: lowerUserEmail });
    }

    /**
     * getUserSnoozedDate - return the snoozed date is the action is snoozed by this user
     * @returns {string} - snooze date if present
     */
    getUserSnoozedDate() {
        const lowerUserEmail = _.toLower(_.get(this.scope, 'user.email'));
        const assignees = _.cloneDeep(_.get(this.scope, 'action.assignees', []));
        const assignee = _.find(assignees, { email: lowerUserEmail });
        const snoozeDate = _.get(assignee, 'snooze');

        if (snoozeDate && moment(snoozeDate).isAfter(moment())) {
            return snoozeDate;
        }
        return;
    }
}
