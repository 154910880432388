import _ from 'lodash';
import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('actionsCarousel', () => {
    return {
        card: '=',
        board: '=',
        user: '<',
        team: '<',
        panel: '<?',
        external: '=?',
        refreshCallbackHitch: '&',
        scope: true,
        template: template,
        controller: Controller,
        link: ($scope, elems, attrs, ctrl) => {
            ctrl.initialize(elems[0]);
            $scope.api = {
                executeEventTrigger: function(data) {
                    $scope.executeEventTrigger(data);
                }
            };
        }
    };
});
