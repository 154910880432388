import template from './index.html';
import './index.scss';
import Controller from './controller';

angular.module('serviceApp').directive('validationError', () => ({
    restrict: 'E',
    scope: {
        form: '=',
        formErrors: '='
    },
    template,
    controller: Controller
}));
