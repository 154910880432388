import { validDateFormats } from '../../scripts/utils/properties';

(function() {
    'use strict';

    angular.module('serviceApp').filter('daysAwayDate', daysAwayDate);

    daysAwayDate.$inject = ['_', 'moment', '$filter'];
    function daysAwayDate(_, $moment, $filter) {
        let filter = function(n) {
            const daysAway = $filter('daysAway')(n);
            const date = $moment(n, validDateFormats, true);

            if (!n || _.isNil(n)) {
                return 'N/A';
            } else if (!date.isValid()) {
                return n;
            }

            // Use mediumDate as that's what we use everywhere else to display dates
            const formattedDate = $filter('mediumDate')(n);
            return `${daysAway} (${formattedDate})`;
        };

        return filter;
    }
})();
