(function() {
    'use strict';

    angular.module('serviceApp').filter('typefilter', typefilter);

    typefilter.$inject = ['_', '$filter', 'moment'];

    function typefilter(_, $filter, $moment) {
        var filter = function(n) {
            if (_.isNil(n)) {
                return 'N/A';
            }

            if (_.isNumber(n)) {
                return '$' + $filter('humanize')(n);
            }

            if (Date.parse(n)) {
                var d = new Date(n);
                return $filter('date')(d);
            }

            return n;
        };

        return filter;
    }
})();
