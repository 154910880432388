'use strict';

/**
 * @ngdoc directive
 * @name serviceApp.directive:pageLoader
 * @description
 * # pageLoader
 */
angular.module('serviceApp').directive('pageLoader', [
    '$timeout',
    function($timeout) {
        return {
            restrict: 'AE',
            template: '<loading-overlay ng-if="loading"></loading-overlay>',
            link: function(scope, element) {
                scope.pendingReqs = 0;
                scope.loading = false;

                scope.startLoader = () => {
                    //if there is another start timer reset it
                    if (scope.timer) {
                        $timeout.cancel(scope.timer);
                    }

                    //if the loader isn't already loading, queue up another pageLoader
                    // to go off after 1 second
                    if (!scope.loading) {
                        scope.timer = $timeout(function() {
                            //if after the time goes off if there are pending requests show loader
                            if (scope.pendingReqs > 0) {
                                scope.loading = true;
                            }
                        }, 500);
                    }
                };

                scope.stopLoader = () => {
                    //if a show loader timer is in flight, stop it.
                    if (scope.timer) {
                        $timeout.cancel(scope.timer);
                    }
                    scope.loading = false;
                    scope.pendingReqs = 0;
                };

                let transitionPreventedListener = scope.$root.$on('transitionPrevented', function(event) {
                    scope.stopLoader();
                });

                scope.$on('$destroy', function() {
                    transitionPreventedListener();
                });

                //watch changes on the pending requests, stop the loader if they get to zero
                scope.$watch('pendingReqs', function() {
                    if (scope.pendingReqs < 1) {
                        scope.stopLoader();
                    }
                });

                scope.$on('$stateChangeStart', function() {
                    scope.startLoader();
                });
                scope.$on('longAPI', function() {
                    scope.startLoader();
                });
                scope.$on('longAPIEnd', function(event) {
                    scope.stopLoader();
                });

                scope.$on('actionLoaderStart', function() {
                    scope.pendingReqs++;
                    scope.startLoader();
                });
                scope.$on('actionLoaderEnd', function(event) {
                    scope.pendingReqs--;
                    if (scope.pendingReqs === 0) {
                        scope.stopLoader();
                    }
                });

                scope.$on('$stateChangeSuccess', function(event) {
                    event.targetScope.$watch('$viewContentLoaded', function() {
                        $timeout(function() {
                            scope.stopLoader();
                        }, 600);
                    });
                });

                scope.$on('$stateChangeCancel', function(event) {
                    scope.stopLoader();
                });
            }
        };
    }
]);
