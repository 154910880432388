SignalsTimelineController.$inject = ['_', '$scope', '$timeout'];

function SignalsTimelineController(_, $scope, $timeout) {
    $scope.today = new Date();
    $scope.today = $scope.today.setHours(0, 0, 0, 0);

    $scope.isLoading = true;

    $scope.$watch('timeline', function() {
        $scope.isLoading = true;
        init();
    });

    function init() {
        $scope.signals = [];
        if (_.isArray($scope.timeline.data)) {
            $scope.timeline.data = _.first($scope.timeline.data);
        }

        _.forEach(_.cloneDeep(_.get($scope.timeline, 'data.signals')), function(signal) {
            var startSignal = _.cloneDeep(signal);

            startSignal = _(startSignal)
                .set('displayDate', signal.end ? signal.end : $scope.today)
                .set(
                    'sortDate',
                    signal.durationFromEnd > 0 ? signal.durationFromEnd : new Date().getMilliseconds()
                )
                .value();

            _.set(startSignal, 'compareDate', new Date(startSignal.displayDate).setHours(0, 0, 0, 0));

            $scope.signals.push(startSignal);
        });

        $scope.signals = _($scope.signals)
            .sortBy('sortDate')
            .value();

        $scope.signals = _.forEach($scope.signals, function(signal) {
            var index = _.findIndex($scope.signals, signal);
            var previous = _.get($scope.signals, index - 1, undefined);
            if (
                (previous && previous.compareDate == signal.compareDate) ||
                signal.compareDate == $scope.today
            ) {
                _.unset(signal, 'displayDate');
            }

            return signal;
        });

        $scope.isLoading = false;
    }
}

export default SignalsTimelineController;
