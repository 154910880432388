'use strict';

/**
 * Angular wrapper for our <FilterGroupEditorExternalComponent /> react component.
 */
export default class FilterGroupEditor {
    static get $inject() {
        return ['$filter'];
    }

    constructor($filter) {
        this.$filter = $filter;
    }

    $onInit() {
        // Props being passed to our react component
        this.hooks = {
            getContentTypeLabel: this.$filter('contentTypeLabel'),
            getPropertyLabel: this.$filter('propertyLabel'),
            onDefinitionUpdated: this.onDefinitionUpdated()
        };

        // If the definition is a $resource object, get the raw object first.
        // Using $resource objects in React leads to inconsistent update behavior.
        if (this.definition && this.definition.toJSON) {
            this.definition = this.definition.toJSON();
        }
        this.data = {
            definition: this.definition && _.cloneDeep(this.definition),
            section: this.section,
            onlyWithHistory: this.onlyWithHistory,
            ownerName: this.ownerName
        };
    }

    $onChanges(changes) {
        if (changes.ownerName) {
            this.ownerName = changes.ownerName.currentValue;
            this.data = { ...this.data, ownerName: this.ownerName };
        }
    }
}
