'use strict';

import _ from 'lodash';

export default class AssignToUserController {
    static get $inject() {
        return ['$scope', 'actionService', 'toastr'];
    }

    constructor(scope, actionService, toastr) {
        this.scope = scope;
        this.toastr = toastr;
        this.actionService = actionService;
        this.scope.assignToUser = this.assignToUser.bind(this);

        this.scope.promptString = this.getPromptString();
    }

    assignToUser() {
        const actionId = _.get(this.scope, 'action.id');
        const userId = _.get(this.scope, 'payload.userId');
        this.scope.buttonDisabled = true;
        this.actionService
            .assignToUser({ userId, actionId })
            .then(newAction => {
                // the parent of this component should have a listen on `redraw-action-form` that will force a redraw
                this.scope.$emit('redraw-action-form', { action: newAction });
            })
            .catch(e => {
                this.toastr.error('An error occurred while reassigning this action', _.get(e, 'msg', e));
            })
            .finally(() => {
                this.scope.buttonDisabled = false;
            });
    }

    getPromptString() {
        const assignees = _.get(this.scope, 'action.assignees');
        let promptString = 'This action is assigned to';

        const getEmailOrName = assignee => _.get(assignee, 'name', _.get(assignee, 'email'));

        if (_.size(assignees) === 1) {
            promptString = `${promptString} ${getEmailOrName(_.first(assignees))}`;
        }

        if (_.size(assignees) === 2) {
            promptString = `${promptString} ${getEmailOrName(_.first(assignees))} and ${getEmailOrName(
                _.last(assignees)
            )}`;
        }

        if (_.size(assignees) === 3) {
            promptString = `${promptString} ${getEmailOrName(_.first(assignees))}, ${getEmailOrName(
                _.last(assignees)
            )} and 1 other`;
        }

        if (_.size(assignees) > 3) {
            promptString = `${promptString} ${getEmailOrName(_.first(assignees))}, ${getEmailOrName(
                _.last(assignees)
            )} and ${_.size(assignees) - 2} others`;
        }

        return `${promptString}.`;
    }
}
