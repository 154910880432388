'use strict';

import template from './index.html';
import controller from './controller';

angular.module('serviceApp').component('defaultViewToggle', {
    controller,
    template,
    bindings: {
        selectedView: '<',
        cardName: '<'
    }
});
