'use strict';

import _ from 'lodash';

export default class Controller {
    // angular dependency injector
    static get $inject() {
        return ['$scope', '$rootScope', 'actionService', 'toastr', '$q'];
    }
    constructor($scope, $rootScope, actionService, toastr, $q) {
        this.scope = $scope;
        this.actionService = actionService;
        this.toastr = toastr;
        this.rootScope = $rootScope;
        this.promise = $q;
        this.scope.loading = false;

        this.scope.menuOptions = [];
        this.getMenuOptions = this.getMenuOptions.bind(this);
        this.scope.getMenuOptions = this.getMenuOptions;
        this.toggleInstantAction = this.toggleInstantAction.bind(this);
    }

    toggleInstantAction(action) {
        if (this.scope.openDrawer) {
            this.scope.$root.$emit('leftDrawer:close');
        }

        this.scope.openDrawer = 'instantActions';
        this.scope.$root.$emit('leftDrawer:open', {
            layout: 'instantActions',
            action: action,
            board: this.scope.board,
            content: this.scope.content,
            external: this.rootScope.isExternal
        });
    }
    getMenuOptions() {
        return this.promise((resolve, reject) => {
            let contentType = this.scope.type;
            let contentId = _.get(this.scope, 'content.id');
            if (_.isEmpty(this.scope.menuOptions)) {
                this.actionService
                    .listInstantActions({
                        contentType,
                        contentId
                    })
                    .then(actions => {
                        if (!actions || _.isEmpty(actions)) {
                            this.scope.menuOptions = [
                                {
                                    sectionData: [{ text: 'No Actions Available' }]
                                }
                            ];
                        } else {
                            const actionMenuOptions = _(actions)
                                .map(action => {
                                    let actionItem = {};
                                    actionItem.text = action.description || action.displayName;
                                    actionItem.onClick = () => {
                                        this.toggleInstantAction(action);
                                    };
                                    return actionItem;
                                }, {})
                                .sortBy('text')
                                .value();
                            this.scope.menuOptions = [
                                {
                                    sectionData: actionMenuOptions
                                }
                            ];
                        }
                        return resolve(this.scope.menuOptions);
                    })
                    .catch(e => {
                        this.toastr.error('An error occurred - please try again', '');
                        return reject(e);
                    });
            } else {
                return resolve(this.scope.menuOptions);
            }
        });
    }
}
