'use strict';

import './index.scss';
import './property-tag-input';

import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('propertyTags', () => ({
    restrict: 'E',
    scope: {
        content: '=?',
        onChangeTags: '&'
    },
    template,
    controller: Controller
}));
