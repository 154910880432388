// Override for the Angular date filter.
// This falls back to the default Angular date filter _except_ when
// it's using the `mediumDate` option, in which case we forward to
// our custom `mediumDate` filter instead so we can run some tz logic.
(function() {
    'use strict';

    angular.module('serviceApp').config([
        '$provide',
        function($provide) {
            $provide.decorator('dateFilter', [
                '$delegate',
                '$filter',
                function($delegate, $filter) {
                    var srcFilter = $delegate;

                    var extendsFilter = function() {
                        const value = arguments[0];
                        // If we don't have a second argument (the format), Angular defaults
                        // to `mediumDate`.  If that's the case, forward to our mediumDate filter.
                        // This helps consolidate the tz logic into that code.
                        if (!arguments[1] || arguments[1] === 'mediumDate') {
                            return $filter('mediumDate').apply(this, arguments);
                        }
                        return srcFilter.apply(this, arguments);
                    };

                    return extendsFilter;
                }
            ]);
        }
    ]);
})();
