(function() {
    'use strict';

    angular.module('serviceApp').filter('inOut', inOut);

    function inOut() {
        const IN = 'IN';
        const OUT = 'OUT';

        return value => {
            return value === true || value === 'true' ? IN : OUT;
        };
    }
})();
