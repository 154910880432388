'use strict';

import template from './index.html';
import controller from './controller';

angular.module('serviceApp').directive('embeddedMenu', () => {
    return {
        scope: {
            board: '<',
            user: '<',
            panel: '<'
        },
        link: function($scope) {
            $scope.api = {
                refresh: function(definition) {
                    $scope.toggleBoard(definition);
                }
            };
        },
        template: template,
        controller
    };
});
