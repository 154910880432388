import _ from 'lodash';

(function() {
    'use strict';
    angular.module('serviceApp').directive('appContentProperties', appContentProperties);
    function appContentProperties() {
        var directive = {
            restrict: 'E',
            scope: {
                policy: '=',
                form: '=',
                newSchema: '=',
                contentSchema: '=',
                metadata: '<',
                appId: '<',
                integrationType: '<'
            },
            template: require('views/tmpl/partials/appOptions/appContentProperties.html'),
            controller: AppContentProperties
        };

        return directive;
    }

    // The prefix that new schema properties should have.  This is used to create the payload
    // to the "update content type" API, and looked for by the API handler, so don't change
    // this without changing the analogous constant in rest-server!
    const NEW_PROPERTY_PREFIX = '<newprop>';

    AppContentProperties.$inject = [
        '_',
        '$scope',
        '$rootScope',
        '$timeout',
        'contentTypeService',
        'i18nService',
        'appSettingsService'
    ];

    function AppContentProperties(
        _,
        $scope,
        $rootScope,
        $timeout,
        contentTypeService,
        i18nService,
        appSettingsService
    ) {
        $scope.productName = $rootScope.productName;

        $scope.supportedCurrencies = i18nService.getSupportedISOCurrencies();

        $scope.filteredMetadata = _.clone($scope.metadata);

        $scope.docURL = `http://${$rootScope.productSupportHostName}/hc/en-us/articles/13345312427291-ML-Property-Mapping`;

        $scope.propertyMappingMetadataHash = {};

        function filterMetadataByType(metadata, dataType) {
            return _.filter(metadata, item => {
                let platformDataType = _.get(item, 'contentProperty.platformDataType', null);
                return !platformDataType || _.includes(_.castArray(platformDataType), dataType) || !dataType;
            });
        }

        $scope.populateType = function(meta) {
            if (meta) {
                // platformDataType could be an array of multiple values, default we set it to the first one
                let platformDataType = _.get(meta, 'contentProperty.platformDataType', null);
                if (platformDataType) {
                    $scope.newContent.type = _.castArray(platformDataType)[0];
                    $scope.handleDataTypeSet();
                }
            }
        };

        $scope.propertyMappingExternalComponent = function(open, content) {
            if (content) {
                $scope.propertyMappingContentType = content.contentType;
                $scope.propertyMappingName = content.name;
            }
            $timeout(() => {
                $scope.propertyMappingDialogOpen = open;
            });
        };

        $scope.handleDataTypeSet = function() {
            let dataType = _.get($scope.newContent, 'type');
            $scope.filteredMetadata = filterMetadataByType($scope.metadata, dataType);
            $scope.isDate = dataType === 'date';
            $scope.isBoolean = dataType === 'boolean';
            $scope.isString = dataType === 'string';
            $scope.isInteger = dataType === 'integer';
            $scope.isLong = dataType === 'long';
            $scope.isDouble = dataType === 'double';
            $scope.isFloat = dataType === 'float';
            if ($scope.isBoolean) {
                _.set($scope.newContent, 'searchable', false);
            }
            if (!$scope.isString) {
                _.set($scope.newContent, 'encrypted', false);
            }

            $scope.isEncrypted = _.get($scope.newContent, 'encrypted');
            if ($scope.isEncrypted) {
                _.set($scope.newContent, 'searchable', false);
                _.set($scope.newContent, 'trackChangesOnServer', false);
                _.set($scope.newContent, 'display.hidden', true);
            }
        };

        $scope.propertyMappingDialog = function(open, isNew, content) {
            if (content) {
                // The prefix is everything up to the last dot.
                // e.g. for 'salesforceEvent.meeting.name' it would be 'salesforceEvent.meeting'
                // We'll use this to remove the prefix in the displayed value in the input.
                const name = content.name;
                $scope.prepend = name
                    .split('.')
                    .slice(0, -1)
                    .join('.');
                $scope.newContent = content;
                $scope.handleDataTypeSet();
                $scope.propertyMapping = {};
                $scope.isNew = isNew;
                $scope.isEdit = true;

                // when the requiredContentType is disabled, add helper text
                if (
                    _.has($scope.newContent, 'requiredContentTypeEnabled') &&
                    !$scope.newContent.requiredContentTypeEnabled
                ) {
                    _.set($scope, 'showDisabledText', true);
                    const requiredContentTypeList = _.join(
                        _.get($scope.newContent, 'requiredContentType'),
                        ', '
                    );
                    _.set($scope, 'requiredContentTypeList', requiredContentTypeList);
                } else {
                    _.set($scope, 'showDisabledText', false);
                    _.unset($scope, 'requiredContentTypeList');
                }

                // Since both currency and currencyWithDecimals use the same filter (currency) we've gotta do some coercion when this drawer loads
                // The format is saved as currency, but the UI needs to show the correct selection
                if (
                    _.get($scope.newContent, 'display.format') === 'currency' &&
                    _.get($scope.newContent, 'display.minimumFractionDigits')
                ) {
                    _.set($scope.newContent, 'display.format', 'currencyWithDecimals');
                }

                // Since both percentage and percentWithColorCoding use the same filter (percentage) work out which this is
                if (
                    _.get($scope.newContent, 'display.format') === 'percent' &&
                    _.get($scope.newContent, 'display.colorCodedPercentages')
                ) {
                    _.set($scope.newContent, 'display.format', 'percentWithColorCoding');
                }

                // This is a hack to make the form validate; otherwise the initial name value (which has dots)
                // causes an initial invalid state until the form is dirtied.
                // TODO -- is there a less hacky solution here?
                $scope.newContent.name = '';
                $timeout(() => {
                    $scope.newContent.name = name;
                });
            } else {
                $scope.prepend = NEW_PROPERTY_PREFIX;
                $scope.newContent = {};
                $scope.propertyMapping = {};
                $scope.isNew = isNew;
                $scope.isEdit = false;
                $scope.isString = false;
                $scope.filteredMetadata = _.clone($scope.metadata);
            }
            $timeout(() => {
                $scope.propertyDialogOpen = open;
            });
        };

        $scope.defaultLabel = function() {
            return _.startCase(_.last(_.get($scope, 'newContent.name', '').split('.')));
        };

        //Map property type to a readable string that matches the dropdown type.
        $scope.typeValue = function(type) {
            let typeValue;
            switch (type) {
                case 'string':
                    typeValue = 'String';
                    break;
                case 'boolean':
                    typeValue = 'Boolean';
                    break;
                case 'date':
                    typeValue = 'Date';
                    break;
                case 'integer':
                    typeValue = 'Integer';
                    break;
                case 'long':
                    typeValue = 'Number';
                    break;
                case 'double':
                    typeValue = 'Number with Decimal';
                    break;
                default:
                    typeValue = type;
            }
            return typeValue;
        };

        $scope.getMaxInputValue = content => {
            const inputType = _.get(content, 'display.inputType');
            const definedMaxInputLength = _.get(content, `display.maxInputLength`);
            let maxInputLength;
            // -1 means "no max length" but cannot use null is content types
            if (definedMaxInputLength === -1) {
                maxInputLength = undefined;
            } else if (!_.isUndefined(definedMaxInputLength)) {
                maxInputLength = definedMaxInputLength;
            } else if (inputType === 'textAreaSmall') {
                maxInputLength = 255;
            } else if (inputType === 'textAreaLarge') {
                maxInputLength = 100000;
            } else {
                maxInputLength = 255;
            }

            _.set(content, 'display.maxInputLength', maxInputLength);

            return {
                get value() {
                    return maxInputLength;
                },
                set value(val) {
                    maxInputLength = val;
                    if (_.isNil(val)) {
                        // if no max length change it to -1 for content type limitations
                        // backend handles this translation
                        _.set(content, 'display.maxInputLength', -1);
                    } else {
                        _.set(content, 'display.maxInputLength', maxInputLength);
                    }
                }
            };
        };

        /**
         * Given an updated type of property, update the other configuration options to be appropriate
         * given that type. For example, boolean properties can't be searchable.
         *
         * @param {String} type The new type of the content property.
         */
        $scope.checkType = function(type) {
            if (type === 'boolean') {
                $scope.isBoolean = true;
                _.set($scope.newContent, 'searchable', false);
            } else {
                $scope.isBoolean = false;
            }

            $scope.isInteger = type === 'integer';
            $scope.isLong = type === 'long';
            $scope.isDouble = type === 'double';
            $scope.isFloat = type === 'float';

            if (type === 'date') {
                $scope.isDate = true;
                _.set($scope.newContent, 'searchable', false);
            } else {
                $scope.isDate = false;
            }

            if (type === 'string') {
                $scope.isString = true;
            } else {
                $scope.isString = false;
                _.set($scope.newContent, 'encrypted', false);
            }

            $scope.filteredMetadata = filterMetadataByType($scope.metadata, type);
        };

        $scope.changeHidden = function() {
            $scope.form.$setDirty();
        };

        $scope.checkEncrypted = function(content) {
            if (_.get(content, 'encrypted') === true) {
                $scope.isEncrypted = true;
                _.set($scope.newContent, 'searchable', false);
                _.set($scope.newContent, 'trackChangesOnServer', false);
                _.set($scope.newContent, 'display.hidden', true);
            } else {
                $scope.isEncrypted = false;
            }
            $scope.form.$setDirty();
        };

        function mergePropertyMapping() {
            let mapping = $scope.propertyMapping;
            if (mapping.mode === 'externalName') {
                let read = _.get(mapping, 'externalName');
                let write = _.get(mapping, 'externalWriteName');
                let advanced = _.get(mapping, 'advanced', {});

                if (read && read !== 'advancedModeInternal') {
                    advanced['externalName'] = read;
                }
                if (write && write !== 'advancedModeInternal') {
                    advanced['externalWriteName'] = write;
                }
                $scope.newContent.source = advanced;
                if (!_.get(mapping, 'advanced') && !_.get($scope.newContent.source, 'externalName')) {
                    let errMessage = 'Please select a read property';
                    $scope.propertyMapping.error = true;
                    $scope.propertyMapping.errorMessage = errMessage;
                    throw new Error(errMessage);
                }
            } else {
                $scope.newContent.source = _.get(mapping, 'advanced', {});
            }
        }

        $scope.savePropertyMapping = function() {
            try {
                mergePropertyMapping();
            } catch (e) {
                console.error(e);
                return;
            }

            $scope.propertyMapping.error = false;

            // Since both currency and currencyWithDecimals use the same filter (currency) we've gotta do some coercion on save
            // Format needs to be saved as currency but with the correct value for fractionDigits
            // Also need to be sure to reset those in case format was changed to currency after currencyWithDecimals
            if ($scope.newContent.display.format === 'currencyWithDecimals') {
                _.set($scope.newContent, 'display.minimumFractionDigits', 2);
                _.set($scope.newContent, 'display.maximumFractionDigits', 2);
                _.set($scope.newContent, 'display.format', 'currency');
            } else {
                _.unset($scope.newContent, 'display.minimumFractionDigits');
                _.unset($scope.newContent, 'display.maximumFractionDigits');
            }

            // Since both percent and percentWithColorCoding use the same filter (percent) we need to do some coercion on save
            // We currently only support one color order ('ascending') but more may be added later
            if ($scope.newContent.display.format === 'percentWithColorCoding') {
                _.set($scope.newContent, 'display.colorCodedPercentages', 'ascending');
                _.set($scope.newContent, 'display.format', 'percent');
            } else {
                _.unset($scope.newContent, 'display.colorCodedPercentages');
            }

            if (!$scope.isEdit) {
                $scope.newSchema.unshift({
                    contentType: $scope.policy.contentType,
                    enabled: $scope.newContent.enabled || false,
                    encrypted: $scope.newContent.encrypted,
                    name: $scope.newContent.name,
                    searchable: $scope.newContent.searchable || false,
                    writeable: $scope.newContent.writeable,
                    trackChangesOnServer: $scope.newContent.trackChangesOnServer,
                    display: $scope.newContent.display,
                    source: $scope.newContent.source,
                    teamId: '',
                    type: $scope.newContent.type,
                    mlType: $scope.newContent.mlType
                });
            }

            $scope.form.$setDirty();
            $scope.propertyDialogOpen = false;
            $scope.isEdit = false;
        };

        $scope.changeTags = function() {
            $scope.form.$setDirty();
        };

        $scope.formatSummary = function(obj) {
            var returnVal;
            _.forIn(obj, function(value, key) {
                if (key === 'externalName') {
                    returnVal = value;
                }
            });
            return returnVal ? returnVal : JSON.stringify(obj);
        };

        $scope.formatPropertyName = function(name) {
            if (_.startsWith(name, `${NEW_PROPERTY_PREFIX}.`)) {
                return _.replace(name, `${NEW_PROPERTY_PREFIX}.`, '') + ' (new)';
            }
            return name;
        };

        $scope.getContentTypeDisplayName = function(name) {
            return contentTypeService.getContentTypePrettyName(name, true);
        };

        $scope.removeMapping = function(index) {
            _.pullAt($scope.newSchema, index);
        };

        $scope.isNotBuiltin = type => {
            return _.size(_.split(type, '.')) === 2;
        };

        $scope.checkValid = function(name) {
            const contentSchema = $scope.contentSchema;
            const contentType = _.get(contentSchema, 'externalId.contentType');
            const baseContentType = _.get(contentSchema, 'externalId.extends', contentType);
            const newProperty = name.replace($scope.prepend, baseContentType);

            if (_.get($scope.contentSchema, newProperty)) {
                $scope.form.$setValidity('required', false);
                $scope.isDupe = true;
            } else {
                $scope.form.$setValidity('required', true);
                $scope.isDupe = false;
            }
        };

        /**
         * Check if the externalName, if configured, has metadata indicating that the
         * integration is tracking history for it.
         *
         * @param {Object} propertyMapping The property mapping metadata.
         */
        $scope.isHistoryTracked = function(propertyMapping) {
            if (!propertyMapping || !propertyMapping.externalName) {
                return false;
            }
            const metadata = _.find($scope.metadata, { name: propertyMapping.externalName });
            return metadata && _.get(metadata, 'contentProperty.isHistoryTracked');
        };

        $scope.$watch(
            'propertyMapping',
            function(newValue) {
                if ($scope.newContent && $scope.isHistoryTracked(newValue)) {
                    $scope.newContent.trackChangesOnServer = false;
                }
            },
            true
        );

        /**
         * Generate hash to check for content that has propertyMapping metadata
         * This is used to conditional rendering 'Map Values' button
         */
        $scope.$watch('metadata', function(newValues) {
            if (_.isEmpty(newValues)) {
                return;
            }
            $scope.propertyMappingMetadataHash = _.reduce(
                newValues,
                (hash, newValue) => {
                    if (!_.isEmpty(newValue.propertyValueMapping)) {
                        return {
                            [newValue.name]: true
                        };
                    }
                    return hash;
                },
                {}
            );
        });
    }
})();
