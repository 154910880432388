PanelSearchController.$inject = ['_', '$scope', 'storageService', 'themeService'];

function PanelSearchController(_, $scope, storageService, themeService) {
    if (_.get($scope.panel, 'layout') === 'horizontal') {
        $scope.currentView =
            _.get($scope, ['facets', 'panel', $scope.panel.name, 'view']) ||
            _.get($scope.panel, 'facetGroups.view.default', 'list');
    }

    const panelBackground = _.get($scope, 'panel.background');
    $scope.searchInputVariant = panelBackground ? (panelBackground === 'white' ? 'light' : 'dark') : null;

    $scope.themeService = themeService;
    $scope.group = _.get($scope.board, 'state.currentGroup', 'main');
    $scope.boardSearchView = _.get($scope.search, 'query', undefined);

    const queryLabel = _.get($scope.boardSearchView, 'label');
    if (queryLabel) {
        $scope.placeholder = queryLabel;
    } else {
        let filterDataPlaceholder = _.get($scope.filterData, 'title');
        let boardTitle = _.get($scope.board, 'title');
        let boardSubtitle = _.get($scope.board, 'properties.subTitle');
        $scope.searchTitle = filterDataPlaceholder || boardSubtitle || boardTitle;
        $scope.placeholder = $scope.searchTitle
            ? `Search ${$scope.searchTitle}...`
            : `Search ${_.get($scope.card, 'title')}...`;
    }

    $scope.query = $scope.query || {};

    $scope.$watch(
        'filterData.definition.propertySchema.filter',
        () => {
            const currentFilters = Object.keys(
                _.get($scope, 'filterData.definition.propertySchema.filter', {})
            ).filter(filter => filter !== '_display');

            $scope.isZeroState = _.isEmpty(currentFilters) && !_.isEmpty($scope.pageData);
        },
        true
    );

    //Used for the support of a boolean switch facet.
    $scope.switch = {};
    const panelFacets = _.get($scope, `facets.panel.${_.get($scope, 'panel.name')}`);
    _.forEach(_.get($scope, 'panel.facetGroups', []), (facetData, facetKey) => {
        const value = _.get(facetData, 'facet.value');
        if (_.get(panelFacets, facetKey) === value) {
            $scope.switch.toggleEnabled = true;
        }
    });

    if ($scope.cardSearch) {
        $scope.filters = _.get($scope, `board.panelGroups["${_.get($scope, 'group')}"].filters`);
    }

    //used to determine if a board is capable of using filters. otherwise use the classic search
    $scope.filterSearch = !_.isEmpty(_.get($scope.board, 'definition.filter'), []) || $scope.editableView;

    var queryWatchInit = false;
    var queryWatcher = $scope.$watch(
        'query',
        function() {
            if (queryWatchInit) {
                $scope.searchPanel();
            } else {
                queryWatchInit = true;
            }
        },
        true
    );

    $scope.$on('$destroy', function() {
        queryWatcher();
    });

    $scope.getIcon = name => {
        return `/images/static/icon-${name}.svg`;
    };

    $scope.searchPanel = () => {
        // when the query changes we need to reset the offset to 0
        if ($scope.cardNames) {
            _.forEach(_.castArray($scope.cardNames), cardName => {
                if (_.has($scope.facets, ['card', cardName, 'offset'])) {
                    _.set($scope.facets, ['card', cardName, 'offset'], 0);
                }
            });
        }

        if ($scope.card && _.has($scope.facets, ['card', $scope.card.name, 'offset'])) {
            _.set($scope.facets, ['card', $scope.card.name, 'offset'], 0);
        }

        // If a cards container search, we should not get the whole panel just the one card
        if ($scope.cardNames && $scope.getCardCallback) {
            $scope.getCardCallback({
                data: {
                    query: $scope.query,
                    facets: $scope.facets,
                    interrupt: true,
                    hierarchy: $scope.hierarchy,
                    cardName: $scope.cardNames
                }
            });
        } else {
            $scope.searchCallback({
                query: $scope.query,
                facets: $scope.facets,
                definition: undefined,
                hierarchy: $scope.hierarchy
            });
        }
    };

    $scope.searchCard = data => {
        $scope.query.str = data;
        $scope.$apply();
    };

    $scope.openFilterEditor = function() {
        var defaultFilter = _.get($scope, 'filters.default');
        $scope.$root.$emit('rightDrawer:open', {
            layout: 'filterEditor',
            definition: {
                name: defaultFilter,
                boardId: _.get($scope, 'board.id')
            },
            team: $scope.team,
            user: $scope.user,
            drawerClass: 'skinny'
        });
    };

    $scope.removeCurrentFilter = function() {
        $scope.$emit('filter:removeCurrentFilter');
    };

    $scope.setViewToggle = function(facetKey, facetItem) {
        $scope.currentView = facetItem.label;
        _.set($scope, `facets.panel.${$scope.panel.name}.${facetKey}`, facetItem.value);

        // Remember this for the next time we come to this board
        storageService.set(`${$scope.board.id}-facets`, JSON.stringify($scope.facets), null, 'local');

        $scope.searchPanel(null, $scope.facets);
    };

    //This allows the search to support a boolean toggle switch facet.
    // We already support and icon selector and a dropdown menu selector facet.
    $scope.setSwitchToggle = function(facetKey, facetItem) {
        if (_.get($scope.switch, 'toggleEnabled')) {
            _.set($scope, `facets.panel.${$scope.panel.name}.${facetKey}`, facetItem.value);
        } else {
            _.unset($scope, `facets.panel.${$scope.panel.name}.${facetKey}`);
        }

        $scope.searchPanel(null, $scope.facets);
    };

    $scope.startCase = input => _.startCase(input);
}

export default PanelSearchController;
