'use strict';

/**
 * @ngdoc directive
 * @name serviceApp.directive:inputPercent
 * @description
 * # inputPercent
 */
/* jshint ignore:start */
angular.module('serviceApp').directive('inputPercent', [
    '_',
    '$filter',
    '$window',
    function(_, $filter, $window) {
        //Support whole number percentiles(1 - 100)
        let decimalConverter = rawValue => {
            if (rawValue == '') {
                return 0;
            }
            //Convert value to number, may be Integer, Float or String.
            const numericValue = _.toNumber(rawValue);

            //Invalid format of either not decimal notation(e.g between .01 to 1.00) nor raw notation(e.g. between 1 and 100)
            //Or not a number
            if (_.isNaN(numericValue) || !_.isNumber(numericValue)) {
                //return the raw value
                return 0;
            }

            return numericValue;
        };

        let parser = viewValue => {
            return _.clamp(decimalConverter(viewValue), 0, 100);
        };

        let formatter = modelValue => {
            if (!_.isUndefined(modelValue)) {
                return $filter('number')(decimalConverter(modelValue), 0);
            }
        };
        return {
            require: 'ngModel',
            scope: { model: '=ngModel' },
            link: function(scope, ele, attr, ctrl) {
                ctrl.$parsers.unshift(parser);
                ctrl.$formatters.unshift(formatter);
                ele.on('blur', function() {
                    if (!_.isUndefined(ctrl.$modelValue)) {
                        ele.val($filter('number')(ctrl.$modelValue, 0));
                    }
                });

                ele.on('click', function() {
                    if (!$window.getSelection().toString()) {
                        //This is a fix for the selection range of type number setting the selection range error based on the functionality
                        //of HMTL inputs.
                        this.type = 'text';
                        this.setSelectionRange(0, this.value.length);
                        this.type = 'number';
                    }
                });
            }
        };
    }
]);
/* jshint ignore:end */
