(function() {
    'use strict';

    angular
        .module('sfDate', ['schemaForm'])
        .config([
            '_',
            'schemaFormProvider',
            'schemaFormDecoratorsProvider',
            'sfBuilderProvider',
            'sfPathProvider',
            function(_, schemaFormProvider, schemaFormDecoratorsProvider, sfBuilderProvider, sfPathProvider) {
                var sfDate = function(name, schema, options) {
                    if (_.get(schema, 'type') === 'date' && _.get(schema, 'format.type') !== 'smart-input') {
                        var f = schemaFormProvider.stdFormObj(name, schema, options);
                        f.key = options.path;
                        f.type = 'date';
                        options.lookup[sfPathProvider.stringify(options.path)] = f;
                        return f;
                    }
                };

                if (_.isEmpty(schemaFormProvider.defaults.date)) {
                    schemaFormProvider.defaults.date = [];
                }
                schemaFormProvider.defaults.date.unshift(sfDate);

                schemaFormDecoratorsProvider.defineAddOn(
                    'bootstrapDecorator',
                    'date',
                    'views/tmpl/templates/sfDate.html',
                    sfBuilderProvider.stdBuilders
                );
            }
        ])
        .run([
            '$templateCache',
            function($templateCache) {
                $templateCache.put(
                    'views/tmpl/templates/sfDate.html',
                    require('views/tmpl/templates/sfDate.html')
                );
            }
        ]);
})();
