'use strict';

import _ from 'lodash';

/**
 * card definition management is used to view the definitions list and toggle definition editor
 *
 * @export
 * @class Controller
 */
export default class Controller {
    // angular dependency injector
    static get $inject() {
        return [
            '_',
            '$scope',
            'definitionService',
            '$rootScope',
            '$location',
            '$state',
            '$anchorScroll',
            '$timeout',
            '$stateParams',
            'toastr'
        ];
    }

    constructor(
        _,
        $scope,
        definitionService,
        $rootScope,
        $location,
        $state,
        $anchorScroll,
        $timeout,
        $stateParams,
        toastr
    ) {
        $scope.listOptions = {
            listType: 'list',
            isCollapsible: false,
            showContextByDefault: false,
            isTogglable: false,
            gridMargins: [0, 0]
        };

        this.toastr = toastr;
        this.teamId = _.get($scope.user, 'state.currentTeam');
        this.targetId = _.get($state, 'params.targetId');
        this.definitionType = _.get($scope, 'facets.definitionType');

        this.definitionService = definitionService;
        this.scope = $scope;
        this.rootScope = $rootScope;
        this.timeout = $timeout;
        this.state = $state;
        this.anchorScroll = $anchorScroll;
        this.location = $location;

        this.CARD_NAME = _.get(this.scope, 'card.name');

        this.scope.refresh = this.refresh.bind(this);
        this.scope.openEditor = this.openEditor.bind(this);
        this.scope.openCreator = this.openCreator.bind(this);
        this.scope.pageNext = this.pageNext.bind(this);
        this.scope.pageBack = this.pageBack.bind(this);
        this.scrollToDefinition = this.scrollToDefinition.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.scope.searchQuery = $stateParams.query;

        var drawerListener = $scope.$root.$on('rightDrawer:close', function() {
            $scope.selectedDefinitionId = undefined;
            $scope.selectedIndex = undefined;
            $state.go(
                $state.current.name,
                {
                    targetId: null,
                    overwriteReload: true
                },
                {
                    location: true,
                    notify: false,
                    reload: false
                }
            );
        });

        $scope.$on('$locationChangeSuccess', function() {
            $scope.searchQuery = $stateParams.query;
        });

        $scope.$on('$destroy', function() {
            drawerListener();
        });

        $scope.$watch(
            'card',
            () => {
                if (this.targetId || $scope.selectedDefinitionId) {
                    const setDefinition = definitionIndex => {
                        this.scrollToDefinition(definitionIndex);
                        $scope.selectedIndex = definitionIndex;
                        $scope.openEditor(
                            _.get($scope, `card.properties.data.results[${definitionIndex}]`),
                            definitionIndex
                        );
                    };

                    // This won't find global definitions, problem?
                    const definitionIndex = _.findIndex(_.get($scope, 'card.properties.data.results'), {
                        id: $scope.selectedDefinitionId || this.targetId
                    });

                    // if the targetId is in the current list just scroll there
                    // and open the editor
                    if (definitionIndex >= 0) {
                        setDefinition(definitionIndex);
                    } else {
                        // otherwise try to go find the target id, put it to the top of the list
                        // and open the editor
                        definitionService
                            .searchDefinitions({
                                filter: {
                                    _id: $scope.selectedDefinitionId || this.targetId,
                                    definitionType: this.definitionType
                                },
                                withPagination: false,
                                size: 1,
                                searchType: 'card-definition-management'
                            })
                            .then(results => {
                                const foundDefinition = _.first(results);
                                if (foundDefinition) {
                                    _.get($scope, 'card.properties.data.results', []).unshift(
                                        foundDefinition
                                    );
                                    setDefinition(0);
                                }
                            })
                            .catch(e => {
                                console.error('Error getting definition by targetId', e);
                            });
                    }
                }
            },
            true
        );
    }

    scrollToDefinition(definitionIndex = 0) {
        this.timeout(() => {
            var id = this.location.hash();
            this.location.hash('definition-' + definitionIndex);
            this.anchorScroll();
            this.location.hash(id);
        }, 200);
    }

    scrollToTop() {
        this.scrollToDefinition('top');
    }

    pageNext() {
        this.scrollToTop();
        this.scope.$root.$emit('rightDrawer:close');
        const cardSize = _.get(this.scope, 'card.properties.data.size');
        const currentOffset = _.get(this.scope, `facets.card.${this.CARD_NAME}.offset`, 0);
        const newOffset = currentOffset + cardSize;

        _.set(this.scope, `facets.card.${this.CARD_NAME}.offset`, newOffset);

        this.scope.getCardHitch({
            data: {
                cardName: this.CARD_NAME,
                facets: _.get(this.scope, 'facets'),
                query: _.get(this.scope, 'query')
            }
        });
    }

    pageBack() {
        this.scrollToTop();
        this.scope.$root.$emit('rightDrawer:close');
        const cardSize = _.get(this.scope, 'card.properties.data.size');
        const currentOffset = _.get(this.scope, `facets.card.${this.CARD_NAME}.offset`, 0);
        const newOffset = Math.max(currentOffset - cardSize, 0);

        _.set(this.scope, `facets.card.${this.CARD_NAME}.offset`, newOffset);

        this.scope.getCardHitch({
            data: {
                cardName: this.CARD_NAME,
                facets: _.get(this.scope, 'facets'),
                query: _.get(this.scope, 'query')
            }
        });
    }

    /**
     * refresh() is called when a definition editor saves, copies, or deletes a definition. It should trigger the
     * list to be updated and the `targetId` set.
     *
     * @param {*} defId
     */
    refresh(defId) {
        _.set(this.state, 'params.targetId', defId);
        this.scope.selectedDefinitionId = defId;
        this.scope.getCardHitch({
            data: {
                cardName: this.CARD_NAME,
                facets: _.get(this.scope, 'facets'),
                query: _.get(this.scope, 'query')
            }
        });
    }

    openEditor(definitionItem, selectedIndex) {
        let scope = this.scope;

        return this.definitionService
            .resolve({
                definition: definitionItem,
                options: { skipAvailableProperties: false }
            })
            .then(result => {
                if (this.rootScope.rightDrawerOpen) {
                    this.rootScope.$emit('rightDrawer:close');
                }

                //toggle the drawer
                // timeout is necessary for tests to pass, don't remove.
                this.timeout(function() {
                    scope.$root.$emit('rightDrawer:open', {
                        layout: 'definitionEditor',
                        api: scope.api,
                        user: scope.user,
                        definition: _.cloneDeep(result),
                        board: scope.board
                    });
                }, 300);

                this.scope.selectedDefinitionId = definitionItem.id;
                this.scope.selectedIndex = selectedIndex;

                //Always set the target ID on the URL of the active definition
                if (result) {
                    this.state.go(
                        this.state.current.name,
                        {
                            targetId: result.id,
                            overwriteReload: true
                        },
                        {
                            location: true,
                            notify: false,
                            reload: false
                        }
                    );
                }
            })
            .catch(e => {
                this.toastr.error('An error occurred, please contact support');
            });
    }

    openCreator() {
        this.scope.$root.$emit('rightDrawer:open', {
            layout: 'definitionCreator',
            type: _.get(this.scope, 'card.properties.definitionType', 'action'),
            definitionTypeString: _.get(this.scope, 'card.settingType'),
            api: this.scope.api,
            user: this.scope.user,
            board: this.scope.board
        });
    }
}
