(function() {
    'use strict';
    angular.module('serviceApp').directive('teamMemberSettings', teamMemberSettings);
    function teamMemberSettings() {
        var directive = {
            restrict: 'E',
            scope: {
                teamMember: '=',
                currencyList: '=',
                teamMemberId: '<?',
                readOnly: '<?'
            },
            template: require('views/tmpl/partials/teamMemberSettings.html'),
            controller: TeamMemberSettingsController
        };

        return directive;
    }

    TeamMemberSettingsController.$inject = [
        '_',
        '$scope',
        '$log',
        '$sanitize',
        'toastr',
        'userServiceNew',
        'teamServiceNew',
        'boardServiceNew',
        'cardsUtilService',
        'integrationService',
        'appSettingsService'
    ];

    function TeamMemberSettingsController(
        _,
        $scope,
        $log,
        $sanitize,
        toastr,
        userServiceNew,
        teamServiceNew,
        boardServiceNew,
        cardsUtilService,
        integrationService,
        appSettingsService
    ) {
        $scope.headerDirty = false;
        $scope.formDirty = false;
        $scope.user = {};
        $scope.homeboards = {
            currentBoardType: undefined,
            teamHomeBoard: _.get($scope, 'team.currentHomeBoard')
        };

        $scope.currencySettingLD = appSettingsService.checkVariationSync('show-team-currency-settings', {
            default: false
        });
        $scope.enableCoreAuthLinking = appSettingsService.checkVariationSync('rev360-manual-linking', {
            default: true
        });

        // Ensure the save button becomes available when changes are made to the homepage
        $scope.$watch('homeboards.currentBoardType', (newVal, oldVal) => {
            // Ignore the initial state
            if (oldVal && oldVal !== newVal) {
                $scope.userProfile.$setDirty();
            }
        });

        init();

        if ($scope.team) {
            $scope.isAdmin = $scope.team.ownerIds.indexOf(_.get($scope, 'teamMember.id')) != -1;
        }

        $scope.confirmResetModal = false;
        $scope.confirmRemoveModal = false;

        function getEmailAlias(user) {
            if (_.isArray(user.lowerCaseEmail)) {
                return _.filter(user.lowerCaseEmail, function(email) {
                    return email !== _.toLower(user.username);
                });
            }

            return [];
        }

        function init() {
            $scope.htmlForm = { readonly: $scope.readOnly };
            // if user data is not supplied go fetch it
            if (!_.get($scope, 'teamMember.userData')) {
                teamServiceNew
                    .getMemberData(_.get($scope, 'teamMember.id'))
                    .then(teamMemberData => {
                        $scope.user.data = teamMemberData;
                        $scope.user.navigation = _.clone(_.get(teamMemberData, 'navigation'));
                        $scope.$broadcast('userNavRetrieved');
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                $scope.user.data = _.clone(_.get($scope, 'teamMember.userData'));
            }

            // if the cards aren't supplied go get them
            if (!_.get($scope, 'teamMember.cards')) {
                // Attempt to find the team-member-settings board.
                boardServiceNew
                    .getBoard({
                        shortcode: 'team-member-settings'
                    })
                    .then(board =>
                        cardsUtilService.getPanel(
                            board.id,
                            board.definitionName,
                            'contentAnalytics',
                            'main',
                            null,
                            {
                                userId: _.get($scope, 'teamMember.id')
                            },
                            null,
                            true
                        )
                    )
                    .then(cards => {
                        $scope.contentAnalyticsCards = {
                            contentByType: _.find(cards, { name: 'contentByType' }),
                            contentByWeek: _.find(cards, { name: 'contentByWeek' })
                        };
                    })
                    .catch(function(err) {
                        $log.error('Error getting panel', err);
                    });
            } else {
                const cards = _.get($scope, 'teamMember.cards', []);
                $scope.contentAnalyticsCards = {
                    contentByType: _.find(cards, { name: 'contentByType' }),
                    contentByWeek: _.find(cards, { name: 'contentByWeek' })
                };
            }

            $scope.user.profile = _.clone(_.get($scope, 'teamMember.profile'));
            $scope.user.username = _.clone(_.get($scope, 'teamMember.email'));
            $scope.user.lowerCaseEmail = _.clone(_.get($scope, 'teamMember.lowerCaseEmail'));
            $scope.user.id = _.clone(_.get($scope, 'teamMember.id'));
            $scope.user.emailAlias = getEmailAlias($scope.user);
            $scope.user.state = _.clone(_.get($scope, 'teamMember.state'));
            $scope.user.currency = _.get($scope, 'teamMember.currency');
            $scope.user.coreAuth = _.get($scope, 'teamMember.coreAuth');

            $scope.api = _.get($scope, 'teamMember.api');
            $scope.team = _.get($scope, 'teamMember.team');

            // check if GUM is enabled for this user
            const isTeamEnableGUM = _.get($scope, 'teamMember.team.coreAuth.tenantId');
            const isUserLinkedToGUM = $scope.user.coreAuth;
            $scope.isUsingGlobalUserDetails = !!(isTeamEnableGUM && isUserLinkedToGUM);
        }

        $scope.showConfirmReset = function() {
            $scope.confirmResetModal = true;
        };

        $scope.hideConfirmReset = function() {
            $scope.confirmResetModal = false;
        };

        $scope.showConfirmRemove = function() {
            $scope.confirmRemoveModal = true;
        };

        $scope.hideConfirmRemove = function() {
            $scope.confirmRemoveModal = false;
        };

        $scope.isActiveUser = function(member) {
            return member.id === _.get($scope, 'teamMember.user.id');
        };

        $scope.updateUserProfile = function(form) {
            var sanitizedSignature = $sanitize(_.get($scope, 'user.profile.emailSignature'));

            var updateUserProfilePayload = {
                profile: {
                    name: $scope.user.profile.name,
                    emailSignature: sanitizedSignature
                },
                id: $scope.user.id,
                currency: $scope.user.currency,
                coreAuth: $scope.user.coreAuth
            };

            if ($scope.user.username !== _.get($scope, 'teamMember.email')) {
                updateUserProfilePayload.email = $scope.user.username;
            }

            let existingLowerCaseEmail = _.castArray(_.get($scope, 'teamMember.lowerCaseEmail'));
            let existingEmailAlias = _.slice(existingLowerCaseEmail, 1);
            if ($scope.user.emailAlias !== existingEmailAlias) {
                updateUserProfilePayload.emailAlias = $scope.user.emailAlias;
            }

            _.set(updateUserProfilePayload, 'state.currentHomeBoard', $scope.homeboards.currentBoardType);
            userServiceNew.updateUser(updateUserProfilePayload).then(
                function() {
                    form.$setPristine();
                    form.$setUntouched();
                    $scope.api.refreshTeam();
                    $scope.closeEditor();
                    toastr.success('Profile Updated!', '');
                },
                function(error) {
                    toastr.error('An Error Occurred', error.msg);
                }
            );
        };

        $scope.resetPassword = function() {
            var data = {
                send_token_to: $scope.user.username
            };

            userServiceNew.sendPasswordResetToken(data).then(
                function() {
                    $scope.hideConfirmReset();
                    toastr.success('Password reset token sent!', '');
                },
                function(error) {
                    toastr.error('Password reset token sent!', '');
                    $scope.errorMessage = error;
                }
            );
        };

        $scope.closeEditor = function() {
            $scope.$root.$emit('rightDrawer:close');
        };

        $scope.appState = function(app, userIntegrations) {
            var integration = _.find(userIntegrations, ['integrationId', app.integrationId]);
            if (integration) {
                return {
                    state: integration.state,
                    warning: integration.lastRunWarning,
                    error: integration.lastRunError
                };
            }
            return null;
        };

        $scope.syncIntegration = function(user, integrationId, isFullSync) {
            var userEmail = _.get(user, 'email');

            if (!integrationId) {
                toastr.error('Could not find integrationId', err);
                return;
            }

            var options = {};

            if (isFullSync) {
                _.set(options, 'syncReset', true);
            }

            integrationService.syncUserIntegration(integrationId, options, userEmail).then(
                function(data) {
                    $scope.api.refreshTeam();
                    $scope.closeEditor();
                    toastr.success('Integration sync attempted, refreshing.', '');
                },
                function(err) {
                    toastr.error('An error occurred', err);
                }
            );
        };

        $scope.setPrimary = function(user, integrationId) {
            var props = {};
            _.set(props, 'primaryUserId', _.get(user, 'id'));

            integrationService.editTeamIntegration(integrationId, props, null).then(
                function(data) {
                    $scope.api.refreshTeam();
                    $scope.closeEditor();
                    toastr.success('Integration set primary user successful.', '');
                },
                function(err) {
                    toastr.error('An error occurred', err);
                }
            );
        };

        $scope.deleteIntegration = function(user, integrationId) {
            var userEmail = _.get(user, 'email');

            if (!integrationId) {
                toastr.error('Could not find integrationId', err);
                return;
            }

            integrationService.forceDeleteUserIntegration(integrationId, null, userEmail).then(
                function(data) {
                    $scope.api.refreshTeam();
                    $scope.closeEditor();
                    toastr.success('Integration deleted, refreshing.', '');
                },
                function(err) {
                    toastr.error('An error occurred', err);
                }
            );
        };
    }
})();
