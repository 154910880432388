(function() {
    'use strict';
    angular.module('serviceApp').directive('cardActionsGreetingBeta', cardActionsGreetingBeta);

    function cardActionsGreetingBeta() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                user: '<'
            },
            template: require('views/tmpl/cards/actionsGreetingBeta.html'),
            controller: CardActionsGreetingBetaController
        };

        return directive;
    }

    CardActionsGreetingBetaController.$inject = ['_', '$scope'];

    function CardActionsGreetingBetaController(_, $scope) {
        $scope.getGreetingTitle = getGreetingTitle;
        $scope.getGreetingSubTitle = getGreetingSubTitle;

        $scope.greetingTitle = $scope.getGreetingTitle();

        var actions = _.get($scope, 'card.properties.actions', []);
        $scope.greetingSubTitle = $scope.getGreetingSubTitle(actions);

        function getGreetingTitle() {
            var userFirstName = _.get($scope.user, 'profile.name');
            if (userFirstName) {
                userFirstName = _.first(userFirstName.split(' '));
            }

            $scope.timeOfDayString = '';
            var date = new Date();
            var hour = date.getHours();

            if (hour <= 11 && hour > 5) {
                $scope.timeOfDayString = 'morning';
            } else if (hour < 17 && hour > 11) {
                $scope.timeOfDayString = 'afternoon';
            } else {
                $scope.timeOfDayString = 'evening';
            }

            var greetingTitle =
                'Good ' + $scope.timeOfDayString + (userFirstName ? ', ' + userFirstName + '.' : '.');
            return greetingTitle;
        }

        function getGreetingSubTitle(actionsInput) {
            var numberOfActions = _.size(actionsInput);
            var actionString = numberOfActions > 1 ? 'Actions' : 'Action';
            var needString = numberOfActions == 1 ? 'needs' : 'need';

            if (numberOfActions < 1) {
                return '';
            }

            return numberOfActions + ' ' + actionString + ' ' + needString + ' your attention';
        }
    }
})();
