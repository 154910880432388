'use strict';

export default class CardLoader {
    static get $inject() {
        return ['$scope'];
    }
    constructor($scope) {
        this.scope = $scope;
    }
}
