import { getTransformedQuery } from '../utils/query-utils';

(function() {
    'use strict';

    angular.module('serviceApp').directive('searchEverything', searchEverything);

    function searchEverything() {
        var directive = {
            restrict: 'E',
            scope: {
                type: '='
            },
            template: require('views/tmpl/partials/searchEverything.html'),
            controller: SearchEverythingController
        };

        return directive;
    }

    SearchEverythingController.$inject = [
        '_',
        '$scope',
        '$rootScope',
        '$log',
        '$q',
        '$sce',
        'cardsUtilService',
        'boardServiceNew',
        'userServiceNew',
        'teamServiceNew',
        'contentTypeService'
    ];

    function SearchEverythingController(
        _,
        $scope,
        $rootScope,
        $log,
        $q,
        $sce,
        cardsUtilService,
        boardServiceNew,
        userServiceNew,
        teamServiceNew,
        contentTypeService
    ) {
        $scope.getSearchEverythingCards = getSearchEverythingCards;
        $scope.toggleView = toggleView;

        $scope.refreshSearchEverythingPanel = function functionName() {
            $scope.getSearchEverythingCards();
        };

        $scope.query = {
            str: null,
            advancedQuery: false
        };
        $scope.facets = {};

        $scope.tooltipContent = $sce.trustAsHtml(
            '<div class="primary-text white size-small">' +
                'Combine complex operators to perform advanced searches...' +
                '</div>'
        );

        var promiseObject = {
            user: userServiceNew.getCachedOrFetch(),
            team: teamServiceNew.getCachedOrFetch(),
            board: boardServiceNew.getBoard({
                shortcode: 'search-everything',
                preventInterrupt: true
            }),
            displayOptions: contentTypeService.getContentDisplayOptions()
        };

        $scope.initialized = false;
        $q.all(promiseObject)
            .then(function(results) {
                $scope.user = _.get(results, 'user');
                $scope.team = _.get(results, 'team');
                $scope.board = _.get(results, 'board');
                var panels = _.get($scope.board, 'panels');
                $scope.panel = _.first(panels);
                $rootScope.displayOptions = _.get(results, 'displayOptions');

                $scope.initialized = true;

                $scope.getSearchEverythingCards();
            })
            .catch(function(err) {
                $log.error('Error resolving promises', err);
            });

        /**
         * Updates the given card within the current board + panel to
         * reflect the given facets. The scope's array of cards is updated
         * with the response.
         *
         * TODO This is copied from PanelController, figure out how to consolidate
         * the logic.
         *
         * @param data.facets Merge these facets into the scope before update
         * @param data.query Merge this query into the scope before update
         * @param data.cardName The card name to retrieve
         */
        $scope.getCard = function(data, opts) {
            $scope.facets = _.mergeWith($scope.facets, _.get(data, 'facets'), (oldFacet, newFacet) =>
                // Don't merge facets containing an array otherwise it becomes impossible to remove array elements
                _.isArray(newFacet) ? newFacet : undefined
            );
            $scope.query = _.merge($scope.query, data.query);

            // get transformed query
            const transformedQuery = getTransformedQuery($scope.query);

            cardsUtilService
                .getCards({
                    boardId: $scope.board.id,
                    boardDefinitionName: $scope.board.definitionName,
                    panelName: $scope.panel.name,
                    panelGroup: $scope.group,
                    cardName: data.cardName,
                    query: transformedQuery,
                    facets: $scope.facets,
                    cacheOnly: _.get(opts, 'cacheOnly', false)
                })
                .then(
                    function(cards) {
                        _.forEach(cards, function(card) {
                            var cardToReplace = _.findIndex($scope.cards, {
                                name: card.name
                            });

                            if (cardToReplace < 0) {
                                $scope.cards.push(card);
                            } else {
                                $scope.cards[cardToReplace] = card;
                            }
                        });
                    },
                    function(err) {
                        $log.error('Error getting card', err);
                    }
                )
                .catch(function(err) {
                    $log.error('Error getting card', err);
                });
        };

        function getSearchEverythingCards(data) {
            if (!$scope.initialized) {
                return;
            }

            if (_.get(data, 'facets')) {
                $scope.facets = data.facets;
            }

            // get transformed query
            const transformedQuery = getTransformedQuery($scope.query);

            return cardsUtilService
                .getPanel(
                    $scope.board.id,
                    $scope.board.definitionName,
                    'main',
                    'main',
                    transformedQuery,
                    $scope.facets,
                    null,
                    false,
                    null,
                    true
                )
                .then(function(cards) {
                    $scope.cards = cards;
                })
                .catch(function(err) {
                    $log.info('get search panel interrupted', err);
                });
        }

        function toggleView() {
            $scope.$root.$emit('leftDrawer:close', {});
        }
    }
})();
