import { isStarred, setStarred } from '../utils/star-utils';

(function() {
    'use strict';

    angular.module('serviceApp').directive('panelHeader', panelHeader);

    function panelHeader() {
        var directive = {
            restrict: 'E',
            scope: {
                board: '=',
                user: '<',
                team: '<',
                panel: '=?',
                query: '=?',
                external: '<?',
                hierarchy: '=?',
                pageLoaded: '<?',
                hasBoardDescription: '<?',
                refreshIndicatorCallback: '&?',
                refreshBoardDefinitionCallback: '&?',
                onSave: '&?'
            },
            link: function($scope) {
                $scope.api = {
                    refresh: function(definitionName) {
                        $scope.refreshBoard(definitionName);
                    },
                    updateBoard: function(definition) {
                        $scope.updateBoard(definition);
                    }
                };
            },
            template: require('views/tmpl/partials/panelHeader.html'),
            controller: PanelHeaderController
        };
        return directive;
    }

    PanelHeaderController.$inject = [
        '_',
        '$scope',
        '$rootScope',
        '$stateParams',
        'userServiceNew',
        'searchServiceNew',
        'definitionService',
        '$state',
        '$window',
        'toastr',
        '$timeout',
        'themeService',
        'captureService',
        'appSettingsService',
        'boardSliceService',
        '$document',
        'storageService'
    ];

    function PanelHeaderController(
        _,
        $scope,
        $rootScope,
        $stateParams,
        userServiceNew,
        searchServiceNew,
        definitionService,
        $state,
        $window,
        toastr,
        $timeout,
        themeService,
        captureService,
        appSettingsService,
        boardSliceService,
        $document,
        storageService
    ) {
        $scope.darkMode = themeService.isDarkMode;
        $scope.toggleDarkMode = themeService.toggleMode;
        $scope.activeBoardDisplayName = _.get($scope, 'board.definition.displayName');
        $scope.timer = null;
        $scope.showAddDescription = false;
        $scope.emailDialogOpen = false;
        $scope.isStarred = isStarred({
            user: $scope.user,
            boardType: 'insights',
            defType: 'board',
            cardDefinitionId: $scope.board.definition.id,
            boardDefinitionName: $scope.board.definition.name
        });

        // if the all dashboards page is enabled, show the star in the top right
        $scope.allDashboardsEnabled = appSettingsService.checkVariationSync('use-all-dashboards-page', {
            default: true
        });

        const allDashboardsFacets = _.get($stateParams, 'dashFacets');
        // if we have all dashboards page facets, create a back to all dashboards link
        if (allDashboardsFacets) {
            // get the recent dashboard ids from local storage
            const recentDashboards = storageService.getRaw(`boardDef-insights`, 'local');
            let recentDashboardIds = recentDashboards.recent.map(b => b.cardDefinitionId);
            recentDashboardIds = recentDashboardIds.slice(0, 5);

            // add the recent ids to facets
            const facets = JSON.parse($window.atob(allDashboardsFacets));
            _.set(facets, 'card.allDashboards.recentDashboardIds', recentDashboardIds);
            const decodedFacets = JSON.stringify(facets);

            const url = $state.href('boards.board.panel', {
                board: 'all-dashboards',
                panel: 'main',
                facets: decodedFacets
            });
            $scope.subtitle = `<a href="${url}">Back to all dashboards</a>`;
        }

        $scope.showNavigation = _.filter(_.get($scope, 'board.panels'), panel => !panel.hidden).length > 1;

        if ($scope.board.definition) {
            $scope.definition = $scope.board.definition;

            //Watch for top down changes and refresh the board.
            let defPropertyValuesWatcher = $scope.$watch(
                'definition.propertyValues.filter',
                (newDef, oldDef) => {
                    if (_.isEqual(newDef, oldDef)) {
                        return;
                    }
                    $scope.isEditingBoardFilter = true;
                    $scope.refreshBoardDefinitionCallback();
                },
                true
            );

            // refresh the board when a new board pill is added
            let defPropertySchemaWatcher = $scope.$watch(
                'definition.propertySchema.filter',
                (newDef, oldDef) => {
                    if (_.isEqual(newDef, oldDef) || !oldDef) {
                        return;
                    }
                    $scope.isEditingBoardFilter = true;
                    $scope.refreshBoardDefinitionCallback();
                },
                true
            );

            let boardDefinitionWatcher = $scope.$watch(
                'board.definition',
                (newDef, oldDef) => {
                    // we do not want setting filter.availableOption from
                    // a resolve to put in a dirty state
                    const { filter: oldFilter, ...oldDefSaveable } = oldDef || {};
                    const { filter: newFilter, ...newDefSaveable } = newDef || {};
                    if (_.isEqual(newDefSaveable, oldDefSaveable)) {
                        return;
                    }
                    $scope.isEditingBoardFilter = true;
                },
                true
            );

            const openAccordionListener = $scope.$on('open-accordion-editor', (event, data) => {
                $scope.openEditor(false, data.accordion, data.scrollTo);
            });

            //clean up the listeners
            $scope.$on('$destroy', function() {
                defPropertyValuesWatcher();
                defPropertySchemaWatcher();
                boardDefinitionWatcher();
                openAccordionListener();
            });
        }

        // properties.supportsSharing states that this board definition has the ability to set the audience
        // editable states that this user has authorization to edit this board
        $scope.shareableEnabled = function() {
            return _.get($scope.board, 'properties.supportsSharing') && _.get($scope.board, 'editable');
        };

        $scope.shareDashboardEnabled = function() {
            return (
                _.get($scope.board, 'definition.propertySchema.layout') &&
                _.get($scope.board, 'panels.length') === 1
            );
        };

        $scope.favoriteEnabled = function() {
            return (
                $scope.board.type === 'insights' &&
                $scope.allDashboardsEnabled &&
                _.get($scope.board, 'panels.length') === 1
            );
        };

        //properties.supportsEditing states that this board has editable definitions
        //editable states that this user has authorization to edit this board
        $scope.editMenuEnabled = function() {
            return _.get($scope.board, 'properties.supportsEditing') && _.get($scope.board, 'editable');
        };

        $scope.showFilterHeader = function() {
            return (
                _.get($scope.board, 'properties.showFilterHeader', true) &&
                _.get($scope.board, 'definition.isReadOnly') !== true
            );
        };

        $scope.hideBoard = function(board) {
            userServiceNew.hideBoard($scope.user, board.id).then(
                function(updatedUser) {
                    toastr.success('Board hidden!', '');
                    $state.go('home.main');
                },
                function(error) {
                    toastr.error('Failed to hide board: ' + error, '');
                }
            );
        };

        $scope.openModal = function(modal) {
            var modalData = _({})
                .set('modal', modal)
                .set('extras', {
                    board: $scope.board
                })
                .value();

            $scope.$root.$emit('showModal', modalData);
        };

        //Check if this can enabled the add description. If it doesn't already have a description
        // and it has an actual definition attached to it, and the board is auth editable by
        // this user
        $scope.isDescriptionEditable = function() {
            return (
                !$scope.hasBoardDescription &&
                _.get($scope.board, 'definition.definitionName') &&
                _.get($scope.board, 'editable')
            );
        };

        /**
         * Utility function to get the latest version of the definition with the given name,
         * and resolve it.
         *
         * @param {String} definitionName The name of the definition to retrieve.
         * @param {String} editing is this on an edited definition
         */
        const getAndResolveBoardDefinition = (definitionName, editing) => {
            return definitionService
                .searchDefinitions({
                    filter: {
                        definitionType: 'board',
                        'board.boardType': $scope.board.type,
                        name: definitionName
                    },
                    withPagination: false,
                    size: 1,
                    searchType: 'panel-header'
                })
                .then(function(definitions) {
                    return _.find(definitions, function(definition) {
                        return definition.name == definitionName;
                    });
                })
                .then(definition => {
                    //Adjust the board definition to include changes to the live edited board
                    if (editing) {
                        definition.propertySchema = _.get(
                            boardSliceService,
                            'boardDefinition.propertySchema'
                        );
                        definition.propertyValues = _.get(
                            boardSliceService,
                            'boardDefinition.propertyValues'
                        );
                        if (_.get(boardSliceService, 'boardDefinition.board.cardConfig')) {
                            _.set(
                                definition.board,
                                'cardConfig',
                                _.get(boardSliceService, 'boardDefinition.board.cardConfig')
                            );
                        }
                    }
                    return definitionService.resolve({ definition: definition });
                });
        };

        $scope.openEditor = (sharing, accordion, scrollTo) => {
            getAndResolveBoardDefinition(
                _.get($scope, 'board.definition.name'),
                $scope.isEditingBoardFilter
            ).then(definition => {
                $scope.$root.$emit('rightDrawer:open', {
                    layout: 'definitionEditor',
                    type: 'board',
                    api: $scope.api,
                    definition: _.cloneDeep(definition),
                    accordion: sharing ? 'audience' : accordion || '',
                    scrollToItem: scrollTo || `board`,
                    user: $scope.user,
                    board: $scope.board,
                    editing: $scope.isEditingBoardFilter
                });
            });
        };

        $scope.captureBoard = viewName => {
            // The container node that has the dashboard layout
            const container = $document[0].getElementById('service-container');

            // These are objects on the dashboard we don't want to show in the image
            // e.g. card menu, add card, add row indicator, and external icons
            const ignoreClasses = [
                'context-menu',
                'layout-add-card',
                'insights-add-row',
                'primary-content-details-icon'
            ];

            // This is a unique image name to use to save this dashboard image
            const imageName = `${_.snakeCase(viewName)}-dashboard`;

            captureService.captureScreen(imageName, container, ignoreClasses);
        };

        $scope.showEmailDialog = () => {
            $scope.$emit('open-email-dashboard');
        };

        $scope.toggleStarred = () => {
            setStarred({
                boardType: 'insights',
                cardDefinitionId: $scope.board.definition.id,
                boardDefinitionName: $scope.board.definition.name,
                starred: !$scope.isStarred,
                userServiceNew: userServiceNew
            }).then(starred => {
                $scope.isStarred = starred;
            });
        };

        $scope.save = () => {
            getAndResolveBoardDefinition(_.get($scope, 'board.definition.name'), true).then(
                definitionItem => {
                    //Only save properties that has a schema key for
                    definitionItem.propertyValues = _.pick(
                        definitionItem.propertyValues,
                        _.keys(definitionItem.propertySchema)
                    );
                    definitionService
                        .updateDefinition(definitionItem)
                        .then(function(updatedDefinition) {
                            const definitionType = _.get(updatedDefinition, 'definitionType');
                            toastr.success(_.startCase(definitionType) + ' updated successfully', '');

                            //Don't reload the page, the definition is saved and the changes applied
                            boardSliceService.clearIsDirty(_.get(boardSliceService, 'boardDefinitionId'));

                            $scope.$root.isEditingFilter = false;
                        })
                        .catch(function(e) {
                            console.error('Error saving definition', e);
                            toastr.error(_.get(e, 'data.message'));
                        });
                }
            );
        };

        $scope.refreshBoard = shortCode => {
            if ($rootScope.rightDrawerOpen) {
                $rootScope.$emit('rightDrawer:close');
            }

            const definitionName = _.last(_.split(shortCode, '$'));
            getAndResolveBoardDefinition(definitionName).then(definitionItem => {
                //if the board definition is disabled or deleted, flush the cookie and load the page without the definition
                if (!definitionItem) {
                    //Clear out the Board Definition
                    $cookies.remove('boardDef');
                    // since this is the failover situation, get the user to a known clean state
                    $state.go('boards.board.panel', {
                        board: _.get($scope.board, 'id'),
                        panel: _.get($scope.panel, 'name'),
                        overwriteReload: false
                    });
                } else {
                    //refresh the board with the updated board definition
                    $state.go('boards.board.panel', {
                        board: shortCode,
                        panel: _.get($scope.panel, 'name'),
                        overwriteReload: false,
                        boardDef: shortCode
                    });
                }
            });
        };

        $scope.updateBoard = definition => {
            $scope.definition = definition;
        };

        $scope.isSettings = function() {
            return $state.current.name == 'boards.settings';
        };

        $scope.openTab = function(link) {
            $window.open(link.url, '_blank');
        };

        var actions = _.get($scope.board, 'properties.actions');
        $scope.hasRefreshAction = _.find(actions, function(action) {
            return action.action == 'refreshContent';
        });

        $scope.refreshContent = function() {
            if (!$scope.board.contentId) {
                toastr.error('Error refreshing content, could not find contentId', '');
                return;
            }

            searchServiceNew.refreshContent($scope.board.contentId).then(
                function() {
                    $scope.refreshIndicatorCallback();
                },
                function(err) {
                    toastr.error('Error refreshing content ' + JSON.stringify(err), '');
                }
            );
        };

        $scope.setHover = function(startTimeout) {
            if (startTimeout && !$scope.timer) {
                $scope.timer = $timeout(() => {
                    $scope.showAddDescription = true;
                }, 750);
            }
            if (!startTimeout) {
                $timeout.cancel($scope.timer);
                $scope.timer = null;
                $scope.showAddDescription = false;
            }
        };

        $scope.runAnalysis = function() {
            if (!$scope.board.contentId) {
                toastr.error('Error running analysis, could not find contentId', '');
                return;
            }

            searchServiceNew
                .refreshContent($scope.board.contentId, {
                    analysisOnly: true
                })
                .then(
                    function() {
                        $scope.refreshIndicatorCallback();
                    },
                    function(err) {
                        toastr.error('Error running analysis ' + JSON.stringify(err), '');
                    }
                );
        };
    }
})();
