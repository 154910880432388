'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('globalUserDetails', {
    controller,
    template,
    bindings: {
        user: '<',
        team: '<'
    }
});
