(function() {
    'use strict';

    angular.module('serviceApp').filter('camelToTitleCase', camelToTitleCase);

    camelToTitleCase.$inject = ['_', '$filter'];

    function camelToTitleCase(_, $filter) {
        var filter = function(n, config) {
            if (_.isNil(n)) {
                return 'N/A';
            }

            if (config && config.keepSymbols) {
                return n.replace(/\w+/g, _.capitalize);
            }

            return _.startCase(n);
        };

        return filter;
    }
})();
