import controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('drawerPanelController', drawerPanelController);

function drawerPanelController() {
    var directive = {
        restrict: 'E',
        scope: {
            boardId: '=',
            user: '<?',
            team: '<?',
            hierarchy: '=?',
            panelGroupName: '<?',
            panelName: '<?',
            drawerPayload: '<?',
            isRightDrawer: '<',
            userTheme: '<?',
            isSummary: '<?',
            includeSummary: '<?',
            drawerClass: '<?',
            toggleCallback: '&?',
            closeCallback: '&?'
        },
        template,
        controller
    };

    return directive;
}
