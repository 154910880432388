(function() {
    'use strict';

    angular.module('serviceApp').filter('subString', subString);

    subString.$inject = ['_'];

    function subString(_) {
        var filter = function(str, start, end) {
            if (_.isNil(str) || !_.isString(str)) {
                return str;
            }
            return str.substr(start, end);
        };
        return filter;
    }
})();
