(function() {
    'use strict';

    angular.module('serviceApp').filter('percent', percent);

    percent.$inject = ['_', '$filter'];

    function percent(_, $filter) {
        return function(n, options = {}) {
            let decimals = _.get(options, 'decimals') ?? _.get(this, 'decimals') ?? undefined;
            const forceSign = _.get(options, 'forceSign') ?? _.get(this, 'forceSign') ?? false;
            const colorCodedPercentages =
                _.get(options, 'colorCodedPercentages') ?? _.get(this, 'colorCodedPercentages') ?? false;

            // Check if value is undefined or nil, but not zero.
            if ((!n || _.isNil(n)) && n !== 0) {
                return 'N/A';
            }

            // Convert value to number, may be Integer, Float or String.
            const numericValue = _.toNumber(n);

            // If not a number, return the raw value
            if (_.isNaN(numericValue) || !_.isNumber(numericValue)) {
                return n;
            }

            if (_.isUndefined(decimals)) {
                decimals = n % 1 === 0 ? 0 : 1;
            }

            const out = `${forceSign && numericValue > 0 ? '+' : ''}${$filter('number')(
                numericValue,
                decimals
            )}%`;

            if (colorCodedPercentages) {
                let colorClass = 'percentage-bad';
                if (numericValue >= 35) {
                    colorClass = 'percentage-medium';
                }
                if (numericValue >= 75) {
                    colorClass = 'percentage-good';
                }
                return `<span class="${colorClass}">${out}</span>`;
            }

            return out;
        };
    }
})();
