'use strict';

export default class TagItemController {
    static get $inject() {
        return ['$scope'];
    }
    constructor($scope) {
        this.scope = $scope;
        this.scope.removeSelf = this.removeSelf.bind(this);
    }

    removeSelf() {
        this.scope.$emit('removeTagItem', { tag: this.scope.tag });
    }
}
