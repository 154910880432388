(function() {
    'use strict';

    angular.module('serviceApp').filter('propertyLabel', propertyLabel);

    propertyLabel.$inject = ['_', 'storageService'];

    function propertyLabel(_, storageService) {
        // _.get + return default value if `null` or empty string is returned.
        function safeGet(obj, key, defaultValue) {
            const val = _.get(obj, key, defaultValue);
            return _.isEmpty(val) ? defaultValue : val;
        }

        // Get the custom property label (if it exists) from the cache, otherwise return the default.
        // If no default was provided, create one by start-casing the property name.
        function getPropertyLabel(propertyName, contentType, defaultValue) {
            return safeGet(
                storageService.get(`content-display-options`, 'session'),
                `${contentType}.propertyDisplayOptions["${contentType}.${propertyName}"].label`,
                defaultValue || _.startCase(propertyName)
            );
        }

        /**
         * Returns a custom label for a given (propertyName, contentType) pair if a label exists.
         *
         * @param {String} propertyName - The raw (no dots) name of the property to retrieve a label for.
         * @param {String} contentType - The content type of the property to retrieve a label for.
         * @param {String} defaultValue - A value to return if no custom label was found.
         */
        return function(propertyName, contentType, defaultValue) {
            // If the propertyName isn't provided, return the provided default.
            if (!propertyName) {
                return defaultValue;
            }

            // If the content type wasn't provided, or we don't have any cached display options,
            // return the best default we can.
            if (!contentType || !storageService.get(`content-display-options`, 'session')) {
                return defaultValue || _.startCase(propertyName);
            }
            // Attempt to get the custom property label from the display options.
            return getPropertyLabel(propertyName, contentType, defaultValue);
        };
    }
})();
