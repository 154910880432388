(function() {
    'use strict';

    angular.module('serviceApp').filter('titlecase', titlecase);

    titlecase.$inject = ['_'];

    function titlecase(_) {
        var filter = function(input) {
            var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

            if (_.isNil(input)) {
                return '';
            }
            input = _.toLower(input);
            return input.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function(match, index, title) {
                if (_.isNil(match) || !_.isString(match)) {
                    return '';
                }

                if (
                    index > 0 &&
                    index + match.length !== title.length &&
                    match.search(smallWords) > -1 &&
                    title.charAt(index - 2) !== ':' &&
                    (title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
                    title.charAt(index - 1).search(/[^\s-]/) < 0
                ) {
                    return _.toLower(match);
                }

                return _.toUpper(match.charAt(0)) + match.substr(1);
            });
        };

        return filter;
    }
})();
