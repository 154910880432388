'use strict';

/**
 * Angular wrapper for our <PropertyMappingExternalComponent /> react component.
 */
export default class PropertyMappingModal {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.data = {
            contentType: this.contentType,
            name: this.name,
            appId: this.appId,
            integrationType: this.integrationType,
            handleCloseModal: this.handleCloseModal
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'contentType')) {
            this.data = {
                ...this.data,
                contentType: this.contentType
            };
        }
        if (this._.get(changes, 'name')) {
            this.data = {
                ...this.data,
                name: this.name
            };
        }
        if (this._.get(changes, 'appId')) {
            this.data = {
                ...this.data,
                appId: this.appId
            };
        }
        if (this._.get(changes, 'integrationType')) {
            this.data = {
                ...this.data,
                integrationType: this.integrationType
            };
        }
    }
}
