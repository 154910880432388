'use strict';
import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('actionGraph', () => ({
    restrict: 'E',
    scope: {
        defaultFilter: '=',
        filterData: '=',
        graphOptions: '<?'
    },
    template,
    controller: Controller
}));
