'use strict';

/**
 * Angular wrapper for our <DefaultViewToggle /> react component.
 */

export default class DefaultViewToggle {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.data = {
            selectedView: this.selectedView,
            cardName: this.cardName
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'selectedView') && !changes.selectedView.isFirstChange()) {
            this.selectedView = changes.selectedView.currentValue;
            this.data = { ...this.data, selectedView: this.selectedView };
        }
    }
}
