(function() {
    'use strict';

    angular.module('serviceApp').factory('filterService', FilterService);

    FilterService.$inject = ['_', '$resource', '$q'];

    function FilterService(_, $resource, $q) {
        const filterUrl = '/api/filter';
        const defaultParams = {};

        const FilterApi = {
            resolve: {
                method: 'POST',
                url: `${filterUrl}/resolve`
            }
        };

        const filterResource = $resource(filterUrl, defaultParams, FilterApi);

        return { resolve, initialize, initializeDefaults };

        /**
         * Given a key-value map of properties and schema for that property, resolve the
         * schema. This involves turning `type: 'interpreted'` into the actual filter, filling
         * out default values, etc.
         *
         * @param {Object} schema The schema
         * @returns {Object} The resolved schema.
         */
        function resolve(schema) {
            return $q.when(filterResource.resolve(schema).$promise);
        }

        // called from definition service code path.
        function initialize(schema, model) {
            let formModel = model;
            let formSchema = _(schema)
                .omitBy(_.isNil)
                .mapValues((val, key) => {
                    // Enrich the form schema with the `contentType` and `propertyName`.
                    if (key.split('.').length === 2) {
                        [val.contentType, val.propertyName] = key.split('.');
                    }
                    return val;
                })
                .value();

            return initializeDefaults(formSchema, formModel);
        }

        // To prevent formModel values from being delayed to appear in widget scope
        // set the default values to the formModel so widgets don't need to "listen"
        // for formModel changes
        // (called from single-property forms UI code path directly)
        function initializeDefaults(schema, model) {
            let formSchema = schema;
            let formModel = model;

            _.forEach(formSchema, (schemaItemValue, schemaItemName) => {
                const defaultValue = _.get(schemaItemValue, 'default');
                if (!_.isNil(defaultValue) && _.isNil(_.get(formModel, [schemaItemName]))) {
                    _.set(formModel, [schemaItemName], defaultValue);
                }
            });

            formSchema = { type: 'object', properties: formSchema };

            return {
                formModel: formModel,
                formSchema: formSchema
            };
        }
    }
})();
