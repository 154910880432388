(function() {
    'use strict';

    function InputAddOnFilter(type, attrs, model) {
        this.type = type;
        this.name = type === 'prepend' ? 'inputPrepend' : 'inputAppend';

        model.$parsers.unshift(angular.bind(this, this.parse));
        model.$formatters.unshift(angular.bind(this, this.format));

        const redefine = angular.bind(this, this.define);

        // When the name of the item being edited changes,
        // reload some of our internal properties and re-run the formatters.
        // There's currently no other way to programmatically run the formatters;
        // see https://github.com/angular/angular.js/issues/3407
        attrs.$observe(this.name, value => {
            redefine(value);

            // Get the list of all formatters.
            const formatters = model.$formatters || [];

            // Run the current model value through all the formatters in reverse,
            // because that's the way Angular does it.
            const viewValue = formatters.reverse().reduce((memo, formatter) => {
                return formatter(memo);
            }, model.$modelValue);

            // If the formatted value changed, re-render.
            if (model.$viewValue !== viewValue) {
                model.$viewValue = model.$$lastCommittedViewValue = viewValue;
                model.$render();
            }
        });

        this.define(attrs[this.name]);
    }

    InputAddOnFilter.prototype.format = function(value) {
        var regexp;

        if (this.type === 'prepend') {
            regexp = new RegExp('^(' + this.escapedAddOn + ')');
        } else {
            regexp = new RegExp('(' + this.escapedAddOn + ')$');
        }

        return angular.isString(value) ? value.replace(regexp, '') : value;
    };

    InputAddOnFilter.prototype.parse = function(value) {
        var regexp, newValue;

        if (this.type === 'prepend') {
            regexp = new RegExp('^(' + this.escapedAddOn + ')?');
            newValue = this.addOn + '$`';
        } else {
            regexp = new RegExp('(' + this.escapedAddOn + ')?$');
            newValue = "$'" + this.addOn;
        }

        return angular.isString(value) ? value.replace(regexp, newValue) : value;
    };

    InputAddOnFilter.prototype.define = function(value) {
        this.addOn = value;
        this.escapedAddOn = this.escape(this.addOn);
    };

    InputAddOnFilter.prototype.escape = function(str) {
        if (str) {
            return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        } else {
            return '';
        }
    };

    function directiveFn(type) {
        return function() {
            return {
                restrict: 'A',
                require: 'ngModel',

                link: function(scope, element, attrs, model) {
                    var inputAddOnFilter = new InputAddOnFilter(type, attrs, model);
                }
            };
        };
    }

    angular
        .module('serviceApp')
        .directive('inputAppend', directiveFn('append'))
        .directive('inputPrepend', directiveFn('prepend'));
})();
