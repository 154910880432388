(function() {
    'use strict';

    angular.module('serviceApp').controller('AppsController', AppsController);

    AppsController.$inject = [
        '_',
        '$scope',
        'apps',
        'appServiceNew',
        '$timeout',
        'user',
        'team',
        'integrations',
        'board',
        '$state'
    ];

    function AppsController(
        _,
        $scope,
        apps,
        appServiceNew,
        $timeout,
        user,
        team,
        integrations,
        board,
        $state
    ) {
        $scope.board = board;
        $scope.panel = _.find($scope.board.panels, ['name', _.get($scope.board, 'state.currentPanel')]);
        $scope.apps = addSize(apps);
        $scope.query = {};
        $scope.showAppOptions = false;
        $scope.filter = false;
        $scope.user = user;
        $scope.team = team;

        const teamIntegrations = integrations;

        (function filterApps() {
            var panel = _.find($scope.board.panels, ['name', _.get($scope.board, 'state.currentPanel')]);
            var filter = _.get(panel, 'filter');
            var addedOnly = _.get(panel, 'addedOnly', false);

            if (!_.isEmpty(filter)) {
                $scope.apps = _.filter(addSize(apps), function(app) {
                    return (
                        _.findIndex(filter, function(filterItem) {
                            return app.events == filterItem;
                        }) >= 0
                    );
                });
            } else {
                $scope.apps = addSize(apps);
            }

            if (addedOnly) {
                $scope.apps = _.filter($scope.apps, function(app) {
                    return _.get(app, 'events') === 'team' && _.get(app, 'teamId') !== '##GLOBAL##';
                });
            }
        })();

        var timer;
        $scope.searchApps = function() {
            if (timer) {
                $timeout.cancel(timer);
            }

            $scope.apps = _.filter(addSize(apps), function(app) {
                return _.includes(app.title.toLowerCase(), $scope.query.str.toLowerCase());
            });

            if (_.isEmpty($scope.apps)) {
                timer = $timeout(function() {
                    appServiceNew.getApps($scope.query.str).then(function(apps) {
                        $scope.apps = addSize(apps);
                    });
                }, 400);
            }
        };

        $scope.isEnabled = function(app) {
            //Either it's not an team integration or a team integration that is enabled
            return (
                !_.find(teamIntegrations, { appId: _.get(app, 'id') }) ||
                _.find(teamIntegrations, { appId: _.get(app, 'id'), state: 'enabled' })
            );
        };

        function addSize(array) {
            var tempApps = _.cloneDeep(array);
            tempApps = _.forEach(tempApps, function(app) {
                return _.set(app, 'size', {
                    x: 1
                });
            });

            return tempApps;
        }

        $scope.selectBoard = function(data) {
            // For new setting navigation, if panel is defined, we will use panel to redirect
            let panel = _.get(data, 'panel');
            if (panel) {
                const shortCode = _.get(data, 'shortCode');
                if (shortCode === 'apps') {
                    $state.go('apps.all', {
                        panel: panel,
                        overwriteReload: false,
                        board: shortCode
                    });
                } else {
                    $state.go('boards.board.panel', {
                        board: shortCode,
                        panel,
                        overwriteReload: false,
                        boardDef: shortCode
                    });
                }
            }
        };
    }
})();
