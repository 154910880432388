(function() {
    'use strict';

    angular.module('serviceApp').factory('searchServiceNew', searchServiceNew);

    searchServiceNew.$inject = ['_', '$resource', '$q', '$log', '$http'];

    function searchServiceNew(_, $resource, $q, $log, $http) {
        var searchUrl = '/api/content';
        var defaultParams = {};

        var searchResourceActions = {
            search: {
                method: 'GET',
                params: {
                    board: '@board',
                    query: '@query'
                },
                isArray: true
            },
            updateSignalActions: {
                method: 'POST',
                url: searchUrl + '/signalaction'
            },
            refreshContent: {
                method: 'POST',
                url: searchUrl + '/:contentId/refresh'
            },
            getActions: {
                method: 'GET',
                url: searchUrl + '/:contentId/action',
                isArray: true,
                params: {
                    active: '@active'
                }
            }
        };

        var searchResource = $resource(searchUrl, defaultParams, searchResourceActions);

        var service = {
            search: search,
            getResultsActivity: getResultsActivity,
            logClick: logClick,
            updateSignalActions: updateSignalActions,
            refreshContent: refreshContent,
            getActions: getActions
        };

        return service;

        ////////////

        function search(board, queryString, destinationTime) {
            return $q.when(
                searchResource.search({
                    board: board.id,
                    query: queryString,
                    destinationTime: destinationTime
                }).$promise
            );
        }

        function getResultsActivity(searchResults) {
            var totalActivity = 0;
            _.forEach(searchResults, function(searchResult) {
                totalActivity += searchResult.metrics.eventCount;
            });
            return totalActivity;
        }

        function logClick(contentId, boardId, userQuery, linkUsed, source, callback) {
            if (!callback) {
                callback = function() {};
            }
            var body = {
                content: contentId,
                board: boardId,
                query: userQuery,
                linkUsed: linkUsed,
                source: source
            };
            $log.debug('logging click ' + contentId);
            $http.post('/api/content/click', body).then(
                function logClickHandler(data) {
                    $log.debug('/api/click results: ' + data);
                    callback(null, 'ok');
                },
                function(err) {
                    $log.error('/api/click failed: ' + err);
                    callback('error');
                }
            );
        }

        function updateSignalActions(contentId, signalHashId, actions) {
            if (!contentId) {
                return $q.reject({
                    err: 'Content Id must be defined'
                });
            }

            if (!signalHashId) {
                return $q.reject({
                    err: 'signal HashId must be defined'
                });
            }

            if (!actions || _.isEmpty(actions)) {
                return $q.reject({
                    err: 'actions must be defined'
                });
            }

            var body = _({})
                .set('contentId', contentId)
                .set('signalHashId', signalHashId)
                .set('actions', actions)
                .value();

            return $q.when(searchResource.updateSignalActions({}, body).$promise);
        }

        function refreshContent(contentId, options) {
            if (!contentId) {
                return $q.reject({
                    err: 'Content Id must be defined'
                });
            }

            if (!options) {
                options = {};
            }

            return $q.when(
                searchResource.refreshContent(
                    {
                        contentId: contentId
                    },
                    options
                ).$promise
            );
        }

        function getActions(contentId, active) {
            if (!contentId) {
                return $q.reject({
                    msg: 'Content id must be defined'
                });
            }

            return searchResource
                .getActions({
                    contentId: contentId,
                    active: active
                })
                .$promise.then(getActionsSuccess)
                .catch(getActionsFailure);

            function getActionsSuccess(actions) {
                return actions;
            }

            function getActionsFailure(error) {
                return $q.reject(error);
            }
        }
    }
})();
