'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').directive('definitionEditor', () => ({
    restrict: 'E',
    scope: {
        definition: '=',
        api: '=',
        user: '<',
        board: '=?',
        activeAccordion: '<?',
        scrollToItem: '<?',
        editing: '<?'
    },
    template,
    controller
}));
