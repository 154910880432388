(function() {
    'use strict';

    angular.module('serviceApp').directive('cardActionsGreeting', cardActionsGreeting);

    function cardActionsGreeting() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                user: '<',
                query: '=',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/actionsGreeting.html'),
            controller: CardActionsGreetingController
        };

        return directive;
    }

    CardActionsGreetingController.$inject = ['$scope', 'moment', '$filter'];

    function CardActionsGreetingController($scope, $moment, $filter) {
        $scope.filterContent = filterContent;
        $scope.getGreetingTitle = getGreetingTitle;
        $scope.getGreetingSubTitle = getGreetingSubTitle;

        $scope.greetingTitle = $scope.getGreetingTitle();
        $scope.greetingSubTitle = $scope.getGreetingSubTitle();

        $scope.$root.$on('actionsChanged', function(event, data) {
            if (data.contentId && data.signals) {
                var contentIndex = _.findIndex(_.get($scope.card, 'properties.content'), [
                    'id',
                    data.contentId
                ]);
                if (contentIndex > -1) {
                    $scope.card.properties.content[contentIndex].signals = data.signals;
                    $scope.greetingSubTitle = $scope.getGreetingSubTitle();
                }
            }
        });

        function getGreetingTitle() {
            var userFirstName = _.get($scope.user, 'profile.name');
            if (userFirstName) {
                userFirstName = _.first(userFirstName.split(' '));
            }

            $scope.timeOfDayString = '';
            var date = new Date();
            var hour = date.getHours();

            if (hour <= 11 && hour > 5) {
                $scope.timeOfDayString = 'morning';
            } else if (hour < 17 && hour > 11) {
                $scope.timeOfDayString = 'afternoon';
            } else {
                $scope.timeOfDayString = 'evening';
            }

            var greetingTitle =
                'Good ' + $scope.timeOfDayString + (userFirstName ? ', ' + userFirstName + '.' : '.');
            return greetingTitle;
        }

        function getGreetingSubTitle() {
            var content = _.get($scope.card, 'properties.content');
            content = $scope.filterContent(content);

            if (content && content.length > 0) {
                content = _.groupBy(content, 'contentType');

                var greetingSubTitle = '';
                var itemCount = 0;
                _.forEach(content, function(value, key) {
                    var label = getPluraized(value.length, key) + (value.length === 1 ? ' needs' : ' need');
                    if (greetingSubTitle !== '') {
                        if (key == _.last(_.keys(content))) {
                            greetingSubTitle =
                                greetingSubTitle +
                                (itemCount > 1
                                    ? ', and ' + value.length + ' ' + label
                                    : ' and ' + value.length + ' ' + label);
                        } else {
                            greetingSubTitle = greetingSubTitle + ', ' + value.length + ' ' + label;
                        }
                    } else {
                        greetingSubTitle = value.length + ' ' + label;
                    }

                    itemCount++;
                });

                greetingSubTitle = greetingSubTitle + ' your attention today.';
                return greetingSubTitle;
            } else {
                return 'Nothing needs your attention right now.';
            }
        }

        function getPluraized(len, string) {
            if (len >= 2) {
                return $filter('pluralize')(string);
            } else {
                return string;
            }
        }

        function filterContent(content) {
            content = _.filter(content, function(contentItem) {
                var keep = true;

                var filteredSignals = _.filter(contentItem.signals, function(signal) {
                    return !!_.find(signal.actions, function(action) {
                        return action.state != 'dismiss';
                    });
                });

                keep = !_.isEmpty(filteredSignals);

                return keep;
            });

            return content;
        }
    }
})();
