'use strict';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('formPropertyHistory', () => ({
    restrict: 'A',
    scope: {
        form: '=',
        formModel: '=formPropertyHistory'
    },
    template,
    controller: Controller
}));
