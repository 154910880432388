(function() {
    'use strict';

    angular.module('serviceApp').directive('cardAuthenticationConfig', cardAuthenticationConfig);

    function cardAuthenticationConfig() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                team: '=',
                user: '='
            },
            template: require('views/tmpl/cards/settings/team/cardAuthenticationConfig.html'),
            controller: cardAuthenticationConfigController
        };

        return directive;
    }

    cardAuthenticationConfigController.$inject = [
        '_',
        '$scope',
        '$rootScope',
        'teamServiceNew',
        'toastr',
        '$state'
    ];

    function cardAuthenticationConfigController(_, $scope, $rootScope, teamServiceNew, toastr, $state) {
        $scope.showCard = false;
        $scope.toggleUserAgreement = false;
        $scope.productName = $rootScope.productName;
        $scope.auth = {
            saml: {
                cert: '',
                enabled: false,
                entryPoint: '',
                issuer: ''
            },
            idleSession: {
                timeout: null,
                period: null
            }
        };
        $scope.idleSessionTimeoutPeriods = [
            { display: '-- Select Unit --', value: null },
            { display: 'minutes', value: 'minutes' },
            { display: 'hours', value: 'hours' },
            { display: 'days', value: 'days' },
            { display: 'weeks', value: 'weeks' },
            { display: 'months', value: 'months' }
        ];

        $scope.forms = {};

        $scope.appDetection = _.get($scope, 'team.policy.appDetection', 'enable');

        teamServiceNew.getTeamAuth($scope.team.id).then(function(auth) {
            if (auth.saml) {
                $scope.auth.saml = auth.saml;
            }
            if (auth.idleSession) {
                $scope.auth.idleSession = auth.idleSession;
            }
            $scope.authBackup = _.cloneDeep($scope.auth);
            $scope.showCard = true;
        });

        $scope.updatedTeam = _.cloneDeep($scope.team);
        $scope.teamBackup = _.cloneDeep($scope.team);

        $scope.$root.$on('updateTeamDetails', (event, data) => {
            $scope.updatedTeam = data;
            if (_.get($scope.forms, 'teamDetails')) {
                $scope.forms.teamDetails.$setDirty();
            }
        });

        // save updates to team profile & auth
        $scope.$on('panel:save', async () => {
            var teamAuthForm = $scope.forms.teamAuth;
            var userAgreementForm = $scope.forms.userAgreement;
            if (teamAuthForm && teamAuthForm.$dirty && !teamAuthForm.$invalid) {
                try {
                    $scope.auth = await teamServiceNew.postTeamAuth($scope.team.id, $scope.auth);
                    $scope.authBackup = _.cloneDeep($scope.auth);
                    teamAuthForm.$setPristine();
                    teamAuthForm.$setUntouched();
                    toastr.success('Team Authentication Updated!', '');
                } catch (error) {
                    toastr.error('Updating Team Authentication Failed: ' + error.msg, '');
                }
            }

            if (
                (userAgreementForm && userAgreementForm.$dirty && !userAgreementForm.$invalid) ||
                $scope.updatedTeam ||
                $scope.toggleUserAgreement
            ) {
                _.unset($scope.team, 'userAgreement');
                if ($scope.updatedTeam) {
                    //Want to not ride userAgreement updates
                    _.unset($scope.updatedTeam, 'userAgreement');
                    $scope.team =
                        userAgreementForm && userAgreementForm.$dirty
                            ? _.set(
                                  $scope.updatedTeam,
                                  'userAgreement',
                                  _.get($scope.teamBackup, 'userAgreement')
                              )
                            : $scope.updatedTeam;
                } else if (userAgreementForm && userAgreementForm.$dirty && !userAgreementForm.$invalid) {
                    $scope.team = _.set(
                        $scope.team,
                        'userAgreement',
                        _.get($scope.teamBackup, 'userAgreement')
                    );
                }

                if ($scope.toggleUserAgreement) {
                    $scope.team = _.set(
                        $scope.team,
                        'userAgreement.enabled',
                        _.get($scope.teamBackup, 'userAgreement.enabled')
                    );
                }

                const propertiesForWarning = [
                    'fiscalMonthOffset',
                    'useRetailCalendar',
                    'retailCalendarType',
                    'lastDayOfWeek',
                    'lastWeekCalculation'
                ];
                if (
                    !_.isEqual(
                        _.pick($scope.updatedTeam, propertiesForWarning),
                        _.pick($scope.teamBackup, propertiesForWarning)
                    )
                ) {
                    $scope.showChangeTeamCalendarModal = true;
                }
                if (!_.isEqual($scope.updatedTeam.timezone, $scope.teamBackup.timezone)) {
                    $scope.showChangeTeamTimezoneModal = true;
                }
                if (!($scope.showChangeTeamCalendarModal || $scope.showChangeTeamTimezoneModal)) {
                    await $scope.updateTeam(userAgreementForm);
                }
            }
        });

        $scope.updateTeam = async function(userAgreementForm) {
            try {
                $scope.team = await teamServiceNew.updateTeam($scope.team.id, $scope.team);
                $scope.teamBackup = _.cloneDeep($scope.updatedTeam);
                toastr.success('Team Updated!', '');
                userAgreementForm.$setPristine();
                userAgreementForm.$setUntouched();
                //Set the upstream team details form to clean after a save
                $scope.forms.teamDetails.$setPristine();
                $scope.forms.teamDetails.$setUntouched();
                $scope.toggleUserAgreement = false;
            } catch (error) {
                console.error(error);
                toastr.error('Updating team failed: ' + error.msg, '');
            }
        };

        $scope.deleteTeam = () => {
            return teamServiceNew
                .deleteTeam($scope.team.id)
                .then(() => {
                    toastr.success('Deleting Team in the Background...', '');
                    $rootScope.$emit('logout');
                    $state.go('core.login');
                })
                .catch(error => {
                    toastr.error('An Error occurred: ' + error.msg, '');
                });
        };

        $scope.deleteTeamContent = () => {
            teamServiceNew
                .deleteTeamContent($scope.team.id)
                .then(() => {
                    toastr.success('Deleting Team Content in the Background...', '');
                    $scope.toggleShowModal('showDeleteTeamContentModal');
                })
                .catch(error => {
                    toastr.error('An Error occurred: ' + error.msg, '');
                });
        };

        $scope.toggleShowModal = modal => {
            if (modal == 'showDeleteTeamModal') {
                $scope.showDeleteTeamModal = !$scope.showDeleteTeamModal;
            } else if (modal == 'showDeleteTeamContentModal') {
                $scope.showDeleteTeamContentModal = !$scope.showDeleteTeamContentModal;
            }
        };

        $scope.toggleCalendarChangeModal = function(toggle) {
            $scope.showChangeTeamCalendarModal = toggle;
        };

        $scope.toggleTimezoneChangeModal = function(toggle) {
            $scope.showChangeTeamTimezoneModal = toggle;
        };
    }
})();
