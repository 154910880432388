'use strict';
import _ from 'lodash';
import TagInputController from '../../tags/tag-input/controller';

export default class PropertyTagInputController extends TagInputController {
    static get $inject() {
        return ['$scope', 'contentTypeService'];
    }

    constructor(scope, contentTypeService) {
        super(scope);
        this.contentType = scope.contentType;
        this.contentTypeService = contentTypeService;
    }

    /**
     * Get tags matching the query and excluding existing tags
     */
    searchTags() {
        // If we haven't gotten the set of tags for this content type yet, do so now.
        if (!this.allTags) {
            // Get display options for all content types. Always get from the server so that
            // recently added tags are reflected in the list.
            return this.contentTypeService
                .getContentDisplayOptions({
                    useCache: false
                })
                .then(contentDisplayOptions => {
                    // Get tags for the content type of this property.
                    this.allTags = _.keys(
                        _.get(contentDisplayOptions, [this.contentType, 'propertyTags'], {})
                    );

                    // Remove _ungrouped.
                    _.pull(this.allTags, '_ungrouped');

                    this.allTags = _.map(this.allTags, tag => ({ label: tag }));

                    return this.handleSearchResults();
                });
        } else {
            return this.handleSearchResults();
        }
    }

    handleSearchResults() {
        // Get the current tags on this property.
        const currentTags = _.get(this.scope, 'definition.tags', []);

        // Remove the current tags from the list to display.
        let tags = _.reject(this.allTags, tag => _.find(currentTags, { label: tag.label }));

        // Match tags against the query, if any.
        const query = _.get(this.scope, 'query.str');
        if (query) {
            tags = _.filter(tags, tag => tag.label.match(new RegExp(query, 'i')));
        }

        return this.handleSearchResult(tags);
    }
}
