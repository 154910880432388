'use strict';
import _ from 'lodash';
import { BaseFormWidgetController } from '../../scripts/utils/form-utils.js';

export default class FormBooleanController extends BaseFormWidgetController {
    constructor(scope, rootScope, filter) {
        super(scope, rootScope, filter);
        this.init();
    }

    /**
     * Builds a list of indicators of type boolean.
     *
     * only hit when wrapPliable: true so don't need the true/false case
     *
     * @param {String} args.label - Label describing the property
     * @param {Object} args.form - Form for the property
     * @param {Object} args.formModel - Model for the property
     * @param {Function} args.translateValue - Transformation function for a properties value
     * @returns
     */
    static buildIndicator({ label, form, formModel, translateValue = null }) {
        const valueProperty = _.get(form, 'format.valueProperty');
        let value = _.get(formModel, [valueProperty]);

        const doesNotInclude = _.isArray(_.get(formModel, ['##NOT##', valueProperty]));
        // Imitate multi-select indicator for form-booleans that will use a multi-select widget
        if (_.get(form, 'enum') && (_.isArray(value) || doesNotInclude)) {
            const multiSelectValues = _.get(formModel, ['##NOT##', valueProperty], value);
            if (form.allowIncludeMissing === false) {
                _.remove(multiSelectValues, function(d) {
                    return d === '##MISSING##';
                });
            }

            const valueString = _.reduce(
                multiSelectValues,
                (accumulator, currentValue, index, array) => {
                    // If we have a custom format configured for the given property try translating the value
                    if (
                        currentValue !== '##MISSING##' &&
                        translateValue &&
                        typeof translateValue === 'function'
                    ) {
                        currentValue = translateValue({
                            contentType: form.contentType,
                            propertyName: valueProperty,
                            propertyValue: currentValue
                        });
                    }

                    currentValue = _.replace(currentValue, /#/g, '');

                    if (index === 0) {
                        return accumulator + currentValue;
                    }

                    if (index === array.length - 1) {
                        return `${accumulator} or ${currentValue}`;
                    }

                    return `${accumulator}, ${currentValue}`;
                },
                ''
            );

            return `${label} ${doesNotInclude ? 'is not' : 'is'} ${valueString}`;
        }

        if (value === true || value === false) {
            return `${label} is ${value}`;
        }

        value = _.get(formModel, ['##OR##', 0, valueProperty]);

        if (value === true || value === false) {
            return `${label} is ${value ? 'true' : 'false or missing'}`;
        }

        return `${label} is unknown`;
    }

    init() {
        this.scope.modelChanged = this.modelChanged.bind(this);
        this.scope.removeSelf = () =>
            this.scope.$emit('removeFilterItem', { property: _.first(_.get(this.scope, 'form.key', [])) });

        this.setDefaultValue();
    }

    setDefaultValue() {
        if (this.scope.wrapPliable) {
            return this.setDefaultWrapPliableValue();
        }

        return this.modelChanged(this.scope.formModel);
    }

    setDefaultWrapPliableValue() {
        const legacyPath = ['formModel', this.scope.valueProperty];
        const legacyValue = _.get(this.scope, legacyPath);
        const hasLegacyValue = legacyValue === true || legacyValue === false;

        const basePath = ['formModel', '##OR##', 0, this.scope.valueProperty];
        const currentValue = _.get(this.scope, basePath);
        const hasValue = currentValue === true || currentValue === false;

        if (hasValue) {
            this.modelChanged(currentValue);
        } else if (hasLegacyValue) {
            this.modelChanged(legacyValue);
        } else {
            //default to true
            this.modelChanged(true);
        }
    }

    modelChanged(suppliedValue) {
        let newValue;
        if (!_.isNil(suppliedValue)) {
            newValue = suppliedValue;
        } else {
            newValue = _.get(this.scope, 'options.includes', true);
        }

        let newFormModel;

        if (this.scope.wrapPliable) {
            newFormModel = {
                '##OR##': [{ [this.scope.valueProperty]: newValue }]
            };

            if (newValue === false) {
                newFormModel['##OR##'].push({ [this.scope.valueProperty]: '##MISSING##' });
            }
        } else {
            newFormModel = !!newValue;
        }

        // set the model incase this is a supplied value or init
        _.set(this.scope, 'options.includes', newValue);
        _.set(this.scope, 'formModel', newFormModel);
    }
}
