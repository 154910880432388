'use strict';
import template from './index.html';
import Controller from './controller';

// import children directives
import './shared/list-label-builder';
import './shared/template-string-builder';

angular.module('serviceApp').directive('formObjectSchemaItem', () => ({
    restrict: 'E',
    scope: {
        remover: '&',
        property: '=',
        localContext: '=',
        inheritedContext: '=',
        supplementalSchema: '=?'
    },
    template,
    controller: Controller
}));
