(function() {
    'use strict';
    angular.module('serviceApp').filter('humanDuration', HumanDuration);

    HumanDuration.$inject = ['_', 'moment', '$filter'];

    function HumanDuration(_, $moment, $filter) {
        var filter = function(n) {
            n = _.replace(n, /<strong>/g, '');
            n = _.replace(n, /<\/strong>/g, '');

            var inputMoment = $moment(n);
            var now = $moment();

            if (!inputMoment.isValid()) {
                return 'N/A';
            }

            var dur = $moment.duration(now.diff(inputMoment)).asMilliseconds();
            dur = dur > 0 ? dur : dur * -1;

            return $moment.duration(dur).humanize();
        };

        return filter;
    }
})();
