(function() {
    'use strict';

    angular.module('serviceApp').directive('cardDynamicFacets', cardDynamicFacets);

    function cardDynamicFacets() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                panel: '=',
                external: '=?',
                user: '<',
                team: '<',
                facets: '=?',
                resizeCallbackHitch: '&',
                popoutCallbackHitch: '&',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/dynamicFacets.html'),
            controller: CardDynamicFacetsController
        };

        return directive;
    }

    CardDynamicFacetsController.$inject = ['_', '$scope'];

    function CardDynamicFacetsController(_, $scope) {
        $scope.applyFilter = applyFilter;
        $scope.startCase = startCase;
        $scope.currentFilter = _.get($scope, 'facets.cards.content.type');

        function applyFilter(filter) {
            $scope.currentFilter = filter;
            $scope.$root.$broadcast($scope.board.shortcode + '-filter-content', filter);
        }

        function startCase(input) {
            return _.startCase(input);
        }
    }
})();
