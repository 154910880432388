'use strict';

import controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('drawerButton', () => ({
    restrict: 'E',
    scope: {
        tooltip: '<?',
        isDark: '<?',
        isLight: '<',
        type: '<'
    },
    template,
    controller
}));
