'use strict';
import _ from 'lodash';
import { createContextBreakdown } from '../../scripts/utils/form-utils';

export default class TrendContainer {
    static get $inject() {
        return ['$scope', 'definitionService'];
    }

    constructor(scope, definitionService) {
        this.scope = scope;
        this.definitionService = definitionService;

        this.scope.addTrendItem = this.addTrendItem.bind(this);
        this.scope.hidePropsDrop = this.hidePropsDrop.bind(this);
        this.scope.toggleShowProps = this.toggleShowProps.bind(this);

        // watch for config changes
        const updateWatcher = this.scope.$on('trend-config-updated', (event, newDefinition) => {
            this.scope.definition = newDefinition;
            this.init();
        });

        // watch for config removal event
        const removeWatcher = this.scope.$on('trend-config-removed', (event, index) => {
            this.removeTrendItem(index);
        });

        // watch for changes on the trend to alert the parent
        const trendWatcher = this.scope.$watch(
            'definition.propertyValues.trend',
            (newVal, oldVal) => {
                if (!_.isEqual(newVal, oldVal)) {
                    this.scope.eventHandler({ data: { trendValues: newVal, eventType: 'updateTrend' } });
                }
            },
            true
        );

        const contextWatcher = this.scope.$watch(
            'definition.action.availableContext',
            () => {
                const newContext = _.get(this.scope, 'definition.action.availableContext', []);
                this.scope.contextBreakdown = createContextBreakdown(newContext, true);
                this.scope.eventHandler({
                    data: { context: newContext, eventType: 'updateAvailableContext' }
                });
            },
            true
        );

        this.scope.$on('$destroy', () => {
            updateWatcher();
            removeWatcher();
            trendWatcher();
            contextWatcher();
        });

        this.init();
    }

    /**
     * init - initialize scope variables
     */
    init() {
        this.scope.propertySchema = _.get(this.scope, 'definition.propertySchema.trend', {});
        this.scope.propertyValues = _.get(this.scope, 'definition.propertyValues.trend', {});
    }

    /**
     * toggleShowProps - toggles to trend type drop down
     */
    toggleShowProps() {
        this.scope.showPropsDrop = !this.scope.showPropsDrop;
    }

    /**
     * hidePropsDrop - hides the trend type drop down
     */
    hidePropsDrop() {
        this.scope.showPropsDrop = false;
    }

    /**
     * redraw - resolve the given definition and reinitialize the view
     * @param {Object} [definition = definition] - if supplied redraw with the supplied definition,
     * otherwise use the scope definition
     */
    redraw(definition = this.scope.definition) {
        this.scope.$root._trendLoading = true;
        this.definitionService
            .resolve({
                definition,
                resolveFilters: false,
                resolveAudience: false,
                resolveActionAvailableContext: true,
                resolveEventTriggers: false,
                resolveTrendConfiguration: true
            })
            .then(newDefinition => {
                this.scope.definition = newDefinition;
                this.init();
                this.scope.$root._trendLoading = false;
            })
            .catch(function(error) {
                console.error(error);
                _.set(this.scope, '$root._trendLoading', false);
            });
    }

    /**
     * removeTrendItem - remove the given trend config from the selectedTrends array and redraw
     * @param {Number} index - index of the trend config to remove
     */
    removeTrendItem(index) {
        const selectedTrends = _.get(this.scope, 'definition.propertyValues.trend.selectedTrends', []);
        _.pullAt(selectedTrends, index);
        const clonedDefinition = _.cloneDeep(this.scope.definition);
        _.set(clonedDefinition, 'propertyValues.trend.selectedTrends', selectedTrends);

        this.redraw(clonedDefinition);
    }

    /**
     * addTrendItem - add the given newTrendItem to the selectedTrends array and redraw
     * @param {Object} newTrendItem - trend config to add
     */
    addTrendItem(newTrendItem) {
        const selectedTrends = _.get(this.scope, 'definition.propertyValues.trend.selectedTrends', []);
        selectedTrends.push(newTrendItem);
        const clonedDefinition = _.cloneDeep(this.scope.definition);
        _.set(clonedDefinition, 'propertyValues.trend.selectedTrends', selectedTrends);

        this.redraw(clonedDefinition);
    }
}
