import template from './index.html';
import controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('boardPills', () => ({
    restrict: 'E',
    scope: {
        board: '<?',
        filterData: '<',
        user: '<',
        hierarchy: '=?',
        save: '&?'
    },
    template,
    controller
}));
