(function() {
    'use strict';
    angular.module('serviceApp').directive('rightContent', rightContent);
    function rightContent() {
        var directive = {
            restrict: 'E',
            scope: {},
            template: require('views/tmpl/partials/rightContent.html'),
            controller: RightContentController
        };

        return directive;
    }

    RightContentController.$inject = ['_', '$scope', '$rootScope', '$log', '$timeout'];

    function RightContentController(_, $scope, $rootScope, $log, $timeout) {
        $rootScope.rightContentOpen = false;
        $scope.log = console.log;
        $scope.toggleContent = function(open, event, data) {
            //timeout required do not remove
            $timeout(function() {
                $scope.contentData = data;
                $rootScope.rightContentOpen = _.isNil(open) ? !$rootScope.rightContentOpen : open;
            }, 0);
        };

        const openContent = _.partial($scope.toggleContent, true);
        const closeContent = _.partial($scope.toggleContent, false);
        const listeners = [
            $rootScope.$on('rightContent:open', openContent),
            $rootScope.$on('rightContent:close', closeContent),
            $rootScope.$on('$stateChangeSuccess', closeContent)
        ];
        $scope.$on('$destroy', () => _.each(listeners, listenerCleanUp => listenerCleanUp()));
    }
})();
