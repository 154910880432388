(function() {
    'use strict';

    angular.module('serviceApp').directive('appErrorIndicator', appErrorIndicator);

    function appErrorIndicator() {
        var directive = {
            restrict: 'E',
            scope: {
                user: '<'
            },
            template: require('views/tmpl/partials/appErrorIndicator.html'),
            controller: AppErrorIndicatorController
        };

        return directive;
    }

    AppErrorIndicatorController.$inject = [
        '_',
        '$state',
        '$scope',
        '$q',
        'integrationService',
        'boardsUtilsService'
    ];

    function AppErrorIndicatorController(_, $state, $scope, $q, integrationService, boardsUtilsService) {
        $scope.showError = false;
        $scope.showDetails = false;
        $scope.errors = [];
        _.forEach(_.get($scope.user, 'integrations'), function(integration) {
            if (!_.has(integration, 'lastRunError')) {
                return;
            }

            var error = _({})
                .set('lastRunError', _.get(integration, 'lastRunError'))
                .set('integrationId', _.get(integration, 'integrationId'))
                .value();

            $scope.errors.push(error);
        });

        var errorPromises = [];
        _.forEach($scope.errors, function(error) {
            errorPromises.push(integrationService.getUserIntegration(error.integrationId));
        });

        $q.all(errorPromises).then(function(results) {
            _.forEach(results, function(result) {
                var index = _.findIndex($scope.errors, {
                    integrationId: _.get(result, 'integration.integrationId')
                });

                if (index != -1) {
                    var mergedObject = _.merge(_.get($scope.errors, index), result);

                    _.set($scope.errors, index, mergedObject);
                }
            });

            if (!$scope.errors || _.isEmpty($scope.errors)) {
                $scope.showError = false;
                return;
            }

            $scope.showError = true;
        });

        $scope.dismiss = function() {
            $scope.showError = false;
        };

        $scope.$root.$on('integrationDeleted', function(event, data) {
            _.remove($scope.errors, function(e) {
                return e.integrationId == data;
            });

            if (!$scope.errors || _.isEmpty($scope.errors)) {
                $scope.showError = false;
            }
        });

        $scope.goToAppBoard = function(erroredIntegration) {
            var boardId = _.get(erroredIntegration, 'integration.app.boardId');

            if (!boardId) {
                return;
            }

            boardsUtilsService.openBoardManager({
                id: boardId
            });
        };
    }
})();
