'use strict';
import _ from 'lodash';

/**
 * icon viewer is used to show the icons for the product based on URL, or internal, .
 *
 * @export
 * @class Controller
 */

export default class Controller {
    static get $inject() {
        return ['$scope', '$rootScope'];
    }
    constructor($scope, $rootScope) {
        this.scope = $scope;
        this.rootScope = $rootScope;
        this.scope.loading = true;
        if (this.scope.defaultIconType === 'url') {
            this.scope.defaultSource = this.scope.defaultIcon;
        } else {
            this.scope.defaultSource = `/images/static/${this.scope.defaultIcon ||
                'productIconLogoDarkSmall'}.svg`;
        }
    }
}
