'use strict';

/**
 * @ngdoc function
 * @name serviceApp.controller:PagesSignupCtrl
 * @description
 * # PagesSignupCtrl
 * Controller of the serviceApp
 */
angular.module('serviceApp').controller('SignupCtrl', [
    '_',
    '$cookies',
    '$scope',
    '$rootScope',
    '$location',
    '$log',
    '$state',
    '$stateParams',
    '$window',
    'userServiceNew',
    function(
        _,
        $cookies,
        $scope,
        $rootScope,
        $location,
        $log,
        $state,
        $stateParams,
        $window,
        userServiceNew
    ) {
        userServiceNew.isAuthenticated().then(hasAuth => {
            if (hasAuth) {
                $state.go('home.main');
            }
        });

        if ($rootScope.cachedCreds) {
            $scope.credentials = $rootScope.cachedCreds;
            $rootScope.cachedCreds = null;
        } else {
            $scope.credentials = {};
        }

        // A secret that must be provided in production environment for signing up new team
        _.set($scope.credentials, 'teamCreateSecret', _.get($stateParams, 'teamCreateSecret'));

        $scope.showModal = false;
        $rootScope.useHeader = false;
        $scope.showSignup = true;
        $scope.hasError = false;

        $scope.emailBlackList = [
            'gmail.com',
            'hotmail.com',
            'yahoo.com',
            'ymail.com',
            'outlook.com',
            'prodigy.com',
            'prodigy.net',
            'aol.com',
            'earthlink.net'
        ];

        $scope.signup = function() {
            $scope.signupfailure = '';

            var data = {
                name: $scope.credentials.name,
                email: $scope.credentials.email,
                teamTitle: $scope.credentials.team,
                teamShortcode: $scope.credentials.team,
                username: $scope.credentials.email,
                password: $scope.credentials.password,
                teamCreateSecret: $scope.credentials.teamCreateSecret
            };

            $log.debug('attempting to signup user');

            _.forEach($scope.emailBlackList, function(email) {
                if (data.email.toLowerCase().includes(email)) {
                    return ($scope.showModal = true);
                }
            });

            if (!$scope.showModal) {
                userServiceNew.signUp(data).then(
                    function() {
                        $log.debug('created user, log in now');
                        userServiceNew.login(data).then(function() {
                            const domain = $cookies.get('serviceDomain');
                            const state = $rootScope.isMobile ? 'mobile.home' : 'home.main';
                            const redirectUrl = `${$location.protocol()}://${domain ||
                                $location.host()}/${$state.href(state)}`;
                            $window.location.href = redirectUrl;

                            // if we're already on the correct domain, only the hash will change which won't
                            // do a full page load. We want a full page load so we don't have to micromanage
                            // state transitions here for very little gain
                            if ($window.location.hostname === domain) {
                                window.location.reload(true);
                            }
                        });
                    },
                    function(error) {
                        $log.debug('Error from userServiceNew.signup: ', error);
                        $scope.signupFailure = error;
                        if (error.data.error == 'A user with that email address is already registered') {
                            $scope.credentials.email = '';
                        }
                        $scope.hasError = true;
                    }
                );
            }
        };

        $scope.goToContact = function() {
            $scope.showSignup = false;
            $state.go('core.contact');
        };

        $scope.toggleModal = function() {
            $scope.showModal = false;
        };

        $scope.closeError = function() {
            $scope.hasError = false;
        };

        $scope.toLogin = function() {
            return $state.go('core.login');
        };
    }
]);
