'use strict';
import _ from 'lodash';

const basePath = ['formModel', '##AND##'];
const eventDatePath = ['##HAS_EVENT##', 'filter', '##DATERANGE##', 'eventDate'];
const hasLtPath = [...basePath, ...eventDatePath, 'lt'];
const hasGtePath = [...basePath, ...eventDatePath, 'gte'];
const hasNotGtPath = [...basePath, '##NOT##', ...eventDatePath, 'gt'];
const hasNotGtePath = [...basePath, '##NOT##', ...eventDatePath, 'gte'];

const rangePath = [...basePath, '##HAS_EVENT##', 'filter', '##RANGE##'];
const rangePathNot = [...basePath, '##NOT##', '##HAS_EVENT##', 'filter', '##RANGE##'];
const eventRangePath = ['##HAS_EVENT##', 'filter', '##RANGE##', 'eventDate'];
const hasLtRangePath = [...basePath, ...eventRangePath, 'lt'];
const hasGteRangePath = [...basePath, ...eventRangePath, 'gte'];
const hasNotGtRangePath = [...basePath, '##NOT##', ...eventRangePath, 'gt'];
const hasNotGteRangePath = [...basePath, '##NOT##', ...eventRangePath, 'gte'];

const allPaths = { hasGtePath, hasLtPath, hasNotGtPath, hasNotGtePath };
const allRangePaths = { hasGteRangePath, hasLtRangePath, hasNotGtRangePath, hasNotGteRangePath };

const buckets = {
    theLast: {
        paths: { hasGtePath, hasLtPath, hasNotGtPath },
        pathDefaults: {
            hasGtePath: (days = 7) => `now-${days}d`,
            hasLtPath: () => 'now',
            hasNotGtPath: () => 'now'
        },
        pathRegex: {
            hasGtePath: new RegExp(/now-(\d*)d/, 'g'),
            hasLtPath: 'now',
            hasNotGtPath: 'now'
        },
        buildIndicator: days => {
            if (!days) {
                return 'right now';
            } else if (days === 1) {
                return `in the last day`;
            } else {
                return `in the last ${days} days`;
            }
        }
    },
    moreThan: {
        paths: { hasLtPath, hasNotGtePath },
        pathDefaults: {
            hasLtPath: (days = 7) => `now-${days}d`,
            hasNotGtePath: (days = 7) => `now-${days}d`
        },
        pathRegex: {
            hasLtPath: new RegExp(/now-(\d*)d/, 'g'),
            hasNotGtePath: new RegExp(/now-(\d*)d/, 'g')
        },
        buildIndicator: days => {
            if (!days) {
                return 'before now';
            } else if (days === 1) {
                return `before yesterday`;
            } else {
                return `more than ${days} days ago`;
            }
        }
    }
};

/**
 * Update filters that are using ##RANGE## for date range filters to use
 * ##DATERANGE##.
 *
 * We are deprecating the use of ##RANGE## for date range queries so this
 * is required until all date range queries use ##DATERANGE##
 */
const useDateRangeFilter = filterVal => {
    // replace any use of range paths with the date range path
    _.forEach(allRangePaths, path => {
        if (_.has(filterVal, path)) {
            const idx = _.indexOf(path, '##RANGE##');
            const newPath = [...path.slice(0, idx), '##DATERANGE##', ...path.slice(idx + 1)];
            _.set(filterVal, newPath, _.get(filterVal, path));
        }
    });
    // remove range paths from the filter
    if (_.has(filterVal, rangePath)) {
        _.unset(filterVal, rangePath);
    }
    if (_.has(filterVal, rangePathNot)) {
        _.unset(filterVal, rangePathNot);
    }
    return filterVal;
};

/**
 * PropertyHistoryController - controls the Property history form widget for finding a set of
 * data with updates in a given time frame
 */
export default class Controller {
    // angular dependency injector
    static get $inject() {
        return ['$scope', '$sce', '$log'];
    }

    constructor($scope, $sce, $log) {
        this.scope = useDateRangeFilter($scope);
        this.sce = $sce;
        this.log = $log;

        this.scope.relativeDate = {};

        this.scope.setPaths = this.setPaths.bind(this);
        this.scope.setBucket = this.setBucket.bind(this);
        this.scope.removeSelf = this.removeSelf.bind(this);
        this.scope.updateRelativeDate = this.updateRelativeDate.bind(this);
        this.scope.isDark = _.get(this.scope, 'form.ngModelOptions.isDark', false);

        this.scope.gte = {};
        this.scope.lte = {};

        this.scope.removable = _.get(this.scope, 'form.schema.removable', true);
        this.scope.requiredDefault = _.get(this.scope, 'form.schema.requiredDefault', false);

        this.scope.tooltipContent = $sce.trustAsHtml(
            '<div class="primary-text color-white size-small">Last Modified</div><div class="primary-text color-mercury light size-small">Report on the last time a property was modified.</div>'
        );

        //init
        const { initBucket, initDays } = Controller.getDefaultBucket(this.scope, this.log);
        this.scope.setBucket(initBucket, initDays);
    }

    /**
     * getDefaultBucket - given a filter value, parse the data and return the bucket and days
     * @param {Object} value - the filter value to be parsed
     * @param {Object} log - $log object
     * @returns {Object} { initBucket, initDays } - initBucket: name of the bucket parsed, initDays: number of days parsed
     */
    static getDefaultBucket(value, log) {
        let initDays = 7;
        let initBucket = 'theLast';

        value = useDateRangeFilter(value);

        // loop over the buckets and try to find one that matches the value supplied
        _.forEach(buckets, (bucket, bucketName) => {
            const paths = bucket.paths;

            // first check if all the paths are populated
            const allPathsMatch = _.every(paths, path => _.get(value, path));

            //if not continue iterating
            if (!allPathsMatch) {
                return;
            }

            const pathRegex = bucket.pathRegex;
            let days;

            // all the regex path patterns must match
            const allRegexMatch = _.every(pathRegex, (regex, pathName) => {
                const path = _.get(paths, pathName);
                const pathValue = _.get(value, path);

                // some regex path patterns are strings like 'now', test for direct match
                if (_.isString(regex) && regex === pathValue) {
                    return true;
                }

                // all regex right now has a capture group so the expected size is 2
                // this can change in the future and will break this logic
                const execResult = new RegExp(regex).exec(pathValue);
                if (_.size(execResult) !== 2) {
                    log.debug(`No match`, { bucketName, pathName });
                    return false;
                }

                const execResultDays = _.parseInt(_.get(execResult, 1));

                // if the number of days change in the same bucket parse there is an error
                if (!_.isNil(days) && days !== execResultDays) {
                    log.error('Days do not match');
                    return false;
                }

                days = execResultDays;
                return true;
            });

            if (!allRegexMatch) {
                return;
            }

            if (!_.isNil(days)) {
                initDays = days;
            }

            initBucket = bucketName;
            // stop iteration
            return false;
        });

        return { initBucket, initDays };
    }

    /**
     * getIndicator - build pill indicator
     * @param {String} bucket - bucket name
     * @param {Number} days - number of days
     * @returns {String|undefined} if bucket does not exist undefined is returned, otherwise the indicator string is returned
     */
    static getIndicator(bucket, days) {
        const selectedBucket = buckets[bucket];
        if (!selectedBucket) {
            return;
        }

        return selectedBucket.buildIndicator(days);
    }

    /**
     * setBucket - given a bucketName set the appropriate scope variables
     * @param {String} bucket - a bucket name
     * @param {Number} days - number of days for the relative date
     */
    setBucket(bucket, days) {
        if (!buckets[bucket]) {
            this.log.error('Unknown bucket selected', { bucket });
            return;
        }

        if (_.get(this.scope, 'relativeDate.bucket') === bucket) {
            this.log.debug('Bucket already selected', { bucket });
            return;
        }

        _.set(this.scope, 'relativeDate.bucket', bucket);
        if (!_.isNil(days)) {
            _.set(this.scope, 'relativeDate.days', _.parseInt(days));
        }

        this.scope.setPaths();
    }

    /**
     * setPaths - reads the current scope properties for relative date and updates the formModel to reflect
     * the selected bucket's available paths
     */
    setPaths() {
        const bucket = _.get(this.scope, 'relativeDate.bucket');
        const days = _.get(this.scope, 'relativeDate.days');
        const selectedBucket = buckets[bucket];

        // unset all paths
        _.forEach(allPaths, path => {
            _.unset(this.scope, path);
        });

        // set all relevant paths
        _.forEach(selectedBucket.paths, (path, pathName) => {
            _.set(this.scope, path, selectedBucket.pathDefaults[pathName](days));
        });
    }

    /**
     * updateRelativeDate - on user input, update the formModel
     */
    updateRelativeDate() {
        this.scope.setPaths();
    }

    /**
     * removeSelf - emit remove event to remove this widget from the filter
     */
    removeSelf() {
        this.scope.$emit('removeFilterItem', { property: _.first(_.get(this.scope, 'form.key', [])) });
    }
}
