'use strict';
import _ from 'lodash';

const PROPERTY_NAME_HISTORY_PHRASE = '._lastModified';

export default class DataHandlerService {
    constructor(definition) {
        // Cards
        this.cardsStale = false;
        // Definition
        this.definitionIsRemovable = false;
        this.definitionId = definition.id;
        this.definition = definition;
        this.definitionPropertySchema = definition.propertySchema;
        this.definitionPropertyValues = definition.propertyValues;
        const columns = this.buildFilterColumns(definition.propertySchema.filter);
        this.definitionColumns = columns;
        this.definitionHiddenColumns = [];
        this.definitionAvailableProperties = _.get(definition, 'filter.availableProperties', []);
        this.isABDEnabled =
            _.get(definition, 'propertyValues.visualization.configuration.properties.isABDEnabled') || false;
    }

    isLoaded() {
        return true;
    }

    buildFilterColumns(filterSchema) {
        // Don't count parents of nested filters (like `forecastSubmissions`) as columns,
        // since we'll never display them as columns or pills.
        const { _display = {}, ...paths } = _.omitBy(filterSchema, prop => prop.type === 'nested');
        const unHiddenColumns = _.filter(
            Object.keys(paths),
            path => !_.has(_display, path) || !_display[path].hidden
        );
        const columns = _.map(unHiddenColumns, (path, idxInPool) => {
            const isHistory = path.includes(PROPERTY_NAME_HISTORY_PHRASE);
            // find out if the default already in the schema
            const isDefaultAdded = isHistory && !!paths[path.replace(PROPERTY_NAME_HISTORY_PHRASE, '')];
            return {
                path,
                idxInPool,
                hidden: false,
                isHistory,
                isDefaultAdded
            };
        });

        const filteredCols = columns.reduce((finalCols, column) => {
            if (!column.isHistory) {
                finalCols.push(column);
            } else if (column.isHistory && !column.isDefaultAdded) {
                finalCols.push({
                    ...column,
                    path: column.path.replace(PROPERTY_NAME_HISTORY_PHRASE, '')
                });
            }
            return finalCols;
        }, []);

        return filteredCols;
    }
}
