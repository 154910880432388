import template from './index.html';
import controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('collapsibleSearchResults', () => ({
    restrict: 'E',
    scope: {
        filterData: '<',
        pageData: '<',
        isZeroState: '<',
        openFilterEditor: '&',
        user: '<',
        cardView: '<'
    },
    template,
    controller
}));
