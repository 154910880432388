import './index.scss';

import template from './index.html';
import controller from './controller';

/**
 * This card is a quick-and-dirty way to show the content update events with property
 * changes.
 *
 * Usage:
 *
```
{
    name: 'debug-content',
    title: 'Debug Content',
    type: 'debugContentList',
    properties: {
        debugContent: {}
    }
}
```
 */
angular.module('serviceApp').directive('cardDebugContent', () => {
    return {
        restrict: 'E',
        scope: {
            card: '=',
            team: '<',
            user: '<',
            facets: '<',
            hierarchy: '<',
            query: '<',
            getCardHitch: '&',
            getCardsHitch: '&',
            refreshCallbackHitch: '&'
        },
        template,
        controller
    };
});
