class CardCreateFromTemplateModal {
    static get $inject() {
        return ['$rootScope', '$scope', 'definitionService', 'boardSliceService', 'toastr'];
    }
    constructor(rootScope, scope, definitionService, boardSliceService, toastr) {
        this.rootScope = rootScope;
        this.scope = scope;
        this.toastr = toastr;
        this.definitionService = definitionService;
        this.boardSliceService = boardSliceService;

        this.scope.save = this.save.bind(this);
        this.scope.close = this.close.bind(this);
        this.scope.inputChange = this.inputChange.bind(this);
        this.scope.model = { name: '' };
        this.scope.creating = undefined;
        this.scope.error = undefined;

        // Reset the modal when closing
        const typeListener = this.scope.$watch('show', newVal => {
            if (!newVal) {
                this.scope.model.name = '';
                this.scope.error = undefined;
                this.scope.creating = undefined;
            }
        });

        // Clean up
        this.scope.$on('$destroy', () => {
            typeListener();
        });
    }

    /**
     * Closes the modal
     *
     * @memberof CardCreateFromTemplateModal
     */
    close() {
        this.scope.show = undefined;
    }

    /**
     * Clear the error state when the input is changed
     *
     * @memberof CardCreateFromTemplateModal
     */
    inputChange() {
        this.scope.error = undefined;
    }

    /**
     * Create a local report with this template definition
     *
     * @memberof CardCreateFromTemplateModal
     */
    save() {
        this.scope.creating = true;
        const newDisplayName = this.scope.model.name;
        const definitionToSave = _.get(this.boardSliceService, 'definition');
        this.definitionService
            .saveAsDefinition({
                definition: definitionToSave,
                newDisplayName,
                teamId: _.get(this.rootScope, 'user.state.currentTeam'),
                options: { enabled: true }
            })
            .then(updated => {
                this.toastr.success('Report created!');
                this.scope.$emit('render-card', {
                    cardDefinitionId: _.get(updated, 'definition.id'),
                    reload: true,
                    resetOverrides: true
                });
                this.scope.show = undefined;
            })
            .catch(err => {
                this.toastr.error('An error occurred creating a report', err.message);
                console.error(err);
                this.scope.error = err.message;
            })
            .finally(() => {
                this.scope.creating = undefined;
            });
    }
}

export default CardCreateFromTemplateModal;
