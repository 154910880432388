'use strict';

export default class CardInsight {
    static get $inject() {
        return ['$scope'];
    }
    constructor($scope) {
        // Always include Replace and Remove to the menu
        $scope.menuOptions = [
            {
                sectionData: [
                    {
                        text: 'Replace Card',
                        onClick: () => {
                            $scope.$emit('card-editor:toggle-is-open', $scope.card);
                        },
                        icon: ['fal', 'fa-arrow-right-arrow-left']
                    },
                    {
                        text: 'Remove Card',
                        onClick: () => {
                            $scope.$emit('card-editor:remove-card', $scope.card);
                        },
                        icon: ['fal', 'fa-xmark']
                    }
                ]
            }
        ];
    }
}
