import template from './index.html';

import './index.scss';

/**
 * A global styled drop down menu select
 *
 * usage:
 *
 * <dropdown-select ng-disabled="<check>" model="<input model>" type="empty for default or 'grey'" aria-label="<optional select element label>" aria-labelledby="<optional select element labelledby">
 *    <option>...</option>
 *    ...
 * </dropdown-select>
 */
angular.module('serviceApp').directive('dropdownSelect', () => ({
    restrict: 'E',
    scope: {
        type: '<?',
        model: '=?',
        ngDisabled: '=?',
        ariaLabel: '@?',
        ariaLabelledby: '@?'
    },
    template,
    transclude: true,
    controller: [
        '$scope',
        '$attrs',
        '$element',
        function($scope, $attrs) {
            // Initial check for accessibility attributes
            if (!$attrs.ariaLabel && !$attrs.ariaLabelledby) {
                console.error('dropdownSelect requires either ariaLabel or ariaLabelledby attribute.');
            }

            // React to changes in accessibility attributes
            $attrs.$observe('ariaLabel', newValue => {
                if (!newValue && !$attrs.ariaLabelledby) {
                    console.error('dropdownSelect requires either ariaLabel or ariaLabelledby attribute.');
                }
            });

            $attrs.$observe('ariaLabelledby', newValue => {
                if (!newValue && !$attrs.ariaLabel) {
                    console.error('dropdownSelect requires either ariaLabel or ariaLabelledby attribute.');
                }
            });

            $scope.style = $scope.type || '';
        }
    ]
}));
