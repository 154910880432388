'use strict';

import _ from 'lodash';

export default class MathTrendConfig {
    static get $inject() {
        return ['$scope', 'definitionService'];
    }

    constructor(scope, definitionService) {
        this.scope = scope;
        this.definitionService = definitionService;

        this.scope.removeSelf = this.removeSelf.bind(this);
        this.scope.onTitleChanged = this.onTitleChanged.bind(this);
        this.lastTitle = _.get(this.scope, 'trend.title');

        this.scope.$watch('contextBreakdown', () => {
            this.scope.filteredContextBreakdown = this.filterContextBreakdown(this.scope.contextBreakdown);
        });
        this.scope.$watch('trend.expression', (newVal, oldVal) => {
            if (oldVal === newVal) {
                return;
            }

            _.set(
                this.scope,
                `definition.propertyValues.trend.selectedTrends[${this.scope.index}].expression`,
                newVal
            );
        });
        this.scope.$watch('trend.transforms', (newVal, oldVal) => {
            if (oldVal === newVal) {
                return;
            }

            _.set(
                this.scope,
                `definition.propertyValues.trend.selectedTrends[${this.scope.index}].transforms`,
                newVal
            );

            this.redraw();
        });
    }

    filterContextBreakdown(contextBreakdown) {
        let breakdown = _.omit(contextBreakdown, 'date');
        breakdown.trend = _.reduce(
            breakdown.trend,
            (acc, item) => {
                if (item.name === this.scope.trend.id) {
                    return acc;
                }
                acc.push(_.omit(item, 'transforms'));
                return acc;
            },
            []
        );
        return breakdown;
    }

    /**
     * removeSelf - emit remove config event
     */
    removeSelf() {
        this.scope.$emit('trend-config-removed', this.scope.index);
    }

    onTitleChanged() {
        const newValue = _.get(this.scope, 'trend.title');
        if (newValue === this.lastTitle) {
            return;
        }
        this.lastTitle = newValue;

        _.set(
            this.scope,
            `definition.propertyValues.trend.selectedTrends[${this.scope.index}].title`,
            newValue
        );

        this.redraw();
    }

    /**
     * redraw - runs definition resolve with the current definition and emits a trend-config-updated event
     */
    redraw() {
        this.scope.$root._trendLoading = true;
        this.definitionService
            .resolve({
                definition: this.scope.definition,
                resolveFilters: false,
                resolveAudience: false,
                resolveActionAvailableContext: true,
                resolveTrendConfiguration: true,
                resolveEventTriggers: false
            })
            .then(result => {
                this.scope.$emit('trend-config-updated', result);
                this.scope.$root._trendLoading = false;
            })
            .catch(error => {
                console.error(error);
                this.scope.$root._trendLoading = false;
            });
    }
}
