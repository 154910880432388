(function() {
    'use strict';

    angular.module('serviceApp').filter('contentTypeLabel', contentTypeLabel);

    contentTypeLabel.$inject = ['_', '$filter', 'storageService', 'contentTypeService'];

    function contentTypeLabel(_, $filter, storageService, contentTypeService) {
        // _.get + return default value if `null` or empty string is returned.
        function safeGet(obj, key, defaultValue) {
            const val = _.get(obj, key, defaultValue);
            return _.isEmpty(val) ? defaultValue : val;
        }

        // Get the custom content type label (if it exists) from the cache, otherwise return the default.
        // If no default was provided, create one by start-casing the content type name.
        function getContentTypeLabel(contentType, defaultValue) {
            const prettyContentTypeName = contentTypeService.getContentTypePrettyName(contentType);
            return safeGet(
                storageService.get(`content-display-options`, 'session'),
                `${contentType}.label`,
                defaultValue || _.startCase(prettyContentTypeName)
            );
        }

        /**
         * Returns a custom label for a given contentType if a label exists.
         *
         * @param {String} contentType - The content type to retrieve a label for.
         * @param {String} defaultValue - A value to return if no custom label was found.
         */
        return function(contentType, defaultValue) {
            // If we don't have any cached display options, return the best default we can.
            if (!storageService.get(`content-display-options`, 'session')) {
                return defaultValue || _.startCase(contentType);
            }
            // Attempt to get the custom content type label from the display options.
            return getContentTypeLabel(contentType, defaultValue);
        };
    }
})();
