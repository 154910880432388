(function() {
    'use strict';

    angular.module('serviceApp').directive('cardProfileTeamMembers', cardProfileTeamMembers);

    function cardProfileTeamMembers() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                team: '='
            },
            template: require('views/tmpl/cards/settings/cardProfileTeamMembers.html'),
            controller: CardProfileTeamMembersController
        };

        return directive;
    }

    CardProfileTeamMembersController.$inject = ['_', '$scope'];

    function CardProfileTeamMembersController(_, $scope) {
        $scope.members = $scope.card.properties.members;

        $scope.isOwner = function(member) {
            return $scope.team.ownerIds.indexOf(member.id) != -1;
        };
    }
})();
