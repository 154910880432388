'use strict';
import { onStringTemplateVar } from '../../../../scripts/utils/templateString';
export default class TemplateStringBuilder {
    static get $inject() {
        return ['$scope', '$filter'];
    }
    constructor(scope, filter) {
        this.scope = scope;
        this.filter = filter;

        this.scope.insertVariable = this.insertVariable.bind(this);
        this.scope.onEditorCreated = this.onEditorCreated.bind(this);
        this.scope.onStringTemplateVar = onStringTemplateVar.bind(this);

        this.scope.startCase = this.startCase.bind(this);
        this.scope.hidePropsDrop = this.hidePropsDrop.bind(this);
        this.scope.toggleShowProps = this.toggleShowProps.bind(this);

        this.scope.filteredQuery = this.filteredQuery.bind(this);

        this.scope.isObject = item => _.isObject(item);

        this.scope.isHtml = this.scope.inputType === 'html-text' && this.scope.varDataType === 'string';

        // When the available context list changes, reload the ngquill editor
        // so that it gets any updated var labels.
        this.scope.$watch('contextBreakdown', () => {
            if (this.quill && this.quill.init) {
                this.quill.init();
            }
        });
    }

    /**
     * onEditorCreated - called by the ng-quill init phase
     * @param {Object} editor - instance of the quill editor
     */
    onEditorCreated(editor) {
        this.quill = editor;
    }

    /**
     * insertVariable - an override method for quill i.e. what to do when a variable is inserted
     * @param {String} data - data to insert into the quill editor string
     */
    insertVariable(data) {
        this.quill.insertVariable(data);
        this.scope.hidePropsDrop();
    }

    /**
     * startCase - given an object or a string, start case the string or the object.name entry
     * @param {Object|String} item - item to be start cased
     * @returns {String} start cased string
     */
    startCase(item) {
        if (_.isObject(item)) {
            return _.startCase(_.replace(item.name, 'content.', ''));
        }

        return _.startCase(_.replace(item, 'content.', ''));
    }

    /**
     * hidePropsDrop - hide the props drop down
     */
    hidePropsDrop() {
        this.scope.showPropsDrop = false;
    }

    /**
     * toggleShowProps - toggle the props drop down visibility
     */
    toggleShowProps() {
        this.scope.showPropsDrop = !this.scope.showPropsDrop;
    }

    filteredQuery(key, query) {
        const valueToCheck = _.isString(key) ? key : key.label;
        return _.toLower(valueToCheck).includes(_.toLower(query)) || !query;
    }
}
