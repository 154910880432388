'use strict';

import _ from 'lodash';
import BaseNavClass from '../BaseNavClass';

/**
 * This is the Side Navigation Class
 *
 * @export
 * @class SideNavClass
 * @extends {BaseNavClass}
 */
export default class SideNavClass extends BaseNavClass {
    constructor(...args) {
        super(...args);
    }
}
