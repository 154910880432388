import themes from '@olono/shared-ui-assets/themes.json';
import colors from '@olono/shared-ui-assets/colors.json';

const DEFAULT_THEME = 'sea-set';
const DEFAULT_COLOR = 'default';

export default class Pill {
    static get $inject() {
        return ['$scope', '$rootScope'];
    }

    constructor(scope, rootScope) {
        this.scope = scope;
        this.rootScope = rootScope;
        this.scope.color = this.scope.color || DEFAULT_COLOR;
        this.setCurrentTheme(this.scope.theme);
        this.setBackgroundColor(this.scope.color);
        this.scope.$watch('color', () => {
            this.setBackgroundColor(this.scope.color);
        });

        this.scope.$watch('theme', () => {
            this.setCurrentTheme(this.scope.theme);
            this.setBackgroundColor(this.scope.color);
        });
    }

    setCurrentTheme(theme) {
        const fallBackTheme = this.rootScope.theme || DEFAULT_THEME;
        this.currentTheme = theme ? theme : fallBackTheme;
    }

    setBackgroundColor(color) {
        let currentColor = '';
        if (colors.hasOwnProperty(color)) {
            currentColor = colors[color];
            if (this.scope.isDot) {
                this.scope.dotColor = currentColor;
                this.scope.dotBackgroundColor = currentColor ? colors['ink-700'] : colors['ink-800'];
            } else {
                this.scope.backgroundColor = colors['cobalt-500'];
            }
        } else if (color !== 'default') {
            currentColor = _.get(themes, `${this.currentTheme}.colorPalette[${color}]`);
            if (this.scope.isDot) {
                this.scope.dotColor = currentColor;
                this.scope.dotBackgroundColor = currentColor ? colors['ink-700'] : colors['ink-800'];
            } else {
                this.scope.backgroundColor = colors['cobalt-500'];
            }
        }
    }
}
