'use strict';

/**
 * Angular wrapper for our <AddNewFilterButtonExternalComponent /> react component.
 */
export default class AddNewFilterButton {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.data = { isLoading: this.isLoading, onButtonClick: this.onButtonClick };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'isLoading') && !changes.isLoading.isFirstChange()) {
            this.isLoading = changes.isLoading.currentValue;
            this.data = { ...this.data, isLoading: this.isLoading };
        }
    }
}
