'use strict';

/**
 * Angular wrapper for our <PropertyList /> react component.
 */

const PROPERTY_LIST_HEADER_LABEL = 'propertyListHeader';

export default class NestedPropertyList {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        let integrationUserItem = _.cloneDeep(_.get(this, 'records'));

        integrationUserItem = {
            email: _.get(integrationUserItem, 'email'),
            userId: _.get(integrationUserItem, 'id'),
            ..._.get(integrationUserItem, 'integrationStatus.integration'),
            integrationStatus: _.get(integrationUserItem, 'integrationStatus')
        };

        _.forEach(_.get(integrationUserItem, 'integrationStatus.metadata'), item => {
            _.set(
                item,
                PROPERTY_LIST_HEADER_LABEL,
                _.get(item, 'contentType')
                    ? `${_.get(item, 'objectName')} (${_.get(item, 'contentType')})`
                    : _.get(item, 'objectName')
            );
        });

        _.unset(integrationUserItem, 'integrationStatus.integration');

        _.set(this, 'data.records', integrationUserItem);
        this.data = {
            records: integrationUserItem,
            id: this.id
        };
    }
}
