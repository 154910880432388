'use strict';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('itemListInput', () => ({
    restrict: 'E',
    scope: {
        itemList: '=',
        title: '=',
        form: '='
    },
    template,
    controller: Controller
}));
