'use strict';

/**
 * Angular wrapper for our <FilterSummaryHover /> react component.
 */

export default class FilterSummaryHover {
    constructor() {}

    $onInit() {
        // Props being passed to our react component
        this.data = {
            summaryHover: this.summaryHover,
            generativePayload: this.generativePayload
        };
    }

    $onChanges() {
        this.data = {
            summaryHover: this.summaryHover,
            generativePayload: this.generativePayload
        };
    }
}
