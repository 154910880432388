'use strict';
/**
 * Angular Drawer Button component used to emulate Material Button for
 *  use in legacy Angular.
 */

export default class DrawerButton {
    static get $inject() {
        return ['$scope'];
    }

    constructor($scope) {
        $scope.isExpandCollapse = $scope.type === 'left' || $scope.type === 'right';

        switch ($scope.type) {
            case 'close':
                $scope.icon = 'images/static/icon-drawer-close.svg';
                break;
            case 'back':
                $scope.icon = 'images/static/icon-drawer-back.svg';
                break;
            case 'left':
                $scope.icon = 'images/static/icon-arrow-left.svg';
                $scope.buttonText = 'View More';
                break;
            case 'right':
                $scope.icon = 'images/static/icon-arrow-right.svg';
                $scope.buttonText = 'View Less';
                break;
            default:
                $scope.icon = 'images/static/icon-drawer-close.svg';
        }
    }
}
