const moment = require('moment-timezone');
import { parseDateString } from '../utils/date-utils';

(function() {
    'use strict';

    angular.module('serviceApp').filter('humanDate', humanDate);

    humanDate.$inject = ['_', '$filter', '$rootScope'];

    var suffixes = ['th', 'st', 'nd', 'rd'];

    function humanDate(_, $filter, $rootScope) {
        var filter = function(n) {
            // Guess the local time zone, so we can see
            // if the given date is the same day as today
            // in the user's area.
            const localZone = moment.tz.guess();

            // Use `parseDateString` to handle irregular date formats.
            let date = parseDateString(n, localZone);

            // If the date isn't valid, bail.
            if (_.isNil(date)) {
                return '';
            }

            // See if the day is "today" relative to the user.
            // isSame() uses the first date's time zone for comparison.
            const sameDay = date.isSame(moment(), 'day');

            // If so, display "Today" if it's in the first hour.
            // This is a hedge for dates without times, so that
            // we don't display 12:00am for all of them.
            if (sameDay) {
                if (date.hours() === 0) {
                    return 'Today';
                }
                // If it's not in the first hour, show the time of
                // day in the user's time zone.
                return $filter('date')(date.toISOString(), 'h:mma');
            }

            // If it's not the same day in the user's time zone,
            // display the date relative to the _team_ time zone.
            const timezone = _.get($rootScope, 'timezone', 'UTC');
            date = parseDateString(n, timezone);

            // If the date is in a different year, display with year.
            if (date.year() != moment.tz(timezone).year()) {
                return moment.tz(date, $rootScope.timezone).format('MMM Do, YYYY');
            }

            // Otherwise just display the date.
            return moment.tz(date, $rootScope.timezone).format('ddd MMM Do');
        };

        return filter;
    }
})();
