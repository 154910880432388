'use strict';
import template from './index.html';
import SideNavClass from './controller';

angular.module('serviceApp').directive('sideNav', () => ({
    restrict: 'E',
    scope: {
        query: '<',
        user: '<',
        team: '<',
        panel: '<'
    },
    template,
    controller: SideNavClass
}));
