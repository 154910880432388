/*
 * logic code inside 'humanize()' take from https://github.com/emartech/humanize
 * this is a hack because there is no bower package available for this package
 * TODO:// after switching to npm package management change to using the npm package
 */

(function() {
    'use strict';

    angular.module('serviceApp').filter('humanize', humanize);

    function humanize() {
        var _createClass = (function() {
            function defineProperties(target, props) {
                for (var i = 0; i < props.length; i++) {
                    var descriptor = props[i];
                    descriptor.enumerable = descriptor.enumerable || false;
                    descriptor.configurable = true;
                    if ('value' in descriptor) descriptor.writable = true;
                    Object.defineProperty(target, descriptor.key, descriptor);
                }
            }
            return function(Constructor, protoProps, staticProps) {
                if (protoProps) defineProperties(Constructor.prototype, protoProps);
                if (staticProps) defineProperties(Constructor, staticProps);
                return Constructor;
            };
        })();

        function _classCallCheck(instance, Constructor) {
            if (!(instance instanceof Constructor)) {
                throw new TypeError('Cannot call a class as a function');
            }
        }

        var SUFFIXES = ['', 'k', 'M', 'B', 'T', 'Q'];
        var round = function(number, precision) {
            var rounder = Math.pow(10, precision);
            return Math.round(number * rounder) / rounder;
        };

        var HumanizePipe = (function() {
            function HumanizePipe() {
                _classCallCheck(this, HumanizePipe);

                this.transform = this.transform.bind(this);
            }

            _createClass(HumanizePipe, [
                {
                    key: 'transform',
                    value: function transform(number, precision) {
                        if (precision === undefined) precision = 1;
                        if (!number) return '0';
                        if (number > -1 && number < 1) return this._stringify(number, precision);

                        var suffixIndex = this._suffixIndex(number);
                        var value = number / Math.pow(1000, suffixIndex);

                        return this._stringify(value, precision) + SUFFIXES[suffixIndex];
                    }
                },
                {
                    key: '_suffixIndex',
                    value: function _suffixIndex(number) {
                        return Math.floor(Math.log(Math.abs(number)) / Math.log(1000));
                    }
                },
                {
                    key: '_stringify',
                    value: function _stringify(num, precision) {
                        return round(num, precision).toString();
                    }
                }
            ]);

            return HumanizePipe;
        })();

        return new HumanizePipe().transform;
    }
})();
