'use strict';
import Controller from './controller';
import template from './index.html';

angular.module('serviceApp').directive('formString', () => ({
    restrict: 'A',
    scope: {
        formModel: '=formString',
        form: '='
    },
    template,
    controller: Controller
}));

angular
    .module('sfString', ['schemaForm'])
    .config([
        '_',
        'schemaFormProvider',
        'schemaFormDecoratorsProvider',
        'sfBuilderProvider',
        'sfPathProvider',
        function(_, schemaFormProvider, schemaFormDecoratorsProvider, sfBuilderProvider, sfPathProvider) {
            const sfString = function(name, schema, options) {
                const formatType = _.get(schema, 'format.type');
                if (
                    _.get(schema, 'type') === 'string' &&
                    (formatType === 'form-string' || formatType === 'form-string')
                ) {
                    var f = schemaFormProvider.stdFormObj(name, schema, options);
                    f.key = options.path;
                    f.type = 'form-string';
                    options.lookup[sfPathProvider.stringify(options.path)] = f;
                    return f;
                }
            };

            schemaFormProvider.defaults.string.unshift(sfString);

            schemaFormDecoratorsProvider.defineAddOn(
                'bootstrapDecorator',
                'form-string',
                'views/tmpl/templates/sfString.html',
                sfBuilderProvider.stdBuilders
            );
        }
    ])
    .run([
        '$templateCache',
        function($templateCache) {
            $templateCache.put(
                'views/tmpl/templates/sfString.html',
                require('views/tmpl/templates/sfString.html')
            );
        }
    ]);
