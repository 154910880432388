(function() {
    'use strict';

    angular.module('serviceApp').directive('contentRefreshIndicator', contentRefreshIndicator);

    function contentRefreshIndicator() {
        var directive = {
            restrict: 'E',
            scope: {
                showContentRefreshIndicator: '='
            },
            template: require('views/tmpl/partials/contentRefreshIndicator.html'),
            controller: ContentRefreshIndicatorController
        };

        return directive;
    }

    ContentRefreshIndicatorController.$inject = ['_', '$scope'];

    function ContentRefreshIndicatorController(_, $scope) {
        $scope.dismiss = function() {
            $scope.showContentRefreshIndicator = false;
        };
    }
})();
