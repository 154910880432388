import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('contentTable', () => {
    return {
        transclude: true,
        scope: {
            isDashboardMode: '<?',
            isEmpty: '<'
        },
        template: template
    };
});
