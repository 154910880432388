(function() {
    'use strict';

    angular.module('serviceApp').filter('niceNumber', niceNumber);

    niceNumber.$inject = ['_', '$filter'];

    function niceNumber(_, $filter) {
        return function(n, decimals) {
            //Check if value is undefined or nil, but not zero.
            if ((!n || _.isNil(n)) && n !== 0) {
                return 'N/A';
            }

            //Convert value to number, may be Integer, Float or String.
            const numericValue = _.toNumber(n);

            // If not a number, return the raw value
            if (_.isNaN(numericValue) || !_.isNumber(numericValue)) {
                return n;
            }

            if (_.isUndefined(decimals)) {
                decimals = decimals || n % 1 === 0 ? 0 : 2;
            }

            return _.toString($filter('number')(numericValue, decimals));
        };
    }
})();
