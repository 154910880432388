(function() {
    'use strict';
    angular.module('serviceApp').directive('definitionCreator', definitionCreator);
    function definitionCreator() {
        var directive = {
            restrict: 'E',
            scope: {
                definitions: '=',
                type: '=',
                definitionTypeString: '=',
                api: '=',
                board: '=?',
                user: '<',
                definitionFilter: '<'
            },
            template: require('views/tmpl/partials/definitionEditors/definitionCreator.html'),
            controller: DefinitionCreatorController
        };

        return directive;
    }

    DefinitionCreatorController.$inject = ['_', '$scope', '$log', 'toastr', 'definitionService'];

    function DefinitionCreatorController(_, $scope, $log, toastr, definitionService) {
        $scope.panelOrder = 1;
        $scope.definitionType = $scope.type;
        $scope.definitionTypeString = _.toLower($scope.definitionTypeString || '') || $scope.type;
        $scope.selectedDefinition = {};
        $scope.definition = {};
        $scope.query = {
            str: undefined,
            options: { debounce: 400 }
        };

        $scope.nameDupe = false;
        $scope.contentTypeDupe = false;
        $scope.formDirty = false;

        $scope.formValid = true;
        $scope.invalidFormSections = [];

        $scope.search = () => {
            const filter = {
                definitionType: $scope.definitionType,
                teamId: '##GLOBAL##', // only want global defs
                ...$scope.definitionFilter
            };

            // Enforce only returning the generic content type when adding
            // net-new content types. This has the plus of including `isCustom`
            // flag on the new definition.
            if ($scope.definitionType === 'contentType') {
                _.set(filter, 'isCustom', true);
            }

            if ($scope.definitionType)
                definitionService
                    .searchDefinitions({
                        filter,
                        sort: [{ 'displayName.rawLowercase': 'asc' }],
                        queryString: $scope.query.str,
                        size: 50,
                        searchType: 'definition-creator-search',
                        excludeDisabledContentType: true
                    })
                    .then(results => {
                        $scope.definitions = _.get(results, 'results', []);
                    })
                    .catch(err => {
                        toastr.error(
                            'An error occurred saving ' + $scope.definitionType + ' - please try again',
                            _.get(err, 'message', err)
                        );
                    });
            $scope.searchQuery = $scope.query.str;
        };

        // init data
        $scope.search();

        const selectedDefinitionWatcher = $scope.$watch('selectedDefinition', (newVal, oldVal) => {
            if (_.isEmpty(newVal)) {
                return;
            }

            let boardType = _.get($scope, 'board.type');
            // If we're on the "settings" page, default to disabled.
            if (boardType === 'settings' || _.endsWith(boardType, 'Definition')) {
                _.set($scope, 'definition.enabled', false);
            }
            // Otherwise we're coming at this from a "Create New Board" flow, so default to enabled.
            else {
                _.set($scope, 'definition.enabled', true);
            }

            // When creating a new def, we only display the first tab.
            const firstTab = _.first(_.get($scope, 'selectedDefinition._tabs', []));
            if (_.size(firstTab.sections) === 0) {
                $scope.accordionGroups = [];
            }
            // Otherwise configure the accordion groups for the selected tab.
            else {
                const tabDef = firstTab;
                $scope.accordionGroups = definitionService.getAccordionGroups(
                    tabDef,
                    $scope.selectedDefinition
                );
            }
        });

        $scope.$on('definition:form-validate', (event, { section, isValid }) => {
            const currentIndex = $scope.invalidFormSections.indexOf(section);
            if (isValid && currentIndex < 0) {
                return;
            }

            if (!isValid && currentIndex < 0) {
                $scope.invalidFormSections.push(section);
                return;
            }

            if (isValid) {
                $scope.invalidFormSections.splice(currentIndex, 1);
                return;
            }
        });

        $scope.$watch(
            'invalidFormSections ',
            () => {
                if ($scope.invalidFormSections.length > 0) {
                    $scope.formValid = false;
                    return;
                }
                $scope.formValid = true;
                return;
            },
            true
        );

        $scope.$on('definitionEditor:update', function(event, data) {
            _.forEach(data, (value, key) => {
                _.set($scope, `selectedDefinition[${key}]`, _.cloneDeep(value));
            });
        });

        $scope.$on('definitionEditor:save', function(event, updatedPropertyValues, updatedSchemaValues) {
            _.set($scope, 'selectedDefinition[propertyValues]', _.cloneDeep(updatedPropertyValues));
            _.set($scope, 'selectedDefinition[propertySchema]', _.cloneDeep(updatedSchemaValues));
            $scope.save();
        });

        // Watch for changes to properties of the selected definition.
        const selectedDefDescriptionWatcher = $scope.$watch('selectedDefinition.description', () => {
            $scope.definition.description = _.get($scope, 'selectedDefinition.description');
        });

        const selectedDefDisplayNameWatcher = $scope.$watch('selectedDefinition.displayName', () => {
            $scope.definition.displayName = _.get(
                $scope,
                'selectedDefinition.displayName',
                _.startCase(_.get($scope, 'selectedDefinition.name'))
            );
        });

        const selectedDefContentTypeWatcher = $scope.$watch('selectedDefinition.contentType', () => {
            $scope.definition.contentType = _.get($scope, 'selectedDefinition.contentType');
        });

        // Watch for changes to the in-progress definition.
        const definitionWatcher = $scope.$watch(
            'definition.displayName',
            function() {
                $scope.nameDupe = false;
            },
            true
        );

        //Don't allow creating definitions with the same name.
        $scope.checkDupe = () => {
            $scope.nameDupe = _.find($scope.definitions, function(definition) {
                return _.toLower(definition.name) === _.toLower($scope.definition.name);
            });
        };

        const contentTypeWatcher = $scope.$watch(
            'definition.contentType',
            function() {
                $scope.contentTypeDupe = false;
            },
            true
        );

        //Don't allow creating definitions with the same contentType.
        $scope.checkContentTypeDupe = () => {
            $scope.contentTypeDupe = _.find($scope.definitions, function(definition) {
                return (
                    definition.contentType &&
                    _.toLower(definition.contentType.contentType) ===
                        _.toLower($scope.definition.contentType.contentType)
                );
            });
        };

        $scope.handleSave = () => {
            // Set `isSaving` to disable the save button.
            $scope.isSaving = true;
            $scope
                .checkName()
                .then(proceed => {
                    if (proceed && _.get($scope.definition, 'contentType')) {
                        return $scope.checkContentType();
                    }
                    return proceed;
                })
                .then(proceed => {
                    if (proceed) {
                        $scope.$broadcast('formAccordion:save');
                    }
                });
        };

        $scope.save = () => {
            _.defaults($scope.definition, _.cloneDeep($scope.selectedDefinition));
            delete $scope.definition.id;
            delete $scope.definition.enabledByDefault;
            $scope.definition.name = _.get($scope, 'selectedDefinition.displayName');

            return definitionService
                .updateDefinition($scope.definition)
                .then(function(newDefinition) {
                    toastr.success(_.get(newDefinition, 'displayName') + ' updated successfully');

                    $scope.$root.$emit('rightDrawer:close');
                    definitionService.redirectAfterSavingDefinition(
                        $scope.board,
                        newDefinition,
                        $scope.api.refresh
                    );
                })
                .catch(function(err) {
                    // Clear `isSaving` to re-enable the save button.
                    $scope.isSaving = false;
                    const definitionType = _.get($scope, 'definition.definitionType');
                    toastr.error('An error occurred saving ' + definitionType + ' - please try again');
                });
        };

        $scope.nextPanel = () => {
            // If we're pressing "next" on the "name your definition" page,
            // check for existing defs of this type with the same display name.
            if ($scope.panelOrder === 2) {
                return $scope
                    .checkName()
                    .then(proceed => {
                        if (proceed && _.get($scope.definition, 'contentType')) {
                            return $scope.checkContentType();
                        }
                        return proceed;
                    })
                    .then(proceed => {
                        if (proceed) {
                            // Otherwise proceed to the next panel.
                            $scope.panelOrder = $scope.panelOrder + 1;
                        }
                    });
            } else {
                // Just proceed to the next panel.
                $scope.panelOrder = $scope.panelOrder + 1;
            }
        };

        $scope.back = () => {
            $scope.panelOrder = $scope.panelOrder - 1;
        };

        $scope.checkName = () => {
            const promise = definitionService
                .searchDefinitions({
                    filter: {
                        definitionType: $scope.definitionType,
                        teamId: _.get($scope.user, 'state.currentTeam'),
                        'displayName.rawLowercase': _.toLower(_.get($scope.definition, 'displayName'))
                    },
                    withPagination: false,
                    size: 1,
                    searchType: 'definition-creator-checkname'
                })
                .then(definitions => {
                    // If we find any definitions, set the dupe flag and bail out.
                    if (_.size(definitions)) {
                        $scope.nameDupe = true;
                        $scope.isSaving = false;
                        return false;
                    }
                    _.set($scope.selectedDefinition, 'displayName', $scope.definition.displayName);
                    _.set($scope.selectedDefinition, 'description', $scope.definition.description);
                    if ($scope.definition.definitionType === 'contentType') {
                        _.set(
                            $scope.selectedDefinition,
                            'contentType.contentType',
                            $scope.definition.contentType.contentType
                        );
                    }
                    return true;
                });
            promise.catch(err => {
                toastr.error(
                    'An error occurred saving ' + $scope.definitionType + ' - please try again',
                    _.get(err, 'message', err)
                );
                $scope.isSaving = false;
            });
            return promise;
        };

        $scope.checkContentType = () => {
            if ($scope.definitionType !== 'contentType') {
                return;
            }
            const promise = definitionService
                .searchDefinitions({
                    filter: {
                        definitionType: $scope.definitionType,
                        teamId: _.get($scope.user, 'state.currentTeam'),
                        'contentType.contentType': _.camelCase(
                            _.get($scope.definition, 'contentType.contentType')
                        )
                    },
                    enabledDefinitions: false,
                    withPagination: false,
                    size: 1,
                    searchType: 'definition-creator-checkcontenttype'
                })
                .then(definitions => {
                    // If we find any definitions, set the dupe flag and bail out.
                    if (_.size(definitions)) {
                        $scope.contentTypeDupe = true;
                        return false;
                    }
                    _.set($scope.selectedDefinition, 'displayName', $scope.definition.displayName);
                    _.set($scope.selectedDefinition, 'description', $scope.definition.description);
                    if ($scope.definition.contentType) {
                        _.set(
                            $scope.selectedDefinition,
                            'contentType.contentType',
                            $scope.definition.contentType.contentType
                        );
                    }
                    return true;
                });
            promise.catch(err => {
                toastr.error(
                    'An error occurred saving ' + $scope.definitionType + ' - please try again',
                    _.get(err, 'message', err)
                );
            });
            return promise;
        };

        $scope.closeCreator = () => {
            $scope.$root.$emit('rightDrawer:close');
        };

        $scope.selectDefinition = (definitionItem, selectedIndex) => {
            $scope.selectedIndex = selectedIndex;
            definitionService
                .resolve({
                    definition: definitionItem,
                    options: { skipAvailableProperties: false }
                })
                .then(function(result) {
                    $scope.selectedDefinition = _.cloneDeep(result);
                    $scope.definition.description = _.get($scope, 'selectedDefinition.description');
                    $scope.ready = true;
                })
                .catch($log.error);
        };

        $scope.startCase = input => _.startCase(input);

        $scope.$on('$destroy', () => {
            // Destroy watchers.
            definitionWatcher();
            contentTypeWatcher();
            selectedDefinitionWatcher();
            selectedDefDescriptionWatcher();
            selectedDefDisplayNameWatcher();
            selectedDefContentTypeWatcher();
        });
    }
})();
