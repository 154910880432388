'use strict';
import _ from 'lodash';
import stripHtml from 'string-strip-html';
import { createContextBreakdown } from '../../../scripts/utils/form-utils.js';

export default class MetricTrendCondition {
    static get $inject() {
        return ['$scope'];
    }

    constructor(scope) {
        this.scope = scope;
        const titleMap = {
            gte: 'Greater than or equal to',
            gt: 'Greater than',
            lt: 'Less than',
            lte: 'Less than or equal to'
        };

        this.scope.removeSelf = this.removeSelf.bind(this);
        this.scope.title = titleMap[_.get(this.scope, 'conditionOperator')];
        this.scope.contextBreakdown = createContextBreakdown(_.get(this.scope, 'trend.availableContext', []));

        // abstract the model to avoid an unnecessary watcher
        this.scope.templateModel = {
            get value() {
                return _.get(scope, 'conditionValue.template.templateString', '');
            },
            set value(newVal) {
                // remove all html but the desired tags
                newVal = stripHtml(newVal, { ignoreTags: ['%=', '%'] }).result;
                _.set(scope, 'conditionValue.template.templateString', newVal);

                // call the onTemplateChanged callback with the new value
                scope.onTemplateChanged({
                    data: { condition: _.get(scope, 'conditionOperator'), template: newVal }
                });
            }
        };
    }

    /**
     * removeSelf - on remove call the onConditionRemoved callback to remove the item
     */
    removeSelf() {
        this.scope.onConditionRemoved({ data: { condition: _.get(this.scope, 'conditionOperator') } });
    }
}
