import template from './index.html';
import Controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('instantActionCreator', () => ({
    restrict: 'E',
    scope: {
        board: '=?',
        contentAction: '=?',
        content: '=?'
    },
    template,
    controller: Controller
}));
