'use strict';

import _ from 'lodash';
import stripHtml from 'string-strip-html';
import { createContextBreakdown } from '../../../scripts/utils/form-utils';

export default class CardInsight {
    static get $inject() {
        return [
            '$cookies',
            '$rootScope',
            '$scope',
            '$state',
            '$stateParams',
            '$timeout',
            '$window',
            'appSettingsService',
            'boardSliceService',
            'captureService',
            'definitionService',
            'toastr',
            'activeViewService'
        ];
    }
    constructor(
        $cookies,
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $window,
        appSettingsService,
        boardSliceService,
        captureService,
        definitionService,
        toastr,
        activeViewService
    ) {
        this.scope = $scope;
        this.rootScope = $rootScope;
        this.timeout = $timeout;
        this.window = $window;
        this.cookies = $cookies;
        this.state = $state;
        this.captureService = captureService;
        this.appSettingsService = appSettingsService;
        this.boardSliceService = boardSliceService;
        this.stateParams = $stateParams;
        this.scope.view = _.get(this.scope, 'card.properties.view') || 'dashboard';
        this.scope.modalIsOpen = false;
        this.scope.emailDialogOpen = false;
        this.activeViewService = activeViewService;

        // Fetch the Generative AI endpoint properties
        const CARD_SUMMARY_SKILL = process.env.CARD_SUMMARY_SKILL || 'card_summary_v1';
        const CARD_SUMMARY_SKILL_VERSION = process.env.CARD_SUMMARY_SKILL_VERSION || 'v1.0.1';
        const SKILL_API_ENDPOINT = process.env.SKILL_API_ENDPOINT || 'api/p/skills/completion';
        const SKILL_API_DOMAIN = process.env.SKILL_API_DOMAIN || '/';

        // render the default view toggle if the card's config has allowedViewTypes and we
        // are in reports or inspection view
        this.scope.renderViewToggle =
            _.get(this.scope.card, 'config.allowedViewTypes') && this.scope.view === 'inspect';

        this.scope.generativeAI = this.appSettingsService.checkVariationSync('enable-gpt-features', {
            default: true
        });

        this.scope.shouldRenderHierarchy = () => {
            if (this.scope.singleView) {
                return false;
            }
            const hierarchyOverride = _.get(
                this.scope.card,
                'properties.summaryHover.hierarchy[0].hierarchyData'
            );
            // render the hierarchy selector if we have hierarchy override data and no warning
            // or if there is a warning that is not NODE_NOT_FOUND or CONTENT_USER_NOT_FOUND
            return (
                ((hierarchyOverride && !_.get(hierarchyOverride, 'warning')) ||
                    (_.get(hierarchyOverride, 'warning') !== 'NODE_NOT_FOUND' &&
                        _.get(hierarchyOverride, 'warning' !== 'CONTENT_USER_NOT_FOUND'))) &&
                this.scope.view === 'dashboard'
            );
        };

        this.scope.$watch('card', newValue => {
            if (newValue) {
                this.scope.summaryHover = _.get(newValue, 'properties.summaryHover');

                // Process the Generative AI prompt if it exists
                if (this.scope.generativeAI) {
                    // Extract the card ai user prompt
                    const prompt = _.get(newValue, 'config.generativeAI.generativePrompt');

                    // Only process a card summary if there is a prompt
                    if (prompt && !_.isEmpty(prompt)) {
                        // Create a constant context ID that includes the dashboard ID and card ID with the hierarchy information
                        const contextId = `${_.get(this.scope.board, 'id')}%%${_.get(
                            newValue,
                            'definitionId',
                            'card'
                        )}%%${_.get(this.scope.hierarchy, 'hierarchyDefinitionId', 'definitionId')}%%${_.get(
                            this.scope.hierarchy,
                            'hierarchyRootId',
                            'rootId'
                        )}%%${_.get(this.scope.hierarchy, 'hierarchyContentUserId', 'userId')}`;

                        const contextPayload = {
                            title: _.get(newValue, 'title', ''),
                            payload: JSON.stringify(_.get(newValue, 'properties.content', {})),
                            user_prompt: prompt,
                            description: _.get(newValue, 'config.description', ''),
                            query: JSON.stringify(_.get(newValue, 'config.timeframeFilter', {}))
                        };

                        this.scope.generativePayload = {
                            contextId,
                            contextPayload,
                            skill: CARD_SUMMARY_SKILL,
                            api: SKILL_API_ENDPOINT,
                            domain: SKILL_API_DOMAIN,
                            version: CARD_SUMMARY_SKILL_VERSION
                        };
                    }
                }
            }

            this.scope.timeFramePropertyKey = _.first(
                _.keys(_.get(this.scope.card, 'config.timeframeFilter.schema'))
            );
        });

        //Set the active state of this card if id passed to the url matches
        if (this.stateParams.targetId) {
            this.scope.targetActive =
                this.stateParams.targetId === _.get(this.scope.card, 'name') ||
                this.stateParams.targetId === _.get(this.scope.card, 'definitionId');
        }

        // Single view in context of the insight card means it's not on a layout view, and doesn't require the layout
        // context menu
        if (!_.isUndefined(this.stateParams.card) && this.stateParams.card != 'undefined') {
            this.scope.singleView = true;
        }

        /**
         * Determine the hover state and drawer-opening behavior for this card.
         *
         * Returns:
         *  - 'container' if the entire container should be hoverable and open the drawer when clicked.
         *  - 'header' if only the header of the card should be hoverable and open the drawer.
         *  - 'none' if no hover nor drawer opening is desired.
         */
        const getHoverBehavior = () => {
            if (this.scope.card.error || this.scope.view !== 'dashboard') {
                return 'none';
            }

            switch (this.scope.card.type) {
                case 'insights-headline':
                    return 'container';
                case 'insights-error':
                    return 'none';
                default:
                    return 'header';
            }
        };

        this.scope.hoverBehavior = getHoverBehavior();

        const api = {
            refresh: data => {
                this.scope.refreshCard(_.get(data, 'cardDef'));
            }
        };

        /**
         * Update an insight card when a card override is changed in dashboard view.
         *
         * In reports or inspection view, the card definition in the store
         * will be updated and `cardsStale` should be set, triggering those
         * views to refresh themselves.
         */
        this.scope.onUpdateCardOverride = () => {
            // In dashboard mode, act as if we've just updated a card facet
            // which will reload the card in place.
            if (this.scope.view === 'dashboard') {
                const facets = _.get(this.scope, 'card.facetGroups.view.facets');
                const cardName = _.get(this.scope, 'card.name');
                this.scope.$emit('card-editor:edited-card', {
                    cardName,
                    facets
                });
            }
        };

        this.scope.openDrawer = function(event) {
            if (event.srcElement.tagName === 'A') {
                event.stopPropagation();
            }
        };

        this.scope.openDetails = data => {
            //If this is the time filter select, ignore
            const srcElement = _.get(data, 'srcElement');
            if (srcElement && srcElement.closest('[aria-label=timeframe-filter-select-date-button]')) {
                return;
            }
            //If this is the facet menu, ignore
            if (srcElement && srcElement.closest('[aria-label="Card Filters & Display Options"]')) {
                return;
            }
            // If this is the hierarchy selector, ignore
            if (srcElement && srcElement.closest('[aria-label=hierarchy-popover-selector]')) {
                return;
            }

            if (this.scope.card.error || this.scope.card.type === 'insights-error') {
                return;
            }

            // If this is the filter summary, ignore
            if (srcElement && srcElement.closest('[aria-label=filter-summary]')) {
                return;
            }

            // If this is the context menu button, ignore
            if (srcElement && srcElement.closest('[aria-label=context-menu-btn]')) {
                return;
            }

            const cardConfigArray = _.get(this.boardSliceService, 'boardDefinition.board.cardConfig', []);
            const cardConfigItem = _.find(cardConfigArray, { cardName: _.get(this.scope, 'card.name') });
            let hierarchyOverride;
            if (cardConfigItem && _.get(cardConfigItem, 'hierarchy')) {
                hierarchyOverride = _.get(cardConfigItem, 'hierarchy');
            }

            this.scope.$emit('rightDrawer:open', {
                layout: 'insightsInspection',
                drawerClass: this.scope.panel.userThemeEnabled ? 'wide user-theme' : 'wide-dark',
                board: this.scope.board,
                card: this.scope.card,
                user: this.scope.user,
                team: this.scope.team,
                panel: this.scope.panel,
                facets: _.get(data, 'facets', this.scope.facets),
                hierarchy: this.scope.hierarchy,
                api,
                hierarchy:
                    hierarchyOverride ||
                    (_.get($scope, 'board.definition.hierarchy.enabled') !== false
                        ? this.scope.hierarchy
                        : {}),
                userTheme: this.scope.panel.userThemeEnabled
            });
        };

        // Called on card definition editor
        this.scope.refreshCard = cardDef => {
            if (this.rootScope.rightDrawerOpen) {
                this.rootScope.$emit('rightDrawer:close');
            }
            const facets = _.get(this.scope, 'card.facetGroups.view.facets');
            // Clear out any card-level facets so that we just use the definition settings.
            facets.cards = null;
            const cardName = _.get(this.scope, 'card.name');
            _.unset(this.scope.facets, `card.${cardName}.category`);
            _.unset(this.scope.facets, `card.${cardName}.series`);
            _.unset(this.scope.facets, `card.${cardName}.sort`);
            _.unset(this.scope.facets, `_default.sort`);

            this.scope.$emit('updateFacet', this.scope.facets);
            this.scope.$emit('card-editor:edited-card', { cardName, cardDef, facets });
        };

        this.scope.$on('openEditor', () => {
            this.scope.openEditor();
        });

        // Load card to the right drawer when opening it.
        this.scope.openEditor = accordion => {
            if (this.rootScope.rightDrawerOpen) {
                this.rootScope.$emit('rightDrawer:close');
            }
            const teamId = _.get(this.scope.user, 'state.currentTeam');
            return definitionService
                .getDefinitionById(this.scope.card.definitionId)
                .then(definitionItem =>
                    // we need to resolve this definition before passing it in
                    definitionService.resolve({
                        definition: definitionItem,
                        options: { skipAvailableProperties: false }
                    })
                )
                .then(definitionItem => {
                    this.scope.$root.$emit('rightDrawer:open', {
                        layout: 'definitionEditor',
                        type: 'card',
                        api,
                        definition: _.cloneDeep(definitionItem),
                        accordion: accordion || '',
                        user: this.scope.user,
                        board: this.scope.board,
                        teamId
                    });
                });
        };

        this.scope.getCardNested = data => {
            this.scope.getCardHitch({
                data: data
            });
        };

        this.scope.buildDownloadUrl = data => {
            return this.scope.buildDownloadUrlHitch({
                data: data
            });
        };

        this.scope.copyCard = () => {
            const cardName = this.scope.card.title;
            const copyPromise = definitionService.copyDefinition({
                definitionId: this.scope.card.definitionId,
                options: { enabled: true }
            });
            return copyPromise
                .then(() => {
                    toastr.success('Card ' + cardName + ' copied to library');
                })
                .catch(err => {
                    console.error(err);
                    toastr.error('An error occurred copying ' + cardName + ' - please try again');
                });
        };

        const menuOptions = [];

        const inspectSection = {
            sectionData: [
                {
                    text: 'Inspect Card',
                    icon: ['fal', 'fa-magnifying-glass-plus'],
                    onClick: () => {
                        this.scope.openDetails();
                    }
                }
            ]
        };
        menuOptions.push(inspectSection);

        //If the board is editable then allow to edit the card
        if (_.get(this.scope.board, 'editable')) {
            const section = {
                sectionData: [
                    {
                        text: 'Duplicate Card',
                        onClick: () => {
                            this.scope.$emit('card-editor:duplicate-card', this.scope.card);
                        },
                        icon: ['fal', 'fa-clone']
                    },
                    {
                        text: 'Replace Card',
                        onClick: () => {
                            this.scope.$emit('card-editor:toggle-is-open', this.scope.card);
                        },
                        icon: ['fal', 'fa-arrow-right-arrow-left']
                    },
                    {
                        text: 'Remove Card',
                        onClick: () => {
                            this.scope.$emit('card-editor:remove-card', this.scope.card);
                        },
                        icon: ['fal', 'fa-xmark']
                    }
                ]
            };

            menuOptions.push(section);
        }

        const section = {
            sectionData: [
                {
                    text: 'Download Card Image',
                    onClick: () => {
                        // These are objects on the card we don't want to show in the image
                        // e.g. card menu and external icons
                        const ignoreClasses = ['context-menu', 'primary-content-details-icon'];
                        // This is a unique image name to use to save this card image
                        const imageName = `${_.snakeCase(_.get(this.scope, 'card.title'))}-card`;

                        this.captureService.captureScreen(imageName, this.scope.cardElement, ignoreClasses);
                    },
                    icon: ['fal', 'fa-download']
                }
            ]
        };
        if (this.scope.board?.panels?.length === 1) {
            section.sectionData.unshift({
                text: 'Email Card',
                onClick: () => {
                    this.scope.$emit('open-email-dashboard', {
                        cardId: _.get(this.scope.card, 'definitionId'),
                        cardName: _.get(this.scope.card, 'name').slice(0, -4)
                    });
                },
                icon: ['fal', 'fa-envelope']
            });
        }

        menuOptions.push(section);

        const baseReportSectionData = [];
        // Only admins can add card to dashboard or edit base report
        if (_.get(this.scope.board, 'editable') && _.get(this.scope.user, 'isOwner')) {
            const section = {
                sectionData: [
                    {
                        text: 'Add to another dashboard',
                        onClick: () => {
                            this.scope.$emit('card-editor:add-card-to-dashboard', this.scope.card);
                        },
                        icon: ['fal', 'fa-plus']
                    }
                ]
            };

            menuOptions.push(section);

            baseReportSectionData.push({
                text: 'Edit Base Report',
                onClick: () => {
                    this.scope.$emit('openEditor:toggle-is-open', this.scope.card);
                    this.scope.openEditor();
                },
                icon: ['fal', 'fa-pencil']
            });
        }

        //Add open base report in the reports page
        const target = {
            board: 'reports',
            panel: 'main',
            overwriteReload: false,
            targetId: `${_.get(this.scope.board, 'definitionName')}$${_.get(this.scope.card, 'definitionId')}`
        };
        const href = $state.href('boards.board.panel', target);
        baseReportSectionData.push({
            href: href,
            text: 'View Base Report',
            onClick: () => {
                $state.go('boards.board.panel', target);
            },
            icon: ['fal', 'fa-eye']
        });

        const baseReportSection = {
            sectionData: baseReportSectionData
        };

        menuOptions.push(baseReportSection);

        // If there is an error finding the card, then remove the Edit. Otherwise even the Oops
        // card with bad data should be editable to make changes
        if (
            this.scope.card.type === 'insights-error' &&
            _.get(this.scope, 'card.properties.error') === 'card_not_found'
        ) {
            let [, ...otherOptions] = menuOptions;
            this.scope.menuOptions = otherOptions;
        } else {
            this.scope.menuOptions = menuOptions;
        }

        /**
         * Return true if we should render the label in the header.
         */
        this.scope.shouldRenderLabel = () => {
            //Check to see if the HTML based content label has value
            const contentLabel = stripHtml(_.get(this.scope.card, 'properties.content.label') || '').result;
            return this.scope.card.type !== 'insights-headline' && _.size(contentLabel) > 0;
        };

        this.scope.cardSize = () => {
            return this.scope.card.type === 'insights-headline' ? 'small' : 'large';
        };

        this.scope.toggleEmailDialog = () => {
            this.scope.emailDialogOpen = false;
        };

        this.scope.getSelectedContextBreakdown = availableContext => {
            const mappedContext = _.map(availableContext, item => {
                if (_.isString(item)) {
                    item = { name: item, label: _.startCase(item) };
                }
                return item;
            });
            const contextBreakdown = createContextBreakdown(mappedContext);
            return contextBreakdown;
        };
    }
}
