(function() {
    'use strict';
    angular.module('serviceApp').directive('cardActionsBeta', cardActionsBeta);

    function cardActionsBeta() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                panel: '=',
                user: '<',
                team: '<',
                query: '=?',
                external: '=?',
                refreshCallbackHitch: '&',
                getCardHitch: '&'
            },
            link: function($scope) {
                $scope.api = {
                    executeEventTrigger: function(data) {
                        $scope.executeEventTrigger(data);
                    },
                    dismissIntegrationCallback: function(data) {
                        $scope.dismissIntegrationCallback(data);
                    },
                    refreshCallbackHitch: function() {
                        $scope.executeEventTrigger();
                    }
                };
            },
            template: require('views/tmpl/cards/actionsBeta.html'),
            controller: CardActionsBetaController
        };

        return directive;
    }

    CardActionsBetaController.$inject = [
        '_',
        '$scope',
        '$state',
        '$rootScope',
        '$timeout',
        '$location',
        '$anchorScroll',
        'toastr',
        'actionService',
        'contentLaunchService',
        '$stateParams',
        'moment',
        'cardsUtilService'
    ];

    function CardActionsBetaController(
        _,
        $scope,
        $state,
        $rootScope,
        $timeout,
        $location,
        $anchorScroll,
        toastr,
        actionService,
        contentLaunchService,
        $stateParams,
        $moment,
        cardsUtilService
    ) {
        $scope.productName = $rootScope.productName;
        $scope.selectObjectCallback = selectObjectCallback;
        $scope.executeEventTrigger = executeEventTrigger;
        $scope.removeCompletedAction = removeCompletedAction;
        $scope.dismissIntegrationCallback = dismissIntegrationCallback;
        $scope.deprecatedFilter = deprecatedFilter;
        $scope.showActionDetails = showActionDetails;
        $scope.isActive = isActive;
        $scope.isRepair = isRepair;
        $scope.toggleContext = toggleContext;
        $scope.hasTarget = !_.isEmpty($stateParams.targetId);
        $scope.needLoadingIfNoAction = !_.isEmpty($scope.card.properties.actions);

        if ($rootScope.isMobile) {
            $scope.isMobile = true;
        }

        $scope.openActionsPanel = openActionsPanel;
        $scope.pullAction = pullAction;
        $scope.listOptions = {
            listType: 'listCard',
            isCollapsible: false,
            showContextByDefault: true
        };

        $scope.hasQuery = !_.isEmpty($stateParams.query);

        let cardWatcher = $scope.$watch(
            'card',
            () => {
                $scope.initialDone = _.isEmpty($scope.card.properties.actions);
                $scope.hasActions = !_.isEmpty($scope.card.properties.actions);
                activateAction();
            },
            true
        );

        $scope.$on('$destroy', () => {
            cardWatcher();
        });

        const SHOW_LIMIT = 7;

        $scope.showLimit = SHOW_LIMIT;
        $scope.showMore = showMore;

        function showMore(quantity) {
            quantity = _.isNil(quantity) ? 7 : quantity;
            $scope.showLimit = $scope.showLimit + quantity;
        }

        //targetId is used in both mobile action selection, embedded actions, and for action deep-linking
        if ($scope.hasTarget) {
            actionService
                .searchActions({
                    filter: { id: $stateParams.targetId },
                    options: { size: 1 }
                })
                .then(results => {
                    const [action] = results.results;

                    //TODO: This is a tempory fix as the getAction is not parsing all the trigger data
                    //ISSUE-3412 will address this
                    _.forEach(_.get(action, 'eventTriggers'), function(trigger) {
                        if (_.get(trigger, 'payload') && _.isString(_.get(trigger, 'payload'))) {
                            try {
                                let payload = JSON.parse(_.get(trigger, 'payload'));
                                trigger.payload = payload;
                            } catch (e) {
                                console.error('Action Payload is Malformed');
                            }
                        }
                    });

                    return action;
                })
                .then(action => {
                    // prevent errors in later logic
                    if (!_.get($scope.card, 'properties.actions')) {
                        _.set($scope.card, 'properties.actions', []);
                    }

                    if (!$scope.isMobile) {
                        $scope.selectedAction = action;
                        let actionIndex = _.findIndex($scope.card.properties.actions, {
                            id: $scope.selectedAction.id
                        });

                        //If the action is not on the action list, append it to the list and focus on that action.
                        //This allows a direct link to an action that the backend query might not have it shown
                        if (actionIndex < 0) {
                            $scope.card.properties.actions.unshift($scope.selectedAction);
                            actionIndex = 0;
                        }

                        //If the action is on the action list, just below the pale, increament the showlimit to 5 above the
                        //action index and focus on that action
                        if ($scope.showLimit < actionIndex) {
                            $scope.showLimit = actionIndex + 5;
                        }

                        //toggle the action details view to the selected Action, and set the URL for deep linking
                        $scope.toggleContext($scope.selectedAction.id, $scope.selectedAction);

                        //This sets the focus of the action list on the action at the index selected
                        $timeout(function() {
                            var id = $location.hash();
                            $location.hash(actionIndex);
                            $anchorScroll();
                            $location.hash(id);
                            _.unset($stateParams, 'targetId');
                        }, 200);
                    } else {
                        $scope.ready = false;

                        //For the mobile view, we call card-action-details directly and need to pass resolvedEventTriggers.
                        return actionService
                            .resolveActionEventTriggers({
                                action: action
                            })
                            .then(result => {
                                $scope.resolvedEventTriggers = result;
                                $scope.selectedAction = action;
                                $scope.ready = true;
                            });
                    }
                })
                .catch(e => {
                    toastr.error('An error occurred', _.get(e, 'msg'));
                });

            // When we get a notification that this card should be updated, ingest the new data.
            if (_.get($scope, 'card.socketEventNames.refresh_finished')) {
                $scope.$on($scope.card.socketEventNames.refresh_finished, function(event, payload) {
                    cardsUtilService
                        .getCard({
                            boardId: $scope.board.id,
                            boardDefinitionName: $scope.board.definitionName,
                            panelName: $scope.panel.name,
                            cardName: $scope.card.name,
                            query: $scope.query,
                            facets: $scope.facets
                        })
                        .then(data => {
                            payload.data = data;

                            // Update the card data.
                            actionService.updateCardAfterRefreshEvent($scope, payload);

                            // from here, rely on our watcher on `scope.card` to update state.
                        });
                });
            }
        }

        function activateAction() {
            // If the currently-active action isn't in the list anymore, try activating
            // the next one in the list, or else close the drawer entirely.
            const activeActionIndex = _.findIndex($scope.card.properties.actions, {
                id: $scope.activeId
            });

            if (activeActionIndex == -1 && !_.isEmpty($scope.card.properties.actions)) {
                const firstAction = _.first($scope.card.properties.actions);
                const firstActionId = _.get(firstAction, 'id');
                $scope.needLoadingIfNoAction = true;
                $scope.toggleContext(firstActionId, firstAction);
            } else if (_.isEmpty($scope.card.properties.actions)) {
                if ($scope.needLoadingIfNoAction) {
                    $scope.needLoadingIfNoAction = !$scope.needLoadingIfNoAction;
                    $scope.getCardHitch({
                        data: {
                            cardName: _.get($scope, 'card.name'),
                            facets: _.get($scope, 'facets')
                        }
                    });
                } else {
                    closeDetailsDrawer();
                }
            }
        }

        function openDetailsDrawer() {
            $scope.$root.$emit('rightContent:open', {
                layout: 'actionDetails',
                staticClass: true,
                board: $scope.board,
                user: $scope.user,
                team: $scope.team,
                card: $scope.card,
                panel: $scope.panel,
                selectedAction: $scope.selectedAction,
                api: $scope.api,
                external: $rootScope.isExternal
            });
        }

        function closeDetailsDrawer() {
            $scope.$root.$emit('rightContent:close');
        }

        function deprecatedFilter(item) {
            return !_.get(item, 'isDeprecated', false);
        }

        function showActionDetails() {
            openDetailsDrawer();
        }

        function selectObjectCallback(selectedAction) {
            if (_.isEmpty($stateParams.targetId)) {
                $scope.selectedAction = selectedAction;
                $scope.showActionDetails();
            }
        }

        function removeCompletedAction(data) {
            //notify socket listener to wait
            $rootScope.$emit('actionSubmitted');

            var actionId = _.get(data, 'actionId');
            var actionIndex = _.findIndex($scope.card.properties.actions, {
                id: actionId
            });
            $scope.pullAction(actionIndex);
        }

        function dismissIntegrationCallback(data) {
            //notify socket listener to wait
            $rootScope.$emit('actionSubmitted');

            $timeout(function() {
                if (!_.isEmpty($scope.card.properties.actions)) {
                    var firstAction = $scope.card.properties.actions[0];
                    var firstActionId = _.get(firstAction, 'id');
                    $scope.toggleContext(firstActionId, firstAction);
                }
            }, 1000);
        }

        function pullAction(actionIndex) {
            var isActive = $scope.isActive(_.get($scope.card.properties.actions[actionIndex], 'id'));
            _.pullAt($scope.card.properties.actions, actionIndex);
            $timeout(function() {
                if (actionIndex > 0 && isActive) {
                    const nextAction = getNextAction(actionIndex);
                    $scope.toggleContext(nextAction.id, nextAction);
                } else if (!_.isEmpty($scope.card.properties.actions) && isActive) {
                    const firstAction = _.head($scope.card.properties.actions);
                    const firstActionId = _.get(firstAction, 'id');
                    $scope.toggleContext(firstActionId, firstAction);
                } else if (_.isEmpty($scope.card.properties.actions)) {
                    closeDetailsDrawer();
                }
                //else let it be
            }, 1000);
            // why the timeout?
            $scope.hasActions = !_.isEmpty($scope.card.properties.actions);
        }

        function isActive(id) {
            return $scope.activeId === id;
        }

        function isRepair(action) {
            return _.get(action, 'name') === 'fix-user-integration';
        }

        function toggleContext(id, action) {
            $scope.activeId = id;
            $scope.selectedAction = action;
            $scope.showActionDetails();
        }

        function executeEventTrigger(data) {
            //notify socket listener to wait
            $rootScope.$emit('actionSubmitted');

            var eventTrigger = _.get(data, 'eventTrigger');
            var eventTriggerAction = _.get(data, 'action');
            const resolvedEventTriggers = _.get(data, 'resolvedEventTriggers', []);

            const payload = _.get(data, 'payload', {
                snoozeUntil: _.get(data, 'snoozeUntil')
            });

            const activeActionIndex = _.findIndex($scope.card.properties.actions, {
                id: _.get(data, 'action.id')
            });

            // assuming if there remaining event triggers in the array
            // the one being run is an integration related event trigger so intercept
            if (_.size(resolvedEventTriggers) - 1 > 0) {
                // add this _redraw property to force the action to redraw its form
                eventTriggerAction._redraw = true;
                return $scope.toggleContext(activeActionIndex, eventTriggerAction);
            }

            if (activeActionIndex >= 0 && !$scope.isMobile) {
                const nextAction = getNextAction(activeActionIndex);
                // if this was the last action in the list then close the drawer
                if (_.isEmpty(nextAction)) {
                    closeDetailsDrawer();
                } else {
                    $scope.toggleContext(nextAction.id, nextAction);
                }
            } else if (!_.isEmpty($scope.card.properties.actions) && isActive && !$scope.isMobile) {
                const firstAction = _.head($scope.card.properties.actions);
                const firstActionId = _.get(firstAction, 'id');
                $scope.toggleContext(firstActionId, firstAction);
            } else if (_.isEmpty($scope.card.properties.actions) && !$scope.isMobile) {
                closeDetailsDrawer();
            }
            //else let it be

            //Before executing any event trigger, except URL, put the action into a temporary running state.
            //This way all actions against it will indicate running, including dismissed and dismissed-all.
            //This indicates running before the action event service is called.

            if (_.get(eventTrigger, 'type') !== 'url') {
                const runningAction = _.clone(eventTriggerAction);
                _.set(runningAction, 'state', 'running');
                _.set($scope.card.properties.actions, activeActionIndex, runningAction);
            }

            contentLaunchService.launchActionEventTrigger($scope.user, eventTriggerAction, eventTrigger);

            actionService.actionEvent(eventTriggerAction, eventTrigger, payload).then(
                function(result) {
                    var action = _.get(result, 'action');

                    if (action && !$scope.isMobile) {
                        var assignee = getUserAssignee(action);
                        var assigneeState = _.get(assignee, 'currentState');
                        var assigneeSnooze = _.get(assignee, 'snooze');
                        var currentlySnoozed = $moment(assigneeSnooze).isAfter($moment());

                        var originalAssignee = getUserAssignee(eventTriggerAction);
                        var originalAssigneeSnooze = _.get(originalAssignee, 'snooze');
                        var wasSnoozed = $moment(originalAssigneeSnooze).isAfter($moment());
                        //this logic could cause issues when team owners can override action state
                        let actionIndex = _.findIndex($scope.card.properties.actions, {
                            id: action.id
                        });
                        if (
                            assigneeState !== 'active' ||
                            action.state !== 'active' ||
                            (currentlySnoozed && !wasSnoozed)
                        ) {
                            if (eventTrigger.type == 'run') {
                                _.set($scope.card.properties.actions, actionIndex, action);
                            } else {
                                $scope.pullAction(actionIndex);
                            }
                        } else {
                            //reset the currently "running" state of the action
                            _.set($scope.card.properties.actions, actionIndex, action);
                        }
                    } else if ($scope.isMobile) {
                        $state.go('home.main');
                    }
                },
                function(error) {
                    toastr.error('An error occurred - please try again', '');
                }
            );
        }

        function getUserAssignee(action) {
            var lowerUserEmail = _.toLower(_.get($scope, 'user.email'));
            return _.find(_.get(action, 'assignees', []), {
                email: lowerUserEmail
            });
        }

        function openActionsPanel() {
            $state.go('boards.board.panel', {
                board: $scope.board.shortcode,
                title: null,
                panel: 'Actions',
                facets: null,
                query: null,
                destinationTime: null,
                overwriteReload: false
            });
        }

        function getNextAction(currentIndex) {
            // last item in the list so try to get the previous action
            if (currentIndex >= _.size($scope.card.properties.actions) - 1) {
                return _.get($scope.card.properties.actions, currentIndex - 1, {});
            }

            return _.get(
                $scope.card.properties.actions,
                currentIndex + 1,
                _.last($scope.card.properties.actions)
            );
        }
    }
})();
