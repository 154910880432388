(function() {
    'use strict';

    angular.module('serviceApp').factory('filterSaveService', FilterSaveService);

    FilterSaveService.$inject = ['_', '$resource', '$q', 'definitionService', '$log'];

    function FilterSaveService(_, $resource, $q, definitionService, $log) {
        return { saveFilter, createFilter, copyFilter };

        /**
         * Save Filter
         *
         * @param {*} definition
         * @return {*} promise
         */
        function saveFilter(definition) {
            var deferred = $q.defer();
            //update definition
            definitionService.updateDefinition(definition).then(
                newDef => {
                    deferred.resolve(newDef);
                },
                error => {
                    deferred.reject(error);
                    $log.error('An error occurred updating filter', error.msg || error);
                }
            );
            return deferred.promise;
        }
        /**
         * Create New Filter
         *
         * @param {*} definition
         * @param {*} name new definition name
         * @return {*} promise
         */
        function createFilter(definition, name) {
            var deferred = $q.defer();

            _.unset(definition, 'id');
            _.set(definition, 'name', name);
            _.set(definition, 'displayName', name);
            //enable it tru so we can search
            _.set(definition, 'enabled', true);
            definitionService.updateDefinition(definition).then(
                newDef => {
                    deferred.resolve(newDef);
                },
                error => {
                    deferred.reject(error);
                    $log.error('An error occurred creating filter', error.msg || error);
                }
            );
            return deferred.promise;
        }
        /**
         * Copy Filter
         *
         * @param {*} definition
         * @param {*} boardName
         */
        function copyFilter(definition, boardName, newDisplayName) {
            var deferred = $q.defer();
            definitionService
                .saveAsDefinition({
                    definition: definition,
                    options: { enabled: 'true' },
                    newDisplayName: newDisplayName
                })
                .then(updated => {
                    //If this is board update, find the current board definition
                    if (boardName) {
                        definitionService
                            .searchDefinitions({
                                filter: {
                                    name: boardName,
                                    definitionType: 'board'
                                },
                                withPagination: false,
                                size: 1,
                                searchType: 'filter-coordinator'
                            })

                            .then(definitionItem => {
                                //parse out in the layout and cards where the original card exists and update
                                //with the new definition ID
                                const newDef = JSON.parse(JSON.stringify(definitionItem), (key, value) => {
                                    if (value == definition.id) {
                                        return updated.id;
                                    } else {
                                        return value;
                                    }
                                });
                                //Update the board definition with the updated card and layout
                                definitionService.updateDefinition(_.first(newDef)).then(updatedDef => {
                                    deferred.resolve({ cardDef: updated, boardDef: updatedDef });
                                });
                            });
                    } else {
                        deferred.resolve({ cardDef: updated });
                    }
                })
                .catch(error => {
                    deferred.reject(error);
                    $log.error('An error occurred copying filter', error.msg || error);
                });
            return deferred.promise;
        }
    }
})();
