(function() {
    'use strict';

    angular.module('serviceApp').directive('cardLeaderGraph', cardLeaderGraph);

    function cardLeaderGraph() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                facets: '=?',
                getCardsHitch: '&?'
            },
            template: require('views/tmpl/cards/cardLeaderGraph.html'),
            controller: CardLeaderGraphController
        };

        return directive;
    }

    CardLeaderGraphController.$inject = ['_', '$scope'];

    function CardLeaderGraphController(_, $scope) {
        $scope.getCase = getCase;
        $scope.drawGraph = drawGraph;

        $scope.options = {
            scaleLabel: function(label) {
                return '$' + label.value.toString();
            },
            multiTooltipTemplate: '<%= datasetLabel %> - $<%= value %>'
        };

        $scope.currentRange = getDefaultRange();

        $scope.rangeFacetSelected = rangeFacetSelected;

        /////////////////////
        $scope.$watch(
            'card',
            function() {
                $scope.intervals = _.get($scope.card, 'facetGroups.range.facets');
                if (!$scope.currentRange) {
                    $scope.currentRange = getDefaultRange();
                }
                $scope.drawGraph();
            },
            true
        );

        function rangeFacetSelected(range) {
            $scope.currentRange = range;
            $scope.getCardsHitch({
                data: {
                    facets: {
                        cards: {
                            leadersGraphClosedWin: {
                                range: $scope.currentRange
                            }
                        }
                    }
                }
            });
        }

        function getDefaultRange() {
            if (_.get($scope, 'facets.cards.leadersGraphClosedWin.range')) {
                return _.get($scope, 'facets.cards.leadersGraphClosedWin.range');
            }

            return _.get($scope.card, 'facetGroups.range.default');
        }

        ////////////////////

        function getCase(string) {
            return _.startCase(string);
        }

        function drawGraph() {
            var intervalData = $scope.card.properties.graphData;
            if (!intervalData) {
                return;
            }
            if (!intervalData.data || !intervalData.owners) {
                $scope.hasData = false;
                return;
            } else {
                $scope.hasData = true;
            }

            $scope.owners = _.get(intervalData, 'owners');

            intervalData = _.map(intervalData.data, function(data) {
                data.byOwner = _.map(data.byOwner, function(ownerItem) {
                    _.set(
                        ownerItem,
                        'owner',
                        _.find($scope.owners, ['opportunityOwner.email', ownerItem.email])
                    );
                    return ownerItem;
                });
                return data;
            });

            $scope.labels = _.map(intervalData, 'label');
            $scope.series = _.map($scope.owners, 'opportunityOwner.name');

            $scope.data = _.map($scope.series, function(name) {
                return _.map(intervalData, function(data) {
                    return _.round(
                        _.get(_.find(data.byOwner, ['owner.opportunityOwner.name', name]), 'totalAmount', 0)
                    );
                });
            });
        }
    }
})();
