(function() {
    'use strict';

    angular.module('serviceApp').directive('showMoreArray', showMoreArray);

    function showMoreArray() {
        var directive = {
            restrict: 'AE',
            replace: true,
            scope: {
                array: '<',
                limit: '<',
                label: '<?'
            },
            template: require('views/tmpl/partials/showMoreArray.html'),
            controller: ShowMoreArrayController
        };

        return directive;
    }

    ShowMoreArrayController.$inject = ['$scope'];

    function ShowMoreArrayController($scope) {
        $scope.arrayLimit = $scope.limit;

        $scope.set = function(path, value) {
            _.set($scope, path, value);
        };
    }
})();
