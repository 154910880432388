(function() {
    'use strict';
    angular
        .module('serviceApp')
        .directive('definitionEditorMetaFormActivity', definitionEditorMetaFormActivity);
    function definitionEditorMetaFormActivity() {
        var directive = {
            restrict: 'E',
            scope: { definition: '=' },
            template: require('views/tmpl/partials/definitionEditors/metaForms/activity.html')
        };

        return directive;
    }
})();
