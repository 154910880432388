import template from './index.html';
import controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('schemaDataView', schemaDataView);

function schemaDataView() {
    var directive = {
        restrict: 'E',
        scope: {
            key: '<',
            values: '<',
            administeredContent: '<?',
            classy: '<?',
            viewUnformatted: '<?',
            contentType: '<',
            propertyDisplayOptions: '<',
            boardId: '<?'
        },
        template,
        controller
    };

    return directive;
}
