'use strict';
import _ from 'lodash';
import TagsController from '../tags/controller';

export default class PropertyTagsController extends TagsController {
    static get $inject() {
        return ['$scope'];
    }
    constructor($scope) {
        // The `tag-input` class expects a `definition` object
        // with a `tags` property which is an array of objects with `label` keys representing tags.
        // We'll create that object out of our array of tag strings.
        $scope.definition = {
            tags: _.map(_.get($scope, 'content.display.tags', []), tag => ({ label: tag }))
        };

        // Run the parent constructor.
        super($scope);

        // Get the content type of the property we're editing.
        $scope.contentType = _.get($scope, 'content.contentType');

        // Set a flag to indicate we're doing our inital load of the component.
        this.initialLoad = true;

        // Start watching for changes to the tags.
        $scope.$watch('definition.tags', this.refreshTags.bind(this), true);
    }

    /**
     * When tags change, update our internal representation to match.
     */
    refreshTags() {
        // Marshal the definition tags array back to an array of strings.
        _.set(this.scope, 'content.display.tags', _.map(_.get(this.scope, 'definition.tags', []), 'label'));

        // If this is the first load, don't dirty the form.
        if (this.initialLoad) {
            this.initialLoad = false;
            return;
        }

        // Otherwise set the form as dirty since we modified tags.
        this.scope.onChangeTags();
    }
}
