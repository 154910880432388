DefinitionEditorController.$inject = [
    '_',
    '$scope',
    '$log',
    'toastr',
    'definitionService',
    '$cookies',
    '$q',
    'auditService'
];

export default function DefinitionEditorController(
    _,
    $scope,
    $log,
    toastr,
    definitionService,
    $cookies,
    $q,
    auditService
) {
    $scope.headerDirty = $scope.editing;
    $scope.formDirty = false;
    $scope.persistedDefinition = _.cloneDeep($scope.definition);
    $scope.nameDupe = false;
    $scope.selectedTab = 'editor';
    $scope.formValid = true;
    $scope.registeredHooks = {
        'post-save': {},
        'pre-save': {}
    };
    $scope.$on('definition-editor:show-warning', (event, data) => {
        $scope.$broadcast('definition-editor-header:show-warning', data);
    });

    $scope.invalidFormSections = [];

    auditService
        .searchAuditEvents({
            size: 1,
            withPagination: false,
            filter: {
                action: ['definition.updated', 'definition.created', 'definition.copied']
            },
            sort: {
                timestamp: { order: 'desc' }
            },
            entity: {
                id: _.get($scope.definition, 'id')
            }
        })
        .then(result => {
            $scope.auditEvents = result.results;
        });

    $scope.$on('definition:form-validate', (event, { section, isValid, setApply }) => {
        const currentIndex = $scope.invalidFormSections.indexOf(section);
        if (isValid && currentIndex < 0) {
            return;
        }

        if (!isValid && currentIndex < 0) {
            $scope.invalidFormSections.push(section);
            //react components don't apply changes automatically
            if (setApply) {
                $scope.$apply();
            }
            return;
        }

        if (isValid) {
            $scope.invalidFormSections.splice(currentIndex, 1);
            //react components don't apply changes automatically
            if (setApply) {
                $scope.$apply();
            }
            return;
        }
    });

    $scope.$watch(
        'invalidFormSections',
        () => {
            if ($scope.invalidFormSections.length > 0) {
                $scope.formValid = false;
                return;
            }
            $scope.formValid = true;
            return;
        },
        true
    );

    // Create the set of accordions used by this editor.
    // TODO -- once we have > 1 tab with accordions on it, we will probably have to do some refactoring,
    //         because the accordion controller is currently responsible for gathering up all of the
    //         changes made in its groups when "save" is clicked.
    $scope.accordions = _.reduce(
        _.get($scope.definition, '_tabs', []),
        (memo, tabDef) => {
            if (_.size(tabDef.sections)) {
                memo.push({
                    tabName: tabDef.name,
                    groups: definitionService.getAccordionGroups(tabDef, $scope.definition)
                });
            }
            return memo;
        },
        []
    );

    // Return whether a given tab exists in the current definition config.
    $scope.hasTab = tabName => {
        return !!_.find(_.get($scope.definition, '_tabs'), { name: tabName });
    };

    const selectedTabWatcher = $scope.$watch('selectedTab', () => {
        if ($scope.selectedTab === 'action-preview') {
            $scope.$broadcast('formAccordion:preview');
        }
        if ($scope.selectedTab === 'hierarchy-preview') {
            $scope.$broadcast('formAccordion:preview');
        }
    });

    const cleanDefinition = () => {
        const baseDefinition = _.cloneDeep($scope.definition);
        const headerDefinition = _.cloneDeep($scope.headerDefinition);
        const updatedPropertyValues = _.cloneDeep($scope.updatedPropertyValues);
        let updatedSchemaValues = _.cloneDeep($scope.updatedSchemaValues);

        if (!updatedPropertyValues) {
            $log.error('updatedPropertyValues must be defined to save');
            return;
        }

        //Update schema with display options
        let displayOptions = _.cloneDeep(_.get($scope.definition, 'propertySchema.filter._display', {}));
        _.set(updatedSchemaValues, 'filter._display', displayOptions);

        if (!baseDefinition.globalId) {
            baseDefinition.globalId = baseDefinition.id;
        }

        //header can only control enabled vs disabled so trust it
        if (headerDefinition) {
            baseDefinition.enabled = headerDefinition.enabled;
        }
        baseDefinition.propertyValues = updatedPropertyValues;
        baseDefinition.propertySchema = updatedSchemaValues;

        return baseDefinition;
    };

    $scope.save = function() {
        // Set `isSaving` to disable the save button.
        $scope.isSaving = true;
        // First make sure that the display name is unique.
        return definitionService
            .searchDefinitions({
                filter: {
                    definitionType: _.get($scope, 'definition.definitionType'),
                    teamId: _.get($scope.user, 'state.currentTeam'),
                    'displayName.rawLowercase': _.toLower(_.get($scope.definition, 'displayName'))
                },
                searchType: 'definition-editor',
                withPagination: false,
                size: 1
            })
            .then(definitions => {
                // If not, set the dupe flag and bail out.
                if (_.size(definitions) && _.first(definitions).id !== _.get($scope, 'definition.id')) {
                    $scope.nameDupe = true;
                    throw new Error('This name is in use');
                }

                // Otherwise continue with the save.
                const baseDefinition = cleanDefinition();

                //save began, clean temp vars
                $scope.headerDefinition = undefined;
                $scope.updatedPropertyValues = undefined;
                $scope.updatedSchemaValues = undefined;

                $log.info('Saving definition', baseDefinition);
                return baseDefinition;
            })
            .then(baseDefinition => {
                const preSavePromises = [];
                _.each($scope.registeredHooks['pre-save'], fn => preSavePromises.push(fn(baseDefinition)));
                if (preSavePromises.length < 1) {
                    return baseDefinition;
                }

                return $q.all(preSavePromises).then(() => baseDefinition);
            })
            .then(baseDefinition => definitionService.updateDefinition(baseDefinition))
            .then(function(updatedDefinition) {
                if (!updatedDefinition) {
                    throw new Error('UPDATED_DEFINITION_UNDEFINED');
                }
                $scope.definition = updatedDefinition;
                $scope.headerDirty = false;
                $scope.formDirty = false;
                const definitionType = _.get($scope, 'definition.definitionType');
                toastr.success(_.startCase(definitionType) + ' updated successfully', '');
                return updatedDefinition;
            })
            .then(updatedDefinition => {
                const postSavePromises = [];
                _.each($scope.registeredHooks['post-save'], fn => postSavePromises.push(fn()));
                if (postSavePromises.length < 1) {
                    return updatedDefinition;
                }

                return $q.all(postSavePromises).then(() => updatedDefinition);
            })
            .then(updatedDefinition => {
                $scope.persistedDefinition = _.cloneDeep($scope.definition);
                definitionService.redirectAfterSavingDefinition(
                    $scope.board,
                    updatedDefinition,
                    $scope.api.refresh
                );
            })
            .catch(err => {
                // Clear `isSaving` to re-enable the save button.
                $scope.isSaving = false;

                const definitionType = _.get($scope, 'definition.definitionType');
                const message = _.get(err, 'data.message') || err.message;

                toastr.error(`An error occurred saving ${definitionType} - ${message} - Please try Again`);
            });
    };

    $scope.delete = function() {
        return definitionService
            .deleteDefinition($scope.headerDefinition.id)
            .then(function() {
                //after deletion, remove the definition if its the set board def to avoid hitting the failover case since we can avoid it
                if (_.includes($cookies.get('boardDef'), $scope.headerDefinition.name)) {
                    $cookies.remove('boardDef');
                }
                definitionService.redirectAfterSavingDefinition(
                    $scope.board,
                    _.get($scope.board, 'definition'),
                    $scope.api.refresh,
                    'defaultDefinitionName'
                );
                toastr.success($scope.headerDefinition.displayName + ' deleted successfully', '');
                $scope.$root.$emit('rightDrawer:close');
            })
            .catch(function(err) {
                console.error(err);
                const definitionType = _.get($scope, 'definition.definitionType');
                if (toastr && toastr.error) {
                    toastr.error('An error occurred deleting ' + definitionType + ' - please try again', '');
                }
            });
    };

    $scope.headerSave = function(definition) {
        $scope.headerDefinition = _.cloneDeep(definition);
        $scope.$broadcast('formAccordion:save');
    };

    $scope.headerDelete = function(definition) {
        $scope.headerDefinition = _.cloneDeep(definition);
        $scope.delete();
    };

    $scope.getCopyPromise = (definition, defEnabled, newDisplayName) =>
        $scope.definition.definitionType === 'card'
            ? definitionService.saveAsDefinition({
                  definition,
                  options: { enabled: defEnabled },
                  newDisplayName: newDisplayName
              })
            : definitionService.copyDefinition({
                  definitionId: _.get(definition, 'id'),
                  options: { enabled: defEnabled }
              });

    $scope.headerCopy = (data, definition) => {
        // Set `isSaving` to disable the copy button.
        $scope.isSaving = true;

        const defEnabled = $scope.definition.definitionType !== 'action' ? $scope.definition.enabled : false;

        const copyPromise = $scope.getCopyPromise(definition, defEnabled, data);

        return copyPromise
            .then(result => {
                definitionService.redirectAfterSavingDefinition(
                    $scope.board,
                    _.get(result, 'definition'),
                    $scope.api.refresh
                );
            })
            .catch(err => {
                // Clear `isSaving` to re-enable the save button.
                $scope.isSaving = false;
                console.error(err);
                const definitionType = _.get($scope, 'definition.definitionType');
                toastr.error('An error occurred copying ' + definitionType + ' - please try again');
            });
    };

    $scope.headerCreate = (data, definition) => {
        $scope.isSaving = true;
        _.unset(definition, 'id');
        _.set(definition, 'name', data);
        _.set(definition, 'displayName', data);
        //enable it tru so we can search
        if ($scope.definition.definitionType !== 'action') {
            _.set(definition, 'enabled', true);
        }
        definitionService
            .updateDefinition(definition)
            .then(result => {
                definitionService.redirectAfterSavingDefinition($scope.board, result, $scope.api.refresh);
            })
            .catch(err => {
                // Clear `isSaving` to re-enable the save button.
                $scope.isSaving = false;
                console.error(err);
                const definitionType = _.get($scope, 'definition.definitionType');
                toastr.error('An error occurred copying ' + definitionType + ' - please try again');
            });
    };

    /**
     * headerCopyReplace
     * copy the card and replace on a current board
     * @param {*} definition
     */
    $scope.headerCopyReplace = (data, definition) => {
        // Set `isSaving` to disable the copy button.
        $scope.isSaving = true;

        const defEnabled = $scope.definition.definitionType !== 'action' ? $scope.definition.enabled : false;

        const copyPromise = $scope.getCopyPromise(definition, defEnabled, data);

        return copyPromise
            .then(result => {
                const copiedDefinition = _.get(result, 'definition');
                if (!copiedDefinition) throw new Error('Error: Copy and Replace | No definition returned');

                // Call to replacing a card on a current board with copied card id
                $scope.$root.$emit('definition-editor:closed', copiedDefinition.id);
                $scope.$root.$emit('rightDrawer:close');
            })
            .catch(err => {
                // Clear `isSaving` to re-enable the save button.
                $scope.isSaving = false;
                console.error(err);
                const definitionType = _.get($scope, 'definition.definitionType');
                toastr.error('An error occurred copying ' + definitionType + ' - please try again');
            });
    };

    // hooks must return promise
    $scope.registerHook = (eventType, identifier, hookCallback) => {
        if (_.has($scope.registeredHooks, eventType)) {
            $scope.registeredHooks[eventType][identifier] = hookCallback;
        } else {
            console.error(
                `unsupported hook in definitionEditor - eventType=${eventType}, identifier=${identifier}`
            );
        }
    };

    $scope.registerPreSaveHooks = (identifier, beforeDefinitionSaveCallback) => {
        $scope.registerHook('pre-save', identifier, beforeDefinitionSaveCallback);
    };

    $scope.registerPostSaveHooks = (identifier, afterDefinitionSaveCallback) => {
        $scope.registerHook('post-save', identifier, afterDefinitionSaveCallback);
    };

    $scope.$on('definitionEditor:save', function(event, updatedPropertyValues, updatedSchemaValues) {
        $scope.updatedPropertyValues = _.cloneDeep(updatedPropertyValues);
        $scope.updatedSchemaValues = _.cloneDeep(updatedSchemaValues);
        $scope.save();
    });

    $scope.$on('definitionEditor:update', function(event, data) {
        $log.info('Definition update', { event, data });
        _.forEach(data, (value, key) => {
            _.set($scope, `definition[${key}]`, _.cloneDeep(value));
        });
        //Communicate to the panelHeader that the filter has updated and should reload the panel cards
        if ($scope.api.updateBoard) {
            $scope.api.updateBoard($scope.definition);
        }
    });

    $scope.$on('editor:setPropertyHidden', (event, { path, hidden }) => {
        //Set the hidden display option for this filter to toggled on/off on this definition
        let hiddenFilter = _.cloneDeep(_.get($scope.definition, 'propertySchema.filter._display', {}));
        _.set(hiddenFilter, [path, 'hidden'], hidden);
        _.set($scope.definition, 'propertySchema.filter._display', hiddenFilter);
    });

    $scope.$on('definitionEditor:preview', function(event, updatedPropertyValues, updatedSchemaValues) {
        $scope.updatedPropertyValues = _.cloneDeep(updatedPropertyValues);
        $scope.updatedSchemaValues = _.cloneDeep(updatedSchemaValues);

        $scope.cleanDefinition = cleanDefinition();
    });

    $scope.$on('definitionEditor:dirty', function(event, formDirty) {
        $scope.formDirty = formDirty;
    });

    $scope.$on('$destroy', () => {
        // Destroy watchers.
        selectedTabWatcher();
    });

    $scope.$on('definitionEditor:exportCsv', function(event, { data, fileName }) {
        const a = document.createElement('a');
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.target = '_blank';
        a.download = fileName;
        a.style.visibility = 'hidden';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    });
}
