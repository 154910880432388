'use strict';

angular
    .module('serviceApp')
    .service('boardSliceService', ['$rootScope', '$timeout', 'definitionService', BoardSliceService]);

function BoardSliceService($rootScope, $timeout, definitionService) {
    let _store;
    let _boardSlice;
    let _sliceName;
    let _state;
    let _isLoaded = false;
    let _unsubscribe;

    let deferredActions = [];

    /**
     * Board Selectors
     */
    const selectBoard = () => {
        return _boardSlice.selectors.selectBoard().resultFunc(_state);
    };

    const selectBoardId = () => {
        return _boardSlice.selectors.selectBoardId().resultFunc(_state);
    };

    const selectCurrentCardName = () => {
        return _boardSlice.selectors.selectCurrentCardName().resultFunc(_state);
    };

    /**
     * Board Definition Selectors
     */
    const selectBoardDefinitionId = () => {
        return _boardSlice.selectors.selectBoardDefinitionId().resultFunc(_state);
    };

    const selectBoardDefinition = () => {
        return _boardSlice.selectors.selectBoardDefinition().resultFunc(_state);
    };

    const selectBoardDefinitionPropertySchema = () => {
        return _boardSlice.selectors.selectBoardDefinitionPropertySchema().resultFunc(_state);
    };

    const selectBoardDefinitionPropertyValues = () => {
        return _boardSlice.selectors.selectBoardDefinitionPropertyValues().resultFunc(_state);
    };

    /**
     * Cards Selectors
     */
    const selectCardsStale = () => {
        return _boardSlice.selectors.selectCardsStale().resultFunc(_state);
    };

    /**
     * Definition Selectors
     */
    const selectDefinitionId = () => {
        return _boardSlice.selectors.selectDefinitionId().resultFunc(_state);
    };

    const selectDefinitionIsRemovable = () => {
        return _boardSlice.selectors.selectDefinitionIsRemovable().resultFunc(_state);
    };

    const selectDefinition = () => {
        return _boardSlice.selectors.selectDefinition().resultFunc(_state);
    };

    const selectDefinitionPropertySchema = () => {
        return _boardSlice.selectors.selectDefinitionPropertySchema().resultFunc(_state);
    };

    const selectDefinitionPropertyValues = () => {
        return _boardSlice.selectors.selectDefinitionPropertyValues().resultFunc(_state);
    };

    /**
     * Filter Columns Selectors
     */
    const selectFilterColumns = definitionId => {
        return _boardSlice.selectors.selectFilterColumns({ definitionId }).resultFunc(_state);
    };

    const selectHiddenFilterColumns = definitionId => {
        return _boardSlice.selectors.selectHiddenFilterColumns({ definitionId }).resultFunc(_state);
    };

    const selectAvailableProperties = definitionId => {
        return _boardSlice.selectors.selectAvailableProperties({ definitionId }).resultFunc(_state);
    };

    const selectCardConfig = cardName => {
        return _boardSlice.selectors.selectCardConfig({ cardName }).resultFunc(_state);
    };

    /**
     * Activity By Day Selector
     */
    const selectABDEnabled = () => {
        if (_boardSlice.selectors.selectABDEnabled) {
            return _boardSlice.selectors.selectABDEnabled().resultFunc(_state);
        }
        return false;
    };

    /**
     * Updates selector values with the latest data from the store.
     */
    const initSelectors = () => {
        // Board
        this.board = selectBoard();
        this.boardId = selectBoardId();

        // Board Definition
        this.boardDefinitionId = selectBoardDefinitionId();
        this.boardDefinition = selectBoardDefinition();
        this.boardDefinitionPropertySchema = selectBoardDefinitionPropertySchema();
        this.boardDefinitionPropertyValues = selectBoardDefinitionPropertyValues();

        // Cards
        this.cardsStale = selectCardsStale();

        // Definition
        this.definitionIsRemovable = selectDefinitionIsRemovable();
        this.definitionId = selectDefinitionId();
        this.definition = selectDefinition();
        this.definitionPropertySchema = selectDefinitionPropertySchema();
        this.definitionPropertyValues = selectDefinitionPropertyValues();

        // Filter Columns
        this.boardDefinitionColumns = selectFilterColumns(this.boardDefinitionId);
        this.boardDefinitionHiddenColumns = selectHiddenFilterColumns(this.boardDefinitionId);
        this.definitionColumns = selectFilterColumns(this.definitionId);
        this.definitionHiddenColumns = selectHiddenFilterColumns(this.definitionId);

        this.boardAvailableProperties = selectAvailableProperties(this.boardDefinitionId);
        this.definitionAvailableProperties = selectAvailableProperties(this.definitionId);

        this.currentCardName = selectCurrentCardName();
        if (this.currentCardName) {
            this.cardConfig = selectCardConfig(this.currentCardName);
        }

        // ABD Enabled
        this.isABDEnabled = selectABDEnabled();

        // Run an AngularJS digest loop in the next tick so that any
        // watchers on the store slice can fire.
        $timeout(() => {
            $rootScope.$digest();
        });
    };

    /**
     * Handles updating selector values every time the store changes
     */
    const handleStoreChanged = () => {
        _state = _store.getState()[_sliceName];

        // Update selectors
        initSelectors();
    };

    /**
     * Returns true when the boardSlice has been loaded in platform-ui-components, false otherwise.
     *
     * @returns {Boolean}
     */
    this.isLoaded = () => {
        return _isLoaded;
    };

    /**
     * Actions
     */
    this.boardAdd = board => {
        if (board && board.toJSON) {
            board = board.toJSON();
        }

        _store.dispatch(_boardSlice.actions.boardAdd({ board }));
        $timeout(() => {
            $rootScope.$digest();
        });
    };

    this.boardClear = () => {
        _store.dispatch(_boardSlice.actions.boardClear());
        $timeout(() => {
            $rootScope.$digest();
        });
    };

    this.definitionAdd = (
        definition,
        isRemovable = false,
        refreshCards = false,
        setAvailableProperties = false,
        cardName
    ) => {
        if (definition && definition.toJSON) {
            definition = definition.toJSON();
        }

        _store.dispatch(
            _boardSlice.actions.definitionAdd({
                definition,
                isRemovable,
                refreshCards,
                setAvailableProperties,
                cardName
            })
        );
        // Set the filter definition in the root scope.
        // @todo - find all places using this and replace with reference to board definition.
        $rootScope.currentFilter = definition;
        // If we're using an in-flight definition, store it in localstorage.
        if (!definition.id) {
            definitionService.setStoredFilter(this.board, definition);
        }
        $timeout(() => {
            $rootScope.$digest();
        });
    };

    this.definitionClear = (isRemovable = false, ignoreStale = false) => {
        _store.dispatch(_boardSlice.actions.definitionClear({ isRemovable, ignoreStale }));
        // Clear out the filter definition in the root scope.
        // @todo - find all places using this and replace with reference to board definition.
        $rootScope.currentFilter = null;
        $timeout(() => {
            $rootScope.$digest();
        });
    };

    this.columnReorder = (definitionId, currentIndex, targetIndex) => {
        _store.dispatch(_boardSlice.actions.columnReorder({ definitionId, currentIndex, targetIndex }));
    };

    this.columnAdded = (definitionId, propertyName) => {
        _store.dispatch(_boardSlice.actions.columnAdded({ definitionId, propertyName }));
    };

    this.addForecastSubmissionFilters = () => {
        _store.dispatch(_boardSlice.thunks.addForecastSubmissionFilters());
    };

    this.resolveAvailableProperties = definitionId => {
        _store.dispatch(_boardSlice.thunks.resolveAvailableProperties(definitionId));
    };

    this.columnSort = (definitionId, orderObj) => {
        _store.dispatch(_boardSlice.actions.columnSort({ definitionId, ...orderObj }));
    };

    this.columnWidthUpdated = (definitionId, columnIndex, columnWidth) => {
        _store.dispatch(_boardSlice.actions.columnWidthUpdated({ definitionId, columnIndex, columnWidth }));
    };

    this.clearCardsStale = () => {
        _store.dispatch(_boardSlice.actions.clearCardsStale());
    };

    this.clearIsDirty = definitionId => {
        _store.dispatch(_boardSlice.actions.clearIsDirty({ definitionId }));
    };

    this.definitionDisplayNameUpdated = (definitionId, displayName) => {
        _store.dispatch(_boardSlice.actions.definitionDisplayNameUpdated({ definitionId, displayName }));
    };

    this.setABDEnabled = (definitionId, isEnabled, cardName) => {
        _store.dispatch(_boardSlice.actions.setABDEnabled({ definitionId, isEnabled, cardName }));
    };

    this.getCardConfig = cardName => {
        return _boardSlice.selectors.selectCardConfig({ cardName }).resultFunc(_state);
    };

    /**
     * Responsible for loading the service with state that represents our `boardSlice`
     * maintained in react.
     *
     * @param {Object} store - An instance of our redux store.
     * @param {Object} boardSlice - The slice from the store representing a board.
     */
    this.loadSlice = ({ store, boardSlice }) => {
        _isLoaded = true;

        // Store actions/thunks/selectors
        _boardSlice = boardSlice;
        _sliceName = boardSlice.name;

        // The entire redux store object
        _store = store;
        // Will be fired every time the entire store changes
        _unsubscribe = _store.subscribe(handleStoreChanged);

        // The current slice of the store
        _state = _store.getState()[_sliceName];

        // If we have deferred actions, run them now.
        if (deferredActions.length) {
            _.each(deferredActions, action => action());
            deferredActions = [];
        }

        // Init selectors
        initSelectors();
    };

    this.sliceRemoved = () => {
        _isLoaded = false;

        // When we are notified that the board slice has been removed, our cache of those properties
        // will still have the values from the now-removed board slice, so we'll clear them out here.
        // This prevents the values from incorrectly being used when transitioning between pages.
        // TODO -- is there a better pattern we can apply to connect Redux to Angular that doesn't
        //         require this kind of manual intervention?
        this.board = {};
        this.boardId = null;
        this.boardDefinitionId = null;
        this.boardDefinition = {};
        this.boardDefinitionPropertySchema = {};
        this.boardDefinitionPropertyValues = {};
        this.cardsStale = false;
        this.definitionIsRemovable = false;
        this.definitionId = null;
        this.definition = {};
        this.definitionPropertySchema = {};
        this.definitionPropertyValues = {};
        this.boardDefinitionColumns = [];
        this.boardDefinitionHiddenColumns = [];
        this.definitionColumns = [];
        this.definitionHiddenColumns = [];
        this.boardAvailableProperties = [];
        this.definitionAvailableProperties = [];
        this.isABDEnabled = false;

        _unsubscribe && _unsubscribe();
    };

    // When changing pages, clear out the slice immediately.
    // Otherwise we may make an API call for the cards for
    // the new page before the old page and components are
    // "destroyed", which is what typicall triggers the
    // call to "sliceRemoved".
    $rootScope.$on('$stateChangeStart', this.sliceRemoved);
}
