(function() {
    'use strict';

    angular.module('serviceApp').directive('signalActionItem', signalActionItem);

    function signalActionItem() {
        var directive = {
            restrict: 'E',
            scope: {
                user: '<',
                action: '=',
                content: '=',
                updateActionCallback: '&'
            },
            template: require('views/tmpl/partials/signalActionItem.html'),
            controller: SignalActionItemController
        };

        return directive;
    }

    SignalActionItemController.$inject = ['_', '$scope', '$interpolate', 'contentLaunchService'];

    function SignalActionItemController(_, $scope, $interpolate, contentLaunchService) {
        if (_.get($scope, 'action.prompt')) {
            $scope.action.prompt = $interpolate($scope.action.prompt)($scope);
        }

        $scope.updateAction = function(update) {
            $scope.action.state = update;

            $scope.updateActionCallback({
                data: {
                    action: $scope.action
                }
            });
        };

        $scope.isDisabled = function() {
            if (!_.isEmpty($scope.action.userUrls)) {
                var isUserDisabled = true;
                _.forEach($scope.action.userUrls, function(id) {
                    if (
                        _.some(_.get($scope.user, 'hashedEmails', []), hashedEmail =>
                            _.includes(id, hashedEmail)
                        )
                    ) {
                        isUserDisabled = false;
                    }
                });
                return isUserDisabled;
            }
            return false;
        };

        $scope.doAction = function() {
            contentLaunchService.doLaunch(
                null,
                $scope.action,
                $scope.user,
                $scope.content.boardId,
                $scope.content
            );
            $scope.updateAction('done');
        };

        $scope.exists = function(url) {
            return !_.isNil(url) && !_.isEmpty(url);
        };
    }
})();
