import { durationFormats } from '../utils/duration-utils';

(function() {
    'use strict';
    angular.module('serviceApp').factory('durationFormatService', DurationFormatService);

    DurationFormatService.$inject = ['_', '$rootScope'];

    function DurationFormatService(_, $rootScope) {
        const pathToDisplayUnit = type => ['displayInterval', type, 'unit'];
        const pathToDisplayValue = type => ['displayInterval', type, 'value'];
        const pathToDisplayIsGroupByChanged = type => ['displayInterval', 'isGroupByChanged', type];
        const pathToModelGroupBy = type => ['model', 'properties', type, 'groupBy'];
        const pathToModelInterval = type => ['model', 'properties', type, 'interval'];
        const pathToModelSize = type => ['model', 'properties', type, 'size'];
        const pathToModelIncludeMissing = type => ['model', 'properties', type, 'includeMissing'];

        /**
         * This function will retrieve the groupBy's format from display options object
         * @param {string} groupBy
         * @returns | string (durationFormat key) || undefined
         */
        const getDisplayFormat = function(groupBy) {
            if (!groupBy) return;
            // retrieve first part for display option key
            const [displayOptionKey] = _.split(groupBy, '.', 1);

            // find out if the propertyDisplayOptions with the key has format and the format match one of the durationFormats
            return _.get($rootScope.displayOptions, [
                displayOptionKey,
                'propertyDisplayOptions',
                groupBy,
                'format'
            ]);
        };

        /**
         * This function will identify whether groupBy is a durationFormat
         * @param {string} groupBy
         * @returns | { unit: string, toMilSec: number } || undefined
         */
        const getDisplayUnitObject = function(groupBy) {
            const displayFormat = getDisplayFormat(groupBy);
            if (!displayFormat) return;
            return _.get(durationFormats, [displayFormat]);
        };

        /**
         * It determines whether groupBy's unit of [independent || split] is one of the
         * durationFormat or not
         * This function will set the display interval unit of property passed in, adherence the condition above
         * This function will also set the display interval value if isFirstTime arg is passed in as true
         * @param {string} property independent | split
         * @param {boolean} isFirstTime T|F [default: false]
         */
        const setDisplayIntervalUnit = function(property, isFirstTime = false) {
            _.set(this.scope, pathToDisplayUnit(property), null);
            const path = pathToModelGroupBy(property);
            const groupBy = _.get(this.scope, pathToModelGroupBy(property));

            const displayUnitObject = getDisplayUnitObject(groupBy);
            const displayUnit = _.get(displayUnitObject, 'unit');
            const toMilSec = _.get(displayUnitObject, 'toMilSec') || 1;

            if (displayUnit) {
                _.set(this.scope, pathToDisplayUnit(property), displayUnit);
            }
            // @first time rendered, set the display interval
            if (isFirstTime) {
                if (this.scope.isNumber(groupBy)) {
                    _.set(
                        this.scope,
                        pathToDisplayValue(property),
                        _.get(this.scope, pathToModelInterval(property)) / toMilSec || 1
                    );
                } else if (this.scope.isString(groupBy) && !_.get(this.scope, pathToModelSize(property))) {
                    _.set(this.scope, pathToModelSize(property), 10);
                }
            }
        };

        /**
         * This function will convert time with any unit to millisecond
         * @param {number} value | interval value
         * @param {string} groupBy | display option key (groupBy)
         */
        const convertTimeIntervalToMilSec = function(value, groupBy) {
            const toMilSec = getDisplayUnitObject(groupBy).toMilSec || 1;
            return value * toMilSec;
        };

        /**
         * This function will update an model.properties.[independent||split].interval
         * every time the displayInterval.independent.unit changed on view
         * @param {string} property independent | split
         */
        const setIntervalValue = function(property) {
            let value = _.get(this.scope, pathToDisplayValue(property));
            if (_.get(this.scope, pathToDisplayUnit(property))) {
                const groupBy = _.get(this.scope, pathToModelGroupBy(property));
                value = convertTimeIntervalToMilSec(value, groupBy);
            }
            _.set(this.scope, pathToModelInterval(property), value);
        };

        const handleGroupByChanged = function(newValue, intervalType) {
            if (!intervalType || !newValue) {
                return;
            }
            if (!_.get(this.scope, pathToDisplayIsGroupByChanged(intervalType))) {
                _.set(this.scope, pathToDisplayIsGroupByChanged(intervalType), true);
                setDisplayIntervalUnit.call(this, intervalType, true);
                return;
            }

            let currentModelIntervalValue = _.get(this.scope, pathToModelInterval(intervalType));
            if (this.scope.isDate(newValue) && _.isNumber(currentModelIntervalValue)) {
                // If we're using a date property to groupBy, and we have a numeric interval set, clear it.
                _.set(this.scope, pathToDisplayValue(intervalType), null);
                _.set(this.scope, pathToModelInterval(intervalType), null);
            } else if (this.scope.isNumber(newValue)) {
                // If we're using a numeric property to groupBy, set a default value for the interval.
                _.set(this.scope, pathToDisplayValue(intervalType), 1);
                _.set(this.scope, pathToModelInterval(intervalType), 1);
            }

            if (
                !this.scope.isString(newValue) &&
                _.get(this.scope, pathToModelIncludeMissing(intervalType))
            ) {
                // If the new property isn't a string, clear out includeMissing
                _.set(this.scope, pathToModelIncludeMissing(intervalType), false);
            } else {
                _.set(this.scope, pathToModelSize(intervalType), 10);
            }
            setDisplayIntervalUnit.call(this, intervalType);
            setIntervalValue.call(this, intervalType);
        };

        return {
            getDisplayUnitObject,
            getDisplayFormat,
            convertTimeIntervalToMilSec,
            setDisplayIntervalUnit,
            setIntervalValue,
            handleGroupByChanged
        };
    }
})();
