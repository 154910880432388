'use strict';

export default class CardBase {
    static get $inject() {
        return ['$scope', '$rootScope', 'actionService', 'toastr', 'contentLaunchService', 'themeService'];
    }
    constructor($scope, $rootScope, actionService, toastr, contentLaunchService, themeService) {
        this.scope = $scope;
        this.rootScope = $rootScope;
        this.scope.view = _.get(this.scope.card, 'properties.view') || 'dashboard';

        // imports used by action list card
        this.actionService = actionService;
        this.toastr = toastr;
        this.contentLaunchService = contentLaunchService;
        this.scope.themeService = themeService;

        this.scope.isDashboard = this.scope.view === 'dashboard';

        this.scope.getCardNested = data => {
            this.scope.getCardHitch({
                data: data
            });
        };

        this.scope.buildDownloadUrlNested = data => {
            return this.scope.buildDownloadUrlHitch({
                data: data
            });
        };

        this.scope.getDynamicSize = value => {
            //creates a relative size for the view box of the title
            return `0 0 ${_.size(value) * 5 + 90} 18`;
        };
    }
}
