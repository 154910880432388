'use strict';

export default class Controller {
    static get $inject() {
        return ['_', '$log', '$scope', '$stateParams', '$state', '$timeout', '$sanitize', 'userServiceNew'];
    }
    constructor(_, $log, $scope, $stateParams, $state, $timeout, $sanitize, userServiceNew) {
        const targetId = _.split(_.get($stateParams, 'targetId'), '$');
        if (targetId) {
            $scope.targetCardId = _.last(targetId);
            $scope.targetBoardName = _.first(targetId);
        }

        // Listen to inner menu click on menu items
        $scope.menuClick = function(payload) {
            const { cardDefinitionId, boardDefinitionName, boardType, type, cardDefinition } = _.get(
                payload,
                'data',
                {}
            );
            if (type === 'card') {
                // If the type of the item clicked is a card, call back to render a single card
                $scope.$emit('render-card', {
                    cardDefinitionId,
                    boardDefinitionName,
                    boardType,
                    cardDefinition
                });
            } else if (type === 'board') {
                // If the type of the item clicked is a board, call back to navigate to the new board
                const shortCode = `${_.kebabCase(boardType)}$${boardDefinitionName}`;
                $state.go('boards.board.panel', {
                    board: shortCode,
                    panel: _.get($scope.panel, 'name'),
                    overwriteReload: false,
                    boardDef: shortCode
                });
            }
        };

        $scope.menuAdd = function(payload) {
            //TODO: Update the payload to handle 'card' and 'board' types
            if (!$scope.$root.rightDrawerOpen) {
                $scope.$root.$emit('rightDrawer:open', {
                    layout: 'definitionCreator',
                    type: 'board',
                    definitionTypeString: 'Dashboard',
                    api: $scope.api,
                    user: $scope.user,
                    board: $scope.board,
                    definitionFilter: { 'board.boardType': $scope.board.type }
                });
            }
        };

        //Setup up data variables and callback functions
        $scope.data = {
            title: _.get($scope.board, 'definition.displayName'),
            innerNav: _.get($scope.board, 'properties.innerNav'),
            targetCardId: $scope.targetCardId,
            targetBoardName: $scope.targetBoardName,
            menuAdd: $scope.menuAdd,
            menuClick: $scope.menuClick
        };
    }
}
