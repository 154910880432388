import controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('panelSearch', function panelSearch() {
    var directive = {
        restrict: 'E',
        template,
        scope: {
            board: '=',
            panel: '=',
            user: '<',
            team: '<',
            facets: '=',
            hierarchy: '=?',
            search: '=?',
            card: '=?',
            cardNames: '=?',
            query: '=?',
            filters: '=?',
            filterData: '<',
            cardSearch: '=?',
            editableView: '=?',
            externalSearch: '=?',
            searchCallback: '&?',
            getCardCallback: '&?',
            refreshBoardCallback: '&?',
            pageData: '=?'
        },
        controller
    };

    return directive;
});
