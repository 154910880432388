(function() {
    'use strict';

    angular
        .module('sfPropertyHistory', ['schemaForm'])
        .config([
            '_',
            'schemaFormProvider',
            'schemaFormDecoratorsProvider',
            'sfBuilderProvider',
            'sfPathProvider',
            function(_, schemaFormProvider, schemaFormDecoratorsProvider, sfBuilderProvider, sfPathProvider) {
                const sfPropertyHistory = function(name, schema, options) {
                    if (_.get(schema, 'format.type') === 'property-history') {
                        const f = schemaFormProvider.stdFormObj(name, schema, options);
                        f.key = options.path;
                        f.type = 'property-history';
                        options.lookup[sfPathProvider.stringify(options.path)] = f;
                        return f;
                    }
                };

                if (_.isEmpty(schemaFormProvider.defaults.date)) {
                    schemaFormProvider.defaults.date = [];
                }

                schemaFormProvider.defaults.date.unshift(sfPropertyHistory);

                schemaFormDecoratorsProvider.defineAddOn(
                    'bootstrapDecorator',
                    'property-history',
                    'views/tmpl/templates/sfPropertyHistory.html',
                    sfBuilderProvider.stdBuilders
                );
            }
        ])
        .run([
            '$templateCache',
            function($templateCache) {
                $templateCache.put(
                    'views/tmpl/templates/sfPropertyHistory.html',
                    require('views/tmpl/templates/sfPropertyHistory.html')
                );
            }
        ]);
})();
