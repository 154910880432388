(function() {
    'use strict';

    angular.module('serviceApp').directive('cardIntegrationContentStats', cardIntegrationContentStats);

    function cardIntegrationContentStats() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '='
            },
            template: require('views/tmpl/cards/integrations/contentStats.html'),
            controller: CardIntegrationContentStatsController
        };

        return directive;
    }

    CardIntegrationContentStatsController.$inject = ['_', '$scope'];

    function CardIntegrationContentStatsController(_, $scope) {
        var stats = _.get($scope.card, 'properties.stats');
        generateContentByPolicyMetrics(stats);

        function generateContentByPolicyMetrics(stats) {
            var policies = _(stats.policies)
                .filter(function(item) {
                    return !_.isNil(item.contentCount);
                })
                .sortBy('contentCount')
                .reverse()
                .take(20)
                .value();
            $scope.chartDataViews = _.map(policies, 'contentCount');
            $scope.chartLabelsViews = _.map(policies, 'contentPolicy');
        }

        $scope.chartopts = {
            legend: {
                display: true,
                position: 'left'
            },
            responsive: true,
            maintainAspectRatio: false
        };
    }
})();
