'use strict';

/**
 * @ngdoc directive
 * @name serviceApp.directive:prettyprint
 * @description
 * # prettyprint
 */
/* jshint ignore:start */
angular.module('serviceApp').directive('prettyprint', function() {
    return {
        restrict: 'C',
        link: function postLink(scope, element) {
            element.html(prettyPrintOne(element.html(), '', true));
        }
    };
});
/* jshint ignore:end */
