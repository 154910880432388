(function() {
    'use strict';

    angular.module('serviceApp').filter('orderObjectBy', orderObjectBy);

    orderObjectBy.$inject = ['_', '$filter'];

    function orderObjectBy(_, $filter) {
        var filter = function(obj) {
            var returnObj = {};
            var keys = _.keys(obj);
            keys.sort();
            _.forEach(keys, function(key) {
                returnObj[key] = obj[key];
            });
            return returnObj;
        };
        return filter;
    }
})();
