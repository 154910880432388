(function() {
    'use strict';

    angular.module('serviceApp').directive('cardSetup', cardSetup);

    function cardSetup() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                team: '<',
                user: '<',
                action: '=',
                preview: '=',
                horizontal: '=',
                integrationData: '=',
                dismissIntegrationCallback: '&?',
                executeEventTrigger: '&',
                closeContextCallback: '&?',
                external: '=?',
                parent: '<'
            },
            template: require('views/tmpl/cards/setup/setupContainer.html'),
            controller: CardSetupController
        };

        return directive;
    }

    CardSetupController.$inject = [
        '_',
        '$scope',
        '$rootScope',
        '$state',
        '$window',
        'deviceDetector',
        'toastr',
        'userServiceNew'
    ];

    function CardSetupController(
        _,
        $scope,
        $rootScope,
        $state,
        $window,
        deviceDetector,
        toastr,
        userServiceNew
    ) {
        $scope.showSuccess = false;
        $scope.isMobile = $rootScope.isMobile;

        $scope.openAppSettings = function(app) {
            if (app.domain) {
                $state.go('boards.settings', {
                    board: app.domain,
                    panel: 'connections'
                });
            } else if (app.type == 'browser-extension') {
                openBX(app);
            } else if (app.type == 'desktop') {
                $window.open('https://s3.amazonaws.com/desktop.nexd/nexd-desktop-client_1.0.2.dmg', '_blank');
            } else if (app.state) {
                $state.go(app.state);
            }
        };

        $scope.dismissBXAction = function() {
            try {
                $scope.executeEventTrigger({
                    data: { eventTrigger: 'dismiss', action: $scope.action }
                });
            } catch (error) {
                // log the event and error
                console.error('Failed to dismiss browser extension action', { error, event: $event });
            }
        };

        function openBX(app) {
            $scope.device = deviceDetector;
            app.subTitle = 'Please refresh window after installing extension';
            if (deviceDetector.browser == 'chrome') {
                var newDataSources = _.get($scope, 'user.state.dataSources', []);
                newDataSources.push('browser-extension-installed');
                newDataSources = _.uniq(newDataSources);
                userServiceNew
                    .updateUser({
                        id: $scope.user.id,
                        state: {
                            dataSources: newDataSources
                        }
                    })
                    .then(
                        function(user) {
                            $window.open(
                                'https://chrome.google.com/webstore/detail/ibdfaablnmkacehnpimhockaommfgoaf'
                            );
                            $scope.showSuccess = true;
                        },
                        function(err) {
                            toastr.error('An error occurred - please try again', err);
                        }
                    );
            } else if (deviceDetector.browser == 'firefox') {
                InstallTrigger.install({
                    Olono: 'https://s3.amazonaws.com/browser.extension.nexd/releases/olono.xpi'
                });
            } else {
                toastr.error('Browser not supported, please use chrome or firefox', '');
            }
        }

        //TODO: this will be moved into the action in a later story
        $scope.titleTypes = [
            {
                prompt:
                    'Select your CRM provider and we will begin syncing your data to ' +
                    $rootScope.productName,
                type: 'setup-team-crm'
            },
            {
                prompt:
                    'Select your primary email provider and we will begin syncing your data to ' +
                    $rootScope.productName,
                type: 'setup-team-mail'
            },
            {
                prompt:
                    'Add ' +
                    $rootScope.productName +
                    ' to your current web browser, this will allow us to sort and detect important information',
                type: 'setup-browser-extension'
            }
        ];

        //TODO: this will be moved into the action in a later story
        $scope.integrationDefinitions = [
            {
                title: 'Google Mail',
                subTitle:
                    'Connect ' +
                    $rootScope.productName +
                    ' to your Google Mail to let us generate insights for your opportunities and contacts to move deals forward.  Always be engaged.',
                iconQueryUrl: 'gmail.com',
                domain: 'google.com',
                isSupported: true,
                type: 'google'
            },
            {
                title: 'Office 365 OAuth',
                subTitle:
                    'Connect ' +
                    $rootScope.productName +
                    ' to you Office 365 to let us generate insights for your opportunities and contacts to move deals forward.  Always be engaged.',
                iconQueryUrl: 'office365.com',
                domain: 'office365.com',
                isSupported: true,
                type: 'office365-oauth'
            },
            {
                title: 'Salesforce OAuth',
                subTitle:
                    'Connect ' +
                    $rootScope.productName +
                    ' to Salesforce and ' +
                    $rootScope.productName +
                    ' will automatically organize all your mail, meetings, and documents for each of your opportunities and contacts.',
                iconQueryUrl: 'salesforce.com',
                domain: 'salesforce.com',
                isSupported: true,
                type: 'salesforce-oauth'
            },
            {
                title: 'HubSpot',
                subTitle:
                    'Connect ' +
                    $rootScope.productName +
                    ' to HubSpot and ' +
                    $rootScope.productName +
                    ' will automatically organize all your mail, meetings, and documents for each of your opportunities and contacts.',
                iconQueryUrl: 'hubspot.com',
                domain: 'hubspot.com',
                isSupported: true,
                type: 'hubspot'
            },
            {
                title: 'Bullhorn',
                subTitle:
                    'Connect ' +
                    $rootScope.productName +
                    ' to Bullhorn and ' +
                    $rootScope.productName +
                    ' will automatically organize all your mail, meetings, and documents for each of your opportunities and contacts.',
                iconQueryUrl: 'bullhorn.com',
                domain: 'bullhornstaffing.com',
                isSupported: true,
                type: 'bullhorn'
            },
            {
                iconUrl: 'images/static/web_large.png',
                isSupported: true,
                type: 'browser-extension'
            },
            {
                title: 'Desktop App (Beta)',
                subTitle:
                    'Install the ' +
                    $rootScope.productName +
                    ' Desktop application to automatically organize presentations, proposals and other files related to opportunities.',
                iconUrl: 'images/static/desktop_large.png',
                isSupported: true,
                type: 'desktop'
            }
        ];

        $scope.$watchCollection('integrationData', function(oldVal, newVal) {
            if ($scope.apps && oldVal == newVal) {
                return;
            }

            if (_.get($scope.integrationData, 'action')) {
                const action = _.get($scope.integrationData, 'action');
                const actionType = _.find($scope.titleTypes, function(actionType) {
                    return actionType.type === action.name;
                });
                $scope.prompt = _.get(actionType, 'prompt');
                $scope.title = action.prompt;
            } else {
                $scope.title = $scope.integrationData.title;
                $scope.prompt = $scope.integrationData.prompt;
            }

            $scope.setUpTypes = $scope.integrationData.types
                ? $scope.integrationData.types
                : $scope.integrationData.type;

            $scope.setUpTypes = _.castArray($scope.setUpTypes);
            $scope.setUpTypes = _.compact($scope.setUpTypes);

            $scope.isBX = _.first($scope.setUpTypes) === 'browser-extension';

            if ($scope.isBX) {
                if (deviceDetector.browser !== 'chrome' && deviceDetector.browser !== 'firefox') {
                    $scope.unsupportedBrowser = true;
                    $scope.title =
                        'Unsupported Browser for the ' + $scope.$root.productName + ' Web Integration';
                    $scope.prompt = 'Your browser is currently unsupported - please try Chrome or Firefox';
                }
            }

            $scope.setUpTypes = _.map($scope.setUpTypes, function(setUpType) {
                return {
                    type: setUpType
                };
            });

            $scope.apps = _.intersectionBy($scope.integrationDefinitions, $scope.setUpTypes, 'type');
        });

        $scope.closeDrawer = function() {
            if ($scope.parent === 'leftDrawer') {
                $scope.$root.$emit('leftDrawer:close');
            } else {
                $scope.$root.$emit('rightDrawer:close');
            }
        };
    }
})();
