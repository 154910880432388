'use strict';

/**
 * Angular wrapper for our <GoalCardPillsExternalComponent /> react component.
 */
export default class GoalCardPills {
    static get $inject() {
        return ['$scope', '_'];
    }

    constructor($scope, _) {
        this.$scope = $scope;
        this._ = _;
    }

    $onInit() {
        this.data = {
            card: this.card
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'card') && !changes.card.isFirstChange()) {
            this.card = changes.card.currentValue;
            this.data = { ...this.data, card: this.card };
        }
    }
}
