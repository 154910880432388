'use strict';
import { getTransformedQuery } from '../../scripts/utils/query-utils';

export default class Controller {
    static get $inject() {
        return [
            '_',
            '$scope',
            'cardsUtilService',
            'definitionService',
            'toastr',
            'activeViewService',
            'boardSliceService',
            'appSettingsService'
        ];
    }

    constructor(
        _,
        $scope,
        cardsUtilService,
        definitionService,
        toastr,
        activeViewService,
        boardSliceService
    ) {
        activeViewService.setIsInspectionView(true);

        $scope.panel = $scope.panel || {
            background: 'dark',
            userThemeEnabled: $scope.userTheme === true
        };

        $scope.boardSliceService = boardSliceService;

        $scope.loading = true;
        $scope.loadingTable = false;
        $scope.closeDetails = function() {
            if (!_.isEmpty($scope.definition)) {
                $scope.isDirty = _.get($scope.boardSliceService, 'definition.isDirty');
                $scope.boardSliceService.definitionClear(true, true);
            }
            // closing the drawer is handled by the refresh method
            if ($scope.api && $scope.api.refresh) {
                //discard any changes made in inspection view
                if ($scope.isDirty || $scope.isDirty === undefined) {
                    return $scope.api.refresh({ cardDef: $scope.cardDefinition });
                } else {
                    return $scope.api.refresh();
                }
            }

            $scope.$emit('rightDrawer:close');
        };

        $scope.query = { label: $scope.card.name };

        let loadData = () => {
            $scope.loadingTable = true;
            // If we haven't got a definition in our scope yet we're still bootstrapping, so
            // don't issue any queries
            if (!$scope.definition) {
                return;
            }

            // If the definition has a sort order, and we don't have a sort facet already, set the sort facet
            // to the order specified in the definition.
            const sortOrder = _.get($scope.definition, 'propertySchema.filter._display.sort');
            const sortFacet = _.get($scope, `facets.card.${$scope.card.name}.sort`);
            if (sortOrder && !sortFacet) {
                _.set($scope, `facets.card.${$scope.card.name}.sort`, sortOrder);
            }

            // Setting `view: inspect` changes the card response in the following key ways:
            // 1. The filter persisted in the definition is no longer applied, instead the filter
            //    passed in this API call is used. This is to allow live-editing of the filter.
            // 2. The list of matching content is included in the properties.  This isn't included
            //    in the default dashboard response to save time and data.
            //Load the facet data into a local facets that will not bleed back into the dashboard view
            $scope.inspectionFacets = _.cloneDeep($scope.facets) || {};
            _.set($scope.inspectionFacets, 'card.view', 'inspect');

            const transformedQuery = getTransformedQuery($scope.query);

            const opts = {
                boardId: $scope.board.id,
                boardDefinitionName: $scope.board.definitionName,
                panelName: $scope.panel?.name || 'main',
                cardName: $scope.card.name,
                query: transformedQuery,
                facets: $scope.inspectionFacets,
                filter: definitionService.getFilter($scope.definition),
                boardDefinition: _.get($scope.boardSliceService, 'boardDefinition'),
                hierarchy: $scope.hierarchy
            };

            return cardsUtilService
                .getCard(opts)
                .then(card => {
                    $scope.card = card;
                    $scope.supportsCompareMode = _.get(card, 'config.supportsCompareMode');
                    $scope.compareButtonData = { cardName: card.name };
                    $scope.loading = false;
                    $scope.loadingTable = false;
                })
                .catch(err => {
                    toastr.error('An error occurred while loading data', err);
                });
        };

        let facetWatcher;
        let boardDefinitionWatcher;

        // This watching basically bootstraps the whole process, and ensures that if the underlying
        // card is changed to a new one we wipe out state and re-initialize.
        let initWatcher = $scope.$watch('card.name', () => {
            if ($scope.definition) {
                $scope.loading = false;
                return;
            }

            // Check to see if this a goal card
            $scope.goalCard = !!_.find(_.get($scope.card, 'config.overridableVizConfig'), {
                name: 'propertyValues.goalConfiguration'
            });
            $scope.definition = undefined;

            // Get the column set override for this card, if any.
            const columnSet = _.get($scope.boardSliceService.getCardConfig($scope.card.name), 'columnSet');

            // Load the definition for this card
            definitionService
                .getDefinitionById($scope.card.definitionId)
                .then(definitionItem =>
                    // we need to resolve this definition before passing it in
                    definitionService.resolve({
                        definition: definitionItem,
                        options: { skipAvailableProperties: false, includeGoalFilters: true },
                        columnSet
                    })
                )
                .then(newDefinition => {
                    $scope.definition = newDefinition;
                    _.set($scope.definition, 'isResolved', true);
                    // Set the selection definition in the board store slice, and update available properties.
                    $scope.boardSliceService.definitionAdd(
                        $scope.definition,
                        false,
                        false,
                        true,
                        // Set the current card name in the store, so that components can tell when
                        // we're inspecting a specific card and act accordingly.
                        $scope.card.name
                    );

                    // Get the sort from the card definition in the board slice (this will have been updated
                    // with any card overrides when we did `definitionAdd()` above).
                    const sortObj = _.get(
                        $scope.boardSliceService,
                        'definition.propertySchema.filter._display.sort'
                    );

                    // Update sort facets and let the facet listener load data
                    $scope.facets = $scope.facets || {};
                    _.set($scope, `facets.card.${$scope.card.name}.sort`, sortObj);
                    _.set($scope, `facets.card.${$scope.card.name}.offset`, 0);

                    // Load the data, then initialize facet & boardDef watchers to prevent duplicate loads
                    loadData().then(() => {
                        facetWatcher = $scope.$watch(
                            'facets',
                            (newVal, oldVal) => {
                                if (_.isEqual(newVal, oldVal)) {
                                    return;
                                }
                                // Don't load data when the sort facet changes, since that config
                                // is merged into the stored definition and will trigger the
                                // board definition watcher, which then reloads the data.
                                if (
                                    !_.isEqual(
                                        _.get(newVal, `card.${$scope.card.name}.sort`),
                                        _.get(oldVal, `card.${$scope.card.name}.sort`)
                                    )
                                ) {
                                    return;
                                }
                                // If any other facet (like offset) changed, reload the data.
                                loadData();
                            },
                            true
                        );

                        // When the board definition changes (due to changes in card filter overrides
                        // or sorting) update the scope and reload card data.
                        // @todo -- this triggers in many situations that are unnecessary, like
                        //          adding a column.  Look into refactoring this to be more like
                        //          report view.
                        boardDefinitionWatcher = $scope.$watch(
                            'boardSliceService.boardDefinition',
                            (newVal, oldVal) => {
                                if (!_.isEmpty(newVal)) {
                                    $scope.boardDefinition = newVal;
                                }
                                if (!_.isEmpty(oldVal) && !_.isEmpty(newVal) && newVal !== oldVal) {
                                    // We will have merged the sort override into the board definition,
                                    // since that's what the content list component looks at to visualize
                                    // the sort arrow in column headers.
                                    const sortObj = _.get(
                                        $scope.boardSliceService,
                                        'definition.propertySchema.filter._display.sort'
                                    );
                                    // Compare the current sort facet to the new sort.
                                    // If they're different, update the facet so that we sort correctly when
                                    // loading data.
                                    // Sort can change due to clicking a column header or applying a column set.
                                    const sortFacet = _.get($scope, `facets.card.${$scope.card.name}.sort`);
                                    if (!_.isEqual(sortObj, sortFacet)) {
                                        $scope.facets = $scope.facets || {};
                                        _.set($scope, `facets.card.${$scope.card.name}.sort`, sortObj);
                                        _.set($scope, `facets.card.${$scope.card.name}.offset`, 0);
                                    }
                                    loadData();
                                }
                            }
                        );
                    });
                })
                .catch(function(error) {
                    console.error(error);
                });
        });

        let queryWatcher = $scope.$watchGroup(
            ['query.str'],
            (newVal, oldVal) => {
                if (_.isEqual(newVal, oldVal)) {
                    return;
                }
                loadData();
            },
            true
        );

        $scope.$watch(
            'hierarchy',
            (newVal, oldVal) => {
                if (_.isEqual(newVal, oldVal)) {
                    return;
                }
                loadData();
            },
            true
        );

        let searchEventListener = $scope.$on('updateSearch', (event, search) => {
            event.stopPropagation();
            $scope.query = _.get(search, 'query', $scope.query);
            $scope.facets = _.get(search, 'facets', $scope.facets);
            $scope.hierarchy = _.get(search, 'hierarchy', $scope.hierarchy);
            $scope.$apply();
        });

        let facetEventListener = $scope.$on('updateFacet', (event, newFacets) => {
            $scope.facets = newFacets;
        });

        // reload data after saving forecast from external drawer
        let forecastDrawerSaveListener = $scope.$root.$on('savedForecastData', event => {
            event.stopPropagation();
            loadData();
            this.scope.$apply();
        });

        // Watch for changed to the filter definition
        $scope.$watch('boardSliceService.definition', def => {
            if (def) {
                $scope.filterData = {
                    definition: def,
                    isRemovable: !def ? true : _.get($scope, 'boardSliceService.definitionIsRemovable'),
                    indicators: _.get($scope, 'boardSliceService.definitionColumns')
                };
            }
        });

        $scope.$on('$destroy', () => {
            searchEventListener();
            facetEventListener();
            forecastDrawerSaveListener();
            initWatcher();
            queryWatcher();
            facetWatcher();
            boardDefinitionWatcher();

            activeViewService.setIsInspectionView(false);
        });

        /**
         * Updates the given card within the current board + panel to
         * reflect the given facets. The scope's array of cards is updated
         * with the response.
         *
         * @param data.facets Merge these facets into the scope before update
         * @param data.query Merge this query into the scope before update
         * @param data.cardName The card name to retrieve
         */
        $scope.getCard = data => {
            $scope.facets = _.merge($scope.facets, data.facets);
            $scope.query = _.merge($scope.query, data.query);
            if (_.get(data, 'cardDef')) {
                $scope.definition = _.get(data, 'cardDef');
                _.set($scope.definition, 'isDirty', true);
                loadData();
            }
        };

        /**
         * Updates the current board+panel to reflect the given facets.
         *
         * This method updates the global $state, and the data is refreshed
         * based on that update.
         *
         * @param data.facets Merge these facets into the scope before update
         * @param data.query Merge this query into the scope before update
         */
        $scope.getCards = data => {
            $scope.facets = _.merge($scope.facets, data.facets);
            $scope.query = _.merge($scope.query, data.query);
        };

        $scope.buildDownloadUrl = data => {
            if ($scope.buildDownloadUrlCallback) {
                return $scope.buildDownloadUrlCallback({ data });
            }

            const opts = {
                boardId: $scope.board.id,
                boardDefinitionName: $scope.board.definitionName,
                panelName: 'main',
                query: getTransformedQuery($scope.query),
                facets: $scope.inspectionFacets,
                boardDefinition: _.get($scope.boardSliceService, 'boardDefinition'),
                hierarchy: $scope.hierarchy,
                cardName: data.cardName,
                filter: data.filter || definitionService.getFilter($scope.definition),
                fields: data.fields || []
            };
            return cardsUtilService.buildDownloadUrl(opts);
        };
    }
}
