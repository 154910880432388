'use strict';
import _ from 'lodash';

/**
 * @typedef suppliedScopeProperties
 * @property {Object} definition - definition of the action
 * @typedef {ng.IScope & suppliedScopeProperties} $scope - input scope param
 *
 * @typedef _actionSearchBaseScope
 * @property {string} prompt - cleaned version of action prompt
 * @property {string} query - user input query string
 * @property {string} queryState - indicates which "facet" of actions to be selected (i.e. All, Completed, Dismissed)
 * @property {Array} actions - array of actions found in search results
 * @property {function} openMenu - function to be called by the drop-down provider
 * @property {function} searchActions - function that calls search actions API and set scope.actions
 * @property {function} toggleContext - function that calls the action drawer to be opened
 * @typedef {$scope & _actionSearchBaseScope} actionSearchBaseScope
 */

export default class ActionSearch {
    static get $inject() {
        return ['$scope', '$rootScope', 'actionService', 'toastr', 'contentLaunchService'];
    }

    /**
     * ActionSearch
     * @param {$scope} scope - angular scope object
     * @param {Object} actionService
     */
    constructor(scope, rootScope, actionService, toastr, contentLaunchService) {
        /** @type {actionSearchBaseScope} */
        // @ts-ignore ignoring so type cast doesn't yell about missing props
        this.scope = scope;
        this.rootScope = rootScope;
        this.actionService = actionService;
        this.toastr = toastr;
        this.contentLaunchService = contentLaunchService;

        this.scope.query = '';
        this.scope.queryState = this.scope.defaultQueryState || 'All';
        this.scope.openMenu = this.openMenu.bind(this);
        this.scope.searchActions = this.searchActions.bind(this);
        this.scope.toggleContext = this.toggleContext.bind(this);
        this.scope.updateFilterState = this.updateFilterState.bind(this);
        this.scope.executeEventTrigger = this.executeEventTrigger.bind(this);
        this.scope.removeCompletedAction = this.removeCompletedAction.bind(this);

        this.scope.updateFilterState();
        this.defaultFilter = _.cloneDeep(this.scope.defaultFilter);
        this.options = _.cloneDeep(this.scope.options);
        this.scope.searchActions();

        this.scope.isAutoRunAction = this.isAutoRunAction();

        const defaultFilterWatcher = this.scope.$watch('defaultFilter', () => {
            this.defaultFilter = _.cloneDeep(this.scope.defaultFilter);
            this.scope.searchActions();
        });

        this.scope.$on('$destroy', () => {
            defaultFilterWatcher();
        });
    }

    /**
     * toggleContext - open or close the action details drawer
     * @param {Object} args
     * @param {Object} args.action
     * @param {Object} args.actionId
     * @param {Object} args.definition
     */
    toggleContext({ action, actionId, definition }) {
        this.scope.$root.$emit('leftDrawer:open', {
            layout: 'actionDetails',
            selectedAction: action,
            external: this.rootScope.isExternal,
            api: {
                executeEventTrigger: this.scope.executeEventTrigger
            }
        });
    }

    updateFilterState() {
        const states = {
            All: undefined,
            Active: { state: 'active' },
            Completed: { state: 'complete' },
            Dismissed: { state: 'dismiss' },
            Expired: { state: 'expired' },
            'Run Error': { lastRunError: '##EXISTS##' }
        };
        if (this.scope.isAutoRunAction) {
            states['Run Error'] = { lastAutoRunSetupErrors: '##EXISTS##' };
        }
        this.scope.filterState = _.get(states, [this.scope.queryState]);
    }

    /**
     * Run action search API for given filter and set scope to the actions.
     *
     * @param {Object} filter - pliable filter to be ran in action search API
     */
    searchActions(value) {
        this.actionService
            .searchActions({
                filter: {
                    '##AND##': [
                        this.defaultFilter,
                        this.scope.filterState,
                        { '##OR##': [{ closedBySystem: false }, { closedBySystem: '##MISSING##' }] }
                    ].filter(Boolean)
                },
                options: {
                    size: 75,
                    query: value || this.scope.query,
                    groupBy: 'none'
                }
            })
            .then(({ results }) => {
                this.scope.actions = results;

                // alert listeners that the filter OR query has changed
                this.scope.filterData = {
                    defaultFilterExtensions: this.scope.filterState,
                    query: this.scope.query
                };
            });
    }

    /**
     * executeEventTrigger - run a given event trigger
     * @param {Object} data - args object
     * @param {String} data.eventTrigger - event trigger type to run.  dismiss or dismiss-all
     * @param {Object} data.action - action the event trigger is running on
     */
    executeEventTrigger(data) {
        const {
            action,
            eventTrigger,
            payload = {
                snoozeUntil: _.get(data, 'snoozeUntil')
            }
        } = data;

        // launches URL based action event triggers if applicable
        this.contentLaunchService.launchActionEventTrigger(this.scope.user, action, eventTrigger);

        this.actionService
            .actionEvent(action, eventTrigger, payload)
            .then(result => {
                const newAction = _.get(result, 'action', {});
                const actionIndex = _.findIndex(this.scope.actions, { id: action.id });

                if (newAction.state === 'active' || newAction.state === 'pending') {
                    _.set(this.scope.actions, actionIndex, newAction);
                } else {
                    _.pullAt(this.scope.actions, actionIndex);
                    this.toastr.success('Action updated');
                }

                // close the open drawer
                this.scope.$root.$emit('leftDrawer:close');
            })
            .catch(err => {
                this.toastr.error('An error occurred running action.', _.get(err, 'message', err));
            });
    }

    /**
     * openMenu
     * @return {Array} array of drop down menu items
     */
    openMenu() {
        return [
            {
                text: 'All',
                click: () => {
                    this.scope.queryState = 'All';
                    this.scope.updateFilterState();
                    this.scope.searchActions();
                }
            },
            {
                text: 'Active',
                click: () => {
                    this.scope.queryState = 'Active';
                    this.scope.updateFilterState();
                    this.scope.searchActions();
                }
            },
            {
                text: 'Completed',
                click: () => {
                    this.scope.queryState = 'Completed';
                    this.scope.updateFilterState();
                    this.scope.searchActions();
                }
            },
            {
                text: 'Dismissed',
                click: () => {
                    this.scope.queryState = 'Dismissed';
                    this.scope.updateFilterState();
                    this.scope.searchActions();
                }
            },
            {
                text: 'Expired',
                click: () => {
                    this.scope.queryState = 'Expired';
                    this.scope.updateFilterState();
                    this.scope.searchActions();
                }
            },
            {
                text: 'Run Error',
                click: () => {
                    this.scope.queryState = 'Run Error';
                    this.scope.updateFilterState();
                    this.scope.searchActions();
                }
            }
        ];
    }

    /**
     * removeCompletedAction - remove a given action from the results list.  This function is called by the action item
     * @param {Object} data - data indicating what action to remove from the list
     * @param {String} data.actionId - id of the action to remove from the list
     */
    removeCompletedAction(data) {
        const actionId = _.get(data, 'actionId');
        const actionIndex = _.findIndex(this.scope.actions, { id: actionId });
        if (actionIndex > -1) {
            _.pullAt(this.scope.actions, actionIndex);
        }
    }

    /**
     * isAutoRunAction - return if the action is an auto run action
     * @returns {boolean}
     */
    isAutoRunAction() {
        const eventTrigger = _.get(this.scope.definition, 'eventTrigger');
        if (!eventTrigger) {
            return false;
        }
        const autoRun = _.get(eventTrigger, 'autoRun', []);
        return !_.isEmpty(autoRun);
    }
}
