import _ from 'lodash';

export default class Controller {
    static get $inject() {
        return [
            '$scope',
            '$window',
            'teamServiceNew',
            'contentLaunchService',
            'actionService',
            'moment',
            '$timeout',
            'cardsUtilService',
            'toastr'
        ];
    }
    constructor(
        $scope,
        $window,
        teamServiceNew,
        contentLaunchService,
        actionService,
        $moment,
        $timeout,
        cardsUtilService,
        toastr
    ) {
        this.$scope = $scope;
        this.$window = $window;
        this.teamServiceNew = teamServiceNew;
        this.contentLaunchService = contentLaunchService;
        this.actionService = actionService;
        this.$moment = $moment;
        this.$timeout = $timeout;
        this.toastr = toastr;

        Object.assign(this.$scope, {
            canGoLeft: false,
            canGoRight: false,
            drawerOpened: false,
            scrollOffset: 0,
            items: [],
            visibleIndicies: [],

            carouselBack: this.moveLeft.bind(this),
            carouselNext: this.moveRight.bind(this),
            carouselToItem: this.moveTo.bind(this),
            toggleContext: this.toggleContext.bind(this),
            executeEventTrigger: this.executeEventTrigger.bind(this),
            removeCompletedAction: this.removeCompletedAction.bind(this)
        });

        let rightDrawerListener = this.$scope.$root.$on('rightDrawer:close', () => {
            this.$scope.drawerOpened = false;
            this.$scope.activeId = undefined;
            this.$scope.selectedAction = undefined;
        });

        this.$scope.$on('$destroy', () => {
            this.$window.removeEventListener('resize', this._reflow.bind(this));
            rightDrawerListener();
        });

        // When we get a notification that this card should be updated, ingest the new data.
        if (_.get($scope, 'card.socketEventNames.refresh_finished')) {
            $scope.$on($scope.card.socketEventNames.refresh_finished, function(event, payload) {
                cardsUtilService
                    .getCard({
                        boardId: $scope.board.id,
                        boardDefinitionName: $scope.board.definitionName,
                        panelName: $scope.panel.name,
                        cardName: $scope.card.name,
                        query: $scope.query,
                        facets: $scope.facets
                    })
                    .then(data => {
                        payload.data = data;
                        // Update the card data.
                        actionService.updateCardAfterRefreshEvent($scope, payload);
                    });
            });
        }
    }

    static get DIRECTION_LEFT() {
        return 'left';
    }

    static get DIRECTION_RIGHT() {
        return 'right';
    }

    initialize(elem) {
        this._el = elem;
        this._stage = this._el.querySelector('.carousel-stage');
        this._viewport = this._el.querySelector('.carousel-viewport');
        this._items = [...this._stage.children];

        this._viewed = 0;

        // respond to changes on children
        const onChildrenItemsMutated = _.throttle(() => {
            this.$scope.$apply(() => {
                this._items = [...this._stage.children];
                this._reflow();
            });
        }, 200);
        new MutationObserver(onChildrenItemsMutated).observe(this._stage, {
            childList: true,
            subtree: true
        });

        // respond to changes in window size
        this.$window.addEventListener('resize', this._reflow.bind(this));

        // calculate initial state
        this._reflow();
    }

    moveLeft() {
        this._move(Controller.DIRECTION_LEFT);
    }

    moveRight() {
        this._move(Controller.DIRECTION_RIGHT);
    }

    moveTo(idx) {
        let scrollOffset;
        const buffer = 10;
        const offsetRight = this._viewport.offsetLeft + this._viewport.offsetWidth;

        if (idx < this._items.length && this._items[idx].offsetLeft < this._viewport.offsetLeft) {
            scrollOffset = Math.abs(this._items[idx].offsetLeft) + buffer + this._viewport.offsetLeft;
        } else if (
            idx < this._items.length &&
            this._items[idx].offsetLeft + this._items[idx].offsetWidth > offsetRight
        ) {
            const indOffsetRight = this._items[idx].offsetLeft + this._items[idx].offsetWidth;
            scrollOffset = offsetRight - indOffsetRight - buffer;
        } else {
            scrollOffset = 0;
        }

        // ensure we don't over-scroll such that there is empty space on the right
        if (scrollOffset + this._viewport.offsetWidth > this._stage.scrollWidth) {
            scrollOffset = this._stage.scrollWidth - this._viewport.offsetWidth;
        }

        this.$scope.scrollOffset = scrollOffset;
        this._viewed = idx;
        this._calculateState();
    }

    _openDetailsDrawer() {
        this.$scope.$root.$emit('rightDrawer:open', {
            layout: 'actionDetails',
            board: this.$scope.board,
            user: this.$scope.user,
            team: this.$scope.team,
            card: this.$scope.card,
            selectedAction: this.$scope.selectedAction,
            api: this.$scope.api
        });
        this.$scope.drawerOpened = true;
    }

    _closeDetailsDrawer() {
        this.$scope.$root.$emit('rightDrawer:close');
        this.$scope.drawerOpened = false;
    }

    toggleContext(id, action) {
        if (this.$scope.drawerOpened && this.$scope.activeId === id) {
            this._closeDetailsDrawer();
        } else {
            this.$scope.activeId = id;
            this.$scope.selectedAction = action;
            this._openDetailsDrawer();
        }
    }

    _isActive(id) {
        return this.$scope.activeId === id;
    }

    _pullAction(actionIndex) {
        _.pullAt(this.$scope.card.properties.actions, actionIndex);
        this._closeDetailsDrawer();
        this.$scope.hasActions = !_.isEmpty(this.$scope.card.properties.actions);
    }

    _getUserAssignee(action) {
        let lowerUserEmail = _.toLower(_.get(this.$scope, 'user.email'));
        return _.find(_.get(action, 'assignees', []), {
            email: lowerUserEmail
        });
    }

    executeEventTrigger(data) {
        let eventTrigger = _.get(data, 'eventTrigger');
        let eventTriggerAction = _.get(data, 'action');

        const payload = _.get(data, 'payload', {
            snoozeUntil: _.get(data, 'snoozeUntil')
        });

        this.contentLaunchService.launchActionEventTrigger(
            this.$scope.user,
            eventTriggerAction,
            eventTrigger
        );

        this.actionService.actionEvent(eventTriggerAction, eventTrigger, payload).then(
            result => {
                this.$scope.$root.$emit('rightDrawer:close');

                let action = _.get(result, 'action');

                if (action) {
                    let assignee = this._getUserAssignee(action);
                    let assigneeState = _.get(assignee, 'currentState');
                    let assigneeSnooze = _.get(assignee, 'snooze');
                    let currentlySnoozed = this.$moment(assigneeSnooze).isAfter(this.$moment());

                    let originalAssignee = this._getUserAssignee(eventTriggerAction);
                    let originalAssigneeSnooze = _.get(originalAssignee, 'snooze');
                    let wasSnoozed = this.$moment(originalAssigneeSnooze).isAfter(this.$moment());
                    //this logic could cause issues when team owners can override action state
                    if (
                        assigneeState !== 'active' ||
                        action.state !== 'active' ||
                        (currentlySnoozed && !wasSnoozed)
                    ) {
                        let actionIndex = _.findIndex(this.$scope.card.properties.actions, {
                            id: action.id
                        });
                        if (eventTrigger.type == 'run') {
                            _.set(
                                this.$scope.card.properties.actions[actionIndex],
                                'state',
                                _.get(action, 'state')
                            );
                        } else {
                            this._pullAction(actionIndex);
                        }
                    }
                }
            },
            error => {
                this.toastr.error('An error occurred - please try again');
            }
        );
    }

    removeCompletedAction(data) {
        let actionId = _.get(data, 'actionId');
        let actionIndex = _.findIndex(this.$scope.card.properties.actions, {
            id: actionId
        });
        this._pullAction(actionIndex);
    }

    get _viewportOffset() {
        const viewportStart = this._viewport.offsetLeft;
        return { start: viewportStart, end: viewportStart + this._viewport.offsetWidth };
    }

    _numItemsToScrollBy(numItemsVisible) {
        return numItemsVisible - Math.max(0, Math.ceil(numItemsVisible / 2) - 1);
    }

    _reflow() {
        this._reflow_throttle = this._reflow_throttle || _.throttle(this._move.bind(this), 200);
        this._reflow_throttle();
    }

    _move(direction) {
        let targetIdx; // this is the index of the item we want to be left most visible
        switch (direction) {
            case Controller.DIRECTION_LEFT:
                targetIdx = _.clamp(this._getMinViewed() - 1, 0, this._items.length - 1);
                break;
            case Controller.DIRECTION_RIGHT:
                targetIdx = _.clamp(this._getMaxViewed() + 1, 0, this._items.length - 1);
                break;
            default:
                //On a reflow, taget the current index. so the view doesn't shift left or right
                targetIdx = this.$scope.currentIdx ? this.$scope.currentIdx : 0;
        }
        this.$scope.currentIdx = targetIdx;
        this.moveTo(targetIdx);
    }

    _getMaxViewed() {
        const viewportOffset = this._viewportOffset;
        const scrolledOffset = this.$scope ? this.$scope.scrollOffset : 0;
        let max;
        _.forEach(this._items, function(item, idx) {
            if (item.offsetLeft + item.offsetWidth + scrolledOffset < viewportOffset.end) {
                max = idx;
            }
        });
        return _.gte(max, 0) ? max : this._items.length;
    }

    _getMinViewed() {
        const viewportOffset = this._viewportOffset;
        const scrolledOffset = this.$scope ? this.$scope.scrollOffset : 0;
        let min;
        _.forEach(this._items, function(item, idx) {
            if (item.offsetLeft + scrolledOffset < viewportOffset.start) {
                min = idx;
            }
        });
        return min || 0;
    }

    _calculateState() {
        this.$scope = Object.assign(this.$scope, {
            canGoLeft: this._viewed > 0,
            canGoRight: this._viewed < this._items.length - 1,
            items: this._items
        });
    }
}
