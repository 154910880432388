'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('expandableList', {
    controller,
    template,
    bindings: {
        expandableLabel: '@',
        emptyStateLabel: '@',
        nestedPropertyName: '@',
        board: '<',
        columns: '<',
        records: '<',
        onRowStateChanged: '&',
        hierarchy: '<'
    }
});
