'use strict';

/**
 * Angular wrapper for our <RegisterBoardSliceExternalComponent /> react component.
 */
export default class RegisterBoardSlice {
    static get $inject() {
        return ['boardSliceService'];
    }

    constructor(boardSliceService) {
        this.boardSliceService = boardSliceService;
    }

    $onInit() {
        // Props being passed to our react component
        this.data = {
            type: this.type,
            onSliceLoaded: storeArgs => this.boardSliceService.loadSlice(storeArgs),
            onSliceRemoved: () => this.boardSliceService.sliceRemoved()
        };
    }
}
