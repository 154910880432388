import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('retailPreviewTable', () => ({
    restrict: 'E',
    scope: {
        data: '<'
    },
    template
}));
