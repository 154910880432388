'use strict';

/**
 * Content Popover is the popover content data for content icon hovers
 *
 * @export
 * @class Controller
 */

export default class Controller {
    static get $inject() {
        return ['$scope'];
    }
    constructor(scope) {
        const filterPath = scope.propertyPath;
        const initializedFilter = _.cloneDeep(scope.filterData.initializedFilter);

        scope.formSchema = {
            ...initializedFilter.formSchema,
            properties: {
                [filterPath]: {
                    // default to visible if no value present
                    hidden: 'visible',
                    ...initializedFilter.formSchema.properties[filterPath]
                },
                _display: {
                    // default to NOT hidden if no value present
                    [filterPath]: { hidden: false },
                    ...initializedFilter.formSchema.properties['_display']
                }
            }
        };
        scope.formModel = {
            [filterPath]: initializedFilter.formModel[filterPath]
        };

        const toggleFilterPropertyVisibility = function(path) {
            const idxInPool = scope.filterData.indicators.findIndex(entry => entry.path === path);
            scope.$emit('filter:togglePropertyVisibility', { path, idxInPool });
        };
        scope.$on('editor:setPropertyHidden', (event, { path, hidden }) => {
            scope.filterPropertyVisibilityPath = path;
            scope.filterPropertyVisibility = hidden;
        });

        scope.close = () => {
            scope.isDirty = false;
            scope.$emit('pill-popover:close');
        };

        scope.applyChanges = () => {
            if (scope.filterPropertyVisibility) {
                toggleFilterPropertyVisibility(scope.filterPropertyVisibilityPath);
            }
            if (scope.isDirty) {
                scope.$emit('filter:updatedFilterProperty', {
                    formModel: scope.formModel,
                    formSchema: scope.formSchema
                });
                scope.isDirty = false;
            }
            scope.$emit('pill-popover:close');
        };

        const formModelWatcher = scope.$watch(
            'formModel',
            (newValue, oldValue) => {
                if (!_.isEqual(newValue, oldValue)) {
                    scope.isDirty = true;
                    scope.$emit('pill-popover:edited');
                }
            },
            true
        );

        scope.$on('$destroy', () => {
            formModelWatcher();
        });
    }
}
