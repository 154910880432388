'use strict';

import _ from 'lodash';

/**
 * Time Popover is the popover used to set the time input values
 *
 * @export
 * @class Controller
 */

export default class Controller {
    static get $inject() {
        return ['$scope', '$templateCache'];
    }
    constructor(scope, $templateCache) {
        //Put the time input widget into the template for the popover to use and compile
        $templateCache.put(
            'template/popover/timePopoverTemplate.html',
            require('./timePopoverTemplate.html')
        );
        scope.template = 'template/popover/timePopoverTemplate.html';

        $templateCache.put('template/popover/timePicker.html', require('./timePicker.html'));
        scope.show = scope.showPopover;

        let showWatcher = scope.$watch('showPopover', function(showThis) {
            scope.show = showThis;
        });

        scope.$on('$destroy', function() {
            showWatcher();
        });

        // supply options to the time picker
        scope.timeOptions = {
            'ng-model': scope.model
        };

        /**
         * This closes the popover when focus lost on mouse out off the popover
         */
        scope.closePopover = () => {
            scope.show = false;
        };
    }
}
