'use strict';
import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('formNumber', () => ({
    restrict: 'A',
    scope: {
        formModel: '=formNumber',
        form: '='
    },
    template,
    controller: Controller
}));
