import _ from 'lodash';

export const validDateFormats = [
    'MM/DD/YYYY',
    'YYYY-MM-DD',
    'MM/DD/YY',
    'YYY-MM-DD',
    'YYYY-MM-DDTHH:mm:ssZ',
    'YYYY-MM-DDTHH:mm:ss.SSSZ',
    'YYYY-MM-DDTHH:mm:ss.SSS',
    'YYYY-MM-DDTHH:mm:ss',
    'YYYYMMDDTHHmmssZ',
    'YYYYMMDDTHHmmss',
    'YYYY-MM-DDTHH:mm:ss.SSSSSSSZ',
    'YYYY-MM-DDTHH:mm:ss.SSSSSSS',
    'YYYY-MM-DD HH:mm:SS.SSS',
    'x'
];

export const getPropertyDisplayOptions = (board, contentType) =>
    _.get(board, `contentDisplayOptions[${contentType}].propertyDisplayOptions`);

export const isValid = (contentType, propertyDisplayOptions, value, key) => {
    if (key) {
        const fieldName = contentType + '.' + key;
        const field = _.get(propertyDisplayOptions, fieldName);
        if (field) {
            if (_.get(field, 'hidden')) {
                return false;
            }
        }
    }
    return angular.isDefined(value) && value !== null;
};

export const getPlaceholder = key => _.last(_.words(key));

export const isHidden = (contentType, propertyDisplayOptions, value) => {
    const fieldName = contentType + '.' + value;
    const field = _.get(propertyDisplayOptions, fieldName);
    if (field) {
        return _.get(field, 'hidden');
    }
    return true;
};

export const getFormat = (contentType, propertyDisplayOptions, value) => {
    const fieldName = contentType + '.' + value;
    const field = _.get(propertyDisplayOptions, fieldName);
    return _.get(field, 'format');
};

export const isEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const getContentField = (item, asArray, displayOptions, content, $interpolate) => {
    if (!item) {
        return item;
    }

    var result;

    if (_.isNil(asArray)) {
        asArray = item.type == 'array';
    }

    if (_.isArray(item.value)) {
        result = _.map(item.value, function(path) {
            if (isPathHidden(path, displayOptions)) {
                return;
            }
            if (_.isObject(path)) {
                path = _.get(path, 'path');
                var def = _.get(path, 'default');
                var itemToReturn = getItem(asArray, path, content, $interpolate);

                if (_.isEmpty(itemToReturn) && def) {
                    itemToReturn = getItem(false, def, content, $interpolate);
                }

                return itemToReturn;
            }

            return getItem(asArray, path, content, $interpolate);
        });

        result = _.join(result, ',');
        result = _.split(result, ',');
        result = _.compact(result);
        result = _.uniq(result);
    } else if (_.isObject(item.value)) {
        var path = _.get(item, 'value.path');
        if (isPathHidden(path, displayOptions)) {
            return;
        }
        var def = _.get(item, 'value.default');
        result = getItem(asArray, path, content);
        if (_.isEmpty(result) && def) {
            result = getItem(false, def, content);
        }
    } else {
        result = getItem(asArray, item.value, content, $interpolate);
        if (asArray) {
            result = _.compact(result);
        }
    }

    if (_.isEmpty(result) && item.default) {
        result = getItem(false, item.default, content, $interpolate);
    }

    return result;
};

export const isEmpty = obj => {
    return _.isEmpty(obj);
};

export const isObject = obj => {
    return _.isObject(obj);
};

export const getItem = (asArray, path, content, $interpolate) => {
    let item;
    let topContent = {};
    topContent.content = content;
    //if templated
    if (_.includes(path, '{{')) {
        var templater = $interpolate(path);
        item = templater(topContent);
    } else {
        item = _.get(content, path);
    }

    return asArray ? _.castArray(item) : item;
};

export const isPathHidden = (path, displayOptions) => {
    var field = _.get(displayOptions, path);
    if (field) {
        return _.get(field, 'hidden');
    }
    return false;
};

export const isPropertyHidden = value => {
    return isHidden(_.toLower($scope.type), $rootScope.displayOptions.propertyDisplayOptions, value);
};
