'use strict';

/**
 * @ngdoc function
 * @name serviceApp.controller:PagesSetPasswordCtrl
 * @description
 * # PagesSetPasswordCtrl
 * Controller of the serviceApp
 */
angular.module('serviceApp').controller('SetPasswordCtrl', [
    '_',
    '$scope',
    '$rootScope',
    '$timeout',
    '$state',
    '$stateParams',
    'userServiceNew',
    '$rootScope',
    function(_, $scope, $rootScope, $timeout, $state, $stateParams, userServiceNew) {
        $scope.credentials = {};
        $scope.showReset = true;
        $scope.passwordResetToken = $stateParams.token;
        $scope.hasError = false;
        $scope.isExpired = false;
        $scope.hasSuccess = false;
        $rootScope.useHeader = false;

        $scope.resetpassword = function() {
            userServiceNew.resetPassword($scope.passwordResetToken, $scope.credentials.password).then(
                function(user) {
                    $scope.hasSuccess = true;
                    $scope.hasError = false;
                    $scope.showReset = false;
                    $scope.$emit('login', user);
                    return $state.go('home.main');
                },
                function(error) {
                    if (_.get(error, 'error') === 'Expired Token') {
                        $scope.isExpired = true;
                    } else {
                        $scope.hasError = true;
                    }
                    console.log('Error from userServiceNew.resetPassword: ' + JSON.stringify(error));
                }
            );
        };

        $scope.resendtoken = function() {
            var data = {
                resend_token: $scope.passwordResetToken
            };

            userServiceNew.resendPasswordResetToken(data).then(
                function() {
                    $timeout(function() {
                        $state.go('core.login');
                    }, 3000);
                },
                function(error) {
                    //Failed to resend expired token.
                    $scope.hasError = true;
                    $scope.isExpired = true;
                    console.log(
                        'Error from userServiceNew.resendPasswordResetToken: ' + JSON.stringify(error)
                    );
                }
            );
        };

        $scope.toLogin = function() {
            return $state.go('core.login');
        };

        $scope.closeSuccess = function() {
            $scope.hasSuccess = false;
        };

        $scope.retryreset = function() {
            return $state.go('core.forgotpass');
        };
    }
]);
