(function() {
    'use strict';
    angular.module('serviceApp').factory('calendarService', CalendarService);

    CalendarService.$inject = ['_', '$resource', '$q'];

    function CalendarService(_, $resource, $q) {
        const calendarUrl = '/api/calendar';
        const defaultParams = {};

        const calendarResourceApi = {
            getRetailCalendarPreview: {
                method: 'GET',
                url: `${calendarUrl}/retail-preview`,
                params: {
                    team: '@team',
                    year: '@year'
                }
            },
            translateDateRange: {
                method: 'POST',
                url: `${calendarUrl}/translate-date-range`,
                ignoreLoadingBar: true
            },
            buckets: {
                method: 'GET',
                url: `${calendarUrl}/buckets`
            }
        };

        const calendarResource = $resource(calendarUrl, defaultParams, calendarResourceApi);

        const service = {
            translateDateRange,
            getBuckets,
            getRetailCalendarPreview,
            getAvailableTimeIntervals
        };

        return service;

        ////////////

        function translateDateRange(body) {
            if (!body.rangeFilter) {
                return $q.reject({ msg: 'Range filter must be defined' });
            }
            return $q(function(resolve, reject) {
                calendarResource
                    .translateDateRange({}, body)
                    .$promise.then(response => {
                        if (response.range) {
                            return resolve(response);
                        }
                        // For backwards compatibility, before response included
                        // both `range` and `timezone`
                        else {
                            return resolve({ range: response, timezone: 'UTC' });
                        }
                    })
                    .catch(e => {
                        return reject(e);
                    });
            });
        }

        function getBuckets({ type, startDate, endDate = null, numberOfBuckets, surround = true }) {
            if (!type) {
                return $q.reject({ msg: 'type is Required' });
            }

            if (!startDate) {
                return $q.reject({ msg: 'startDate is Required' });
            }

            if (!endDate && !Number.isInteger(numberOfBuckets)) {
                return $q.reject({ msg: 'Either a valid endDate or numberOfBuckets is Required' });
            }

            const params = {
                startDate,
                type,
                surround
            };
            if (Number.isInteger(numberOfBuckets)) {
                params.n = numberOfBuckets;
            }

            if (endDate) {
                params.endDate = endDate;
            }

            return $q.when(calendarResource.buckets(params).$promise);
        }

        function getRetailCalendarPreview(body) {
            return $q(function(resolve, reject) {
                calendarResource
                    .getRetailCalendarPreview({}, body)
                    .$promise.then(response => {
                        return resolve(response);
                    })
                    .catch(e => {
                        return reject(e);
                    });
            });
        }

        function getAvailableTimeIntervals() {
            return [
                { value: 'day', title: 'Day' },
                { value: 'week', title: 'Week' },
                { value: 'fiscal-week', title: 'Fiscal Week' },
                { value: 'month', title: 'Month' },
                { value: 'fiscal-period', title: 'Fiscal Period' },
                { value: 'quarter', title: 'Quarter' },
                { value: 'fiscal-quarter', title: 'Fiscal Quarter' },
                { value: 'year', title: 'Year' },
                { value: 'fiscal-year', title: 'Fiscal Year' }
            ];
        }
    }
})();
