(function() {
    'use strict';

    angular
        .module('serviceApp')
        .config([
            'schemaFormProvider',
            'schemaFormDecoratorsProvider',
            'sfPathProvider',
            function(schemaFormProvider, schemaFormDecoratorsProvider, sfPathProvider) {
                var datepicker = function(name, schema, options) {
                    if (schema.format === 'date' || schema.format === 'date-time') {
                        var f = schemaFormProvider.stdFormObj(name, schema, options);
                        f.key = options.path;
                        f.type = 'datepicker';
                        options.lookup[sfPathProvider.stringify(options.path)] = f;
                        return f;
                    }
                };

                schemaFormProvider.defaults.string.unshift(datepicker);

                schemaFormDecoratorsProvider.addMapping(
                    'bootstrapDecorator',
                    'datepicker',
                    '/views/tmpl/partials/forms/formDatepicker.html'
                );
                schemaFormDecoratorsProvider.createDirective(
                    'datepicker',
                    '/views/tmpl/partials/forms/formDatepicker.html'
                );
            }
        ])
        .controller('DatepickerCtrl', datepickerCtrl);

    datepickerCtrl.$inject = ['_', '$scope', '$rootScope', 'moment'];

    function datepickerCtrl(_, $scope, $rootScope, $moment) {
        $scope.toggleCalendar = function($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened = !$scope.opened;
        };

        $scope.isMobile = $rootScope.isMobile;

        $scope.dateOptions = {
            formatYear: 'yy',
            minDate: $scope.setMinDate ? $scope.setMinDate : new Date(),
            maxDate: $scope.setMaxDate ? $scope.setMaxDate : null,
            showWeeks: false,
            customClass: function() {
                return 'datepickerpopup';
            }
        };

        $scope.setDate = function(year, month, day) {};

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
    }
})();
