'use strict';

/**
 * Angular wrapper for our <GoalIntervalSelectorExternalComponent /> react component.
 */
export default class GoalIntervalSelector {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.data = {
            period: this.period,
            interval: this.interval,
            availableIntervals: this.availableIntervals,
            numberOfIntervals: this.numberOfIntervals,
            offset: this.offset,
            onIntervalUpdated: this.onIntervalUpdated
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'interval') && !changes.interval.isFirstChange()) {
            this.interval = changes.interval.currentValue;
            this.data = { ...this.data, interval: this.interval };
        }

        if (this._.get(changes, 'availableIntervals') && !changes.availableIntervals.isFirstChange()) {
            this.availableIntervals = changes.availableIntervals.currentValue;
            this.data = { ...this.data, availableIntervals: this.availableIntervals };
        }

        if (this._.get(changes, 'numberOfIntervals') && !changes.numberOfIntervals.isFirstChange()) {
            this.numberOfIntervals = changes.numberOfIntervals.currentValue;
            this.data = { ...this.data, numberOfIntervals: this.numberOfIntervals };
        }

        if (this._.get(changes, 'offset') && !changes.offset.isFirstChange()) {
            this.offset = changes.offset.currentValue;
            this.data = { ...this.data, offset: this.offset };
        }

        if (this._.get(changes, 'period') && !changes.period.isFirstChange()) {
            this.period = changes.period.currentValue;
            this.data = { ...this.data, period: this.period };
        }
    }
}
