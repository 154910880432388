'use strict';

import ViewBase from '../common/ViewBase';

export default class ExternalController extends ViewBase {
    static get $inject() {
        return [
            '_',
            '$scope',
            '$rootScope',
            '$stateParams',
            '$state',
            'cardsUtilService',
            'boardServiceNew',
            'themeService',
            'user',
            'board',
            'panelGroup',
            'panel',
            'panelCards',
            'query',
            'facets',
            'filter',
            'displayOptions',
            'team',
            '$log',
            'hierarchy',
            'appSettingsService',
            'boardSliceService',
            'activeViewService',
            '$q',
            'definitionService',
            '$window',
            'overrideService'
        ];
    }
    constructor(
        _,
        $scope,
        $rootScope,
        $stateParams,
        $state,
        cardsUtilService,
        boardServiceNew,
        themeService,
        user,
        board,
        panelGroup,
        panel,
        panelCards,
        query,
        facets,
        filter,
        displayOptions,
        team,
        $log,
        hierarchy,
        appSettingsService,
        boardSliceService,
        activeViewService,
        $q,
        definitionService,
        $window,
        overrideService
    ) {
        super(
            _,
            $scope,
            $rootScope,
            $state,
            cardsUtilService,
            boardServiceNew,
            board,
            panelCards,
            $log,
            hierarchy,
            appSettingsService,
            boardSliceService,
            $q
        );

        let isLoadedWatcher = () => {};
        let cardsStaleWatcher = () => {};

        $scope.overrides = $stateParams.overrides
            ? JSON.parse($window.atob($stateParams.overrides))
            : undefined;
        $scope.hasLayout = _.get(board, 'definition.propertySchema.layout');
        $scope.boardSliceService = boardSliceService;
        $scope.boardSliceLoaded = false;
        isLoadedWatcher = $scope.$watch('boardSliceService.isLoaded()', isLoaded => {
            if (!isLoaded) {
                return;
            }
            $scope.$watch('boardSliceService.board', () => {
                // When the stored board is updated, copy it to the board in scope so that
                // we visualize the updates properly.
                if (boardSliceService.board) {
                    $scope.board = _.cloneDeep(boardSliceService.board);
                }
            });
            if (boardSliceService.isLoaded()) {
                // if we are navigating home, set an isHome flag on the board that will be checked by the nav bar component
                if (_.get($stateParams, 'isHome')) {
                    _.set(board, 'isHome', true);
                }
                $scope.boardSliceLoaded = true;
                boardSliceService.boardAdd(board);
                // TODO -- remove this if/when we come up with a better way to determine
                //         whether forecasting is enabled than by resolving board def.
                const boardDefinitionId = _.get(board, 'definition.id');
                if (boardDefinitionId) {
                    boardSliceService.resolveAvailableProperties(boardDefinitionId);
                }

                // If a filter definition was resolved (from localstorage or from a filter ID in the URL)
                // then add that definition to the store.
                if (!_.isEmpty(filter)) {
                    boardSliceService.definitionAdd(filter, true);
                }
                // Update the board in the scope to be a copy of the stored board, since that's what
                // we'll be visualizing and updating.
                $scope.board = _.cloneDeep(boardSliceService.board);
                // Load lazy card if not layout view, as they will be loaded by the layout controller
                if (!_.isEmpty($scope.lazyCards) && (!$scope.hasLayout || $stateParams.card)) {
                    _.forEach($scope.lazyCards, function(cardName) {
                        $scope.getCard({ cardName });
                    });
                    $scope.lazyCards = null;
                }
            }
        });

        cardsStaleWatcher = $scope.$watch('boardSliceService.cardsStale', () => {
            if (activeViewService.hasActiveBoardFilters() && boardSliceService.cardsStale) {
                $scope
                    .searchPanel(
                        $scope.query,
                        $scope.facets,
                        boardSliceService.boardDefinition,
                        $scope.hierarchy
                    )
                    .then(() => boardSliceService.clearCardsStale());
            }
        });

        $scope.getCards = function(data) {
            $scope.facets = _.merge($scope.facets, data.facets);
            cardsUtilService
                .getPanel(
                    $scope.board.id,
                    $scope.board.definitionName,
                    $scope.panel.name,
                    $scope.panelGroup.name,
                    $scope.query,
                    $scope.facets,
                    null,
                    true
                )
                .then(function(cards) {
                    var lazyCards = [];

                    _.forEach(cards, function(card) {
                        if (card.lazy && !card.computed) {
                            // If card is set only add the card to the lazy loaded cards if it matches the card name
                            if (!_.isUndefined($stateParams.card) && $stateParams.card != 'undefined') {
                                if (card.name === $stateParams.card) {
                                    card.loading = true;
                                    lazyCards.push(card.name);
                                } else if (_.includes($stateParams.card, card.definitionId)) {
                                    // This is the case where the external card URL contains the positional card information
                                    // e.g. .../card=(cardDefinition)-(row)-(column)
                                    const parts = _.split($stateParams.card, '-');
                                    if (
                                        _.size(parts) >= 3 &&
                                        parts[_.size(parts) - 2] === card.row &&
                                        parts[_.size(parts) - 1] == card.position
                                    ) {
                                        card.loading = true;
                                        lazyCards.push(card.name);
                                    }
                                }
                            } else {
                                card.loading = true;
                                lazyCards.push(card.name);
                            }
                        }
                    });

                    $scope.cards = cards;

                    // Now do a secondary call to get the lazy cards
                    if (!_.isEmpty(lazyCards)) {
                        _.forEach(lazyCards, function(cardName) {
                            $scope.getCard({ cardName });
                        });
                    }
                })
                .catch(function(err) {
                    $log.info('get search panel interrupted', err);
                });
        };

        $scope.external = true;
        $scope.user = user;
        $scope.board = board;
        $rootScope.board = board;
        $scope.team = team;
        $scope.hierarchy = _.get($scope, 'board.definition.hierarchy.enabled') !== false ? hierarchy : {};
        $scope.board.state = {
            currentPanel: _.get(panel, 'name')
        };
        $scope.panelGroup = panelGroup;
        $scope.panel = panel;
        $rootScope.displayOptions = displayOptions;
        $scope.darkMode = themeService.isDarkMode;

        $scope.lazyCards = [];
        _.forEach(panelCards, function(card) {
            //Check for lazy loading cards and not computed. In this case, these cards need to be retrieved separately.
            // Don't add cards that are in a panelTab nor drawerCard(details), they will be loaded separately.
            if (card.lazy && !card.computed && !card.panelTab && !card.drawerCard) {
                // If card is set only add the card to the lazy loaded cards if it matches the card name
                if (!_.isUndefined($stateParams.card) && $stateParams.card != 'undefined') {
                    if (card.name === $stateParams.card) {
                        card.loading = true;
                        $scope.lazyCards.push(card.name);
                    } else if (_.includes($stateParams.card, card.definitionId)) {
                        // This is the case where the external card URL contains the positional card information
                        // e.g. .../card=(cardDefinition)-(row)-(column)
                        const parts = _.split($stateParams.card, '-');
                        if (
                            _.size(parts) >= 3 &&
                            parts[_.size(parts) - 2] === card.row &&
                            parts[_.size(parts) - 1] == card.position
                        ) {
                            card.loading = true;
                            $scope.lazyCards.push(card.name);
                        }
                    }
                } else {
                    card.loading = true;
                    $scope.lazyCards.push(card.name);
                }
            }
        });
        $scope.cards = panelCards;
        $scope.cardNames = _.map($scope.cards, 'name');
        $scope.group = _.get(board, 'state.currentGroup', 'main');
        $scope.filters = _.get($scope, `board.panelGroups["${_.get($scope, 'group')}"].filters`);

        //If query is resolved on the URL use it, otherwise default the query object
        $scope.query = query || {
            str: '',
            destinationTime: ''
        };

        $scope.facets = facets;
        $rootScope.currentFilter = !_.isEmpty(filter) ? filter : undefined;

        if ($rootScope.isMobile) {
            $scope.isMobile = true;
        }

        if (!_.isUndefined($stateParams.card) && $stateParams.card != 'undefined') {
            $scope.cards = _.filter($scope.cards, function(card) {
                if (card.name === $stateParams.card) {
                    return true;
                } else if (_.includes($stateParams.card, card.name)) {
                    return true;
                } else if (_.includes($stateParams.card, card.definitionId)) {
                    // This is the case where the external card URL contains the positional card information
                    // e.g. .../card=(cardDefinition)-(row)-(column)
                    const parts = _.split($stateParams.card, '-');
                    if (
                        _.size(parts) >= 3 &&
                        parts[_.size(parts) - 2] === card.row &&
                        parts[_.size(parts) - 1] == card.position
                    ) {
                        return true;
                    }
                }
            });
            $scope.layout = 'card';
            _.set(_.first($scope.cards), 'size.x', 12);
        } else if ($stateParams.panel) {
            $scope.layout = 'panel';
        } else {
            $scope.layout = 'board';
        }

        // if the shortcode is _report and there is card, we are loading a shared report so
        // use the card/single api to load it
        if (board.shortcode === 'insights$_report' && $stateParams.card) {
            $scope.cards = [null];
            definitionService.getDefinitionById($stateParams.card).then(definitionItem => {
                let defToResolve = definitionItem;
                if (_.get($scope.overrides, 'report')) {
                    defToResolve = overrideService.applyOverrides(
                        definitionItem,
                        _.get($scope.overrides, 'report')
                    );
                }
                cardsUtilService
                    .renderCard({
                        cardDefinitionId: defToResolve,
                        boardDefinitionName: 'unassigned',
                        boardType: 'insights',
                        hierarchy,
                        cardDefinition: defToResolve
                    })
                    .then(card => {
                        _.set(card, 'size', 12);
                        $scope.cards = [card];
                        $scope.cardNames = [card.name];
                    });
            });
        }

        $scope.layout = $stateParams.layout ? $stateParams.layout : $scope.layout;

        $scope.$on('$destroy', function() {
            isLoadedWatcher();
            cardsStaleWatcher();
        });
    }
}
