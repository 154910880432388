'use strict';

/**
 * Angular wrapper for our <ProgressBar /> react component.
 */
export default class ProgressBar {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onChanges() {
        this.ariaLabel = _.get(this, 'ariaLabel');
        this.ariaLabelledby = _.get(this, 'ariaLabelledby');
        this.data = {
            value: _.get(this, 'value'),
            ariaProps: {
                ...(!this._.isNil(this.ariaLabel) && { 'aria-label': this.ariaLabel }),
                ...(!this._.isNil(this.ariaLabelledby) && {
                    'aria-labelledby': this.ariaLabelledby
                })
            }
        };
    }
}
