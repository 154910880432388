import { csvToJson, jsonToCsv } from '../../utils/form-utils.js';

(function() {
    'use strict';
    angular.module('serviceApp').directive('formAccordion', formAccordion);
    function formAccordion() {
        var directive = {
            restrict: 'E',
            scope: {
                definition: '=',
                formDirty: '=',
                accordionGroups: '=',
                activeGroup: '<?',
                scrollToItem: '<?',
                persistedDefinition: '<'
            },
            template: require('views/tmpl/partials/forms/formAccordion.html'),
            controller: formAccordionController
        };

        return directive;
    }

    formAccordionController.$inject = ['_', '$scope', 'appSettingsService', 'boardSliceService'];

    function formAccordionController(_, $scope, appSettingsService, boardSliceService) {
        $scope.boardDefinition = boardSliceService.boardDefinition;

        $scope.forms = {};

        //TODO: this flag after beta should be moved to using the more encapsulating flag, "enable-gpt-features"
        $scope.generativeAI =
            appSettingsService.checkVariationSync('enable-gpt-features', {
                default: true
            }) &&
            ($scope.definition.definitionType === 'board' || $scope.definition.definitionType === 'card');

        $scope.$watch(
            'definition',
            function() {
                $scope.isGlobal = _.get($scope, 'definition.teamId') === '##GLOBAL##';
                if (_.get($scope.forms, 'meta')) {
                    var metaForm = _.get($scope.forms, 'meta');
                    if ($scope.definition.displayName && metaForm.$dirty) {
                        $scope.formDirty = !metaForm.$invalid;
                    }
                }

                //Open the active group accordion if passed in, and focus on the scroll item
                if ($scope.activeGroup) {
                    $scope.toggleGroup(null, $scope.activeGroup, $scope.scrollToItem);
                }
                //Open the editor if the scroll item is board
                if ($scope.scrollToItem === 'board') {
                    $scope.showEditor = true;
                }
            },
            true
        );

        const formDirtyWatcher = $scope.$watch('formDirty', () => {
            $scope.$emit('definitionEditor:dirty', $scope.formDirty);
        });

        var validWatch = false;
        const accordionWatcher = $scope.$watch(
            'accordionGroups',
            function() {
                //ignore init watch being fired
                if (
                    validWatch &&
                    _.get($scope, 'definition.displayName') &&
                    _.get($scope, 'definition.description')
                ) {
                    $scope.formDirty = true;
                } else {
                    validWatch = true;
                }
            },
            true
        );

        const tagsWatcher = $scope.$watch(
            'definition.tags',
            function(newVal, oldVal) {
                if (!_.isEqual(newVal, oldVal)) {
                    $scope.formDirty = true;
                }
            },
            true
        );

        function getSavableProperties() {
            const propertyValuesToSave = _.reduce(
                $scope.accordionGroups,
                function(accumulator, value, key) {
                    _.set(accumulator, value.sectionName, value.formModel);
                    return accumulator;
                },
                {}
            );

            const propertySchemaToSave = _.reduce(
                $scope.accordionGroups,
                function(accumulator, value, key) {
                    _.set(accumulator, value.sectionName, _.get(value, 'formSchema.properties'));
                    return accumulator;
                },
                {}
            );

            return {
                propertyValuesToSave: propertyValuesToSave,
                propertySchemaToSave: propertySchemaToSave
            };
        }

        $scope.$on('formAccordion:save', function() {
            const savableProperties = getSavableProperties();

            $scope.$emit(
                'definitionEditor:save',
                savableProperties.propertyValuesToSave,
                savableProperties.propertySchemaToSave
            );
        });

        $scope.$on('formAccordion:preview', function() {
            const savableProperties = getSavableProperties();

            $scope.$emit(
                'definitionEditor:preview',
                savableProperties.propertyValuesToSave,
                savableProperties.propertySchemaToSave
            );
        });

        $scope.filterEventHandler = function(data) {
            var validFilterWatch = false;
            const eventData = _.get(data, 'data');
            const sectionName = _.get(data, 'sectionName');
            const filter = _.get(eventData, 'filter');
            const timeframeFilter = _.get(filter, 'card.config.timeframeFilter');
            const cardLabel = _.get(filter, 'card.config.cardLabel');

            const filterGroupIndex = _.findIndex($scope.accordionGroups, { sectionName });
            const filterGroup = _.get($scope.accordionGroups, filterGroupIndex);

            _.set(filterGroup, 'formModel', _.get(filter, ['propertyValues', sectionName]));
            _.set(filterGroup, 'formSchema.properties', _.get(filter, ['propertySchema', sectionName]));

            _.set($scope.accordionGroups, filterGroupIndex, filterGroup);

            const savableProperties = getSavableProperties();

            if (validFilterWatch) {
                $scope.formDirty = true;
            } else {
                validFilterWatch = true;
            }

            const savableObject = {
                propertyValues: savableProperties.propertyValuesToSave,
                propertySchema: savableProperties.propertySchemaToSave
            };

            // If the card config has been set or updated get the card config payload and set it to the saveable object
            if (timeframeFilter || cardLabel) {
                const cardConfig = _.cloneDeep(_.get($scope.definition, 'card'));
                if (timeframeFilter) {
                    _.set(cardConfig, 'config.timeframeFilter', timeframeFilter);
                }
                if (cardLabel) {
                    _.set(cardConfig, 'config.cardLabel', timeframeFilter);
                }
                _.set(savableObject, 'card', cardConfig);
            }

            //If the timeframe filter has been removed, remove it from the card config payload
            if (!timeframeFilter && _.get($scope.definition, 'card.config.timeframeFilter')) {
                const cardConfig = _.cloneDeep(_.get($scope.definition, 'card'));
                _.unset(cardConfig, 'config.timeframeFilter');
                _.set(savableObject, 'card', cardConfig);
            }

            $scope.$emit('definitionEditor:update', savableObject);
        };

        $scope.promptEventHandler = function(data) {
            var validPromptWatch = false;
            const eventData = _.get(data, 'data');
            const eventTrigger = _.get(eventData, 'eventTrigger');
            const promptValues = _.get(eventData, 'promptValues');
            const promptSchema = _.get(eventData, 'promptSchema');
            const actionEventData = _.get(eventData, 'action');

            const promptGroupIndex = _.findIndex($scope.accordionGroups, { sectionName: 'prompt' });
            const promptGroup = _.get($scope.accordionGroups, promptGroupIndex);

            _.set(promptGroup, 'formModel', promptValues);
            _.set(promptGroup, 'formSchema.properties', promptSchema);

            _.set($scope.accordionGroups, promptGroupIndex, promptGroup);

            const savableProperties = getSavableProperties();

            if (validPromptWatch) {
                $scope.formDirty = true;
            } else {
                validPromptWatch = true;
            }

            $scope.$emit('definitionEditor:update', {
                propertyValues: savableProperties.propertyValuesToSave,
                propertySchema: savableProperties.propertySchemaToSave,
                eventTrigger,
                action: actionEventData
            });
        };

        // listen for layout config update events
        $scope.layoutEventHandler = data => {
            const layoutData = _.get(data, 'data');
            const layoutValues = _.get(layoutData, 'layoutValues');

            const layoutGroupIndex = _.findIndex($scope.accordionGroups, { sectionName: 'layout' });
            const layoutGroup = _.get($scope.accordionGroups, layoutGroupIndex);

            _.set(layoutGroup, 'formModel', layoutValues);

            _.set($scope.accordionGroups, layoutGroupIndex, layoutGroup);

            const savableProperties = getSavableProperties();

            $scope.$emit('definitionEditor:update', {
                propertyValues: savableProperties.propertyValuesToSave,
                propertySchema: savableProperties.propertySchemaToSave
            });
        };

        // listen for trend config update events
        $scope.trendEventHandler = data => {
            const eventData = _.get(data, 'data');
            const context = _.get(eventData, 'context');
            const trendValues = _.get(eventData, 'trendValues');
            const eventType = _.get(eventData, 'eventType');

            if (eventType === 'updateAvailableContext') {
                _.set($scope, 'definition.action.availableContext', context);
                $scope.$broadcast('updatedAvailableContext', $scope.definition);
                return;
            }
            const trendGroupIndex = _.findIndex($scope.accordionGroups, { sectionName: 'trend' });
            const trendGroup = _.get($scope.accordionGroups, trendGroupIndex);

            _.set(trendGroup, 'formModel', trendValues);

            _.set($scope.accordionGroups, trendGroupIndex, trendGroup);

            const savableProperties = getSavableProperties();

            $scope.$emit('definitionEditor:update', {
                propertyValues: savableProperties.propertyValuesToSave,
                propertySchema: savableProperties.propertySchemaToSave
            });
        };

        // listen for visualization config update events in visualization-editor-container
        $scope.visualizationEventHandler = data => {
            const eventData = _.get(data, 'data');
            const visualizationConfig = eventData;

            const visualizationGroupIndex = _.findIndex($scope.accordionGroups, {
                sectionName: 'visualization'
            });
            const visualizationGroup = _.get($scope.accordionGroups, visualizationGroupIndex);
            _.set(visualizationGroup, 'formModel.configuration', visualizationConfig);
            _.set($scope.accordionGroups, visualizationGroupIndex, visualizationGroup);

            const savableProperties = getSavableProperties();

            $scope.$emit('definitionEditor:update', {
                propertyValues: savableProperties.propertyValuesToSave,
                propertySchema: savableProperties.propertySchemaToSave
            });
        };

        // listen for segment config update events in segment-editor-container
        $scope.segmentEventHandler = data => {
            // Modifying a Segments Attainment Data/Calculation Field and Filter will both be handled in this callback
            const eventData = _.get(data, 'data'); // Segment Attainment Data/Calculation Field
            const filterData = _.get(eventData, 'filter'); // Segment filter

            // Pull out the current segment so that we can update it
            let segmentGroupIndex = _.findIndex($scope.accordionGroups, { sectionName: 'segments' });
            let segmentGroup = _.get($scope.accordionGroups, segmentGroupIndex);

            const { definition } = eventData;
            // We're modifying the Segments Attainment Date || Calculation Field
            if (!filterData && definition) {
                const segmentsListSchema = _.get(definition, 'propertySchema.segments.segmentsList', []);
                const segmentsListValues = _.get(definition, 'propertyValues.segments.segmentsList', []);

                segmentGroup.formSchema.properties.segmentsList = segmentsListSchema;
                segmentGroup.formModel.segmentsList = segmentsListValues;
            }

            // We're modifying the segments filter
            if (filterData) {
                // The filterData in the event is a dummy definition created for the segment that
                // was edited.  The ID of that definition is the ID of the segment.
                const segmentId = filterData.id;
                const filterSchema = _.get(filterData, 'propertySchema.filter', []);
                const filterValues = _.get(filterData, 'propertyValues.filter', []);

                // Find the matching segment object in the accordion section's model and update its "filter"
                // property.  This is ultimately updating propertyValues.segmentsList in the definition.
                const segmentValues = _.find(_.get(segmentGroup, 'formModel.segmentsList'), {
                    id: filterData.id
                });
                segmentValues.filter = filterValues;

                // Find the matching segment object in the accordion section's formSchema.properties
                // and update its "filter" property.  This is ultimately updating propertySchema.segmentsList
                // in the definition.
                const segmentSchema = _.find(_.get(segmentGroup, 'formSchema.properties.segmentsList'), {
                    id: filterData.id
                });
                segmentSchema.filter = filterSchema;
            }

            // Write back the segment updates
            _.set($scope.accordionGroups, segmentGroupIndex, segmentGroup);
        };

        $scope.goalConfigurationEventHandler = data => {
            const goalConfigurationFormModel = _.get(data, 'data');

            // remove the calculation field from segments if we have a count calculation
            if (_.get(goalConfigurationFormModel, 'calculation') === 'count') {
                let segmentGroupIndex = _.findIndex($scope.accordionGroups, { sectionName: 'segments' });
                let segmentGroup = _.get($scope.accordionGroups, segmentGroupIndex);

                _.forEach(segmentGroup.formModel.segmentsList, segment => {
                    _.set(segment, 'calculationField', null);
                });

                _.set($scope.accordionGroups, segmentGroupIndex, segmentGroup);
            }

            const goalConfigurationIndex = _.findIndex($scope.accordionGroups, { sectionName: 'goal' });
            const currentGoalConfiguration = { ...$scope.accordionGroups[goalConfigurationIndex] };

            currentGoalConfiguration.formModel = goalConfigurationFormModel;

            _.set($scope.accordionGroups, goalConfigurationIndex, currentGoalConfiguration);
        };

        $scope.cardGoalConfigurationEventHandler = data => {
            $scope.$broadcast('updatedGoalConfig', data);
            $scope.$broadcast('updatedInsightsContext', $scope.definition);
            const goalData = _.get(data, 'data');

            const goalConfigurationIndex = _.findIndex($scope.accordionGroups, {
                sectionName: 'goalConfiguration'
            });
            const goalConfigGroup = _.get($scope.accordionGroups, goalConfigurationIndex);
            _.set(goalConfigGroup, 'formModel', goalData);
            _.set($scope.accordionGroups, goalConfigurationIndex, goalConfigGroup);

            const savableProperties = getSavableProperties();
            $scope.$emit('definitionEditor:update', {
                propertyValues: savableProperties.propertyValuesToSave,
                propertySchema: savableProperties.propertySchemaToSave
            });
        };

        $scope.forecastingConfigurationEventHandler = data => {
            const savableProperties = getSavableProperties();
            $scope.$emit('definitionEditor:update', {
                propertyValues: savableProperties.propertyValuesToSave,
                propertySchema: savableProperties.propertySchemaToSave
            });
        };

        $scope.hierarchyUploaderEventHandler = data => {
            const results = csvToJson(data.data);
            $scope.$emit('definitionEditor:update', {
                'hierarchy.customHierarchy': results
            });
        };

        $scope.hierarchyExportEventHandler = () => {
            const hierarchy = _.get($scope, 'definition.hierarchy.customHierarchy', []);
            const results = jsonToCsv(hierarchy, { columns: ['id', 'parentId'] });
            $scope.$emit('definitionEditor:exportCsv', {
                data: results,
                fileName: 'customHierarchy.csv'
            });
        };

        $scope.hierarchyDefaultEventHandler = data => {
            const boardDef = _.get($scope, 'definition.board');
            _.set(boardDef, 'hierarchy', data.data);
        };

        $scope.showGroup = function(accordionGroup) {
            // Use this method to gate access to certain panels using LaunchDarkly flags
            return true;
        };

        $scope.toggleGroup = function(index, name, _scrollToItem) {
            _.forEach($scope.accordionGroups, function(group, n) {
                if ((group && n === index) || (group && _.get(group, 'sectionName') === name)) {
                    group._isOpen = !group._isOpen;
                    group._scrollToItem = _scrollToItem ? _scrollToItem : undefined;
                    if (group._isOpen) {
                        group._hasBeenOpen = true;
                    }
                } else {
                    _.set(group, '_isOpen', false);
                    _.set(group, '_scrollToItem', undefined);
                }
            });
        };

        $scope.toggleEditor = function() {
            $scope.showEditor = !$scope.showEditor;
        };

        // If this form accordion is in a definition header, listen for possible
        // visibility changes and broadcast them down.
        $scope.$on('definitionHeaderVisibilityChanged', function() {
            $scope.$broadcast('formVisibilityChanged');
        });

        $scope.groupIsOpen = function(groups) {
            return !!_.find(groups, '_isOpen');
        };

        $scope.$on('$destroy', () => {
            // Destroy watchers.
            formDirtyWatcher();
            accordionWatcher();
            tagsWatcher();
        });
    }

    /**
     * Controller for the ng-repeat over accordian groups, so we can watch individual properties
     * of that group and broadcast events down when it changes.
     */
    angular.module('serviceApp').controller('AccordionGroupController', [
        '$scope',
        function($scope) {
            $scope.$watch('group._isOpen', function() {
                $scope.$broadcast('formVisibilityChanged');
            });
        }
    ]);
})();
