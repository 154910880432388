'use strict';
import _ from 'lodash';

import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('actionContentIcon', () => ({
    restrict: 'E',
    scope: {
        content: '=',
        size: '=?'
    },
    template,
    controller: Controller
}));
