(function() {
    'use strict';
    angular.module('serviceApp').factory('actionService', ActionService);

    ActionService.$inject = ['_', '$resource', '$q'];

    function ActionService(_, $resource, $q) {
        var actionUrl = '/api/action';
        var userUrl = '/api/user';
        var defaultParams = {};

        var actionResourceApi = {
            actionEvent: {
                method: 'POST',
                url: actionUrl + '/event'
            },
            listInstantActions: {
                method: 'GET',
                url: actionUrl + '/instant/list',
                params: {
                    contentType: '@contentType',
                    contentId: '@contentId'
                },
                isArray: true
            },
            generateInstantAction: {
                method: 'POST',
                url: actionUrl + '/instant/generate'
            },
            getAction: {
                method: 'GET',
                url: actionUrl + '/:actionId'
            },
            searchActions: {
                method: 'POST',
                url: `${actionUrl}/search`
            },
            getActionStats: {
                isArray: true,
                method: 'POST',
                url: actionUrl + '/stats'
            },
            autoActionOptIn: {
                method: 'POST',
                url: userUrl + '/auto-run/opt-in'
            },
            autoActionOptOut: {
                method: 'POST',
                url: userUrl + '/auto-run/opt-out'
            },
            resolveActionEventTriggers: {
                method: 'POST',
                url: actionUrl + '/event-trigger/resolve'
            },
            assignToUser: {
                method: 'POST',
                url: `${actionUrl}/assign`
            },
            forceAutoRun: {
                method: 'POST',
                url: `${actionUrl}/force/auto-run`
            }
        };

        var actionResource = $resource(actionUrl, defaultParams, actionResourceApi);

        var service = {
            actionEvent,
            listInstantActions,
            generateInstantAction,
            getAction,
            searchActions,
            getActionStats,
            getEventTriggerIconData,
            resolveEventTriggers,
            resolveActionEventTriggers,
            autoActionOptIn,
            autoActionOptOut,
            updateCardAfterRefreshEvent,
            assignToUser,
            forceAutoRun
        };

        return service;

        ////////////

        function execute(action) {
            return $q.when(action.$promise).then(
                resp => resp,
                (err = {}) => {
                    const msg = _.get(err, 'data.error') || _.get(err, 'statusText') || JSON.stringify(err);
                    return $q.reject(Object.assign({ msg }, err));
                }
            );
        }

        function getAction(actionId) {
            if (!actionId) {
                return $q.reject({
                    msg: 'Action id must be defined'
                });
            }

            return execute(actionResource.getAction({ actionId: actionId }, {}));
        }

        /**
         * searchActions - search for actions
         * @param {Object} args
         * @param {Object} args.filter - filter object
         * @param {Object} args.options - options object
         * @param {Number} args.options.uniqueSize - number of unique action types to return
         * @param {Boolean} args.options.active - shortcut to return only active actions
         * @param {Boolean} args.options.complete - shortcut to return only complete actions
         * @param {Number} args.options.size - max number of actions to return
         * @param {Number} args.options.query - user entered query
         */
        function searchActions({ filter, options }) {
            return execute(actionResource.searchActions({}, { filter, options }));
        }

        function actionEvent(action, eventTrigger, payload) {
            if (!action) {
                return $q.reject({
                    msg: 'Action must be defined'
                });
            }

            if (!eventTrigger) {
                return $q.reject({
                    msg: 'EventTrigger must be defined'
                });
            }

            if (eventTrigger == 'complete') {
                eventTrigger = {
                    type: 'userTriggered',
                    payload: {
                        userState: 'complete'
                    }
                };
            } else if (eventTrigger == 'snooze') {
                const snoozeUntil = _.get(payload, 'snoozeUntil');
                if (!snoozeUntil) {
                    return $q.reject({
                        msg: 'snoozeUntil must be defined for snoozing actions'
                    });
                }

                eventTrigger = {
                    type: 'userTriggered',
                    payload: {
                        userState: 'snooze',
                        snoozeUntil: snoozeUntil
                    }
                };
            } else if (eventTrigger == 'dismiss') {
                eventTrigger = {
                    type: 'userTriggered',
                    payload: {
                        userState: 'dismiss'
                    }
                };
            } else if (eventTrigger == 'dismiss-all') {
                eventTrigger = {
                    type: 'userTriggered',
                    payload: {
                        userState: 'dismiss-all'
                    }
                };
            } else if (eventTrigger == 'automation-user-opt-in') {
                const definitionId = _.get(payload, 'definitionId');
                if (!definitionId) {
                    return $q.reject({
                        msg: 'definitionId must be defined for opt in actions'
                    });
                }
                eventTrigger = {
                    type: 'automation-user-opt-in',
                    payload: { definitionId }
                };
            } else if (eventTrigger == 'automation-user-opt-out') {
                const definitionId = _.get(payload, 'definitionId');
                if (!definitionId) {
                    return $q.reject({
                        msg: 'definitionId must be defined for opt out actions'
                    });
                }
                eventTrigger = {
                    type: 'automation-user-opt-out',
                    payload: { definitionId }
                };
            }

            var body = {
                eventTrigger: eventTrigger,
                actionId: _.get(action, 'id'),
                contentId: _.get(action, 'content.id')
            };

            if (action.instant) {
                body.action = action;
            }

            return execute(actionResource.actionEvent({}, body));
        }

        /**
         * getActionStats - retrieve stats for a given action
         * @param {Object} body - body of the request
         * @param {Object} body.range - object representing the range of the graph
         * @param {String} body.range.min - min bound of time range
         * @param {String} body.range.max - max bound of time range
         * @param {String} body.interval - interval in which to return stats buckets (week, month, etc)
         * @param {String} body.actionName - name of the action to get stats for
         * @param {String} body.filter - additional custom filter to apply to the stats
         * @param {String} body.query - free form query string to filter results further
         */
        function getActionStats(body = {}) {
            return execute(actionResource.getActionStats({}, body));
        }

        function listInstantActions(options) {
            const contentType = _.get(options, 'contentType');
            const contentId = _.get(options, 'contentId');

            return execute(actionResource.listInstantActions({ contentType, contentId }));
        }

        function generateInstantAction(options) {
            const actionName = _.get(options, 'actionName');
            const definitionId = _.get(options, 'definitionId');
            const globalDefinitionId = _.get(options, 'globalDefinitionId');
            const contentId = _.get(options, 'contentId');

            const boardId = _.get(options, 'boardId');

            if (!actionName) {
                return $q.reject({ msg: 'Action name must be defined' });
            }
            if (!boardId) {
                return $q.reject({ msg: 'Board id must be defined' });
            }
            if (!definitionId) {
                return $q.reject({ msg: 'Definition id must be defined' });
            }
            if (!globalDefinitionId) {
                return $q.reject({ msg: 'Global definition id must be defined' });
            }

            return execute(
                actionResource.generateInstantAction(
                    {},
                    { actionName, definitionId, globalDefinitionId, boardId, contentId }
                )
            );
        }

        function getEventTriggerIconData(eventTrigger) {
            var DEFAULT_ICON = 'fa-external-link';
            var iconDisplayOptions = _.get(eventTrigger, 'displayOptions.icon');
            var url = _.get(iconDisplayOptions, 'url');

            if (!iconDisplayOptions) {
                return { url: undefined, default: DEFAULT_ICON };
            }

            var location = document.createElement('a');
            location.href = url;

            if (_.includes(url, 'http')) {
                return {
                    url: '//logo.clearbit.com/' + location.hostname,
                    default: iconDisplayOptions.default ? iconDisplayOptions.default : DEFAULT_ICON
                };
            } else {
                return {
                    url: '//logo.clearbit.com/' + url,
                    default: iconDisplayOptions.default ? iconDisplayOptions.default : DEFAULT_ICON
                };
            }
        }

        function resolveEventTriggers(eventTriggers) {
            if (_.isEmpty(eventTriggers) || !Array.isArray(eventTriggers)) {
                return [];
            }

            return _.map(eventTriggers, function(eventTrigger) {
                _.set(eventTrigger, 'displayOptions.icon', getEventTriggerIconData(eventTrigger));
                return eventTrigger;
            });
        }

        function autoActionOptIn(definitionId, userId) {
            if (!definitionId || !userId) {
                return $q.reject({ msg: 'Definition id and User id must be defined' });
            }

            return execute(actionResource.autoActionOptIn({}, { definitionId, userId }));
        }

        function autoActionOptOut(definitionId, userId) {
            if (!definitionId || !userId) {
                return $q.reject({ msg: 'Definition id and User id must be defined' });
            }

            return execute(actionResource.autoActionOptOut({}, { definitionId, userId }));
        }

        function updateCardAfterRefreshEvent($scope, payload) {
            // Get the new actions data.
            var actionsFromServer = _.get(payload.data, 'properties.actions');
            // Merge the new actions in to the existing list.  This maintains order while
            // skipping actions from the server payload that weren't already in the list
            // and aren't part of the socket event, so that things like pending actions and
            // items that were just snoozed don't get re-added.
            var currentCardActionIds = _.map(_.get($scope.card, 'properties.actions'), 'id');
            var allowedActionIds = _.union(
                currentCardActionIds,
                _(payload.eventData)
                    .map('actions')
                    .flatten()
                    .value()
            );
            var newCardActions = _.reduce(
                actionsFromServer,
                function(memo, actionFromServer) {
                    if (_.includes(allowedActionIds, actionFromServer.id)) {
                        memo.push(actionFromServer);
                    }
                    return memo;
                },
                []
            );
            // Set the new actions list on the scope, which will cause them
            // to auto-magically re-render.
            _.set($scope.card, 'properties.actions', newCardActions);
        }

        function resolveActionEventTriggers({ actionId, action, definition, options, currentPayload }) {
            if (!actionId && !action) {
                return $q.reject({ msg: 'action or action id must be defined' });
            }

            return $q
                .when(
                    actionResource.resolveActionEventTriggers(
                        {},
                        { actionId, action, definition, options, currentPayload }
                    ).$promise
                )
                .then(response => _.get(response, 'eventTriggers', []))
                .catch(err => {
                    const msg = _.get(err, 'data.error') || _.get(err, 'statusText') || JSON.stringify(err);
                    return $q.reject(Object.assign({ msg }, err));
                });
        }

        /**
         * assignToUser - assign a given action to a given user
         * @param {Object} body - body of the request
         * @param {String} body.userId - user to assign
         * @param {String} body.actionId - action to assign the user to
         * @returns {Promise<Object>} the action with the new assignee and event triggers resolved
         */
        function assignToUser(body) {
            return execute(actionResource.assignToUser({}, body));
        }

        /**
         * forceAutoRun - schedule auto-run of actions with definitionId up to maxActions
         * @param {String} args.definitionId - definitionId of actions to schedule
         * @param {Number} [args.maxActions] - total actions to try to auto-run
         */
        function forceAutoRun({ definitionId, maxActions }) {
            return execute(actionResource.forceAutoRun({}, { definitionId, maxActions }));
        }
    }
})();
