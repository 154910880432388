import {
    getDefaultFormModel,
    convertModelToCurrentPayload,
    validatePayload
} from '../../utils/form-utils.js';

(function() {
    'use strict';
    angular.module('serviceApp').directive('cardActionDetails', cardActionDetails);

    function cardActionDetails() {
        var directive = {
            restrict: 'E',
            scope: {
                board: '=',
                user: '<',
                team: '<',
                action: '=',
                external: '=?',
                executeEventTrigger: '&',
                horizontal: '=?',
                preview: '=?',
                resolvedEventTriggers: '=?',
                definition: '=?',
                parent: '<'
            },
            template: require('views/tmpl/cards/actionDetails.html'),
            controller: CardActionDetailsController
        };

        return directive;
    }

    CardActionDetailsController.$inject = [
        '_',
        '$scope',
        '$rootScope',
        '$interpolate',
        'actionService',
        '$sce',
        '$filter',
        'toastr'
    ];
    function CardActionDetailsController(
        _,
        $scope,
        $rootScope,
        $interpolate,
        actionService,
        $sce,
        $filter,
        toastr
    ) {
        $scope.showDetails = true;
        $scope.actionOpen = true;
        $scope.checkValid = false;

        $scope.handleEventTrigger = function(eventTrigger, toggleButton) {
            if (!toggleButton) {
                $scope.showDetails = false;
            }
            $scope.toggleButton = toggleButton;
            $scope.buttonDisabled = true;
            $scope.executeEventTrigger({ data: { eventTrigger: eventTrigger, action: $scope.action } });
        };

        $scope.submitFormTrigger = function(actionForm, eventTrigger) {
            const { form, errors } = validatePayload({
                model: $scope.model,
                schemaForm: _.get(eventTrigger, 'payload.schema.properties'),
                angularForm: actionForm
            });

            if (form.$valid) {
                $scope.buttonDisabled = true;
                //clean the form
                form.$setPristine();
                form.$setUntouched();
                eventTrigger.payload.model = $scope.model;
                $scope.handleEventTrigger(eventTrigger);
            } else {
                $scope.errorForm = form;
                $scope.formErrors = errors;
                $scope.checkValid = true;
            }
        };

        $scope.closeDrawer = function() {
            if ($scope.parent === 'leftDrawer') {
                $scope.$root.$emit('leftDrawer:close');
            } else {
                $scope.$root.$emit('rightDrawer:close');
            }
        };

        $scope.model = {};
        $scope.forms = {};
        $scope.toggleButton = false;
        $scope.isMobile = $rootScope.isMobile;

        $scope.$watch('action', function() {
            if ($scope.action) {
                $scope.formOptions = {
                    formDefaults: {
                        ngModelOptions: {
                            contentDefinition: _.get($scope, 'action')
                        }
                    }
                };
                resolveActionPrompt();
                resolveEventTriggers();
                translateAssignees();
                isUserAssignee();
                $scope.toggleButton = false;
                $scope.showDetails = true;
            } else {
                $scope.showDetails = false;
            }
            $scope.errorForm = undefined;
            $scope.checkValid = false;
            $scope.buttonDisabled = undefined;
            $scope.pending = _.get($scope.action, 'state') === 'pending';
        });

        // listen for changes to the form that require a form redraw
        const formItemValueChangedListener = $scope.$root.$on(
            `form-item-value-changed`,
            (message, data = {}) => {
                startLoading();
                $scope.buttonDisabled = undefined;
                const { formKey, value } = data;

                actionService
                    .resolveActionEventTriggers({
                        action: $scope.action,
                        // there is a race condition that the model is not updated before the
                        // event is handled so passing the value through
                        currentPayload: convertModelToCurrentPayload(
                            Object.assign({}, $scope.model, { [formKey]: value }),
                            $scope.defaultTrigger
                        ),
                        // only populated in action preview
                        definition: $scope.definition,
                        options: { withIntegrationBlockers: $scope.preview ? false : true }
                    })
                    .then(result => {
                        $scope.resolvedEventTriggers = result;
                        resolveEventTriggers();
                    })
                    .catch(e => {
                        toastr.error('Unable to update form', _.get(e, 'message', e));
                    })
                    .then(() => {
                        endLoading();
                    });
            }
        );

        // destroy listeners
        $scope.$on('$destroy', () => {
            formItemValueChangedListener();
        });

        function resolveActionPrompt() {
            var prompt = _.get($scope.action, 'prompt');
            prompt = $interpolate(prompt)($scope);

            $scope.resolvedActionPrompt = prompt;
        }

        function resolveEventTriggers() {
            // if resolved event triggers are not supplied then default to action persisted event triggers
            if (!$scope.resolvedEventTriggers) {
                $scope.resolvedEventTriggers = actionService.resolveEventTriggers(
                    $scope.action.eventTriggers
                );
            }

            const defaultTrigger = _.head($scope.resolvedEventTriggers);
            $scope.model = getDefaultFormModel(defaultTrigger);

            //required for json-form-schema bug that does not properly redraw empty forms
            if (_.isEmpty(_.get(defaultTrigger, 'payload.schema.properties'))) {
                _.unset(defaultTrigger, 'payload.schema');
            }
            $scope.defaultTrigger = defaultTrigger;
        }

        function translateAssignees() {
            var lowerUserEmail = _.toLower(_.get($scope, 'user.email'));
            var assignees = _.cloneDeep(_.get($scope, 'action.assignees', []));
            var lastCalculationDate = _.get($scope, 'action.lastCalculationDate');
            var recDate = _.get($scope, 'action.relevantDate', lastCalculationDate);

            $scope.translatedAssignees = _(assignees)
                .map(function(assignee) {
                    var name;

                    if (assignee.email === lowerUserEmail) {
                        name = 'You';
                    } else {
                        name = assignee.name || `${assignee.email} (not in team)`;
                    }

                    if (assignee.currentState !== 'active') {
                        name = name + ' (' + _.startCase(assignee.currentState) + ')';
                    }

                    return name;
                })
                .compact()
                .join(',');

            $scope.tooltipContent = $sce.trustAsHtml(
                '<strong>Assigned To: </strong>' +
                    $scope.translatedAssignees +
                    '</br>' +
                    '<strong>Recommended: </strong>' +
                    $filter('humanDate')(recDate)
            );
        }

        function isUserAssignee() {
            var lowerUserEmail = _.toLower(_.get($scope, 'user.email'));
            var assignees = _.cloneDeep(_.get($scope, 'action.assignees', []));
            $scope.userIsAssignee = !!_.find(assignees, { email: lowerUserEmail });
        }

        function startLoading() {
            // if already loading no need to start again
            if ($scope.loading) {
                return;
            }

            $scope.loading = true;
            $scope.$emit('actionLoaderStart');
        }

        function endLoading() {
            $scope.loading = false;
            $scope.$emit('actionLoaderEnd');
        }
    }
})();
