'use strict';

/**
 * Angular wrapper for our <ApiLimit /> react component.
 */

export default class ApiLimit {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onChanges() {
        this.data = {
            dailyApiCallLimit: _.get(this, 'dailyApiCallLimit'),
            dailyApiCalls: _.get(this, 'primaryUserSettings.calculatedDailyApiCalls'),
            dailyOverallApiCalls: _.get(this, 'primaryUserSettings.dailyOverallApiCalls'),
            dailyOverallApiLimit: _.get(this, 'primaryUserSettings.dailyOverallApiLimit'),
            updateDailyApiCallLimit: _.get(this, 'updateDailyApiCallLimit')
        };
    }
}
