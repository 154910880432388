'use strict';
import Controller from './controller';
import template from './index.html';

angular.module('serviceApp').directive('formBoolean', () => ({
    restrict: 'A',
    scope: {
        formModel: '=formBoolean',
        form: '='
    },
    template,
    controller: Controller
}));
