'use strict';
import _ from 'lodash';

export default class Controller {
    // angular dependency injector
    static get $inject() {
        return ['$scope'];
    }

    constructor($scope, contentTypeService) {
        $scope.itemModel = _.get($scope, 'formModel.payload.schema.properties.forecastEntryItem.default');
        const onModelChange = value => {
            _.set($scope, 'formModel.payload.schema.properties.forecastEntryItem.default', value);
        };

        $scope.hooks = { onModelChange };
    }
}
