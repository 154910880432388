'use strict';

import controller from './controller';
import template from './index.html';

/* Note that `progressBar` is already declared by the `toastr` module. */
angular.module('serviceApp').component('platformProgressBar', {
    controller,
    template,
    bindings: {
        value: '<',
        ariaLabel: '@?',
        ariaLabelledby: '@?'
    }
});
