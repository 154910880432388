'use strict';
import template from './index.html';
import Controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('expandableContentList', () => ({
    restrict: 'E',
    scope: {
        results: '=',
        user: '<',
        team: '<?',
        board: '<?',
        panel: '<?',
        facets: '<?',
        filterData: '<?',
        setNumberOfShownItems: '&?',
        refreshCallback: '&?',
        isDetailsContent: '<?',
        cardLayout: '<?',
        resultLength: '<?',
        userTheme: '<?'
    },
    template: template,
    controller: Controller
}));
