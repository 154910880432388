'use strict';

/**
 * Angular wrapper for our <SearchInputExternalExternalComponent /> react component.
 */
export default class SearchInputExternal {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.data = {
            queryValue: this.queryValue,
            searchLabel: this.searchLabel,
            onQuery: this.onQuery,
            variant: this.variant
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'queryValue') && !changes.queryValue.isFirstChange()) {
            this.queryValue = changes.queryValue.currentValue;
            this.data = { ...this.data, queryValue: this.queryValue };
        }

        if (this._.get(changes, 'searchLabel') && !changes.searchLabel.isFirstChange()) {
            this.searchLabel = changes.searchLabel.currentValue;
            this.data = { ...this.data, searchLabel: this.searchLabel };
        }
    }
}
