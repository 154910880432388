'use strict';

import './index.scss';
import template from './index.html';
import controller from './controller';

angular.module('serviceApp').directive('cardInsightsChart', () => {
    return {
        scope: {
            board: '<',
            panel: '<',
            card: '<',
            user: '<',
            facets: '<',
            query: '=',
            filterData: '<',
            loadingTable: '=',
            getCardHitch: '&',
            openDetailsHitch: '&',
            buildDownloadUrlHitch: '&',
            onUpdateChangeRange: '&',
            hierarchy: '<'
        },
        template: template,
        controller
    };
});
