'use strict';

import controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').component('energyScore', {
    controller,
    template,
    bindings: {
        value: '<',
        dark: '<?'
    }
});
