'use strict';

/**
 * @ngdoc function
 * @name serviceApp.controller:RedirectController
 * @description
 * # RedirectController
 * Controller of the serviceApp
 */
angular.module('serviceApp').controller('RedirectController', [
    '$state',
    '$stateParams',
    function($state, $stateParams) {
        if ($state.current.name == 'app.home' && $stateParams.board != '') {
            $state.go('boards.board.panel', { board: $stateParams.board, panel: '' });
        } else if ($state.current.name == 'app.home') {
            $state.go('home.main');
        }
    }
]);
