(function() {
    'use strict';

    angular.module('serviceApp').filter('rawOutput', rawOutput);

    rawOutput.$inject = ['_', '$filter'];

    function rawOutput(_, $filter) {
        var filter = function(value) {
            //Return the value as presented with no extra formatting
            return value;
        };

        return filter;
    }
})();
