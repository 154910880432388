'use strict';
import _ from 'lodash';

export default class FormItemValidatorController {
    static get $inject() {
        return ['$scope'];
    }
    constructor(scope) {
        scope.getToolTip = () => {
            if (scope.readonly) {
                return scope.readonlyReason ? scope.readonlyReason : 'This field is locked';
            } else if (scope.maxLengthExceeded()) {
                return `Current input is too large at ${_.size(
                    scope.formModel
                )} the maximum input length is ${scope.maxLength}`;
            }
        };

        scope.maxLengthExceeded = () => {
            return scope.maxLength !== -1 && _.size(scope.formModel) > scope.maxLength;
        };
    }
}
