'use strict';

/**
 * @ngdoc function
 * @name serviceApp.controller:MobileViewController
 * @description
 * # MobileViewController
 */
angular.module('serviceApp').controller('MobileViewController', [
    '_',
    '$scope',
    '$stateParams',
    'board',
    'user',
    'type',
    'displayOptions',
    '$rootScope',
    '$state',
    function(_, $scope, $stateParams, board, user, type, displayOptions, $rootScope, $state) {
        $scope.board = board;
        $scope.type = type;

        $rootScope.displayOptions = displayOptions;
        $rootScope.useHeader = false;

        if (!$rootScope.isMobile) {
            $state.go('core.login');
        }
    }
]);
