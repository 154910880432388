'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('propertyMappingModal', {
    controller,
    template,
    bindings: {
        contentType: '<',
        name: '<',
        appId: '<',
        integrationType: '<',
        handleCloseModal: '&'
    }
});
