(function() {
    'use strict';

    angular.module('serviceApp').directive('cardTeamContentSettings', cardTeamContentSettings);

    function cardTeamContentSettings() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                team: '='
            },
            template: require('views/tmpl/cards/settings/team/cardTeamContentSettings.html'),
            controller: CardTeamContentSettingsController
        };

        return directive;
    }

    CardTeamContentSettingsController.$inject = [
        '$scope',
        'teamServiceNew',
        '$state',
        '$rootScope',
        'toastr'
    ];

    function CardTeamContentSettingsController($scope, teamServiceNew, $state, $rootScope, toastr) {
        $scope.productName = $rootScope.productName;

        $scope.deleteTeam = function() {
            teamServiceNew.deleteTeam($scope.team.id).then(function() {
                toastr.success('Deleting Team in the Background...', '');
                $rootScope.$emit('logout');
                $state.go('core.login');
            });
        };

        $scope.deleteTeamContent = function() {
            teamServiceNew.deleteTeamContent($scope.team.id).then(function() {
                toastr.success('Deleting Team Content in the Background...', '');
                $scope.toggleShowModal('showDeleteTeamContentModal');
            });
        };

        $scope.toggleShowModal = function(modal) {
            if (modal == 'showDeleteTeamModal') {
                $scope.showDeleteTeamModal = !$scope.showDeleteTeamModal;
            } else if (modal == 'showDeleteTeamContentModal') {
                $scope.showDeleteTeamContentModal = !$scope.showDeleteTeamContentModal;
            }
        };
    }
})();
