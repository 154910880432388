(function() {
    'use strict';
    angular
        .module('serviceApp')
        .factory('clickAnywhereButHereService', [
            '_',
            '$document',
            function(_, $document) {
                var tracker = [];

                return function($scope, expr) {
                    var trackerItem = _.find(tracker, { expr: expr, scope: $scope });

                    if (trackerItem) {
                        return trackerItem;
                    }

                    var handler = function() {
                        $scope.$apply(expr);
                    };

                    $document.on('click', handler);

                    $scope.$on('$destroy', function() {
                        $document.off('click', handler);
                    });

                    trackerItem = { scope: $scope, expr: expr };
                    tracker.push(trackerItem);
                    return trackerItem;
                };
            }
        ])
        .directive('clickAnywhereButHere', [
            '$document',
            'clickAnywhereButHereService',
            function($document, clickAnywhereButHereService) {
                return {
                    restrict: 'A',
                    link: function(scope, elem, attr, ctrl) {
                        var handler = function(event) {
                            scope.$root.$emit('clickAnywhere', {
                                elem: elem
                            });
                            event.stopPropagation();
                        };

                        let clickAnywhereListener = scope.$root.$on('clickAnywhere', function(id, data) {
                            //Check to see if the item ID matches, or if the attributes says this is a parent
                            //container of another click anywhere listener.
                            if (data.elem[0].id !== elem[0].id && !attr.clickAnywhereButHereParent) {
                                scope.$apply(attr.clickAnywhereButHere);
                            }
                        });

                        elem.on('click', handler);

                        scope.$on('$destroy', function() {
                            elem.off('click', handler);
                            clickAnywhereListener();
                        });

                        return clickAnywhereButHereService(scope, attr.clickAnywhereButHere);
                    }
                };
            }
        ]);
})();
