'use strict';

import './index.scss';
import './definition-tag-input';

import template from './index.html';
import Controller from '../tags/controller';

angular.module('serviceApp').directive('definitionTags', () => ({
    restrict: 'E',
    scope: {
        definition: '=?'
    },
    template,
    controller: Controller
}));
