'use strict';

import template from './index.html';
import controller from './controller';

angular.module('serviceApp').component('addCardModal', {
    controller,
    template,
    bindings: {
        closeModal: '<',
        row: '<',
        position: '<',
        setCard: '<'
    }
});
