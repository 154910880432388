(function() {
    'use strict';

    angular.module('serviceApp').factory('appServiceNew', AppServiceNew);

    AppServiceNew.$inject = ['_', '$resource', '$q'];

    function AppServiceNew(_, $resource, $q) {
        var appUrl = '/api/app';
        var defaultParams = {};

        var appResourceActions = {
            getApps: {
                method: 'GET',
                isArray: true
            },
            getApp: {
                method: 'GET',
                url: appUrl + '/:appId'
            },
            createApp: {
                method: 'POST'
            },
            updateApp: {
                method: 'POST',
                url: appUrl + '/:appId'
            },
            resetApp: {
                method: 'PUT',
                url: appUrl + '/restoreGlobalAppSettings/:appId'
            },
            promoteApp: {
                method: 'POST',
                url: appUrl + '/:appId/promoteApp'
            },
            addAppToTeam: {
                method: 'POST',
                url: appUrl + '/addAppToTeam/:appId'
            },
            removeAppFromTeam: {
                method: 'POST',
                url: appUrl + '/removeAppFromTeam/:appId'
            },
            disableApp: {
                method: 'POST',
                url: appUrl + '/:appId'
            }
        };

        var appResource = $resource(appUrl, defaultParams, appResourceActions);

        var service = {
            getApps: getApps,
            getApp: getApp,
            createApp: createApp,
            updateApp: updateApp,
            promoteApp: promoteApp,
            resetApp: resetApp,
            addAppToTeam: addAppToTeam,
            removeAppFromTeam: removeAppFromTeam,
            disableApp: disableApp
        };

        return service;

        ////////////

        function getApps(domain, eventType, filter) {
            return $q.when(appResource.getApps({ domain, eventType, filter }).$promise);
        }

        function getApp(appId) {
            if (!appId) {
                return $q.reject({
                    msg: 'App id must be defined'
                });
            }

            return $q.when(
                appResource.getApp(
                    {
                        appId: appId
                    },
                    {}
                ).$promise
            );
        }

        function createApp(appId, policyName, urlPattern) {
            if (!appId) {
                return $q.reject({
                    msg: 'App id must be defined'
                });
            }

            if (!policyName) {
                return $q.reject({
                    msg: 'Policy name must be defined'
                });
            }

            if (!urlPattern) {
                return $q.reject({
                    msg: 'URL pattern must be defined'
                });
            }

            var body = {
                sourceAppId: appId,
                contentPolicyName: policyName,
                urlPattern: urlPattern
            };

            return $q.when(appResource.createApp({}, body).$promise);
        }

        function updateApp(app) {
            if (!app.id) {
                return $q.reject({
                    msg: 'App id must be defined'
                });
            }

            return $q.when(
                appResource.updateApp(
                    {
                        appId: app.id
                    },
                    app
                ).$promise
            );
        }

        function promoteApp(appId) {
            if (!appId) {
                return $q.reject({
                    msg: 'App id must be defined'
                });
            }

            return $q.when(
                appResource.promoteApp(
                    {
                        appId: appId
                    },
                    {}
                ).$promise
            );
        }

        function resetApp(appId) {
            if (!appId) {
                return $q.reject({
                    msg: 'App id must be defined'
                });
            }

            return $q.when(
                appResource.resetApp(
                    {
                        appId: appId
                    },
                    {}
                ).$promise
            );
        }

        function addAppToTeam(appId, appTitle) {
            if (!appId) {
                return $q.reject({
                    msg: 'App id must be defined'
                });
            }

            let body = {};
            if (appTitle) {
                body.appTitle = appTitle;
            }

            return $q.when(
                appResource.addAppToTeam(
                    {
                        appId: appId
                    },
                    body
                ).$promise
            );
        }

        function removeAppFromTeam(appId) {
            if (!appId) {
                return $q.reject({
                    msg: 'App id must be defined'
                });
            }

            return $q.when(
                appResource.removeAppFromTeam(
                    {
                        appId: appId
                    },
                    {}
                ).$promise
            );
        }

        function disableApp(appId) {
            if (!appId) {
                return $q.reject({
                    msg: 'App id must be defined'
                });
            }

            return $q.when(
                appResource.disableApp(
                    {
                        appId: appId
                    },
                    {
                        events: 'disable'
                    }
                ).$promise
            );
        }
    }
})();
