import { isValid, getPlaceholder } from '../../../utils/properties';

(function() {
    'use strict';

    angular.module('serviceApp').directive('cardIntegrationSummary', cardIntegrationSummary);

    function cardIntegrationSummary() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                user: '<',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/integrations/summary.html'),
            controller: CardIntegrationSummaryController
        };

        return directive;
    }

    CardIntegrationSummaryController.$inject = ['_', '$scope', '$rootScope'];

    function CardIntegrationSummaryController(_, $scope, $rootScope) {
        $scope.isValid = isValid.bind(this, $scope.contentType, $scope.propertyDisplayOptions);
        $scope.getPlaceholder = getPlaceholder;
        $scope.descriptors = {
            'salesforce.com': {
                description: 'Connect with Salesforce to gain insight on opportunities',
                body: {
                    intro:
                        'Connecting ' +
                        $rootScope.productName +
                        ' to the worlds more popular CRM analyzes your sales process to help you improve results.'
                },
                links: [
                    {
                        isExternal: true,
                        label: 'Instructions to setup Salesforce Connection',
                        url: `http://${$rootScope.productSupportHostName}/hc/en-us/articles/13345284702235-Getting-Started-With-the-Intelligence360-Platform-Salesforce-Integration`
                    }
                ]
            },
            'google.com': {
                description: 'Connect with Google Apps to sync your data',
                body: {
                    intro:
                        'The ' +
                        $rootScope.productName +
                        ' Google Apps integration allows you to analyzes your sales process to help you improve results.'
                },
                links: [
                    {
                        isExternal: true,
                        label: 'Instructions to setup Google Integration',
                        url: `http://${$rootScope.productSupportHostName}/hc/en-us/articles/13345239932955`
                    }
                ]
            },
            'office365.com': {
                description: 'Connect with Office 365 to sync your data',
                body: {
                    intro:
                        'The ' +
                        $rootScope.productName +
                        ' Office 365 integration allows you to analyzes your sales process to help you improve results.'
                },
                links: [
                    {
                        isExternal: true,
                        label: 'Instructions to setup Office 365 Integration',
                        url: `http://${$rootScope.productSupportHostName}/hc/en-us/articles/13345239890587`
                    }
                ]
            }
        };
    }
})();
