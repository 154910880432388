'use strict';
import { BaseFormWidgetController } from '../../scripts/utils/form-utils.js';

export default class FormTextAreaController extends BaseFormWidgetController {
    constructor(scope, rootScope, filter) {
        super(scope, rootScope, filter);
        this.scope.widgetType = _.get(this.formSchema, 'format.type', 'textAreaSmall');
        this.scope.rows = this.scope.widgetType === 'textAreaSmall' ? 4 : 12;
    }
}
