'use strict';

/**
 * Angular wrapper for our <ActivityTimeline /> react component.
 */

export default class ActivityTimeline {
    static get $inject() {
        return ['_', '$scope', '$rootScope', '$interpolate', 'interpretedContentFactory'];
    }

    constructor(_, $scope, $rootScope, $interpolate, interpretedContentFactory) {
        this._ = _;
        this.rootScope = $rootScope;
        this.scope = $scope;
        this.displayOptions = this.rootScope.displayOptions;
        this.interpolate = $interpolate;
        this.scope.interpreter = this.interpreter.bind(this);
        this.scope.interpretedContentFactory = interpretedContentFactory;
    }

    interpreter(content, path, asArray) {
        this.scope.content = content;
        var item;
        //if templated
        if (_.includes(path, '{{')) {
            var templater = this.interpolate(path);
            item = templater(this.scope);
        } else {
            item = _.get(this.scope.content, path);
        }

        return asArray ? _.castArray(item) : item;
    }

    $onChanges() {
        this.hooks = {
            interpreter: this.scope.interpreter,
            interpretedContentFactory: this.scope.interpretedContentFactory
        };

        this.data = {
            activity: _.get(this, 'activityData'),
            scrollContainerId: _.get(this, 'scrollContainerId'),
            displayOptions: this.displayOptions
        };
    }
}
