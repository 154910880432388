/**
 * onStringTemplateVar - a listener fire from quill when a variable is inserted
 * @param {String} data - data to insert
 * @returns {Object} - object with data for quill to insert the variable
 */
export const onStringTemplateVar = function(data) {
    // Check for a string value expecting 'operation_contentType_field | optionalDisplayLabel'
    // We'll format it correctly, and look up the right label for the field if it exists
    // Also possible, but not optimal, is the format of 'contentType__fieldDescriptionHere'
    // We'll deal with this by a prepend flag check
    const [stringValue, stringTransforms] = data.split('|');
    let label;

    // Before anything else, see if we can find a label for the variable in the available context.
    const entry = _(this.scope.contextBreakdown)
        // object is grouped by category; just get the list of vars for each category
        .values()
        // combine the lists
        .concat()
        // flatten to one array
        .flatten()
        // look for the var in question in the flattened array
        .find({ name: stringValue.trim() });
    // If we found an entry, use it.
    if (entry && entry.label) {
        return {
            varName: _.trim(stringValue),
            varLabel: entry.label,
            varTransforms: stringTransforms ? [_.trim(stringTransforms)] : []
        };
    }

    let opString = data.match(/.*_.*_.*/i);
    let prependedString = data.match(/.*__.*/i);
    if (opString || prependedString) {
        const prependTypeCheck = _.trim(data)
            .slice(4, 6)
            .includes('__');
        if (!prependTypeCheck) {
            const [op, contentType] = stringValue.split('_');

            // Remove the op and content type to find the property name
            let propertyNameArray = stringValue.split('_').slice(2);
            // camelCaseAllTheThings
            for (let i = 1; i < propertyNameArray.length; i++) {
                propertyNameArray[i] =
                    propertyNameArray[i].charAt(0).toUpperCase() + propertyNameArray[i].slice(1);
            }
            // Convert to expected type of String, remove commas from conversion
            const propertyName = propertyNameArray.toString().replaceAll(',', '');
            // If there is a contentType in the string, we should be able to look up the label
            if (contentType) {
                label = `${_.startCase(op)} ${this.filter('contentTypeLabel')(
                    contentType,
                    _.startCase(contentType)
                )} ${this.filter('propertyLabel')(propertyName, contentType)}`;
            }
            // If there is no contentType in the string, it should just be an operation like Count
            else {
                label = `${_.startCase(op)}`;
            }
            return {
                varName: _.trim(stringValue),
                varLabel: label,
                varTransforms: [_.trim(stringTransforms)]
            };
        } else {
            const [stringValue, stringTransform] = data.split('|');
            const [prependTypePrefix, prependFieldName] = data.split('__');
            let label = _.startCase(prependFieldName);
            return {
                varName: _.trim(stringValue),
                varLabel: label,
                varTransforms: [_.trim(stringTransform)]
            };
        }
    } else {
        // Use the custom property label if one exists.
        // This takes strings like 'content.opportunity.name' and extracts the property name
        // and content type properly, while also handling 'externalId' correctly.
        const [property, filter] = data.split('|');
        const [propertyName, contentType] = property
            .split('.')
            .slice(-2)
            .reverse();
        let label = this.filter('propertyLabel')(propertyName, contentType);
        if (contentType) {
            label = `${this.filter('contentTypeLabel')(contentType, _.startCase(contentType))} ${label}`;
        }
        return {
            varName: _.trim(property),
            varLabel: label,
            varTransforms: [_.trim(filter)]
        };
    }
};
