(function() {
    'use strict';

    angular.module('serviceApp').controller('MainCtrl', MainCtrl);

    MainCtrl.$inject = [
        '$rootScope',
        '$scope',
        '$window',
        '$log',
        'toastr',
        'userServiceNew',
        '$http',
        '$timeout',
        '$interval',
        '$cookies'
    ];

    function MainCtrl(
        $rootScope,
        $scope,
        $window,
        $log,
        toastr,
        userServiceNew,
        $http,
        $timeout,
        $interval,
        $cookies
    ) {
        $scope.isSearching = false;
        $scope.query = {};
        $scope.query.str = '';

        $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
            $rootScope.useHeader = true;
            $scope.isSearching = false;
        });

        $rootScope.$on('toggleSearch', function(event, data) {
            if (_.has(data, 'toggle')) {
                $scope.isSearching = data.toggle;
            } else {
                $scope.isSearching = !$scope.isSearching;
            }

            if (_.has(data, 'type')) {
                $scope.searchType = data.type;
            }
        });

        $scope.hideSearch = function() {
            $scope.isSearching = false;
        };

        $rootScope.board = {};

        $scope.closeDrawer = function() {
            if ($rootScope.rightDrawerOpen) {
                $rootScope.$emit('rightDrawer:close');
            }
        };

        $scope.isDesktopClient = $window.navigator.userAgent.indexOf('nexd-desktop-client') === 0;
        var visibilitychanged = function() {
            var isHidden =
                document.hidden || document.webkitHidden || document.mozHidden || document.msHidden;
            if (!isHidden) {
                $log.debug('Browser is now visible');
                $rootScope.$emit('isVisible', 'true');
            }
        };

        //Stagger the reload times to prevent overloading the backend with updates at the same time
        const minTime = 1; //Minimum reload time in minutes. Minimum of 1 to exceed deploy times
        const maxTime = 30; //Maximum reload time in minutes.

        // set current app version and poll for future releases
        const getLatestAppVersion = () => {
            return $http.get('/version.json').then(({ data }) => {
                const { version } = data;
                return version;
            });
        };

        //this get called on a page refresh and will pick up new version changes
        getLatestAppVersion()
            .then(version => {
                $cookies.put('appVersion', version);
            })
            .catch(err => {
                $log.error('An error occurred checking for updated code.', err);
            });

        //Set and interval to check the current version of the web application, if it's out of date schedule a page refresh
        $interval(() => {
            getLatestAppVersion()
                .then(latestVersion => {
                    //Check the version.
                    if ($cookies.get('appVersion') !== latestVersion) {
                        //Find a time between the minimum wait time and the max
                        const randTime = Math.floor(
                            (Math.random() * (maxTime - minTime + 1) + minTime) * 60000
                        );

                        toastr.success($rootScope.productName + ' has been updated - reloading!', '');
                        $timeout(() => {
                            //Don't do a reload if the user is currently editing
                            if (!$rootScope.leftDrawerOpen && !$rootScope.rightDrawerOpen) {
                                $window.location.reload(true);
                            }
                        }, randTime); //Random time between minTime and maxTime.
                    }
                })
                .catch(err => {
                    $log.error('An error occurred checking for updated code.', err);
                });
        }, maxTime * 2 * 60000); //Double maxTime to prevent doubling the work

        document.addEventListener('visibilitychange', visibilitychanged);
        document.addEventListener('webkitvisibilitychange', visibilitychanged);
    }
})();
