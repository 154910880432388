(function() {
    'use strict';

    angular.module('serviceApp').controller('LoginCtrl', LoginCtrl);

    LoginCtrl.$inject = [
        '$cookies',
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        'userServiceNew',
        'hasAuth',
        '$window',
        '$location',
        'toastr'
    ];
    function LoginCtrl(
        $cookies,
        $scope,
        $rootScope,
        $state,
        $stateParams,
        userServiceNew,
        hasAuth,
        $window,
        $location,
        toastr
    ) {
        $rootScope.pageTitle = 'Login';
        $rootScope.useHeader = false;

        $scope.credentials = {
            username: null,
            password: null
        };

        $scope.readyLogin = false;
        $scope.isAdmin = false;

        if (hasAuth) {
            // Verify the authentication is valid by retrieving the user data before
            // claiming success and moving on.
            userServiceNew.getUser().then(function(user) {
                $rootScope.$emit('login');
                $state.go('home.main');
            });
        }

        //Check for SAML, GUM, or other redirect
        userServiceNew.getLoginPage().then(
            function(loginPage) {
                setLogin(loginPage);
            },
            function(err) {
                toastr.error('Error Finding Login Page');
            }
        );

        $scope.login = function() {
            var data = {
                username: $scope.credentials.username,
                password: $scope.credentials.password
            };

            userServiceNew.login(data).then(
                function(token) {
                    // If we got passed redirect data in our state parameters, use that.
                    const redirectState = $stateParams.redirectState;
                    const redirectParams = $stateParams.redirectParams;

                    const targetPath = $state.href(redirectState, redirectParams);

                    // Preserve if we're not loaded at `/`, eg when in `/embedded` mode.
                    const base = $window.location.pathname;

                    const domain = $cookies.get('serviceDomain');
                    const redirectUrl = `${$location.protocol()}://${domain ||
                        $location.host()}${base}${targetPath}`;

                    $window.location.href = redirectUrl;

                    // if we're already on the correct domain, only the hash will change which won't
                    // do a full page load. Emit the event that other components outside the ui-router
                    // resolvers can use to update their state.
                    if ($window.location.hostname === domain) {
                        $rootScope.$emit('login');
                    }
                },
                function(err) {
                    $scope.credentials.password = '';
                    $scope.hasError = true;
                }
            );
        };

        $scope.submitEmail = function() {
            //Check for SAML Redirect
            userServiceNew.getLoginPage($scope.credentials.username).then(
                function(loginPage) {
                    if (setLogin(loginPage)) {
                        $scope.readyLogin = true;
                    }
                },
                function(err) {
                    $scope.hasError = true;
                }
            );
        };

        $scope.closeError = function() {
            $scope.hasError = false;
        };

        $scope.backOut = function() {
            $scope.credentials.username = null;
            $scope.credentials.password = null;
            $scope.readyLogin = false;
        };

        $scope.forgotPassword = function() {
            return $state.go('core.forgotpass');
        };

        $scope.setAdmin = function(value) {
            $scope.readyLogin = false;
            $scope.credentials.username = null;
            $scope.credentials.password = null;
            $scope.isAdmin = value;
        };

        function setLogin(loginPage) {
            if (loginPage.url && loginPage.url.indexOf('/core/login') === -1) {
                if (loginPage.hostname) {
                    var protocol = $location.protocol();
                    $window.location.href = protocol + '://' + loginPage.hostname + loginPage.url;
                } else {
                    $window.location.href = loginPage.url;
                }
                return false;
            } else {
                $scope.showLogin = true;
            }
            $scope.hasError = false;
            return true;
        }
    }
})();
