const moment = require('moment-timezone');
import { parseDateString } from '../utils/date-utils';
(function() {
    'use strict';

    angular.module('serviceApp').filter('mediumDate', mediumDate);

    mediumDate.$inject = ['_', '$filter', '$rootScope'];

    function mediumDate(_, $filter, $rootScope) {
        var filter = function(value) {
            if (!value || _.isNil(value)) {
                return 'N/A';
            }

            // This filter does not output a time component, just a date.
            // We want such dates to be relative to the team's time zone.
            const timezone = _.get($rootScope, 'timezone', 'UTC');
            const date = parseDateString(value, timezone);
            return _.isNil(date) ? value : date.format('MMM Do, YYYY');
        };

        return filter;
    }
})();
