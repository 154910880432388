'use strict';

import _ from 'lodash';

/**
 * This is the base navigation class that does the common work for the menu nav and side nav.
 *
 * @export
 * @class BaseNavClass
 */
export default class BaseNavClass {
    static get $inject() {
        return [
            '_',
            '$window',
            '$scope',
            '$rootScope',
            '$state',
            'userServiceNew',
            '$location',
            '$interpolate',
            'themeService',
            'appSettingsService'
        ];
    }
    /**
     *Creates an instance of BaseNavClass.
     * @param {*} $window
     * @param {*} $scope
     * @param {*} $rootScope
     * @param {*} $state
     * @param {*} userServiceNew
     * @param {*} $location
     * @param {*} $interpolate
     * @param {*} themeService
     * @param {*} appSettingsService
     * @memberof BaseNavClass
     */
    constructor(
        _,
        $window,
        $scope,
        $rootScope,
        $state,
        userServiceNew,
        $location,
        $interpolate,
        themeService,
        appSettingsService
    ) {
        this.scope = $scope;
        this.rootScope = $rootScope;
        this.interpolate = $interpolate;
        this.userServiceNew = userServiceNew;
        this.state = $state;
        this.window = $window;
        this.location = $location;
        this.scope.productName = this.rootScope.productName;
        this.scope.isExternal = $rootScope.isExternal;
        this.scope.darkMode = themeService.isDarkMode;
        this.appSettingsService = appSettingsService;
        this.themeService = themeService;

        Object.assign(this.scope, {
            parseTitle: this.parseTitle.bind(this),
            showDrawer: this.showDrawer.bind(this),
            logout: this.logout.bind(this),
            toggleMenu: this.toggleMenu.bind(this),
            goTo: this.goTo.bind(this),
            openSupport: this.openSupport.bind(this),
            openTraining: this.openTraining.bind(this),
            toggleDarkMode: this.toggleDarkMode.bind(this)
        });

        this.scope.showMenu = false;
        this.scope.initialized = false;
        this.scope.navigation = _.cloneDeep(_.get(this.scope, 'user.navigation.primary'));

        // We want to show the app switcher if:
        //  1. The tenant has been linked to our core solution, and
        //  2. They actually have something to switch to.
        const userSolutions = _.get(this.scope, 'user.coreAuth.solutions', []);
        const numSolutions = _.size(_.without(userSolutions, 'Intelligence360'));
        this.scope.showSolutions = _.has(this.scope, 'team.coreAuth.tenantId') && numSolutions > 0;

        const stateChangeListener = $rootScope.$on('$stateChangeStart', function(
            event,
            toState,
            toParams,
            fromState,
            fromParams
        ) {
            $scope.currentState = toState.name;
        });

        $scope.$on('$destroy', () => {
            stateChangeListener();
        });
    }

    /**
     * This parses the menu item title
     *
     * @param {*} title
     * @returns
     * @memberof BaseNavClass
     */
    parseTitle(title) {
        return this.interpolate(title)(this.scope);
    }

    /**
     * Trigger a left-hand drawer to open by name. If the specified panel is currently
     * open then it's toggled closed.
     *
     * @param {String} panel The name of the panel to open in the left drawer, currently
     *  'searchEverything' or 'instantActions'
     */
    showDrawer(panel) {
        // Allow for using control to toggle it closed.
        if (this.rootScope.leftDrawerOpen && this.scope.openDrawer == panel) {
            this.scope.openDrawer = undefined;
            this.rootScope.$emit('leftDrawer:close');
            return;
        }

        if (this.scope.openDrawer !== panel) {
            this.rootScope.$emit('leftDrawer:close');
        }

        this.scope.openDrawer = panel;
        this.rootScope.$emit('leftDrawer:open', {
            layout: panel,
            board: this.rootScope.board,
            external: this.rootScope.isExternal
        });
    }

    /**
     * This logs the user out of the product
     *
     * @memberof BaseNavClass
     */
    logout() {
        this.userServiceNew.logOut().then(() => {
            this.rootScope.$emit('logout');
            this.state.go('core.login');
        });
    }
    /**
     * This formats the domain
     *
     * @param {*} input
     * @returns
     * @memberof BaseNavClass
     */
    getDomain(input) {
        var domain = _(input)
            .split('.')
            .takeRight(2)
            .value();
        return _.join(domain, '.');
    }

    /**
     * This toggle the menu from showing and not showing
     *
     * @memberof BaseNavClass
     */
    toggleMenu() {
        this.scope.showMenu = !this.scope.showMenu;
    }

    /**
     * This send the navigation to the desired state
     *
     * @param {*} stateName
     * @param {*} extras
     * @memberof BaseNavClass
     */
    goTo(stateName, extras) {
        if (stateName === 'profile') {
            this.rootScope.board.shortcode = stateName;
        }
        this.scope.query = {};
        this.state.go(stateName, extras);
    }

    /**
     * This opens the product support page
     *
     * @memberof BaseNavClass
     */
    openSupport() {
        this.window.open('http://' + this.rootScope.productSupportHelp, '_blank');
    }

    /**
     * This opens the product training certification platform
     *
     * @memberof BaseNavClass
     */
    openTraining() {
        this.window.open('http://' + this.rootScope.productTrainingHostname, '_blank');
    }

    toggleDarkMode() {
        this.scope.darkMode = !this.scope.darkMode;
        this.themeService.toggleMode(this.scope.darkMode);
    }
}
