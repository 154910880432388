'use strict';
import _ from 'lodash';
import { getClearbitUrl } from '../action-item-utils';

export default class ActionContentIcon {
    static get $inject() {
        return ['$scope', '$rootScope', '$interpolate', 'contentTypeService'];
    }

    constructor(scope, rootScope, interpolate, contentTypeService) {
        this.scope = scope;
        this.rootScope = rootScope;
        this.scope.iconInit = false; // icon not ready
        this.interpolate = interpolate;
        this.contentTypeService = contentTypeService;

        this.scope.boardId = _.get(this.scope, 'content.boardId');
        this.scope.showContentIcon = !!(
            _.get(this.scope, 'content.contentType') !== 'team' && this.scope.boardId
        );

        this.contentType = _.get(this.scope, 'content.contentType');
        this.contentDisplayOptions = _.get(this.rootScope.displayOptions, [this.contentType]);

        // Get the label for this content type and set it on the scope
        // so interpolate can use it if needed
        this.scope.contentTypeLabel = _.get(
            this.contentDisplayOptions,
            'label',
            _.startCase(this.contentType)
        );
        // init icon data
        this.setIconUrls();
        this.setToolTipData();
        this.scope.iconInit = true;
        this.scope.size = this.scope.size ? this.scope.size : 25;
    }

    /**
     * setToolTipData - generate the tooltip hover data
     */
    setToolTipData() {
        const titleValue = _.get(this.contentDisplayOptions, 'title.value', "{{'content'}}");
        this.scope.toolTipData = `Go to ${this.interpolate(titleValue)(this.scope)}`;
    }

    /**
     * setIconUrls - set scope variables for icon related information
     */
    setIconUrls() {
        // set default icon state to the logo and url
        const defaultIconValue = _.get(
            this.contentDisplayOptions,
            'icon.default',
            getClearbitUrl(this.scope.$root.productPrimaryHostname)
        );

        // get icon value off the content display options
        const iconValue = _.get(
            this.contentDisplayOptions,
            'icon.value',
            _.get(this.contentDisplayOptions, 'icon.default')
        );

        this.scope.icon = iconValue;

        // if no icon data kick to url and use default icon
        if (!this.scope.icon) {
            this.scope.iconType = 'url';
            return;
        }

        // always interpolate so the transforms are resolved
        this.scope.icon = this.interpolate(this.scope.icon)(this.scope);
        this.scope.defaultIcon = this.interpolate(defaultIconValue)(this.scope);

        this.scope.iconType = this.getTypeFromIcon(this.scope.icon);
        this.scope.defaultIconType = this.getTypeFromIcon(this.scope.defaultIcon);

        if (this.scope.iconType === 'url') {
            this.scope.icon = getClearbitUrl(this.scope.icon);
        }
    }

    getTypeFromIcon(icon) {
        if (_.startsWith(icon, 'icon-')) {
            return 'internal';
        } else if (_.startsWith(icon, 'initials')) {
            return 'initials';
        } else {
            return 'url';
        }
    }
}
