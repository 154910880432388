'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('cardAddToDashboardModal', {
    controller,
    template,
    bindings: {
        hideModal: '<',
        reportId: '<',
        contentType: '<',
        cardName: '<',
        cardTitle: '<'
    }
});
