'use strict';

export default class TeamIntegrationStatusController {
    static get $inject() {
        return ['_', '$scope'];
    }

    constructor(_, $scope) {
        const CARD_NAME = _.get($scope, 'card.name');

        //Listen to search changes to the integration users list
        const integrationUserWatcher = $scope.$watch('card.properties.integrationUsers', function() {
            const integrationUsers = _.get($scope, `card.properties.integrationUsers`);
            if (_.isArray(integrationUsers)) {
                $scope.integrationUsers = {
                    offset: 0,
                    count: integrationUsers.length,
                    total: integrationUsers.length,
                    results: integrationUsers
                };
            } else {
                $scope.integrationUsers = integrationUsers;
            }
        });

        $scope.pageBack = () => {
            const cardSize = _.get($scope, 'card.properties.integrationUsers.size');
            const currentOffset =
                _.get($scope, `facets.card.${CARD_NAME}.offset`) || $scope.integrationUsers.offset;
            const newOffset = Math.max(currentOffset - cardSize, 0);

            _.set($scope, `facets.card.${CARD_NAME}.offset`, newOffset);

            $scope.getCardHitch({ data: { cardName: CARD_NAME, facets: _.get($scope, 'facets') } });
        };

        $scope.pageNext = () => {
            const cardSize = _.get($scope, 'card.properties.integrationUsers.size');
            const currentOffset =
                _.get($scope, `facets.card.${CARD_NAME}.offset`) || $scope.integrationUsers.offset;
            const newOffset = currentOffset + cardSize;

            _.set($scope, `facets.card.${CARD_NAME}.offset`, newOffset);

            $scope.getCardHitch({ data: { cardName: CARD_NAME, facets: _.get($scope, 'facets') } });
        };

        $scope.itemRefreshCallback = () => {
            $scope.getCardHitch({ data: { cardName: CARD_NAME } });
        };

        $scope.toggleAddEditUserModal = () => {
            $scope.addEditUserModalIsOpen = !$scope.addEditUserModalIsOpen;
        };

        const integrationType = _.get($scope, 'card.properties.integration.integrationType');
        $scope.useServiceAccount = _.get(
            $scope,
            `card.properties.integration.${integrationType}.useServiceAccount`
        );

        $scope.openEditor = (integrationUserItem, newIndex) => {
            if (_.isNumber($scope.selectedIndex)) {
                $scope.closeEditor();
            } else {
                $scope.selectedIndex = newIndex;
                integrationUserItem.primaryUserId = $scope.card.properties.primaryUserId;
                integrationUserItem.pollPrimaryOnly = _.get(
                    $scope.card.properties,
                    'integration.pollPrimaryOnly'
                );

                $scope.$root.$emit('rightDrawer:open', {
                    layout: 'userIntegrationStatus',
                    api: $scope.api,
                    integrationUser: _.cloneDeep(integrationUserItem)
                });
            }
        };

        $scope.closeEditor = () => {
            $scope.$root.$emit('rightDrawer:close');
        };

        let rightDrawerListener = $scope.$root.$on('rightDrawer:close', () => {
            $scope.selectedIndex = undefined;
        });

        $scope.$on('$destroy', () => {
            integrationUserWatcher();
            rightDrawerListener();
        });
    }
}
