(function() {
    'use strict';
    angular.module('serviceApp').directive('leftDrawer', leftDrawer);
    function leftDrawer() {
        var directive = {
            restrict: 'E',
            scope: {},
            template: require('views/tmpl/partials/leftDrawer.html'),
            controller: LeftDrawerController
        };

        return directive;
    }

    LeftDrawerController.$inject = ['_', '$scope', '$rootScope', 'contentTypeService'];

    function LeftDrawerController(_, $scope, $rootScope, contentTypeService) {
        $rootScope.leftDrawerOpen = false;

        $scope.openDrawer = (event, data) => {
            // Make sure we have relatively up-to-date display options set before we update
            // the scope, which causes the content to be rendered.
            contentTypeService.getContentDisplayOptions({ useCache: true }).then(displayOptions => {
                $rootScope.displayOptions = displayOptions;

                $scope.drawerData = data;
                $rootScope.leftDrawerOpen = true;
            });
        };

        $scope.closeDrawer = (event, data) => {
            $rootScope.leftDrawerOpen = false;
            // call scope.apply if called from external component
            if (_.get(data, 'isApply')) {
                $scope.$apply();
            }
        };
        const listeners = [
            $rootScope.$on('leftDrawer:open', $scope.openDrawer),
            $rootScope.$on('leftDrawer:close', $scope.closeDrawer),
            $rootScope.$on('$stateChangeStart', $scope.closeDrawer)
        ];
        $scope.$on('$destroy', () => _.each(listeners, listenerCleanUp => listenerCleanUp()));
    }
})();
