'use strict';
import _ from 'lodash';
const { parse } = require('csv-parse/sync');
const Papa = require('papaparse');

export const shouldHideProperty = ({ attemptHidden, modelValue, required }) => {
    // if the property is not configured to be hidden then don't ever hide it
    if (!attemptHidden) {
        return false;
    }

    // if the property is hidden and required but empty show it anyway
    if (_.isEmpty(modelValue) && required) {
        return false;
    }

    return true;
};

/**
 * getDefaultFormModel - if a form item has a 'default' property seed it to the model
 * @param {Object} eventTrigger
 * @returns {Object} the default model
 */
export const getDefaultFormModel = eventTrigger => {
    const model = {};
    const schemaProperties = _.get(eventTrigger, 'payload.schema.properties');
    _.forEach(schemaProperties, (schemaItem, schemaItemName) => {
        const defaultValue = _.get(schemaItem, 'default');
        if (!_.isNil(defaultValue)) {
            model[schemaItemName] = defaultValue;
        }
    });

    return model;
};

/**
 * validatePayload - ensure that custom directives validation is enforced
 * this function is needed due to the validation for custom form items
 * in angular form schema having weak documentation and apparent bugs
 * @param {Object} args - args
 * @param {Object} args.model - object containing the resolved values from the form
 * @param {Object} args.schemaForm - angular form schema form to compare the payload again
 * @param {Object} args.angularForm - optional angular form input to build the error form off of
 * @returns {Object} returns an object with plain errors and an angular form object with error data
 */
export const validatePayload = ({ model, schemaForm, angularForm = {} }) => {
    // enforce $$controls exists and is an error for use later
    if (!Array.isArray(_.get(angularForm, '$$controls'))) {
        _.set(angularForm, '$$controls', []);
    }

    // remove previous validation we added here
    angularForm.$$controls = _.reject(angularForm.$$controls, '_custom');

    const output = _.reduce(
        schemaForm,
        (accumulator, value, key) => {
            const title = _.get(value, 'title', _.get(value, 'placeholder'));
            const type = _.get(value, 'format.type');
            const maxInputLength = _.get(value, 'format.maxInputLength', -1);
            const modelValue = _.get(model, [key]);

            const modelIsUndefined = _.isUndefined(modelValue);
            const modelIsEmptyArray = Array.isArray(modelValue) && _.isEmpty(modelValue);

            // only using custom validation on our type-ahead widgets for now
            if (type === 'type-ahead-single' || type === 'type-ahead') {
                if (value.required) {
                    if (modelIsUndefined || modelIsEmptyArray) {
                        // set plain errors in an easy to inspect object
                        _.set(accumulator, ['errors', key], `${title} is required`);
                        // set error in form namespace so angular can handle the validation from here
                        accumulator.form.$$controls.push({
                            _custom: true,
                            $error: {
                                required: true
                            },
                            $$attr: {
                                formKey: key,
                                placeholder: title
                            }
                        });
                    }
                }
            } else if (maxInputLength !== -1 && !_.isNull(maxInputLength)) {
                if (_.size(modelValue) > maxInputLength) {
                    // set plain errors in an easy to inspect object
                    _.set(
                        accumulator,
                        ['errors', key],
                        `${title} exceeds max length of ${maxInputLength} by ${_.size(modelValue) -
                            maxInputLength}`
                    );
                    // set error in form namespace so angular can handle the validation from here
                    accumulator.form.$$controls.push({
                        _custom: true,
                        $error: {
                            maxlength: true
                        },
                        $$attr: {
                            formKey: key,
                            placeholder: title
                        }
                    });
                }
            }

            return accumulator;
        },
        { errors: {}, form: angularForm }
    );

    const hasError = !!_.find(
        _.get(output, 'form.$$controls'),
        control => !_.isEmpty(_.get(control, '$error'))
    );

    // if we found errors update the validation values of the form
    if (hasError) {
        output.form.$valid = false;
        output.form.$invalid = true;
    } else {
        output.form.$valid = true;
        output.form.$invalid = false;
    }

    return output;
};

export class BaseFormWidgetController {
    constructor(scope, rootScope, filter) {
        this.scope = scope;
        this.rootScope = rootScope;
        this.filter = filter;

        // init
        this.form = _.get(this.scope, 'form', {});
        this.formSchema = _.get(this.form, 'schema', {});

        this.name = _.get(this.formSchema, 'name');
        this.formKey = _.get(this.form, 'key[0]');
        this.scope.required = _.get(this.form, 'required', false);
        this.scope.readonly = _.get(this.form, 'readonly', false);
        this.scope.readonlyReason = _.get(this.formSchema, 'readonlyReason');
        this.scope.paramountRequisite = _.get(this.formSchema, 'paramountRequisite');
        this.scope.removable = _.get(this.formSchema, 'removable', true);
        this.attemptHidden = _.get(this.formSchema, 'format.hidden', false);
        this.scope.wrapPliable = _.get(this.formSchema, 'wrapPliable', false);
        this.scope.valueProperty = _.get(this.formSchema, 'format.valueProperty');
        this.scope.requiredDefault = _.get(this.formSchema, 'requiredDefault', false);
        this.scope.maxLength = _.get(this.formSchema, 'format.maxInputLength', -1);

        this.scope.isMobile = _.get(this.rootScope, 'isMobile');
        this.scope.showTitle = _.get(this.formSchema, 'showTitle', true);
        this.scope.isDark = _.get(this.scope, 'form.ngModelOptions.isDark', false);

        this.scope.hideProperty = shouldHideProperty({
            attemptHidden: this.attemptHidden,
            modelValue: this.scope.formModel,
            required: this.scope.required
        });

        // If we have a property name associated with this input, and there's a custom label
        // for that property, use that label as the form input title.
        // Otherwise use the default title.
        let { propertyName, contentType } = this.formSchema;
        if ((!propertyName || !contentType) && this.formSchema.name) {
            // This takes strings like 'content.opportunity.name' and extracts the property name
            // and content type properly, while also handling strings like 'externalId' correctly.
            [propertyName, contentType] = this.formSchema.name
                .split('.')
                .slice(-2)
                .reverse();
        }

        this.scope.title = this.filter('propertyLabel')(
            propertyName,
            contentType,
            this.formSchema.title || this.formSchema.name
        );

        // If value is not required, then add (Optional) the placeholder.
        const optional = !this.scope.required ? ' (optional)' : '';
        this.scope.placeholder = _.get(
            this.formSchema,
            'placeholder',
            _.startCase(
                `${contentType ? filter('contentTypeLabel')(contentType) + ' ' : ''}${this.scope.title}`
            )
        );
        this.scope.placeholder = `${this.scope.placeholder}${optional}`;
    }

    // angular dependency injector
    static get $inject() {
        return ['$scope', '$rootScope', '$filter'];
    }
}

/**
 * convertModelToCurrentPayload - convert a payload object to the correct "current payload" object format
 * this take {amount: 100} and converts it to {'opportunity.amount': 100}
 * @param {Object} model - the current state of the form
 * @param {Object} eventTrigger - the event trigger the form was generated from
 */
export const convertModelToCurrentPayload = (model, eventTrigger) => {
    const formSchema = _.get(eventTrigger, 'payload.schema.properties');
    return _.reduce(
        formSchema,
        (accumulator, formItem = {}, key) => {
            const modelValue = _.get(model, [key]);
            if (!_.isNil(modelValue)) {
                // for supplemental config formItem.name does not exist so use key
                _.set(accumulator, [formItem.name || key], modelValue);
            }
            return accumulator;
        },
        {}
    );
};

/**
 * createContextBreakdown - groups properties for template string builder
 * @param {Array} properties - array of property to group
 * @returns {Object} grouped properties where key is the group and values are the grouped data
 */
export const createContextBreakdown = (_properties = [], mapStrings = false) => {
    const properties = mapStrings
        ? _.map(_properties, item => {
              if (_.isString(item)) {
                  item = { name: item, label: _.startCase(item) };
              }
              return item;
          })
        : _properties;

    let nested = _.groupBy(properties, ({ name, groupKey }) =>
        _(groupKey || name) // if  groupKey exists group on that instead
            .split('.')
            .first()
    );

    return (
        _(nested)
            // Break the object into key, val pairs to make it easier to sort.
            .toPairs()
            .sortBy([
                // First sort all of the groups with only one item in them to the top,
                // since we'll display those without a group heading.
                ([key, group]) => (_.size(group) <= 1 && !_.first(group).groupKey ? 0 : 1),
                // Next sort all content properties above non-content properties.
                ([key]) => (key === 'content' ? 0 : 1),
                // Then sort by the first item in the pair (the key), which is index 0 in the pair array.
                0
            ])
            // Transform back into an object.
            .fromPairs()
            .value()
    );
};

/**
 * @param {String} data, text version of the csv file data, including header
 * @param {Object} options, additional options that can be passed into parse function
 * @param {Boolean} options.columns, if true, use first row as column name
 * @param {String} options.delimiter, the delimiter that used to parse the data, default to comma
 * @returns {Array}, array of objects where each object is key/values pairs for each row in csv
 */
export const csvToJson = (data, options = {}) => {
    return parse(data, {
        columns: _.get(options, 'columns', true),
        delimiter: _.get(options, 'delimiter', ','),
        skip_empty_lines: true,
        trim: true
    });
};

/**
 * @param {Array} data - An array of JSON objects
 * @param {Object} options - Options for unparsing the CSV data. Some options are provided for defaults.
 *          See PapaParse documentation for all available options: https://www.papaparse.com/docs#json-to-csv
 * @param {Array} options.columns - If `data` is an array of objects this option can be used to manually
 *          specify the keys (columns) you expect in the objects. If not set the keys of the first objects
 *          are used as column.
 * @param {String} options.delimiter - The delimiting character. Multi-character delimiters are supported.
 * @param {String} options.newline - The character used to determine newline sequence.
 * @param {Boolean} options.skipEmptyLines - If true, lines that are completely empty (those which evaluate
 *          to an empty string) will be skipped. If set to 'greedy', lines that don't have any content (those
 *          which have only whitespace after parsing) will also be skipped.
 * @param {Boolean} options.escapeFormulae - If true, field values that begin with =, +, -, @, \t, or \r, will
 *          be prepended with a ' to defend against injection attacks, because Excel and LibreOffice will
 *          automatically parse such cells as formulae. You can override those values by setting this option to a
 *          regular expression
 * @returns {String} The JSON converted to a CSV string
 */
export const jsonToCsv = (data, options = {}) => {
    _.defaults(options, {
        columns: null,
        delimiter: ',',
        newline: '\n',
        skipEmptyLines: true,
        escapeFormulae: true
    });
    return Papa.unparse(data, options);
};
