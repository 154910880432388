(function() {
    'use strict';

    angular.module('serviceApp').factory('contentTypeService', ContentTypeService);

    ContentTypeService.$inject = ['_', '$resource', '$q', 'moment', 'storageService', '$filter'];

    function ContentTypeService(_, $resource, $q, moment, storageService, $filter) {
        var contentTypesUrl = '/api/content-type';
        var defaultParams = {};

        var contentTypeActions = {
            getContentTypes: {
                method: 'GET',
                url: contentTypesUrl
            },
            updateContentType: {
                method: 'POST',
                url: contentTypesUrl,
                isArray: true
            },
            getContentDisplayOptions: {
                method: 'GET',
                url: `${contentTypesUrl}/display-options`
            }
        };

        var contentTypeResource = $resource(contentTypesUrl, defaultParams, contentTypeActions);

        var service = {
            getContentTypes: getContentTypes,
            updateContentType: updateContentType,
            getEnabledProperties: getEnabledProperties,
            getWriteableProperties: getWriteableProperties,
            getCachedContentDisplayOptions: getCachedContentDisplayOptions,
            getContentDisplayOptions: getContentDisplayOptions,
            groupProperties: groupProperties,
            getContentTypePrettyName
        };

        return service;

        ////////////

        /**
         * Get data about one or more content types.
         * @param {String=} appId If provided, only get content type data for the matching app.
         * @param {String=} policyName If provided, only get content type data for the matching policy.
         * @param {String=} contentType If provided, only get content type data for the content type.
         * @param {Array=} integrationType If provided, only get content type data for an app with
         *                                 one of the matching integration types.  Note that this will
         *                                 translate to duplicate querystring keys if the array has more
         *                                 than one entry, which the API provider is expected to hydrate
         *                                 back to an array.
         * @param {Boolean=} useCache If true, attempt to load content type data from the cache.
         */
        function getContentTypes({
            appId,
            appName,
            policyName,
            contentType,
            integrationType,
            useCache,
            includeEmbeddedSchema
        }) {
            var deferred = $q.defer();

            if (useCache) {
                const cachedData = storageService.get(`content-type-${contentType}`, 'local');
                if (cachedData) {
                    deferred.resolve(cachedData);
                }
            } else {
                contentTypeResource
                    .getContentTypes({
                        appName,
                        appId,
                        integrationType,
                        contentType,
                        includeEmbeddedSchema,
                        policyName
                    })
                    .$promise.then(
                        freshData => {
                            storageService.set(
                                `content-type-${contentType}`,
                                freshData.toJSON(),
                                moment.duration(2, 'minutes').asMilliseconds(),
                                'local'
                            );

                            deferred.resolve(freshData.toJSON());
                        },
                        err => {
                            deferred.reject(err);
                        }
                    );
            }

            return deferred.promise;
        }

        function updateContentType(contentType) {
            if (!contentType) {
                return $q.reject({
                    msg: 'content must be defined'
                });
            }

            storageService.delete(`content-display-options`, 'session');
            storageService.delete(`content-type-${contentType}`, 'local');

            return $q.when(contentTypeResource.updateContentType({}, contentType).$promise);
        }

        // get content display option default to using the cached version
        function getContentDisplayOptions({ useCache = true } = {}) {
            var deferred = $q.defer();

            //Check to see if we should try and use the cache AND that there is actually cached data in the session, otherwise fetch the display options
            const cachedData = getCachedContentDisplayOptions();
            if (useCache && cachedData) {
                deferred.resolve(cachedData);
            } else {
                contentTypeResource.getContentDisplayOptions().$promise.then(
                    freshData => {
                        if (freshData) {
                            storageService.set(`content-display-options`, freshData, null, 'session');
                        }

                        deferred.resolve(freshData);
                    },
                    err => {
                        deferred.reject(err);
                    }
                );
            }

            return deferred.promise;
        }

        function getCachedContentDisplayOptions() {
            return storageService.get(`content-display-options`, 'session');
        }

        function getWriteableProperties(schema) {
            return getEnabledProperties(schema, true);
        }

        function getEnabledProperties(schema, writeableOnly) {
            return _(schema)
                .map((value, key) => {
                    const name = _.get(value, 'name');
                    let extendedName = _.clone(name);
                    const extended = _.get(value, 'extends');

                    if (extended) {
                        const propertyParts = _.split(extendedName, '.');
                        if (_.size(propertyParts) > 1 && !_.includes(propertyParts, extended)) {
                            extendedName = `${extended}.${extendedName}`;
                        }
                    }

                    if (!value.enabled) {
                        return;
                    }

                    if (writeableOnly && !value.writeable) {
                        return;
                    }

                    const propertyName = _.last(_.split(name, '.'));
                    value.extendedName = extendedName;
                    value.propertyDisplayName = $filter('propertyLabel')(
                        propertyName,
                        value.contentType,
                        _.startCase(propertyName)
                    );

                    //safety against schema properties colliding with prompt formObjectSchema related properties
                    _.unset(value, 'default');
                    _.unset(value, 'enum');

                    return value;
                })
                .compact()
                .sortBy('name')
                .value();
        }

        /**
         *
         * Group data by property tags.
         *
         * Given an object of key/value pairs, where the keys are property names, this method
         * will find each property's tags (if any) and build an object whose keys are tag names,
         * and values are subsets of the input object made of just the properties that have that
         * tag.
         *
         * @param {object} args
         * @param {object} args.properties - The property data (key/value pairs) to group together.
         * @param {string|array} args.contentType - The name or names of the content types that the input object's properties belong to.
         * @param {object} args.displayOptions - The display options for the content type, as returned by getContentDisplayOptions()
         * @param {string=} args.ungroupedLabel - If provided, this label will be used in place of "_ungrouped" for the group of untagged properties.
         *
         * @example
         * See test for example.
         */
        function groupProperties({ properties, contentType = [], displayOptions, ungroupedLabel }) {
            // Get property tag groups for this content type.
            // This is an object whose keys are tags and values are arrays of
            // properties that have that tag.
            let propertyTags = _.cloneDeep(_.get(displayOptions, 'propertyTags', {}));

            // Set up the ungrouped group with the requested label, merging it with any existing group.
            if (ungroupedLabel) {
                _.set(
                    propertyTags,
                    ungroupedLabel,
                    _.concat(_.get(propertyTags, ungroupedLabel, []), _.get(propertyTags, '_ungrouped', []))
                );
                _.unset(propertyTags, '_ungrouped');
            }

            // Make sure content type is an array.
            contentType = _.castArray(contentType);
            // Create a regex to remove content type names from property names.
            const replacer = new RegExp(`^(${contentType.join('|')})\\.`);

            // Transform the lists of properties in each group into an object whose keys are
            // property names and values are property values.
            const groupedProps = _.mapValues(propertyTags, propertiesWithTag => {
                // Remove content type from property name if necessary.
                const propertyNames = _.map(propertiesWithTag, propertyName =>
                    _.replace(propertyName, replacer, '')
                );
                // Pick only the given properties from the dataset.
                const groupData = _.pick(properties, propertyNames);

                // Get the list of visible properties (i.e. props that don't have "display: hidden")
                const visibleProperties = _(propertiesWithTag)
                    .reject(propertyName =>
                        _.get(displayOptions, ['propertyDisplayOptions', propertyName, 'hidden'], false)
                    )
                    .map(propertyName => _.replace(propertyName, replacer, ''))
                    .value();

                // Get the list of properties that actually have data.
                const propertiesWithData = _.omitBy(
                    groupData,
                    val => _.isNil(val) || (_.isObject(val) && _.isEmpty(val))
                );

                return {
                    // Sort the dataset by key.
                    data: _sortObject(groupData),
                    // Return some metadata about the group.
                    meta: {
                        numPropertiesInGroup: _.size(propertiesWithTag),
                        numVisiblePropertiesInGroup: _.size(visibleProperties),
                        numPropertiesWithData: _.size(_.keys(propertiesWithData)),
                        numVisiblePropertiesWithData: _.size(
                            _.intersection(visibleProperties, _.keys(propertiesWithData))
                        )
                    }
                };
            });

            // Sort and return the groups.
            const sortedGroupedProps = _sortObject(groupedProps);
            return sortedGroupedProps;
        }

        // Get the human-readable version of the content type name.
        // Format: `{teamId}##{contentType}`
        function getContentTypePrettyName(name, excludeCustom) {
            if (_.includes(name, '##')) {
                const contentType = _.split(name, '##').pop();
                return excludeCustom ? contentType : `${contentType} (custom)`;
            }
            return name;
        }

        function _sortObject(obj) {
            return _(obj)
                .toPairs()
                .sortBy(0)
                .fromPairs()
                .value();
        }
    }
})();
