'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('apiLimit', {
    controller,
    template,
    bindings: {
        board: '<',
        id: '<',
        primaryUserSettings: '<',
        dailyApiCallLimit: '<',
        updateDailyApiCallLimit: '<'
    }
});
