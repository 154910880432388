import { getPlaceholder, getPropertyDisplayOptions, isEmail, isValid } from '../../utils/properties';
import template from 'views/tmpl/cards/metrics/metrics.html';

(function() {
    'use strict';
    angular.module('serviceApp').directive('cardMetrics', cardMetrics);

    function cardMetrics() {
        var directive = {
            restrict: 'E',
            scope: {
                board: '=',
                panel: '=',
                card: '=',
                external: '=?',
                popoutCallbackHitch: '&'
            },
            template,
            controller: CardMetricsController
        };

        return directive;
    }

    CardMetricsController.$inject = ['$scope', '$window', 'translateValuesService'];

    function CardMetricsController($scope, $window, translateValuesService) {
        $scope.contentType = _.get($scope.board, 'type');
        $scope.propertyDisplayOptions = getPropertyDisplayOptions($scope.board, $scope.contentType);
        $scope.getFormattedKey = _.startCase;
        $scope.isEmail = isEmail;
        $scope.isValid = isValid.bind(this, $scope.contentType, $scope.propertyDisplayOptions);
        $scope.getPlaceholder = getPlaceholder;

        $scope.createMetricsArray = function(cardMetrics) {
            $scope.metricsArray = _.filter(cardMetrics, metric => {
                return metric.value !== undefined;
            });
            _.forEach($scope.metricsArray, metric => {
                if (metric.propertyName && metric.value !== null) {
                    const contentType = _.first(_.split(metric.propertyName, '.'));
                    const key = _.last(_.split(metric.propertyName, '.'));
                    const translatedVal = translateValuesService.translateValue({
                        $scope,
                        contentType: contentType,
                        propertyName: key,
                        propertyValue: metric.value
                    });
                    _.set(metric, 'value', translatedVal);
                }
                if (metric.value === null) {
                    const placeholder = getPlaceholder(metric.label) + ' not found';
                    _.set(metric, 'value', placeholder);
                }
            });
        };

        $scope.createMetricsArray($scope.card.properties.metrics);

        $scope.$watch('card.properties.metrics', (newVal, oldVal) => {
            if (newVal !== oldVal) {
                $scope.createMetricsArray(newVal);
            }
        });

        $scope.popout = function() {
            var data = {
                board: $scope.board.shortcode,
                panelGroup: $scope.board.state.currentGroup || 'main',
                panel: $scope.panel.name,
                card: $scope.card.name
            };
            $scope.popoutCallbackHitch({
                data: data
            });
        };

        $scope.openMailTo = function(value) {
            $window.open('mailto:' + value, '_self');
        };

        $scope.toggleShowMore = function() {
            $scope.showMore = !$scope.showMore;
            if ($scope.showMore) {
                $scope.card.size.y = 4;
            } else {
                $scope.card.size.y = 1;
            }
        };
    }
})();
