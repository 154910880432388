'use strict';

import template from './index.html';
import controller from './controller';

angular.module('serviceApp').component('filterSummaryHover', {
    controller,
    template,
    bindings: {
        summaryHover: '<',
        generativePayload: '<?'
    }
});
