(function() {
    'use strict';
    angular.module('serviceApp').factory('hierarchyService', HierarchyService);

    HierarchyService.$inject = ['_', '$resource', '$q'];

    function HierarchyService(_, $resource, $q) {
        const hierarchyUrl = '/api/hierarchy';
        const defaultParams = {};

        const hierarchyResourceApi = {
            generateHierarchyPreview: {
                method: 'POST',
                url: `${hierarchyUrl}/preview`
            },
            getHierarchy: {
                method: 'POST',
                url: `${hierarchyUrl}/get`
            },
            getHierarchyMetadata: {
                method: 'GET',
                url: `${hierarchyUrl}/me`
            }
        };

        const hierarchyResource = $resource(hierarchyUrl, defaultParams, hierarchyResourceApi);

        const service = { generateHierarchyPreview, getHierarchyById, getHierarchyMetadata };

        return service;

        ////////////

        function generateHierarchyPreview({ hierarchyDefinition } = {}) {
            if (!hierarchyDefinition) {
                return $q.reject({ msg: 'Hierarchy definition must be defined' });
            }

            const body = {
                hierarchyDefinition
            };

            return $q.when(hierarchyResource.generateHierarchyPreview({}, body).$promise);
        }

        function getHierarchyById(id, rootId = undefined) {
            if (!id) {
                return $q.reject({ msg: 'Hierarchy id is required' });
            }

            const body = {
                id,
                rootId
            };

            return $q.when(hierarchyResource.getHierarchy({}, body).$promise);
        }

        function getHierarchyMetadata() {
            return $q.when(hierarchyResource.getHierarchyMetadata({}).$promise);
        }
    }
})();
