'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('activityTimeline', {
    controller,
    template,
    bindings: {
        activityData: '<',
        user: '<',
        board: '<',
        scrollContainerId: '<?'
    }
});
