'use strict';
import '../../tags/tag-input/index.scss';
import template from '../../tags/tag-input/index.html';
import Controller from './controller';

angular.module('serviceApp').directive('propertyTagInput', () => ({
    restrict: 'E',
    scope: {
        definition: '=?',
        contentType: '<'
    },
    template,
    controller: Controller
}));
