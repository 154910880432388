(function() {
    'use strict';

    angular
        .module('sfNumber', ['schemaForm'])
        .config([
            '_',
            'schemaFormProvider',
            'schemaFormDecoratorsProvider',
            'sfBuilderProvider',
            'sfPathProvider',
            function(_, schemaFormProvider, schemaFormDecoratorsProvider, sfBuilderProvider, sfPathProvider) {
                var sfNumber = function(name, schema, options) {
                    if (
                        _.get(schema, 'type') === 'number' &&
                        _.get(schema, 'format.type') !== 'smart-input'
                    ) {
                        var f = schemaFormProvider.stdFormObj(name, schema, options);
                        f.key = options.path;
                        f.type = 'number';
                        options.lookup[sfPathProvider.stringify(options.path)] = f;
                        return f;
                    }
                };

                if (_.isEmpty(schemaFormProvider.defaults.number)) {
                    schemaFormProvider.defaults.number = [];
                }
                schemaFormProvider.defaults.number.unshift(sfNumber);

                schemaFormDecoratorsProvider.defineAddOn(
                    'bootstrapDecorator',
                    'number',
                    'views/tmpl/templates/sfNumber.html',
                    sfBuilderProvider.stdBuilders
                );
            }
        ])
        .run([
            '$templateCache',
            function($templateCache) {
                $templateCache.put(
                    'views/tmpl/templates/sfNumber.html',
                    require('views/tmpl/templates/sfNumber.html')
                );
            }
        ]);
})();
