'use strict';

import 'angular';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

//Datadog RUM setup
import { datadogRum } from '@datadog/browser-rum';

import 'angular-animate';
import 'angular-cookies';
import 'angular-chart.js';
import 'angular-clipboard';
import 'angular-bootstrap-contextmenu';
import 'angular-inview';
import 'angular-loading-bar';
import 'angular-location-update';
import 'angular-media-queries';
import 'angular-moment';
import 'angular-resource';
import 'angular-route';
import 'angular-sanitize';
import 'angular-theme-spinner';
import 'angular-touch';
import 'angular-ui-router';
import 'angular-ui-utils';
import 'ng-device-detector';
import 'ng-youtube-embed';
import 'ngletteravatar';
import 'ngstorage';
import 're-tree'; // required for ng-device-detector

/**
 * Lets you use lodash as a service from a controller.
 * Adapted from https://github.com/andresesfm/angular-underscore-module
 * Homepage https://github.com/kmc059000
 */
import 'lodash';
angular.module('lodash', []).constant('_', window._);

//Initialize Datadog RUM
if (window && window.location && window.location.host) {
    const domain = window.location.host;

    // If we're running locally and haven't explicitly enabled Datadog for dev mode,
    // do nothing.
    if (
        (!_.includes(domain, 'localhost') && !_.includes(domain, 'olono.test')) ||
        (typeof ddForDev !== 'undefined' && ddForDev === true)
    ) {
        // Otherwise initialize Datadog RUM.
        let env = 'production';
        if (_.includes(domain, 'localhost') || _.includes(domain, 'olono.test')) {
            env = 'dev';
        } else if (_.includes(domain, 'sandbox.')) {
            env = 'sandbox';
        }
        datadogRum.init({
            applicationId: '5ba4998c-ebae-4e71-9f37-a85676117956',
            clientToken: 'pub5bf266e036a123acc1e2015729d01fdd',
            site: 'datadoghq.com',
            service: 'platform-ui',
            env,
            version: appVersion,
            sessionSampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            allowedTracingUrls: [window.location.origin],
            beforeSend: event => {
                // If we have custom error context, try and create a debug command for it.
                if (event.error && event.context?.module) {
                    // Pull the top stack trace line.
                    const regex = new RegExp(`at.*${event.context.module}\\/(\\S+)`);
                    const match = event.error.stack?.match(regex);
                    if (!match) {
                        return;
                    }
                    const [, errLine] = match;
                    if (!errLine) {
                        return;
                    }
                    // Create metadata for the debug command.
                    const [file, line = 0, col = 0] = errLine.split(':');
                    const traceData = {
                        ...event.context,
                        file,
                        location: `${line}:${col}`
                    };
                    // Add the debug command to the context.
                    event.context.cliTraceCmd = `olono sourcemap ${window.btoa(JSON.stringify(traceData))}`;
                }

                // Redact reset password URL's
                if (_.includes(_.toString(event.view.url), 'resetpassword')) {
                    event.view.url = 'REDACTED';
                    return false;
                }
            }
        });
    }
}

/**
 * @ngdoc overview
 * @name serviceApp
 * @description
 * # serviceApp
 *
 * Main module of the application.
 */
export default angular.module('serviceApp', [
    'ngAnimate', // above
    'ngCacheBuster', // see app/scripts/modules/
    'ngCookies', // above
    'ngResource', // above
    'ngRoute', // above
    'ngSanitize', // above
    'ngStorage', // above
    'ngTouch', // above
    'angular-clipboard', // above
    'angular-inview', // above
    'angular-loading-bar', // above
    'angular-theme-spinner', // above. TODO, verify this is still used.
    'picardy.fontawesome',
    'ui.bootstrap',
    'ui.bootstrap.contextMenu', //above, 'angular-bootstrap-contextmenu'
    'ui.bootstrap.dropdown',
    'ui.router', // above 'angular-ui-router'
    'ui.utils', // above 'angular-ui-utils'
    'angularMoment', // above 'angular-moment'
    'toastr',
    'errSrc',
    'truncate',
    'ng.deviceDetector',
    'ngLocationUpdate', // above 'angular-location-update'
    'chart.js', // above 'angular-chart.js'
    'ngYoutubeEmbed', // above 'ng-youtube-embed'. TODO verify this is still used.
    'lodash',
    'ngModal',
    'authInterceptor',
    'loadingIndicatorInterceptor',
    'monitoringInterceptor',
    'ngPluralizeFilter',
    'schemaForm',
    'sfTypeAhead',
    'sfTypeAheadSingle',
    'sfNumberRange',
    'sfDateRange',
    'sfForecastSubmissionPeriod',
    'sfDate',
    'sfNumber',
    'sfMultiSelect',
    'sfFormBoolean',
    'sfInput',
    'sfPropertyHistory',
    'sfConditionalSelect',
    'sfObjectSchemaBuilder',
    'sfForecastEntryBuilder',
    'sfForecastEntryItem',
    'sfHtml',
    'sfTextArea',
    'sfString',
    'ngQuill',
    'ng.deviceDetector', // above 'ng-device-detector'
    'ngLetterAvatar', // above 'ngletteravatar'
    'matchMedia' // above 'angular-media-queries'
]);
