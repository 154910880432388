'use strict';

import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('metricTrendCondition', () => ({
    restrict: 'E',
    scope: {
        trend: '=',
        conditionValue: '=',
        conditionOperator: '=',
        onConditionRemoved: '&',
        onTemplateChanged: '&',
        contextBreakdown: '<'
    },
    template,
    controller: Controller
}));
