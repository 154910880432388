(function() {
    'use strict';
    angular.module('serviceApp').directive('actionAutomationSettings', () => ({
        restrict: 'E',
        scope: {
            definitions: '=',
            user: '<'
        },
        template: require('views/tmpl/partials/actionAutomationSettings.html'),
        controller: ActionAutomationSettings
    }));

    ActionAutomationSettings.$inject = ['_', '$scope', 'toastr', 'actionService'];

    function ActionAutomationSettings(_, $scope, toastr, actionService) {
        init();

        $scope.$watchCollection('definitions', function() {
            init();
        });

        function init() {
            $scope.autoRunTitle = $scope.$root.productPredict;
            $scope.autoRunEnabledDefinitions = _.get($scope.definitions, 'autoRunEnabledDefinitions');
            $scope.autoRunDisabledDefinitions = _.get($scope.definitions, 'autoRunDisabledDefinitions');
            $scope.autoRunPendingDefinitions = _.get($scope.definitions, 'autoRunPendingDefinitions');
            $scope.autoRunDismissedDefinitions = _.get($scope.definitions, 'autoRunDismissedDefinitions');
            $scope.autoRunDefinitionsSettings =
                !_.isEmpty($scope.autoRunEnabledDefinitions) ||
                !_.isEmpty($scope.autoRunDisabledDefinitions) ||
                !_.isEmpty($scope.autoRunPendingDefinitions) ||
                !_.isEmpty($scope.autoRunDismissedDefinitions);
        }

        $scope.enableDisableDefinition = definition => {
            if (definition.isEnabled) {
                actionService
                    .autoActionOptIn(definition.id, $scope.user.id)
                    .then(results => {})
                    .catch(function(err) {
                        toastr.error('An error occurred - please try again', '');
                        definition.isEnabled = false;
                    });
            } else {
                actionService
                    .autoActionOptOut(definition.id, $scope.user.id)
                    .then(results => {})
                    .catch(function(err) {
                        toastr.error('An error occurred - please try again', '');
                        definition.isEnabled = true;
                    });
            }
        };
    }
})();
