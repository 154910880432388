angular.module('serviceApp').directive('cardBoards', function() {
    return {
        restrict: 'E',
        scope: {
            card: '=',
            user: '<',
            team: '<'
        },
        template: require('views/tmpl/pages/boards.html'),

        controller: [
            '_',
            '$scope',
            '$rootScope',
            '$state',
            '$stateParams',
            '$window',
            '$cookies',
            '$log',
            'userServiceNew',
            'deviceDetector',
            function(
                _,
                $scope,
                $rootScope,
                $state,
                $stateParams,
                $window,
                $cookies,
                $log,
                userServiceNew,
                deviceDetector
            ) {
                $rootScope.$on('boardEvent', function(event, data) {
                    if (data.message == 'delete') {
                        _.remove($scope.card.properties.boards, data.board);
                    }
                });

                $scope.isBoardHidden = function(board) {
                    return !!($scope.user.hiddenBoards && $scope.user.hiddenBoards.indexOf(board.id) != -1);
                };

                $scope.reloadPage = function() {
                    $window.location.reload(false);
                };
            }
        ]
    };
});
