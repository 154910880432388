'use strict';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('forecastEntryBuilder', () => ({
    restrict: 'A',
    scope: {
        formModel: '=forecastEntryBuilder',
        form: '='
    },
    template,
    controller: Controller
}));
