'use strict';
import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('actionStatsSearch', () => ({
    restrict: 'E',
    scope: {
        dark: '=?', // if rendered on a dark background set to true
        options: '=?',
        filterData: '=?',
        defaultFilter: '=?',
        defaultQueryState: '<?',
        definition: '<'
    },
    template,
    controller: Controller
}));
