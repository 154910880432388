'use strict';
import _ from 'lodash';

export default class TagsController {
    static get $inject() {
        return ['$scope'];
    }
    constructor($scope) {
        this.scope = $scope;

        this.scope.$on('removeTagItem', (event, data) => {
            _.set(
                this.scope,
                'definition.tags',
                _.reject(_.get(this.scope, 'definition.tags', []), { label: _.get(data, 'tag.label') })
            );
        });
    }
}
