'use strict';

/**
 * @ngdoc directive
 * @name serviceApp.directive:errSrc
 * @description
 * # replace invalid images
 */
angular.module('errSrc', []).directive('errSrc', [
    '_',
    '$compile',
    function(_, $compile) {
        return {
            link: function(scope, element, attrs) {
                element.bind('error', function() {
                    if (attrs.src != attrs.errSrc && !_.includes(attrs.errSrc, 'fa ')) {
                        attrs.$set('src', attrs.errSrc);
                        element.removeClass('img-circle');
                        //Add ability to replace the broken icon with a Font Awesome Icon.
                    } else if (_.includes(attrs.errSrc, 'fa ')) {
                        element[0].outerHTML = '<i class="' + attrs.errSrc + '" aria-hidden="true"></i>';
                        $compile(element.contents())(scope);
                    }
                });

                attrs.$observe('ngSrc', function(value) {
                    if (!value && attrs.errSrc) {
                        attrs.$set('src', attrs.errSrc);
                    }
                });
            }
        };
    }
]);
