(function() {
    'use strict';

    angular.module('serviceApp').directive('boardBasicTile', boardBasicTile);

    function boardBasicTile() {
        var directive = {
            restrict: 'E',
            scope: {
                userBoard: '=',
                searchModel: '='
            },
            template: require('views/tmpl/partials/basicBoardTile.html'),
            controller: BoardBasicTileController
        };

        return directive;
    }

    BoardBasicTileController.$inject = ['_', '$scope', 'boardsUtilsService'];

    function BoardBasicTileController(_, $scope, boardsUtilsService) {
        $scope.showMenu = false;

        $scope.toggleShowMenu = function() {
            $scope.showMenu = !$scope.showMenu;
        };

        $scope.getClass = function() {
            if (!$scope.iconClass) {
                $scope.iconClass = 'board-icon bg' + _.random(0, 9);
            }
            return $scope.iconClass;
        };

        $scope.openBoard = function(userBoard, clearSearch) {
            boardsUtilsService.openBoard(userBoard, clearSearch);
        };

        $scope.openBoardManager = function(board) {
            boardsUtilsService.openBoardManager(board);
        };

        $scope.openModal = function(modal) {
            var modalData = _({})
                .set('modal', modal)
                .set('extras', {
                    board: $scope.userBoard
                })
                .value();

            $scope.$root.$emit('showModal', modalData);
        };

        $scope.urlOrIcon = function(board) {
            if (board.iconUrl) {
                return board.iconUrl.substring(0, 13) == 'font-awesome:' ? 'icon' : 'url';
            } else {
                return null;
            }
        };

        $scope.getBoardFaviconUrl = function(board) {
            var url = '';
            if (board.iconUrl) {
                return board.iconUrl;
            } else {
                url = '//logo.clearbit.com/' + board.shortcode;
                return url;
            }
        };
    }
})();
