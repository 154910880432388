'use strict';
import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('assignToUser', () => ({
    restrict: 'E',
    scope: {
        payload: '<',
        action: '<'
    },
    template,
    controller: Controller
}));
