(function() {
    'use strict';
    angular.module('serviceApp').factory('backgroundJobService', BackgroundJobService);

    BackgroundJobService.$inject = ['_', '$resource', '$q', '$filter', '$log', 'storageService'];

    function BackgroundJobService(_, $resource, $q, $filter, $log, storageService) {
        const BACKGROUND_JOB_URL = '/api/background-job';
        const DEFAULT_PARAMS = {};

        const BACKGROUND_JOB_RESOURCE_API = {
            createContextRefreshJob: {
                method: 'POST',
                url: BACKGROUND_JOB_URL + '/context-refresh-job'
            },
            previewContextRefreshJob: {
                method: 'POST',
                url: BACKGROUND_JOB_URL + '/context-refresh-job/preview'
            }
        };

        const BACKGROUND_JOB_RESOURCE = $resource(
            BACKGROUND_JOB_URL,
            DEFAULT_PARAMS,
            BACKGROUND_JOB_RESOURCE_API
        );

        return {
            createContextRefreshJob,
            previewContextRefreshJob
        };

        ////////////

        function createContextRefreshJob({ contentType, filterSchema = {}, filterValues = {}, options }) {
            // Ensure that schema and values are objects, per the API contract.
            filterSchema = filterSchema || {};
            filterValues = filterValues || {};
            const contentTypeLabel = $filter('contentTypeLabel')(contentType);
            const description = `Manual refresh of ${contentTypeLabel} content`;
            const body = { contentType, filterSchema, description, filterValues, options };

            return BACKGROUND_JOB_RESOURCE.createContextRefreshJob({}, body)
                .$promise.then(function(result) {
                    return result;
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }

        function previewContextRefreshJob({ contentType, filterSchema = {}, filterValues = {}, options }) {
            // Ensure that schema and values are objects, per the API contract.
            filterSchema = filterSchema || {};
            filterValues = filterValues || {};
            const body = { contentType, filterSchema, filterValues, options };

            return BACKGROUND_JOB_RESOURCE.previewContextRefreshJob({}, body)
                .$promise.then(function(result) {
                    return result.count;
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }
    }
})();
