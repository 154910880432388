'use strict';

export default class Controller {
    static get $inject() {
        return ['_', '$scope'];
    }

    constructor(_, $scope) {
        this._ = _;
        this.scope = $scope;
    }

    $onInit() {
        this.scope.data = {
            entry: this.scope.entry,
            attainable: this.scope.attainable
        };
        this.scope.hooks = {
            closeForecastDrawer: () => {
                this.scope.$emit('rightDrawer:close');
                this.scope.$apply();
            },
            // trigger savedForecastData listener to reload after saving
            saveForecastCallback: () => {
                this.scope.$root.$emit('savedForecastData');
            }
        };
    }
}
