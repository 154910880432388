import template from './index.html';

import './index.scss';

/**
 * A link that copies text to the clipboard
 *
 */
angular.module('serviceApp').directive('copyToClipboard', () => ({
    restrict: 'E',
    scope: {
        inputText: '<'
    },
    template,
    controller: [
        '$scope',
        'toastr',
        function($scope, toastr) {
            $scope.copy = function() {
                if ($scope.inputText) {
                    var copyElement = document.createElement('textarea');
                    copyElement.style.position = 'fixed';
                    copyElement.style.opacity = '0';
                    copyElement.textContent = decodeURI($scope.inputText);
                    var body = document.getElementsByTagName('body')[0];
                    body.appendChild(copyElement);
                    copyElement.select();
                    document.execCommand('copy');
                    body.removeChild(copyElement);
                    toastr.success($scope.inputText, 'Text copied to clipboard:');
                }
            };
        }
    ]
}));
