import { isHidden } from '../../scripts/utils/properties';

ContentSubtitleController.$inject = [
    '_',
    '$scope',
    '$rootScope',
    '$interpolate',
    '$filter',
    'translateValuesService'
];
export default function ContentSubtitleController(
    _,
    $scope,
    $rootScope,
    $interpolate,
    $filter,
    translateValuesService
) {
    $scope.set = set;
    $scope.open = open;
    $scope.isHidden = isHidden.bind(this, $scope.contentType, $scope.propertyDisplayOptions);
    $scope.isObject = _.isObject;
    $scope.init = init;
    $scope.init();

    $scope.$watchCollection('values', function() {
        $scope.init();
    });

    function init() {
        $scope.asArray = Array.isArray($scope.values);
        $scope.asObject = $scope.asArray ? false : $scope.isObject($scope.values);
        $scope.translatedValues = translateValuesService.translateValues({
            $scope,
            $filter,
            $interpolate,
            contentType: $scope.contentType,
            propertyDisplayOptions: $scope.propertyDisplayOptions,
            values: $scope.values,
            asArray: $scope.asArray,
            asObject: $scope.asObject
        });
        $scope.arrayLimit = 2;
        $scope.globalArrayLimit = $rootScope.globalArrayLimit;
    }

    function set(obj, path, value) {
        if (_.isNull(value)) {
            $scope[obj] = path;
        } else {
            _.set($scope[obj], path, value);
        }
    }

    function open() {
        $scope.clickCallback();
    }
}
