'use strict';

/**
 * Angular wrapper for our <FilterPillsButtonGroupExternalComponent /> react component.
 */
export default class FilterPillsButtonGroup {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.data = {
            numberItemsShowing: this.numberItemsShowing,
            numberItemsTotal: this.numberItemsTotal,
            filterName: this.filterName,
            isPillsOpen: this.isPillsOpen,
            isExpandable: this.isExpandable,
            isFilterSelected: this.isFilterSelected,
            isRemovable: this.isRemovable,
            onFilterSelectButtonClick: this.onFilterSelectButtonClick,
            onTogglePillButtonClick: this.onTogglePillButtonClick,
            onFilterToggleOff: this.onFilterToggleOff,
            isInsightsView: this.isInsightsView
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'numberItemsShowing') && !changes.numberItemsShowing.isFirstChange()) {
            this.numberItemsShowing = changes.numberItemsShowing.currentValue;
            this.data = { ...this.data, numberItemsShowing: this.numberItemsShowing };
        }

        if (this._.get(changes, 'numberItemsTotal') && !changes.numberItemsTotal.isFirstChange()) {
            this.numberItemsTotal = changes.numberItemsTotal.currentValue;
            this.data = { ...this.data, numberItemsTotal: this.numberItemsTotal };
        }

        if (this._.get(changes, 'filterName') && !changes.filterName.isFirstChange()) {
            this.filterName = changes.filterName.currentValue;
            this.data = { ...this.data, filterName: this.filterName };
        }

        if (this._.get(changes, 'isPillsOpen') && !changes.isPillsOpen.isFirstChange()) {
            this.isPillsOpen = changes.isPillsOpen.currentValue;
            this.data = { ...this.data, isPillsOpen: this.isPillsOpen };
        }

        if (this._.get(changes, 'isFilterSelected') && !changes.isFilterSelected.isFirstChange()) {
            this.isFilterSelected = changes.isFilterSelected.currentValue;
            this.data = { ...this.data, isFilterSelected: this.isFilterSelected };
        }

        if (this._.get(changes, 'isRemovable') && !changes.isRemovable.isFirstChange()) {
            this.isRemovable = changes.isRemovable.currentValue;
            this.data = { ...this.data, isRemovable: this.isRemovable };
        }
    }
}
