'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('contentGridHeader', {
    controller,
    template,
    bindings: {
        options: '<',
        metadata: '<'
    }
});
