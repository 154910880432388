'use strict';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('listLabelBuilder', () => ({
    restrict: 'E',
    scope: {
        schema: '<',
        contextBreakdown: '<',
        model: '=ngModel'
    },
    template,
    controller: class {}
}));
