(function() {
    'use strict';

    angular.module('serviceApp').directive('hideOptions', hideOptions);

    function hideOptions() {
        var directive = {
            restrict: 'E',
            scope: {
                result: '=',
                isOwner: '=',
                callback: '&'
            },
            template: require('views/tmpl/partials/hideOptions.html'),
            controller: HideOptionsController
        };

        return directive;
    }

    HideOptionsController.$inject = ['$scope', 'galleryUtilsService'];

    function HideOptionsController($scope, galleryUtilsService) {
        $scope.hideContent = function() {
            galleryUtilsService.hideContent($scope.result).then(function() {
                $scope.callback({
                    data: {
                        event: 'hide',
                        result: $scope.result
                    }
                });
            });
        };

        $scope.hideAllContent = function() {
            galleryUtilsService.hideAllContent($scope.result).then(function() {
                $scope.callback({
                    data: {
                        event: 'hideAll',
                        result: $scope.result
                    }
                });
            });
        };
    }
})();
