'use strict';

export default class ActionRunOverlay {
    static get $inject() {
        return ['$scope'];
    }

    constructor(scope) {
        this.scope = scope;
    }
}
