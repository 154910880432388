(function() {
    'use strict';
    angular.module('serviceApp').factory('actionPreviewService', ActionPreviewService);

    ActionPreviewService.$inject = ['_', '$resource', '$q'];

    function ActionPreviewService(_, $resource, $q) {
        const actionPreviewUrl = '/api/action/preview';
        const defaultParams = {};

        const actionPreviewResourceApi = {
            findContent: {
                method: 'POST',
                url: `${actionPreviewUrl}/find-content`
            },
            generateActionPreviewForContent: {
                method: 'POST',
                url: `${actionPreviewUrl}/generate`
            }
        };

        const actionPreviewResource = $resource(actionPreviewUrl, defaultParams, actionPreviewResourceApi);

        const service = { findContent, generateActionPreviewForContent };

        return service;

        ////////////

        function findContent({ actionDefinition, queryString, advancedQuery = true } = {}) {
            if (!actionDefinition) {
                return $q.reject({ msg: 'Action definition must be defined' });
            }

            const body = {
                actionDefinition,
                query: { query: queryString, advancedQuery }
            };

            return $q.when(actionPreviewResource.findContent({}, body).$promise);
        }

        function generateActionPreviewForContent({ contentId, actionDefinition } = {}) {
            if (!actionDefinition) {
                return $q.reject({ msg: 'Action definition must be defined' });
            }

            if (!contentId) {
                return $q.reject({ msg: 'contentId must be defined' });
            }

            const body = {
                contentId,
                actionDefinition
            };

            return $q
                .when(actionPreviewResource.generateActionPreviewForContent({}, body).$promise)
                .then(response => {
                    return response;
                })
                .catch(err => {
                    const msg = _.get(err, 'data.error') || _.get(err, 'statusText') || JSON.stringify(err);
                    return $q.reject(Object.assign({ msg }, err));
                });
        }
    }
})();
