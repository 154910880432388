(function() {
    'use strict';

    angular.module('serviceApp').filter('autoLink', autoLink);

    autoLink.$inject = ['_', '$filter'];

    function autoLink(_, $filter) {
        var filter = function(value) {
            if (!value || _.isNil(value)) {
                return 'N/A';
            }
            // Clean out any unescaped parentheses. TODO: Angularjs Linky filter has an issue with parsing out end
            // parentheses that will not be fixed. If this fix in the future becomes limiting because it may contain
            // strings with links, then we should look into a 3rd party filter like linkyjs OR when we move to REACT this
            // will no longer be a limiting issue.
            value = value.replace(/\(/g, '%28').replace(/\)/g, '%29');

            //Attempt to generate a link with the angular 'linky' filter.
            let link = $filter('linky')(value, '_blank');

            //If linky failed to generate a link, create a generic URL link.
            if (_.isEqual(link, value)) {
                link = `<a href="http://${value}" target="_blank">${value}</a>`;
            }

            return link;
        };

        return filter;
    }
})();
