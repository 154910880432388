import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('rightDrawerData', () => ({
    restrict: 'E',
    scope: {
        drawerData: '<',
        openDrawerCallback: '&',
        closeDrawerCallback: '&',
        child: '<?'
    },
    template,
    controller: Controller
}));
