'use strict';
import _ from 'lodash';
import moment from 'moment-timezone';

/**
 * Attempt to parse a date string to a Moment in the given timezone.
 * @param {String} value - The candidate date string.
 * @param {String} timezone - The timezone to parse the string in.
 * @returns {Moment} - A Moment instance.
 */
export const parseDateString = (value, timezone) => {
    // Attempt to parse as an ISO_8601 compatible string.
    let date = moment.tz(value, moment.ISO_8601, true, timezone);
    // If that works, we're done.
    if (date.isValid()) {
        return date;
    }
    // If we weren't able to parse the date using an ISO_8601 format,
    // we'll try falling back to the JS date parser which can handle
    // a lot of weird formats.
    date = new Date(value);

    // If that didn't work, give up.
    if (isNaN(date)) {
        return null;
    }

    // If it did work, take the pieces out and reform them to an ISO-8601 string
    // that we can pass back to moment-timezone.
    const year = date.getFullYear();
    const month = _.padStart(date.getMonth() + 1, 2, '0');
    const day = _.padStart(date.getDate(), 2, '0');
    const hours = _.padStart(date.getHours(), 2, '0');
    const minutes = _.padStart(date.getMinutes(), 2, '0');
    const seconds = _.padStart(date.getSeconds(), 2, '0');
    // `date` is currently in the local timezone, since that's how `new Date()` works.
    // So if we just did `moment.tz(date, timezone)` it could give us a different date
    // than we expect.  So we'll reconstruct the date and set the timezone explicitly.
    date = moment.tz(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`, timezone);
    return date;
};
