(function() {
    'use strict';

    angular.module('serviceApp').factory('userServiceNew', UserServiceNew);

    UserServiceNew.$inject = [
        '$rootScope',
        '_',
        '$resource',
        '$q',
        '$http',
        '$cookies',
        'storageService',
        '$location',
        '$timeout',
        '$window',
        'teamServiceNew'
    ];

    function UserServiceNew(
        $rootScope,
        _,
        $resource,
        $q,
        $http,
        $cookies,
        storageService,
        $location,
        $timeout,
        $window,
        teamServiceNew
    ) {
        var userUrl = '/api/user';
        var samlUrl = '/api/auth';
        var authUrl = '/api/auth';

        var defaultParams = {};

        // Singleton User object and user profiles object
        let userObject;
        let userProfiles = {};

        var userResourceActions = {
            check: {
                method: 'GET',
                url: authUrl + '/check',
                redirect: false
            },
            signUp: {
                method: 'POST'
            },
            getUser: {
                method: 'GET'
            },
            getLoginPage: {
                method: 'GET',
                url: samlUrl + '/loginPage'
            },
            hideBoard: {
                method: 'POST',
                url: userUrl + '/:userId'
            },
            updateUser: {
                method: 'POST',
                url: userUrl + '/:userId'
            },
            updateUserData: {
                method: 'POST',
                url: userUrl + '/update'
            },
            token: {
                method: 'POST',
                url: authUrl + '/token'
            },
            revoke: {
                method: 'POST',
                url: authUrl + '/revoke'
            },
            getUserProfile: {
                method: 'GET',
                url: `${userUrl}/profile`,
                params: {
                    userId: '@userId',
                    email: '@email'
                }
            }
        };

        var userResource = $resource(userUrl, defaultParams, userResourceActions);

        var service = {
            signUp: signUp,
            login: login,
            logOut: logOut,
            getUser: getUser,
            getLoginPage: getLoginPage,
            getCachedOrFetch: getCachedOrFetch,
            hideBoard: hideBoard,
            updateUser: updateUser,
            updateUserData: updateUserData,
            isAuthenticated: isAuthenticated,
            sendPasswordResetToken: sendPasswordResetToken,
            resendPasswordResetToken: resendPasswordResetToken,
            resetPassword: resetPassword,
            getUserProfile
        };

        return service;

        ////////////

        function isAuthenticated() {
            return $q.when(userResource.check().$promise).then(() => true);
        }

        function signUp(userData) {
            if (!userData) {
                return $q.reject({
                    msg: 'Sign up data must be defined'
                });
            }

            return $q.when(userResource.signUp({}, userData).$promise);
        }

        function login(userData) {
            if (!userData) {
                return $q.reject({
                    msg: 'Login data must be defined'
                });
            }
            return $q.when(userResource.token({}, userData).$promise).then(token => {
                // Flush local storage on login to prevent cross-team contamination
                storageService.flush();
                return token;
            });
        }

        function logOut() {
            //Clear out the user object and cookies
            userObject = undefined;
            teamServiceNew.logOut();

            $cookies.remove('serviceDomain');

            // Flush local storage data on a logout to prevent cross-team contamination
            storageService.flush();
            return $q.when(userResource.revoke({}, {}).$promise);
        }

        function getUser() {
            return userResource.getUser().$promise.then(userData => {
                userObject = userData.toJSON ? userData.toJSON() : userData;
                return userObject;
            });
        }

        function getLoginPage(userEmail) {
            const url = $window.location.href;

            const hostname = $location.host();
            const payload = {
                hostname,
                url
            };

            if (userEmail) {
                payload.email = userEmail;
            }

            if ($rootScope.mcode) {
                payload.mcode = $rootScope.mcode;
            }

            return $q.when(userResource.getLoginPage(payload, {}).$promise);
        }

        function getCachedOrFetch() {
            return $q(function(resolve, reject) {
                // If user data is already present us it.
                if (userObject) {
                    return resolve(userObject);
                }

                getUser().then(
                    function(retrievedUser) {
                        return resolve(retrievedUser);
                    },
                    function(err) {
                        return reject(err);
                    }
                );
            });
        }

        function hideBoard(user, boardId) {
            if (!user) {
                return $q.reject({
                    msg: 'User must be defined'
                });
            }

            if (!boardId) {
                return $q.reject({
                    msg: 'Board ID must be defined'
                });
            }

            var hiddenBoards = _.clone(user.hiddenBoards);

            if (_.find(hiddenBoards, boardId)) {
                return $q.reject({
                    msg: 'Board already hidden for user'
                });
            } else {
                hiddenBoards.push(boardId);
            }

            return $q.when(
                userResource.hideBoard(
                    {
                        userId: user.id
                    },
                    {
                        hiddenBoards: user.hiddenBoards
                    }
                ).$promise
            );
        }

        function updateUser(userData) {
            if (!userData) {
                return $q.reject({
                    msg: 'User data must be defined'
                });
            }

            if (!userData.id) {
                return $q.reject({
                    msg: 'User id must be defined'
                });
            }

            return userResource
                .updateUser(
                    {
                        userId: userData.id
                    },
                    userData
                )
                .$promise.then(updatedUser => {
                    userObject = undefined;
                    userProfiles = {};
                    // Update the user so changes flow down to external components
                    $rootScope.user = { ...$rootScope.user, ...updatedUser.toJSON().user };
                    $rootScope.$emit('user:updated');
                    return updatedUser;
                });
        }

        function updateUserData(userData) {
            if (!userData) {
                return $q.reject({
                    msg: 'User data must be defined'
                });
            }

            return userResource.updateUserData({}, userData).$promise.then(updatedUserData => {
                userObject = undefined;
                userProfiles = {};
                return updatedUserData;
            });
        }

        function sendPasswordResetToken(data) {
            var deferred = $q.defer();

            $http.post('/api/user/password', data).then(
                function(response) {
                    deferred.resolve(_.get(response, 'data'));
                },
                function(response) {
                    deferred.reject(_.get(response, 'data'));
                }
            );
            return deferred.promise;
        }

        function resendPasswordResetToken(data) {
            var deferred = $q.defer();

            $http.post('/api/user/password', data).then(
                function(response) {
                    deferred.resolve(_.get(response, 'data'));
                },
                function(response) {
                    deferred.reject(_.get(response, 'data'));
                }
            );
            return deferred.promise;
        }

        function resetPassword(token, newPassword) {
            var deferred = $q.defer();

            $http
                .post('/api/user/password', {
                    token: token,
                    password: newPassword
                })
                .then(
                    function(response) {
                        var loginData = {
                            username: _.get(response, 'data.username'),
                            password: newPassword
                        };

                        // TODO Whey do we have a 1-second lag here?
                        $timeout(function() {
                            service.login(loginData).then(
                                function(user) {
                                    deferred.resolve(user);
                                },
                                function(error) {
                                    deferred.reject(error);
                                }
                            );
                        }, 1000);
                    },
                    function(response) {
                        deferred.reject(_.get(response, 'data'));
                    }
                );

            return deferred.promise;
        }

        function getUserProfile({ userId, email } = {}, { refresh = false } = {}) {
            let cacheKey, profile;

            // not supplying an email or userId will return the current users profile
            if (!email && !userId) {
                cacheKey = 'user:profile';
            } else {
                cacheKey = `user:profile:${email ? email : userId}`;
            }

            if (!refresh) {
                profile = _.get(userProfiles, cacheKey);
            }

            if (!_.isEmpty(profile)) {
                return $q.resolve(profile);
            }

            return userResource
                .getUserProfile({
                    email,
                    userId
                })
                .$promise.then(profile => {
                    // if fetching yields nothing this probably means the user does not exist
                    if (_.isEmpty(profile)) {
                        return profile;
                    }

                    _.set(userProfiles, cacheKey, profile);
                    return profile;
                });
        }
    }
})();
