'use strict';

/**
 * Angular wrapper for our <ExpandableList /> react component.
 */
export default class ExpandableList {
    static get $inject() {
        return ['_', '$scope'];
    }

    constructor(_, $scope) {
        this._ = _;
        this.scope = $scope;
    }

    $onInit() {
        this.onRowStateChanged = this.onRowStateChanged || angular.noop;

        const handleRowStateChanged = data => {
            this.onRowStateChanged({ data });
        };

        const openForecastDrawer = entry => {
            this.scope.$emit('rightDrawer:open', {
                layout: 'forecastSubmissionDrawer',
                board: this.board,
                entry,
                drawerClass: 'skinny user-theme'
            });
        };

        const openAddToForecastDrawer = attainable => {
            this.scope.$emit('rightDrawer:open', {
                layout: 'forecastSubmissionDrawer',
                board: this.board,
                entry: {
                    ownerId: this.hierarchy.rootId
                },
                attainable,
                drawerClass: 'skinny user-theme'
            });
        };

        // Props being passed to our react component
        this.data = {
            expandableLabel: this.expandableLabel,
            emptyStateLabel: this.emptyStateLabel,
            nestedPropertyName: this.nestedPropertyName,
            columns: this.columns,
            records: this.records,
            onRowStateChanged: handleRowStateChanged,
            openForecastDrawer,
            openAddToForecastDrawer
        };
    }

    $onChanges(changes) {
        let changed = false;

        if (this._.get(changes, 'records') && !changes.records.isFirstChange()) {
            this.records = changes.records.currentValue;
            changed = true;
        }

        if (this._.get(changes, 'columns') && !changes.columns.isFirstChange()) {
            this.columns = changes.columns.currentValue;
            changed = true;
        }

        // Re-render the react component
        if (changed) {
            this.data = {
                ...this.data,
                records: this.records,
                columns: this.columns
            };
        }
    }
}
