(function() {
    'use strict';

    angular.module('loadingIndicatorInterceptor', []).factory('indicatorInterceptor', IndicatorInterceptor);

    IndicatorInterceptor.$inject = ['$q', '$rootScope'];
    function IndicatorInterceptor($q, $rootScope) {
        var factory = {
            response: response,
            responseError: responseError,
            request: request
        };

        return factory;

        //////////////

        function request(request) {
            $rootScope.$emit('reqSent');
            return request;
        }

        function response(response) {
            $rootScope.$emit('reqRec');
            return response;
        }

        function responseError(response) {
            $rootScope.$emit('reqRec');
            return $q.reject(response);
        }
    }
})();
