export default class GlobalUserDetails {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        this.hooks = {
            onEditGlobalUserClick: () => {}
        };
        this.data = {
            name: this._.get(this, 'user.profile.name'),
            email: this._.get(this, 'user.username'),
            coreAuthId: this._.get(this, 'user.coreAuth.id'),
            coreAuthTenantId: this._.get(this, 'team.coreAuth.tenantId')
        };
    }
    $onChanges() {}
}
