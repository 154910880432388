'use strict';

import _ from 'lodash';
export default class MetricTrendConfig {
    static get $inject() {
        return ['$scope', 'definitionService'];
    }

    constructor(scope, definitionService) {
        this.scope = scope;
        this.definitionService = definitionService;

        this.scope.removeSelf = this.removeSelf.bind(this);
        this.scope.onConditionAdded = this.onConditionAdded.bind(this);
        this.scope.onTemplateChanged = this.onTemplateChanged.bind(this);
        this.scope.onConditionRemoved = this.onConditionRemoved.bind(this);
        this.scope.onPropertySelectionChanged = this.onPropertySelectionChanged.bind(this);

        this.scope.allowConditions = _.get(
            this.scope.definition,
            'propertySchema.trend.configuration.allowConditions',
            true
        );

        this.scope.$watch('definition.propertyValues.trend.selectedTrends', () => {
            this.validateSelectedTrend();
            return;
        });
    }

    validateSelectedTrend() {
        const selectedTrends = _.get(this.scope, `definition.propertyValues.trend.selectedTrends`);
        let isValid = true;
        for (let i in selectedTrends) {
            if (selectedTrends[i].availableProperties && !selectedTrends[i].property) {
                isValid = false;
            }
        }
        this.scope.$emit('definition:form-validate', { section: 'selectedTrends', isValid });
    }

    /**
     * removeSelf - emit remove config event
     */
    removeSelf() {
        this.scope.$emit('trend-config-removed', this.scope.index);
    }

    /**
     * onTemplateChanged - callback to listen to condition and condition template changes
     * @param {Object} data - contains the condition value and the updated template
     */
    onTemplateChanged(data) {
        const condition = _.get(data, 'condition');
        const template = _.get(data, 'template');
        _.set(
            this.scope,
            `definition.propertyValues.trend.selectedTrends[${this.scope.index}].condition[${condition}].template.templateString`,
            template
        );
    }

    /**
     * onConditionAdded - callback to listen for added conditions and redraws the form
     * @param {Object} data - contains the condition to add
     */
    onConditionAdded(data) {
        const conditionToAdd = _.get(data, 'condition');
        _.set(
            this.scope,
            `definition.propertyValues.trend.selectedTrends[${this.scope.index}].condition[${conditionToAdd}]`,
            {}
        );

        this.redraw();
    }

    /**
     * onConditionRemoved - callback to listen for removed conditions and redraws the form
     * @param {Object} data - contains the condition to remove
     */
    onConditionRemoved(data) {
        const conditionToRemove = _.get(data, 'condition');
        _.unset(
            this.scope,
            `definition.propertyValues.trend.selectedTrends[${this.scope.index}].condition[${conditionToRemove}]`
        );

        this.redraw();
    }

    /**
     * onPropertySelectionChanged - callback to listen for property selection changes and redraws the form
     */
    onPropertySelectionChanged() {
        const newProperty = _.get(this.scope, 'trend.property');

        _.set(
            this.scope,
            `definition.propertyValues.trend.selectedTrends[${this.scope.index}].property`,
            newProperty
        );

        this.redraw();
    }

    /**
     * redraw - runs definition resolve with the current definition and emits a trend-config-updated event
     */
    redraw() {
        this.scope.$root._trendLoading = true;
        this.definitionService
            .resolve({
                definition: this.scope.definition,
                resolveFilters: false,
                resolveAudience: false,
                resolveActionAvailableContext: true,
                resolveTrendConfiguration: true,
                resolveEventTriggers: false
            })
            .then(result => {
                this.scope.$emit('trend-config-updated', result);
                this.scope.$root._trendLoading = false;
            })
            .catch(error => {
                console.error(error);
                this.scope.$root._trendLoading = false;
            });
    }
}
