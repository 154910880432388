import template from './index.html';

angular.module('serviceApp').directive('confirmDialog', () => ({
    restrict: 'E',
    scope: {
        prompt: '@',
        confirmText: '@',
        cancelText: '@',
        onConfirm: '&',
        onCancel: '&'
    },
    template
}));
