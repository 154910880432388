(function() {
    'use strict';

    angular.module('serviceApp').directive('cardTimeline', cardTimeline);

    function cardTimeline() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                user: '<',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/timeline/timeline.html'),
            controller: CardTimelineController
        };

        return directive;
    }

    CardTimelineController.$inject = ['_', '$scope', '$timeout'];

    function CardTimelineController(_, $scope, $timeout) {
        $scope.signals = [];
        $scope.today = new Date();
        $scope.today = $scope.today.setHours(0, 0, 0, 0);

        $scope.isLoading = true;

        $timeout(function() {
            if (_.isArray($scope.card.properties.data)) {
                $scope.card.properties.data = _.first($scope.card.properties.data);
            }

            if (!_.get($scope.card, 'properties.data.signals')) {
                $scope.card.properties.data = {};
                $scope.card.properties.data.signals = [];
                _.forEach(_.get($scope.card, 'properties.content'), function(contentItem) {
                    contentItem.signals = _.forEach(contentItem.signals, function(signal) {
                        signal.contentTitle = contentItem.displayTitle;
                        signal.boardId = contentItem.boardId;
                        return signal;
                    });

                    if (contentItem.signals) {
                        $scope.card.properties.data.signals = _.concat(
                            $scope.card.properties.data.signals,
                            contentItem.signals
                        );
                    }
                });
            }

            _.forEach(_.cloneDeep(_.get($scope.card, 'properties.data.signals')), function(signal) {
                var startSignal = _.cloneDeep(signal);

                startSignal = _(startSignal)
                    .set('displayDate', signal.end ? signal.end : $scope.today)
                    .set(
                        'sortDate',
                        signal.durationFromEnd > 0 ? signal.durationFromEnd : new Date().getMilliseconds()
                    )
                    .value();

                _.set(startSignal, 'compareDate', new Date(startSignal.displayDate).setHours(0, 0, 0, 0));

                $scope.signals.push(startSignal);
            });

            $scope.signals = _($scope.signals)
                .sortBy('sortDate')
                .value();

            $scope.signals = _.forEach($scope.signals, function(signal) {
                var index = _.findIndex($scope.signals, signal);
                var previous = _.get($scope.signals, index - 1, undefined);
                if (
                    (previous && previous.compareDate == signal.compareDate) ||
                    signal.compareDate == $scope.today
                ) {
                    _.unset(signal, 'displayDate');
                }

                return signal;
            });

            $scope.isLoading = false;
        }, 100);
    }
})();
