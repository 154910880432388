'use strict';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('formTypeAheadSingle', [
    '_',
    _ => ({
        restrict: 'A',
        scope: {
            formModel: '=formTypeAheadSingle',
            form: '=',
            localValues: '=?',
            placeholder: '=?'
        },
        link: (scope, element) => {
            scope.setInputFocus = () => {
                const input = _.get(element.find('input'), 0, {});

                if (_.isFunction(_.get(input, 'focus'))) {
                    input.focus();
                }
            };
        },
        template,
        controller: Controller
    })
]);
