'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('historyIntervalSelector', {
    controller,
    template,
    bindings: {
        interval: '<',
        availableIntervals: '<',
        numberOfIntervals: '<',
        offset: '<',
        onIntervalUpdated: '&',
        defaultRange: '<',
        sliceAt: '<'
    }
});
