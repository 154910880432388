(function() {
    'use strict';

    angular.module('serviceApp').directive('cardBoardSettings', cardBoardSettings);

    function cardBoardSettings() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                user: '<',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/settings/board.html'),
            controller: CardBoardSettingsController
        };

        return directive;
    }

    CardBoardSettingsController.$inject = [
        '$scope',
        '$log',
        '$state',
        '$stateParams',
        'userServiceNew',
        'toastr',
        'boardServiceNew'
    ];

    function CardBoardSettingsController(
        $scope,
        $log,
        $state,
        $stateParams,
        userServiceNew,
        toastr,
        boardServiceNew
    ) {
        $log.debug('Going to board dashboard: ' + $stateParams.board);
        $scope.currentBoard = $scope.board;
        $scope.newBoardMember = '';
        $scope.currentBoardUrl = $scope.domain + '/#/' + $scope.currentBoard.shortcode;
        $scope.textToCopy = $scope.currentBoardUrl;
        $log.debug('Board Dashboard - loading board: ' + $scope.currentBoard.title);

        if (!_.isEmpty(_.get($scope.board, 'content'))) {
            var compiled = _.template(
                '<% _.forEach(properties, function(value,key) { %><%- key %>  :  <%- JSON.stringify(value) %>\n<% }); %>'
            );
            $scope.shareDetails = _.unescape(compiled({ properties: _.get($scope.board, 'content') }));
        }

        $scope.filterBoardType = function(prop, val) {
            return function(board) {
                return board[prop] == val;
            };
        };

        $scope.updateBoard = function(board, form) {
            $log.debug('Updating board: ' + board.id + ' :' + board.title);

            boardServiceNew.updateBoard(board).then(
                function(updatedBoard) {
                    toastr.success('Board Updated!', '');

                    form.$setPristine();
                    form.$setUntouched();
                },
                function(error) {
                    $log.error('Failed to update board: ' + board.title);

                    toastr.error('Failed to update board: ' + error, '');
                }
            );
        };
        $scope.hideBoard = function(board) {
            $log.debug('Hide board: ' + board.id + ' :' + board.title);
            userServiceNew.hideBoard($scope.user, board.id).then(
                function(updatedUser) {
                    toastr.success('Board hidden!', '');
                    $state.go('home.main');
                },
                function(error) {
                    toastr.error('Failed to hide board: ' + error, '');
                }
            );
        };
    }
})();
