import controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('interpretedContent', interpretedContent);

/**
 * This directive is our primary component for displaying a single piece of content. It's used in top-content (via expandable-content-list),
 * card-content-list, action preview, and many other places.
 *
 * TODO:
 * - Both isTableContent and isDetailsContent have side-effects on showing featured properties. Consider making that
 *   an explicit configuration, or not passing in `filterData` if we don't want featured properties to be shown.
 */
function interpretedContent() {
    var directive = {
        restrict: 'E',
        scope: {
            board: '<',
            content: '<',
            user: '<',
            action: '<',
            cardLayout: '<?',
            facets: '<?',
            filterData: '<?',
            updateActionCallback: '&',
            closeContextCallback: '&',
            toggleRelatedContent: '&',
            actionPreviewCallback: '&?',
            refreshCallback: '&',
            isActionContent: '@?',
            isRelatedContent: '@?',
            isTableContent: '@?', // Set when this instance is used as part of a table view; disables featured properties amongst other changes.
            isDetailsContent: '<?', // Set when this instance is used in the "details" view; disabled featured properties and other behavioral changes.
            headerOnly: '@?',
            bodyOnly: '@?',
            userTheme: '<?',
            isDark: '<',
            isDashboardMode: '<?',
            hideActionButton: '<?'
        },
        template,
        controller
    };

    return directive;
}
