'use strict';

/**
 * Angular wrapper for our <CardFilterPillsExternalComponent /> react component.
 */
export default class CardFilterPills {
    static get $inject() {
        return ['$scope', '$filter', '_'];
    }

    constructor($scope, $filter, _) {
        this.$scope = $scope;
        this.$filter = $filter;
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.hooks = {
            getContentTypeLabel: this.$filter('contentTypeLabel')
        };

        this.data = {
            isExpanded: this.isExpanded,
            showEmpty: this.showEmpty,
            card: this.card
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'isExpanded') && !changes.isExpanded.isFirstChange()) {
            this.isExpanded = changes.isExpanded.currentValue;
            this.data = { ...this.data, isExpanded: this.isExpanded, showEmpty: this.showEmpty };
        }
    }
}
