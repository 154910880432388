(function() {
    'use strict';

    angular.module('serviceApp').controller('IntegrationCallbackController', IntegrationCallbackController);

    IntegrationCallbackController.$inject = ['$scope', '$stateParams'];

    function IntegrationCallbackController($scope, $stateParams) {
        if ($stateParams.success !== null && $stateParams.success !== undefined) {
            if ($stateParams.success == 'true') {
                $scope.success = true;
            } else {
                $scope.success = false;
            }
        }
    }
})();
