(function() {
    'use strict';

    angular.module('serviceApp').directive('autoFocus', autoFocus);

    autoFocus.$inject = ['$timeout'];
    function autoFocus($timeout) {
        var directive = {
            restrict: 'AC',
            link: function(_scope, _element) {
                $timeout(function() {
                    _element[0].focus();
                }, 0);
            }
        };

        return directive;
    }
})();
