(function() {
    'use strict';

    angular.module('serviceApp').directive('signalActions', signalActions);

    function signalActions() {
        var directive = {
            restrict: 'E',
            scope: {
                user: '<',
                content: '=',
                showTitle: '=?',
                actions: '=?',
                showMore: '=?',
                actionsCallback: '&?'
            },
            template: require('views/tmpl/partials/signalActions.html'),
            controller: SignalActionsController
        };

        return directive;
    }

    SignalActionsController.$inject = ['_', '$scope', 'searchServiceNew', '$q', 'galleryUtilsService'];

    function SignalActionsController(_, $scope, searchServiceNew, $q, galleryUtilsService) {
        $scope.toggleConfirmDismiss = toggleConfirmDismiss;
        $scope.updateActionCallback = updateActionCallback;
        $scope.dismissAll = dismissAll;
        $scope.doBlur = doBlur;
        $scope.openBoard = openBoard;
        $scope.toggleShowMore = toggleShowMore;
        $scope.showConfirmModal = false;

        $scope.showMore = _.isNil($scope.showMore) ? false : $scope.showMore;
        $scope.showTitle = _.isNil($scope.showTitle) ? true : $scope.showTitle;

        $scope.signals = _.get($scope.content, 'signals', []);

        $scope.actions = getActions($scope.signals);

        function getActions(signals) {
            var actions = _.map(signals, function(signal) {
                var mappedActions = _.map(signal.actions, function(action) {
                    _.set(action, 'start', signal.start);
                    _.set(action, 'impact', signal.impact);
                    _.set(action, 'signalHashId', signal.signalHashId);
                    return action;
                });

                mappedActions = _.filter(mappedActions, function(mapped) {
                    return !mapped.state || mapped.state == 'done';
                });

                return mappedActions;
            });

            actions = _.flatten(actions);
            actions = _.sortBy(actions, 'start');
            actions = _.reverse(actions);

            return actions;
        }

        function updateActionCallback(data, dismissAll) {
            if (dismissAll) {
                var promises = _.forEach($scope.signals, function(signal) {
                    var actions = _.map(signal.actions, function(action) {
                        _.unset(action, 'signalHashId');
                        _.unset(action, 'start');
                        _.unset(action, 'impact');
                        _.set(action, 'state', 'dismiss');
                        return action;
                    });

                    return searchServiceNew.updateSignalActions(
                        $scope.content.id,
                        signal.signalHashId,
                        actions
                    );
                });

                $q.all(promises).then(function() {
                    $scope.actions = getActions($scope.signals);
                    $scope.actionsCallback({ actions: $scope.actions });
                    $scope.toggleConfirmDismiss();
                    $scope.$root.$emit('actionsChanged', {
                        signals: $scope.signals,
                        contentId: $scope.content.id
                    });
                });
            } else {
                var actions = _.filter(_.cloneDeep($scope.actions), [
                    'signalHashId',
                    data.action.signalHashId
                ]);

                var hash = _.clone(data.action.signalHashId);

                _.unset(data.action, 'signalHashId');
                _.unset(data.action, 'start');
                _.unset(data.action, 'impact');

                searchServiceNew.updateSignalActions($scope.content.id, hash, actions).then(
                    function(content) {
                        $scope.actions = getActions($scope.signals);
                        $scope.actionsCallback({ actions: $scope.actions });
                        $scope.$root.$emit('actionsChanged', {
                            signals: $scope.signals,
                            contentId: $scope.content.id
                        });
                    },
                    function(err) {
                        console.error(err);
                    }
                );
            }
        }

        function toggleConfirmDismiss() {
            $scope.showConfirmModal = !$scope.showConfirmModal;
        }

        function dismissAll() {
            $scope.updateActionCallback(null, true);
        }

        function doBlur(event) {
            var target = event.target;
            target.blur();
        }

        function openBoard() {
            galleryUtilsService.openBoard($scope.content.boardId);
        }

        function toggleShowMore() {
            $scope.showMore = !$scope.showMore;
        }
    }
})();
