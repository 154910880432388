'use strict';
import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('actionItemCompact', () => ({
    restrict: 'E',
    scope: {
        dark: '=?', // if rendered on a dark background set dark to true
        action: '=',
        options: '=?',
        toggleContext: '&',
        executeEventTrigger: '&',
        removeCompletedAction: '&',
        isAutoRunAction: '<'
    },
    template,
    controller: Controller
}));
