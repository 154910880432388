'use strict';

/**
 * Angular wrapper for our <EngagementMetrics /> react component.
 */

export default class EngagementMetrics {
    static get $inject() {
        return [];
    }

    constructor() {}

    $onInit() {
        // Props being passed to our react component
        this.data = {
            payload: this.payload
        };
    }
}
