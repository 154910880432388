(function() {
    'use strict';
    angular.module('serviceApp').factory('goalService', GoalService);

    GoalService.$inject = ['_', '$resource', '$q'];

    function GoalService(_, $resource, $q) {
        const goalAPIUrl = '/goals/v1/api/goal';
        const defaultParams = {};

        const GoalsResourceApi = {
            query: {
                method: 'POST',
                url: `${goalAPIUrl}/query`
            },
            saveEntries: {
                method: 'POST',
                url: `${goalAPIUrl}/entries`
            },
            saveEntry: {
                method: 'GET',
                url: `${goalAPIUrl}/entries/:id`,
                params: {
                    id: '@id'
                }
            },
            getEntries: {
                method: 'GET',
                url: `${goalAPIUrl}/entries/:id`,
                params: {
                    id: '@id'
                }
            },
            deleteAllEntries: {
                method: 'DELETE',
                url: `${goalAPIUrl}/:goalId/entries/`,
                params: {
                    goalId: '@goalId'
                }
            }
        };

        const goalsApiResource = $resource(goalAPIUrl, defaultParams, GoalsResourceApi);

        const service = { getEntries, saveEntries, deleteAllEntries };

        return service;

        ////////////

        function getEntries({ goalId, hierarchyId, segmentId } = {}) {
            if (!goalId) {
                return $q.reject({ msg: 'goalId must be defined' });
            }

            const body = {
                goalId,
                hierarchyId,
                segmentId: segmentId
            };

            return $q.when(goalsApiResource.query({}, body).$promise).then(res => {
                return res.result;
            });
        }

        function saveEntries({ entries } = {}) {
            if (!entries) {
                return $q.reject({ msg: 'entries must be defined' });
            }

            const body = {
                rows: entries
            };

            return $q.when(goalsApiResource.saveEntries({}, body).$promise);
        }

        function deleteAllEntries(goalId) {
            if (!goalId) {
                return $q.reject({ msg: 'goal Id is Required' });
            }

            return $q.when(goalsApiResource.deleteAllEntries({ goalId }).$promise);
        }
    }
})();
