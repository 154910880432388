'use strict';

angular.module('serviceApp').directive('draggable', [
    () => ({
        restrict: 'E',
        scope: {
            dropCallback: '&', //Callback handler to to the work of on drop that includes src/target
            ngClass: '<?', //The drop-zone container class to constrain the drag and drop to that container
            disabled: '<?'
        },
        link: function(scope, elem, attr) {
            elem.attr('draggable', true);

            //listen to changes on disable drag
            let disableWatcher = scope.$watch(
                'disabled',
                function() {
                    elem.attr('draggable', !scope.disabled);
                },
                true
            );

            scope.$on('$destroy', function() {
                disableWatcher();
            });

            let dragDataVal = '';
            attr.$observe('id', function(newVal) {
                dragDataVal = newVal;
            });

            //Enable dragging element over a drop-able element
            function onDragOver(e) {
                let overElem;
                if (!e || !e.target) {
                    return;
                }
                if (e.preventDefault) {
                    e.preventDefault();
                }
                if (e.stopPropagation) {
                    e.stopPropagation();
                }

                //Determine if there is a drop-zone class to indicate a valid spot on dragover.
                if (scope.$root.dragClass) {
                    try {
                        overElem = e.target.closest(`.${scope.$root.dragClass}`);
                    } catch (exc) {
                        //not a valid element
                        return;
                    }
                }
                if (overElem) {
                    overElem.className = scope.$root.dragClass + ' drop-zone';
                }
                e.dataTransfer.dropEffect = 'move';
                return false;
            }

            //Called when a dragging element leaves this draggable element
            function onDragLeave(e) {
                let overElem;
                if (!e || !e.target) {
                    return;
                }
                if (e.stopPropagation) {
                    e.stopPropagation();
                }

                //Clear out drop-zone styling once a drag leaves this component
                if (scope.$root.dragClass) {
                    try {
                        overElem = e.target.closest(`.${scope.$root.dragClass}`);
                    } catch (exc) {
                        //not a valid element
                        return;
                    }
                }
                if (overElem) {
                    overElem.className = scope.$root.dragClass;
                }
            }

            //Begin drag setting the source id
            function onDragStart(e) {
                e.dataTransfer.effectAllowed = 'copyMove';
                e.dataTransfer.setData('Text', dragDataVal);

                //Set the drop-zone container class if supplied. This allows the draggable event
                //listeners to check if they are in drop-zone and style on enter and leave
                scope.$root.dragClass = scope.ngClass;
                if (e.stopPropagation) {
                    e.stopPropagation();
                }
            }

            //On drop get the ID of the over target element and communicate a drop action
            function onDrop(e) {
                if (e.preventDefault) {
                    e.preventDefault();
                }
                if (e.stopPropagation) {
                    e.stopPropagation();
                }
                var src = e.dataTransfer.getData('Text');
                var headerElem = e.target.closest('draggable');
                var target = headerElem.id;

                //Clear out added drop zone classes
                headerElem.className = scope.ngClass;

                //Check if there is a valid drop-zone container. If there is a container
                //check if it's a valid drop zone.
                if (!scope.ngClass || scope.ngClass === scope.$root.dragClass) {
                    //Drop callback that includes the source, target and optional container class
                    scope.dropCallback({ data: { src, target, container: scope.ngClass } });
                }
                //Reset active drag class
                scope.$root.dragClass = undefined;
            }

            elem.bind('dragover', onDragOver);
            elem.bind('dragleave', onDragLeave);
            elem.bind('drop', onDrop);
            elem.bind('dragstart', onDragStart);
        }
    })
]);
