(function() {
    'use strict';

    angular.module('serviceApp').directive('cardHeaderMetrics', cardHeaderMetrics);

    function cardHeaderMetrics() {
        var directive = {
            restrict: 'E',
            scope: {
                metrics: '='
            },
            template: require('views/tmpl/cards/metrics/header.html'),
            controller: CardHeaderMetricsController
        };

        return directive;
    }

    CardHeaderMetricsController.$inject = ['_', '$scope', 'moment', '$filter'];

    function CardHeaderMetricsController(_, $scope, $moment, $filter) {
        $scope.format = format;
        $scope.getMetrics = getMetrics;
        $scope.getDisplayMetrics = getDisplayMetrics;

        $scope.displayMetrics = $scope.getDisplayMetrics();

        $scope.$watch(
            'metrics',
            function() {
                $scope.displayMetrics = $scope.getDisplayMetrics();
            },
            true
        );

        function getDisplayMetrics() {
            var displayMetrics = _.map(_.get($scope.metrics, 'data'), function(value, key) {
                return $scope.getMetrics(key, value);
            });

            displayMetrics = _.flatten(displayMetrics);
            return displayMetrics;
        }

        function getMetrics(dataSetName, dataSetValues) {
            var display = getDisplay(dataSetName);
            var metrics = [];
            _.forEach(dataSetValues, function(dataSetItem) {
                metrics.push(
                    _.map(dataSetItem, function(value, key) {
                        var displayObject = _.find(display.displayOptions, ['field', key]);

                        if (!displayObject) {
                            return undefined;
                        }
                        var itemsToReturn = [];
                        _.forEach(displayObject.titles, function(titleObject) {
                            itemsToReturn.push({
                                title: titleObject.title,
                                value: titleObject.calc
                                    ? $scope.format(value[titleObject.calc], key)
                                    : $scope.format(value, key)
                            });
                        });

                        return itemsToReturn;
                    })
                );
            });

            metrics = _.flattenDeep(metrics);

            metrics = _.filter(metrics, function(item) {
                return item !== undefined;
            });

            return metrics;
        }

        function getDisplay(dataSetName) {
            var display = _.get($scope.metrics, 'display');
            if (!display) {
                return undefined;
            }

            var dataSetDisplay = _.find(display.dataSets, ['name', dataSetName]);
            return dataSetDisplay;
        }

        function format(value, key, options) {
            if (key == 'duration') {
                return $moment.duration(_.round(value), 'milliseconds').humanize();
            }

            if (key == 'trend') {
                return (value * 100).toFixed(1) + '%';
            }

            if (key == 'velocity') {
                return $filter('currency')(value.toFixed(0), options) + ' per day';
            }

            return value;
        }
    }
})();
