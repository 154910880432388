'use strict';

import './index.scss';
import template from './index.html';

angular.module('serviceApp').directive('cardInsightsEmptyBoard', () => {
    return {
        template: template
    };
});
