import { validDateFormats } from '../../scripts/utils/properties';

(function() {
    'use strict';

    angular.module('serviceApp').filter('daysAway', daysAway);

    daysAway.$inject = ['_', '$filter', 'moment', '$rootScope'];
    function daysAway(_, $filter, $moment, $rootScope) {
        let filter = function(n) {
            const timezone = _.get($rootScope, 'timezone', 'UTC');
            const date = $moment(n, validDateFormats, true).tz(timezone); // The passed in date should already be in the correct timezone
            const today = $moment().tz(timezone);
            if (!n || _.isNil(n)) {
                return 'N/A';
            } else if (!date.isValid()) {
                return n;
            }

            //See if today is the same day as the date inputted
            const sameDay = date.isSame(today, 'day', true);

            //If today, label it 'Today' with time.
            if (sameDay) {
                const time = date.format('h:mma');
                return `Today at ${time}`;
            }

            //Find the differential in days
            const difference = Math.abs(date.diff(today, 'days', true));

            //Find the differential in intuitive whole days
            //Keeping the date but setting the time to match effectively lets us round up/down based on time and timezone
            //a 1.5 day difference will become 1 day if it was (1st 00:01 - 2nd 12:01) or 2 days for (1st 23:00 - 3rd 11:00)
            const todayMidday = today.clone().set({ hour: 12, minute: 0, second: 0 });
            const dateMidday = date.clone().set({ hour: 12, minute: 0, second: 0 });
            const differenceWholeDays = Math.abs(Math.round(dateMidday.diff(todayMidday, 'days', true)));
            const differenceFiltered = $filter('number')(differenceWholeDays, 0);

            //The day difference will less than 1 if it's less than 24 hours "Yesterday" or "Tomorrow"
            if (difference < 1) {
                const time = date.format('h:mma');
                return date.isAfter(today) ? `Tomorrow at ${time}` : `Yesterday at ${time}`;
            }

            if (differenceWholeDays == 1) {
                return date.isAfter(today)
                    ? `${differenceFiltered} day from now`
                    : `${differenceFiltered} day ago`;
            }

            //Return the differential in the future or the past
            return date.isAfter(today, 'day')
                ? `${differenceFiltered} days from now`
                : `${differenceFiltered} days ago`;
        };

        return filter;
    }
})();
