'use strict';

/**
 * Angular wrapper for our <AddCardModal /> react component.
 */

export default class AddCardModal {
    static get $inject() {
        return ['$scope'];
    }
    constructor($scope) {
        this.scope = $scope;
        this.onClose = () => {
            this.closeModal();
        };
    }

    $onInit() {
        this.data = {
            open: true,
            row: this.row,
            position: this.position
        };
        this.hooks = {
            onClose: this.onClose,
            setCard: this.setCard
        };
    }
}
