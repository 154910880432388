(function() {
    'use strict';

    angular.module('serviceApp').filter('durationInDays', durationInDays);

    durationInDays.$inject = ['_', 'moment', '$filter'];

    function durationInDays(_, $moment, $filter) {
        const filter = (milliseconds, formatAsNumber = true) => {
            if (_.isNil(milliseconds)) {
                return formatAsNumber ? 'N/A' : undefined;
            }

            const days = _.round($moment.duration(milliseconds).asDays());
            let daysAsNumber = `${$filter('number')(days)} day`;
            if (days !== 1) {
                daysAsNumber = $filter('pluralize')(daysAsNumber);
            }

            return formatAsNumber ? daysAsNumber : days;
        };

        return filter;
    }
})();
