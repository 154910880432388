import _ from 'lodash';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('autoRunOptInAction', () => ({
    restrict: 'E',
    scope: {
        action: '=',
        eventTrigger: '=',
        executeEventTrigger: '&',
        user: '=?',
        preview: '<'
    },
    template,
    controller: Controller
}));

class Controller {
    constructor($scope, $rootScope) {
        const lowerUserEmail = _.toLower(_.get($scope, 'user.email'));
        const assignees = _.cloneDeep(_.get($scope, 'action.assignees', []));

        $scope.userIsAssignee = !$scope.action || !!_.find(assignees, { email: lowerUserEmail });

        $scope.iconHost = $rootScope.productPrimaryHostname;

        // TODO add drawer close function

        $scope.optIn = () =>
            $scope.executeEventTrigger({
                data: {
                    eventTrigger: 'automation-user-opt-in',
                    action: $scope.action,
                    payload: _.get($scope.eventTrigger, 'payload')
                }
            });

        $scope.dismiss = () =>
            $scope.executeEventTrigger({
                data: {
                    eventTrigger: 'automation-user-opt-out',
                    action: $scope.action,
                    payload: _.get($scope.eventTrigger, 'payload')
                }
            });
    }
}

Controller.$inject = ['$scope', '$rootScope'];
