'use strict';

// Define an abstract "meta-form" directive that will show the correct metadata form for the definition type.
// Some definition types may not have forms defined.
angular.module('serviceApp').directive('definitionEditorMetaForm', definitionEditorMetaForm);
function definitionEditorMetaForm($compile) {
    var directive = {
        restrict: 'E',
        scope: { definition: '=' },
        replace: true,
        link: function($scope, $element, $attrs) {
            $element.html(
                `<definition-editor-meta-form-${$scope.definition.definitionType} definition="definition"/>`
            );
            $compile($element.contents())($scope);
        }
    };
    return directive;
}
definitionEditorMetaForm.$inject = ['$compile'];
