'use strict';

import template from './index.html';
import controller from './controller';

angular.module('serviceApp').component('cardFacetMenu', {
    controller,
    template,
    bindings: {
        property: '<',
        selectedValue: '&',
        openEdit: '&',
        card: '<',
        getSelectedContextBreakdown: '<'
    }
});
