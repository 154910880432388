(function() {
    'use strict';

    angular.module('serviceApp').factory('contentFiltersService', ContentFiltersService);

    ContentFiltersService.$inject = ['_', '$resource', '$q'];

    function ContentFiltersService(_, $resource, $q) {
        var contentFiltersUrl = '/api/content-filters';
        var defaultParams = {};

        var contentFiltersActions = {
            getContentFilters: {
                method: 'GET',
                url: contentFiltersUrl,
                isArray: true
            },
            updateContentFilters: {
                method: 'POST',
                url: contentFiltersUrl,
                isArray: true
            }
        };

        var contentFiltersResource = $resource(contentFiltersUrl, defaultParams, contentFiltersActions);

        var service = {
            getContentFilters: getContentFilters,
            updateContentFilters: updateContentFilters
        };

        return service;

        ////////////

        function getContentFilters() {
            return $q.when(contentFiltersResource.getContentFilters().$promise);
        }

        function updateContentFilters(contentFilters) {
            if (!contentFilters) {
                return $q.reject({
                    msg: 'content filters must be defined'
                });
            }
            return $q.when(contentFiltersResource.updateContentFilters({}, contentFilters).$promise);
        }
    }
})();
