(function() {
    'use strict';

    angular.module('serviceApp').directive('boardAppTile', boardAppTile);

    function boardAppTile() {
        var directive = {
            restrict: 'E',
            scope: {
                userBoard: '=',
                user: '<'
            },
            template: require('views/tmpl/partials/boardAppTile.html'),
            controller: BoardAppTileController
        };

        return directive;
    }

    BoardAppTileController.$inject = ['$scope', 'boardsUtilsService', 'toastr', '$state'];

    function BoardAppTileController($scope, boardsUtilsService, toastr, $state) {
        $scope.showMenu = false;

        $scope.toggleShowMenu = function() {
            $scope.showMenu = !$scope.showMenu;
        };

        $scope.openBoard = function() {
            boardsUtilsService.openBoard($scope.userBoard);
        };

        $scope.openBoardManager = function() {
            boardsUtilsService.openBoardManager($scope.userBoard);
        };

        $scope.urlOrIcon = function(board) {
            if (board.iconUrl) {
                return board.iconUrl.substring(0, 13) == 'font-awesome:' ? 'icon' : 'url';
            } else {
                return null;
            }
        };

        $scope.getBoardFaviconUrl = function(board) {
            var url = '';
            if (board.iconUrl) {
                return board.iconUrl;
            } else {
                url = '//logo.clearbit.com/' + board.rootDomain;
                return url;
            }
        };

        $scope.hideBoard = function(board) {
            userServiceNew.hideBoard($scope.user, board.id).then(
                function(updatedUser) {
                    toastr.success('Board hidden!', '');
                    $state.go('home.main');
                },
                function(error) {
                    toastr.error('Failed to hide board: ' + error, '');
                }
            );
        };
    }
})();
