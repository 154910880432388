'use strict';

import './index.scss';
import template from './index.html';
import controller from './controller';

angular.module('serviceApp').directive('cardInsight', () => {
    return {
        scope: {
            board: '<',
            panel: '<',
            card: '<',
            user: '<',
            hierarchy: '<?',
            query: '=',
            facets: '=',
            definition: '=',
            filterData: '<',
            loadingTable: '=',
            getCardHitch: '&',
            buildDownloadUrlHitch: '&?',
            hideMenuOnError: '<'
        },
        template: template,
        controller,
        link: ($scope, elems, attrs, ctrl) => {
            $scope.cardElement = elems[0];
        }
    };
});
