'use strict';

export default class MetricTrendConditionSelector {
    static get $inject() {
        return ['$scope'];
    }

    constructor(scope) {
        this.scope = scope;
        this.scope.selectedCondition = undefined;
        this.scope.onConditionSelected = this.onConditionSelected.bind(this);
    }

    /**
     * onConditionSelected - when a condition is selected call the onConditionAdded callback
     * and unset the selectedCondition value
     */
    onConditionSelected() {
        this.scope.onConditionAdded({ data: { condition: this.scope.selectedCondition } });
        this.scope.selectedCondition = undefined;
    }
}
