(function() {
    'use strict';
    angular.module('serviceApp').directive('userIntegrationStatus', userIntegrationStatus);
    function userIntegrationStatus() {
        var directive = {
            restrict: 'E',
            scope: { integrationUser: '=', api: '=' },
            template: require('views/tmpl/partials/userIntegrationStatus.html'),
            controller: UserIntegrationStatusController
        };

        return directive;
    }

    UserIntegrationStatusController.$inject = ['_', '$scope', 'toastr', 'integrationService'];

    function UserIntegrationStatusController(_, $scope, toastr, integrationService) {
        $scope.closeEditor = () => {
            $scope.$root.$emit('rightDrawer:close');
        };

        $scope.isObject = item => {
            var testObject;
            try {
                testObject = JSON.parse(item);
            } catch (e) {
                testObject = item;
            }
            return _.isObject(testObject);
        };

        $scope.canSync = () => {
            if (_.get($scope.integrationUser, 'pollPrimaryOnly')) {
                return _.get($scope.integrationUser, 'id') === _.get($scope.integrationUser, 'primaryUserId');
            }
            return true;
        };

        $scope.syncIntegration = isFullSync => {
            const userEmail = _.get($scope.integrationUser, 'email');
            const integrationId = _.get(
                $scope.integrationUser,
                'integrationStatus.integration.integrationId'
            );

            if (!integrationId) {
                toastr.error('Could not find integrationId', err);
                return;
            }

            var options = {};

            if (isFullSync) {
                _.set(options, 'syncReset', true);
            }

            integrationService.syncUserIntegration(integrationId, options, userEmail).then(
                function(data) {
                    toastr.success('Integration sync attempted, refreshing.', '');
                    $scope.api.refresh();
                },
                function(err) {
                    toastr.error('An error occurred', err);
                }
            );
        };

        $scope.setPrimary = () => {
            const integrationId = _.get(
                $scope.integrationUser,
                'integrationStatus.integration.integrationId'
            );

            var props = {};
            _.set(props, 'primaryUserId', _.get($scope.integrationUser, 'id'));

            integrationService.editTeamIntegration(integrationId, props, null).then(
                function(data) {
                    toastr.success('Integration set primary user successful.', '');
                    $scope.api.refresh();
                },
                function(err) {
                    toastr.error('An error occurred', err);
                }
            );
        };

        $scope.enableIntegration = () => {
            const userEmail = _.get($scope.integrationUser, 'email');
            const integrationId = _.get(
                $scope.integrationUser,
                'integrationStatus.integration.integrationId'
            );

            if (!integrationId) {
                toastr.error('Could not find integrationId', err);
                return;
            }

            integrationService.enableUserIntegration(integrationId, null, userEmail).then(
                function(data) {
                    toastr.success('Integration enabled, refreshing.', '');
                    $scope.api.refresh();
                },
                function(err) {
                    toastr.error('An error occurred', err);
                }
            );
        };

        $scope.deleteIntegration = () => {
            const userEmail = _.get($scope.integrationUser, 'email');
            const integrationId = _.get(
                $scope.integrationUser,
                'integrationStatus.integration.integrationId'
            );

            if (!integrationId) {
                toastr.error('Could not find integrationId', err);
                return;
            }

            integrationService.forceDeleteUserIntegration(integrationId, null, userEmail).then(
                function(data) {
                    toastr.success('Integration deleted, refreshing.', '');
                    $scope.api.refresh();
                },
                function(err) {
                    toastr.error('An error occurred', err);
                }
            );
        };
    }
})();
