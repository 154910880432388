import momenttz from 'moment-timezone';

(function() {
    'use strict';

    angular.module('serviceApp').directive('cardTeamDetails', cardTeamDetails);

    function cardTeamDetails() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                team: '=',
                user: '<'
            },
            template: require('views/tmpl/cards/settings/team/cardTeamDetails.html'),
            controller: CardTeamDetailsController
        };

        return directive;
    }

    CardTeamDetailsController.$inject = ['$scope', '$rootScope', 'calendarService', 'appSettingsService'];

    function CardTeamDetailsController($scope, $rootScope, calendarService, appSettingsService) {
        $scope.productName = $rootScope.productName;
        $scope.domains = _.isEmpty($scope.team.domains) ? [] : _.cloneDeep($scope.team.domains);
        $scope.partnerDomains = _.isEmpty($scope.team.partnerDomains)
            ? []
            : _.cloneDeep($scope.team.partnerDomains);
        $scope.editTeam = _.cloneDeep($scope.team);
        $scope.forms = {};

        $scope.navigation = _.cloneDeep(_.get($scope, 'user.navigation.primary'));

        // Hack to work around integer values in ng-select
        if (_.isString($scope.editTeam.fiscalMonthOffset)) {
            $scope.editTeam.fiscalMonthOffset = _.toNumber($scope.editTeam.fiscalMonthOffset);
        }

        $scope.currentBoardType = _.get($scope, 'team.currentHomeBoard');
        $scope.editTeam.currentHomeBoard = $scope.currentBoardType;

        // Using this roundabout way due to `momenttz.tz.names()` having deprecated timezones like `US/Pacific`.
        // @see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
        // @see https://en.wikipedia.org/wiki/Tz_database
        const zones = new Set();
        const countries = momenttz.tz.countries();
        for (const country of countries) {
            momenttz.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones);
        }
        $scope.availableTimezones = [...zones].sort();

        $scope.availableMonths = [
            { name: 'January', index: 0 },
            { name: 'February', index: 1 },
            { name: 'March', index: 2 },
            { name: 'April', index: 3 },
            { name: 'May', index: 4 },
            { name: 'June', index: 5 },
            { name: 'July', index: 6 },
            { name: 'August', index: 7 },
            { name: 'September', index: 8 },
            { name: 'October', index: 9 },
            { name: 'November', index: 10 },
            { name: 'December', index: 11 }
        ];

        $scope.availableRetailCalendarTypes = ['4-4-5', '5-4-4', '4-5-4'];
        $scope.availableLastWeekCalculations = [
            {
                name: `Last ${$scope.editTeam.lastDayOfWeek} nearest end of month`,
                value: 'lastDayNearestEOM'
            },
            {
                name: `Last ${$scope.editTeam.lastDayOfWeek} before end of month`,
                value: 'lastDayBeforeEOM'
            },
            {
                name: 'First week of the year',
                value: 'firstBOWOfFirstMonth'
            }
        ];
        $scope.availableDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        $scope.getRetailCalendarPreview = function() {
            calendarService
                .getRetailCalendarPreview({
                    team: _.pick($scope.editTeam, [
                        'id',
                        'timezone',
                        'fiscalMonthOffset',
                        'fiscalYearBasedOnStart',
                        'useRetailCalendar',
                        'retailCalendarType',
                        'lastDayOfWeek',
                        'lastWeekCalculation'
                    ])
                })
                .then(result => {
                    $scope.retailPreview = result;
                })
                .catch(err => {
                    console.error('ERROR getting retail calendar preview', err);
                });
        };
        if ($scope.editTeam.useRetailCalendar && _.isNil($scope.retailPreview)) {
            $scope.getRetailCalendarPreview();
        }

        $scope.$watch('currentBoardType', board => {
            $scope.editTeam.currentHomeBoard = board;
            // home page selector second level dropdown isn't recognized as part of the form,
            // so if only that is changed the watcher won't trigger
            if (_.includes(board, '$')) {
                $scope.$root.$emit('updateTeamDetails', $scope.editTeam);
            }
        });

        $scope.$watch(
            'forms.teamDetails.$dirty',
            dirty => {
                if (dirty) {
                    $scope.editTeam.domains = _.isEmpty($scope.domains) ? [] : _.cloneDeep($scope.domains);
                    $scope.editTeam.partnerDomains = _.isEmpty($scope.partnerDomains)
                        ? []
                        : _.cloneDeep($scope.partnerDomains);
                    if ($scope.editTeam.useRetailCalendar) {
                        // set defaults
                        if (_.isNil(_.get($scope.editTeam, 'retailCalendarType'))) {
                            _.set(
                                $scope.editTeam,
                                'retailCalendarType',
                                _.first($scope.availableRetailCalendarTypes)
                            );
                        }
                        if (_.isNil(_.get($scope.editTeam, 'lastDayOfWeek'))) {
                            _.set($scope.editTeam, 'lastDayOfWeek', 'Saturday');
                            $scope.updateWeekCalculationNames();
                        }
                        if (_.isNil(_.get($scope.editTeam, 'lastWeekCalculation'))) {
                            _.set($scope.editTeam, 'lastWeekCalculation', 'lastDayNearestEOM');
                        }
                        if (_.isNil($scope.retailPreview)) {
                            $scope.getRetailCalendarPreview();
                        }
                    }
                    $scope.$root.$emit('updateTeamDetails', $scope.editTeam);
                    $scope.iconUrl = $scope.urlDomain();
                    $scope.forms.teamDetails.$setPristine();
                }
            },
            false
        );

        // If the user changes any relevant calendar settings, re-render the retail cal example.
        $scope.$watchGroup(
            [
                'editTeam.retailCalendarType',
                'editTeam.lastDayOfWeek',
                'editTeam.lastWeekCalculation',
                'editTeam.fiscalMonthOffset'
            ],
            () => {
                if ($scope.editTeam.useRetailCalendar) {
                    $scope.getRetailCalendarPreview();
                }
            }
        );

        $scope.updateWeekCalculationNames = function() {
            $scope.availableLastWeekCalculations = [
                {
                    name: `Last ${$scope.editTeam.lastDayOfWeek} nearest end of month`,
                    value: 'lastDayNearestEOM'
                },
                {
                    name: `Last ${$scope.editTeam.lastDayOfWeek} before end of month`,
                    value: 'lastDayBeforeEOM'
                },
                {
                    name: 'First week of the year',
                    value: 'firstBOWOfFirstMonth'
                }
            ];
        };

        $scope.urlDomain = () => {
            let domain = !_.isEmpty($scope.domains)
                ? _.join(_.takeRight(_.split(_.last($scope.domains), '.'), 2), '.')
                : $rootScope.productPrimaryHostname;
            return '//logo.clearbit.com/' + domain;
        };

        $scope.iconUrl = $scope.urlDomain();

        $scope.enableCoreAuthLinking = appSettingsService.checkVariationSync('rev360-manual-linking', {
            default: true
        });
    }
})();
