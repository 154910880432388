(function() {
    'use strict';

    angular.module('serviceApp').directive('cardIntegrationStats', cardIntegrationStats);

    function cardIntegrationStats() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                user: '<',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/integrations/stats.html'),
            controller: CardIntegrationStatsController
        };

        return directive;
    }

    CardIntegrationStatsController.$inject = ['_', '$scope', 'appServiceNew'];

    function CardIntegrationStatsController(_, $scope, appServiceNew) {
        $scope.dataLabels = [];
        $scope.series = [];
        $scope.data = [];

        var stats = _.get($scope.card, 'properties.stats');

        $scope.dataLabels = _.map(stats, 'label');

        $scope.chartopts = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                yAlign: 'below',
                bodyFontSize: 10
            }
        };

        _.forEach(stats, function(stat) {
            _.forEach(stat.policies, function(policy) {
                $scope.series.push(policy.contentPolicy);
            });
        });

        $scope.series = _.uniq($scope.series);
        $scope.data = _.map($scope.series, function(series) {
            var arr = new Array(stats.length);
            return _.fill(arr, 0);
        });

        _.forEach(stats, function(stat, statIndex) {
            _.forEach(stat.policies, function(policy) {
                var seriesIndex = _.findIndex($scope.series, function(series) {
                    return series == policy.contentPolicy;
                });
                _.set($scope.data, '[' + seriesIndex + '][' + statIndex + ']', policy.eventCount);
            });
        });
    }
})();
