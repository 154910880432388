'use strict';
import _ from 'lodash';

/**
 * Add/update the `displayLabel` property in each object in an array to ensure a unique and user friendly label.
 * (This will not mutate the original array or objects)
 * @param {Array<Object>} objArray - The array of objects we're updating.
 * @param {function(String, String): String} getPropertyLabel - The method we should use to build the default property label
 * @param {function(String): String} getContentTypeLabel - The method we should use to build a content type label
 * @param {String} pathProperty - The property containing the property path, defaults to 'path'.
 * @returns {Array<Object>} - A new array containing the updated objects.
 */
export const addDisplayLabels = (objArray, getPropertyLabel, getContentTypeLabel, pathProperty = 'path') => {
    if (typeof getPropertyLabel !== 'function' || typeof getContentTypeLabel !== 'function') {
        return objArray;
    }

    // Ensure each object has a default display label
    const withDefaultDisplayLabels = objArray.map(column => {
        const [propertyName, contentType] = _.get(column, pathProperty, '')
            .split('.')
            .filter(p => p !== '_lastModified')
            .slice(-2)
            .reverse();
        const newDisplayLabel =
            _.get(column, 'displayLabel', '') || getPropertyLabel(propertyName, contentType) || propertyName;

        return {
            ...column,
            displayLabel: newDisplayLabel
        };
    });

    // De-duplicate the default labels
    const deduplicatedLabels = _.chain(withDefaultDisplayLabels)
        .groupBy('displayLabel')
        .flatMap(duplicates => {
            // No matching labels
            if (duplicates.length <= 1) {
                return duplicates;
            }
            // Apply a suitable modification to the label to de-dupe
            duplicates.forEach((duplicate, i, arr) => {
                const originalLabel = duplicate.displayLabel;
                const contentType = _.get(duplicate, pathProperty, '').split('.')[0];
                if (
                    arr.some(
                        (col, j) => i !== j && _.get(col, pathProperty, '').split('.')[0] === contentType
                    )
                ) {
                    // If there are duplicates within the same content type
                    duplicate.displayLabel = `${originalLabel} (${_.startCase(
                        _.get(duplicate, pathProperty, '')
                    )})`;
                } else {
                    // If there are only matches in other content types
                    duplicate.displayLabel = `${getContentTypeLabel(contentType)} ${originalLabel}`;
                }
            });
            return duplicates;
        })
        .value();

    return deduplicatedLabels;
};
