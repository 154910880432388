angular.module('serviceApp').factory('galleryUtilsService', [
    '_',
    '$q',
    'appServiceNew',
    'searchServiceNew',
    '$http',
    '$window',
    '$log',
    'toastr',
    '$state',
    '$rootScope',
    function(_, $q, appServiceNew, searchServiceNew, $http, $window, $log, toastr, $state, $rootScope) {
        var timeoutId;

        return {
            getIconUrl: function(searchResult) {
                if (searchResult.iconURL) {
                    return searchResult.iconURL;
                } else {
                    var matches = _.get(searchResult, 'url');
                    if (matches) {
                        matches = matches.match(/:\/\/(.[^/]+)/);
                    } else {
                        return '';
                    }
                    var domain = matches !== null && typeof matches[1] != 'undefined' ? matches[1] : null;
                    var url = '//logo.clearbit.com/' + domain;
                    return url;
                }
            },
            openContent: function(searchResult, queryString, linkUsed, boardId) {
                var isDesktopClient = $window.navigator.userAgent.indexOf('nexd-desktop-client') === 0;
                var source = isDesktopClient ? $window.navigator.userAgent : 'nexd-web-client';
                if (searchResult.url.indexOf('file://') !== 0) {
                    $log.debug('launching URL: ' + searchResult.url);
                    $window.open(searchResult.url, '_blank');
                } else {
                    $log.debug('client will launch URL: ' + searchResult.url);

                    // Let web users know that we are attempting to open the file on their desktop
                    if (source === 'nexd-web-client') {
                        toastr.success('Opening file on desktop', '');
                    }
                }
                var query = queryString || '';
                boardId = boardId || '';

                $log.debug('click ' + searchResult.url);
                $log.debug('   contentId: ' + searchResult.id);
                $log.debug('   currentBoard: ' + boardId);
                $log.debug('   currentQuery: ' + query);
                $log.debug('   link used: ' + linkUsed);
                $log.debug('   source: ' + source);

                searchServiceNew.logClick(searchResult.id, boardId, query, linkUsed, source, function() {});
            },

            hideContent: function(searchResult) {
                $log.debug('Deleting content: ' + searchResult.url);
                return $http
                    .delete('/api/content', {
                        params: {
                            contentUrl: searchResult.url
                        }
                    })
                    .then(
                        function(data) {
                            $log.debug('delete /api/content results: ' + data);

                            toastr.success("Content link hidden - It's outta here!", '');
                        },
                        function(data) {
                            $log.error('failure deleting: ' + data);
                        }
                    );
            },

            hideAllContent: function(searchResult) {
                return appServiceNew.getApp(searchResult.appId).then(function(app) {
                    var message = '';
                    if (app.contentPolicies.length > 1) {
                        var contentPolicyIndex = _.findIndex(app.contentPolicies, [
                            'name',
                            searchResult.contentPolicy
                        ]);
                        if (contentPolicyIndex > -1) {
                            app.contentPolicies[contentPolicyIndex].indexLevel = 'disable';
                            message = app.contentPolicies[contentPolicyIndex].title;
                        }
                    } else {
                        app.events = 'disable';
                        message = app.title;
                    }
                    return appServiceNew.updateApp(app).then(function(app) {
                        toastr.success('All ' + message + ' content being hidden - going going...', '');
                        $rootScope.$emit('contentEvent', {
                            message: 'hideAll'
                        });
                    });
                });
            },

            isRecentContent: function(searchResult) {
                var ONE_HOUR = 60 * 60 * 1000; // in MS
                return new Date() - Date.parse(searchResult.metrics.lastUpdated) < ONE_HOUR;
            },

            hideContentToggle: function(searchResult) {
                var deferred = $q.defer();

                searchResult.hideOptions = !searchResult.hideOptions;
                deferred.resolve(searchResult);

                return deferred.promise;
            },
            closeHideContentToggles: function(results) {
                angular.forEach(results, function(result) {
                    result.hideOptions = false;
                });
            },

            openBoard: function(boardId, panel) {
                $state.go('boards.board.panel', {
                    id: boardId,
                    board: undefined,
                    query: undefined,
                    destinationTime: undefined,
                    panel,
                    overwriteReload: false,
                    targetId: null
                });
            }
        };
    }
]);
