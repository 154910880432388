'use strict';

/**
 * Angular wrapper for our <ContentTypeSelector /> react component.
 */
export default class ContentTypeSelector {
    static get $inject() {
        return ['_', 'toastr', '$filter', '$scope'];
    }

    constructor(_, toastr, $filter, $scope) {
        this._ = _;
        this.toastr = toastr;
        this.$filter = $filter;
        this.scope = $scope;
    }

    $onInit() {
        // Apply a new filter Facet
        this.applyNewFilter = filter => {
            this.applyFilter({ data: filter });
        };
        // Props being passed to our react component
        this.facet = _.get(this.facets, 'cards.content.type', []);
        this.types = _.get(this.card, 'properties.data', []);
        this.commonTypes = _.get(this.card, 'properties.featuredContentTypes', []);
        this.data = {
            types: this.types,
            commonTypes: this.commonTypes,
            facet: this.facet
        };
        this.hooks = {
            applyFilter: this.applyNewFilter,
            getContentTypeLabel: this.$filter('contentTypeLabel')
        };
    }

    $onChanges(changes) {
        let changed = false;
        if (this._.get(changes, 'facets') && !changes.facets.isFirstChange()) {
            this.facet = _.get(changes.facets.currentValue, 'cards.content.type', []);
            changed = true;
        }
        if (this._.get(changes, 'card') && !changes.card.isFirstChange()) {
            this.types = _.get(changes.card.currentValue, 'properties.data', []);
            this.commonTypes = _.get(changes.card.currentValue, 'properties.featuredContentTypes', []);
            changed = true;
        }
        if (changed) {
            this.data = {
                ...this.data,
                facet: this.facet,
                types: this.types,
                commonTypes: this.commonTypes
            };
        }
    }
}
