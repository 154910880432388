(function() {
    'use strict';
    angular.module('serviceApp').directive('formConditionalSelect', formConditionalSelect);
    function formConditionalSelect() {
        var directive = {
            restrict: 'A',
            scope: {
                formModel: '=formConditionalSelect',
                form: '='
            },
            template: require('views/tmpl/partials/forms/formConditionalSelect.html'),
            controller: FormConditionalSelectController
        };

        return directive;
    }

    FormConditionalSelectController.$inject = ['_', '$scope'];

    function FormConditionalSelectController(_, $scope) {
        $scope.selectedValues = {};
        $scope.primaryValues = getPrimaryValues();
        $scope.primaryValueSelected = primaryValueSelected;
        $scope.secondaryValueSelected = secondaryValueSelected;

        $scope.primaryHint = _.get($scope, 'form.schema.format.primaryHint');
        $scope.secondaryHint = _.get($scope, 'form.schema.format.secondaryHint');

        if ($scope.formModel) {
            const selectedValue = _.find(getData(), { savedValue: $scope.formModel });
            $scope.selectedValues.primary = _.get(selectedValue, 'primaryValue');
            primaryValueSelected();
            $scope.selectedValues.secondary = _.get(selectedValue, 'secondaryValue');
            secondaryValueSelected();
        }

        function getData() {
            return _.get($scope, 'form.schema.format.data', []);
        }

        function getPrimaryValues() {
            return _(getData())
                .map('primaryValue')
                .uniq()
                .compact()
                .value();
        }

        function primaryValueSelected() {
            _.unset($scope, 'selectedValues.secondary');
            _.unset($scope, 'formModel');

            const primaryValue = _.get($scope, 'selectedValues.primary');
            $scope.secondaryValues = _(getData())
                .filter({ primaryValue })
                .map('secondaryValue')
                .uniq()
                .compact()
                .value();
        }

        function secondaryValueSelected() {
            const primaryValue = _.get($scope, 'selectedValues.primary');
            const secondaryValue = _.get($scope, 'selectedValues.secondary');
            const selectedValue = _.find(getData(), { primaryValue, secondaryValue });
            $scope.formModel = _.get(selectedValue, 'savedValue');
        }
    }
})();
