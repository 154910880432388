'use strict';

/**
 * Angular wrapper for our <AddToDashboardModal /> react component.
 */
export default class CardAddToDashboardModal {
    static get $inject() {
        return ['_', 'toastr'];
    }

    constructor(_, toastr) {
        this._ = _;
        this.toastr = toastr;

        this.onComplete = dashboardDef => {
            this.toastr.success(
                `${this.cardTitle} has been added to ${dashboardDef.displayName}`,
                'Added to dashboard',
                {
                    timeOut: 4000
                }
            );
            const url = `#/boards/${_.kebabCase(_.get(dashboardDef, 'board.boardType'))}$${_.get(
                dashboardDef,
                'name'
            )}`;
            this.toastr.info(
                `<a href="${url}" style="text-decoration: underline">Click here to view the dashboard</a>`,
                '',
                {
                    allowHtml: true,
                    timeOut: 5000,
                    extendedTimeOut: 5000
                }
            );
            this.hideModal();
        };
        this.onCancel = () => {
            this.hideModal();
        };
        this.onError = err => {
            this.toastr.error('An error occurred adding this report to the dashboard.', err);
        };
    }

    $onInit() {
        // Props being passed to our react component
        this.data = {
            open: true,
            reportId: this.reportId,
            contentType: this.contentType,
            cardName: this.cardName
        };
        this.hooks = {
            onComplete: this.onComplete,
            onCancel: this.onCancel,
            onError: this.onError
        };
    }

    $onChanges(changes) {
        let changed = false;
        if (this._.get(changes, 'reportId') && !changes.reportId.isFirstChange()) {
            this.reportId = changes.reportId.currentValue;
            changed = true;
        }
        if (this._.get(changes, 'contentType') && !changes.contentType.isFirstChange()) {
            this.contentType = changes.contentType.currentValue;
            changed = true;
        }
        if (changed) {
            this.data = {
                ...this.data,
                reportId: this.reportId,
                contentType: this.contentType
            };
        }
    }
}
