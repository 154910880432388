'use strict';

export default class CardActionStats {
    static get $inject() {
        return ['$scope', 'actionService', 'themeService'];
    }

    constructor(scope, actionService, themeService) {
        this.scope = scope;
        this.themeService = themeService;
        this.scope.isDark = this.themeService.isDarkMode;
        this.themeService.onThemeChange(this.scope, isDark => {
            this.scope.isDark = isDark;
        });
        // assume this card is rendered on a dark background
        this.scope.dark = true;
        this.scope.actionSearchOptions = { showActionName: true };

        this.scope.defaultFilter = _.get(this.scope, 'card.properties.actionStatsFilter');
    }
}
