(function() {
    'use strict';

    angular.module('serviceApp').filter('convertDurationToMilliseconds', convertDurationToMilliseconds);

    convertDurationToMilliseconds.$inject = ['_', 'moment'];

    function convertDurationToMilliseconds(_, $moment) {
        const filter = (numbers, unit) => {
            return $moment.duration(numbers, unit).asMilliseconds();
        };

        return filter;
    }
})();
