'use strict';

import './index.scss';
import template from './index.html';

angular.module('serviceApp').directive('dashboardLink', () => {
    return {
        restrict: 'E',
        scope: {
            boardShortcode: '<',
            card: '<'
        },
        template: template
    };
});
