import controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('signalsTimeline', signalsTimeline);
function signalsTimeline() {
    var directive = {
        restrict: 'E',
        scope: {
            timeline: '='
        },
        template,
        controller
    };

    return directive;
}
