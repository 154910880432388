CardItemInterpretedController.$inject = [
    '_',
    '$scope',
    '$interpolate',
    '$rootScope',
    '$filter',
    '$element',
    '$stateParams',
    'contentLaunchService',
    'searchServiceNew',
    'translateValuesService',
    'interpretedContentFactory',
    'storageService'
];
export default function CardItemInterpretedController(
    _,
    $scope,
    $interpolate,
    $rootScope,
    $filter,
    $element,
    $stateParams,
    contentLaunchService,
    searchServiceNew,
    translateValuesService,
    interpretedContentFactory,
    storageService
) {
    const icFactory = interpretedContentFactory($scope);
    const { getContentField, getItem, convertThreadIdsToUserUrls } = icFactory;
    const displayOptions = _.get($scope, 'board.contentDisplayOptions', $rootScope.displayOptions);
    const defaultDisplayOptions = {
        title: {
            value: '{{content.displayTitle}}'
        }
    };

    $scope.arrayLimit = $rootScope.globalArrayLimit;
    $scope.showMore = false;

    $scope.isMobile = $rootScope.isMobile;

    $scope.getContentField = getContentField;
    $scope.isPathHidden = icFactory.isPathHidden;
    $scope.open = open;
    $scope.set = set;
    $scope.isEmpty = _.isEmpty;
    $scope.isObject = _.isObject;
    $scope.isExpandable = isExpandable;

    $scope.toggleDetails = toggleDetails;
    $scope.titleClick = titleClick;
    $scope.toggleHideOptions = toggleHideOptions;
    $scope.hideCallback = hideCallback;
    $scope.generateHref = icFactory.generateHref;
    $scope.getButtonIconHtml = getButtonIconHtml;
    $scope.toggleContent = toggleContent;
    $scope.contentIsOpen = contentIsOpen;
    $scope.isHidden = isHidden;
    $scope.toggleCallback = toggleCallback;
    $scope.closeCallback = closeCallback;

    //This checks if the view is embedded as a single view
    $scope.singleView = $stateParams.singleView;
    $scope.isExternal = $rootScope.isExternal;

    $scope.translateValues = translateValuesService.translateValues.bind(this, {
        $scope,
        $filter,
        $interpolate,
        contentType: $scope.contentType,
        propertyDisplayOptions: $scope.propertyDisplayOptions
    });

    $scope.getPropKey = path => {
        return _.last(_.split(path, '.'));
    };

    $scope.getPropVal = path => {
        let val;
        let isTopLevelProp = false;
        let contentType;
        if (_.includes(path, '.')) {
            contentType = _.first(_.split(path, '.'));
        } else {
            isTopLevelProp = true;
            contentType = $scope.contentType;
        }

        path = _.replace(path, `${contentType}.`, '');
        let propertyDisplayOptions = $scope.propertyDisplayOptions;

        if (isTopLevelProp) {
            val = _.get($scope.content, path);
        } else if (
            _.isArray(_.get($scope.content, 'contentType')) &&
            contentType === _.nth($scope.content.contentType, 0)
        ) {
            const type = _.nth($scope.content.contentType, 1);
            const subType = _.nth($scope.content.contentType, 0);
            val = _.get($scope.content, `${type}.${subType}.${path}`);
        } else {
            val = _.get($scope.content, `${contentType}.${path}`);
        }

        const translated = translateValuesService.translateValues({
            $scope,
            $filter,
            $interpolate,
            contentType,
            propertyDisplayOptions,
            values: { [path]: val },
            asObject: true,
            showHidden: true
        });
        var returnVal = _.get(translated, '0.value');
        returnVal = _.isArray(returnVal) ? _.join(returnVal, ', ') : returnVal;
        return !_.isNil(returnVal) ? returnVal : `No ${_.startCase(path)}`;
    };

    $scope.getFeaturedPropPillColor = idxInPool => (idxInPool % 8) + 1;

    let contentWatcher = $scope.$watch('content.id', function() {
        init();
    });

    let featuredPropsWatcher = $scope.$watch('filterData.indicators', function() {
        initFeaturedProps();
    });

    $element.on('click contextmenu', function(event) {
        if (!$scope.board) {
            return;
        }

        if ($element.find('interpreted-content')) {
            searchServiceNew.logClick(
                $scope.content.id,
                $scope.board.id,
                null,
                $scope.content.url,
                null,
                function() {}
            );
        }
    });

    function init() {
        $scope.administeredContent = !!_.get($scope.content, 'boardId');
        $scope.contentType = _.first(_.castArray(_.get($scope.content, 'contentType')));
        $scope.baseContentType = _.last(_.castArray(_.get($scope.content, 'contentType')));

        // Get the content type display options.
        $scope.contentTypeDisplayOptions = _.get(displayOptions, [$scope.contentType], defaultDisplayOptions);

        // Get the label for this content type.
        $scope.contentTypeLabel = _.get(
            displayOptions,
            [$scope.contentType, 'label'],
            _.startCase($scope.contentType)
        );

        // Get the property display options for this content type.
        $scope.propertyDisplayOptions = _.get(
            displayOptions,
            [$scope.contentType, 'propertyDisplayOptions'],
            defaultDisplayOptions
        );

        $scope.title = icFactory.resolveTitle($scope.contentTypeDisplayOptions.title);
        $scope.subTitles = resolveSubTitles($scope.contentTypeDisplayOptions.subTitles);
        $scope.displayDate = resolveDisplayDate($scope.contentTypeDisplayOptions.date);
        $scope.iconUrl = getIconUrl($scope.contentTypeDisplayOptions);
        $scope.schemaData = getSchemaData();
        $scope.hasActionInspection =
            !$scope.hideActionButton &&
            $scope.administeredContent &&
            _.get($scope.contentTypeDisplayOptions, 'hasActionInspection', false);
        // This is for backwards compatibility. As a bit of content types don't have
        // a built in details card/board so it generates details based on content mappings.
        $scope.hasDetailsPanel = _.get($scope.contentTypeDisplayOptions, 'hasDetailsPanel', false);
        $scope.hasSummary = _.get($scope.contentTypeDisplayOptions, 'hasSummary', false);

        const allContentTypes = _.castArray(_.get($scope.content, 'contentType'));

        //If the board instantAction property is undefined, enable the action menu and let the callback
        //fetch the instant actions, otherwise if the board does have a defined instantAction property
        //deal with it based on the content type and show or not show the menu based on that.
        $scope.hasInstantActions =
            !_.get($scope.board, 'properties.instantActions') ||
            _.some(_.get($scope.board, 'properties.instantActions'), function(instantAction) {
                return _.includes(allContentTypes, _.get(instantAction, 'action.contentType'));
            });
    }

    //Evaluate featured properties.
    //Note - the only place we could find that still uses this is the content list in
    //the Kanban view of Opportunities.
    function initFeaturedProps() {
        if (
            !_.isEmpty(_.get($scope, 'filterData.indicators')) &&
            !$scope.isTableContent &&
            !$scope.isDetailsContent
        ) {
            let props = [];
            _.forEach(_.get($scope, 'filterData.indicators'), function(indicator) {
                if (!_.get(indicator, 'hidden')) {
                    let indicatorObject = {};
                    indicatorObject.title = $scope.getPropKey(indicator.path);
                    indicatorObject.value = $scope.getPropVal(indicator.path);
                    props.push(indicatorObject);
                }
                $scope.featureProps = props;
            });
        }
    }

    $scope.showFeaturedProps = function() {
        return (
            !_.isEmpty(_.get($scope, 'filterData.indicators')) &&
            !$scope.isTableContent &&
            !$scope.isDetailsContent
        );
    };

    function resolveSubTitles(subTitles) {
        var resolvedSubTitles = _.map(_.cloneDeep(subTitles), function(subTitle) {
            subTitle.style = _.get(subTitle, 'value.style');
            var subTitleType = _.get(subTitle, 'type');
            subTitle.value = getContentField(subTitle, subTitleType == 'array');

            //filter out any excluded values
            if (_.includes(_.get(subTitle, 'excludes'), subTitle.value)) {
                return;
            }

            if (
                _.isNil(subTitle.value) ||
                (_.isObject(subTitle.value) && _.isEmpty(subTitle.value)) ||
                subTitle.value === ''
            ) {
                return;
            }

            // When there are no custom contentType labels for a card, use the default label if there is one
            subTitle.label = getItem(false, _.get(subTitle, 'label')) || _.get(subTitle, 'label');
            subTitle.click = _.get(subTitle, 'click');
            return subTitle;
        });

        return resolvedSubTitles;
    }

    function resolveDisplayDate(displayDate) {
        displayDate = getContentField(displayDate);

        if (displayDate) {
            displayDate = $filter('humanDate')(displayDate);
        }

        return displayDate;
    }

    function open(clickObject) {
        var destination = _.get(clickObject, 'destination');
        var urls;
        var threadIds;

        if (!destination) {
            return;
        }

        // First check to see if the content has userUrls that can be used to build urls.
        if (destination.userUrls && getItem(false, destination.userUrls)) {
            urls = JSON.parse(getItem(false, destination.userUrls));
        } else if (destination.type === 'mail') {
            // If userUrls didn't work, if it's type 'mail', try to use threadIds to build urls.
            if (destination.threadIds && getItem(false, destination.threadIds)) {
                threadIds = JSON.parse(getItem(false, destination.threadIds));
                urls = convertThreadIdsToUserUrls(threadIds);
            }
        }

        contentLaunchService.doLaunch(
            destination,
            null,
            $scope.user,
            destination ? getContentField(destination, false) : $scope.content.boardId,
            $scope.content,
            urls
        );
    }

    let toggleListener = $scope.$root.$on('viewContentDetails', function(event, content, options) {
        // since a top-content list can be on a dashboard we don't want to listen for events from the
        // inspection drawer on the top-content list
        const fromTableContent = _.get(options, 'fromTableContent', false);
        if (!!fromTableContent !== !!$scope.isTableContent) {
            return;
        }
        // only call openDetails if we are in inspection view
        if (content.id === $scope.content.id && !$scope.isDashboardMode) {
            openDetails();
        }
    });

    // Content Title Click Handler
    function titleClick(event) {
        // Prevent the left click on the title to open href link
        //  and let flow to the toggle details
        if (event && event.type === 'click' && !event.metaKey && !event.ctrlKey && !event.shiftKey) {
            event.preventDefault();
        } else {
            // All other clicks like cmd+click and right click allow, and prevent toggle details
            event.stopPropagation();
        }
    }

    function toggleDetails(event) {
        // stop propagation unless functionality relies on it
        if (event && event.stopPropagation && !$scope.isTableContent) {
            event.stopPropagation();
        }
        // if we are in an action preview we want to alert the parent there
        // was a click and do nothing else
        if ($scope.actionPreviewCallback) {
            $scope.actionPreviewCallback({
                data: {
                    selectedContent: $scope.content
                }
            });
            return;
        }

        // if we aren't on a dashboard don't call openDetails(), calling
        // openDetails() from inspection view is handled in the toggleListener
        if ($scope.isTableContent && !$scope.isDashboardMode) {
            return;
        }

        if (
            ($scope.content.boardId && !$scope.contentTypeDisplayOptions.noDetails) ||
            $scope.isDashboardMode
        ) {
            openDetails();
            return;
        }

        if (
            !$scope.isMobile &&
            !$scope.isActionContent &&
            $scope.content.relatedContent &&
            $scope.content.relatedContent.length
        ) {
            $scope.content.relatedContentToggled = !$scope.content.relatedContentToggled;
            $scope.toggleRelatedContent();
        }

        if (isExpandable()) {
            $scope.showMore = !$scope.showMore;
        }
    }

    let rightDrawerCloseListener = $scope.$root.$on('rightDrawer:close', () => {
        if (!$scope.isTableContent) {
            $scope.isSelected = false;
        }
    });

    let rightDrawerOpenListener = $scope.$root.$on('rightDrawer:open', () => {
        if ($scope.content.id != $scope.$root.selectedContent && !$scope.isTableContent) {
            $scope.isSelected = false;
        }
    });

    //clean up the listener
    $scope.$on('$destroy', function() {
        toggleListener();
        contentWatcher();
        featuredPropsWatcher();
        rightDrawerCloseListener();
        rightDrawerOpenListener();
    });

    // This is used to toggle the content details from details to summary
    function toggleCallback() {
        const isSummary = storageService.get(`expandedContentView`, 'local');
        if (isSummary) {
            storageService.delete(`expandedContentView`, 'local');
        } else {
            storageService.set(`expandedContentView`, true, null, 'local');
        }
        openDetails();
    }

    // Used to toggle the isSelected from a stacked drawer
    function closeCallback() {
        if (!$scope.isTableContent) {
            $scope.isSelected = false;
        }
    }

    function openDetails() {
        let drawerClass = 'skinny-dark';
        let summaryClass = 'wide-dark';
        if ($scope.userTheme) {
            drawerClass = 'user-theme skinny';
            summaryClass = 'user-theme wide';
        }
        $scope.isSelected = true;
        $scope.$root.selectedContent = $scope.content.id;
        if ($scope.hasDetailsPanel) {
            const isSummary = storageService.get(`expandedContentView`, 'local');
            $scope.$emit('rightDrawer:open', {
                layout: 'boardView',
                boardId: _.get($scope.content, 'boardId'),
                panelGroup: $scope.hasSummary && isSummary ? 'summary' : 'details',
                panelName: $scope.hasSummary && isSummary ? 'summary' : 'detailsPanel',
                user: $scope.user,
                drawerClass: $scope.hasSummary && isSummary ? summaryClass : drawerClass,
                includeSummary: $scope.hasSummary,
                isSummary,
                toggleCallback: $scope.toggleCallback,
                closeCallback: $scope.closeCallback
            });
        } else {
            // For backwards compatibility
            $scope.$emit('rightDrawer:open', {
                layout: 'contentDetails',
                board: $scope.board,
                user: $scope.user,
                content: $scope.content,
                // Use the base content type when showing details, because we're moving
                // away from extended types and should assume that the base type contains
                // all of the needed property display options.
                contentType: $scope.baseContentType,
                //If embedded, load the full view drawer
                drawerClass: $scope.singleView ? 'full-external' : drawerClass,
                userTheme: $scope.userTheme
            });
        }
        // }
    }

    function toggleHideOptions() {
        $scope.content.hideOptions = !$scope.content.hideOptions;
    }

    function hideCallback(data) {
        $scope.refreshCallback();
    }

    function set(obj, path, value) {
        if (_.isNull(value)) {
            $scope[obj] = path;
        } else {
            _.set($scope[obj], path, value);
        }
    }

    function getIconUrl(dispOptions) {
        var iconOptions = _.get(dispOptions, 'icon');

        if (!iconOptions) {
            $scope.defaultIcon = 'productIconLogoSmall';
            return;
        }

        var icon = getContentField(iconOptions);

        if (!icon) {
            return;
        }

        $scope.defaultIcon = getItem(false, iconOptions.default) || 'productIconLogoSmall';

        if (_.startsWith(icon, 'icon-')) {
            $scope.iconType = 'internal';
            return icon;
        } else if (_.startsWith(icon, 'initials')) {
            $scope.iconType = 'initials';
            return;
        } else {
            $scope.iconType = 'url';
            return getClearbitUrl(icon);
        }
    }

    function getSchemaData() {
        var schemaArray;
        if (_.isArray($scope.content.contentType)) {
            var typeData = _.get($scope.content, $scope.content.contentType[1]);
            schemaArray = _.get(typeData, $scope.content.contentType[0]);
        } else {
            schemaArray = _.get($scope.content, $scope.content.contentType);
        }
        return schemaArray;
    }

    function getLocation(href) {
        var l = document.createElement('a');
        l.href = href;
        return l;
    }

    function isHidden(value) {
        var fieldName = $scope.contentType + '.' + value;
        var field = _.get($scope.propertyDisplayOptions, fieldName);

        if (field) {
            return _.get(field, 'hidden');
        }
        return false;
    }

    function getClearbitUrl(website) {
        var l = getLocation(website);

        if (website.indexOf('http') > -1) {
            return '//logo.clearbit.com/' + l.hostname;
        } else {
            return '//logo.clearbit.com/' + website;
        }
    }

    function isExpandable() {
        if (
            $scope.headerOnly ||
            $scope.bodyOnly ||
            (!$scope.administeredContent &&
                !$scope.contentTypeDisplayOptions.expandedContent &&
                !$scope.schemaData)
        ) {
            return false;
        }
        return true;
    }

    function getButtonIconHtml(icon) {
        var isFontAwesome = fontAwesomeRegexp.test(icon);

        if (isFontAwesome) {
            return '<i class="fa ' + icon + '"></i>';
        } else {
            return '<img width="20" height="20" src="' + icon + '" err-src="fa fa-external-link" alt>';
        }
    }

    function toggleContent(index) {
        _.forEach($scope.contentTypeDisplayOptions.expandedContent, function(content, n) {
            if (content && n === index) {
                content._isOpen = !content._isOpen;
            } else {
                _.set(content, '_isOpen', false);
            }
        });
    }

    function contentIsOpen(content) {
        return !!_.find(content, '_isOpen');
    }
}
