'use strict';

export default class CardActionStatsDrawer {
    static get $inject() {
        return ['$rootScope', '$scope', 'actionService'];
    }

    constructor(rootScope, scope) {
        this.scope = scope;
        this.rootScope = rootScope;
        this.scope.dark = true;
        this.scope.defaultFilter = _.get(this.scope.graphOptions, 'defaultFilter');
        this.scope.defaultState = _.get(this.scope.graphOptions, 'defaultState');

        /**
         * closeDrawer - close the drawer
         */
        this.scope.closeDrawer = () => {
            if (this.rootScope.rightDrawerOpen) {
                this.scope.$emit('rightDrawer:close');
            }
        };
    }
}
