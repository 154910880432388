'use strict';

/**
 * Angular wrapper for our <HistoryIntervalSelectorExternalComponent /> react component.
 */
export default class HistoryIntervalSelector {
    static get $inject() {
        return ['_'];
    }

    constructor(_) {
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.data = {
            interval: this.interval,
            availableIntervals: this.availableIntervals,
            numberOfIntervals: this.numberOfIntervals,
            offset: this.offset,
            defaultRange: this.defaultRange,
            onIntervalUpdated: this.onIntervalUpdated,
            calculationBound: this.sliceAt
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'interval') && !changes.interval.isFirstChange()) {
            this.interval = changes.interval.currentValue;
            this.data = { ...this.data, interval: this.interval };
        }

        if (this._.get(changes, 'calculationBound') && !changes.calculationBound.isFirstChange()) {
            this.calculationBound = changes.calculationBound.currentValue;
            this.data = { ...this.data, calculationBound: this.calculationBound };
        }

        if (this._.get(changes, 'defaultRange') && !changes.defaultRange.isFirstChange()) {
            this.defaultRange = changes.defaultRange.currentValue;
            this.data = { ...this.data, defaultRange: this.defaultRange };
        }
    }
}
