(function() {
    'use strict';
    angular.module('serviceApp').directive('definitionEditorHeader', definitionEditorHeader);
    function definitionEditorHeader() {
        var directive = {
            restrict: 'E',
            scope: {
                definition: '=',
                headerDirty: '=',
                formDirty: '=',
                formValid: '=',
                selectedTab: '=',
                nameDupe: '=',
                isSaving: '=',
                saveCallback: '&?',
                copyCallback: '&?',
                deleteCallback: '&?',
                copyReplaceCallback: '&?',
                createCallback: '&?',
                auditEvents: '='
            },
            template: require('views/tmpl/partials/definitionEditors/definitionEditorHeader.html'),
            controller: DefinitionEditorHeaderController
        };

        return directive;
    }

    DefinitionEditorHeaderController.$inject = [
        '_',
        '$scope',
        '$rootScope',
        'actionService',
        'contentTypeService'
    ];

    function DefinitionEditorHeaderController(_, $scope, $rootScope, actionService, contentTypeService) {
        $scope.showDelete = false;
        $scope.isDeleteEnabled =
            _.get($scope, 'definition.definitionType') !== 'contentType' ||
            _.get($scope, 'definition.isCustom') === true;
        $scope.showWarning = false;
        $scope.forms = {};
        $scope.productName = $rootScope.productName;
        $scope.$on('definition-editor-header:show-warning', (event, data) => {
            $scope.toggleWarningDialog(data);
        });
        $scope.savedDisplayName = _.get($scope, 'definition.displayName');

        const defWatcher = $scope.$watch(
            'definition',
            function() {
                $scope.nameDupe = false;
                $scope.isGlobal = _.get($scope, 'definition.teamId') === '##GLOBAL##';
                $scope.showTabs = _.size(_.get($scope, 'definition._tabs'), []) > 1;
                $scope.filterData = { definition: $scope.definition };
                var nameForm = _.get($scope.forms, 'nameForm');
                if (!_.get($scope, 'definition.displayName') && !nameForm) {
                    _.set($scope, 'definition.displayName', _.startCase(_.get($scope, 'definition.name')));
                }
                if (nameForm) {
                    var nameForm = _.get($scope.forms, 'nameForm');
                    $scope.headerDirty = nameForm.$dirty && !nameForm.$invalid;
                    if (nameForm.$invalid || !$scope.definition.description) {
                        $scope.formDirty = false;
                        $scope.headerDirty = false;
                    }
                }
            },
            true
        );

        $scope.nameChanged = function() {
            return (
                _.toLower($scope.definition.displayName) !== _.toLower(_.kebabCase($scope.definition.name))
            );
        };

        $scope.closeEditor = function() {
            $scope.$root.$emit('rightDrawer:close');
            $scope.$root.$emit('leftDrawer:close');
            $scope.$root.$emit('definition-editor:closed');
        };

        $scope.saveDefinition = function() {
            $scope.saveCallback({ definition: $scope.definition });
        };

        $scope.copyDefinition = data => {
            $scope.copyCallback({ data: data });
        };

        $scope.copyReplaceDefinition = data => {
            $scope.copyReplaceCallback({ data: data });
        };

        $scope.createDefCallback = data => {
            $scope.createCallback({ data: data });
        };

        $scope.forceAutoRun = () => {
            actionService
                .forceAutoRun({ definitionId: _.get($scope, 'definition.id'), maxActions: 500 })
                .then(result => {
                    console.log('Forced auto run success', result);
                })
                .catch(err => {
                    console.error('Forced auto run failed', err);
                });
        };

        $scope.deleteDefinition = function() {
            $scope.deleteCallback({ definition: $scope.definition });
        };

        $scope.toggleDeleteDialog = function(show) {
            $scope.showDelete = show;
        };

        $scope.toggleWarningDialog = function(warning) {
            if (warning) {
                $scope.warning = warning;
            } else {
                $scope.warning = {};
            }
            $scope.showWarning = !$scope.showWarning;
        };

        $scope.setTab = function(tab) {
            $scope.selectedTab = tab;
        };

        $scope.getContentTypeFromDefinition = () => {
            const definitionType = _.get($scope, 'definition.definitionType');
            const contentTypeName = _.get($scope, 'definition.' + definitionType + '.contentType');
            return contentTypeService.getContentTypePrettyName(contentTypeName);
        };

        const tabWatcher = $scope.$watch('selectedTab', function() {
            $scope.$broadcast('definitionHeaderVisibilityChanged');
        });

        var validWatch = false;
        const enableWatcher = $scope.$watch('definition.enabled', function() {
            //ignore init watch being fired
            if (validWatch) {
                $scope.headerDirty = true;
            } else {
                validWatch = true;
            }
        });

        $scope.$on('$destroy', () => {
            defWatcher();
            tabWatcher();
            enableWatcher();
        });
    }
})();
