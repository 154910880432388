'use strict';
import colors from '@olono/shared-ui-assets/colors.json';
/**
 * Angular EnergyScore component.
 */

export default class EnergyScore {
    static get $inject() {
        return ['_', '$scope'];
    }

    constructor(_, $scope) {
        this._ = _;
        this.scope = $scope;
    }

    $onChanges() {
        this.scope.value = this.value;
        this.scope.dark = this.dark;
        //Calculate the energy score color value. Base green
        this.scope.valueColor = colors['red-500'];
        if (this.scope.value >= 35 && this.scope.value < 75) {
            this.scope.valueColor = colors['amber-300'];
        } else if (this.scope.value >= 75) {
            this.scope.valueColor = colors['green-500'];
        }
    }
}
