(function() {
    'use strict';

    angular.module('serviceApp').directive('timelineItem', timelineItem);

    function timelineItem() {
        var directive = {
            restrict: 'E',
            scope: {
                signal: '=',
                isEven: '='
            },
            template: require('views/tmpl/partials/timelineItem.html'),
            controller: TimelineItemController
        };

        return directive;
    }

    TimelineItemController.$inject = ['$scope', 'galleryUtilsService'];

    function TimelineItemController($scope, galleryUtilsService) {
        $scope.openBoard = function(boardId) {
            galleryUtilsService.openBoard(boardId);
        };
    }
})();
