'use strict';

import _ from 'lodash';

/**
 * Expandable Content list is a container for card items
 *
 * @export
 * @class Controller
 */
export default class Controller {
    // angular dependency injector
    static get $inject() {
        return ['_', '$scope'];
    }

    constructor(_, $scope) {
        const SHOW_LIMIT = 10;
        const SHOW_MORE = 50;

        $scope.showLimit = SHOW_LIMIT;
        // Callback to set the shown value
        $scope.setNumberOfShownItems &&
            $scope.setNumberOfShownItems({
                data: $scope.resultLength < SHOW_LIMIT ? $scope.resultLength : SHOW_LIMIT
            });
        $scope.showMoreValue = SHOW_MORE;
        $scope.insertedContentMap = {};

        $scope.toggleRelatedContent = (contentItem, index) => {
            let pathToinsertMapItem = 'insertedContentMap[' + contentItem.id + ']';
            let relatedContentMeta = _.get($scope, pathToinsertMapItem);
            let content = $scope.results;

            if (relatedContentMeta) {
                //related content already added, remove it
                var numberToRemove = _.get(relatedContentMeta, 'quantity', 0);

                if (content && _.isArray(content)) {
                    $scope.results.splice(index + 1, numberToRemove);

                    $scope.showMoreContent(-1 * numberToRemove);
                }

                _.unset($scope, pathToinsertMapItem);
            } else {
                //insert related content
                var quantity = _.get(contentItem, 'relatedContent.length', 0);
                _.set($scope, pathToinsertMapItem, {
                    quantity: quantity
                });

                if (content && _.isArray(content)) {
                    //Sort the related content by the lastEventDate in descending order.
                    contentItem.relatedContent = _.sortBy(
                        contentItem.relatedContent,
                        'lastEventDate'
                    ).reverse();

                    _.forEach(contentItem.relatedContent, function(relatedContentItem, i, array) {
                        var startIndex = index + i + 1;

                        relatedContentItem.isRelatedContent = true;
                        if (i === array.length - 1) {
                            relatedContentItem.isLastRelatedContent = true;
                        }
                        $scope.results.splice(startIndex, 0, relatedContentItem);
                    });

                    $scope.showMoreContent(quantity);
                }
            }
        };

        $scope.showMoreContent = quantity => {
            quantity = _.isNil(quantity) ? $scope.showMoreValue : quantity;
            $scope.showLimit = $scope.showLimit + quantity;
            // Callback to set the shown value
            $scope.setNumberOfShownItems &&
                $scope.setNumberOfShownItems({
                    data: $scope.showLimit >= $scope.resultLength ? $scope.resultLength : $scope.showLimit
                });
        };
    }
}
