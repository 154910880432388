'use strict';

/**
 * search zero state displays messages after search panel returns no results
 * @export
 * @class Controller
 */
export default class Controller {
    // angular dependency injector
    static get $inject() {
        return ['$scope'];
    }

    constructor($scope) {
        this.scope = $scope;
        if (!this.scope.noSearchResultMessage) {
            this.scope.noSearchResultMessage = 'No search results found. Please try a different search term.';
        }
        if (!this.scope.noResultMessage) {
            this.scope.noResultMessage = 'There are no results.';
        }
    }
}
