'use strict';

export default class AccordionExternalController {
    static get $inject() {
        return ['_', '$scope'];
    }

    constructor(_, $scope) {
        this._ = _;
        this.scope = $scope;

        this.scope.onUpdated = payload => {
            _.each(payload, (value, key) => {
                _.set($scope.definition, key, value);
            });
        };

        // Update the data that we supply to external component.
        // This should force a re-render of the component.
        const updateExternalData = () => {
            this.scope.externalData = {
                definition: this.scope.definition
            };
        };

        // Any time the definition is changed (probably by another accordion)
        // update the external data.
        // TODO: supply a callback function to the external function to indicate which definition
        // keys should trigger an update
        this.scope.$watch('definition', updateExternalData, true);

        // Initialize the data to send to the external component.
        updateExternalData();
    }
}
