'use strict';
import template from './index.html';
import MenuNavClass from './controller';

angular.module('serviceApp').directive('menuNav', () => ({
    restrict: 'E',
    scope: {
        user: '<',
        board: '='
    },
    template,
    controller: MenuNavClass
}));
