import template from './index.html';
import controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('contentSubtitle', topContentSubtitle);

function topContentSubtitle() {
    var directive = {
        restrict: 'E',
        scope: {
            subTitle: '<',
            values: '<',
            contentType: '<',
            propertyDisplayOptions: '<',
            administeredContent: '<?',
            clickCallback: '&'
        },
        template,
        controller
    };

    return directive;
}
