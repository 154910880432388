(function() {
    'use strict';

    angular.module('serviceApp').directive('cardSwitch', cardSwitch);

    function cardSwitch() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '<',
                user: '<',
                team: '<?',
                board: '=',
                panel: '=',
                query: '=?',
                external: '=?',
                drawer: '<?',
                apps: '=?',
                facets: '=?',
                filterData: '=',
                hierarchy: '<?',
                refreshCallback: '&?',
                refreshBoardCallback: '&?',
                resizeCallback: '&?',
                popoutCallback: '&?',
                newMemberCallback: '&?',
                getCard: '&?',
                getCards: '&?',
                buildDownloadUrl: '&?'
            },
            template: require('views/tmpl/partials/cardSwitch.html'),
            controller: CardSwitchController
        };

        return directive;
    }

    CardSwitchController.$inject = ['$scope'];

    function CardSwitchController($scope) {
        $scope.refreshCallbackHitch = function() {
            $scope.refreshCallback();
        };

        $scope.resizeCallbackHitch = function(data) {
            $scope.resizeCallback({
                data: data
            });
        };

        $scope.popoutCallbackHitch = function(data) {
            $scope.popoutCallback({
                data: data
            });
        };

        $scope.refreshBoardCallbackHitch = function(data) {
            $scope.refreshBoardCallback({
                data: data
            });
        };

        $scope.newMemberCallbackHitch = function(data) {
            $scope.newMemberCallback({
                data: data
            });
        };

        $scope.getCardHitch = function(data) {
            $scope.getCard({
                data: data
            });
        };

        $scope.getCardsHitch = function(data) {
            $scope.getCards({
                data: data
            });
        };

        $scope.buildDownloadUrlHitch = function(data) {
            return $scope.buildDownloadUrl({
                data: data
            });
        };
    }
})();
