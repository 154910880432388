(function() {
    'use strict';

    angular.module('serviceApp').directive('panelZeroState', panelZeroState);

    function panelZeroState() {
        var directive = {
            restrict: 'E',
            scope: {
                isContentSearch: '=?',
                query: '=?'
            },
            template: require('views/tmpl/partials/panelZeroState.html'),
            controller: PanelZeroStateController
        };

        return directive;
    }

    PanelZeroStateController.$inject = ['$scope', '$rootScope', '$window'];

    function PanelZeroStateController($scope, $rootScope, $window) {
        $scope.external = $rootScope.isExternal;

        $scope.refresh = function() {
            $window.location.reload();
        };

        $scope.searchGoogle = function() {
            var url = 'https://www.google.com/?gws_rd=ssl#safe=active&q=' + $scope.query.str;
            $window.open(url, '_blank');
        };

        $scope.searchEverything = function() {
            $scope.query.boards = [];
        };
    }
})();
