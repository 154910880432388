import template from './index.html';
import controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('cardTopContent', cardTopContent);

function cardTopContent() {
    var directive = {
        restrict: 'E',
        scope: {
            card: '=',
            board: '=',
            panel: '=',
            external: '=?',
            user: '<',
            team: '<',
            query: '=?',
            facets: '=?',
            filterData: '<?',
            getCardsHitch: '&?',
            contentProperty: '@?',
            resizeCallbackHitch: '&',
            popoutCallbackHitch: '&',
            refreshCallbackHitch: '&'
        },
        template,
        controller
    };

    return directive;
}
