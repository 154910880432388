(function() {
    'use strict';

    angular.module('serviceApp').factory('contentLaunchService', ContentLaunchService);

    ContentLaunchService.$inject = ['_', 'galleryUtilsService', '$window', '$state'];

    /**
     * ContentLaunchService - This service is used to launch action and content links
     */
    function ContentLaunchService(_, galleryUtilsService, $window, $state) {
        var factory = {
            doLaunch: doLaunch,
            launchActionEventTrigger: launchActionEventTrigger
        };

        return factory;

        function includeAnyEmail(target, emails) {
            return _.some(emails, email => _.includes(target, email));
        }

        /**
         * doLaunch - launch context
         *
         * @param  {type} destination  Destination type, if called as a content type
         * @param  {type} action  The action object if called as an action
         * @param  {type} user  The User object
         * @param  {type} board  The Board ID if launching to a board
         * @param  {object} content The content object
         * @param  {type} urls  content user urls
         */

        function doLaunch(destination, action, user, board, content, urls) {
            if (!destination) {
                //assume action type if no destination is provided
                if (action.url == 'board') {
                    galleryUtilsService.openBoard(board);
                } else {
                    if (action.userUrls && action.triggeredContentType === 'mail') {
                        _.forEach(action.userUrls, function(id) {
                            if (user && includeAnyEmail(user.hashedEmails, id)) {
                                var threadId = id.split('##').pop();
                                $window.open(emailUrl.concat(threadId), '_blank');
                            }
                        });
                    } else if (action.userUrls) {
                        _.forEach(action.userUrls, function(id) {
                            if (includeAnyEmail(user.hashedEmails, id)) {
                                var url = id.split('##').pop();
                                $window.open(url, '_blank');
                            }
                        });
                    } else {
                        $window.open(action.url, '_blank');
                    }
                }
            } else if (destination.type == 'board') {
                galleryUtilsService.openBoard(board, destination.panel);
            } else if (destination.type === 'url' || destination.type === 'mail') {
                if (urls) {
                    _.forEach(urls, function(id) {
                        if (user && includeAnyEmail(user.hashedEmails, id)) {
                            content.url = id.split('##').pop();
                            galleryUtilsService.openContent(content, undefined, 'content', board);
                            return false; // return false to exit out of forEach early.
                        }
                    });
                } else {
                    galleryUtilsService.openContent(content, undefined, 'content', board);
                }
            } else if (destination.type === 'panel') {
                $state.go('boards.board.panel', {
                    board: board,
                    title: null,
                    panel: destination.value,
                    facets: null,
                    destinationTime: null,
                    overwriteReload: false,
                    targetId: null
                });
            }
        }

        function launchActionEventTrigger(user, action, eventTrigger) {
            var eventTriggerType = _.get(eventTrigger, 'type');
            var eventTriggerPayload = _.get(eventTrigger, 'payload');

            if (eventTriggerType == 'url') {
                var url = _.get(eventTriggerPayload, 'url');
                var userUrls = _.get(eventTriggerPayload, 'userUrls', []);
                var threadIds = _.get(eventTriggerPayload, 'threadIds', []);

                $window.open(getUrl(user, url, userUrls, threadIds), '_blank');
            } else if (eventTriggerType == 'board') {
                return galleryUtilsService.openBoard(_.get(eventTriggerPayload, 'boardId'));
            }
        }

        function getUrl(user, url, userUrls, threadIds) {
            if (_.isEmpty(userUrls) && _.isEmpty(threadIds)) {
                return url;
            }

            let hashedEmails = _.get(user, 'hashedEmails');

            // Look in userUrls before threadIds
            let userUrl = _.find(userUrls, function(urlItem) {
                return includeAnyEmail(hashedEmails, urlItem);
            });

            if (userUrl) {
                return userUrl.split('##').pop();
            }

            // Look in threadIds last - this will be a legacy case, and potentially can
            // be removed completely if we migrate existing threadIds to userUrls.
            let threadId = _.find(threadIds, function(threadIdItem) {
                return includeAnyEmail(hashedEmails, threadIdItem);
            });

            if (threadId) {
                threadId = threadId.split('##').pop();
                return 'https://mail.google.com/mail/?authuser=' + user.email + '#all/' + threadId;
            }

            return _.contains(url, 'http://') || _.contains(url, 'https://') ? url : 'http://' + url;
        }
    }
})();
