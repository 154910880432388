(function() {
    'use strict';
    angular.module('serviceApp').directive('actionDetails', actionDetails);
    function actionDetails() {
        var directive = {
            restrict: 'E',
            scope: {
                board: '=',
                user: '<',
                team: '<',
                card: '=',
                selectedAction: '=',
                api: '=',
                horizontal: '=?',
                preview: '=?',
                definition: '=?',
                external: '=?',
                parent: '<'
            },
            template: require('views/tmpl/partials/actionDetails.html'),
            controller: ActionDetailsController
        };

        return directive;
    }

    ActionDetailsController.$inject = ['$scope', 'actionService', 'toastr'];

    function ActionDetailsController($scope, actionService, toastr) {
        $scope.executeEventTrigger = data => {
            $scope.api.executeEventTrigger(
                Object.assign({}, data, { resolvedEventTriggers: $scope.resolvedEventTriggers })
            );
        };

        const actionWatcher = $scope.$watch('selectedAction.id', redrawAction);
        // listen for action redraw events from assign-to-user component
        const actionChangeListener = $scope.$on('redraw-action-form', (event, data) => {
            $scope.selectedAction = _.get(data, 'action');
            redrawAction();
        });

        function redrawAction() {
            $scope.ready = false;
            $scope.resolvedEventTriggers = undefined;
            $scope.defaultTrigger = undefined;
            $scope.integrationData = undefined;

            actionService
                .resolveActionEventTriggers({
                    definition: $scope.definition,
                    action: $scope.selectedAction,
                    options: { withIntegrationBlockers: $scope.preview ? false : true }
                })
                .then(resolvedEventTriggers => {
                    $scope.resolvedEventTriggers = resolvedEventTriggers;
                    $scope.defaultTrigger = _.first($scope.resolvedEventTriggers);
                    $scope.integrationData = {};

                    $scope.integrationData.actionType = _.get($scope.defaultTrigger, 'type', 'action');

                    // find the action form event trigger
                    $scope.actionDetailsEventTrigger = _.find($scope.resolvedEventTriggers, eventTrigger =>
                        _.includes(['run', 'url'], _.get(eventTrigger, 'type', 'action'))
                    );

                    $scope.showActionForm =
                        $scope.actionDetailsEventTrigger &&
                        !_.includes(
                            // these action types require the whole card
                            [
                                'automation-user-opt-in',
                                'team-integration',
                                'user-integration',
                                'fix-user-integration'
                            ],
                            $scope.integrationData.actionType
                        );

                    // the consumer expects this as an array
                    if ($scope.actionDetailsEventTrigger) {
                        $scope.actionDetailsEventTrigger = _.castArray($scope.actionDetailsEventTrigger);
                    }

                    $scope.integrationData.action = $scope.selectedAction;
                    $scope.integrationData.payload = _.get($scope.defaultTrigger, 'payload');
                    $scope.integrationData.integrationType = _.get(
                        $scope.defaultTrigger,
                        'payload.integrationType'
                    );
                    $scope.integrationData.types = _.get(
                        $scope.defaultTrigger,
                        'payload.targetIntegrationTypes'
                    );
                    $scope.integrationData.appId = _.get($scope.defaultTrigger, 'payload.integrationAppId');

                    // for blocking event triggers we don't add the action referrer id
                    if (_.get($scope.defaultTrigger, 'payload.isBlocking')) {
                        $scope.referrer = 'action';
                    } else {
                        $scope.referrer = `action::${_.get($scope, 'selectedAction.id')}`;
                    }

                    $scope.ready = true;
                })
                .catch(e => {
                    toastr.error('Unable to update form');
                });
        }

        $scope.$on('$destroy', () => {
            actionWatcher();
            actionChangeListener();
        });
    }
})();
