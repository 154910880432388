'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('benchmarkLinesConfig', {
    controller,
    template,
    bindings: {
        trendsCalculations: '<',
        value: '<',
        withCalculations: '<',
        onChange: '&'
    }
});
