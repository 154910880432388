(function() {
    'use strict';

    angular.module('serviceApp').directive('boardHomeTile', boardHomeTile);

    function boardHomeTile() {
        var directive = {
            restrict: 'E',
            scope: {
                userBoard: '='
            },
            template: require('views/tmpl/partials/boardHomeTile.html'),
            controller: BoardHomeTileController
        };

        return directive;
    }

    BoardHomeTileController.$inject = ['$scope', 'boardsUtilsService'];

    function BoardHomeTileController($scope, boardsUtilsService) {
        $scope.openBoard = function() {
            boardsUtilsService.openBoard($scope.userBoard);
        };
    }
})();
