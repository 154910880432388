(function() {
    'use strict';

    angular.module('serviceApp').factory('teamServiceNew', TeamServiceNew);

    TeamServiceNew.$inject = ['_', '$resource', '$q', '$rootScope'];

    function TeamServiceNew(_, $resource, $q, $rootScope) {
        var teamUrl = '/api/team';
        var defaultParams = {};

        // Team data object singleton
        let teamObject;

        var teamResourceActions = {
            getTeam: {
                method: 'GET'
            },
            updateTeam: {
                method: 'POST',
                url: teamUrl + '/:teamId'
            },
            addTeamMember: {
                method: 'POST',
                url: teamUrl + '/:teamId/member'
            },
            sendInvite: {
                method: 'PUT',
                url: teamUrl + '/:teamId/member/send'
            },
            removeTeamMember: {
                method: 'DELETE',
                url: teamUrl + '/:teamId/member/:memberId'
            },
            enableUser: {
                method: 'PUT',
                url: teamUrl + '/:teamId/member/:memberId/enable'
            },
            disableUser: {
                method: 'PUT',
                url: teamUrl + '/:teamId/member/:memberId/disable'
            },
            getTeamMembers: {
                method: 'GET',
                url: teamUrl + '/:teamId/member',
                isArray: true
            },
            queryTeamMembers: {
                method: 'POST',
                url: teamUrl + '/:teamId/query'
            },
            getMemberData: {
                method: 'GET',
                url: teamUrl + '/:memberId/data'
            },
            addOwner: {
                method: 'POST',
                url: teamUrl + '/:teamId'
            },
            removeOwner: {
                method: 'POST',
                url: teamUrl + '/:teamId'
            },
            toggleAppDetection: {
                method: 'POST',
                url: teamUrl + '/:teamId'
            },
            deleteTeam: {
                method: 'DELETE',
                url: teamUrl + '/:teamId'
            },
            deleteTeamContent: {
                method: 'POST',
                url: teamUrl + '/:teamId/flush'
            },
            getTeamAuth: {
                method: 'GET',
                url: teamUrl + '/authn/:teamId'
            },
            postTeamAuth: {
                method: 'POST',
                url: teamUrl + '/authn/:teamId'
            }
        };

        var teamResource = $resource(teamUrl, defaultParams, teamResourceActions);

        var service = {
            getTeam: getTeam,
            getCachedOrFetch: getCachedOrFetch,
            updateTeam: updateTeam,
            getTeamMembers: getTeamMembers,
            queryTeamMembers: queryTeamMembers,
            getMemberData: getMemberData,
            addTeamMember: addTeamMember,
            sendInvite: sendInvite,
            removeTeamMember: removeTeamMember,
            enableUser: enableUser,
            disableUser: disableUser,
            addOwner: addOwner,
            removeOwner: removeOwner,
            toggleAppDetection: toggleAppDetection,
            deleteTeam: deleteTeam,
            deleteTeamContent: deleteTeamContent,
            getTeamAuth: getTeamAuth,
            postTeamAuth: postTeamAuth,
            logOut: logOut
        };

        return service;

        ////////////

        function getTeam() {
            return teamResource.getTeam().$promise.then(teamData => {
                if (teamData) {
                    teamObject = teamData.toJSON ? teamData.toJSON() : teamData;
                }

                return teamObject;
            });
        }

        function getCachedOrFetch() {
            return $q(function(resolve, reject) {
                if (teamObject) {
                    return resolve(teamObject);
                }

                getTeam().then(
                    function(retrievedTeam) {
                        return resolve(retrievedTeam);
                    },
                    function(err) {
                        return reject(err);
                    }
                );
            });
        }

        function logOut() {
            //Clear out the team object
            teamObject = undefined;
        }

        function updateTeam(teamId, team) {
            if (!team) {
                return $q.reject({ msg: 'Updated team must be defined' });
            }

            if (!teamId) {
                return $q.reject({ msg: 'Team ID must be defined' });
            }

            const updatedTeam = {
                account: team.account,
                description: team.description,
                title: team.title,
                timezone: team.timezone,
                domains: team.domains,
                partnerDomains: team.partnerDomains,
                userAgreement: team.userAgreement,
                fiscalMonthOffset: team.fiscalMonthOffset,
                fiscalYearBasedOnStart: team.fiscalYearBasedOnStart,
                useRetailCalendar: team.useRetailCalendar,
                retailCalendarType: team.retailCalendarType,
                lastDayOfWeek: team.lastDayOfWeek,
                lastWeekCalculation: team.lastWeekCalculation,
                currentHomeBoard: team.currentHomeBoard,
                coreAuth: team.coreAuth
            };

            return teamResource
                .updateTeam({ teamId: teamId }, updatedTeam)
                .$promise.then(updateTeamSuccess)
                .catch(updateTeamFailure);

            function updateTeamSuccess(team) {
                teamObject = undefined;
                $rootScope.team = team.toJSON();
                $rootScope.$emit('team:updated');
                return team;
            }

            function updateTeamFailure(error) {
                return $q.reject(error);
            }
        }

        function getTeamMembers(teamId) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            return $q.when(teamResource.getTeamMembers({ teamId: teamId }).$promise);
        }

        function queryTeamMembers(teamId, query, options) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            return $q.when(teamResource.queryTeamMembers({ teamId }, { query, options }).$promise);
        }

        function getMemberData(memberId) {
            if (!memberId) {
                return $q.reject({ msg: 'Member id must be defined' });
            }

            return $q.when(teamResource.getMemberData({ memberId: memberId }).$promise);
        }

        function addTeamMember(teamId, teamMember) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            if (!teamMember) {
                return $q.reject({ msg: 'Team member must be defined' });
            }

            let newMember = {
                name: teamMember.name,
                email: teamMember.email,
                emailAlias: teamMember.emailAlias,
                admin: teamMember.admin,
                disable: teamMember.disable,
                homePage: teamMember.homePage
            };
            return $q.when(
                teamResource
                    .addTeamMember({ teamId: teamId }, newMember)
                    .$promise.then((teamObject = undefined))
            );
        }

        function sendInvite(teamId, teamMember) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            if (!teamMember) {
                return $q.reject({ msg: 'Team member must be defined' });
            }

            return $q.when(
                teamResource.sendInvite(
                    { teamId: teamId },
                    {
                        name: teamMember.name,
                        email: teamMember.email
                    }
                ).$promise
            );
        }

        function removeTeamMember(teamId, memberId) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }
            if (!memberId) {
                return $q.reject({ msg: 'Member id must be defined' });
            }

            return $q.when(teamResource.removeTeamMember({ teamId: teamId, memberId: memberId }).$promise);
        }

        function enableUser(teamId, memberId) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }
            if (!memberId) {
                return $q.reject({ msg: 'Member id must be defined' });
            }

            return $q.when(teamResource.enableUser({ teamId: teamId, memberId: memberId }, {}).$promise);
        }

        function disableUser(teamId, memberId) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }
            if (!memberId) {
                return $q.reject({ msg: 'Member id must be defined' });
            }

            return $q.when(teamResource.disableUser({ teamId: teamId, memberId: memberId }, {}).$promise);
        }

        function addOwner(teamId, ownerIds, newOwnerId) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            if (!angular.isDefined(ownerIds)) {
                return $q.reject({ msg: 'Owners must be defined' });
            }

            const ownerIDsArray = _.cloneDeep(ownerIds);

            if (!newOwnerId) {
                return $q.reject({ msg: 'New owner must be defined' });
            }

            if (_.find(ownerIDsArray, newOwnerId)) {
                return $q.reject({ msg: 'Member is already an owner' });
            }

            ownerIDsArray.push(newOwnerId);

            return $q.when(teamResource.addOwner({ teamId: teamId }, { ownerIds: ownerIDsArray }).$promise);
        }

        function removeOwner(teamId, ownerIds, ownerToRemoveId) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            if (!angular.isDefined(ownerIds)) {
                return $q.reject({ msg: 'Owners must be defined' });
            }

            const ownerIDsArray = _.cloneDeep(ownerIds);

            if (!ownerToRemoveId) {
                return $q.reject({ msg: 'Owner to remove must be defined' });
            }

            var isOwner = !!_.find(ownerIDsArray, function(id) {
                return id == ownerToRemoveId;
            });

            if (!isOwner) {
                return $q.reject({ msg: 'Member is not an owner' });
            }

            _.remove(ownerIDsArray, function(id) {
                return id == ownerToRemoveId;
            });

            return $q.when(
                teamResource.removeOwner({ teamId: teamId }, { ownerIds: ownerIDsArray }).$promise
            );
        }

        function toggleAppDetection(teamId, enable) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            if (!angular.isDefined(enable)) {
                return $q.reject({ msg: 'Enable must be defined' });
            }

            return $q.when(
                teamResource.toggleAppDetection(
                    { teamId: teamId },
                    {
                        policy: {
                            appDetection: enable ? 'enable' : 'disable'
                        }
                    }
                ).$promise
            );
        }

        function deleteTeam(teamId) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            return $q.when(teamResource.deleteTeam({ teamId: teamId }).$promise);
        }

        function deleteTeamContent(teamId) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            return $q.when(teamResource.deleteTeamContent({ teamId: teamId }, {}).$promise);
        }

        function getTeamAuth(teamId) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            return $q.when(teamResource.getTeamAuth({ teamId: teamId }).$promise);
        }

        function postTeamAuth(teamId, auth) {
            if (!teamId) {
                return $q.reject({ msg: 'Team id must be defined' });
            }

            return teamResource
                .postTeamAuth({ teamId: teamId }, auth)
                .$promise.then(updateTeamAuthSuccess)
                .catch(updateTeamAuthFailure);

            function updateTeamAuthSuccess(team) {
                return team;
            }

            function updateTeamAuthFailure(error) {
                return $q.reject(error);
            }
        }
    }
})();
