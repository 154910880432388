import template from './index.html';
import Controller from './controller';
import './index.scss';
import './card-add-to-dashboard-modal';
import './card-create-from-template-modal';

angular.module('serviceApp').directive('cardReportView', () => ({
    restrict: 'E',
    scope: {
        card: '<',
        user: '<',
        board: '<',
        team: '<',
        user: '<',
        panel: '<',
        hierarchy: '=?'
    },
    template,
    controller: Controller
}));
