(function() {
    'use strict';
    angular.module('serviceApp').directive('appPolicyEditor', appPolicyEditor);
    function appPolicyEditor() {
        var directive = {
            restrict: 'E',
            scope: {
                policy: '=',
                app: '=',
                integration: '=',
                isNew: '=?', // is set to true when the content policy is being created for the first time
                index: '='
            },
            template: require('views/tmpl/partials/appOptions/appPolicyEditor.html'),
            controller: AppPolicyEditor
        };

        return directive;
    }

    AppPolicyEditor.$inject = [
        '_',
        '$log',
        '$scope',
        '$rootScope',
        'appServiceNew',
        'toastr',
        'contentTypeService',
        'contentFiltersService',
        'metadataService',
        'integrationService'
    ];

    function AppPolicyEditor(
        _,
        $log,
        $scope,
        $rootScope,
        appServiceNew,
        toastr,
        contentTypeService,
        contentFiltersService,
        metadataService,
        integrationService
    ) {
        $scope.forms = {};
        $scope.productName = $rootScope.productName;

        $scope.isSupport = false;
        $scope.isMappings = false;
        $scope.isFilters = false;
        $scope.existsDefault = false;
        $scope.patternExists = false;
        $scope.isEditing = false;

        $scope.newContentSchema = [];
        $scope.contentFilters = [];
        $scope.contentTypes = [];

        $scope.confirmDeleteModal = false;
        $scope.confirmCloseModal = false;
        const FULL_SYNC_BY_POLICY_INTEGRATION_TYPES = [
            'salesforce-oauth',
            'hubspot',
            'bullhorn',
            'google-sheet'
        ];

        const integrationType = _.get($scope, 'app.integrationType');
        metadataService
            .getMetadata({
                contentType: _.get($scope, 'policy.contentType'),
                integrationType: integrationType
            })
            .then(results => {
                $scope.metadata = results;
                $scope.perPolicyFullSyncingSupported = FULL_SYNC_BY_POLICY_INTEGRATION_TYPES.includes(
                    integrationType
                );
            })
            .catch(err => {
                console.log(err);
            });

        $scope.showConfirm = function(isDelete) {
            if (isDelete || $scope.isNew) {
                $scope.confirmDeleteModal = true;
            } else {
                $scope.confirmCloseModal = true;
            }
        };

        $scope.hideConfirm = function() {
            $scope.confirmDeleteModal = false;
            $scope.confirmCloseModal = false;
        };

        $scope.toggleSupport = function() {
            $scope.isSupport = !$scope.isSupport;
        };

        $scope.toggleMappings = function() {
            $scope.isMappings = !$scope.isMappings;
        };

        $scope.toggleFilters = function() {
            $scope.isFilters = !$scope.isFilters;
        };

        initSources();
        initCustomConfig();

        $scope.removeContentPolicy = function() {
            _.pullAt($scope.app.contentPolicies, $scope.index);
            $scope.confirmDeleteModal = false;
            appServiceNew.updateApp($scope.app).then(
                function(updatedApp) {
                    toastr.success('App Updated!', '');
                    $scope.app = updatedApp.app;
                    $scope.closeDrawer();
                },
                function(error) {
                    $scope.$emit('longAPIEnd');
                    $log.debug('Failed to update app: ' + $scope.app.title);
                    $log.error('Failed to update app: ' + $scope.app.title + ' : ' + error);
                    toastr.error('Failed to update app: ' + error, '');
                }
            );
        };

        $scope.clean = function(form) {
            form.$setPristine();
            form.$setUntouched();
        };

        $scope.isDirty = function(policyForm, contentForm, filtersForm) {
            var checkDirty =
                (policyForm && policyForm.$dirty && policyForm.$valid) ||
                (contentForm && contentForm.$dirty) ||
                (filtersForm && filtersForm.$dirty);

            var checkValid = policyForm ? policyForm.$valid : true;

            if (checkDirty) {
                $rootScope.appDirty = true;
            }
            return checkDirty || ($rootScope.appDirty && checkValid);
        };

        $scope.isInvalidURL = function(urlPattern = '') {
            // Find any existing policy that has the same pattern as this one.
            $scope.existingPattern = _.find(_.without($scope.app.contentPolicies, $scope.policy), policy => {
                return policy.urlPattern === urlPattern;
            });
            return !!$scope.existingPattern;
        };

        $scope.changeURL = function() {
            $scope.existsDefault = false;
            $scope.patternExists = false;
            $scope.isEditing = true;
        };

        function getContentTypes() {
            contentTypeService
                .getContentTypes({
                    appId: $scope.app.id,
                    appName: $scope.app.name,
                    policyName: $scope.policy.name
                })
                .then(
                    function(contentTypes) {
                        $scope.contentTypes = !_.isEmpty(contentTypes)
                            ? _(contentTypes)
                                  // Add `displayName` for handling custom content types
                                  // with prepended teamIds.
                                  .map(contentType => {
                                      // Ignore empty types.
                                      if (!contentType.name) {
                                          return;
                                      }
                                      return _.set(
                                          contentType,
                                          'displayName',
                                          contentTypeService.getContentTypePrettyName(contentType.name)
                                      );
                                  })
                                  .compact()
                                  // Make sure that we have sorted list of content types.
                                  .sortBy(contentType => contentType.displayName)
                                  .value()
                            : [];

                        let contentType;
                        if ($scope.policy.contentType) {
                            contentType = _.find(contentTypes, { name: $scope.policy.contentType });
                        }
                        $scope.contentType = contentType;
                        $scope.contentSchema = _.get(contentType, 'schema');
                        $scope.newContentSchema = [];
                    },
                    function(error) {
                        $log.error(
                            'Failed to fetch content types for: ' + $scope.policy.contentType + ' : ' + error
                        );
                        $scope.contentSchema = null;
                        $scope.newContentSchema = [];
                    }
                );
        }

        function getContentFilters() {
            //Update filters
            contentFiltersService.getContentFilters().then(
                function(contentFilters) {
                    $scope.contentFilters = !_.isEmpty(contentFilters) ? contentFilters : [];
                },
                function(error) {
                    $log.error(
                        'Failed to fetch content filters for: ' + $scope.policy.contentType + ' : ' + error
                    );
                    $scope.contentFilters = [];
                }
            );
        }

        $scope.$watchCollection('policy', function() {
            if (_.isEmpty($scope.contentTypes)) {
                getContentTypes();
                getContentFilters();
            } else if ($scope.policy.contentType) {
                const contentType = _.find($scope.contentTypes, { name: $scope.policy.contentType });
                $scope.contentType = contentType;
                $scope.contentSchema = _.get(contentType, 'schema');
                $scope.newContentSchema = [];
            } else {
                $scope.contentSchema = null;
                $scope.newContentSchema = [];
                $scope.contentFilters = [];
            }
            initSources();
            initCustomConfig();
        });

        $scope.$on('updatedContentTypes', function() {
            if ($scope.policy.contentType) {
                contentTypeService
                    .getContentTypes({
                        appId: $scope.app.id,
                        appName: $scope.app.name,
                        contentType: $scope.policy.contentType,
                        policyName: getPolicyName()
                    })
                    .then(
                        function(contentType) {
                            $scope.contentType = contentType;
                            $scope.contentSchema = _.get(contentType, 'schema');
                            $scope.newContentSchema = [];
                        },
                        function(error) {
                            $log.error(
                                'Failed to fetch content types for: ' +
                                    $scope.policy.contentType +
                                    ' : ' +
                                    error
                            );
                            $scope.contentSchema = null;
                            $scope.newContentSchema = [];
                        }
                    );
            } else {
                $scope.contentSchema = null;
                $scope.newContentSchema = [];
            }
        });

        function getPolicyName() {
            const policy = $scope.policy;
            const app = $scope.app;
            if (policy.name) {
                return policy.name;
            } else if (policy.title && app.rootDomain) {
                return `${app.rootDomain}::${_.kebabCase(policy.title)}`;
            }
            return undefined;
        }

        $scope.$on('undoChanges', function() {
            if (_.get($scope.forms, 'appContentPolicies')) {
                $scope.forms.appContentPolicies.$setPristine();
                $scope.forms.appContentPolicies.$setUntouched();
            }
            if (_.get($scope.forms, 'contentTypeFilters')) {
                $scope.forms.contentTypeFilters.$setPristine();
                $scope.forms.contentTypeFilters.$setUntouched();
            }
            if (_.get($scope.forms, 'contentTypeMappings')) {
                $scope.forms.contentTypeMappings.$setPristine();
                $scope.forms.contentTypeMappings.$setUntouched();
            }
            $rootScope.appDirty = false;
            $scope.newContentSchema = [];
        });

        $scope.updateApp = function(form, contentform, filtersForm) {
            try {
                $scope.policy.sources = translateSources();
            } catch (e) {
                toastr.error('Error Occured translating sources', e.message);
            }

            $scope.policy.customConfig = translateCustomConfig();

            //ensure there is no content policy URL pattern for the last policy
            //we must have a catchall policy to prevent issues server side
            $scope.app.contentPolicies[$scope.app.contentPolicies.length - 1].urlPattern = '';

            if (form && form.$dirty) {
                appServiceNew.updateApp($scope.app).then(
                    function(updatedApp) {
                        toastr.success('App Updated!', '');
                        $scope.app = updatedApp.app;
                        form.$setPristine();
                        form.$setUntouched();
                        $scope.closeDrawer();
                    },
                    function(error) {
                        $scope.$emit('longAPIEnd');
                        $log.debug('Failed to update app: ' + $scope.app.title);
                        $log.error('Failed to update app: ' + $scope.app.title + ' : ' + error);
                        toastr.error('Failed to update app: ' + error, '');
                    }
                );
            }

            if (contentform && contentform.$dirty) {
                _.forEach($scope.newContentSchema, function(newContent) {
                    if (newContent.name && newContent.name !== '') {
                        $scope.contentSchema[newContent.name] = newContent;
                    }
                });
                // Set any empty display label values to null.
                _.forEach($scope.contentSchema, function(schema) {
                    if (!_.get(schema, 'display.label')) {
                        _.set(schema, 'display.label', null);
                    }
                });
                var payload = {};

                // Send the app ID and full schema as a payload to the "update content type" API.
                // The API will determine which items have changed.
                payload.appId = _.get($scope, 'app.id');
                _.set(payload, 'data.contentType', $scope.policy.contentType);
                _.set(payload, 'data.schema', $scope.contentSchema);
                _.set(payload, 'data.policyName', getPolicyName());

                contentTypeService.updateContentType(payload).then(
                    function() {
                        // The next card that uses display options should load them fresh
                        // from the API, since we may have just changed them.
                        $rootScope.forceDisplayOptionsLoad = true; // this isn't working?
                        $scope.$emit('updatedContentTypes');
                        toastr.success('Content Mappings Updated!', '');
                        $scope.closeDrawer();
                    },
                    function(response) {
                        const errorMessage = _.get(response, 'data.error');
                        $scope.$emit('longAPIEnd');
                        $log.debug('Failed to update content mappings: ' + $scope.policy.contentType);
                        $log.error(
                            'Failed to update content mappings: : ' +
                                $scope.policy.contentType +
                                ' : ' +
                                errorMessage
                        );
                        toastr.error('Failed to update content mappings: ' + errorMessage, '');
                    }
                );
            }

            if (filtersForm && filtersForm.$dirty) {
                contentFiltersService.updateContentFilters($scope.contentFilters).then(
                    function(contentFilters) {
                        $scope.contentFilters = contentFilters ? contentFilters : [];
                        toastr.success('Content Filters Updated!', '');
                        filtersForm.$setPristine();
                        filtersForm.$setUntouched();
                        $scope.closeDrawer();
                    },
                    function(response) {
                        const errorMessage = _.get(response, 'data.error');
                        $scope.$emit('longAPIEnd');
                        $log.debug('Failed to update content filters: ' + $scope.policy.contentType);
                        $log.error(
                            'Failed to update content filters: : ' +
                                $scope.policy.contentType +
                                ' : ' +
                                errorMessage
                        );
                        toastr.error('Failed to update content filters: ' + errorMessage, '');
                    }
                );
            }

            $rootScope.appDirty = false;
            $scope.isNew = false;
        };

        /**
         * Handles initializing the `policy.customConfig` into the editor state as `$scope.potentialConfigOptions`.
         *
         * @returns {void}
         */
        function initCustomConfig() {
            let customConfig = _.get($scope.policy, 'customConfig', {});
            if (_.isString(customConfig)) {
                try {
                    customConfig = JSON.parse(customConfig);
                } catch (e) {
                    // Swallow
                }
            }

            $scope.potentialConfigOptions = _.map($scope.app.policySchema, function(schema, name) {
                return {
                    name,
                    label: schema.label || _.startCase(name),
                    ...schema,
                    value: _.get(customConfig, [name], schema.default)
                };
            });
        }

        /**
         * Converts the `$scope.potentialConfigOptions` editor state back into the `policy.customConfig`
         * the API is expecting.
         *
         * @returns {String}
         */
        function translateCustomConfig() {
            let customConfig = _.get($scope.policy, 'customConfig', {});
            if (_.isString(customConfig)) {
                try {
                    customConfig = JSON.parse(customConfig);
                } catch (e) {
                    // Swallow
                }
            }
            _.forEach($scope.potentialConfigOptions, function(option) {
                if (!_.isNil(option.value)) {
                    _.set(customConfig, [option.name], option.value);
                } else {
                    // Avoid empty strings for property values.
                    _.unset(customConfig, [option.name]);
                }
            });
            return _.isObject(customConfig) ? JSON.stringify(customConfig) : customConfig;
        }

        function initSources() {
            $scope.potentialSources = [
                {
                    type: 'desktop',
                    title: 'Desktop'
                },
                {
                    type: 'web',
                    title: 'Web'
                }
            ];

            if ($scope.app.integrationType) {
                $scope.potentialSources.push({
                    type: $scope.app.integrationType,
                    title: _.startCase($scope.app.integrationType)
                });
            }

            if ($scope.policy.sources && !_.isEmpty($scope.policy.sources)) {
                $scope.potentialSources = _.forEach($scope.potentialSources, function(source) {
                    if ($scope.policy.sources.indexOf(source.type) > -1) {
                        return _.set(source, 'value', true);
                    } else {
                        return _.set(source, 'value', false);
                    }
                });
            } else {
                $scope.potentialSources = _.forEach($scope.potentialSources, function(source) {
                    return _.set(source, 'value', true);
                });
            }

            _.forEach($scope.potentialSources, function(source) {
                _toggleView(source);
            });

            $scope.potentialSourcesBackup = angular.copy($scope.potentialSources);
        }

        function translateSources() {
            var selectedSources = _.filter($scope.potentialSources, {
                value: true
            });

            _.forEach($scope.potentialSources, function(source) {
                _toggleView(source);
            });

            if (selectedSources.length == $scope.potentialSources.length) {
                //all selected
                return null;
            } else if (selectedSources.length === 0) {
                //none selected
                throw {
                    message: 'You cannot have no selected data sources in policy ' + $scope.policy.title
                };
            } else {
                // some selected
                return _.map(selectedSources, function(source) {
                    return source.type;
                });
            }
        }

        function _toggleView(source) {
            if (source.title === $scope.potentialSources[1].title) {
                $scope.setWeb = source.value;
            }
        }

        $scope.closeDrawer = function(undo) {
            $scope.confirmCloseModal = false;
            if (undo) {
                $scope.$root.$emit('policyUndoChanges');
            }
            $scope.$root.$emit('rightDrawer:close');
        };

        $scope.isDefaultPolicy = function(policy) {
            return _.isEmpty(policy.urlPattern) && _.isEmpty(policy.contentType) && !$scope.isNew;
        };

        $scope.triggerFullSyncForPolicy = function() {
            const primaryUserId = _.get($scope, 'integration.primaryUserId');
            const pollPrimaryOnly = _.get($scope, 'integration.pollPrimaryOnly');
            const integrationId = _.get($scope, 'integration.id');
            const integrationTitle = _.get($scope, 'integration.title', 'The integration');
            const policyTitle = _.get($scope, 'policy.title');
            if (!primaryUserId || !pollPrimaryOnly) {
                toastr.error(
                    `Failed to trigger a full sync for "${policyTitle}" policy.`,
                    `${integrationTitle} must have a primary user and poll primary only enabled.`
                );
                return;
            }
            integrationService
                .syncUserIntegration(integrationId, {}, undefined, primaryUserId, $scope.policy.name)
                .then(() => {
                    toastr.success(`Triggered a full sync for "${policyTitle}" policy.`, '');
                })
                .catch(err => {
                    console.log(err);
                    toastr.error(
                        `Failed to trigger a full sync for "${policyTitle}" policy.`,
                        _.get(err, 'message', err)
                    );
                });
        };
    }
})();
