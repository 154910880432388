'use strict';

export default class Controller {
    static get $inject() {
        return ['$scope'];
    }
    constructor(scope) {
        this.scope = scope;
        this.scope.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.scope.$emit('card-editor:toggle-is-open', {
            row: this.scope.row || null
        });
    }
}
