import template from './index.html';
import controller from './controller';

import './index.scss';

angular.module('serviceApp').directive('pill', () => ({
    restrict: 'E',
    scope: {
        clickable: '<',
        isDot: '<',
        color: '<?',
        theme: '<?',
        small: '<',
        displayLoading: '<',
        clicked: '<'
    },
    template,
    transclude: true,
    controller
}));
