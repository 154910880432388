(function() {
    'use strict';

    angular.module('serviceApp').factory('storageService', StorageService);

    StorageService.$inject = ['_', '$localStorage', '$sessionStorage', '$window'];

    /**
     * StorageService - This service can be used to save data in the session
     * or local storage on the browser.
     */
    function StorageService(_, $localStorage, $sessionStorage, $window) {
        var LOCAL = 'local';
        var SESSION = 'session';

        var factory = {
            set: set,
            setRaw: setRaw,
            get: get,
            getRaw: getRaw,
            delete: deleteKey,
            flush: flush
        };

        return factory;

        /**
         * set - this sets key/value in the target store
         *
         * @param  {type} key        The storage key
         * @param  {type} value      The key payload
         * @param  {type} expiration expiration time of the key in Millis
         * @param  {type} type       type of store, either "local" or "session"
         */

        function set(key, value, expiration, type) {
            var payload = {};

            payload.expiration = 0;
            if (expiration && _.isInteger(expiration)) {
                var now = new Date().getTime();
                payload.expiration = now + expiration;
            }
            payload.value = value;
            if (_.isEqual(type, LOCAL)) {
                $localStorage[key] = payload;
            } else {
                $sessionStorage[key] = payload;
            }
        }

        /**
         * setRaw - this sets key/value in the target store, bypassing the ngStorage service.
         *          This means the key will be not be prefixed, and any serialization must be
         *          done by the caller.
         *
         *
         * @param  {type} key        The storage key
         * @param  {type} value      The key payload
         * @param  {type} type       type of store, either "local" or "session"
         */

        function setRaw(key, value, type = 'local') {
            let storage;
            if (type === 'local') {
                storage = $window.localStorage;
            } else if (type === 'session') {
                storage = $window.sessionStorage;
            } else {
                throw new Error(`The type argument to getRaw must be 'local' or 'session', got ${type}`);
            }
            if (value) {
                try {
                    storage.setItem(key, JSON.stringify(value));
                } catch (error) {
                    // error
                }
            }
        }

        /**
         * getRaw - this gets the value by key in the target store
         *
         * @param  {type} key  The storage key
         * @param  {type} type type of store, either "local" or "session"
         * @return {type}      payload of the key or null if empty or expired.
         */

        function get(key, type) {
            var testKey = _.isEqual(type, LOCAL) ? $localStorage[key] : $sessionStorage[key];
            if (_.isNil(testKey)) {
                return null;
            } else if (_isInvalid(testKey, type)) {
                deleteKey(testKey, type);
                return null;
            } else {
                return testKey.value;
            }
        }

        /**
         * get - this gets the value by key in the target store, bypassing the ngStorage service.
         *       This means the key will not be prefixed, and any deserialization must be done
         *       by the caller.
         *
         * @param  {type} key  The storage key
         * @param  {type} type type of store, either "local" or "session"
         * @return {type}      payload of the key or null if empty or expired.
         */

        function getRaw(key, type = 'local') {
            let storedValue;
            if (type === 'local') {
                storedValue = $window.localStorage.getItem(key);
            } else if (type === 'session') {
                storedValue = $window.sessionStorage.getItem(key);
            } else {
                throw new Error(`The type argument to getRaw must be 'local' or 'session', got ${type}`);
            }
            try {
                return storedValue ? JSON.parse(storedValue) : {};
            } catch (exc) {
                return {};
            }
        }

        /**
         * deleteKey - this deletes the key/value from the target store
         *
         * @param  {type} key  The storage key
         * @param  {type} type type of store, either "local" or "session"
         */

        function deleteKey(key, type) {
            if (_.isEqual(type, LOCAL)) {
                delete $localStorage[key];
            } else {
                delete $sessionStorage[key];
            }
        }

        /**
         * flush - this flushes the content of the session/local storage instance
         *
         * @param  {type} type type of store, either "local" or "session"
         */

        function flush(type) {
            if (_.isEqual(type, LOCAL)) {
                $localStorage.$reset();
            } else {
                $sessionStorage.$reset();
            }
        }

        function _isInvalid(key) {
            return key.expiration && key.expiration < new Date().getTime();
        }
    }
})();
