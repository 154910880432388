'use strict';
import template from './index.html';
import Controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('cardDefinitionManagement', () => ({
    restrict: 'E',
    scope: {
        card: '<',
        user: '<',
        board: '=',
        facets: '=?',
        query: '<?',
        getCardHitch: '&',
        getCardsHitch: '&'
    },
    link: function($scope) {
        $scope.api = { refresh: $scope.refresh };
    },
    template: template,
    controller: Controller
}));
