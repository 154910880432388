(function() {
    'use strict';

    angular.module('serviceApp').filter('durationInMonths', durationInMonths);

    durationInMonths.$inject = ['_', 'moment', '$filter'];

    function durationInMonths(_, $moment, $filter) {
        const filter = (milliseconds, formatAsNumber = true) => {
            if (_.isNil(milliseconds)) {
                return formatAsNumber ? 'N/A' : undefined;
            }

            const months = _.round($moment.duration(milliseconds).asMonths());
            let monthsAsNumber = `${$filter('number')(months)} month`;
            if (months !== 1) {
                monthsAsNumber = $filter('pluralize')(monthsAsNumber);
            }

            return formatAsNumber ? monthsAsNumber : months;
        };

        return filter;
    }
})();
