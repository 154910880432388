'use strict';

import _ from 'lodash';

export const getClearbitUrl = website => {
    const l = document.createElement('a');
    l.href = website;

    if (website.indexOf('http') > -1) {
        return `//logo.clearbit.com/${l.hostname}`;
    } else {
        return `//logo.clearbit.com/${website}`;
    }
};
