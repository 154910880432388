// This module is taken from https://github.com/saintmac/angular-cache-buster
// edited for nexd needs by Marcus Motill

angular
    .module('ngCacheBuster', [])
    .provider('httpRequestInterceptorCacheBuster', function() {
        this.logRequests = false;

        //Default to allow (i.e. block all except matches)
        this.deny = false;

        //Select allowList or denyList, default to allowList
        this.setMatchList = function(list, deny) {
            this.deny = typeof deny != 'undefined' ? deny : false;
            this.matchlist = list;
        };

        this.setLogRequests = function(logRequests) {
            this.logRequests = logRequests;
        };

        this.$get = [
            '$cookies',
            '$q',
            '$log',
            function($cookies, $q, $log) {
                var matchlist = this.matchlist;
                var logRequests = this.logRequests;
                var deny = this.deny;
                if (logRequests) {
                    $log.log('Deny? ', deny);
                }

                var buster = {
                    request: function(config) {
                        //Deny list by default, match with allowlist
                        var busted = !deny;

                        for (var i = 0; i < matchlist.length; i++) {
                            if (config.url.match(matchlist[i])) {
                                busted = deny;
                                break;
                            }
                        }

                        //Bust if the URL was on denylist or not on allowlist
                        if (busted) {
                            config.url = config.url.replace(/[?|&]cacheBuster=\d+/, '');
                            //Some url's already have '?' attached
                            config.url += config.url.indexOf('?') === -1 ? '?' : '&';

                            var d = new Date();

                            if (config.url.indexOf('api') > -1) {
                                config.url += 'cacheBuster=' + d.getTime();
                            } else if (config.url.indexOf('version.json') > -1) {
                                config.url += 'cacheBuster=' + d.getTime();
                            } else {
                                var appVersion = $cookies.get('appVersion');
                                if (appVersion) {
                                    config.url += 'cacheBuster=' + appVersion;
                                } else {
                                    config.url += 'cacheBuster=' + d.getTime();
                                }
                            }
                        }

                        if (logRequests) {
                            var log = 'request.url =' + config.url;
                            busted ? $log.warn(log) : $log.info(log);
                        }

                        return config || $q.when(config);
                    }
                };

                return buster;
            }
        ];
    })
    .config([
        '$httpProvider',
        function($httpProvider) {
            return $httpProvider.interceptors.push('httpRequestInterceptorCacheBuster');
        }
    ]);
