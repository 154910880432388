(function() {
    'use strict';

    angular.module('serviceApp').directive('createBoardTile', createBoardTile);

    function createBoardTile() {
        var directive = {
            restrict: 'E',
            scope: {},
            template: require('views/tmpl/partials/createBoardTile.html'),
            controller: CreateBoardTileController
        };

        return directive;
    }

    CreateBoardTileController.$inject = ['$scope', '$state'];

    function CreateBoardTileController($scope, $state) {
        $scope.openCreateBoard = function() {
            $state.go('boards.new');
        };
    }
})();
