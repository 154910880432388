(function() {
    'use strict';

    angular.module('serviceApp').factory('boardServiceNew', BoardServiceNew);

    BoardServiceNew.$inject = ['$resource', '$q', '$http', '$rootScope'];

    function BoardServiceNew($resource, $q, $http, $rootScope) {
        var boardUrl = '/api/board';
        var defaultParams = {};

        var boardResourceActions = {
            getBoardByShortcode: {
                method: 'GET',
                params: {
                    shortcode: '@shortcode'
                }
            },
            getBoardByShortcodeWithDefinition: {
                method: 'POST',
                params: {
                    shortcode: '@shortcode'
                }
            },
            getBoardByExternalId: {
                method: 'GET',
                params: {
                    externalId: '@externalId'
                }
            },
            getBoardById: {
                method: 'GET',
                params: {
                    id: '@id'
                }
            },
            getBoards: {
                method: 'GET',
                isArray: true
            },
            getBoardMembers: {
                method: 'GET',
                url: boardUrl + '/:boardId/member',
                isArray: true
            },
            addBoardMember: {
                method: 'POST',
                url: boardUrl + '/:boardId/member'
            },
            removeBoardMember: {
                method: 'DELETE',
                url: boardUrl + '/:boardId/member/:memberId'
            },
            updateBoard: {
                method: 'POST',
                url: boardUrl + '/:boardId'
            },
            createBoard: {
                method: 'POST',
                url: boardUrl + '/create'
            },
            deleteBoard: {
                method: 'DELETE',
                url: boardUrl + '/:boardId'
            }
        };

        var boardResource = $resource(boardUrl, defaultParams, boardResourceActions);

        var service = {
            getBoard: getBoard,
            getBoards: getBoards,
            getBoardMembers: getBoardMembers,
            addBoardMember: addBoardMember,
            removeBoardMember: removeBoardMember,
            updateBoard: updateBoard,
            createBoard: createBoard,
            deleteBoard: deleteBoard
        };

        return service;

        ////////////

        function getBoard(board) {
            if (!board) {
                return $q.reject({
                    msg: 'Board must be defined'
                });
            }

            // Go through all the currently inflight card requests and cancel them
            if (!board.preventInterrupt && $rootScope._currentCardRequest) {
                _.each($rootScope._currentCardRequest, request => {
                    if (!request.$resolved) {
                        request.$cancelRequest();
                    }
                });
                $rootScope._currentCardRequest = undefined;
            }

            if (board.id) {
                return boardResource
                    .getBoardById({
                        id: board.id,
                        facets: board.facets
                    })
                    .$promise.then(getBoardSuccess)
                    .catch(getBoardFailure);
            } else if (board.shortcode && board.boardDefinition) {
                return boardResource
                    .getBoardByShortcodeWithDefinition({
                        shortcode: board.shortcode,
                        facets: board.facets,
                        boardDefinition: board.boardDefinition
                    })
                    .$promise.then(getBoardSuccess)
                    .catch(getBoardFailure);
            } else if (board.shortcode) {
                return boardResource
                    .getBoardByShortcode({
                        shortcode: board.shortcode,
                        facets: board.facets
                    })
                    .$promise.then(getBoardSuccess)
                    .catch(getBoardFailure);
            } else if (board.externalId) {
                return boardResource
                    .getBoardByExternalId({
                        externalId: board.externalId,
                        facets: board.facets
                    })
                    .$promise.then(getBoardSuccess)
                    .catch(getBoardFailure);
            } else {
                return $q.reject({
                    msg: 'Board.id or board.shortcode must be defined, truthy strings'
                });
            }

            function getBoardSuccess(returnedBoard) {
                // If we were resolving a board in a dirty state, re-apply the `isDirty` flag.
                // Since it's not a persistable board property, it's not something the server
                // will return.
                if (_.get(board, 'boardDefinition.isDirty')) {
                    _.set(returnedBoard, 'definition.isDirty', true);
                }
                return returnedBoard;
            }

            function getBoardFailure(error) {
                return $q.reject(error);
            }
        }

        function getBoards() {
            return boardResource
                .getBoards()
                .$promise.then(getBoardsSuccess)
                .catch(getBoardsFailure);

            function getBoardsSuccess(boards) {
                return boards;
            }

            function getBoardsFailure(error) {
                return $q.reject(error);
            }
        }

        function getBoardMembers(boardId) {
            if (!boardId) {
                return $q.reject({
                    msg: 'Board id must be defined'
                });
            }

            return boardResource
                .getBoardMembers({
                    boardId: boardId
                })
                .$promise.then(getMembersSuccess)
                .catch(getMembersFailure);

            function getMembersSuccess(members) {
                return members;
            }

            function getMembersFailure(error) {
                return $q.reject(error);
            }
        }

        function addBoardMember(boardId, email) {
            if (!email) {
                return $q.reject({
                    msg: 'email id must be defined'
                });
            }

            if (!boardId) {
                return $q.reject({
                    msg: 'Board id must be defined'
                });
            }

            return boardResource
                .addBoardMember(
                    {
                        boardId: boardId
                    },
                    {
                        email: email
                    }
                )
                .$promise.then(addBoardMemberSuccess)
                .catch(addBoardMemberFailure);

            function addBoardMemberSuccess(member) {
                return member;
            }

            function addBoardMemberFailure(error) {
                return $q.reject(error);
            }
        }

        function removeBoardMember(boardId, memberId) {
            if (!memberId) {
                return $q.reject({
                    msg: 'Member id must be defined'
                });
            }

            if (!boardId) {
                return $q.reject({
                    msg: 'Board id must be defined'
                });
            }

            return $q.when(
                boardResource.removeBoardMember({
                    boardId: boardId,
                    memberId: memberId
                })
            );
        }

        function updateBoard(board) {
            if (!board) {
                return $q.reject({
                    msg: 'Board must be defined'
                });
            }

            return $q.when(
                boardResource.updateBoard(
                    {
                        boardId: board.id
                    },
                    board
                )
            );
        }

        function createBoard(board) {
            if (!board.title) {
                return $q.reject({
                    msg: 'Board title must be defined'
                });
            }

            if (!board.query) {
                return $q.reject({
                    msg: 'Board query must be defined'
                });
            }

            return $q.when(boardResource.createBoard({}, board).$promise);
        }

        function deleteBoard(boardId) {
            if (!boardId) {
                return $q.reject({
                    msg: 'Board id must be defined'
                });
            }

            return $q.when(
                boardResource.deleteBoard(
                    {
                        boardId: boardId
                    },
                    {}
                ).$promise
            );
        }
    }
})();
