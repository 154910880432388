'use strict';

angular.module('serviceApp').service('overrideService', function() {
    /**
     * Apply overrides from a URL to a definition
     * @param {*} definition report definition to be resolved
     * @param {*} overrides override object from a URL
     * @returns
     */
    this.applyOverrides = function(definition, overrides) {
        _.set(definition, 'isDirty', true);
        if (overrides.filterOverrides) {
            const filterSchema = {};
            // we have a list of filter properties, translate them so they can be resolved
            _.forEach(_.get(overrides, 'filterOverrides.schema.propertyKeys'), function(prop) {
                _.set(filterSchema, [prop], { type: 'interpreted' });
            });
            // apply filter overrides
            _.set(definition, 'propertySchema.filter', filterSchema);
            _.set(
                definition,
                'propertySchema.filter._display',
                _.get(overrides, 'filterOverrides.schema._display')
            );
            _.set(definition, 'propertyValues.filter', _.get(overrides, 'filterOverrides.values'));
        }
        // apply viz overrides
        _.each(overrides.vizOverrides, (value, key) => {
            const initialValue = _.get(definition, key);
            // The options that are merged may be Arrays, and rather than trust the
            // hazardous merge, return the array verbatum. The array values should be
            // in it's entirety.
            const updatedValue = _.mergeWith(initialValue, value, (dest, src) => {
                if (_.isArray(dest)) {
                    return src;
                }
            });
            _.set(definition, key, updatedValue);
        });
        if (overrides.timeframeFilter) {
            _.set(definition, 'card.config.timeframeFilter', overrides.timeframeFilter);
        }
        return definition;
    };
});
