'use strict';

import './index.scss';
import Controller from './controller';
import template from './index.html';

angular.module('serviceApp').directive('trendContainer', () => ({
    restrict: 'E',
    scope: {
        team: '<',
        user: '<',
        definition: '=',
        eventHandler: '&'
    },
    template,
    controller: Controller
}));
