(function() {
    'use strict';

    angular.module('serviceApp').filter('durationInHours', durationInHours);

    durationInHours.$inject = ['_', 'moment', '$filter'];

    function durationInHours(_, $moment, $filter) {
        const filter = (milliseconds, formatAsNumber = true) => {
            if (_.isNil(milliseconds)) {
                return formatAsNumber ? 'N/A' : undefined;
            }

            const hours = _.round($moment.duration(milliseconds).asHours());
            let hoursAsNumber = `${$filter('number')(hours)} hour`;
            if (hours !== 1) {
                hoursAsNumber = $filter('pluralize')(hoursAsNumber);
            }

            return formatAsNumber ? hoursAsNumber : hours;
        };

        return filter;
    }
})();
