(function() {
    'use strict';

    angular.module('serviceApp').controller('BoardController', BoardController);

    BoardController.$inject = ['$rootScope', '_', '$state', '$scope', '$log'];

    function BoardController($rootScope, _, $state, $scope, $log) {
        // Get the shortcode for this board.
        const boardShortcode = _.get($state, 'params.board');

        try {
            const socket = $scope.socket;

            // Subscribe to notifications about the board.
            socket.emit('subscribe-to-board', boardShortcode);

            // Unsubscribe from notifications when the board is destroyed.
            $scope.$on('$destroy', function() {
                if (boardShortcode) {
                    socket.emit('unsubscribe-from-board', boardShortcode);
                }
            });
        } catch (e) {
            $log.error(e);
            $scope.error = _.get(e, 'data.message', e);
        }
    }
})();
