SchemaDataViewController.$inject = [
    '_',
    '$scope',
    '$rootScope',
    '$interpolate',
    '$filter',
    'translateValuesService'
];

function SchemaDataViewController(_, $scope, $rootScope, $interpolate, $filter, translateValuesService) {
    $scope.set = set;
    $scope.isArray = _.isArray;
    $scope.isObject = _.isObject;
    $scope.init = init;
    $scope.init();

    $scope.$watchCollection('values', function() {
        $scope.init();
    });

    function init() {
        $scope.asArray = $scope.isArray($scope.values);
        $scope.asObject = $scope.asArray ? false : $scope.isObject($scope.values);
        $scope.translatedValues = translateValuesService.translateValues({
            $scope,
            $filter,
            $interpolate,
            contentType: $scope.contentType,
            propertyDisplayOptions: $scope.propertyDisplayOptions,
            values: $scope.values,
            asArray: $scope.asArray,
            asObject: $scope.asObject,
            key: $scope.key,
            asRaw: $scope.viewUnformatted
        });
        $scope.arrayLimit = $rootScope.globalArrayLimit;
        $scope.globalArrayLimit = $rootScope.globalArrayLimit;
        $scope.schemaKey = $scope.key;
        $scope.defaultLabel = _.startCase($scope.key);
    }

    function set(obj, path, value) {
        if (_.isNull(value)) {
            $scope[obj] = path;
        } else {
            _.set($scope[obj], path, value);
        }
    }
}
export default SchemaDataViewController;
