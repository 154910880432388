import controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('contentDetails', contentDetails);

function contentDetails() {
    var directive = {
        restrict: 'E',
        scope: {
            board: '=?',
            panel: '<?',
            content: '=?',
            details: '=?',
            user: '=?',
            contentType: '=?',
            card: '=details',
            userTheme: '<?'
        },
        template,
        controller
    };

    return directive;
}
