'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('insightsError', {
    controller,
    template,
    bindings: {
        errorType: '<',
        title: '<',
        body: '<'
    }
});
