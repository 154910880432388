(function() {
    'use strict';

    angular.module('serviceApp').filter('durationInMinutes', durationInMinutes);

    durationInMinutes.$inject = ['_', 'moment', '$filter'];

    function durationInMinutes(_, $moment, $filter) {
        const filter = (milliseconds, formatAsNumber = true) => {
            if (_.isNil(milliseconds)) {
                return formatAsNumber ? 'N/A' : undefined;
            }

            const minutes = _.round($moment.duration(milliseconds).asMinutes());
            let minutesAsNumber = `${$filter('number')(minutes)} minute`;
            if (minutes !== 1) {
                minutesAsNumber = $filter('pluralize')(minutesAsNumber);
            }

            return formatAsNumber ? minutesAsNumber : minutes;
        };

        return filter;
    }
})();
