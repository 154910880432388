'use strict';

/**
 * @ngdoc function
 * @name serviceApp.controller:MobileHomeCtrl
 * @description
 * # MobileHomeCtrl
 */
angular.module('serviceApp').controller('MobileHomeCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    function($rootScope, $scope, $state) {
        if (!$rootScope.isMobile) {
            $state.go('core.login');
        }

        $rootScope.useHeader = false;
    }
]);
