(function() {
    'use strict';

    angular.module('serviceApp').directive('cardEnableIntegration', cardEnableIntegration);

    function cardEnableIntegration() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/integrations/enable.html'),
            controller: CardEnableIntegrationController
        };

        return directive;
    }

    CardEnableIntegrationController.$inject = ['_', '$scope', 'integrationService', 'toastr'];

    function CardEnableIntegrationController(_, $scope, integrationService, toastr) {
        $scope.integrationForm = {};
        $scope.integrationForm.title = _.clone($scope.board.title);
        $scope.integrationForm.settings = {};
        $scope.integrationForm.properties = {};
        $scope.hasAdvanced = false;

        if ($scope.card.properties.definition) {
            _.forEach($scope.card.properties.definition.teamSchema, function(value, key) {
                if (value.default) {
                    $scope.integrationForm.settings[key] = value.default;
                }
                //Check to see if it has an advanced option
                if (value.advanced && !_.get(value, 'display.hidden')) {
                    $scope.hasAdvanced = true;
                }
            });
        }

        if (!$scope.integrationForm.properties.pollingInterval) {
            $scope.integrationForm.properties.pollingInterval = _.get(
                $scope.card.properties.definition,
                'pollingInterval',
                300
            );
        }

        if (_.isNil($scope.integrationForm.properties.pollPrimaryOnly)) {
            $scope.integrationForm.properties.pollPrimaryOnly = _.get(
                $scope.card.properties.definition,
                'pollPrimaryOnly',
                false
            );
        }

        $scope.submitForm = function(isValid) {
            if (isValid) {
                integrationService
                    .addTeamIntegration(
                        $scope.integrationForm.title,
                        $scope.card.properties.integrationType,
                        $scope.board.appId,
                        $scope.integrationForm.settings,
                        $scope.integrationForm.properties
                    )
                    .then(integrationAPISuccess, integrationAPIFailure);
            } else {
                toastr.error('Invalid input, please fill fields correctly', '');
            }
        };

        $scope.camelCase = function(key) {
            return _.startCase(key);
        };

        function integrationAPISuccess() {
            toastr.success('Integration Added!', '');
            $scope.refreshCallbackHitch();
        }

        function integrationAPIFailure(error) {
            toastr.error('An error occurred', error.msg);
        }

        $scope.toggleHelpModal = function(helpText) {
            $scope.helpText = helpText || '';
            $scope.showHelpModal = !$scope.showHelpModal;
        };
    }
})();
