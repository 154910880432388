import { createContextBreakdown } from '../../../scripts/utils/form-utils.js';

(function() {
    'use strict';
    angular.module('serviceApp').directive('promptContainer', promptContainer);
    function promptContainer() {
        var directive = {
            restrict: 'E',
            scope: {
                team: '<',
                user: '<',
                definition: '=',
                eventHandler: '&',
                inputPrompt: '<prompt'
            },
            template: require('views/tmpl/partials/definitionEditors/promptContainer.html'),
            controller: PromptContainerController
        };

        return directive;
    }

    PromptContainerController.$inject = [
        '_',
        '$scope',
        '$log',
        'definitionService',
        'contentTypeService',
        '$filter'
    ];

    function PromptContainerController(_, $scope, $log, definitionService, contentTypeService, $filter) {
        $scope.initEventTrigger = initEventTrigger;
        $scope.buttonLabels = ['View', 'Add', 'Complete', 'Submit', 'Update', 'Go', 'Create', 'Send'];

        $scope.promptModel = _.get($scope, ['inputPrompt', 'propertyValues', 'prompt'], {});
        $scope.promptSchema = {
            type: 'object',
            properties: _.get($scope, ['inputPrompt', 'propertySchema', 'prompt'], {})
        };

        loadAvailableContext();
        $scope.formOptions = { formDefaults: { ngModelOptions: { definition: $scope.definition } } };

        $scope.promptTypes = { externalUrl: true, automation: false };
        $scope.promptTypeChanged = promptTypeChanged;

        $scope.initEventTrigger(_.get($scope, ['inputPrompt', 'eventTrigger']));

        function initEventTrigger(eventTrigger) {
            $scope.eventTriggerDefinition = eventTrigger;
            // Add the event trigger to the model options so we the form builder can load correct
            // content type data.
            _.set(
                $scope,
                'formOptions.formDefaults.ngModelOptions.integrationType',
                _.get(eventTrigger, 'eventTrigger.runner.integrationType')
            );
            const rootName = _.get($scope, 'eventTriggerDefinition.rootName');
            const name = _.get($scope, 'eventTriggerDefinition.name');
            if (rootName && rootName !== 'external-link') {
                $scope.promptTypes.externalUrl = false;
                $scope.promptTypes.automation = true;
            } else if (name !== 'external-link' && $scope.promptTypes.externalUrl) {
                definitionService
                    .findDefinitions(
                        {
                            teamId: ['##GLOBAL##'],
                            definitionType: 'eventTrigger',
                            name: 'external-link'
                        },
                        { resolveDefaults: true }
                    )
                    .then(function(definitions) {
                        $scope.initEventTrigger(_.first(definitions));
                    });
            }

            $scope.eventTriggerConfigurationSchema = {
                type: 'object',
                properties: _.get($scope, ['eventTriggerDefinition', 'propertySchema', 'configuration'], {})
            };

            $scope.eventTriggerConfigurationModel = _.get(
                $scope,
                ['eventTriggerDefinition', 'propertyValues', 'configuration'],
                {}
            );

            if (_.isNil($scope.eventTriggerDefinition.autoRun)) {
                $scope.eventTriggerDefinition.autoRun = [];
            }
        }

        $scope.$watch(
            'promptModel',
            (oldVal, newVal) => {
                if (_.isEqual(oldVal, newVal)) {
                    return;
                }

                const selectedTrigger = _.get($scope, 'promptModel.availableEventTriggers');
                if (!selectedTrigger) {
                    $scope.eventTriggerDefinition = undefined;
                    return;
                }
                definitionService
                    .findDefinitions(
                        {
                            teamId: ['##GLOBAL##'],
                            definitionType: 'eventTrigger',
                            name: selectedTrigger
                        },
                        { resolveDefaults: true }
                    )
                    .then(function(definitions) {
                        $scope.initEventTrigger(_.first(definitions));
                    });
            },
            true
        );

        $scope.$watch('definition.action.prompt', promptChanged, true);
        $scope.$watch('definition.tags', promptChanged, true);
        $scope.$watch('eventTriggerDefinition.autoRun', promptChanged, true);
        $scope.$watch('eventTriggerDefinition.runAsPrimaryUser', promptChanged, true);
        $scope.$watch('eventTriggerConfigurationModel', promptChanged, true);
        $scope.$watch('eventTriggerDefinition.eventTriggerPrompt', promptChanged);

        $scope.$on('updatedAvailableContext', function() {
            loadAvailableContext();
        });

        function loadAvailableContext() {
            const actionContentType = _.get($scope, 'definition.action.contentType');
            const availableActionContext = _.map(
                _.get($scope, 'definition.action.availableContext', []),
                item => {
                    if (_.isString(item)) {
                        item = { name: item };
                    }
                    item.extendedName = item.name;

                    item.templateEnabledProperty = true;

                    if (!item.label) {
                        // Add a default label based on the name.
                        // We'll collapse repeated strings in the name to avoid things like "meeting meeting attendee".
                        item.label = $filter('propertyLabel')(
                            _.last(_.split(item.name, '.')),
                            item.contentType
                        );
                        if (_.includes(item.name, '.')) {
                            let prefix = _.first(item.name.split('.'));
                            prefix = $filter('contentTypeLabel')(prefix, _.startCase(prefix));
                            item.label = prefix + ' ' + item.label;
                        }
                        item.label = item.label.replace(/\b(\w+)\s+\1\b/g, '$1');
                    }
                    return item;
                }
            );

            const actionGeneratorType = _.get($scope, 'definition.action.actionGenerator');
            if (actionGeneratorType) {
                $scope.isSetupAction = _.startsWith(actionGeneratorType, 'setup');
            }

            if (actionContentType) {
                contentTypeService
                    .getContentTypes({
                        contentType: _.camelCase(actionContentType),
                        includeEmbeddedSchema: true
                    })
                    .then(objectTypeData => {
                        const mappedProperties = generatePropertiesFromSchema(
                            actionContentType,
                            _.get(objectTypeData, 'schema')
                        );

                        const embeddedSchema = _.get(objectTypeData, 'embeddedSchema', null);
                        let dependencyProperties = [];
                        if (!_.isEmpty(embeddedSchema)) {
                            _.each(embeddedSchema, schema => {
                                let embeddedProperties = generatePropertiesFromSchema(
                                    actionContentType,
                                    schema
                                );
                                dependencyProperties = _.concat(dependencyProperties, embeddedProperties);
                            });
                        }
                        broadcastAvailableContext([
                            ...availableActionContext,
                            ...mappedProperties,
                            ...dependencyProperties
                        ]);
                    })
                    .catch($log.error);
            } else {
                broadcastAvailableContext(availableActionContext);
            }
        }

        /**
         * Generate an array of property items from schema.
         *
         * @param {String} mainContentType - Content Type name of the definition you are currently on
         * @param {Object} schema - The schema object of this content type
         * @returns {Array} An array of property items can be consumed by the form
         */
        function generatePropertiesFromSchema(mainContentType, schema) {
            return _.map(
                contentTypeService.getEnabledProperties(schema),
                ({ name, type, extendedName, contentType }) => {
                    const contextItem = {
                        extendedName: `content.${extendedName}`,
                        name: mainContentType === contentType ? `content.${name}` : `${name}`,
                        templateEnabledProperty: true,
                        type,
                        dataType: type,
                        contentType: contentType,
                        label: generateCorrectLabel(contentType, name)
                    };

                    // only non-date content properties can generate a picklist
                    if (type !== 'date' && _.split(name, '.').length > 1) {
                        contextItem.picklistEnabledProperty = true;
                    }
                    return contextItem;
                }
            );
        }

        /**
         * Generate the label for the property item.
         *
         * @param {String} contentType - Name of the content type
         * @param {String} name - Name of the property from the API response
         * @returns {String} The label that will be display in the dropdown
         */
        function generateCorrectLabel(contentType, name) {
            const propertyName = _.includes(name, `${contentType}.`)
                ? name.replace(`${contentType}.`, '')
                : null;

            // label default should be 'Content Type Name' + 'Property Name'
            let label =
                _.startCase(contentType) +
                ' ' +
                (propertyName
                    ? $filter('propertyLabel')(propertyName, contentType)
                    : _.startCase(_.last(_.split(name, '.'))));

            // handle duplicated Name such as Account Account Name, change it to Account Name
            label = label.replace(/\b(\w+)\s+\1\b/g, '$1');

            // in case we have custom label of a content-type replace the default content type name to custom name
            label = label.replace(
                _.startCase(contentType),
                $filter('contentTypeLabel')(contentType, _.startCase(contentType))
            );

            return label;
        }

        function promptChanged(newVal, oldVal) {
            if (_.isEqual(newVal, oldVal)) {
                return;
            }

            broadcastAvailableContext(undefined, true);
            $scope.eventHandler({
                event: '_promptChanged',
                data: {
                    promptValues: _.get($scope, 'promptModel', {}),
                    promptSchema: _.get($scope, 'promptSchema.properties'),
                    eventTrigger: {
                        autoRun: _.get($scope.eventTriggerDefinition, 'autoRun'),
                        runAsPrimaryUser: _.get($scope.eventTriggerDefinition, 'runAsPrimaryUser'),
                        runner: _.get($scope.eventTriggerDefinition, 'eventTrigger.runner'),
                        rootName: _.get($scope.eventTriggerDefinition, 'name'),
                        eventTriggerType: _.get(
                            $scope.eventTriggerDefinition,
                            'eventTrigger.eventTriggerType'
                        ),
                        eventTriggerPrompt: _.get($scope.eventTriggerDefinition, 'eventTriggerPrompt', 'Go'),
                        propertyValues: { configuration: $scope.eventTriggerConfigurationModel },
                        propertySchema: {
                            supplementalConfiguration: _.get(
                                $scope,
                                ['eventTriggerDefinition', 'propertySchema', 'supplementalConfiguration'],
                                {}
                            ),
                            configuration: _.omit(
                                _.get($scope, 'eventTriggerConfigurationSchema.properties', {}),
                                '_display'
                            )
                        }
                    },
                    action: _.get($scope, 'definition.action')
                }
            });
        }

        function promptTypeChanged(type) {
            const clonedState = _.cloneDeep($scope.promptTypes);
            const updatedValue = !!_.get($scope.promptTypes, type);
            $scope.promptTypes = _.mapValues($scope.promptTypes, (value, key) => {
                if (key === type) {
                    return value;
                } else {
                    return !updatedValue;
                }
            });

            if (!_.isEqual(clonedState, $scope.promptTypes)) {
                $scope.promptModel = {};
                $scope.initEventTrigger();
            }
        }

        function broadcastAvailableContext(availableContext, force) {
            $scope.availableContext = force ? $scope.availableContext : availableContext;
            $scope.contextBreakdown = {};
            $scope.availableContext = _($scope.availableContext)
                .uniqBy('name')
                .sortBy([({ name }) => _.split(name, '.').length, ({ name }) => name]) // sort by '.' complexity then by name
                .value();

            $scope.contextBreakdown = createContextBreakdown($scope.availableContext);

            const name = _.snakeCase(_.get($scope, 'definition.name'));
            $scope.$broadcast(`_${name}_available_context`, $scope.availableContext);
        }

        // below is related to quill editor
        // TODO: move all logic to ng-quill or a variable selector directive
        let quill;
        $scope.onEditorCreated = editor => {
            quill = editor;
        };

        $scope.onStringTemplateVar = data => {
            // Use the custom property label if one exists.
            // This takes strings like 'content.opportunity.name' and extracts the property name
            // and content type properly, while also handling 'externalId' correctly.
            const [property, filter] = data.split('|');
            const [propertyName, contentType] = property
                .split('.')
                .slice(-2)
                .map(_.trim)
                .reverse();
            let label = $filter('propertyLabel')(propertyName, contentType);
            if (contentType) {
                label = `${$filter('contentTypeLabel')(contentType, _.startCase(contentType))} ${label}`;
            }
            return {
                varName: _.trim(property),
                varLabel: label,
                varTransforms: [_.trim(filter)]
            };
        };

        $scope.insertVariable = data => {
            quill.insertVariable(data);
            $scope.hidePropsDrop();
        };

        $scope.toggleShowProps = () => {
            $scope.showPropsDrop = !$scope.showPropsDrop;
        };

        $scope.hidePropsDrop = () => {
            $scope.showPropsDrop = false;
        };

        $scope.startCase = item => {
            if (_.isObject(item)) {
                return _.startCase(_.replace(item.name, 'content.', ''));
            }

            return _.startCase(_.replace(item, 'content.', ''));
        };

        $scope.filteredQuery = (key, query) => {
            const valueToCheck = _.isString(key) ? key : key.label;
            return _.toLower(valueToCheck).includes(_.toLower(query)) || !query;
        };

        $scope.formatValue = item => {
            return _.startCase(_.last(_.split(item, '.')));
        };

        $scope.isObject = item => {
            return _.isObject(item);
        };
    }
})();
