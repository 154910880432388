'use strict';
import _ from 'lodash';

/**
 * Mobile instant is the container for the instant actions state view on mobile
 *
 * @export
 * @class Controller
 */

export default class Controller {
    static get $inject() {
        return ['_', '$scope', '$state', '$stateParams', '$rootScope'];
    }
    constructor(_, $scope, $state, $stateParams, $rootScope) {
        if (!$rootScope.isMobile) {
            $state.go('core.login');
        }

        this.scope = $scope;
        $scope.board = {
            id: _.get($stateParams, 'boardId')
        };

        $scope.content = {
            boardId: _.get($stateParams, 'boardId'),
            id: _.get($stateParams, 'contentId')
        };

        $scope.contentAction = {
            name: _.get($stateParams, 'name'),
            id: _.get($stateParams, 'id'),
            globalId: _.get($stateParams, 'globalId')
        };

        $scope.$root.$on('instantActionComplete', () => {
            $state.go('mobile.home');
        });
    }
}
