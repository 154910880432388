'use strict';
import _ from 'lodash';
import template from './index.html';
import './index.scss';
import moment from 'moment';

angular.module('serviceApp').directive('actionInspectionHandler', () => ({
    restrict: 'E',
    scope: {
        user: '<',
        content: '<',
        userTheme: '<?'
    },
    template,
    controller: class actionInspectionHandler {
        static get $inject() {
            return ['$scope', '$rootScope', '$timeout'];
        }

        constructor(scope, rootScope, timeout) {
            this.scope = scope;
            this.rootScope = rootScope;
            this.timeout = timeout;
            this.scope.openActionInspector = this.openActionInspector.bind(this);
            this.scope.showBadge = this.showBadge();
        }

        /**
         * whether or not to show the active action badge
         */
        showBadge() {
            const legacyActionsArray = _.get(this.scope, 'content.action', []);
            const activeActionsArray = _.get(this.scope, 'content.activeActions', []);

            let useLegacy = false;

            if (_.isEmpty(activeActionsArray)) {
                useLegacy = true;
            }

            // if legacy mode, show badge if legacy actions is not empty
            if (useLegacy) {
                return !_.isEmpty(legacyActionsArray);
            }

            // visible actions are non-expired and visibilty set to something the user can see
            const visibileActions = _.filter(activeActionsArray, activeActionItem => {
                // if the action item has an expirationDate after now, do not show it since it will be hidden by ActionSearch
                if (
                    activeActionItem.expirationDate &&
                    moment().isSameOrAfter(activeActionItem.expirationDate)
                ) {
                    return false;
                }

                // if this user does not match the visibility rules, don't consider this an active action
                if (activeActionItem.visibility) {
                    // only show to team admins
                    if (activeActionItem.visibility === 'hidden_from_team_members') {
                        return _.get(this.scope, 'user.isOwner');
                    }

                    // don't show to anyone
                    if (activeActionItem.visibility === 'hidden_from_team_admins') {
                        return false;
                    }
                }

                // else is visibility undefined or "show_to_all" which means anyone can see it
                return true;
            });

            // if there are any 'visible' actions show the badge
            return !_.isEmpty(visibileActions);
        }

        /**
         * openActionInspector - fire event to open or toggle the right drawer with the action inspector open
         */
        openActionInspector() {
            const contentId = _.get(this.scope, 'content.id');
            this.scope.$root.selectedActionInspectorId = contentId;
            let drawerClass = '';
            if (!this.scope.userTheme) {
                drawerClass = 'dark';
            } else {
                drawerClass = 'user-theme';
            }

            this.timeout(() => {
                this.scope.$emit('rightDrawer:open', {
                    layout: 'actionStatsDrawer',
                    userTheme: this.scope.userTheme,
                    drawerClass,
                    statsGraph: {
                        maintainAspect: true,
                        title: 'Actions',
                        interval: 'day',
                        range: '1W',
                        defaultFilter: {
                            '##NOT##': { instant: true },
                            '##OR##': {
                                'content.id': contentId,
                                assignees: {
                                    email: _.toLower(
                                        _.get(this.scope, 'content.contentUser.email', 'NO_MATCH')
                                    )
                                }
                            }
                        },
                        defaultState: 'Active'
                    }
                });
            });
        }
    }
}));
