'use strict';

import './index.scss';
import template from './index.html';
import controller from './controller';

angular.module('serviceApp').directive('boardTitle', () => {
    return {
        restrict: 'E',
        scope: {
            board: '<',
            user: '<',
            panel: '<',
            theme: '<',
            subtitle: '<'
        },
        template: template,
        controller
    };
});
