'use strict';

import ViewBase from '../common/ViewBase';

export default class PanelController extends ViewBase {
    static get $inject() {
        return [
            '_',
            '$scope',
            '$rootScope',
            '$stateParams',
            '$state',
            'cardsUtilService',
            'boardServiceNew',
            'user',
            'board',
            'panelCards',
            'query',
            'facets',
            'hierarchy',
            'displayOptions',
            'team',
            '$log',
            'eventHandler',
            '$timeout',
            'appSettingsService',
            'boardSliceService',
            'activeViewService',
            'filter',
            'socket',
            '$q'
        ];
    }
    constructor(
        _,
        $scope,
        $rootScope,
        $stateParams,
        $state,
        cardsUtilService,
        boardServiceNew,
        user,
        board,
        panelCards,
        query,
        facets,
        hierarchy,
        displayOptions,
        team,
        $log,
        EventHandler,
        $timeout,
        appSettingsService,
        boardSliceService,
        activeViewService,
        filter,
        socket,
        $q
    ) {
        super(
            _,
            $scope,
            $rootScope,
            $state,
            cardsUtilService,
            boardServiceNew,
            board,
            panelCards,
            $log,
            hierarchy,
            appSettingsService,
            boardSliceService,
            $q
        );
        $scope.filter = filter;
        $scope.user = user;
        $scope.team = team;
        $scope.cards = panelCards;
        $scope.board = board;
        $scope.targetId = _.get($stateParams, 'targetId');

        $scope.hierarchy = _.get($scope, 'board.definition.hierarchy.enabled') !== false ? hierarchy : {};
        $rootScope.board = board;
        $scope.group = _.get(board, 'state.currentGroup', 'main');
        $scope.panel = _.find(board.panels, {
            name: board.state.currentPanel
        });
        $scope.query = query;
        $scope.facets = facets;
        $scope.boardSliceService = boardSliceService;

        $scope.hasLayout = _.get(board, 'definition.propertySchema.layout');

        let isLoadedWatcher = () => {};
        let cardsStaleWatcher = () => {};
        let boardSliceWatcher = () => {};

        // If the root user object is updated, update the user object in our scope
        // that we pass down to all other components.
        $rootScope.$on('user:updated', () => {
            $scope.user = $rootScope.user;
        });

        // If the root team object is updated, update the team object in our scope
        // that we pass down to all other components.
        $rootScope.$on('team:updated', () => {
            $scope.team = $rootScope.team;
        });

        isLoadedWatcher = $scope.$watch('boardSliceService.isLoaded()', isLoaded => {
            if (!isLoaded) {
                return;
            }
            boardSliceWatcher = $scope.$watch('boardSliceService.board', () => {
                // When the stored board is updated, copy it to the board in scope so that
                // we visualize the updates properly.
                if (boardSliceService.board && !_.isEmpty(boardSliceService.board)) {
                    $scope.board = _.cloneDeep(boardSliceService.board);
                }
            });
            if (boardSliceService.isLoaded()) {
                // if we are navigating home, set an isHome flag on the board that will be checked by the nav bar component
                if (_.get($stateParams, 'isHome')) {
                    _.set(board, 'isHome', true);
                }
                boardSliceService.boardAdd(board);
                // TODO -- remove this if/when we come up with a better way to determine
                //         whether forecasting is enabled than by resolving board def.
                const boardDefinitionId = _.get(board, 'definition.id');
                if (boardDefinitionId) {
                    boardSliceService.resolveAvailableProperties(boardDefinitionId);
                }

                // If a filter definition was resolved (from localstorage or from a filter ID in the URL)
                // then add that definition to the store.
                if (!_.isEmpty($scope.filter)) {
                    boardSliceService.definitionAdd($scope.filter, true);
                }
                // Update the board in the scope to be a copy of the stored board, since that's what
                // we'll be visualizing and updating.
                $scope.board = _.cloneDeep(boardSliceService.board);
            }
        });

        cardsStaleWatcher = $scope.$watch('boardSliceService.cardsStale', () => {
            if (activeViewService.hasActiveBoardFilters() && boardSliceService.cardsStale) {
                $scope
                    .searchPanel(
                        $scope.query,
                        $scope.facets,
                        boardSliceService.boardDefinition,
                        $scope.hierarchy
                    )
                    .then(() => boardSliceService.clearCardsStale());
            }
        });

        // reload board after saving forecast from external drawer
        $scope.$root.$on('savedForecastData', event => {
            event.stopPropagation();
            $scope
                .searchPanel($scope.query, $scope.facets, boardSliceService.boardDefinition, $scope.hierarchy)
                .then(() => boardSliceService.clearCardsStale());
        });

        //On first render send timing event to DD RUM
        $timeout(function() {
            DD_RUM.addTiming('initial_render');
        });

        // the panelGroup can declare a reference to a filter.
        // eg from AccountActivity board: `filters: { default: 'root-account-activity' }`,
        // TODO trace down where this actually used (passed into `panel-search` and `cards-container`)
        // I _think_ this is basically used as a flag for "does this board support reports" ?
        $scope.filters = _.get($scope, `board.panelGroups["${_.get($scope, 'group')}"].filters`);

        // This is cross-cutting, so use root scope
        $rootScope.displayOptions = displayOptions;

        $scope.showContentRefreshIndicator = false;

        //TODO: when we move the mobile view panel to it's own route, it'll use mobilePanel.html and this will not be needed.
        if ($rootScope.isMobile) {
            $scope.isMobile = true;
        }

        $scope.cardNames = _.map($scope.cards, 'name');

        // Wire the cards on this panel to listen for socket events.
        // Since we don't have access to the card controllers directly here, we can't $scope.$emit on them,
        // so instead we'll create instances of the EventHandler class to broadcast events on the root scope
        // TODO -- replace global "socket" instance with something less global.
        let eventHandlers = [];
        if (socket) {
            _.forEach($scope.cards, function(card) {
                const socketEvents = _.get(card, 'properties.socketEvents');
                eventHandlers = _.concat(
                    eventHandlers,
                    _.map(socketEvents, (eventDef, eventType) =>
                        new EventHandler(socket, card, eventDef, eventType).startListening()
                    )
                );
            });
        }

        var cardsWatcher = $scope.$watch(
            'panelCards',
            function() {
                $scope.cards = panelCards;
                $scope.cardNames = _.map($scope.cards, 'name');
                $scope.formatURL();
                let lazyCards = [];

                //Check for lazy loading cards and not computed. In this case, these cards need to be retrieved separately.
                // Don't add cards that are in a panelTab nor drawerCard(details), they will be loaded separately.
                _.forEach($scope.cards, function(card) {
                    if (card.lazy && !card.computed && !card.panelTab && !card.drawerCard) {
                        card.loading = true;
                        lazyCards.push(card.name);
                    }
                });

                // For non layout boards, fetch any lazy cards separately
                if (!$scope.hasLayout) {
                    if (!_.isEmpty(lazyCards)) {
                        _.forEach(lazyCards, function(cardName) {
                            $scope.getCard({ cardName });
                        });
                    }
                }

                // Pick up the drawer data if present on the URL and open the drawer with
                // the payload context
                const rightDrawerData = _.get($stateParams, 'rightDrawerData');
                if (rightDrawerData) {
                    try {
                        const payload = JSON.parse(rightDrawerData);
                        $scope.$emit('rightDrawer:open', {
                            layout: 'boardView',
                            drawerPayload: payload,
                            user: $scope.user,
                            team: $scope.team,
                            hierarchy: $scope.hierachy,
                            drawerClass: _.get(payload, 'drawerClass')
                                ? _.get(payload, 'drawerClass')
                                : 'user-theme skinny'
                        });
                    } catch (exc) {
                        console.error('Invalid Drawer Payload');
                    }
                }
                // Inlcude the left drawer data if present.
                const leftDrawerData = _.get($stateParams, 'leftDrawerData');
                if (leftDrawerData) {
                    try {
                        const payload = JSON.parse(leftDrawerData);
                        $scope.$emit('leftDrawer:open', {
                            layout: 'boardView',
                            drawerPayload: payload,
                            user: $scope.user,
                            team: $scope.team,
                            hierarchy: $scope.hierachy,
                            drawerClass: _.get(payload, 'drawerClass')
                                ? _.get(payload, 'drawerClass')
                                : 'user-theme skinny'
                        });
                    } catch (exc) {
                        console.error('Invalid Drawer Payload');
                    }
                }
            },
            true
        );

        $scope.$on('$destroy', function() {
            // Stop watching cards.
            cardsWatcher();
            isLoadedWatcher();
            cardsStaleWatcher();

            //Stop Watching the board slice
            boardSliceWatcher();

            // Loop through the cards and turn off all listeners.
            _.each(eventHandlers, eventHandler => eventHandler.stopListening());
        });

        /**
         * Updates the current board+panel to reflect the given facets.
         *
         * This method updates the global $state, and the data is refreshed
         * based on that update.  This causes the equivalent of a full page
         * reload, with a loading spinner.
         *
         * @param data.facets Merge these facets into the scope before update
         * @param data.query Merge this query into the scope before update
         */
        $scope.getCards = function(data) {
            $scope.facets = _.merge($scope.facets, data.facets);
            $scope.query = _.merge($scope.query, data.query);

            const currentFilter = _.get($rootScope, 'currentFilter');
            const filterFragment = currentFilter && currentFilter.id ? `_${currentFilter.id}` : '';

            $state.transitionTo(
                $state.current.name,
                {
                    panel: _.get($scope.panel, 'name'),
                    board: _.get($scope.board, 'shortcode'),
                    title: _.get($scope.board, 'title'),
                    query: _.get($scope.query, 'str'),
                    hierarchy: !_.isEmpty($scope.hierarchy) ? JSON.stringify($scope.hierarchy) : undefined,
                    facets: !_.isEmpty($scope.facets) ? JSON.stringify($scope.facets) : undefined,
                    filter: filterFragment,
                    targetId: $scope.targetId,
                    destinationTime: _.get($scope.query, 'destinationTime'),
                    advanced: _.get($scope.query, 'advancedQuery', ''),
                    overwriteReload: _.get(data, 'overwriteReload', false)
                },
                {
                    location: _.get(data, 'location', 'replace'),
                    notify: _.get(data, 'notify', true),
                    reload: _.get(data, 'reload', true)
                }
            );
        };

        /**
         * If save button in panel header is clicked, broadcast an event
         */
        $scope.onSave = function() {
            $scope.$broadcast('panel:save');
        };
    }
}
