'use strict';
import template from './index.html';

angular.module('serviceApp').directive('detailsPanel', () => ({
    restrict: 'E',
    scope: {
        board: '<',
        contentType: '<',
        details: '<'
    },
    template: template
}));
