import template from './index.html';
import './index.scss';
import Controller from './controller';

angular.module('serviceApp').directive('iconViewer', () => ({
    restrict: 'E',
    scope: {
        iconType: '<?', //Icon type that can be 'URL','internal', 'initials' or 'fontAwesome'
        icon: '<?', //The base icon object
        defaultIcon: '<?', //The fall over icon if icon is not found
        defaultIconType: '<?', // the type of the fall over icon
        displayTitle: '<?', //If using icon type of initials this is the data title to be used
        height: '<', //Height of the icon
        width: '<' //Width of the icon
    },
    template,
    controller: Controller
}));
