'use strict';

import template from './index.html';
import Controller from './controller';

/**
 * This directive consumes one or more metric data sets, and formatting options
 * for those data sets and renders them into the DOM.
 *
 * @param {Object} scope.metrics.metrics Each key is a named data set, containing an array of options.
 * @param {Object} scope.metrics.display An array of display options, mapping to the data sets above.
 * @param {Object} scope.selector Pass to _.find() over the above metrics
 */
angular.module('serviceApp').directive('metricsDisplay', () => {
    return {
        restrict: 'E',
        scope: {
            header: '@',
            metrics: '<',
            selector: '<'
        },
        template: template,
        controller: Controller
    };
});
