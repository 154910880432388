'use strict';

/**
 * Angular wrapper for our <BenchmarkLinesConfig /> react component from the vizboi-module.
 */
export default class BenchmarkLinesConfig {
    constructor() {}

    $onInit() {
        // Props being passed to our react component
        this.data = {
            onChange: this.onChange(),
            trendsCalculations: this.trendsCalculations,
            value: this.value,
            withCalculations: this.withCalculations,
            showTitle: false
        };
    }
}
