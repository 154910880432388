import template from './index.html';
import controller from './controller';

angular.module('serviceApp').directive('cardExternalComponent', () => {
    return {
        restrict: 'E',
        scope: {
            card: '<',
            board: '<',
            user: '<',
            team: '<',
            data: '<',
            facets: '<',
            getCardHitch: '&',
            getCardsHitch: '&'
        },
        template,
        controller
    };
});
