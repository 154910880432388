'use strict';

/**
 * Angular wrapper for our <ContextMenu /> react component.
 */

export default class ContextMenu {
    static get $inject() {
        return ['$scope'];
    }
    constructor($scope) {
        this.scope = $scope;
        this.onClose = () => {
            this.onMenuClose();
        };
    }

    $onInit() {
        this.data = {
            anchorEl: this.anchorEl,
            menuOptions: this.menuOptions
        };
        this.hooks = {
            onClose: this.onClose
        };
    }
}
