(function() {
    'use strict';

    angular.module('serviceApp').directive('cardAppDetails', cardAppDetails);

    function cardAppDetails() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                user: '=',
                refreshCallbackHitch: '&',
                refreshBoardCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/settings/app.html'),
            controller: CardAppDetailsController
        };

        return directive;
    }

    CardAppDetailsController.$inject = [
        '_',
        '$templateCache',
        '$scope',
        '$rootScope',
        '$log',
        'toastr',
        'contentTypeService'
    ];

    function CardAppDetailsController(
        _,
        $templateCache,
        $scope,
        $rootScope,
        $log,
        toastr,
        contentTypeService
    ) {
        $scope.forms = {};
        $rootScope.appDirty = false;
        $scope.productName = $rootScope.productName;

        $scope.showAdvanced = false;
        $scope.isCancel = true;
        $scope.query = '';

        $scope.cardBackup = _.cloneDeep($scope.card);

        $scope.showConfirm = function() {
            $scope.confirmResetModal = true;
        };

        $scope.hideConfirm = function() {
            $scope.confirmResetModal = false;
        };

        // Re-order policies to make the "default" one (if any) always at the bottom.
        const defaultPolicies = _.remove(
            $scope.card.properties.app.contentPolicies,
            policy => _.isEmpty(policy.urlPattern) && _.isEmpty(policy.contentType)
        );
        if (defaultPolicies) {
            $scope.card.properties.app.contentPolicies.push(...defaultPolicies);
        }

        $scope.$watchCollection('card.properties.app.contentPolicies', function() {
            if ($scope.forms.applicationDetails && !$scope.isCancel) {
                $scope.forms.applicationDetails.$setDirty();
            }
            $scope.isCancel = false;
        });

        // Add correct `show` and `isDefaultPolicy` settings to each policy.
        $scope.card.properties.app.contentPolicies = _.forEach(
            $scope.card.properties.app.contentPolicies,
            function(p) {
                _.set(p, 'show', false);
                return p;
            }
        );

        $scope.openAdvanced = function() {
            $scope.index = undefined;
            $scope.$root.$emit('rightDrawer:open', {
                layout: 'appAdvancedSettings',
                card: $scope.card,
                board: $scope.board,
                user: $scope.user,
                refreshBoardCallbackHitch: $scope.refreshBoardCallbackHitch
            });
        };

        $scope.togglePolicyEditor = function(policy, isNew) {
            if (!$rootScope.rightDrawerOpen) {
                $scope.$root.$emit('rightDrawer:open', {
                    layout: 'appPolicyEditor',
                    policy: policy,
                    integration: $scope.card.properties.integration,
                    app: $scope.card.properties.app,
                    isNew: isNew,
                    index: $scope.index,
                    refreshBoardCallbackHitch: $scope.refreshBoardCallbackHitch
                });
            }
        };

        $scope.closeDrawer = function() {
            $scope.$root.$emit('rightDrawer:close');
        };

        let rightDrawerCloseListener = $scope.$root.$on('rightDrawer:close', function() {
            $scope.index = undefined;
        });

        let policyUndoListener = $scope.$root.$on('policyUndoChanges', function() {
            $scope.cancel();
        });

        //clean up the listener
        $scope.$on('$destroy', function() {
            rightDrawerCloseListener();
            policyUndoListener();
        });

        $scope.newContentPolicy = function() {
            $scope.card.properties.app.contentPolicies.unshift({
                title: 'New Policy ' + new Date(),
                requiredContentActivity: 1,
                requiredUniqueUsers: 2,
                indexLevel: $scope.card.properties.app.events
            });
            $scope.policy = _.first($scope.card.properties.app.contentPolicies);
            $scope.setIndex(0);
            $scope.togglePolicyEditor($scope.policy, true);
            $rootScope.appDirty = true;
        };

        // Get the human-readable version of the content type name.
        $scope.getContentTypeDisplayName = function(name) {
            return contentTypeService.getContentTypePrettyName(name);
        };

        $scope.cancel = function() {
            $scope.isCancel = true;
            $scope.card = _.cloneDeep($scope.cardBackup);
            $rootScope.appDirty = false;
            $rootScope.$broadcast('undoChanges');
        };

        $scope.setIndex = function(index, isLast) {
            if (!$rootScope.rightDrawerOpen) {
                $scope.index = index;
                $scope.isLast = isLast ? true : false;
            }
        };

        $scope.isActive = function(index) {
            return $scope.index && $scope.index === index;
        };

        $scope.selectObjectCallback = function(policy) {
            $scope.policy = policy;
        };

        $scope.search = function(object) {
            if (_.includes(object.title, $scope.query) || _.includes(object.contentType, $scope.query)) {
                return true;
            }
            return false;
        };
    }
})();
