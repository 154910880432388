'use strict';

/**
 * @ngdoc directive
 * @name serviceApp.directive:anchorScroll
 * @description
 * # anchorScroll
 */
angular.module('serviceApp').directive('anchorScroll', [
    '$location',
    '$anchorScroll',
    function($location, $anchorScroll) {
        return {
            restrict: 'AC',
            link: function(scope, el, attr) {
                el.on('click', function(e) {
                    $location.hash(attr.anchorScroll);
                    $anchorScroll();
                });
            }
        };
    }
]);
