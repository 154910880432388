(function() {
    'use strict';
    angular.module('serviceApp').factory('i18nService', I18nService);

    I18nService.$inject = ['_', '$resource', '$q'];

    function I18nService(_, $resource, $q) {
        const service = { getSupportedISOCurrencies, getCurrencySymbol };

        return service;

        function getSupportedISOCurrencies() {
            return [
                { displayLabel: 'United States Dollar (USD)', value: 'USD' },
                { displayLabel: 'Euro (EUR)', value: 'EUR' },
                { displayLabel: 'Japanese Yen (JPY)', value: 'JPY' },
                { displayLabel: 'British Pound Sterling (GBP)', value: 'GBP' },
                { displayLabel: 'Australian Dollar (AUD)', value: 'AUD' },
                { displayLabel: 'Canadian Dollar (CAD)', value: 'CAD' },
                { displayLabel: 'Swiss Franc (CHF)', value: 'CHF' },
                { displayLabel: 'Hong Kong Dollar (HKD)', value: 'HKD' },
                { displayLabel: 'Chinese Yuan Renminbi (CNH)', value: 'CNH' },
                { displayLabel: 'New Zealand Dollar (NZD)', value: 'NZD' },
                { displayLabel: 'South African Rand (ZAR)', value: 'ZAR' },
                { displayLabel: 'Nigerian Naira (NGN)', value: 'NGN' },
                { displayLabel: 'Botswana Pula (BWP)', value: 'BWP' },
                { displayLabel: 'Kenyan Shilling (KES)', value: 'KES' },
                { displayLabel: 'Mauritian Rupee (MUR)', value: 'MUR' }
            ];
        }

        function getCurrencySymbol(isoCurrency) {
            const currencyMappings = {
                USD: '$',
                EUR: '€',
                JPY: '¥',
                GBP: '£',
                AUD: 'A$',
                CAD: 'CA$',
                CHF: 'CHF',
                HKD: 'HK$',
                CNH: 'CNH',
                NZD: 'NZ$',
                ZAR: 'ZAR',
                NGN: 'NGN',
                BWP: 'BWP',
                KES: 'KES',
                MUR: 'MUR'
            };
            return _.get(currencyMappings, isoCurrency);
        }
    }
})();
