(function() {
    'use strict';

    angular
        .module('sfInput', ['schemaForm'])
        .config([
            '_',
            'schemaFormProvider',
            'schemaFormDecoratorsProvider',
            'sfBuilderProvider',
            'sfPathProvider',
            function(_, schemaFormProvider, schemaFormDecoratorsProvider, sfBuilderProvider, sfPathProvider) {
                var sfInput = function(name, schema, options) {
                    if (_.isObject(schema.format) && schema.format.type == 'smart-input') {
                        var f = schemaFormProvider.stdFormObj(name, schema, options);
                        f.key = options.path;
                        f.type = 'smart-input';
                        options.lookup[sfPathProvider.stringify(options.path)] = f;
                        return f;
                    }
                };

                if (_.isEmpty(schemaFormProvider.defaults.date)) {
                    schemaFormProvider.defaults.date = [];
                }

                schemaFormProvider.defaults.boolean.unshift(sfInput);
                schemaFormProvider.defaults.date.unshift(sfInput);
                schemaFormProvider.defaults.number.unshift(sfInput);
                schemaFormProvider.defaults.string.unshift(sfInput);
                schemaFormProvider.defaults.array.unshift(sfInput);

                schemaFormDecoratorsProvider.defineAddOn(
                    'bootstrapDecorator',
                    'smart-input',
                    'views/tmpl/templates/sfInput.html',
                    sfBuilderProvider.stdBuilders
                );
            }
        ])
        .run([
            '$templateCache',
            function($templateCache) {
                $templateCache.put(
                    'views/tmpl/templates/sfInput.html',
                    require('views/tmpl/templates/sfInput.html')
                );
            }
        ]);
})();
