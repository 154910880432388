'use strict';

/**
 * @ngdoc directive
 * @name serviceApp.directive:jsonText
 * @description
 * # Json object to editable text
 */
angular.module('serviceApp').directive('jsonText', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$parsers.push(fromUser);
            ngModelCtrl.$formatters.push(toUser);

            element.bind('blur', function() {
                element.val(toUser(scope.$eval(attrs.ngModel)));
            });

            scope.$watch(
                attrs.ngModel,
                function(newValue, oldValue) {
                    if (newValue && newValue !== oldValue) {
                        ngModelCtrl.$setViewValue(toUser(newValue));
                        ngModelCtrl.$render();
                    }
                },
                true
            );

            function fromUser(text) {
                if (!text || text.trim() === '') {
                    // If the input is empty let it be and just set the form to invalid
                    ngModelCtrl.$setValidity('invalidJson', false);
                } else {
                    try {
                        const validValue = angular.fromJson(text);
                        if (!_.isObject(validValue)) {
                            throw 'Invalid Syntax';
                        }
                        ngModelCtrl.$setValidity('invalidJson', true);
                        // If the value is valid return that value
                        return validValue;
                    } catch (e) {
                        // Even if text is invalid, don't flush the value as it may still be being worked on
                        // Just set the form as invalid.
                        ngModelCtrl.$setValidity('invalidJson', false);
                    }
                }
            }

            function toUser(input) {
                let object = input;
                // Need to parse to format JSON object correctly to display to user
                if (typeof input === 'string' || input instanceof String) {
                    try {
                        object = JSON.parse(input);
                    } catch (e) {
                        // Catching error thrown from double parsing a string
                    }
                }
                return angular.toJson(object, true);
            }
        }
    };
});
