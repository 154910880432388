'use strict';
import './index.scss';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('definitionRowItem', () => ({
    restrict: 'E',
    scope: {
        definition: '=',
        api: '='
    },
    template,
    controller: Controller
}));
