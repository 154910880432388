'use strict';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('templateStringBuilder', () => ({
    restrict: 'E',
    scope: {
        contextBreakdown: '<',
        inputType: '=',
        varDataType: '=',
        model: '=ngModel'
    },
    template,
    controller: Controller
}));
