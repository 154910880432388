import template from './index.html';
import './index.scss';
import Controller from './controller';

angular.module('serviceApp').directive('insightsInspection', () => ({
    restrict: 'E',
    scope: {
        board: '<',
        card: '<',
        panel: '<',
        cardDefinition: '<',
        user: '<',
        team: '<',
        facets: '<',
        definition: '<?',
        api: '<',
        addToDashboardCallback: '&?',
        renderCardCallback: '&?',
        buildDownloadUrlCallback: '&?',
        hierarchy: '<?',
        userTheme: '<'
    },
    template,
    controller: Controller
}));
