(function() {
    'use strict';

    angular
        .module('sfFormBoolean', ['schemaForm'])
        .config([
            '_',
            'schemaFormProvider',
            'schemaFormDecoratorsProvider',
            'sfBuilderProvider',
            'sfPathProvider',
            function(_, schemaFormProvider, schemaFormDecoratorsProvider, sfBuilderProvider, sfPathProvider) {
                var sfFormBoolean = (name, schema, options) => {
                    if (
                        schema.type === 'boolean' &&
                        _.isObject(schema.format) &&
                        schema.format.type == 'boolean'
                    ) {
                        var f = schemaFormProvider.stdFormObj(name, schema, options);
                        f.key = options.path;
                        if (_.get(f, 'schema.enum')) {
                            f.type = 'multi-select';
                        } else {
                            f.type = 'form-boolean';
                        }

                        options.lookup[sfPathProvider.stringify(options.path)] = f;
                        return f;
                    }
                };

                if (!schemaFormProvider.defaults.boolean) {
                    schemaFormProvider.defaults.boolean = [];
                }

                schemaFormProvider.defaults.boolean.unshift(sfFormBoolean);
                schemaFormDecoratorsProvider.defineAddOn(
                    'bootstrapDecorator',
                    'form-boolean',
                    'views/tmpl/templates/sfFormBoolean.html',
                    sfBuilderProvider.stdBuilders
                );
            }
        ])
        .run([
            '$templateCache',
            function($templateCache) {
                $templateCache.put(
                    'views/tmpl/templates/sfFormBoolean.html',
                    require('views/tmpl/templates/sfFormBoolean.html')
                );
            }
        ]);
})();
