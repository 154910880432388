(function() {
    'use strict';

    angular.module('serviceApp').directive('cardPillFacets', cardPillFacets);

    function cardPillFacets() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                panel: '=',
                external: '=?',
                user: '<',
                team: '<',
                facets: '=?',
                query: '=?',
                resizeCallbackHitch: '&',
                popoutCallbackHitch: '&',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/pillFacets.html'),
            controller: CardPillFacetsController
        };

        return directive;
    }

    CardPillFacetsController.$inject = ['_', '$scope', 'cardsUtilService'];

    function CardPillFacetsController(_, $scope, cardsUtilService) {
        $scope.applyFilter = applyFilter;
        $scope.startCase = startCase;
        $scope.facetFilter = _.get($scope, 'facets.cards.content.type');

        if ($scope.facetFilter) {
            $scope.$root.$broadcast($scope.board.shortcode + '-filter-content', $scope.facetFilter);
        }

        $scope.getPillColor = (idx, label) => ($scope.indicatorSelected(label) ? (idx % 10) + 1 : '');

        function applyFilter(filter) {
            $scope.$root.$broadcast(
                $scope.board.shortcode + '-filter-content',
                $scope.facetFilter === filter ? '' : filter
            );
            $scope.facetFilter = $scope.facetFilter === filter ? '' : filter;
        }

        $scope.indicatorSelected = function(label) {
            return $scope.facetFilter === label;
        };

        function startCase(input) {
            return _.startCase(input);
        }
    }
})();
