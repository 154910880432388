'use strict';

import template from './index.html';
import controller from './controller';

angular.module('serviceApp').component('filterGroupEditor', {
    template,
    controller,
    bindings: {
        section: '<',
        onlyWithHistory: '<',
        onDefinitionUpdated: '&',
        definition: '<',
        ownerName: '<'
    }
});
