angular.module('serviceApp').directive('cardActivity', function() {
    return {
        restrict: 'E',
        scope: {
            card: '=',
            board: '=',
            panel: '=',
            external: '=?',
            resizeCallbackHitch: '&',
            popoutCallbackHitch: '&'
        },
        template: require('views/tmpl/cards/activity/activity.html'),
        controller: [
            '_',
            '$scope',
            '$timeout',
            function(_, $scope, $timeout) {
                $scope.activityLabels = [];
                $scope.activityData = [[]];

                $scope.options = {
                    scaleLabel: function(label) {
                        return label.value.toString() + ' min';
                    },
                    tooltipTemplate: '<%= value %> min',
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }
                        ]
                    },
                    responsive: true
                };

                $timeout(function() {
                    if ($scope.card.properties.activity) {
                        _.forEach($scope.card.properties.activity, function(intervalData) {
                            $scope.activityLabels.push(intervalData.label);
                            $scope.activityData[0].push(intervalData.eventCount);
                        });
                    }
                }, 200);

                $scope.popout = function() {
                    var data = {
                        board: $scope.board.shortcode,
                        panelGroup: $scope.board.state.currentGroup || 'main',
                        panel: $scope.panel.name,
                        card: $scope.card.name
                    };
                    $scope.popoutCallbackHitch({
                        data: data
                    });
                };
            }
        ]
    };
});
