'use strict';

import './index.scss';
import template from './index.html';

angular.module('serviceApp').directive('cardInsightsLoadingTemplate', () => {
    return {
        scope: {
            size: '<?',
            includeHierarchy: '<?'
        },
        template: template
    };
});
