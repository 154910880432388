(function() {
    'use strict';

    angular.module('serviceApp').filter('durationInSeconds', durationInSeconds);

    durationInSeconds.$inject = ['_', 'moment', '$filter'];

    function durationInSeconds(_, $moment, $filter) {
        const filter = (milliseconds, formatAsNumber = true) => {
            if (_.isNil(milliseconds)) {
                return formatAsNumber ? 'N/A' : undefined;
            }

            const seconds = _.round($moment.duration(milliseconds).asSeconds());
            let secondsAsNumber = `${$filter('number')(seconds)} second`;
            if (seconds !== 1) {
                secondsAsNumber = $filter('pluralize')(secondsAsNumber);
            }
            return formatAsNumber ? secondsAsNumber : seconds;
        };

        return filter;
    }
})();
