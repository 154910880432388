(function() {
    'use strict';
    angular.module('serviceApp').directive('addTeamMember', addTeamMember);
    function addTeamMember() {
        var directive = {
            restrict: 'E',
            scope: { userData: '=' },
            template: require('views/tmpl/partials/addTeamMember.html'),
            controller: AddTeamMemberController
        };

        return directive;
    }

    AddTeamMemberController.$inject = ['_', '$scope', 'toastr', 'teamServiceNew'];

    function AddTeamMemberController(_, $scope, toastr, teamServiceNew) {
        $scope.newuser = { emailAlias: [] };
        $scope.headerDirty = false;
        $scope.formDirty = false;
        $scope.user = _.get($scope.userData, 'user');
        $scope.api = _.get($scope.userData, 'api');
        $scope.team = _.get($scope.userData, 'team');
        $scope.teamHomeBoard = _.get($scope, 'team.currentHomeBoard');

        $scope.closeEditor = function() {
            $scope.$root.$emit('rightDrawer:close');
        };

        $scope.addNewUser = function(form) {
            if ($scope.currentBoardType) {
                _.set($scope.newuser, 'homePage', $scope.currentBoardType);
            }
            teamServiceNew.addTeamMember($scope.team.id, $scope.newuser).then(
                function(member) {
                    form.$setPristine();
                    form.$setUntouched();
                    $scope.api.refreshTeam();
                    $scope.closeEditor();
                    toastr.success('User successfully added!', '');
                },
                function(err) {
                    toastr.error('Adding member failed: ' + _.get(err, ['data', 'error'], 'failed'), '');
                }
            );
        };
    }
})();
