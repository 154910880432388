'use strict';

import template from './index.html';
import controller from './controller';

angular.module('serviceApp').component('cardFilterPills', {
    template,
    controller,
    bindings: {
        isExpanded: '<',
        showEmpty: '<?',
        card: '<'
    }
});
