'use strict';

import themes from '@olono/shared-ui-assets/themes.json';
import colors from '@olono/shared-ui-assets/colors.json';

import CardBase from '../common/CardBase';

export default class Controller extends CardBase {
    static get $inject() {
        return ['$scope', '$rootScope'];
    }
    constructor($scope, $rootScope) {
        super($scope, $rootScope);
        $scope.headlineColor = () => {
            const colorNum = _.get(this.scope.card, 'properties.content.color', 5);
            const theme = _.get(themes, _.get(this.scope.card, 'properties.content.theme'));
            const colorPalette = _.values(_.get(theme, 'colorPalette'), []);
            return _.get(colorPalette, [colorNum - 1], colors['teal-400']);
        };
    }
}
