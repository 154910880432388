'use strict';
import template from './index.html';
import controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('contextMenuButton', () => ({
    restrict: 'E',
    scope: {
        menuOptions: '=?',
        asyncMenuOptions: '=?',
        contextMenuClass: '<?'
    },
    template,
    controller
}));
