(function() {
    'use strict';

    angular.module('serviceApp').filter('truncateString', truncateString);

    truncateString.$inject = ['_'];

    function truncateString(_) {
        const MAXLENGTH = 300;
        let filter = (str, max) => {
            if (_.isNil(str) || !_.isString(str)) {
                return str;
            }
            return _.truncate(str, {
                length: max ? max : MAXLENGTH,
                separator: ' '
            });
        };
        return filter;
    }
})();
