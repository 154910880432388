import parsePhoneNumber from 'libphonenumber-js';

(function() {
    'use strict';

    angular.module('serviceApp').filter('phoneNumber', phoneNumber);

    phoneNumber.$inject = ['_', '$filter'];

    function phoneNumber(_, $filter) {
        var filter = function(n) {
            //Check if value is undefined or nil, but not zero.
            if (!n || _.isNil(n)) {
                return 'N/A';
            }

            //Convert value to number, may be Integer or String.
            let stringValue = _.toString(n);

            //Put the string into a proper E.164 if possible. Ignore numbers that start with (area code) and dot notated.
            stringValue =
                _.startsWith(stringValue, '+') ||
                _.startsWith(stringValue, '(') ||
                _.includes(stringValue, '.')
                    ? stringValue
                    : '+' + stringValue;

            let phoneNumber;
            try {
                phoneNumber = parsePhoneNumber(stringValue, 'US');
                const national = phoneNumber.formatNational();
                const international = phoneNumber.formatInternational();
                const country = phoneNumber.country;
                if (country) {
                    return `${country}: ${national} (${international})`;
                } else {
                    //If country is undefined, this is a bad number
                    return n;
                }
            } catch (error) {
                //Failed to parse the number, return the raw value.
                return n;
            }
        };

        return filter;
    }
})();
