'use strict';

/**
 * Angular wrapper for our <CardFacetMenu /> react component.
 */

export default class CardFacetMenu {
    constructor() {}

    $onInit() {
        const selectedValueChanged = data => {
            this.selectedValue({ data });
        };

        const openEditor = data => {
            this.openEdit({ data });
        };
        // Props being passed to our react component
        this.data = {
            cardTimeFrameFilter: {
                property: this.property
            },
            selectedValue: selectedValueChanged,
            openEdit: openEditor,
            card: this.card
        };
        this.hooks = {
            getSelectedContextBreakdown: this.getSelectedContextBreakdown
        };
    }

    $onChanges() {
        const selectedValueChanged = data => {
            this.selectedValue({ data });
        };

        const openEditor = data => {
            this.openEdit({ data });
        };
        this.data = {
            cardTimeFrameFilter: {
                property: this.property
            },
            selectedValue: selectedValueChanged,
            openEdit: openEditor,
            card: this.card
        };

        this.hooks = {
            getSelectedContextBreakdown: this.getSelectedContextBreakdown
        };
    }
}
