'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('goalIntervalSelector', {
    controller,
    template,
    bindings: {
        interval: '<',
        availableIntervals: '<',
        numberOfIntervals: '<',
        offset: '<',
        period: '<?',
        onIntervalUpdated: '&'
    }
});
