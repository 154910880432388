'use strict';

import './index.scss';
import template from './index.html';
import controller from './controller';

angular.module('serviceApp').directive('cardInsightsHeadline', () => {
    return {
        scope: {
            board: '<',
            panel: '<',
            card: '<',
            user: '<',
            facets: '=',
            query: '=',
            filterData: '<',
            openDetailsHitch: '&',
            getCardHitch: '&',
            buildDownloadUrlHitch: '&',
            hierarchy: '<'
        },
        template: template,
        controller: controller
    };
});
