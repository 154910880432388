'use strict';
import _ from 'lodash';

import Controller from './controller';
import template from './index.html';
import './index.scss';

angular
    .module('serviceApp')
    .directive('actionItemAvatar', () => ({
        restrict: 'E',
        scope: {
            dark: '=?', // if rendered on a dark background set to true
            assignees: '=?'
        },
        template,
        controller: Controller
    }))
    .filter('actionItemAvatarLetter', () => Controller.getInitials)
    .filter('actionItemAvatarToolTip', () => Controller.actionItemAvatarToolTip);
