const short = require('short-uuid');

(function() {
    'use strict';
    angular.module('serviceApp').factory('eventHandler', EventHandler);

    EventHandler.$inject = ['_', '$rootScope'];

    const defaultEventOptions = {
        throttle: 9000
    };

    function EventHandler(_, $rootScope) {
        class _EventHandler {
            constructor(socket, card, eventDef, eventType) {
                if (!socket) {
                    console.warn(
                        'No socket passed to EventHandler creator; will not be able to listen and respond to socket events.'
                    );
                }
                // The Websocket to bind a listener to.
                this.socket = socket;
                // The data from one or more incoming Websocket refresh events.
                this.eventData = [];
                // The card that this handler instance is for.
                this.card = card;
                // The event type we're binding.
                this.eventType = eventType;
                // Options for how to handle this event.
                this.options = {};
                // Handle event def being just the event name.
                if (_.isString(eventDef)) {
                    this.eventName = eventDef;
                } else {
                    // Handle event def being an object with name and options.
                    this.eventName = eventDef.name;
                    this.options = eventDef.options;
                }
                // Set the option defaults.
                _.defaults(this.options, defaultEventOptions);

                // Add a throttled function for actually sending the event.
                this.options.throttle = this.options.throttle || 0;
                this.broadcastEventToCard = _.throttle(this._broadcastEventToCard, this.options.throttle, {
                    leading: true,
                    trailing: true
                });

                // Create a randomized event name that this card can listen for to handle this event type.
                // This prevents us from broadcasting the same Angular event for each card that handles the same
                // socket event, while allowing each card to have different event handling options (like throttle).
                // TODO -- if we could get access to the card's $scope and $emit directly to it, we wouldn't need this.
                this.card.socketEventNames = this.card.socketEventNames || {};
                this.card.socketEventNames[eventType] = `${eventType}-${short.generate()}`;

                this.listenerFunc = this._listener.bind(this);
            }
            startListening() {
                if (!this.socket) {
                    console.warn(
                        'No socket present in EventHandler instance; ignoring `startListening()` call.'
                    );
                    return;
                }
                this.socket.on(this.eventName, this.listenerFunc);
                return this;
            }
            stopListening() {
                if (!this.socket) {
                    console.warn(
                        'No socket present in EventHandler instance; ignoring `stopListening()` call.'
                    );
                    return;
                }
                this.socket.off(this.eventName, this.listenerFunc);
                return this;
            }
            _listener(data) {
                // When the Websocket event comes in, save its data and call `refreshCard`, which is throttled.
                this.eventData.push(data);
                this.broadcastEventToCard();
            }
            _broadcastEventToCard() {
                $rootScope.$broadcast(this.card.socketEventNames[this.eventType], {
                    card: this.card,
                    eventData: this.eventData
                });
                this.eventData = [];
            }
        }

        return _EventHandler;
    }
})();
