'use strict';
import Controller from './controller';
import template from './index.html';
//import './index.scss';

angular.module('serviceApp').directive('actionDefinitionStats', () => ({
    restrict: 'E',
    scope: {
        definition: '='
    },
    template,
    controller: Controller
}));
