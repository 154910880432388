'use strict';

import _ from 'lodash';
import themes from '@olono/shared-ui-assets/themes.json';

export default class DefinitionRowItem {
    static get $inject() {
        return ['$scope', '$rootScope', 'definitionService', 'toastr'];
    }
    constructor(scope, rootScope, definitionService, toastr) {
        // watch for the selected pill items to change
        let featureWatcher = scope.$watch(
            '$root.featuredProperties',
            function() {
                scope.tagBackgroundColors = {};
                scope.theme = scope.theme ? scope.theme : rootScope.theme;

                // find the pill for the "tags" feature
                const pill = _.find(rootScope.featuredProperties, { path: 'tags.label' });

                if (!pill) {
                    return;
                }

                const color = _.get(themes, [scope.theme, 'colorPalette', _.get(pill, 'idxInPool', 0) + 1]);

                const pillValue = _.castArray(pill.value);

                // for each tag selected set a background color for it
                _.forEach(pillValue, item => {
                    _.set(scope.tagBackgroundColors, [item], color);
                });
            },
            true
        );

        scope.$on('$destroy', function() {
            featureWatcher();
        });

        scope.enableDisableDefinition = () => {
            definitionService.updateDefinition(scope.definition).then(
                () => {
                    scope.api.refresh();
                    toastr.success(
                        `${_.startCase(_.get(scope, 'definition.definitionType'))} definition ${
                            scope.definition.enabled ? 'enabled' : 'disabled'
                        }!`
                    );
                },
                error => {
                    toastr.error('An error occurred updating definition', error.msg);
                }
            );
        };

        scope.startCase = input => _.startCase(input);
    }
}
