'use strict';

import Controller from './controller';
import template from './index.html';
import '../index.scss';

angular.module('serviceApp').directive('metricTrendConfig', () => ({
    restrict: 'E',
    scope: {
        index: '=',
        trend: '=',
        definition: '=',
        contextBreakdown: '<'
    },
    template,
    controller: Controller
}));
