'use strict';

import 'quill/dist/quill.min.js';
import 'quill/dist/quill.snow.css';

import colors from '@olono/shared-ui-assets/colors.json';

import Quill from 'quill/dist/quill.min.js';
import d3 from 'd3';
import _ from 'lodash';
import Delta from 'quill-delta';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

import fontData from './font-data';

/**
 * @module modules/ng-quill
 * An angular friendly module of quill.js
 * Inspired by [ng-quill](https://github.com/KillerCodeMonkey/ng-quill) but heavily modified
 * For quilljs specific docs see here: https://quilljs.com/docs/
 */

const Parchment = Quill.import('parchment');

// register custom data-variable attribute
// see: https://github.com/quilljs/parchment for more details
const VariableAttribute = new Parchment.Attributor.Attribute('variable', 'data-variable', {
    scope: Parchment.Scope.INLINE
});
Quill.register(VariableAttribute, true);

const TransformsAttribute = new Parchment.Attributor.Attribute('transforms', 'data-transforms', {
    scope: Parchment.Scope.INLINE
});
Quill.register(TransformsAttribute, true);

const DeferredAttribute = new Parchment.Attributor.Attribute('deferred', 'data-deferred', {
    scope: Parchment.Scope.INLINE
});
Quill.register(DeferredAttribute, true);

// declare ngQuill module
const app = angular.module('ngQuill', []);

/**
 * @module ngQuillConfig
 * Supplies a provider to angular root
 * For more usage see: https://github.com/KillerCodeMonkey/ng-quill#usage
 * In app.js:
 * ngQuillConfigProvider.set({modules: { ... }, theme: 'snow', placeholder: 'placeholder')
 */
app.provider('ngQuillConfig', function() {
    // set up default config if none supplied by ngQuillConfig
    const config = {
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'link', 'blockquote'],
                [{ color: [] }, { background: [] }, { font: [] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['image']
            ]
        },
        theme: 'snow',
        placeholder: 'Insert text here ...',
        readOnly: false,
        bounds: document.body,
        preserveWhitespace: false
    };

    this.set = function(customConf) {
        customConf = customConf || {};

        if (customConf.modules) {
            config.modules = customConf.modules;
        }
        if (customConf.theme) {
            config.theme = customConf.theme;
        }
        if (customConf.placeholder !== null && customConf.placeholder !== undefined) {
            config.placeholder = customConf.placeholder.trim();
        }
        if (customConf.bounds) {
            config.bounds = customConf.bounds;
        }
        if (customConf.readOnly) {
            config.readOnly = customConf.readOnly;
        }
        if (customConf.formats) {
            config.formats = customConf.formats;
        }
        if (customConf.preserveWhitespace) {
            config.preserveWhitespace = true;
        }
    };

    this.$get = () => config;
});

/**
 * @class NgQuillController
 * Controller for ng-quill component instance
 * Constructor injection done below class declaration
 */
class NgQuillController {
    constructor($scope, $element, $timeout, $transclude, $window, ngQuillConfig) {
        this.$scope = $scope;
        this.$element = $element;
        this.$timeout = $timeout;
        this.$transclude = $transclude;
        this.$window = $window;
        this.ngQuillConfig = ngQuillConfig;
        this.content;
        this.modelChanged = false;
        this.editorChanged = false;
        this.editor;
        this.lastKnownRange;
    }

    $onInit() {
        if (!this.placeholder) {
            this.placeholder = this.ngQuillConfig.placeholder;
        }
        if (this.placeholder !== null && this.placeholder !== undefined) {
            this.placeholder = this.placeholder.trim();
        }
        this.config = {
            theme: this.theme || this.ngQuillConfig.theme,
            readOnly: this.readOnly || this.ngQuillConfig.readOnly,
            modules: this.modules || this.ngQuillConfig.modules,
            formats: this.formats || this.ngQuillConfig.formats,
            placeholder: this.placeholder,
            bounds: this.bounds || this.ngQuillConfig.bounds,
            strict: this.strict,
            scrollingContainer: this.scrollingContainer
        };
    }

    $postLink() {
        // create quill instance after dom is rendered
        this.$timeout(this._initEditor.bind(this), 0);
    }

    /**
     * @function _initEditor
     * Internal initialization phase.  Sets up the editor's element event handlers
     * and customization
     */
    _initEditor() {
        const container = this.$element.children();
        const $editorElem = this.preserveWhitespace
            ? angular.element('<pre></pre>')
            : angular.element('<div></div>');

        const editorElem = $editorElem[0];

        if (this.config.bounds === 'self') {
            this.config.bounds = this.editorElem;
        }

        // if toolbar supplied via transclude use it rather than the default
        if (this.$transclude.isSlotFilled('toolbar')) {
            this.config.modules.toolbar = container.find('ng-quill-toolbar').children()[0];
        }

        // ensure that the editor element is in the DOM before rendering
        container.append($editorElem);

        if (this.customOptions) {
            this.customOptions.forEach(customOption => {
                const newCustomOption = Quill.import(customOption.import);
                newCustomOption.whitelist = customOption.whitelist;
                if (customOption.toRegister) {
                    newCustomOption[customOption.toRegister.key] = customOption.toRegister.value;
                }
                Quill.register(newCustomOption, true);
            });
        }

        // create editor instance and export public functions
        this.editor = new Quill(editorElem, this.config);
        this.editor.insertVariable = this.insertVariable.bind(this);
        this.editor.init = this.initContent.bind(this);

        this.ready = true;

        // init quill event handlers
        this.editor.on('text-change', this._textChanged.bind(this));
        this.editor.on('editor-change', this._editorChanged.bind(this));
        this.editor.on('selection-change', this._selectionChanged.bind(this));

        // init custom HTML tag handler
        this.editor.clipboard.addMatcher('VARIABLE', this._variableMatched.bind(this));

        this.initContent();
    }

    initContent() {
        // set initial content
        if (this.content) {
            const contents = this.editor.clipboard.convert(this.content);
            this.editor.setContents(contents);
            this.editor.history.clear();
        }

        // call editor created handler to the requester
        if (this.onEditorCreated) {
            this.onEditorCreated({ editor: this.editor });
        }

        /**
         * do initial variable translation
         * taking <%= some property %> or <% some property %> and replace it with
         * <variable data-variable="some property"></variable>
         * this tag is then picked up by the VARIABLE matcher and converted
         * to an image
         * TODO: Move to own function for repeatability
         */
        // The = is optional to support both pre-render and deferred render syntax.
        // TODO We should probably extract the presence of the = into a variable that can
        // be used later. We should do this when we allow the user to choose transforms.
        const pattern = /<%(=)?([\s\S]+?)%>/g;

        let insertionIndexes = [];
        let match = new RegExp(pattern).exec(this.editor.getText());

        while (match) {
            const deferred = _.get(match, 1) !== '=';
            const data = _.get(match, 2);
            const matchingIndex = _.get(match, 'index');
            let matchingLength = _.get(match, '0.length', 0);

            // remove found <%= something %> from the editor
            this.editor.deleteText(matchingIndex, matchingLength);

            // keep track of where we removed the item from
            insertionIndexes.push({ index: matchingIndex, data, deferred });

            // find next match
            match = new RegExp(pattern).exec(this.editor.getText());
        }

        // once all matches have been removed insert <variable></variable> tags
        // at the proper locations
        _.forEach(insertionIndexes, (value, index) =>
            this.insertVariable(value.data, value.deferred, value.index + index)
        );
    }

    /**
     * @function _selectionChanged - selection change event listener
     * @param {object} range - { index: start of selection, length: length of selection }
     * @param {object} oldRange - same as range but for the previous selection
     * @param {string} source - [user, api ](will be user in most cases)
     */
    _selectionChanged(range, oldRange, source) {
        if (this.onSelectionChanged) {
            this.onSelectionChanged({
                editor: this.editor,
                oldRange: oldRange,
                range: range,
                source: source
            });
        }

        if (range) {
            return;
        }

        this.$scope.$applyAsync(this.ngModelCtrl.$setTouched.bind(this.ngModelCtrl));
    }

    /**
     * @function _textChanged - text change event listener
     * On every text change we convert the html in the editor to our server friendly
     * <%= something %> tags and update the external model
     * @param {instance} delta - current delta see: https://github.com/quilljs/delta
     * @param {instance} oldDelta - same as delta but for previous text change
     * @param {string} source - [user, api ](will be user in most cases)
     */
    _textChanged(delta, oldDelta, source) {
        const emptyModelTag = [
            `<${this.editor.root.firstChild.localName}>`,
            `</${this.editor.root.firstChild.localName}>`
        ];

        // ensure text is present for form validation
        const text = this.editor.getText();
        this.validate(text);

        if (!this.modelChanged) {
            this.$scope.$applyAsync(() => {
                this.editorChanged = true;

                // loop over editor delta finding our custom tag and converting it
                // back to server friendly tags, retaining attributes
                const deltaOps = _.map(this.editor.getContents().ops, op => {
                    const variableAttribute = _.get(op, 'attributes.variable');
                    const deferredVariable = _.get(op, 'attributes.deferred', false);
                    const transformsVariable = _.get(op, 'attributes.transforms', []);
                    if (variableAttribute && !_.isString(op.insert)) {
                        const attributeContent = _.concat([variableAttribute], transformsVariable);
                        op = {
                            // the spaces around this variable are important for later html striping
                            insert: `<%${!deferredVariable ? '=' : ''} ${_.join(attributeContent, '|')} %>`,
                            attributes: _.omit(op.attributes, 'variable', 'transforms')
                        };
                    }

                    return op;
                });

                // convert the delta instance to html without encoding text html
                // this is safe bc quill editor won't allow malicious html to this point
                let htmlData = new QuillDeltaToHtmlConverter(deltaOps, {
                    encodeHtml: false,
                    // if a <a></a> has our template in it don't sanitize the url
                    urlSanitizer: url => (_.includes(url, '<%') ? url : undefined)
                }).convert();

                // update model and view
                this.ngModelCtrl.$setViewValue(htmlData);

                // if empty html return null
                if (htmlData === emptyModelTag[0] + '<br>' + emptyModelTag[1]) {
                    htmlData = null;
                }

                // fire content changed event
                if (this.onContentChanged) {
                    this.onContentChanged({
                        editor: this.editor,
                        html: htmlData,
                        text: text,
                        delta: delta,
                        oldDelta: oldDelta,
                        source: source
                    });
                }
            });
        }

        this.modelChanged = false;
    }

    /**
     * @function _editorChanged - editor change event listener
     * This is fired for every selection and text change helping us keep
     * track of where the cursor is in the editor
     */
    _editorChanged() {
        const range = this.editor.getSelection();
        this.lastKnownRange = range ? range : this.lastKnownRange;
    }

    /**
     * @function insertVariable - Insert <variable></variable> at specified index with
     * specified data
     * @param {string} data - data to pass through to be resolved as a variable
     * @param {integer} definedIndex - where to insert the variable tag
     */
    insertVariable(data, deferred, definedIndex) {
        // ensure we have the most up-to-date cursor location for insertion
        const defaultIndex = _.get(this, 'lastKnownRange.index', 0);
        const currentIndex = _.get(this.editor.getSelection(), 'index', defaultIndex);

        let varName, varLabel, varTransforms;
        // If we're adding a var from the UI, it'll come in as an object.
        if (_.isObject(data)) {
            // If a template var translator is available, use it to get the right label for the bubble.
            if (this.onStringTemplateVar) {
                ({ varName, varLabel, varTransforms = [] } = this.onStringTemplateVar({
                    data: `${data.name}${data.transforms ? `|${data.transforms}` : ''}`
                }));
            }
            // Otherwise just take the provided data as-is.
            else {
                ({ name: varName, label: varLabel, transforms: varTransforms } = data);
            }
        }
        // If we're adding a var from a saved definition, it'll come in as a string.
        // If a template var translator is available, use it to get the right label for the bubble.
        else if (this.onStringTemplateVar) {
            ({ varName, varLabel, varTransforms = [] } = this.onStringTemplateVar({ data }));
        }
        // Otherwise guess the label from the var name.
        else {
            const splitData = data.split('|');
            varName = _.trim(splitData[0]);
            varTransforms = _(splitData)
                .slice(1)
                .map(_.trim)
                .value();
            varLabel = _.startCase(_.replace(varName, 'content.', ''));
        }

        const dataLabel = varLabel ? `data-label="${varLabel}"` : '';
        const dataVariable = varName ? `data-variable="${varName}"` : '';
        const dataTransforms = varTransforms ? `data-transforms="${varTransforms}"` : '';
        const dataDeferred = deferred ? `data-deferred="${deferred}"` : '';
        this.editor.clipboard.dangerouslyPasteHTML(
            !_.isNil(definedIndex) ? definedIndex : currentIndex,
            `<variable ${dataVariable} ${dataLabel} ${dataTransforms} ${dataDeferred}></variable>`
        );
    }

    /**
     * @function _variableMatched - Tag matcher for translating <variable></variable>
     * to <img> with an encoded SVG as the src
     * @param {object} node - HTML node of the matched tag
     * @param {instance} delta - delta instance of the tags contents
     */
    _variableMatched(node, delta) {
        // dig out the data passed via the data-variable attribute
        const nodeAttributes = _.get(node, 'attributes');

        const dataAttribute = _.find(nodeAttributes, { nodeName: 'data-variable' });
        const dataValue = _.get(dataAttribute, 'nodeValue', '');

        const labelAttribute = _.find(nodeAttributes, { nodeName: 'data-label' });
        const labelValue = _.get(labelAttribute, 'nodeValue', '');

        const transformsAttribute = _.find(nodeAttributes, { nodeName: 'data-transforms' });
        const transformsValue = _.get(transformsAttribute, 'nodeValue', []);

        const deferredAttribute = _.find(nodeAttributes, { nodeName: 'data-deferred' });
        const deferredValue = _.get(deferredAttribute, 'nodeValue', false);

        // find temp-svg-holder on rightDrawer.html for pre-render calculation
        let plot = d3.select('#temp-svg-holder').append('svg');

        // svg height
        const height = 25;

        // create background rectangle
        plot.append('rect')
            .attr('fill', colors['ink-100'])
            .attr('rx', 12)
            .attr('height', height);

        // create text element with center alignment
        plot.append('text')
            .attr('y', '50%')
            .attr('x', '50%')
            .attr('fill', colors['ink-600'])
            .style('font-size', '11px')
            .style('font-family', 'Roboto')
            .style('text-anchor', 'middle')
            .style('alignment-baseline', 'central')
            .text(labelValue);

        // calculate text width
        const textElement = plot.select('text');

        // using try catches here so tests can pass
        // the karma instance does not have d3 loaded into it yet
        // so just stub out the known bad stuff
        let textWidth;
        try {
            textWidth = textElement.node().getComputedTextLength() + 20;
        } catch (e) {
            textWidth = 20;
        }

        // update background to match text width
        plot.select('rect').attr('width', textWidth);

        let plotHtml;
        try {
            plotHtml = plot.html();
        } catch (e) {
            plotHtml = '<div>error</div>';
        }

        // build SVG html
        const imageHtmlString = `
            <svg xmlns='http://www.w3.org/2000/svg' height="${height}" width="${textWidth}">
                <defs>
                    <!-- need to inject all text styling here bc encoded imgs can't inherit styling -->
                    <style type="text/css">
                    @font-face {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.18;
                        letter-spacing: 1px;
                        src:url(${fontData}) format('truetype');
                    }
                    </style>
                </defs>
                <!-- add generated svg data here (background and text) -->
                ${plotHtml}
            </svg>`;

        // remove svg from temp-svg-holder bc its done being calculated
        plot = plot.remove();

        // update delta, this inserts an image tag at the desire location
        // replaces what is currently there (<variable></variable>)
        return new Delta().insert(
            {
                image: `data:image/svg+xml,${encodeURIComponent(imageHtmlString)}`
            },
            { variable: dataValue, transforms: transformsValue, deferred: deferredValue }
        );
    }

    /**
     * @function - native component function updates editor on model changes
     */
    $onChanges(changes) {
        if (changes.ngModel && changes.ngModel.currentValue !== changes.ngModel.previousValue) {
            this.content = changes.ngModel.currentValue;

            if (this.editor && !this.editorChanged) {
                this.modelChanged = true;
                if (this.content) {
                    this.editor.setContents(this.editor.clipboard.convert(this.content));
                } else {
                    this.editor.setText('');
                }
            }
            this.editorChanged = false;
        }

        if (this.editor && changes.readOnly) {
            this.editor.enable(!changes.readOnly.currentValue);
        }
    }

    /**
     * @function validate - check if the text matches desired length
     * @param {string} text - text currently in the editor
     */
    validate(text) {
        if (this.maxLength) {
            if (text.length > this.maxLength + 1) {
                this.ngModelCtrl.$setValidity('maxlength', false);
            } else {
                this.ngModelCtrl.$setValidity('maxlength', true);
            }
        }

        if (this.minLength > 1) {
            // validate only if text.length > 1
            if (text.length <= this.minLength && text.length > 1) {
                this.ngModelCtrl.$setValidity('minlength', false);
            } else {
                this.ngModelCtrl.$setValidity('minlength', true);
            }
        }
    }
}

NgQuillController.$inject = ['$scope', '$element', '$timeout', '$transclude', '$window', 'ngQuillConfig'];

app.component('ngQuillEditor', {
    bindings: {
        theme: '@?',
        strict: '<?',
        bounds: '<?',
        ngModel: '<',
        formats: '<?',
        readOnly: '<?',
        maxLength: '<',
        minLength: '<',
        placeholder: '@?',
        modules: '<modules',
        customOptions: '<?',
        scrollingContainer: '<?',
        preserveWhitespace: '<?',

        onEditorCreated: '&?',
        onContentChanged: '&?',
        onSelectionChanged: '&?',
        onStringTemplateVar: '&?'
    },
    require: { ngModelCtrl: 'ngModel' },
    transclude: { toolbar: '?ngQuillToolbar' },
    template: `
            <div class="ng-hide ql-transclude-container" ng-show="$ctrl.ready">
                <ng-transclude ng-transclude-slot="toolbar"></ng-transclude>
            </div>`,
    controller: NgQuillController
});
