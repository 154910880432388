'use strict';
import { BaseFormWidgetController } from '../../scripts/utils/form-utils.js';

export default class FormStringController extends BaseFormWidgetController {
    constructor(scope, rootScope, filter) {
        super(scope, rootScope, filter);

        this.init();
    }

    init() {
        this.scope.chromeAutoCompleteKiller = Math.random();
    }
}
