'use strict';

export default class ActionDefinitionStatsController {
    static get $inject() {
        return ['$scope'];
    }
    constructor(scope) {
        this.scope = scope;

        this.scope.defaultFilter = { definitionId: this.scope.definition.id };
    }
}
