import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('forecastSubmissionDrawer', () => ({
    restrict: 'E',
    scope: {
        board: '<',
        entry: '<',
        attainable: '<'
    },
    template,
    controller: Controller
}));
