ContentDetailsController.$inject = [
    '_',
    '$q',
    '$scope',
    '$rootScope',
    '$log',
    '$window',
    '$state',
    '$interpolate',
    'searchServiceNew',
    'cardsUtilService',
    'boardServiceNew',
    '$filter',
    'toastr'
];

function ContentDetailsController(
    _,
    $q,
    $scope,
    $rootScope,
    $log,
    $window,
    $state,
    $interpolate,
    searchServiceNew,
    cardsUtilService,
    boardServiceNew,
    $filter,
    toastr
) {
    $scope.getBoard = getBoard;
    $scope.loading = true;

    $scope.productName = $rootScope.productName;

    var detailsWatcher = $scope.$watchCollection('details', (newVal, oldVal) => {
        $scope.facetArray =
            _.map(_.get(newVal, 'properties.facets'), function(item) {
                return item;
            }) || [];
    });
    $scope.$on('$destroy', function() {
        detailsWatcher();
    });

    if ($scope.content) {
        $scope.drawerContent = true;
        setDrawerContent();
        var boardWatcher = $scope.$watchCollection('content', (newVal, oldVal) => {
            if ($scope.content && !_.isEqual(newVal, oldVal)) {
                setDrawerContent();
            }
        });

        $scope.$on('$destroy', function() {
            boardWatcher();
        });
    } else {
        var boardWatcher = $scope.$watchCollection('board', (newVal, oldVal) => {
            setDetailsContent();
        });

        var detailsWatcher = $scope.$watchCollection('details', (newVal, oldVal) => {
            setDetailsContent();
        });

        $scope.$on('$destroy', function() {
            boardWatcher();
            detailsWatcher();
        });
    }

    // When we get a notification that the board context was updated, reload the card.
    if (_.get($scope, 'card.socketEventNames.refresh_finished')) {
        $scope.$on($scope.card.socketEventNames.refresh_finished, function(event, payload) {
            if ($scope.content) {
                setDrawerContent();
                $scope.contextRefreshInProgress = false;
            } else {
                cardsUtilService
                    .getCard({
                        boardId: $scope.board.id,
                        panelName: 'summary',
                        cardName: 'details',
                        cacheOnly: true
                    })
                    .then(data => {
                        _.set($scope, 'details.properties.facets', _.get(data, 'properties.facets'));
                        setDetailsContent();
                        $scope.contextRefreshInProgress = false;
                    });
            }
        });
    }

    // When we get a notification that board context refresh started, show an indicator.
    if (_.get($scope, 'card.socketEventNames.refresh_started')) {
        $scope.$on($scope.card.socketEventNames.refresh_started, function(event, payload) {
            $scope.contextRefreshInProgress = true;
        });
    }

    /**
     * Given the key for a property in the "metrics" facet, get the correct label for it.
     * These facets may have templated labels associated with them.
     * Defaults to the start-cased version of the key.
     */
    $scope.metricsKey = function(key) {
        const label = _.get($scope.details, `display.metricsDisplayOptions.properties['${key}'].label`);
        if (!label) {
            return key;
        }
        if (_.includes(label, '{{')) {
            var templater = $interpolate(label);
            return templater($scope);
        }
        return label;
    };

    $scope.tabTitle = function(title) {
        if (_.includes(title, '{{')) {
            var templater = $interpolate(title);
            return templater($scope);
        }
        return title;
    };

    $scope.openTab = function(link) {
        $window.open(link.url, '_blank');
    };

    $scope.openContent = function() {
        const url = $state.href('boards.board.panel', {
            board: _.get($scope.extendedContent, 'boardId'),
            panel: null,
            overwriteReload: false,
            facets: undefined,
            query: undefined,
            filter: undefined,
            destinationTime: undefined
        });
        $window.open(url, '_blank');
    };

    $scope.openDrawer = link => {
        $rootScope.$emit('rightDrawer:open', _.get(link, 'drawer', {}));
    };

    $scope.closeDetails = function() {
        if ($rootScope.rightDrawerOpen) {
            $scope.$emit('rightDrawer:close');
        }
    };

    $scope.refreshContent = function() {
        //get the contentID directly off the content if none is available on the board
        const contentId = _.get($scope.details, 'properties.facets.details.detailContent.id');
        if (!($scope.board.contentId || contentId)) {
            toastr.error('Error refreshing content, could not find contentId', '');
            return;
        }

        searchServiceNew.refreshContent($scope.board.contentId || contentId).then(
            function() {
                toastr.success('Refresh Content Started', '');
            },
            function(err) {
                toastr.error('Error refreshing content ' + JSON.stringify(err), '');
            }
        );
    };

    $scope.runAnalysis = function() {
        //get the contentID directly off the content if none is available on the board
        const contentId = _.get($scope.details, 'properties.facets.details.detailContent.id');
        if (!($scope.board.contentId || contentId)) {
            toastr.error('Error running analysis, could not find contentId', '');
            return;
        }

        searchServiceNew
            .refreshContent($scope.board.contentId || contentId, {
                analysisOnly: true
            })
            .then(
                function() {
                    toastr.success('Run Analysis Started', '');
                },
                function(err) {
                    toastr.error('Error running analysis ' + JSON.stringify(err), '');
                }
            );
    };

    function setDetailsContent() {
        $scope.type = _.get($scope.board, 'type');
        $scope.typeLabel = _.toUpper($filter('contentTypeLabel')($scope.type));
        $scope.title = _.get($scope.board, 'properties.subTitle')
            ? _.get($scope.board, 'properties.subTitle')
            : _.get($scope.board, 'title');
        $scope.boardDisplayOptions = _.get(
            $scope.board,
            'contentDisplayOptions[' + _.get($scope.board, 'type') + ']'
        );
        $scope.extendedContent = _.get($scope.details, 'properties.facets.details.detailContent');
        $scope.contentLastRefreshed = _.get($scope.extendedContent, 'context.lastRefreshDate');
        // If we're setting details content, assume we're viewing a board which means we'll
        // show the "update now" and "view in app" buttons.
        // @todo - revisit this logic when we get more granular detail about which content
        //         supports external links and "update now".
        $scope.isUpdatable = true;
        $scope.isExternallyLinkable = true;
        $scope.loading = false;
    }

    function setDrawerContent() {
        $scope.loading = true;
        let boardPromise = $q.when();
        let cardPromise = $q.when();
        const contentBoardId = _.get($scope, 'content.boardId');
        // if the current board does not match the specified content, go fetch it
        if (contentBoardId && _.get($scope, 'board.id', 'NO_BOARD') !== contentBoardId) {
            boardPromise = boardServiceNew.getBoard({ id: $scope.content.boardId });
        }

        if (contentBoardId) {
            cardPromise = cardsUtilService.getCard({
                boardId: $scope.content.boardId,
                panelName: 'summary',
                cardName: 'details',
                noFilter: true
            });
        }

        $q.all([boardPromise, cardPromise])
            .then(([board, card]) => {
                if (board) {
                    $scope.board = board;
                }

                // If we got a card from the API, load that into the scope.
                if (card) {
                    $scope.details = card;
                    // Get the content type out of the card data we just retrieved.
                    const newContentType = _.last(
                        _.castArray(
                            _.get($scope.details, 'properties.facets.details.detailContent.contentType')
                        )
                    );
                    // Update to the new content type if we got one.
                    // This should really only be relevant in use cases where we don't
                    // have content on initial drawer open.
                    $scope.contentType = newContentType || $scope.contentType;
                    $scope.isUpdatable = true;
                    $scope.isExternallyLinkable = true;
                }
                // Otherwise, mock up a details card using the content we already have.
                else {
                    $scope.details = {
                        name: 'details',
                        title: 'Details',
                        display: {
                            viewType: 'contentDetails'
                        },
                        properties: {
                            facets: {
                                details: {
                                    type: 'details',
                                    title: 'Details',
                                    extendedDetails:
                                        $scope.content[_.last(_.castArray($scope.content.contentType))]
                                }
                            }
                        }
                    };

                    // To be safe, don't display the "view in" or "update now" buttons.
                    $scope.isUpdatable = false;
                    $scope.isExternallyLinkable = false;
                }

                $scope.type = $scope.contentType;
                $scope.typeLabel = _.toUpper($filter('contentTypeLabel')($scope.type));
                $scope.title = _.get(
                    $scope.content,
                    [$scope.contentType, 'name'],
                    $scope.content.contentTitle
                );
                $scope.boardDisplayOptions = _.get(
                    $scope.board,
                    'contentDisplayOptions[' + $scope.type + ']',
                    _.get($rootScope[('displayOptions', $scope.type)])
                );
                $scope.extendedContent = _.get($scope.details, 'properties.facets.details.detailContent');
                $scope.contentLastRefreshed = _.get($scope.extendedContent, 'context.lastRefreshDate');

                $scope.loading = false;
            })
            .catch(err => {
                $log.error('Error getting data', err);
            });
    }
    function getBoard(key, data) {
        return _.get(data, [key, 'boardId'], undefined);
    }
}

export default ContentDetailsController;
