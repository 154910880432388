'use strict';

export default class CardUserAccountSettings {
    static get $inject() {
        return [
            '$scope',
            '$rootScope',
            '$sanitize',
            'userServiceNew',
            'definitionService',
            'toastr',
            '$state'
        ];
    }

    constructor($scope, $rootScope, $sanitize, userServiceNew, definitionService, toastr, $state) {
        $scope.confirmModal = false;
        $scope.homeboards = {
            currentBoardType: undefined,
            teamHomeBoard: _.get($scope, 'team.currentHomeBoard')
        };

        const isTeamEnableGUM = _.get($scope, 'team.coreAuth.tenantId');
        const isUserLinkedToGUM = _.get($scope, 'user.coreAuth.id');
        $scope.isUsingGlobalUserDetails = isTeamEnableGUM && isUserLinkedToGUM;

        $scope.showConfirm = function() {
            $scope.confirmModal = true;
        };

        $scope.hideConfirm = function() {
            $scope.confirmModal = false;
        };

        // save updates to user profile
        $scope.$on('panel:save', () => {
            var sanitizedSignature = $sanitize(_.get($scope, 'user.profile.emailSignature'));
            var updateUserProfilePayload = {
                id: $scope.user.id,
                profile: {
                    emailSignature: sanitizedSignature
                },
                state: { currentHomeBoard: $scope.homeboards.currentBoardType }
            };

            userServiceNew.updateUser(updateUserProfilePayload).then(
                function() {
                    toastr.success('Profile Updated!', '');
                },
                function(error) {
                    toastr.error('An Error Occurred', error.msg);
                }
            );
        });

        $scope.resetPassword = function() {
            var data = {
                send_token_to: $scope.user.username
            };

            userServiceNew.sendPasswordResetToken(data).then(
                function() {
                    toastr.success('Password reset token sent!', '');
                    userServiceNew.logOut().then(function() {
                        $state.go('core.login');
                        $rootScope.$emit('logout');
                    });
                },
                function(error) {
                    toastr.error('Password reset token sent!', '');
                    $scope.errorMessage = error;
                }
            );
        };
    }
}
