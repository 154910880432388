'use strict';

import _ from 'lodash';
/**
 * Scrollable Menu Container
 *
 * @export
 * @class Controller
 */
export default class Controller {
    // angular dependency injector
    static get $inject() {
        return ['_', '$scope'];
    }

    constructor(_, $scope) {
        this.scope = $scope;
        this.scope.maxHeight = this.scope.large ? 300 : 200;
    }

    initialize(elem) {
        this._el = elem;
        this._viewport = this._el.querySelector('.scroll-content');
        this._arrowEL = angular.element(this._el.querySelector('.bottom-arrow'));
        this._viewportEl = angular.element(this._viewport);

        //Function that responds to a scroll event that either maintains the indicator or removes it
        let scroll = function() {
            if (this._viewport.scrollTop + this._viewport.offsetHeight >= this._viewport.scrollHeight) {
                this._arrowEL.addClass('scrolled');
            } else {
                this._arrowEL.removeClass('scrolled');
            }
        };

        // respond to changes on view and test if the scroll area is larger than the view.
        const onChildrenItemsMutated = _.throttle(() => {
            //If the scrolled height is less than than the height total height, don't show the scroll indicator
            if (this._viewport.scrollHeight <= this.scope.maxHeight) {
                this._arrowEL.addClass('scrolled');
                this._viewportEl.removeClass(this.scope.large ? 'maxheight-large' : 'maxheight');
            } else if (this._viewport.scrollHeight > this.scope.maxHeight) {
                this._arrowEL.removeClass('scrolled');
                this._viewportEl.addClass(this.scope.large ? 'maxheight-large' : 'maxheight'); //If the height is greater than the menu height, force the max height
            }
        }, 100);
        //This will listen to the the list container in it's final form. This is a builtin angular element listener that listens to any changes on the element
        new MutationObserver(onChildrenItemsMutated).observe(this._viewport, {
            childList: true,
            subtree: true
        });

        // respond to scroll
        this._viewport.addEventListener('scroll', scroll.bind(this));
    }
}
