'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('addNewFilterButton', {
    controller,
    template,
    bindings: {
        isLoading: '<',
        onButtonClick: '<'
    }
});
