import IndicatorUtil from '../utils/indicatorUtil';

(function() {
    'use strict';
    angular.module('serviceApp').factory('definitionService', DefinitionService);

    DefinitionService.$inject = [
        '_',
        '$resource',
        '$q',
        '$filter',
        '$log',
        'storageService',
        'translateValuesService',
        'filterService'
    ];

    function DefinitionService(
        _,
        $resource,
        $q,
        $filter,
        $log,
        storageService,
        translateValuesService,
        filterService
    ) {
        const DEFINITION_URL = '/api/definition';
        const DEFAULT_PARAMS = {};

        const DEFINITION_RESOURCE_API = {
            findDefinitions: {
                method: 'POST',
                url: DEFINITION_URL + '/find',
                isArray: true
            },
            countDefinitions: {
                method: 'POST',
                url: DEFINITION_URL + '/count'
            },
            searchDefinitions: {
                method: 'POST',
                url: DEFINITION_URL + '/search'
            },
            searchDefinitionsArray: {
                isArray: true,
                method: 'POST',
                url: DEFINITION_URL + '/search'
            },
            getDefinitionById: {
                method: 'GET',
                url: DEFINITION_URL + '/:definitionId'
            },
            updateDefinition: {
                method: 'POST',
                url: DEFINITION_URL
            },
            resolve: {
                method: 'POST',
                url: DEFINITION_URL + '/resolve'
            },
            findTags: {
                method: 'POST',
                url: DEFINITION_URL + '/tags/find'
            },
            deleteDefinition: {
                method: 'DELETE',
                url: DEFINITION_URL + '/:definitionId'
            },
            copyDefinition: {
                method: 'POST',
                url: `${DEFINITION_URL}/copy`
            }
        };

        const DEFINITION_RESOURCE = $resource(DEFINITION_URL, DEFAULT_PARAMS, DEFINITION_RESOURCE_API);

        const indicatorUtil = new IndicatorUtil($filter, $log, translateValuesService);

        return {
            findDefinitions,
            countDefinitions,
            searchDefinitions,
            getDefinitionById,
            updateDefinition,
            resolve,
            findTags,
            deleteDefinition,
            getStoredFilter,
            setStoredFilter,
            deleteStoredFilter,
            buildIndicators: indicatorUtil.buildIndicators.bind(indicatorUtil),
            buildIndicator: indicatorUtil.buildIndicator.bind(indicatorUtil),
            getSavableDefinitionFromFilter,
            getFilter,
            initializeFilter,
            getAccordionGroups,
            copyDefinition,
            saveAsDefinition,
            redirectAfterSavingDefinition,
            createInFlightDefinition,
            checkDefinitionIsEditableInline,
            isDisplayNameInUse
        };

        ////////////

        /**
         *
         * @deprecated Use `searchDefinitions` instead.
         */
        function findDefinitions(filter, options) {
            if (!filter) {
                filter = {};
            }

            const body = { filter: filter, options: options };

            return DEFINITION_RESOURCE.findDefinitions({}, body)
                .$promise.then(function(results) {
                    return _.map(results, definitionItem => {
                        if (_.get(definitionItem, 'teamId') === '##GLOBAL##') {
                            _.set(
                                definitionItem,
                                'enabled',
                                !!_.get(definitionItem, 'enabledByDefault', false)
                            );
                            _.set(definitionItem, 'globalId', _.get(definitionItem, 'id'));
                        }

                        return definitionItem;
                    });
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }

        /**
         * Returns the count of definitions that match the filter
         * @param {Filter} filter object for the definition.
         */
        function countDefinitions(filter) {
            if (!filter) {
                filter = {};
            }

            const body = { filter: filter };

            return DEFINITION_RESOURCE.countDefinitions({}, body)
                .$promise.then(function(results) {
                    return results;
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }

        function searchDefinitions(args) {
            let searchType;
            if (args.searchType) {
                searchType = args.searchType;
                delete args.searchType;
            }
            // if pagination is false then we need to use the resource that expects an array
            if (_.get(args, 'withPagination') === false) {
                return DEFINITION_RESOURCE.searchDefinitionsArray({ searchType }, args).$promise;
            }

            return DEFINITION_RESOURCE.searchDefinitions({ searchType }, args).$promise;
        }

        function getDefinitionById(definitionId) {
            return DEFINITION_RESOURCE.getDefinitionById({ definitionId: definitionId })
                .$promise.then(function(result) {
                    if (_.get(result, 'teamId') === '##GLOBAL##') {
                        _.set(result, 'enabled', !!_.get(result, 'enabledByDefault', false));
                        _.set(result, 'globalId', _.get(result, 'id'));
                    }

                    return result;
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }

        function updateDefinition(definition) {
            const clonedDefinition = _.cloneDeep(definition);

            const filterSections = _.reduce(
                definition.propertySchema,
                (memo, schemaPropertyDef, schemaPropertyName) => {
                    const sectionType = _.get(schemaPropertyDef, '_display.sectionType');
                    if (sectionType === 'filter' || sectionType === 'historicFilter') {
                        memo.push(schemaPropertyName);
                    }
                    return memo;
                },
                []
            );
            _.each(filterSections, sectionName => {
                const filterValues = _.get(clonedDefinition, ['propertyValues', sectionName]);

                // force remove _indicator
                _.set(
                    clonedDefinition,
                    ['propertyValues', sectionName],
                    _.reduce(
                        filterValues,
                        (accumulator = {}, value, key) => {
                            _.unset(value, '_indicator');
                            _.set(accumulator, [key], value);

                            return accumulator;
                        },
                        undefined
                    )
                );
            });

            return DEFINITION_RESOURCE.updateDefinition({}, clonedDefinition)
                .$promise.then(function(result) {
                    return result;
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }

        function resolve(args) {
            if (!args) {
                return $q.reject('args must be defined');
            }

            return DEFINITION_RESOURCE.resolve({}, args)
                .$promise.then(function(result) {
                    return result;
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }

        function findTags(args) {
            return DEFINITION_RESOURCE.findTags({}, args)
                .$promise.then(function(result) {
                    return result;
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }

        function deleteDefinition(definitionId) {
            if (!definitionId) {
                return $q.reject('definitionId must be defined');
            }

            return DEFINITION_RESOURCE.deleteDefinition({ definitionId: definitionId })
                .$promise.then(function(result) {
                    return result;
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }

        function getStoredFilter(board) {
            var storedFilter = storageService.get(getFilterStorageKey(board), 'local');
            try {
                storedFilter = JSON.parse(storedFilter);
            } catch (e) {
                storedFilter = undefined;
            }

            return storedFilter;
        }

        function setStoredFilter(board, definition) {
            var definitionString;
            try {
                definitionString = JSON.stringify(definition);
            } catch (e) {
                definitionString = undefined;
            }

            return storageService.set(getFilterStorageKey(board), definitionString, null, 'local');
        }

        function deleteStoredFilter(board) {
            return storageService.delete(getFilterStorageKey(board), 'local');
        }

        function getFilterStorageKey(board) {
            const shortCode = _.isString(board) ? board : _.get(board, 'shortcode');
            const panelGroup = _.get(board, 'state.currentGroup', 'main');
            const delimiter = '::';

            return _.toLower(shortCode + delimiter + panelGroup + delimiter + 'filter');
        }

        function getSavableDefinitionFromFilter(filter, form, value, sectionName) {
            var propSchema = _.get(form, 'properties');

            var savableDefinition = _.cloneDeep(filter);

            _.set(savableDefinition, `propertySchema.${sectionName}`, _.cloneDeep(propSchema));
            _.set(savableDefinition, `propertyValues.${sectionName}`, _.cloneDeep(value));

            return savableDefinition;
        }

        /**
         * Extract the filter configuration from the given definition and convert it into an array
         * of Pliable queries. This shares significant logic with `services/filter-definition.js#getPliableFromFilter()`
         * in `rest-server`.
         *
         * @param {Definition} definition The definition instance.
         */
        function getFilter(definition) {
            const clonedDefinition = _.cloneDeep(definition);
            const propertySchema = _.get(clonedDefinition, 'propertySchema.filter', {});
            const propertyValues = _.get(clonedDefinition, 'propertyValues.filter', {});

            let clauses = _.reduce(
                propertySchema,
                (memo, propertyConfig, propertyKey) => {
                    // Skip items without config.
                    if (_.isEmpty(propertyConfig)) {
                        return memo;
                    }

                    const definedValue = propertyValues[propertyKey];
                    const defaultValue = _.get(propertyConfig, 'default');

                    // indicator is some meta data that might be set and we want to remove it
                    // really for legacy UI support
                    _.unset(definedValue, '_indicator');

                    // if the user has defined a filter value for a given filter schema
                    // property use it. other wise default to default schema value
                    // this may result in undefined but the reject in this chain will drop it
                    const filterValue = definedValue ? definedValue : defaultValue;

                    // Handle nested filter containers.
                    if (propertyConfig.type === 'nested') {
                        // If this nested property has options (like "sort" or "operation"),
                        // add them to the Pliable filter.
                        let _options = _.get(definedValue, 'filterOptions', {});
                        _options = _.defaults(_options, _.get(propertyConfig, 'filterOptions', {}));
                        if (!_.isEmpty(_options)) {
                            _.set(memo, [`_${propertyKey}`, propertyKey, '_options'], _options);
                        }
                        return memo;
                    }

                    // If there's no filter value, exclude it from the result.
                    if (_.isEmpty(filterValue)) {
                        return memo;
                    }

                    // If this isn't a subfilter of a nested filter, we'll just add a clause
                    // for the filter to the result.
                    if (!propertyConfig.nested) {
                        memo[propertyKey] = filterValue;
                        return memo;
                    }

                    // If it's a subfilter, we'll add the filter to the grouped array of filters
                    // for the parent.
                    //
                    // `parentKey` is just an arbitrary unique key to group under.
                    // We don't want to use the `propertyConfig.nested` value as the key
                    // as the parent filter may have its own config.
                    const parentKey = `_${propertyConfig.nested}`;
                    // Push the subfilter to the parent filter's ##AND## block.
                    const nestedFilters = _.get(memo, [parentKey, propertyConfig.nested, '##AND##'], []);
                    nestedFilters.push(filterValue);
                    _.set(memo, [parentKey, propertyConfig.nested, '##AND##'], nestedFilters);
                    return memo;
                },
                {}
            );

            // Get the array of values of the map we created above.
            clauses = _.values(clauses);
            return clauses;
        }

        function initializeFilter(filter, rootName, sectionName) {
            if (!filter) {
                return;
            }
            const clonedFilter = _.cloneDeep(filter);

            const sectionModel = _.cloneDeep(_.get(clonedFilter, `propertyValues.${sectionName}`, {}));
            const sectionSchema = _.cloneDeep(_.get(clonedFilter, `propertySchema.${sectionName}`, {}));

            const { formSchema, formModel } = filterService.initialize(sectionSchema, sectionModel);

            _.set(clonedFilter, 'filter.rootName', rootName);

            return {
                filter: clonedFilter,
                formModel: formModel,
                formSchema: formSchema
            };
        }

        function getAccordionGroups(tabDef, definition) {
            return _.compact(
                _.map(tabDef.sections, sectionName => {
                    const value = _.get(definition, ['propertySchema', sectionName]);
                    if (!value || !value._display) {
                        return null;
                    }
                    const sectionType = _.get(value, '_display.sectionType');
                    const sectionTitle = _.get(value, '_display.sectionTitle');
                    const sectionIcon = _.get(value, '_display.sectionIcon');
                    const sectionIconClass = _.get(value, '_display.sectionIconClass', '');
                    const sectionDescription = _.get(value, '_display.sectionDescription');
                    const sectionModule = _.get(value, '_display.sectionModule');
                    const sectionComponent = _.get(value, '_display.sectionComponent');

                    const formModel = _.get(definition, ['propertyValues', sectionName], {});

                    //This is used to set the order of the properties by setting the sf-form definition '*' sets
                    //the default behavior if none is given
                    const formOrder = _.get(definition, ['propertyOrder', sectionName], ['*']);

                    return {
                        sectionName,
                        sectionType,
                        sectionTitle,
                        sectionIconPath: 'images/static/' + sectionIcon + '.svg',
                        sectionIconClass,
                        sectionDescription,
                        sectionModule,
                        sectionComponent,
                        formSchema: { type: 'object', properties: value },
                        formModel,
                        formOrder,
                        definition,
                        filter: _.cloneDeep(definition),
                        prompt: _.cloneDeep(definition)
                    };
                })
            );
        }

        function copyDefinition({ definitionId, options }) {
            if (!definitionId) {
                return $q.reject('definitionId must be defined');
            }

            return DEFINITION_RESOURCE.copyDefinition({}, { definitionId, options }).$promise;
        }

        /**
         * Check wether a definition exists with a specific display name.
         *
         * @param {Object} args - Args used
         * @param {string} args.definitionType - The type of definitions we are searching for conflicts in.
         * @param {string} args.teamId - The team we are searching for conflicts in.
         * @param {string} args.displayName - The display name we are searching for duplicates of.
         * @param {string} args.ignoredDefinitionId - Optionally, ignore one existing ID.
         * @returns {boolean} Returns whether the display name is already in use.
         */
        function isDisplayNameInUse({ definitionType, teamId, displayName, ignoredDefinitionId = null }) {
            // If no displayName is passed in, skip the check
            if (!displayName) {
                return Promise.resolve(false);
            }
            return searchDefinitions({
                filter: {
                    definitionType: definitionType,
                    teamId: teamId,
                    'displayName.rawLowercase': _.toLower(displayName)
                },
                searchType: 'display-name-in-use-check',
                size: 1
            }).then(response => {
                // If we find any results (ignoring ignoredDefinitionId if it's passed in) then the display name is in use
                const definitions = _.get(response, 'results');
                return (
                    _.size(definitions) &&
                    (!ignoredDefinitionId || _.first(definitions).id !== ignoredDefinitionId)
                );
            });
        }

        /**
         * Creates a new definition from an existing definition.
         *
         * @param {Object} args - Args used to create a new definition
         * @param {Object} args.definition - The existing definition to copy
         * @param {Object} args.options - Options to pass into the copyDefinition call.
         * @param {string} args.newDisplayName - Optionally, provide a new displayName for the copy to use.
         * @param {string} args.teamId - Only required if a new display name is being provided,
         * used to check for existing definitions with the same name.
         * @returns {Object} Returns the newly copied definition.
         */
        function saveAsDefinition({ definition, options, newDisplayName, teamId }) {
            const definitionId = _.get(definition, 'id');

            if (!definitionId) {
                return $q.reject('definition must be defined');
            }

            // Check wether the display name is in use
            // (If display name is falsy, isDisplayNameInUse returns false without any
            // complex logic/requests, so there's no need to check it beforehand)
            return isDisplayNameInUse({
                definitionType: definition.definitionType,
                teamId: teamId,
                displayName: newDisplayName
            }).then(inUse => {
                if (inUse) {
                    throw new Error('This name is already in use');
                }
                return DEFINITION_RESOURCE.copyDefinition({}, { definitionId, options })
                    .$promise.then(result => {
                        const id = _.get(result, 'definition.id');
                        const name = _.get(result, 'definition.name');
                        const displayName = newDisplayName || _.get(result, 'definition.displayName');
                        const enabled = _.get(result, 'definition.enabled');

                        return updateDefinition({ ...definition, id, displayName, name, enabled });
                    })
                    .then(definition => ({ definition }));
            });
        }

        /**
         * Redirect to a definition after it has been saved.
         *
         * @param {Object} board - Configuration of the current board, including shortcode
         * @param {Object} definition - A board definition that has just been saved
         * @param {Function} refreshFn - A function to call to refresh the page with a new shortcode or ID
         * @param {String} defName - The key of the redirect target in the definition object
         */
        function redirectAfterSavingDefinition(board, definition, refreshFn, defName = 'name') {
            if (
                board &&
                board.type !== 'definitions' &&
                board.type !== 'teamMgmt' &&
                board.type !== 'settings' &&
                !_.endsWith(board.type, 'Definition')
            ) {
                // Pull out the shortcode for the board that this definition is based off of.
                const baseBoardShortcode = _.first(_.split(_.get(board, 'shortcode'), '$'));
                // Determine the virtual shortcode for this new board and redirect to it.
                const definitionShortcode = baseBoardShortcode + '$' + _.toLower(_.get(definition, defName));
                refreshFn(definitionShortcode, definition.id);
            } else {
                refreshFn(definition.id, _.get(definition, 'name'));
            }
        }

        function createInFlightDefinition({ rootFilterName, userId, boardId }) {
            return findDefinitions(
                {
                    teamId: ['##GLOBAL##'],
                    definitionType: 'filter',
                    name: rootFilterName
                },
                { resolveDefaults: { boardId } }
            )
                .then(filters => {
                    // Remove metadata from the global
                    let filter = _.first(filters);
                    filter.globalId = filter.id;
                    _.unset(filter, 'id');
                    _.unset(filter, 'name');
                    _.unset(filter, 'displayName');
                    // Set the user ID and root name on the filter.
                    // This allows the filter coordinator to correctly display itself
                    // when it's reloaded from data in localstorage or the URL.
                    filter.userId = userId;
                    _.set(filter, 'filter.rootName', rootFilterName);

                    // Get a copy of the definition to resolve in the next step.
                    return filter;
                })
                .catch(error => {
                    console.error(error);
                });
        }

        /**
         * Determine if a definition used to filter content (e.g. card, board or filter definition)
         * can be edited in the regular UI (as opposed to the settings pages).
         * @param {Object} board - the current board configuration (not board definition!)
         * @param {Object} user - the logged-in user
         * @param {Object} definition - the definition we want to check edit eligibility for.
         * @returns
         */
        function checkDefinitionIsEditableInline({ board, user, definition }) {
            // Definitions with temporary filters (e.g. goal definitions) aren't editable
            // from any view except the definition editor (which doesn't call this method).
            if (
                _.get(definition, 'hasTemporaryFilter') ||
                _.get(definition, 'propertySchema.goalConfiguration')
            ) {
                return false;
            }

            // Admins should be able to edit any definition.
            if (_.get(user, 'isOwner')) {
                return true;
            }

            // If we're dealing with a filter set (which is shareable), only allow
            // the person who created the filter set to edit it.
            else if (_.get(definition, 'definitionType') === 'filter') {
                return _.get(user, 'id') === definition.userId;
            }

            // Otherwise allow editing the definition if the user is able to edit the board.
            // This means that the user is the owner of, or in the set of editors of the board.
            return board.editable;
        }
    }
})();
