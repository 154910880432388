(function() {
    'use strict';

    angular.module('serviceApp').filter('isArray', isArray);

    isArray.$inject = ['_', '$filter'];

    function isArray(_, $filter) {
        var filter = function(n) {
            return _.isArray(n);
        };

        return filter;
    }
})();
