'use strict';
import Controller from './controller';
import template from './index.html';

angular.module('serviceApp').directive('actionSnooze', () => ({
    restrict: 'E',
    scope: {
        snoozeModel: '=?'
    },
    template,
    transclude: true,
    controller: Controller
}));
