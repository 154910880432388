(function() {
    'use strict';

    angular.module('serviceApp').filter('startCase', startCase);

    startCase.$inject = ['_'];

    function startCase(_) {
        return input => _.startCase(input);
    }
})();
