'use strict';

angular.module('serviceApp').service('activeViewService', ActiveViewService);

ActiveViewService.$inject = ['boardSliceService'];

function ActiveViewService(boardSliceService) {
    let _isInspectionView = false;

    /**
     * Returns true when the inspection drawer is open
     *
     * @returns {Boolean}
     */
    this.isInspectionView = () => {
        return _isInspectionView;
    };

    /**
     * Returns true when the reports view is active
     *
     * @returns {Boolean}
     */
    this.isReportsView = () => {
        return _.get(boardSliceService, 'board.type') === 'reports';
    };

    this.setIsInspectionView = value => {
        _isInspectionView = value;
    };

    /**
     * Returns true when the current view supports updating board filters.
     *
     * @returns {Boolean}
     */
    this.hasActiveBoardFilters = () => {
        return !this.isInspectionView() && !this.isReportsView();
    };
}
