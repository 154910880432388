(function() {
    'use strict';
    angular.module('serviceApp').directive('appContentFilters', appContentFilters);
    function appContentFilters() {
        var directive = {
            restrict: 'E',
            scope: {
                policy: '=',
                contentFilters: '=',
                contentSchema: '=',
                form: '='
            },
            template: require('views/tmpl/partials/appOptions/appContentFilters.html'),
            controller: AppContentFilters
        };

        return directive;
    }

    AppContentFilters.$inject = ['_', '$scope'];

    function AppContentFilters(_, $scope) {
        $scope.filterDialog = function(open, filter, index) {
            if (filter) {
                $scope.activeIndex = index;
                $scope.editFilter = _.clone(filter);
                $scope.isFilterNew = false;
                $scope.isAdvanced = filter.name === '##ADVANCED##';
            } else {
                $scope.editFilter = {};
                $scope.editFilter.contentType = $scope.policy.contentType;
                $scope.isFilterNew = true;
                $scope.isAdvanced = false;
            }
            $scope.filterDialogOpen = open;
        };

        $scope.toggleAdvanced = function() {
            $scope.isAdvanced = !$scope.isAdvanced;
        };

        $scope.filterOverflow = function() {
            let totalFilters = 0;
            _.forEach($scope.contentFilters, function(filter) {
                if (filter.contentType == $scope.policy.contentType) {
                    totalFilters += 1;
                }
            });
            return totalFilters >= 20;
        };

        $scope.saveContentFilter = function() {
            if ($scope.isFilterNew) {
                $scope.contentFilters.unshift($scope.editFilter);
            } else {
                $scope.contentFilters.splice($scope.activeIndex, 1, $scope.editFilter);
            }

            $scope.form.$setDirty();
            $scope.filterDialogOpen = false;
        };

        $scope.removeContentFilter = function(index) {
            _.pullAt($scope.contentFilters, index);
            $scope.form.$setDirty();
        };

        $scope.isNotBuiltin = type => {
            return _.size(_.split(type, '.')) === 2;
        };

        $scope.isExtendedProperty = schemaItem => {
            return schemaItem.extends && !schemaItem.name.startsWith(schemaItem.extends);
        };

        $scope.getSchemaItemName = schemaItem => {
            return $scope.isExtendedProperty(schemaItem)
                ? schemaItem.extends + '.' + schemaItem.name
                : schemaItem.name;
        };
    }
})();
