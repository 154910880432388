'use strict';
/**
 * validation error is used to format the error message of a field form
 *
 * @export
 * @class Controller
 */

import _ from 'lodash';

export default class Controller {
    static get $inject() {
        return ['$scope'];
    }

    constructor($scope) {
        this.scope = $scope;
        this.scope.init = this.init.bind(this);

        //Listen to the changes on the form
        let formWatcher = this.scope.$watch('form', function() {
            $scope.init($scope.form, $scope.formErrors);
        });

        this.scope.$on('$destroy', function() {
            formWatcher();
        });
    }

    init(form, formErrors) {
        let errorString = 'Oops! Looks like some fields are missing or incorrect.<br>';
        //Get the controls of the angular form
        const controls = _.get(form, '$$controls');

        //iterate over the form controls and test each error
        _.forEach(controls, value => {
            let errorStr;

            //the error object
            const error = _.get(value, '$error', {});
            const formKey = _.get(value, '$$attr.formKey');
            const customError = _.get(formErrors, [formKey]);
            // if there is a custom error, use it
            if (!_.isEmpty(customError)) {
                errorString = `${errorString}${customError}<br>`;
            } else if (!_.isEmpty(error)) {
                //extract the keys of error object. May be multiple
                const errorKeys = _.keys(error);
                _.forEach(errorKeys, key => {
                    let errorMsg = '';
                    //format the message for some known errors. If the error is not on of the selected errors
                    //then show the key as incorrect.
                    switch (key) {
                        case 'required':
                            errorMsg = 'is missing';
                            break;
                        case 'maxlength':
                            errorMsg = 'exceeds the maximum length';
                            break;
                        case 'minlength':
                            errorMsg = 'is not minimum length';
                            break;
                        default:
                            errorMsg = `${key} is not correct`;
                    }
                    //Format the string with the place holder string followed by the formatted message.
                    errorStr = `${_.get(value, '$$attr.placeholder', 'field')} ${errorMsg}`;
                    errorString = `${errorString}${errorStr}<br>`;
                });
            }
        });
        this.scope.error = errorString;
    }
}
