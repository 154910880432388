'use strict';
import _ from 'lodash';

export default class ActionItemAvatar {
    constructor() {}
    static actionItemAvatarToolTip(assignees) {
        const takeSize = 3;
        const prefix = `Assigned to`;
        const sortedAssignees = _.sortBy(assignees, ['name', 'email']);
        const assigneeSize = _.size(assignees);

        if (assigneeSize === 1) {
            return `${prefix} ${getAssigneeLabel(sortedAssignees, 0)}`;
        }

        if (assigneeSize === 2) {
            return `${prefix} ${getAssigneeLabel(sortedAssignees, 0)} and ${getAssigneeLabel(
                sortedAssignees,
                1
            )}`;
        }

        let toolTip = `${prefix} ${_.map(_.take(sortedAssignees, takeSize - 1), (item, index, arr) =>
            getAssigneeLabel(arr, index)
        )
            .sort()
            .join(', ')}`;

        if (assigneeSize > takeSize) {
            toolTip = `${toolTip}, ${getAssigneeLabel(sortedAssignees, takeSize - 1)}, and ${assigneeSize -
                takeSize} more`;
        } else {
            toolTip = `${toolTip}, and ${getAssigneeLabel(sortedAssignees, takeSize - 1)}`;
        }

        return toolTip;
    }

    static getInitials(name) {
        let parts = _.split(name, ' ');
        if (parts && parts.length >= 2) {
            const firstName = _.first(parts);
            const lastName = _.last(parts);
            return _.toUpper(`${_.first(firstName)}${_.first(lastName)}`);
        } else {
            return _.toUpper(_.take(name, 2).join(''));
        }
    }
}

const getAssigneeLabel = (assignees, index) =>
    _.get(assignees, [index, 'name'], _.get(assignees, [index, 'email']));
