'use strict';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('expandableRichText', [
    '$timeout',
    $timeout => ({
        restrict: 'E',
        scope: {
            htmlValue: '=?',
            descriptionId: '='
        },
        template: template,
        link: ($scope, elems) => {
            const DEFAULT_HEIGHT = '35px';
            let _el = elems[0];
            const querySel = `.rich-text-container-${$scope.descriptionId}`;

            let viewMore = function() {
                let el = _el.querySelector(querySel);
                let elContainer = $(querySel);
                const offsetHeight = el.offsetHeight;
                const scrollHeight = el.scrollHeight;

                if (offsetHeight + 2 < scrollHeight) {
                    $scope.show = true;
                    $scope.showMoreBtn = true;
                } else {
                    $scope.show = false;
                    elContainer.css('height', 'auto');
                }
            };

            //Initialize the view
            $timeout(function() {
                viewMore();
                $scope.$apply();
            }, 0);

            //Toggle the show more content, and removes the more content indicator
            $scope.showMore = function() {
                let el = $(querySel);
                const curHeight = el.height();
                const autoHeight = el.css('height', 'auto').height();

                el.removeClass('more-indicator');
                el.height(curHeight).animate({ height: autoHeight }, 300, function() {
                    el.css('height', 'auto');
                });
                $scope.showMoreBtn = false;
            };

            //Toggle the show less content, and adds the more content indicator
            $scope.showLess = function() {
                let el = $(querySel);
                el.animate({ height: DEFAULT_HEIGHT }, 250);
                $scope.showMoreBtn = true;
            };
        }
    })
]);
