(function() {
    'use strict';

    angular.module('serviceApp').factory('boardsUtilsService', BoardsUtilsService);

    BoardsUtilsService.$inject = ['_', 'userServiceNew', '$state'];

    function BoardsUtilsService(_, userServiceNew, $state) {
        var factory = {
            openBoard: openBoard,
            openBoardManager: openBoardManager
        };

        return factory;

        ////////////////

        function openBoard(board, clearSearch) {
            $state.go('boards.board.panel', {
                id: board.id,
                board: undefined,
                panel: _.get(board, 'panels[0].name', undefined),
                overwriteReload: false,
                query: undefined,
                destinationTime: undefined
            });
        }

        function openBoardManager(board) {
            $state.go('boards.settings', {
                id: board.id,
                board: undefined,
                overwriteReload: false,
                query: undefined,
                destinationTime: undefined
            });
        }
    }
})();
