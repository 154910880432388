(function() {
    'use strict';

    angular.module('serviceApp').controller('DefinitionAdminController', DefinitionAdminController);

    DefinitionAdminController.$inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        'searchDefinitionsResponse',
        'user',
        'team',
        'displayOptions'
    ];

    function DefinitionAdminController(
        $scope,
        $rootScope,
        $stateParams,
        searchDefinitionsResponse,
        user,
        team,
        displayOptions
    ) {
        $scope.searchDefinitionsResponse = searchDefinitionsResponse;
        $scope.definitions = _.sortBy(_.get($scope.searchDefinitionsResponse, 'results'), 'name');
        $scope.user = user;
        $scope.team = team;

        $rootScope.displayOptions = displayOptions;

        $scope.board = {
            type: 'definitionSettings',
            title: 'Definition Management',
            noControls: true,
            state: {},
            panels: [
                {
                    title: 'Definition Management',
                    name: 'definitionManagement',
                    rows: {
                        main: {
                            position: 1
                        }
                    }
                }
            ]
        };

        $scope.board.state.currentPanel = _.get($scope, 'board.panels[0].name');

        $scope.panels = {
            definitionManagement: [
                {
                    title: 'Definitions',
                    type: 'definitionManagement',
                    row: 'main',
                    position: 1,
                    size: {
                        x: 12
                    },
                    properties: {
                        searchDefinitionsResponse: searchDefinitionsResponse,
                        definitions: $scope.definitions,
                        type: $stateParams.type
                    }
                }
            ]
        };

        $scope.cards = _.get($scope, 'panels[' + $scope.board.state.currentPanel + ']', []);
    }
})();
