(function() {
    'use strict';

    angular.module('serviceApp').directive('appBrowseItem', appBrowseItem);

    function appBrowseItem() {
        var directive = {
            restrict: 'E',
            scope: {
                app: '=',
                enabled: '=?'
            },
            template: require('views/tmpl/partials/appBrowseItem.html'),
            controller: AppBrowseItemController
        };
        return directive;
    }

    AppBrowseItemController.$inject = ['$scope', '$state', '$rootScope', 'appServiceNew'];

    function AppBrowseItemController($scope, $state, $rootScope, appServiceNew) {
        $scope.divId = _.kebabCase(_.get($scope, 'app.title'));
        $scope.productName = $rootScope.productName;
        $scope.isAdded = !!(
            $scope.app.teamId != '##GLOBAL##' &&
            ($scope.app.events == 'team' || $scope.app.events == 'individual')
        );

        $scope.openApp = function(app, panel) {
            $state.go('boards.settings', {
                board: app.rootDomain,
                panel: panel
            });
        };

        $scope.toggleAppOptions = function() {
            $scope.showAppOptions = !$scope.showAppOptions;
        };

        $scope.updateApp = function(event) {
            $scope.app.events = event;
            //ensure there is no content policy URL pattern for the last policy
            //we must have a catchall policy to prevent issues server side
            $scope.app.contentPolicies[$scope.app.contentPolicies.length - 1].urlPattern = '';

            appServiceNew.updateApp($scope.app).then(
                function(updatedApp) {},
                function(error) {}
            );
        };

        $scope.addApp = function() {
            appServiceNew.addAppToTeam($scope.app.id).then(function(app) {
                $scope.app = app;
                $scope.isAdded = !!(
                    $scope.app.teamId != '##GLOBAL##' &&
                    ($scope.app.events == 'team' || $scope.app.events == 'individual')
                );
            });
        };

        $scope.disableApp = function() {
            appServiceNew.disableApp($scope.app.id).then(function(app) {
                $scope.app = app;
                $scope.isAdded = !!(
                    $scope.app.teamId != '##GLOBAL##' &&
                    ($scope.app.events == 'team' || $scope.app.events == 'individual')
                );
            });
        };
    }
})();
