'use strict';
import moment from 'moment';

export default class ActionSnooze {
    static get $inject() {
        return ['$scope'];
    }
    constructor(scope) {
        this.scope = scope;

        this.scope.format = 'M/d/yy';
        this.scope.picker = { isOpen: false };

        this.scope.dateOptions = {
            startingDay: 1,
            snoozeDates: ActionSnooze.SNOOZE_DATES
        };

        this.scope.toggleSnooze = () => {
            this.scope.picker.isOpen = !this.scope.picker.isOpen;
        };
    }

    static get SNOOZE_DATES() {
        return [
            {
                label: 'In 3 hours',
                value: new Date(
                    moment()
                        .add(3, 'hours')
                        .toISOString()
                )
            },
            {
                label: 'Tomorrow',
                value: new Date(
                    moment()
                        .add(1, 'day')
                        .startOf('day')
                        .toISOString()
                )
            },
            {
                label: 'Next Week',
                value: new Date(
                    moment()
                        .add(7, 'days')
                        .startOf('isoweek')
                        .toISOString()
                )
            }
        ];
    }
}
