FilterContainerController.$inject = ['_', '$scope', 'definitionService', 'userServiceNew', '$filter'];

export default function FilterContainerController(_, $scope, definitionService, userServiceNew, $filter) {
    // For filter set definitions, all of the data should come from the Redux store.  For all other
    // definition types, we will provide the definition to the filter editor components.
    if (
        $scope.definition &&
        $scope.definition.definitionType &&
        $scope.definition.definitionType !== 'filter'
    ) {
        $scope.filterGroupEditorDefinition = $scope.definition;
        $scope.filterSetOnDefinitionUpdated = definition => {
            _.set(
                $scope,
                `filter.propertyValues.${$scope.sectionName}`,
                _.get(definition, `propertyValues.${$scope.sectionName}`)
            );
            _.set(
                $scope,
                `filter.propertySchema.${$scope.sectionName}`,
                _.get(definition, `propertySchema.${$scope.sectionName}`)
            );
            if (_.get(definition, `card.config.timeframeFilter`)) {
                _.set(
                    $scope,
                    `filter.card.config.timeframeFilter`,
                    _.get(definition, `card.config.timeframeFilter`)
                );
            }
            if (
                _.get($scope, `filter.card.config.timeframeFilter`) &&
                !_.get(definition, `card.config.timeframeFilter`)
            ) {
                _.unset($scope, `filter.card.config.timeframeFilter`);
            }
            $scope.eventHandler({
                event: '_filterChanged',
                data: { filter: $scope.filter }
            });
        };
    }

    //input filter is the filter passed to the directive
    $scope.$watch(
        'inputFilter',
        function() {
            $scope.cleanFilter = _.cloneDeep($scope.inputFilter);
            init($scope.inputFilter);
        },
        true
    );

    init();

    function init(inputFilter) {
        inputFilter = inputFilter || $scope.filter;
        const rootName = _.get($scope, 'definition.name');
        const initData = definitionService.initializeFilter(inputFilter, rootName, $scope.sectionName);

        $scope.filter = _.get(initData, 'filter');

        if (
            _.get($scope, 'inputFilter.userId') &&
            _.get($scope, 'user.id') !== _.get($scope, 'inputFilter.userId')
        ) {
            userServiceNew
                .getUserProfile({
                    userId: _.get($scope, 'inputFilter.userId')
                })
                .then(user => {
                    $scope.ownerName = _.get(user, 'profile.name');
                })
                .catch(e => {
                    console.error('Unable to get user profile', {
                        e: _.get(e, 'message', e),
                        userId: _.get($scope, 'inputFilter.userId')
                    });
                });
        } else {
            $scope.ownerName = undefined;
        }

        $scope.formModel = _.get(initData, 'formModel');
        $scope.formSchema = _.get(initData, 'formSchema');

        $scope.headerText = _.get($scope, ['formSchema', 'properties', '_display', 'headerText']);
        $scope.emptyHeaderText = _.get($scope, ['formSchema', 'properties', '_display', 'emptyHeaderText']);
    }
}
