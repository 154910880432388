'use strict';

/**
 * Angular wrapper for our <NavBar /> react component.
 */
export default class NavBar {
    static get $inject() {
        return ['$rootScope', '_', 'alertService'];
    }

    constructor($rootScope, _, alertService) {
        this.rootScope = $rootScope;
        this._ = _;
        this.alertService = alertService;

        this.supportLink = this.rootScope.productSupportHelp;
        this.trainingLink = this.rootScope.productTrainingHostname;
        this.isExternal = this.rootScope.isExternal;
    }

    $onInit() {
        // Props being passed to our React component
        this.data = {
            supportLink: this.supportLink,
            trainingLink: `${this.trainingLink}?email=${this.rootScope.user.email}`,
            showSolutions: this.showSolutions,
            isExternal: this.isExternal,
            isLeftDrawerOpen: this.isLeftDrawerOpen
        };

        this.loadAlerts();
    }

    $onChanges(changes) {
        if (this._.get(changes, 'isLeftDrawerOpen') && !changes.isLeftDrawerOpen.isFirstChange()) {
            this.isLeftDrawerOpen = changes.isLeftDrawerOpen.currentValue;
            this.data = { ...this.data, isLeftDrawerOpen: this.isLeftDrawerOpen };
        }
    }

    loadAlerts() {
        this.alertService.getAlerts().then(alertResponse => {
            const alerts = alertResponse.alerts;

            this.data = {
                ...this.data,
                alerts
            };
        });
    }
}
