(function() {
    'use strict';

    angular.module('serviceApp').directive('cardUserIntegrationSettings', cardUserIntegrationSettings);

    function cardUserIntegrationSettings() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=?',
                fetchCard: '=?',
                appId: '=?',
                closeContextCallback: '&?',
                dismissIntegrationCallback: '&?',
                executeEventTrigger: '&',
                refreshCallbackHitch: '&?',
                referrer: '=?',
                action: '=?',
                integrationType: '=?',
                actionType: '=?',
                user: '=?',
                horizontal: '=?', // indicates if coming from action carousel
                preview: '=?', // indicates if we are in action preview
                dismissDisabled: '=?', // indicates if the action can be dismissed
                embedded: '=?', // indicates if the prompt should be shown in a compact form
                external: '=?', //indicates this view is a stand alone external card view
                parent: '<' // indicates where this card is being rendered
            },
            template: require('views/tmpl/cards/integrations/userContainer.html'),
            controller: CardUserIntegrationSettingsController
        };

        return directive;
    }

    CardUserIntegrationSettingsController.$inject = [
        '_',
        '$scope',
        '$state',
        '$rootScope',
        '$stateParams',
        'toastr',
        'integrationService',
        'cardsUtilService',
        '$timeout',
        '$window'
    ];

    function CardUserIntegrationSettingsController(
        _,
        $scope,
        $state,
        $rootScope,
        $stateParams,
        toastr,
        integrationService,
        cardsUtilService,
        $timeout,
        $window
    ) {
        if (!$scope.card && $scope.fetchCard) {
            getCard();
        } else if ($scope.card) {
            init();
        }

        $scope.productName = $rootScope.productName;
        $scope.isMobile = $rootScope.isMobile;

        function init() {
            const builtInIcons = {
                bx: 'images/static/web_large.png'
            };

            $scope.repairAction = $scope.actionType === 'fix-user-integration';

            //This should go away once we have a google OAuth mobile solution
            $scope.supported = true;

            //Google OAUth is not supported in webviews(there may be others later?).
            //This way we can prevent user integration activation on unsupported types.
            if (
                $rootScope.isMobile &&
                _.includes(
                    $rootScope.unsupportMobileAuth,
                    _.get($scope.card, 'properties.app.integrationType', $scope.integrationType)
                )
            ) {
                $scope.supported = false;
            }

            $scope.userIntegration = {};
            $scope.userIntegration.form = _.clone(_.get($scope.card, 'properties.userSettings')) || {};
            $scope.showHelpModal = false;
            $scope.showFullSyncModal = false;
            $scope.showDangerOptions = false;
            $scope.totalShown = 0;
            $scope.isPending = false;
            $scope.submitting = false;
            $scope.iconUrl = _.get($scope.card, 'properties.app.iconURL');
            if (!$scope.iconUrl) {
                $scope.iconUrl = _.get(
                    builtInIcons,
                    _.get($scope.card, 'properties.app.integrationType', $scope.integrationType)
                );
            }
            isUserAssignee();
        }

        function isUserAssignee() {
            var lowerUserEmail = _.toLower(_.get($scope, 'user.email'));
            var assignees = _.cloneDeep(_.get($scope, 'action.assignees', []));

            $scope.userIsAssignee = !$scope.action || !!_.find(assignees, { email: lowerUserEmail });
            $scope.listAssignees = _(assignees)
                .map(function(assignee) {
                    var name;

                    if (assignee.email === lowerUserEmail) {
                        return;
                    }

                    name = assignee.name || `${assignee.email} (not in team)`;

                    if (name && assignee.currentState !== 'active') {
                        name = name + ' (' + _.startCase(assignee.currentState) + ')';
                    }

                    return name;
                })
                .compact()
                .join(', ');
        }

        function getCard() {
            return cardsUtilService
                .getCard({
                    panelName: 'userconnection',
                    panelGroup: 'settings',
                    cardName: 'userIntegrationSettings',
                    appId: $scope.appId
                })
                .then(
                    function(card) {
                        $scope.card = card;
                        init();
                    },
                    function(err) {
                        toastr.error('Unable to get integration info!', '');
                    }
                )
                .then(function() {
                    if (
                        $scope.fetchCard &&
                        _.get($scope, 'card.properties.userSettings.integration.state') == 'enabled'
                    ) {
                        $scope.showSuccessDialog = true;
                    } else {
                        $scope.showSuccessDialog = false;
                    }
                    $scope.submitting = false;
                });
        }

        if ($scope.appId) {
            $scope.$watch('appId', function(oldVal, newVal) {
                if (oldVal == newVal) {
                    return;
                }

                getCard();
            });
        }

        if ($stateParams.success !== null && $stateParams.success !== undefined) {
            if ($stateParams.success == 'true') {
                $scope.isPending = true;
                $stateParams.success = null;
                $timeout(function() {
                    $state.transitionTo(
                        $state.current.name,
                        {
                            panel: $stateParams.panel,
                            board: $stateParams.board,
                            success: null,
                            overwriteReload: true
                        },
                        {
                            location: 'replace',
                            notify: false
                        }
                    );
                    $timeout(function() {
                        $scope.refreshCallbackHitch();
                        $scope.isPending = false;
                    }, 100);
                }, 5000);
            } else {
                if (!_.isNil($stateParams.error)) {
                    toastr.error(decodeURIComponent($stateParams.error), '');
                } else {
                    toastr.error("Ugh, That didn't work", '');
                }
            }
        }

        $scope.isEmpty = function(object) {
            return _.isEmpty(object);
        };

        $scope.camelCase = function(key) {
            return _.startCase(key);
        };

        $scope.hasSettings = function() {
            var settingsCount = _.countBy(
                _.get($scope, 'card.properties.definition.userSchema'),
                'display.hidden'
            );
            return !!settingsCount['undefined'];
        };

        $scope.submitForm = function(isValid, form) {
            if ($rootScope.isExternal && $scope.action && !$rootScope.isMobile) {
                var url = $state.href('boards.settings', {
                    board: _.get($scope.card, 'properties.app.title'),
                    panel: 'userconnection'
                });
                $window.open(url, '_blank');
            } else if (isValid) {
                enableOrPostUserIntegration(form);
            } else {
                toastr.error('Invalid input, please fill fields correctly', '');
            }
        };

        $scope.dismissIntegrationAction = function() {
            $scope.executeEventTrigger({
                data: { eventTrigger: 'dismiss', action: $scope.action }
            });
        };

        $scope.deleteIntegration = function() {
            $scope.$root.$emit('integrationDeleted', $scope.card.properties.integration.id); // $rootScope.$on
            integrationService
                .deleteUserIntegration($scope.card.properties.integration.id)
                .then(integrationAPISuccess, integrationAPIFailure);
            $scope.userIntegration.form = {};
        };

        $scope.fullSync = function() {
            return integrationService
                .syncUserIntegration($scope.card.properties.integration.id, {
                    syncReset: true
                })
                .then(
                    function() {
                        toastr.success('Integration Sync Started', '');
                        $scope.showFullSyncModal = false;
                    },
                    function(error) {
                        toastr.error('An error occurred', error);
                    }
                );
        };

        $scope.toggleHelpModal = function(helpText) {
            $scope.helpText = helpText || '';
            $scope.showHelpModal = !$scope.showHelpModal;
        };

        $scope.toggleFullSyncModal = function() {
            $scope.showFullSyncModal = !$scope.showFullSyncModal;
        };

        $scope.toggleDangerOptions = function() {
            $scope.showDangerOptions = !$scope.showDangerOptions;
        };

        $scope.openAppSettings = function(app) {
            $state.go('boards.settings', {
                board: app.title,
                panel: 'userManagement'
            });
        };

        $scope.closeDrawer = function() {
            if ($scope.parent === 'leftDrawer') {
                $scope.$root.$emit('leftDrawer:close');
            } else {
                $scope.$root.$emit('rightDrawer:close');
            }
        };

        function enableOrPostUserIntegration(form) {
            $scope.submitting = true;
            _.unset($scope, 'userIntegration.form.integration');

            angular.forEach($scope.userIntegration.form, function(value, key) {
                if (value === '##ENCRYPTED##') {
                    delete $scope.userIntegration.form[key];
                }
            });

            if (_.get($scope.card, 'properties.userSettings.integration.state') == 'enabled') {
                return integrationService
                    .editUserIntegration($scope.card.properties.integration.id, $scope.userIntegration.form)
                    .then(updatedIntegration => {
                        integrationAPISuccess(updatedIntegration);
                    })
                    .catch(e => {
                        integrationAPIFailure(e);
                    })
                    .then(() => {
                        form.$setPristine();
                        form.$setUntouched();
                    });
            } else {
                // If a referrer value is passed in via a state param, override any referrer embedded in the directive.
                // This could happen if we got here via the user profile page, for example.
                const stateReferrer = _.get($stateParams, 'referrer');
                if (stateReferrer) {
                    $scope.referrer = stateReferrer;
                }

                if ($scope.userIntegration.form && $scope.referrer) {
                    $scope.userIntegration.form.referrer = $scope.referrer;
                }

                return integrationService
                    .enableUserIntegration(
                        $scope.card.properties.integration.id,
                        $scope.userIntegration.form,
                        null,
                        _.get($scope.action, 'id')
                    )
                    .then(enabledIntegration => {
                        integrationAPISuccess(enabledIntegration);
                    })
                    .catch(e => {
                        return integrationAPIFailure(e);
                    })
                    .then(() => {
                        form.$setPristine();
                        form.$setUntouched();
                    });
            }
        }

        function integrationAPISuccess(integration) {
            toastr.success('Integration updated!', '');
            if (integration.authorizationUrl) {
                $window.location = integration.authorizationUrl; //Being oauth flow
            } else if ($scope.action) {
                // if the integration is not enabled at this point alert the user something is wrong
                if (_.get(integration, 'state') !== 'enabled') {
                    toastr.error('An error occurred', 'Unable to enable integration please contact support');
                    return;
                }

                $scope.dismissIntegrationAction();
            } else {
                _.delay(function() {
                    if ($scope.fetchCard) {
                        getCard();
                    } else {
                        $scope.refreshCallbackHitch();
                    }
                }, 1000);
            }
        }

        function integrationAPIFailure(error) {
            toastr.error('An error occurred', error.msg);
            $scope.submitting = false;
        }
    }
})();
