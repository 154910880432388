'use strict';

export default class CardDebugContentController {
    static get $inject() {
        return ['_', '$scope', '$rootScope'];
    }

    constructor(_, $scope, $rootScope) {
        const CARD_NAME = _.get($scope, 'card.name');

        $scope.searchHelpURL = `https://${$rootScope.productSupportHostName}/hc/en-us/articles/13345239090075-Intelligence360-s-Search-Capability`;

        $scope.refreshData = () => {
            $scope.getCardHitch({
                data: { query: _.get($scope, 'query'), cardName: CARD_NAME, facets: _.get($scope, 'facets') }
            });
        };

        $scope.pageBack = () => {
            const cardSize = _.get($scope, 'card.properties.debugContent.size');
            const currentOffset =
                _.get($scope, `facets.card.${CARD_NAME}.offset`) ||
                _.get($scope, 'card.properties.debugContent.offset');
            const newOffset = Math.max(currentOffset - cardSize, 0);

            _.set($scope, `facets.card.${CARD_NAME}.offset`, newOffset);

            $scope.refreshData();
        };

        $scope.pageNext = () => {
            const cardSize = _.get($scope, 'card.properties.debugContent.size');
            const currentOffset =
                _.get($scope, `facets.card.${CARD_NAME}.offset`) ||
                _.get($scope, 'card.properties.debugContent.offset');
            const newOffset = currentOffset + cardSize;

            _.set($scope, `facets.card.${CARD_NAME}.offset`, newOffset);

            $scope.refreshData();
        };

        $scope.showColumn = column => {
            if (!column.reason) {
                return true;
            } else if (column.reason === 'debug: hierarchy') {
                return _.get($scope, 'hierarchy') && !_.isNil($scope.hierarchy.definitionId);
            }
            return true;
        };

        $scope.getField = (content, property) => {
            return _.get(content, property);
        };

        $scope.whyIncludes = (why, reason) => {
            return _.includes(why, reason);
        };

        $scope.getColumnClass = column => {
            if (column.reason) {
                return 'reason-col';
            }
            return;
        };
    }
}
