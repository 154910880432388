'use strict';

/**
 * @ngdoc function
 * @name serviceApp.controller:PagesForgotPasswordCtrl
 * @description
 * # PagesForgotPasswordCtrl
 * Controller of the serviceApp
 */
angular.module('serviceApp').controller('ForgotPasswordCtrl', [
    '$scope',
    '$rootScope',
    '$state',
    '$timeout',
    'userServiceNew',

    function($scope, $rootScope, $state, $timeout, userServiceNew) {
        $scope.credentials = {};
        $scope.hasError = false;
        $scope.hasSuccess = false;
        $rootScope.useHeader = false;
        $scope.sendToken = function() {
            var data = {
                send_token_to: $scope.credentials.email
            };

            userServiceNew.sendPasswordResetToken(data).then(
                function() {
                    $scope.hasSuccess = true;
                    $timeout(function() {
                        $state.go('core.login');
                    }, 3000);
                },
                function(error) {
                    $scope.hasError = true;
                }
            );
        };

        $scope.toLogin = function() {
            return $state.go('core.login');
        };

        $scope.closeError = function() {
            $scope.hasError = false;
        };

        $scope.closeSuccess = function() {
            $scope.hasSuccess = false;
        };
    }
]);
