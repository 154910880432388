'use strict';

export default class Controller {
    // angular dependency injector
    static get $inject() {
        return [
            '_',
            '$scope',
            'moment',
            'userServiceNew',
            'teamServiceNew',
            'toastr',
            '$state',
            '$rootScope'
        ];
    }
    constructor(_, $scope, $moment, userServiceNew, teamServiceNew, toastr, $state, $rootScope) {
        this.scope = $scope;
        this.scope.logout = this.logout.bind(this);
        this.scope.agree = this.agree.bind(this);

        this.userServiceNew = userServiceNew;
        this.toastr = toastr;
        this.state = $state;
        this.rootScope = $rootScope;
        $scope.needsAgreement = false;

        //Check user agreement after each state change for an updated user agreement
        $rootScope.$on('$stateChangeSuccess', function() {
            init();
        });

        function init() {
            if (_.get($scope.team, 'userAgreement.enabled')) {
                const teamAgreement = _.get($scope.team, 'userAgreement.updated');
                const userAgreement = _.get($scope.user, 'userAgreement.accepted');

                if (!userAgreement || $moment(teamAgreement).isAfter(userAgreement)) {
                    $scope.needsAgreement = true;
                }
            }
        }

        init();
    }

    logout() {
        const state = this.state;
        const rootScope = this.rootScope;
        const scope = this.scope;
        this.userServiceNew.logOut().then(function() {
            rootScope.$emit('logout');
            state.go('core.login');
            scope.needsAgreement = false;
        });
    }

    agree() {
        const scope = this.scope;
        const toastr = this.toastr;
        var updateUserProfilePayload = {
            userAgreement: {
                accepted: Date.now()
            }
        };

        this.userServiceNew.updateUserData(updateUserProfilePayload).then(
            function() {
                scope.needsAgreement = false;
                toastr.success('User Agreement Accepted', '');
            },
            function(error) {
                toastr.error('An Error Occurred', error.msg);
            }
        );
    }
}
