(function() {
    'use strict';

    angular.module('serviceApp').filter('durationInYears', durationInYears);

    durationInYears.$inject = ['_', 'moment', '$filter'];

    function durationInYears(_, $moment, $filter) {
        const filter = (milliseconds, formatAsNumber = true) => {
            if (_.isNil(milliseconds)) {
                return formatAsNumber ? 'N/A' : undefined;
            }

            const years = _.round($moment.duration(milliseconds).asYears());
            let yearsAsNumber = `${$filter('number')(years)} year`;
            if (years !== 1) {
                yearsAsNumber = $filter('pluralize')(yearsAsNumber);
            }
            return formatAsNumber ? yearsAsNumber : years;
        };

        return filter;
    }
})();
