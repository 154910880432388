'use strict';
import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('cardActionStats', () => ({
    restrict: 'E',
    scope: {
        card: '=',
        board: '=',
        panel: '=',
        user: '<',
        team: '<',
        query: '=?',
        external: '=?',
        refreshCallbackHitch: '&'
    },
    template,
    controller: Controller
}));
