(function() {
    'use strict';
    angular.module('serviceApp').factory('typeAheadService', TypeAheadService);

    TypeAheadService.$inject = ['_', '$resource', '$q', '$http'];

    function TypeAheadService(_, $resource, $q, $http) {
        var url = '/api/type-ahead';
        var defaultParams = {};

        var typeAheadResourceApi = {
            typeAhead: { method: 'POST', url: url, isArray: true },
            hydrate: { method: 'POST', url: url + '/hydrate', isArray: true }
        };

        var typeAheadResource = $resource(url, defaultParams, typeAheadResourceApi);

        var service = {
            typeAhead: typeAhead,
            hydrate: hydrate
        };

        return service;

        ////////////
        // args consists of { query, filter, fields, pick, type, boardId, queryProperty, definitionId }
        function typeAhead(args = {}) {
            if (_.isEmpty(args.fields)) {
                return $q.reject({
                    msg: 'Fields must be defined'
                });
            }

            return $q.when(typeAheadResource.typeAhead({}, args).$promise);
        }

        function hydrate(dataList, queryField, pick, type, filter) {
            if (_.isEmpty(dataList)) {
                return $q.resolve([]);
            }

            if (_.isEmpty(queryField)) {
                return $q.reject({
                    msg: 'queryField must be defined'
                });
            }

            return $q.when(
                typeAheadResource.hydrate(
                    {},
                    {
                        queryField: queryField,
                        dataList: dataList,
                        pick: pick,
                        type: type,
                        ...(!_.isNil(filter) && {
                            filter
                        })
                    }
                ).$promise
            );
        }
    }
})();
