'use strict';

export default class CardUserActiveSettings {
    static get $inject() {
        return ['_', '$scope', 'deviceDetector', '$window', '$state', 'integrationService', 'toastr'];
    }

    constructor(_, $scope, deviceDetector, $window, $state, integrationService, toastr) {
        $scope.activeApps = _.get($scope.card, 'properties.activeApps');
        $scope.autoActions = _.get($scope.card, 'properties.autoActions');
        $scope.teamApps = _.get($scope.card, 'properties.availableApps');

        if (deviceDetector.browser === 'chrome' || deviceDetector.browser === 'firefox') {
            $scope.supportedBrowser = true;
        }

        $scope.appState = function(app, userIntegrations) {
            if (!userIntegrations) {
                return app.lastUpdateError
                    ? { error: app.lastUpdateError, state: app.lastUpdateError }
                    : { state: 'enabled' };
            }

            var integration = _.find(userIntegrations, ['integrationId', app.integrationId]);
            if (integration) {
                return {
                    state: integration.state,
                    warning: integration.lastRunWarning,
                    error: integration.lastRunError
                };
            }
            return null;
        };

        $scope.openApp = function(app) {
            $state.go('boards.settings', {
                board: app.rootDomain,
                panel: 'userconnection',
                referrer: 'userProfile'
            });
        };

        $scope.syncIntegration = function(integrationId, isFullSync) {
            var options = {};

            if (isFullSync) {
                _.set(options, 'syncReset', true);
            }

            integrationService.syncUserIntegration(integrationId, options).then(
                function(data) {
                    toastr.success('Integration sync attempted, refreshing.', '');
                    $scope.refreshCallbackHitch();
                },
                function(err) {
                    toastr.error('An error occurred', err);
                }
            );
        };

        $scope.deleteIntegration = function(integrationId) {
            integrationService.deleteUserIntegration(integrationId).then(
                function(data) {
                    toastr.success('Integration deleted, refreshing.', '');
                    //This need more than a card refresh, it needs a user refresh and board. Do a full transition
                    $state.go('boards.board.panel', {
                        board: 'profile',
                        panel: 'userProfile',
                        overwriteReload: false
                    });
                },
                function(err) {
                    toastr.error('An error occurred', err);
                }
            );
        };
    }
}
