(function() {
    'use strict';
    angular.module('serviceApp').directive('formMultiSelect', formMultiSelect);
    function formMultiSelect() {
        var directive = {
            restrict: 'A',
            scope: {
                formModel: '=formMultiSelect',
                form: '='
            },
            template: require('views/tmpl/partials/forms/formMultiSelect.html'),
            controller: FormMultiSelectController
        };

        return directive;
    }

    FormMultiSelectController.$inject = ['_', '$scope', '$filter', '$rootScope', 'translateValuesService'];

    function FormMultiSelectController(_, $scope, $filter, $rootScope, translateValuesService) {
        // Wait until the form model is initialized before updating the form selections.
        // When using `default` to populate the selections, it seems that we need to
        // wait a tick before the model is updated to reflect the default values.
        $scope.$watch('formModel', () => {
            return init();
        });

        function init() {
            $scope.removable = _.get($scope, 'form.schema.removable', true);
            $scope.requiredDefault = _.get($scope, 'form.schema.requiredDefault', false);
            $scope.reversible = _.get($scope, 'form.schema.reversible', true);
            $scope.allowEmpty = _.get($scope, 'form.schema.allowEmpty', false);
            $scope.options = {
                includes: _.get($scope, 'form.schema.includes', true),
                valueFormat: _.get($scope, 'form.schema.valueFormat', 'includes')
            };
            $scope.valueProperty = _.get($scope, 'form.schema.format.valueProperty');
            $scope.wrapPliable = _.get($scope, 'form.schema.wrapPliable', true);

            // Create a different path when working with reversible properties
            $scope.basePath = $scope.wrapPliable ? ['formModel', $scope.valueProperty] : ['formModel'];

            if ($scope.wrapPliable && _.get($scope, ['formModel', '##NOT##', $scope.valueProperty])) {
                $scope.basePath = ['formModel', '##NOT##', $scope.valueProperty];
                $scope.options.includes = false;
            }

            if (
                $scope.wrapPliable &&
                _.get($scope, 'form.schema.type') === 'boolean' &&
                _.isArray(_.get($scope, ['formModel', '##OR##']))
            ) {
                // backwards compatibility for form boolean widgets requires us to make form-boolean form models consumable by multi-select.
                $scope.formModel[$scope.valueProperty] = _.map(
                    _.get($scope, ['formModel', '##OR##']),
                    `${$scope.valueProperty}`
                );

                // Remove the old formModel so that changes are persisted.
                _.unset($scope, ['formModel', '##OR##']);
            }

            const data = _.get($scope, $scope.basePath);
            if ($scope.form.schema.allowIncludeMissing === false) {
                _.remove(data, function(d) {
                    return d === '##MISSING##';
                });
                _.remove($scope.form.schema.enum, function(d) {
                    return d[$scope.valueProperty] === '##MISSING##';
                });
            }

            _.remove($scope.form.schema.enum, function(d) {
                return (
                    d[$scope.valueProperty] === null ||
                    d[$scope.valueProperty] === '' ||
                    d[$scope.valueProperty] === undefined
                );
            });

            const includes = _.get($scope, 'options.includes');
            const valueFormat = _.get($scope, 'options.valueFormat');

            $scope.enum = _.map(_.get($scope, 'form.schema.enum'), function(enumItem) {
                // We can either store explicit true/false selections for each entry in the enum,
                // or an array of the selected items / array of the unselected items
                if (valueFormat === 'selections') {
                    const itemValueProp = enumItem[$scope.valueProperty];
                    let itemValue = _.find(data, d => {
                        return d[$scope.valueProperty] === itemValueProp;
                    });
                    enumItem._isSelected = _.get(itemValue, 'selected');
                } else {
                    const saveUnselected = includes === false && $scope.wrapPliable === false;
                    if (saveUnselected) {
                        // Select all if data is nil, otherwise inverse the selections from the stored data
                        enumItem._isSelected = _.isNil(data)
                            ? true
                            : _.indexOf(data, enumItem[$scope.valueProperty]) === -1;
                    } else {
                        enumItem._isSelected = _.indexOf(data, enumItem[$scope.valueProperty]) !== -1;
                    }
                }

                const titleProperty = _.get($scope, 'form.schema.format.titleProperty');
                if (titleProperty) {
                    enumItem.displayLabel = enumItem[titleProperty];
                } else {
                    const propertyName = _.get($scope, 'form.schema.format.valueProperty');
                    const propertyValue = enumItem[propertyName];

                    // If we have a custom format configured for the given property try translating the value
                    enumItem.displayLabel =
                        propertyValue === '##MISSING##'
                            ? propertyValue
                            : translateValuesService.translateValue({
                                  contentType: $scope.form.schema.contentType,
                                  propertyName,
                                  propertyValue
                              });
                }

                return enumItem;
            });

            $scope.enum = _.uniqBy($scope.enum, $scope.valueProperty);
        }

        $scope.updateList = function() {
            // If includes is false, and wrapPliable is false, we will invert the selection
            // manually. When saving with pliable, the query is inverted with a NOT when
            // includes is false.
            const includes = _.get($scope, 'options.includes');
            const valueFormat = _.get($scope, 'options.valueFormat');
            let values;

            const saveUnselected = includes === false && $scope.wrapPliable === false;

            if (!$scope.formModel) {
                $scope.formModel = {};
            }

            if (valueFormat === 'selections') {
                const valueProp = $scope.valueProperty;
                values = _($scope.enum)
                    .map(enumItem => {
                        let val = {};
                        _.set(val, valueProp, _.get(enumItem, valueProp));
                        _.set(val, 'selected', !!enumItem._isSelected);
                        return val;
                    })
                    .value();

                // Delete the values by sending a null if needed
                _.set($scope, $scope.basePath, _.isEmpty(values) ? null : values);
            } else {
                values = _($scope.enum)
                    .filter(enumItem => {
                        const selected = enumItem._isSelected;
                        return saveUnselected ? !selected : selected;
                    })
                    .map($scope.valueProperty)
                    .value();

                // Delete the values by sending a null if needed
                _.set($scope, $scope.basePath, _.isEmpty(values) ? null : values);
            }

            if (_.isEmpty(_.get($scope, $scope.basePath)) && !$scope.allowEmpty) {
                $scope.formModel = undefined;
            }
        };

        $scope.updateIncludes = function() {
            if (!$scope.wrapPliable) {
                return;
            }
            // Invert the selection with a query when wrapPliable is true.
            const includes = _.get($scope, 'options.includes');
            if (includes) {
                if (_.includes($scope.basePath, '##NOT##')) {
                    const data = _.get($scope, $scope.basePath);
                    _.unset($scope, $scope.basePath);
                    $scope.basePath = ['formModel', $scope.valueProperty];
                    _.set($scope, $scope.basePath, data);
                }
            } else {
                if (!_.includes($scope.basePath, '##NOT##')) {
                    const data = _.get($scope, $scope.basePath);
                    _.unset($scope, $scope.basePath);
                    $scope.basePath = ['formModel', '##NOT##', $scope.valueProperty];
                    _.set($scope, $scope.basePath, data);
                }
            }
        };

        $scope.removeSelf = function() {
            $scope.$emit('removeFilterItem', { property: _.first(_.get($scope, 'form.key', [])) });
        };

        // logic to block users from unselecting all values
        $scope.disableCursor = dataItem => {
            if ($scope.allowEmpty) {
                return false;
            }

            // only block input for reports
            if (!$scope.wrapPliable) {
                return false;
            }

            // if the item is already not selected allow it to be selected
            if (!_.get(dataItem, '_isSelected')) {
                return false;
            }

            const totalSelected = _.filter($scope.enum, '_isSelected');
            // if this is the only selected data item don't allow it to be unselected
            if (_.size(totalSelected) === 1) {
                return true;
            }

            return false;
        };
    }
})();
