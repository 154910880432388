(function() {
    'use strict';

    angular.module('serviceApp').directive('cardBoardInvite', cardBoardInvite);

    function cardBoardInvite() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                user: '<',
                team: '<',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/settings/invite.html'),
            controller: CardBoardInviteController
        };

        return directive;
    }

    CardBoardInviteController.$inject = ['$scope', '$log', 'toastr', 'boardServiceNew', 'teamServiceNew'];
    function CardBoardInviteController($scope, $log, toastr, boardServiceNew, teamServiceNew) {
        //allow only team owners OR private board owners to invite to the board.
        if (($scope.user.boards && _.indexOf($scope.user.boards, $scope.board.id)) || $scope.user.isOwner) {
            boardServiceNew.getBoardMembers($scope.board.id).then(function(members) {
                $scope.members = members;
            });

            teamServiceNew.getTeamMembers($scope.user.state.currentTeam).then(function(members) {
                $scope.team.members = members;
            });
        } else {
            $scope.isHidden = true;
        }

        $scope.currentBoard = $scope.board;
        $scope.newBoardMember = '';

        $scope.inviteMemberToBoard = function(email, board, form) {
            for (var i = 0; i < $scope.members.length; i++) {
                if ($scope.members[i].email == email) {
                    toastr.error('User is already a member', '');
                    form.$setPristine();
                    form.$setUntouched();
                    this.newBoardMember = '';
                    return;
                }
            }

            boardServiceNew.addBoardMember(board.id, email).then(
                function(member) {
                    $log.debug('Added Board Member: ' + email);

                    form.$setPristine();
                    form.$setUntouched();
                    $scope.newBoardMember = '';

                    toastr.success('Member Added!', '');
                },
                function(error) {
                    toastr.error('Adding member failed: ' + error.data.error, '');
                }
            );
        };

        $scope.filterBoardType = function(prop, val) {
            return function(board) {
                return board[prop] == val;
            };
        };
    }
})();
