(function() {
    'use strict';

    angular.module('serviceApp').filter('currency', currency);

    currency.$inject = ['_', '$filter'];

    function currency(_, $filter) {
        const filter = function(n, options = {}) {
            if (_.isNil(n)) {
                return 'N/A';
            }

            const locale = _.get(options, 'locale') || _.get(this, 'locale') || 'en-US';
            const currency = _.get(options, 'currency') || _.get(this, 'currency') || 'USD';
            const minimumFractionDigits =
                _.get(options, 'minimumFractionDigits') || _.get(this, 'minimumFractionDigits') || 0;
            const maximumFractionDigits =
                _.get(options, 'maximumFractionDigits') || _.get(this, 'maximumFractionDigits') || 0;
            return new Intl.NumberFormat(locale, {
                style: 'currency',
                currency,
                minimumFractionDigits,
                maximumFractionDigits
            }).format(n);
        };

        return filter;
    }
})();
