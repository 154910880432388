'use strict';
import Controller from './controller';
import template from './index.html';
import './index.scss';

angular.module('serviceApp').directive('actionItemStandard', () => ({
    restrict: 'E',
    scope: {
        embedded: '=?',
        user: '=?',
        action: '=',
        executeEventTrigger: '&',
        removeCompletedAction: '&',
        toggleContext: '&',
        definition: '=?',
        first: '=?',
        horizontal: '=?',
        preview: '=?',
        type: '=?',
        isSelected: '=?'
    },
    template,
    controller: Controller
}));
