'use strict';

import _ from 'lodash';

export default class Controller {
    // angular dependency injector
    static get $inject() {
        return ['$scope'];
    }
    constructor($scope) {
        this.scope = $scope;

        Object.assign(this.scope, {
            addItem: this.addItem.bind(this),
            selectItem: this.selectItem.bind(this),
            removeItem: this.removeItem.bind(this)
        });
    }

    addItem() {
        if (!_.isEmpty(this.scope.itemList) && _.isEmpty(this.scope.itemList[0])) {
            return;
        }
        this.scope.itemList.unshift('');
        this.scope.selectedIndex = 0;
    }

    selectItem(newIndex) {
        this.scope.selectedIndex = newIndex;
    }

    removeItem(index) {
        _.pullAt(this.scope.itemList, index);
        this.scope.form.$setDirty();
    }
}
