'use strict';

import './index.scss';
import template from './index.html';
import CardBase from '../common/CardBase';

angular.module('serviceApp').directive('cardInsightsTopContent', () => {
    return {
        scope: {
            board: '<',
            panel: '<',
            card: '<',
            user: '<',
            query: '=',
            filterData: '<',
            getCardHitch: '&',
            openDetailsHitch: '&',
            buildDownloadUrlHitch: '&',
            hierarchy: '<'
        },
        template: template,
        controller: CardBase
    };
});
