'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('searchInputExternal', {
    controller,
    template,
    bindings: {
        queryValue: '<',
        searchLabel: '<',
        onQuery: '&',
        variant: '<'
    }
});
