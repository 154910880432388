'use strict';

import './index.scss';
import template from './index.html';
import controller from './controller';

angular.module('serviceApp').directive('insightsAddCard', () => {
    return {
        scope: {
            row: '<'
        },
        template: template,
        controller
    };
});
