import _ from 'lodash';

/**
 * Return query with advanced query options transformed.
 * @param {Object} query - The query object.
 * @returns {Object} - A query with str and advancedQuery.
 */
export const getTransformedQuery = query => {
    const transformedQuery = _.cloneDeep(query);
    const isAdvanced = query && query.str ? _.startsWith(query.str, '##ADVANCED##') : false;

    _.set(transformedQuery, 'str', query && query.str ? query.str.replace('##ADVANCED##', '') : '');
    _.set(transformedQuery, 'advancedQuery', isAdvanced);

    return transformedQuery;
};
