import * as LDClient from 'launchdarkly-js-client-sdk';
(function() {
    'use strict';
    angular.module('serviceApp').factory('appSettingsService', AppSettingsService);

    AppSettingsService.$inject = ['_', '$q', '$window', 'teamServiceNew', 'userServiceNew'];

    function AppSettingsService(_, $q, $window, teamServiceNew, userServiceNew) {
        const service = { checkVariation, checkVariationSync, initLD };
        let ldClient;
        return service;

        //Initialize the Launch Darkly Client
        function initLD() {
            let LD_KEY;
            const LD_KEY_LOCAL = '5b72f315ee07bf163bef2474';
            const LD_KEY_PROD = '5b72f315ee07bf163bef2475';
            const LD_KEY_SANDBOX = '5e56c3f57b21e80924932666';

            let deferred = $q.defer();
            const domain = _.get($window, 'location.host');
            if (!_.includes(domain, 'localhost') && !_.includes(domain, 'olono.test')) {
                if (_.includes(domain, '.sandbox.')) {
                    LD_KEY = LD_KEY_SANDBOX;
                } else {
                    LD_KEY = LD_KEY_PROD;
                }
            } else {
                LD_KEY = LD_KEY_LOCAL;
            }

            // If the client is not initialized, intialize it, otherwise return the client.
            if (ldClient) {
                deferred.resolve(ldClient);
            } else {
                // Fetch the user object so that we can bootstrap flags and initialize the client
                // with the team shortcode and the logged-in user's email address.
                $q.all([teamServiceNew.getCachedOrFetch(), userServiceNew.getCachedOrFetch()]).then(
                    result => {
                        const [team, user] = result;
                        const options = _.isEmpty(user.flags) ? {} : { bootstrap: user.flags };
                        ldClient = LDClient.initialize(
                            LD_KEY,
                            {
                                key: team.shortcode, // this is the key used to find flag variations
                                name: team.shortcode, // this sets the name displayed in the UI
                                email: user.email, // this allows us to switch variations based on email rules
                                anonymous: false
                            },
                            options
                        );

                        deferred.resolve(ldClient);
                    }
                );
            }

            return deferred.promise;
        }

        /**
         * Checks if the variation is available
         *
         * @param {String} variation name of the variation to test
         * @param {Object} options.default - The default value for the setting.
         * @return {boolean} promise of either true or false
         *
         * usage:
         *
         * appSettingsService
         *     .checkVariation('<variation-name>', {default: true})
         *      .then(value => {
         *         $scope.<SCOPED_VARIABLE> = value;
         *     })
         *     .catch(err => {
         *         console.error('ERROR', err);
         *     });
         */
        function checkVariation(variation, options = {}) {
            let deferred = $q.defer();
            if (!variation) {
                return $q.reject({
                    msg: 'Variation must be defined'
                });
            }
            initLD()
                .then(client => {
                    deferred.resolve(client.variation(variation, options.default));
                })
                .catch(error => {
                    //error initializing LD client, default false
                    console.error(error);
                    deferred.resolve(options.default);
                });
            return deferred.promise;
        }

        function checkVariationSync(variation, options) {
            if (!ldClient) {
                return options.default;
            }
            return ldClient.variation(variation, options.default);
        }
    }
})();
