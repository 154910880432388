'use strict';

/**
 * Angular wrapper for our <ReactApp /> react component.
 */
export default class ReactApp {
    static get $inject() {
        return ['$scope', 'themeService'];
    }

    constructor($scope, themeService) {
        this.$scope = $scope;
        this.themeService = themeService;
        this.$scope.toggleMode = this.toggleMode.bind(this);
    }

    toggleMode(darkMode) {
        this.themeService.toggleMode(darkMode);
    }

    $onChanges(changes) {
        this.hooks = {
            toggleMode: this.$scope.toggleMode
        };
        if (changes.team && changes.team.currentValue) {
            this.teamObj = changes.team.currentValue;
            if (this.teamObj.toJSON) {
                this.teamObj = this.teamObj.toJSON();
            }
        }
        if (changes.user && changes.user.currentValue) {
            this.userObj = changes.user.currentValue;
            if (this.userObj.toJSON) {
                this.userObj = this.userObj.toJSON();
            }
        }
    }
}
