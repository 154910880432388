'use strict';

export default class Controller {
    static get $inject() {
        return ['_', '$log', '$scope', '$stateParams', '$state', '$timeout', '$sanitize', 'userServiceNew'];
    }
    constructor(_, $log, $scope, $stateParams, $state, $timeout, $sanitize, userServiceNew) {
        let targetId = _.get($stateParams, 'targetId');
        if (targetId) {
            targetId = _.split(targetId, '$');
            $scope.targetCardId = _.last(targetId);
            $scope.targetBoardName = _.first(targetId);
        } else {
            // if there is no target ID(mainly for load cards), fall back to set the board short code and panel
            // and pass that down to the platform ui component
            $scope.targetBoardShortCode = _.get($scope, 'board.shortcode');
            $scope.targetPanel = _.get($scope, 'panel.name');
        }

        //Fetch the starred definition list for this board
        const starredDefs =
            _.get($scope.user, 'state.starredDefinitions') || JSON.stringify({ insights: [] });
        if (starredDefs) {
            try {
                $scope.starred = _.get(JSON.parse(starredDefs), _.get($scope.board, 'type'), []);
            } catch (exc) {
                $log.error('An Error Occurred', exc);
            }
        }

        // Listen to inner menu click on menu items
        $scope.$on('inner-menu-item-click', (event, payload) => {
            const {
                cardDefinitionId,
                boardDefinitionName,
                boardType,
                type,
                cardDefinition,
                panel,
                shortCode
            } = _.get(payload, 'data', {});
            if (type === 'card') {
                // If the type of the item clicked is a card, call back to render a single card
                $scope.renderCardCallback({
                    data: { cardDefinitionId, boardDefinitionName, boardType, cardDefinition }
                });
            } else if (type === 'board') {
                // If the type of the item clicked is a board, call back to navigate to the new board
                $scope.boardSelectCallback({
                    data: { boardDefinitionName, boardType, panel, shortCode }
                });
            }
        });

        $scope.$on('inner-menu-add-click', (event, payload) => {
            //TODO: Update the payload to handle 'card' and 'board' types
            if (!$scope.$root.rightDrawerOpen) {
                $scope.$root.$emit('rightDrawer:open', {
                    layout: 'definitionCreator',
                    type: 'board',
                    definitionTypeString: 'Dashboard',
                    api: $scope.api,
                    user: $scope.user,
                    board: $scope.board,
                    definitionFilter: { 'board.boardType': $scope.board.type }
                });
            }
        });

        //Listen for changes to the starred definitions list and update the user object accordingly
        $scope.$on('inner-menu-star-click', (event, payload) => {
            const updatedStarred = _.get(payload, 'data');
            let starredDefinitions = _.get($scope, 'user.state.starredDefinitions', {});
            if (starredDefinitions) {
                try {
                    starredDefinitions = JSON.parse(starredDefinitions);
                } catch (exc) {
                    $log.error('An Error Occurred', exc);
                }
            }
            const boardType = _.get($scope.board, 'type');
            starredDefinitions[boardType] = updatedStarred;
            var sanitizedSignature = $sanitize(_.get($scope, 'user.profile.emailSignature'));
            var updateUserProfilePayload = {
                profile: {
                    name: $scope.user.profile.name,
                    emailSignature: sanitizedSignature
                },
                id: $scope.user.id
            };

            _.set(updateUserProfilePayload, 'state.starredDefinitions', JSON.stringify(starredDefinitions));

            userServiceNew.updateUser(updateUserProfilePayload).then(
                function() {
                    $log.info('Profile Updated!', '');
                },
                function(error) {
                    $log.error('An Error Occurred', error.msg);
                }
            );
        });

        /**
         * refresh() Toggle the current board definition
         *
         * @param {*} shortCode Shortcode of board to load.  May include definition name (separated by a $).
         */
        $scope.toggleBoard = function(shortCode) {
            // The timeout is needed here otherwise the page update doesn't happen.
            $timeout(function() {
                $state.go('boards.board.panel', {
                    board: shortCode,
                    panel: _.get($scope.panel, 'name'),
                    overwriteReload: false,
                    boardDef: shortCode
                });
            }, 100);
        };
    }
}
