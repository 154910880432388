'use strict';

/**
 * Angular wrapper for our <ListFilterPillsExternalComponent /> react component.
 */
export default class ListFilterPills {
    static get $inject() {
        return ['$scope', '$filter', '_'];
    }

    constructor($scope, $filter, _) {
        this.$scope = $scope;
        this.$filter = $filter;
        this._ = _;
    }

    $onInit() {
        // Props being passed to our react component
        this.hooks = {
            getContentTypeLabel: this.$filter('contentTypeLabel')
        };

        this.data = {
            isExpanded: this.isExpanded,
            showEmpty: this.showEmpty,
            card: this.card
        };
    }

    $onChanges(changes) {
        if (this._.get(changes, 'isExpanded') && !changes.isExpanded.isFirstChange()) {
            this.isExpanded = changes.isExpanded.currentValue;
            this.data = {
                ...this.data,
                isExpanded: this.isExpanded,
                showEmpty: this.showEmpty,
                card: this.card
            };
        }
        if (this._.get(changes, 'card') && !changes.card.isFirstChange()) {
            this.card = changes.card.currentValue;
            this.data = {
                ...this.data,
                isExpanded: this.isExpanded,
                showEmpty: this.showEmpty,
                card: this.card
            };
        }
    }
}
