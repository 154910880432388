'use strict';

import controller from './controller';
import template from './index.html';

angular.module('serviceApp').component('filterPillsButtonGroup', {
    controller,
    template,
    bindings: {
        numberItemsShowing: '<',
        numberItemsTotal: '<',
        filterName: '<',
        isPillsOpen: '<',
        isExpandable: '<',
        isFilterSelected: '<',
        isRemovable: '<',
        onFilterSelectButtonClick: '&',
        onTogglePillButtonClick: '&',
        onFilterToggleOff: '&',
        isInsightsView: '<'
    }
});
