import _ from 'lodash';

export default class CardTopContentController {
    static get $inject() {
        return ['$scope', '$element', '$location', '$anchorScroll', 'themeService'];
    }
    constructor($scope, $element, $location, $anchorScroll, themeService) {
        this.scope = $scope;
        this.element = $element;
        this.location = $location;
        this.anchorScroll = $anchorScroll;
        this.themeService = themeService;
        this.scope.isDark = themeService.isDarkMode;
        this.themeService.onThemeChange($scope, isDark => {
            this.scope.isDark = isDark;
        });

        this.scope.resultLength = 0;
        this.contentProperty = _.get(this.scope, 'contentProperty', 'properties.content');

        this.scope.sortable = !_.get(this.scope, 'panel.disableSort', false);
        this.scope.sorting = _.get(
            this.scope,
            'facets.cards.content.sorting',
            _.get(this.scope.card, 'facetGroups.sorting.default', 'relevance')
        );

        this.scope.isPaginated = !_.isArray(_.get(this.scope.card, this.contentProperty));

        this.scope.defaultSize = _.clone(this.scope.card.size);

        this.scope.collapseCard = this.collapseCard.bind(this);

        let refreshTopContentListener = this.scope.$root.$on('refreshTopContent', (event, data) => {
            this.scope.refreshCallbackHitch();
        });

        var cleanUpFunc = this.scope.$root.$on(
            this.scope.board.shortcode + '-filter-content',
            this.applyFilter.bind(this)
        );

        this.scope.$on('apply-filter', (event, data) => {
            this.applyFilter(event, data);
        });

        var weeklyListener = this.scope.$root.$on('queryWeeklyContent', (event, data) => {
            if (!_.get(this.scope, `panel.contentLayout`)) {
                this.scope.getCardsHitch({
                    data: {
                        cardName: this.scope.card.name,
                        facets: {
                            cards: {
                                content: {
                                    type: this.scope.$root.localFilter ? this.scope.$root.localFilter : '',
                                    sorting: this.scope.sorting,
                                    period: data ? data : ''
                                }
                            }
                        }
                    }
                });
            }
        });

        //clean up the listener on panel updates
        this.scope.$on('$destroy', () => {
            cleanUpFunc();
            weeklyListener();
            refreshTopContentListener();
        });

        this.scope.setSorting = this.setSorting.bind(this);

        this.scope.refreshCallback = this.refreshCallback.bind(this);

        this.scope.expandCard = this.expandCard.bind(this);

        this.scope.popout = this.popout.bind(this);

        this.scope.setNumberOfShownItems = this.setNumberOfShownItems.bind(this);

        this.initializeSize();

        this.scope.$on('cardsChanged', (event, data) => {
            this.scope.sorting = _.get(
                data,
                'cards.content.sorting',
                _.get(data, 'cards.content.facetGroups.sorting.default', 'relevance')
            );
            this.initializeSize();
        });

        this.element.bind('resize', () => {
            var width = _.get(this.element, '[0].firstChild.offsetWidth');
            if (width < 700) {
                this.scope.cardLayout = 'skinny';
            }
        });

        angular.element(this.element).on('resize', () => {
            var width = _.get(this.element, '[0].firstChild.offsetWidth');
            if (width < 700) {
                this.scope.cardLayout = 'skinny';
            }
        });

        this.scope.$watchCollection('card.' + this.contentProperty, () => {
            this.setLength();
        });
    }

    setSorting(type) {
        this.scope.getCardsHitch({
            data: {
                cardName: this.scope.card.name,
                facets: {
                    cards: {
                        content: {
                            type: this.scope.$root.localFilter ? this.scope.$root.localFilter : '',
                            sorting: type
                        }
                    }
                }
            }
        });
    }

    expandCard(init) {
        this.scope.layout = _.get(this.scope.panel, 'layout', 'vertical');
        if (this.scope.layout == 'vertical') {
            this.scope.card.collapse = init ? this.scope.card.collapse : !this.scope.card.collapse;
            this.scope.card.size.y = this.scope.defaultSize.y;
        } else {
            this.scope.card.collapse = init ? this.scope.card.collapse : !this.scope.card.collapse;
            this.scope.card.size.x = this.scope.defaultSize.x;
            this.scope.resizeCallbackHitch();
        }
    }

    refreshCallback() {
        this.scope.refreshCallbackHitch();
    }

    setLength() {
        if (this.scope.isPaginated) {
            this.scope.results = _.get(this.scope.card, this.contentProperty + '.results');
            this.scope.resultLength = _.get(this.scope.card, this.contentProperty + '.count');
        } else {
            this.scope.results = _.get(this.scope.card, this.contentProperty);
            this.scope.resultLength = _.size(this.scope.results);
        }
        if (_.get(this.scope.card.metrics, 'totalSize')) {
            this.scope.resultLength = _.get(this.scope.card.metrics, 'totalSize');
        }
    }

    popout() {
        var data = {
            board: this.scope.board.shortcode,
            panelGroup: _.get(this.scope.board, 'state.currentGroup', 'main'),
            panel: this.scope.panel.name,
            card: this.scope.card.name
        };
        this.scope.popoutCallbackHitch({
            data: data
        });
    }

    // Shows the content list viewed number of items for the header
    setNumberOfShownItems(data) {
        this.scope.showLimit = data;
    }

    collapseCard(init) {
        this.scope.layout = _.get(this.scope.panel, 'layout', 'vertical');
        if (this.scope.layout == 'vertical') {
            this.scope.card.collapse = init ? this.scope.card.collapse : !this.scope.card.collapse;
            this.scope.card.size.y = 1;
        } else {
            this.scope.card.collapse = init ? this.scope.card.collapse : !this.scope.card.collapse;
            this.scope.card.size.x = 1;
            this.scope.resizeCallbackHitch();
        }
    }

    applyFilter(event, filter) {
        this.scope.$root.localFilter = filter;
        this.scope.getCardsHitch({
            data: {
                cardName: this.scope.card.name,
                facets: {
                    cards: {
                        content: {
                            type: this.scope.$root.localFilter ? this.scope.$root.localFilter : '',
                            sorting: this.scope.sorting
                        }
                    }
                }
            }
        });
    }

    initializeSize() {
        var width = _.get(this.element, '[0].firstChild.offsetWidth');
        if (width < 700) {
            this.scope.cardLayout = 'skinny';
        }

        this.scope.layout = _.get(this.scope.panel, 'layout', 'vertical');
        if (this.scope.layout == 'vertical') {
            return;
        }

        this.setLength();

        if (!this.scope.results || _.isEmpty(this.scope.results)) {
            _.set(this.scope.card, 'collapse', true);
        } else {
            _.set(this.scope.card, 'collapse', false);
        }

        if (this.scope.card.collapse) {
            this.scope.collapseCard(true);
        } else {
            this.scope.expandCard(true);
        }

        //Force scroll to the top of the list
        this.location.hash('card-header-id');
        this.anchorScroll();
    }
}
