(function() {
    'use strict';

    angular.module('serviceApp').factory('integrationService', IntegrationService);

    IntegrationService.$inject = ['_', '$log', '$resource', '$q', 'storageService'];

    function IntegrationService(_, $log, $resource, $q, storageService) {
        var integrationsUrl = '/api/integration';
        var userIntegrationsUrl = '/api/user/integration';
        var defaultParams = {};
        var integrationResourceActions = {
            searchIntegrations: {
                method: 'GET',
                url: integrationsUrl,
                isArray: true
            },
            addTeamIntegration: {
                method: 'POST'
            },
            editTeamIntegration: {
                method: 'POST',
                url: integrationsUrl + '/:integrationId'
            },
            deleteTeamIntegration: {
                method: 'DELETE',
                url: integrationsUrl + '/:integrationId'
            },
            enableUserIntegration: {
                method: 'PUT',
                url: userIntegrationsUrl + '/:integrationId/enable'
            },
            disableUserIntegration: {
                method: 'PUT',
                url: userIntegrationsUrl + '/:integrationId/disable'
            },
            enableTeamIntegration: {
                method: 'PUT',
                url: integrationsUrl + '/enable/:id'
            },
            disableTeamIntegration: {
                method: 'PUT',
                url: integrationsUrl + '/disable/:id'
            },
            forceDeleteUserIntegration: {
                method: 'PUT',
                url: userIntegrationsUrl + '/:integrationId/delete'
            },
            editUserIntegration: {
                method: 'POST',
                url: userIntegrationsUrl + '/:integrationId'
            },
            deleteUserIntegration: {
                method: 'DELETE',
                url: userIntegrationsUrl + '/:integrationId'
            },
            getUserIntegration: {
                method: 'GET',
                url: userIntegrationsUrl + '/:integrationId'
            },
            forceRestart: {
                method: 'PUT',
                url: integrationsUrl + '/force-restart'
            },
            removeIntegrationContent: {
                method: 'DELETE',
                url: integrationsUrl + '/:integrationId/content'
            },
            syncUserIntegration: {
                method: 'POST',
                url: userIntegrationsUrl + '/:integrationId/sync'
            },
            syncTeamIntegration: {
                method: 'POST',
                url: integrationsUrl + '/:integrationId/sync'
            },
            deleteStaleContent: {
                method: 'POST',
                url: integrationsUrl + '/:integrationId/delete-stale-content'
            },
            checkStaleContent: {
                method: 'GET',
                url: integrationsUrl + '/:integrationId/check-stale-content'
            }
        };

        var integrationResource = $resource(integrationsUrl, defaultParams, integrationResourceActions);

        var service = {
            searchIntegrations: searchIntegrations,
            addTeamIntegration: addTeamIntegration,
            editTeamIntegration: editTeamIntegration,
            deleteTeamIntegration: deleteTeamIntegration,
            enableUserIntegration: enableUserIntegration,
            disableUserIntegration: disableUserIntegration,
            enableTeamIntegration: enableTeamIntegration,
            disableTeamIntegration: disableTeamIntegration,
            forceDeleteUserIntegration: forceDeleteUserIntegration,
            editUserIntegration: editUserIntegration,
            deleteUserIntegration: deleteUserIntegration,
            getUserIntegration: getUserIntegration,
            forceRestart: forceRestart,
            removeIntegrationContent: removeIntegrationContent,
            syncUserIntegration: syncUserIntegration,
            syncTeamIntegration: syncTeamIntegration,
            deleteStaleContent: deleteStaleContent,
            checkStaleContent: checkStaleContent
        };

        return service;

        ////////////

        function searchIntegrations() {
            return $q.when(integrationResource.searchIntegrations().$promise);
        }

        function addTeamIntegration(title, type, appId, settings, properties) {
            //aka enable team integration
            if (!title) {
                return $q.reject({
                    msg: 'Integration title must be defined'
                });
            }

            if (!type) {
                return $q.reject({
                    msg: 'integration type must be defined'
                });
            }

            var body = {};

            _.set(body, 'title', title);
            _.set(body, 'type', type);
            _.set(body, 'app', appId);
            _.set(body, 'pollingInterval', _.get(properties, 'pollingInterval'));
            _.set(body, 'pollPrimaryOnly', _.get(properties, 'pollPrimaryOnly'));
            _.set(body, 'primaryUserId', _.get(properties, 'primaryUserId'));
            _.set(body, 'refreshContext', _.get(properties, 'refreshContext'));

            if (settings) {
                _.set(body, type, settings);
            }

            //Remove the cached team object from the storage
            storageService.delete('team');
            return $q.when(integrationResource.addTeamIntegration({}, body).$promise);
        }

        function editTeamIntegration(integrationId, properties, settings) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }

            var body = {};

            _.set(body, 'title', _.get(properties, 'title'));
            _.set(body, 'type', _.get(properties, 'type'));
            _.set(body, 'pollingInterval', _.get(properties, 'pollingInterval'));
            _.set(body, 'pollPrimaryOnly', _.get(properties, 'pollPrimaryOnly'));
            _.set(body, 'pollingInterval', _.get(properties, 'pollingInterval'));
            _.set(body, 'primaryUserId', _.get(properties, 'primaryUserId'));
            _.set(body, 'refreshContext', _.get(properties, 'refreshContext'));

            if (settings) {
                _.set(body, _.get(properties, 'type'), settings);
            }

            //Remove the cached team object from the storage
            storageService.delete('team');
            return $q.when(
                integrationResource.editTeamIntegration(
                    {
                        integrationId: integrationId
                    },
                    body
                ).$promise
            );
        }

        function enableUserIntegration(integrationId, options, userEmail, actionId) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }

            //Remove the cached user object from the storage
            storageService.delete('user');
            return $q.when(
                integrationResource.enableUserIntegration(
                    {
                        integrationId: integrationId
                    },
                    {
                        settings: options,
                        userEmail: userEmail,
                        actionId: actionId
                    }
                ).$promise
            );
        }

        function disableUserIntegration(integrationId, options, userEmail) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }

            //Remove the cached user object from the storage
            storageService.delete('user');
            return $q.when(
                integrationResource.disableUserIntegration(
                    {
                        integrationId: integrationId
                    },
                    {
                        userEmail: userEmail
                    }
                ).$promise
            );
        }

        function enableTeamIntegration(integrationId) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }

            //Remove the cached team object from the storage
            storageService.delete('team');
            return $q.when(
                integrationResource.enableTeamIntegration(
                    {
                        id: integrationId
                    },
                    {}
                ).$promise
            );
        }

        function disableTeamIntegration(integrationId) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }

            //Remove the cached team object from the storage
            storageService.delete('team');
            return $q.when(
                integrationResource.disableTeamIntegration(
                    {
                        id: integrationId
                    },
                    {}
                ).$promise
            );
        }

        function forceDeleteUserIntegration(integrationId, options, userEmail) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }

            //Remove the cached user object from the storage
            storageService.delete('user');
            return $q.when(
                integrationResource.forceDeleteUserIntegration(
                    {
                        integrationId: integrationId
                    },
                    {
                        userEmail: userEmail
                    }
                ).$promise
            );
        }

        function deleteTeamIntegration(integrationId) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }

            //Remove the cached team object from the storage
            storageService.delete('team');
            return $q.when(
                integrationResource.deleteTeamIntegration({
                    integrationId: integrationId
                }).$promise
            );
        }

        function deleteUserIntegration(integrationId, options, userEmail) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }

            //Remove the cached user object from the storage
            storageService.delete('user');
            return $q.when(
                integrationResource.deleteUserIntegration(
                    {
                        integrationId: integrationId
                    },
                    {
                        userEmail: userEmail
                    }
                ).$promise
            );
        }

        // if users exists, settings must be an array matching the users array
        function editUserIntegration(integrationId, settings, users, keepAuthSettings = false) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }

            //Remove the cached user object from the storage
            storageService.delete('user');
            return $q.when(
                integrationResource.editUserIntegration(
                    {
                        integrationId: integrationId
                    },
                    {
                        settings: settings,
                        users: users,
                        keepAuthSettings
                    }
                ).$promise
            );
        }

        function getUserIntegration(integrationId) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }
            return $q.when(
                integrationResource.getUserIntegration(
                    {
                        integrationId: integrationId
                    },
                    {}
                ).$promise
            );
        }

        function forceRestart(email, integrationType, integrationId) {
            return $q.when(
                integrationResource.forceRestart(
                    {},
                    {
                        userEmail: email,
                        integrationType: integrationType,
                        integrationId: integrationId
                    }
                ).$promise
            );
        }

        function removeIntegrationContent(integrationId) {
            return $q.when(
                integrationResource.removeIntegrationContent(
                    {
                        integrationId: integrationId
                    },
                    {}
                ).$promise
            );
        }

        function syncUserIntegration(integrationId, syncOptions, userEmail, userId, policyName) {
            return $q.when(
                integrationResource.syncUserIntegration(
                    {
                        integrationId: integrationId
                    },
                    {
                        options: syncOptions,
                        policyName,
                        userEmail,
                        userId
                    }
                ).$promise
            );
        }

        function syncTeamIntegration(integrationId, syncOptions, userEmail) {
            return $q.when(
                integrationResource.syncTeamIntegration(
                    {
                        integrationId: integrationId
                    },
                    {
                        options: syncOptions,
                        userEmail: userEmail
                    }
                ).$promise
            );
        }

        function deleteStaleContent(integrationId, syncOptions, userEmail) {
            return $q.when(
                integrationResource.deleteStaleContent(
                    {
                        integrationId: integrationId
                    },
                    {
                        options: syncOptions,
                        userEmail: userEmail
                    }
                ).$promise
            );
        }

        function checkStaleContent(integrationId) {
            if (!integrationId) {
                return $q.reject({
                    msg: 'Integration ID must be defined'
                });
            }
            return $q.when(
                integrationResource.checkStaleContent(
                    {
                        integrationId: integrationId
                    },
                    {}
                ).$promise
            );
        }
    }
})();
