'use strict';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('formTypeAhead', () => ({
    restrict: 'A',
    scope: {
        formModel: '=formTypeAhead',
        form: '=',
        readonly: '=?'
    },
    template,
    controller: Controller,
    link: ($scope, elems, attrs, ctrl) => {
        ctrl.initialize(elems[0]);
    }
}));
