'use strict';
import template from './index.html';
import Controller from './controller';

angular.module('serviceApp').directive('accordionExternal', () => ({
    scope: {
        definition: '=?',
        module: '<',
        component: '<'
    },
    template,
    controller: Controller
}));
