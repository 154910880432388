(function() {
    'use strict';
    angular.module('serviceApp').directive('appAdvancedSettings', appAdvancedSettings);
    function appAdvancedSettings() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                board: '=',
                user: '<',
                refreshBoardCallbackHitch: '&'
            },
            template: require('views/tmpl/partials/appOptions/appAdvancedSettings.html'),
            controller: AppAdvancedSettings
        };

        return directive;
    }

    AppAdvancedSettings.$inject = ['_', '$scope', '$rootScope', '$log', 'toastr', 'appServiceNew', '$state'];

    function AppAdvancedSettings(_, $scope, $rootScope, $log, toastr, appServiceNew, $state) {
        $scope.productName = $rootScope.productName;

        $scope.cardBackup = _.cloneDeep($scope.card);

        $scope.duplicateAppForm = {};

        $scope.showConfirm = function() {
            $scope.confirmResetModal = true;
        };

        $scope.hideConfirm = function() {
            $scope.confirmResetModal = false;
        };
        $scope.showDuplicate = function() {
            $scope.duplicateAppForm.title = '';
            $scope.duplicateAppModal = true;
        };

        $scope.hideDuplicate = function() {
            $scope.duplicateAppModal = false;
        };

        $scope.isDirty = function(advancedForm) {
            var checkDirty = advancedForm && advancedForm.$dirty;
            if (checkDirty) {
                $rootScope.appDirty = true;
            }
            return checkDirty || $rootScope.appDirty;
        };

        $scope.resetGlobalSettings = function(form) {
            appServiceNew.resetApp($scope.card.properties.app.id).then(
                function(updatedApp) {
                    toastr.success('App Reset!', '');
                    $scope.card.properties.app = updatedApp;
                    form.$setPristine();
                    form.$setUntouched();
                    $scope.refreshBoardCallbackHitch();
                    $scope.cardBackup = _.cloneDeep($scope.card);
                    $scope.hideConfirm();
                },
                function(error) {
                    $scope.$emit('longAPIEnd');
                    $log.debug('Failed to reset app: ' + $scope.card.properties.app.title);
                    $log.error('Failed to reset app: ' + $scope.card.properties.app.title + ' : ' + error);
                    toastr.error('Failed to reset app: ' + error, '');
                    $scope.hideConfirm();
                }
            );
        };

        $scope.addRemoveApp = function(app) {
            if (app.teamId != '##GLOBAL##' && app.events != 'team' && app.events != 'individual') {
                $scope.addApp();
            } else {
                $scope.removeApp();
            }
        };

        $scope.updateApp = function(form) {
            $log.debug(
                'Updating App: ' + $scope.card.properties.app.id + ' :' + $scope.card.properties.app.title
            );

            //ensure there is no content policy URL pattern for the last policy
            //we must have a catchall policy to prevent issues server side
            $scope.card.properties.app.contentPolicies[
                $scope.card.properties.app.contentPolicies.length - 1
            ].urlPattern = '';

            appServiceNew.updateApp($scope.card.properties.app).then(
                function(updatedApp) {
                    toastr.success('App Updated!', '');
                    $scope.card.properties.app = updatedApp.app;
                    // reset the form
                    form.$setPristine();
                    form.$setUntouched();
                    $scope.refreshBoardCallbackHitch();
                    $scope.cardBackup = _.cloneDeep($scope.card);
                },
                function(error) {
                    $scope.$emit('longAPIEnd');
                    $log.debug('Failed to update app: ' + $scope.card.properties.app.title);
                    $log.error('Failed to update app: ' + $scope.card.properties.app.title + ' : ' + error);
                    toastr.error('Failed to update app: ' + error, '');
                }
            );
        };

        $scope.duplicateApp = function() {
            $scope.duplicateAppError = '';
            appServiceNew
                .addAppToTeam($scope.card.properties.app.globalAppId, $scope.duplicateAppForm.title)
                .then(newApp => {
                    $state.go('boards.board.panel', {
                        board: newApp.boardId,
                        panel: 'settings',
                        overwriteReload: false
                    });
                })
                .catch(res => {
                    const { data } = res;
                    $scope.duplicateAppError = data?.error || 'An error occurred.  Please try again.';
                    if (res.status === 409) {
                        $scope.duplicateAppError = 'This title is already in use.  Please choose another.';
                    }
                });
        };

        $scope.set = function(variable, path, value) {
            _.set(variable, path, value);
            $scope.forms.applicationDetails.$setDirty();
            if (path.indexOf('events') != -1) {
                $scope.reflectEvents();
            }
            return;
        };

        $scope.reflectEvents = function() {
            _.forEach($scope.card.properties.app.contentPolicies, function(p) {
                return _.set(p, 'indexLevel', $scope.card.properties.app.events);
            });
        };

        $scope.addApp = function() {
            appServiceNew.addAppToTeam(_.get($scope.card, 'properties.app.id')).then(
                function(app) {
                    _.set($scope.card, 'properties.app', app);
                    $scope.board.properties.app = app;
                    toastr.success('App Added!', '');
                },
                function() {
                    toastr.error('Something went wrong', '');
                }
            );
        };

        $scope.removeApp = function() {
            appServiceNew.removeAppFromTeam(_.get($scope.card, 'properties.app.id')).then(
                function(app) {
                    _.set($scope.card, 'properties.app', app);
                    $scope.board.properties.app = app;
                    toastr.success('App Removed', '');
                },
                function() {
                    toastr.error('Something went wrong', '');
                }
            );
        };

        $scope.publishApp = function() {
            appServiceNew.promoteApp($scope.card.properties.app.id).then(
                function(publishedApp) {
                    toastr.success(
                        'Thanks for sharing! App published to the ' + $rootScope.productName + ' Community',
                        ''
                    );
                },
                function(error) {
                    toastr.error('Failed to update app: ' + error.data.error, '');
                }
            );
        };

        $scope.closeDrawer = function() {
            $scope.$root.$emit('rightDrawer:close');
        };
    }
})();
