angular.module('serviceApp').factory('forecastingService', function() {
    const constants = {
        FORECAST_PERIOD_TYPE_MONTH: 'Month',
        FORECAST_PERIOD_TYPE_QUARTER: 'Quarter'
    };
    constants.FORECAST_PERIOD_TYPE_ALL = [
        constants.FORECAST_PERIOD_TYPE_MONTH,
        constants.FORECAST_PERIOD_TYPE_QUARTER
    ];

    return {
        ...constants
    };
});
