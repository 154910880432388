'use strict';

/**
 * @ngdoc function
 * @name serviceApp.controller:ClientErrorResponse
 * @description
 * # ClientErrorResponse
 */
angular.module('serviceApp').controller('ClientErrorResponse', [
    '$scope',
    '$rootScope',
    '$stateParams',

    function($scope, $rootScope, $stateParams) {
        $rootScope.useHeader = false;
        $scope.user = $stateParams.user;
        $scope.team = $stateParams.team;
        $scope.errorCode = $stateParams.errorCode;
        $scope.shortcode = _.get($stateParams, 'shortcode');
        $scope.message =
            'Hmmm… Seems we are having trouble finding the content you are looking for. Here are some links that might help you get back on track';
        if ($scope.errorCode === 403) {
            $scope.message = `You don’t have access to your default homepage ${$scope.shortcode}, please contact your admin.`;
        }
    }
]);
