(function() {
    'use strict';

    angular.module('serviceApp').directive('cardTeamIntegrationSettings', cardTeamIntegrationSettings);

    function cardTeamIntegrationSettings() {
        var directive = {
            restrict: 'E',
            scope: {
                card: '=',
                refreshCallbackHitch: '&'
            },
            template: require('views/tmpl/cards/integrations/teamContainer.html'),
            controller: CardTeamIntegrationSettingsController
        };

        return directive;
    }

    CardTeamIntegrationSettingsController.$inject = [
        '_',
        '$scope',
        '$rootScope',
        '$log',
        'toastr',
        'integrationService',
        'moment'
    ];

    function CardTeamIntegrationSettingsController(
        _,
        $scope,
        $rootScope,
        $log,
        toastr,
        integrationService,
        $moment
    ) {
        $scope.teamIntegration = {};
        $scope.productName = $rootScope.productName;
        $scope.teamIntegration.form = _.clone($scope.card.properties.teamSettings);
        $scope.totalShown = 0;

        // api limit react component only for Salesforce
        $scope.showApiLimitComponent =
            _.get($scope, 'card.properties.integration.pollPrimaryOnly') &&
            _.includes(_.get($scope, 'card.properties.integration.integrationType'), 'salesforce');

        if ($scope.showApiLimitComponent) {
            _.set($scope, 'card.properties.definition.teamSchema.dailyApiCallLimit.display.hidden', true);
        }

        $scope.isEnabled =
            !$scope.card.properties.integration.disabled ||
            $moment($scope.card.properties.integration.enabled).isAfter(
                $scope.card.properties.integration.disabled
            );

        if (!$scope.card.properties.integration.pollingInterval) {
            $scope.card.properties.integration.pollingInterval = _.get(
                $scope.card.properties.definition,
                'pollingInterval',
                300
            );
        }

        if (_.isNil($scope.card.properties.integration.pollPrimaryOnly)) {
            $scope.card.properties.integration.pollPrimaryOnly = _.get(
                $scope.card.properties.definition,
                'pollPrimaryOnly',
                false
            );
        }

        $scope.isEmpty = function(object) {
            return _.isEmpty(object);
        };

        $scope.camelCase = function(key) {
            return _.startCase(key);
        };

        $scope.hasSettings = function() {
            var settingsCount = _.countBy($scope.card.properties.definition.teamSchema, 'display.hidden');
            return !!settingsCount['undefined'];
        };

        // Check to see if it has advanced properties and they are not all hidden
        $scope.hasAdvanced = false;
        if ($scope.card.properties.definition) {
            _.forEach($scope.card.properties.definition.teamSchema, function(value, key) {
                if (value.advanced && !_.get(value, 'display.hidden')) {
                    $scope.hasAdvanced = true;
                }
            });
        }

        $scope.submitForm = function(isValid, form) {
            if (isValid) {
                $log.debug('Team Integration Form:');
                $log.debug($scope.teamIntegration.form);
                angular.forEach($scope.teamIntegration.form, function(value, key) {
                    if (value === '##ENCRYPTED##') {
                        delete $scope.teamIntegration.form[key];
                    }
                });

                var props = {};

                _.set(props, 'title', $scope.card.properties.integration.title);
                _.set(props, 'type', $scope.card.properties.integration.integrationType);
                _.set(props, 'pollingInterval', $scope.card.properties.integration.pollingInterval);
                _.set(props, 'pollPrimaryOnly', $scope.card.properties.integration.pollPrimaryOnly);
                _.set(props, 'primaryUserId', $scope.card.properties.integration.primaryUserId);
                _.set(props, 'refreshContext', $scope.card.properties.integration.refreshContext);

                integrationService
                    .editTeamIntegration(
                        $scope.card.properties.integration.id,
                        props,
                        $scope.teamIntegration.form
                    )
                    .then(integrationAPISuccess, integrationAPIFailure);
                form.$setPristine();
            } else {
                toastr.error('Invalid input, please fill out fields correctly', '');
            }
        };

        $scope.fullTeamSync = function() {
            integrationService
                .syncTeamIntegration($scope.card.properties.integration.id, {
                    syncReset: true
                })
                .then(
                    function() {
                        toastr.success('Integration Team Sync Started', '');
                        $scope.showFullSyncModal = false;
                    },
                    function(error) {
                        toastr.error('An error occurred', error);
                    }
                );
        };

        $scope.toggleFullSyncModal = function() {
            $scope.showFullSyncModal = !$scope.showFullSyncModal;
        };

        $scope.deleteStaleContent = function() {
            integrationService.deleteStaleContent($scope.card.properties.integration.id).then(
                function(result) {
                    var totalDeleted = result.totalDeleted;
                    var s = totalDeleted === 1 ? '' : 's';
                    var msg = result.totalDeleted + ' stale item' + s + ' deleted!';
                    toastr.success(msg);
                    $scope.showDeleteStaleContentModal = false;
                },
                function(error) {
                    let msg = 'An error occurred';
                    if (error.status === 400) {
                        msg = _.get(error, 'data.error', msg);
                    }
                    toastr.error(msg, error);
                }
            );
        };

        $scope.toggleDeleteStaleContentModal = function() {
            if (!$scope.showDeleteStaleContentModal) {
                return integrationService.checkStaleContent($scope.card.properties.integration.id).then(
                    function(result) {
                        // Set the # of stale content items for display.
                        $scope.numStaleContentItems = result.meta.totalHits;
                        // Log the full report to the console.
                        console.log('STALE CONTENT REPORT');
                        console.log(_.pick(result, ['meta', 'items']));
                        $scope.showDeleteStaleContentModal = true;
                    },
                    function(error) {
                        let msg = 'An error occurred';
                        if (error.status === 400) {
                            msg = _.get(error, 'data.error', msg);
                        }
                        toastr.error(msg, error);
                    }
                );
            } else {
                $scope.showDeleteStaleContentModal = false;
            }
        };

        $scope.toggleHelpModal = function(helpText) {
            $scope.helpText = helpText || '';
            $scope.showHelpModal = !$scope.showHelpModal;
        };

        $scope.toggleDeleteModal = function() {
            $scope.showDelete = !$scope.showDelete;
        };

        $scope.toggleRemoveContentModal = function() {
            $scope.showRemove = !$scope.showRemove;
        };

        $scope.toggleDangerOptions = function() {
            $scope.showDangerOptions = !$scope.showDangerOptions;
        };

        $scope.deleteTeamIntegration = function() {
            integrationService.deleteTeamIntegration($scope.card.properties.integration.id).then(function() {
                toastr.success('Integration Deleted!', '');
                $scope.showDelete = !$scope.showDelete;
                _.delay(function() {
                    $scope.refreshCallbackHitch();
                }, 1000);
            });
        };

        $scope.removeIntegrationContent = function() {
            integrationService.removeIntegrationContent($scope.card.properties.integration.id).then(
                function() {
                    toastr.success('Integration content being removed', '');
                    $scope.showRemove = false;
                },
                function(error) {
                    toastr.error('An error occurred', error);
                    $scope.showRemove = false;
                }
            );
        };

        $scope.disableTeamIntegration = function() {
            integrationService
                .disableTeamIntegration($scope.card.properties.integration.id)
                .then(integrationAPISuccess, integrationAPIFailure);
        };

        $scope.enableTeamIntegration = function() {
            integrationService
                .enableTeamIntegration($scope.card.properties.integration.id)
                .then(integrationAPISuccess, integrationAPIFailure);
        };

        $scope.updateDailyApiCallLimit = function(newValue) {
            _.set($scope, 'teamIntegration.form.dailyApiCallLimit', newValue);
        };

        function integrationAPISuccess() {
            toastr.success('Integration Edited!', '');
            _.delay(function() {
                $scope.refreshCallbackHitch();
            }, 1000);
        }

        function integrationAPIFailure(error) {
            toastr.error('An error occurred', error.msg);
        }
    }
})();
