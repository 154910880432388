(function() {
    'use strict';

    angular.module('serviceApp').filter('dateTime', dateTime);

    dateTime.$inject = ['_', '$filter'];

    function dateTime(_, $filter) {
        var filter = function(value) {
            if (!value || _.isNil(value)) {
                return 'N/A';
            }
            //Return browser localized medium format e.g US_Localized "Sep 3, 2010 12:05:08 PM"
            return $filter('date')(value, 'medium');
        };

        return filter;
    }
})();
