'use strict';

import template from './index.html';
import controller from './controller';

angular.module('serviceApp').component('contextMenu', {
    controller,
    template,
    bindings: {
        anchorEl: '<',
        onMenuClose: '<',
        menuOptions: '<'
    }
});
