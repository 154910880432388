/*eslint no-undef: "error"*/
(function() {
    'use strict';
    // eslint-disable-next-line no-undef
    angular.module('serviceApp').factory('interpretedContentFactory', InterpretedContentFactory);

    InterpretedContentFactory.$inject = ['_', '$interpolate', '$rootScope'];

    function InterpretedContentFactory(_, $interpolate, $rootScope) {
        return function($scope = {}) {
            function getItem(asArray, path) {
                var item;
                //if templated
                if (_.includes(path, '{{')) {
                    var templater = $interpolate(path);
                    item = templater($scope);
                } else {
                    item = _.get($scope.content, path);
                }

                return asArray ? _.castArray(item) : item;
            }

            function isPathHidden(path) {
                const contentType = $scope.contentType;
                const propertyDisplayOptions =
                    $scope.propertyDisplayOptions ||
                    _.get($scope, [
                        'board',
                        'contentDisplayOptions',
                        contentType,
                        'propertyDisplayOptions'
                    ]) ||
                    _.get($rootScope, [
                        'board',
                        'contentDisplayOptions',
                        contentType,
                        'propertyDisplayOptions'
                    ]);
                var field = _.get(propertyDisplayOptions, path);
                if (field) {
                    return _.get(field, 'hidden');
                }
                return false;
            }

            function getContentField(item, asArray) {
                if (!item) {
                    return item;
                }

                var result;

                if (_.isNil(asArray)) {
                    asArray = item.type == 'array';
                }

                if (_.isArray(item.value)) {
                    result = _.map(item.value, function(path) {
                        if (isPathHidden(path)) {
                            return;
                        }
                        if (_.isObject(path)) {
                            path = _.get(path, 'path');
                            var def = _.get(path, 'default');
                            var itemToReturn = getItem(asArray, path);

                            if (_.isEmpty(itemToReturn) && def) {
                                itemToReturn = getItem(false, def);
                            }

                            return itemToReturn;
                        }

                        return getItem(asArray, path);
                    });

                    result = _.join(result, ',');
                    result = _.split(result, ',');
                    result = _.compact(result);
                    result = _.uniq(result);
                } else if (_.isObject(item.value)) {
                    var path = _.get(item, 'value.path');
                    if (isPathHidden(path)) {
                        return;
                    }
                    var def = _.get(item, 'value.default');
                    result = getItem(asArray, path);

                    if (_.isEmpty(result) && def) {
                        result = getItem(false, def);
                    }
                } else {
                    result = getItem(asArray, item.value);

                    if (asArray) {
                        result = _.compact(result);
                    }
                }

                if (_.isEmpty(result) && item.default) {
                    result = getItem(false, item.default);
                }

                return result;
            }

            function isClickable(enabled) {
                if (!enabled) {
                    return false;
                }

                if (enabled === true) {
                    return true;
                }

                var result = getContentField({
                    value: enabled
                });

                if (result == 'false') {
                    return false;
                }

                return !!result;
            }

            function getTitleHtml(titleObject) {
                var title = getContentField(titleObject);
                var titleWords = _.split(title, ' ');
                var lastWord = _.last(titleWords);

                $scope.rawTitle = _.clone(title);

                if (_.get(titleWords, 'length') > 1) {
                    _.unset(titleWords, _.get(titleWords, 'length') - 1);
                }

                title = _.join(titleWords, ' ');

                if (title === lastWord) {
                    title = '';
                }

                if (lastWord) {
                    return (
                        '<div class="inline">' +
                        title +
                        '<div class="nowrap inline">' +
                        lastWord +
                        '</div></div>'
                    );
                } else {
                    return '<div class="inline">' + title + '</div>';
                }
            }

            function generateHref(clickObject) {
                //This prevents loading of a non-embedded view from loading. Toggle will still open content
                if ($scope.singleView) {
                    return;
                }
                if ($scope.actionPreviewCallback) {
                    return;
                }

                var destination = _.get(clickObject, 'destination');
                if (!destination) {
                    return;
                }

                var destinationType = _.get(destination, 'type');

                $scope.boardSref = false;

                if (destinationType === 'board') {
                    $scope.boardSref = true;
                } else if (destinationType === 'url') {
                    $scope.targetHref = getTargetHrefFromUrl(destination);
                } else if (destinationType === 'mail') {
                    $scope.targetHref = getTargetHrefFromMail(destination);
                } else {
                    $scope.targetHref = $scope.content?.clickableUrl || $scope.content.url;
                }

                // basically everything but "board" destination type is an externalTarget.
                if (destination.type === 'url' || destination.type === 'mail') {
                    $scope.externalTarget = true;
                }
            }

            function startWithAnyEmail(target, emails) {
                return _.some(emails, email => {
                    return _.startsWith(target, email + '##');
                });
            }

            /**
             * Returns an href for a destination of type url.
             * @param {object} destination
             * @returns {string|null} The user-specific url for the destination content, otherwise null.
             */
            function getTargetHrefFromUrl(destination) {
                let hashedEmails = _.get($scope, 'user.hashedEmails');
                // if something's not present, just return $scope.content.url as a default answer.
                if (!hashedEmails || !destination.userUrls || !getItem(false, destination.userUrls)) {
                    return (
                        getItem(false, destination.value) ||
                        $scope.content?.clickableUrl ||
                        $scope.content?.url
                    );
                }

                let urls = JSON.parse(getItem(false, destination.userUrls));
                if (!urls) {
                    return null;
                }

                let foundUrl = _.find(urls, function(url) {
                    return startWithAnyEmail(url, hashedEmails);
                });

                return foundUrl ? foundUrl.split('##').pop() : null;
            }

            /**
             * Converts the input threadIds to user url "format".
             * @param threadIds An array of threadId values to convert.
             * @returns {*} An array of userUrls.
             */
            function convertThreadIdsToUserUrls(threadIds) {
                let hashedEmails = _.get($scope, 'user.hashedEmails');
                if (!_.get($scope, 'user.email') || !hashedEmails) {
                    return null;
                }

                let threadIdsForCurrentUser = _.filter(threadIds, function(threadId) {
                    return startWithAnyEmail(threadId, hashedEmails);
                });

                let threadIdUrlPrefix =
                    'https://mail.google.com/mail/?authuser=' + $scope.user.email + '#inbox/';
                return _.map(threadIdsForCurrentUser, function(threadId) {
                    let [hashedEmailPiece, threadIdSuffix] = threadId.split('##');
                    return hashedEmailPiece + '##' + threadIdUrlPrefix + threadIdSuffix;
                });
            }

            /**
             * Returns an href for a destination of type mail.
             * This function attempts to get an href from the destination's userUrls property,
             * then if nothing is found it uses the (legacy) threadIds property.
             * @param {object} destination
             * @returns {string|null} The user-specific url for the destination content, otherwise null.
             */
            function getTargetHrefFromMail(destination) {
                let targetHrefFromUrl = getTargetHrefFromUrl(destination);

                // if userUrls didn't work, try using the (legacy) threadIds property.
                if (targetHrefFromUrl) {
                    return targetHrefFromUrl;
                }

                let hashedEmails = _.get($scope, 'user.hashedEmails');

                // make sure we've got all the necessary inputs
                if (!hashedEmails || !destination.threadIds || !getItem(false, destination.threadIds)) {
                    return null;
                }

                let threadIds = JSON.parse(getItem(false, destination.threadIds));
                let urls = convertThreadIdsToUserUrls(threadIds);
                if (!urls || _.isEmpty(urls)) {
                    return null;
                }

                let foundUrl = _.find(urls, function(url) {
                    return startWithAnyEmail(url, hashedEmails);
                });
                return foundUrl ? foundUrl.split('##').pop() : null;
            }

            function resolveTitle(titleObject) {
                if (!titleObject) {
                    $scope.titleHtml = $scope.content.displayTitle;
                    $scope.targetHref = $scope.content.url;
                    $scope.externalTarget = true;
                    return;
                }

                if (titleObject && _.get(titleObject, 'click')) {
                    $scope.isClickable = isClickable(_.get(titleObject, 'click.enabled'));
                    $scope.titleHtml = getTitleHtml(titleObject);
                    if ($scope.isClickable) {
                        generateHref(_.get(titleObject, 'click'));
                    }
                    return titleObject;
                }

                //if the content does not have userUrls and doesn't have a way to generate a link, use the content URL
                if (!$scope.targetHref && !$scope.boardSref) {
                    $scope.titleHtml = $scope.content.displayTitle;
                    $scope.targetHref = $scope.content.url;
                    $scope.externalTarget = true;
                }

                return titleObject;
            }

            $scope.isEnabledBasedOnHashedEmails = function(context) {
                const { threadIds, hashedEmails, fromAddress, toAddress, lowerCaseEmails } = context;
                if (threadIds) {
                    let threadIdsString = threadIds.join(',');
                    return _.some(hashedEmails, hashedEmail => _.includes(threadIdsString, hashedEmail));
                } else if (lowerCaseEmails) {
                    let addressArray = _.concat(fromAddress, toAddress);
                    return _.some(_.castArray(lowerCaseEmails), lowerCaseEmail =>
                        _.includes(addressArray, lowerCaseEmail)
                    );
                }
            };

            return {
                resolveTitle,
                getContentField,
                isPathHidden,
                generateHref,
                getItem
            };
        };
    }
})();
