(function() {
    'use strict';
    angular.module('serviceApp').factory('translateValuesService', TranslateValuesService);

    TranslateValuesService.$inject = ['_', 'moment', '$filter', '$interpolate', '$rootScope'];

    function TranslateValuesService(_, moment, $filter, $interpolate, $rootScope) {
        const isHidden = (contentType, propertyDisplayOptions, value) => {
            const fieldName = contentType + '.' + value;
            const field = _.get(propertyDisplayOptions, fieldName);
            if (field) {
                return _.get(field, 'hidden');
            }
            return false;
        };

        const getFormat = (contentType, propertyDisplayOptions, value) => {
            const fieldName = contentType + '.' + value;
            const field = _.get(propertyDisplayOptions, fieldName);

            let format = _.get(field, 'format');
            let type = _.get(field, 'type');
            if (type && !format) {
                switch (type) {
                    case 'integer':
                    case 'long':
                    case 'double':
                        format = 'niceNumber';
                        break;
                    case 'date':
                        format = 'mediumDate';
                        break;
                    case 'string':
                    case 'boolean':
                    default:
                        break;
                }
            }
            // This should never happen, but until we figure out the root cause
            // of INSIGHT-26516 we'll mitigate it here.
            if (format === 'percentWithColorCoding') {
                format = 'percent';
            }
            if (format === 'currencyWithDecimals') {
                format = 'currency';
            }
            return format;
        };

        const getDisplayOptions = (contentType, propertyDisplayOptions, value) => {
            const fieldName = contentType + '.' + value;
            return _.get(propertyDisplayOptions, fieldName);
        };

        // For mapped date formats we also support UNIX number types which will break integer strings,
        // so only support UNIX types if it's mapped
        const validMappedDateFormats = [
            'MM/DD/YYYY',
            'YYYY-MM-DD',
            'MM/DD/YY',
            'YYY-MM-DD',
            'YYYY-MM-DDTHH:mm:ssZ',
            'YYYY-MM-DDTHH:mm:ss.SSSZ',
            'YYYY-MM-DDTHH:mm:ss.SSS',
            'YYYY-MM-DDTHH:mm:ss',
            'YYYYMMDDTHHmmssZ',
            'YYYYMMDDTHHmmss',
            'YYYY-MM-DDTHH:mm:ss.SSSSSSSZ',
            'YYYY-MM-DDTHH:mm:ss.SSSSSSS',
            'YYYY-MM-DD HH:mm:SS.SSS',
            'x'
        ];

        const validDateFormats = [
            'MM/DD/YYYY',
            'YYYY-MM-DD',
            'MM/DD/YY',
            'YYY-MM-DD',
            'YYYY-MM-DDTHH:mm:ssZ',
            'YYYY-MM-DDTHH:mm:ss.SSSZ',
            'YYYY-MM-DDTHH:mm:ss.SSS',
            'YYYY-MM-DDTHH:mm:ss',
            'YYYYMMDDTHHmmssZ',
            'YYYYMMDDTHHmmss',
            'YYYY-MM-DDTHH:mm:ss.SSSSSSSZ',
            'YYYY-MM-DDTHH:mm:ss.SSSSSSS',
            'YYYY-MM-DD HH:mm:SS.SSS'
        ];

        return { translateValue, translateValues };

        ////////////
        function translateValues({
            $scope,
            $filter,
            $interpolate,
            contentType,
            propertyDisplayOptions,
            values,
            asArray,
            asObject,
            key,
            asRaw,
            showHidden,
            formatOverride,
            options
        }) {
            function getTranslatedValue(value) {
                if (asRaw) {
                    return value;
                }

                if (formatOverride) {
                    // if we've been told what format to use, just use it
                    return getFormattedValue(value, formatOverride, null, options);
                }

                const format = getFormat(contentType, propertyDisplayOptions, key);
                const displayOptions = getDisplayOptions(contentType, propertyDisplayOptions, key);

                // If we have a label and an explicit format...
                if (key && format) {
                    // If the format is a date, and the value is a valid date, then
                    // get a UTC version of the date and send it to the formatter.
                    // This protects us from incorrectly displaying dates that are
                    // not formatted with time zone info (we'll assume they're UTC).
                    // See https://insightsquared.atlassian.net/browse/ISSUE-8225
                    if (_.includes(['dateTime', 'daysAway', 'daysAwayDate', 'mediumDate', 'date'], format)) {
                        // Don't parse this unless we have to!
                        const valueMoment = moment.utc(value, validMappedDateFormats, true);
                        if (valueMoment.isValid()) {
                            return getFormattedValue(valueMoment.toISOString(), format);
                        }
                    }

                    // Otherwise let the formatter do its best with the value.
                    return getFormattedValue(value, format, displayOptions);
                }

                // If we don't have an explicit format, do some default formatting based on the input data
                if (_.isNumber(value)) {
                    return $filter('niceNumber')(value);
                } else if (_.includes(value, '{{')) {
                    let interpolatedValue;
                    try {
                        // Bail out early if we don't have the required params
                        if (!$scope || !$interpolate) {
                            return value;
                        }

                        interpolatedValue = $interpolate(value)($scope);
                    } catch (exc) {
                        //The value is not properly formatted return original un-interpolated value
                        return value;
                    }
                    return interpolatedValue;
                }

                // Not a number, not interpolated, so parse to check if it's a valid date.
                // This is relatively expensive so do it last.
                const valueMoment = moment.utc(value, validDateFormats, true);

                // If we don't have a label or an explicit format, do our best to display the value
                // based on context clues like its data type and content.
                if (_.isString(value) && valueMoment.isValid()) {
                    return $filter('humanDate')(valueMoment.toISOString());
                }

                return value;
            }

            function getFormattedValue(value, format, displayOptions, formatOptions) {
                if (_.isArray(value)) {
                    //return an array of filtered array items
                    return _.map(value, item => $filter(format).apply(displayOptions, [item, formatOptions]));
                }
                return $filter(format).apply(displayOptions, [value, formatOptions]);
            }
            //const clonedValues = _.cloneDeep(values);
            if (asArray) {
                return _.map(values, function(value) {
                    return getTranslatedValue(value);
                });
            } else if (asObject) {
                return _(_.keys(values))
                    .map(function(key) {
                        const label = _.get(values, key + '.label', _.startCase(key));
                        const value = _.get(values, key + '.value', _.get(values, key));
                        if (_.isEmpty(value) && !_.isNumber(value) && !_.isBoolean(value)) {
                            return;
                        }
                        if (
                            !showHidden &&
                            isHidden(contentType, propertyDisplayOptions, _.get(values, key + '.label', key))
                        ) {
                            return;
                        }
                        if (_.isBoolean(value)) {
                            return {
                                label: label,
                                value: _.toString(value)
                            };
                        }

                        const format = getFormat(
                            contentType,
                            propertyDisplayOptions,
                            _.get(values, key + '.label', key)
                        );

                        const displayOptions = getDisplayOptions(
                            contentType,
                            propertyDisplayOptions,
                            _.get(values, key + '.label', key)
                        );

                        if (format) {
                            return {
                                label: label,
                                value: getFormattedValue(value, format, displayOptions)
                            };
                        }
                        return {
                            label: label,
                            value: getTranslatedValue(value)
                        };
                    })
                    .compact()
                    .sortBy('label')
                    .value();
            } else {
                return getTranslatedValue(values);
            }
        }

        /**
         * Uses the properties display options for the supplied content type to
         * format a properties value.
         *
         * @param {Object} param.$scope - The directive/component scope
         * @param {String} param.contentType - The object type of the property to be formatted.
         * @param {String} param.propertyName - The name of the property to be formatted.
         * @param {*} param.propertyValue - The value to be formatted.
         * @returns The formatted property value
         */
        function translateValue({ $scope, contentType, propertyName, propertyValue }) {
            const propertyDisplayOptions = _.get(
                $rootScope,
                `displayOptions.${contentType}.propertyDisplayOptions`
            );

            return translateValues({
                $scope,
                $filter,
                $interpolate,
                contentType,
                propertyDisplayOptions,
                values: propertyValue,
                key: propertyName,
                showHidden: true
            });
        }
    }
})();
