(function() {
    'use strict';

    angular.module('serviceApp').directive('cardContextListPanel', cardContextListPanel);

    function cardContextListPanel() {
        var directive = {
            restrict: 'E',
            scope: {
                selectObjectCallback: '&',
                api: '=',
                summary: '=?',
                options: '=?'
            },
            transclude: {
                'primary-body': 'primaryBody',
                'primary-header': '?primaryHeader',
                'primary-menu': '?primaryMenu',
                'primary-footer': '?primaryFooter',
                'right-window': '?rightWindow'
            },
            link: function($scope, element, attr, ctrl) {
                $scope.api = {
                    toggleContext: function(label, item) {
                        $scope.toggleContext(label, item);
                    },
                    isActive: function(label, item) {
                        return $scope.isActive(label, item);
                    },
                    closeContext: function(label, item) {
                        $scope.closeContext();
                    }
                };
            },
            template: require('views/tmpl/partials/cardContextListPanel.html'),
            controller: CardContextListPanelController
        };

        return directive;
    }

    CardContextListPanelController.$inject = ['_', '$scope'];

    function CardContextListPanelController(_, $scope) {
        $scope.options = !_.isNil($scope.options) ? $scope.options : {};
        $scope.options.listType = !_.isNil($scope.options.listType) ? $scope.options.listType : 'legacy';
        $scope.options.isCollapsible = !_.isNil($scope.options.isCollapsible)
            ? $scope.options.isCollapsible
            : true;
        $scope.options.gridMargins = !_.isNil($scope.options.gridMargins)
            ? $scope.options.gridMargins
            : [15, 15];
        $scope.options.showContextByDefault = !_.isNil($scope.options.showContextByDefault)
            ? $scope.options.showContextByDefault
            : false;
        $scope.options.isTogglable = !_.isNil($scope.options.isTogglable) ? $scope.options.isTogglable : true;

        const styles = {
            listCard: {
                context: 'primary-listcard-data',
                primary: 'primary-listcard'
            },
            list: {
                context: undefined,
                primary: 'primary-list'
            },
            legacy: {
                context: 'contextwindowmap',
                primary: 'primarywindowmap'
            }
        };

        $scope.styles = { context: undefined, primary: undefined };
        $scope.styles.context = _.get(styles, $scope.options.listType + '.context');
        $scope.styles.primary = _.get(styles, $scope.options.listType + '.primary');

        //Until we lose the app config expander
        if ($scope.showContextByDefault && !$scope.summary) {
            $scope.showcontext = true;
        } else {
            $scope.showcontext = false;
        }

        /**
         * toggleContext - toggles the menu context window open and close
         *
         * @param  {string} label menu label
         * @param  {object} item  context item
         */

        $scope.toggleContext = function(label, item) {
            if (!$scope.options.isTogglable) {
                return;
            }

            if ($scope.showcontext && $scope.activetab === label && $scope.options.isCollapsible) {
                $scope.showcontext = false;
                $scope.activetab = null;
            } else {
                $scope.showcontext = true;
                $scope.activetab = label;
            }
            $scope.selectObjectCallback({ data: item });
        };

        /**
         * closesContext - close the menu context window
         */

        $scope.closeContext = function() {
            $scope.showcontext = false;
            $scope.activetab = null;
        };

        /**
         * isActive - returns if the menu item is currently active for styling
         *
         * @param  {string} label menu label
         * @param  {object} item  context item
         * @return {boolean}       description
         */

        $scope.isActive = function(label, item) {
            return $scope.activetab === label;
        };
    }
})();
