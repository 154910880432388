'use strict';
import _ from 'lodash';
import stripHtml from 'string-strip-html';

angular.module('serviceApp').filter('actionPromptFilter', () => (prompt, { length = 100 } = {}) =>
    _.truncate(stripHtml(prompt).result, {
        length,
        separator: ' '
    })
);
