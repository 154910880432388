'use strict';
import template from './index.html';
import Controller from './controller';
import './index.scss';

angular.module('serviceApp').directive('menuScrollableIndicator', () => ({
    restrict: 'E',
    scope: {
        large: '<?',
        fixed: '<?'
    },
    template: template,
    transclude: true,
    controller: Controller,
    link: ($scope, elems, attrs, ctrl) => {
        ctrl.initialize(elems[0]);
    }
}));
