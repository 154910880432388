'use strict';

import CardBase from '../common/CardBase';

export default class Controller extends CardBase {
    constructor(...args) {
        super(...args);
        this.scope.toggleContext = this.toggleContext.bind(this);
        this.scope.executeEventTrigger = this.executeEventTrigger.bind(this);
        this.scope.removeCompletedAction = this.removeCompletedAction.bind(this);
        this.actionsPath = ['card', 'properties', 'actions'];

        this.scope.isDarkMode = this.scope.themeService.isDarkMode;

        // need a watcher here as some aspects of the action list are from before
        // we had a light-dark CSS transform
        this.scope.$watch('themeService.isDarkMode', newValue => {
            this.scope.isDarkMode = newValue;
        });
    }

    /**
     * executeEventTrigger - run a given event trigger
     * @param {Object} data - args object
     * @param {String} data.eventTrigger - event trigger type to run.  dismiss or dismiss-all
     * @param {Object} data.action - action the event trigger is running on
     */
    executeEventTrigger(data) {
        const { action, eventTrigger, payload } = data;

        // launch URL based action event triggers
        this.contentLaunchService.launchActionEventTrigger(this.scope.user, action, eventTrigger);

        this.actionService
            .actionEvent(action, eventTrigger, payload)
            .then(result => {
                const newAction = _.get(result, 'action', {});
                const actionIndex = _.findIndex(this.scope.card.properties.actions, { id: action.id });

                if (newAction.state === 'active' || newAction.state === 'pending') {
                    _.set(this.scope.card.properties.actions, actionIndex, newAction);
                } else {
                    _.pullAt(this.scope.card.properties.actions, actionIndex);
                    this.toastr.success('Action updated');
                }

                // close the open drawer
                this.scope.$root.$emit('leftDrawer:close');
            })
            .catch(err => {
                this.toastr.error('An error occurred running action.', _.get(err, 'message', err));
            });
    }

    removeCompletedAction(data) {
        const actionId = _.get(data, 'actionId');
        const actionIndex = _.findIndex(this.scope.card.properties.actions, { id: actionId });
        _.pullAt(this.scope.card.properties.actions, actionIndex);
        this.toastr.success('Action updated');
    }

    /**
     * toggleContext - open or close the action details drawer
     * @param {Object} args
     * @param {Object} args.action
     */
    toggleContext({ action }) {
        this.scope.$root.$emit('leftDrawer:open', {
            layout: 'actionDetails',
            selectedAction: action,
            external: this.rootScope.isExternal,
            api: {
                executeEventTrigger: this.scope.executeEventTrigger
            }
        });
    }
}
